import React, { useEffect, useState } from "react";
import fileTab from "../../assets/file-tab.svg";
import { PlantEquipmentsDATA, PlantEquipmentsDTO } from "../../utils/interface";
import Button from "../Button/Button";
import ImageImports from "../../utils/ImageImports";
import { Link, useParams } from "react-router-dom";
import "../../components/PlantSiteDetails/PlantSiteDetails";

interface PlantSiteTableProps {
  tableColumns: Array<string>;
  tableData: PlantEquipmentsDTO;
  openEquipmentsAttachments?: (value: number) => void;
  onLinkClick?: () => void
}

function PlantSiteTable({ tableColumns, tableData, onLinkClick }: PlantSiteTableProps) {
  const { downArrow, pencil } = ImageImports;
  const [newData, setNewData] = useState<PlantEquipmentsDATA[]>([]);

  useEffect(() => {
    setNewData(tableData.data);
    if (tableData.data?.length > 0) {
      const newSliceData = tableData.data.slice(0, 4);
      setNewData(newSliceData);
    }
  }, [tableData]);

  const addMore = () => {
    const addMoreData = tableData.data;
    setNewData(addMoreData);
  };
  const subtractMore = () => {
    const subtractMoreData = tableData.data.slice(0, 4);
    setNewData(subtractMoreData);
  };

  let button = null;
  if (tableData.data.length > 4) {
    if (newData.length > 4) {
      button = (
        <Button
          className="whiteBtn showMoreBtn subtractMore"
          text="View Less Equipment"
          img={<img src={downArrow} className={"button-dropdown-less"} alt="" />}
          onClick={subtractMore}
        />
      );
    } else {
      button = (
        <Button
          className="whiteBtn showMoreBtn addMore"
          text="View More Equipment"
          img={<img src={downArrow} className={"button-dropdown"} alt="" />}
          onClick={addMore}
        />
      );
    }
  }

  return (
    <>
      {newData.length > 0 ? (
        <table className="border-collapse w-full">
          <thead>
            <tr>
              {tableColumns.map((option, i: number) => (
                <th className={`py-[14px] px-6 bg-[#F2F2F2] text-left`} key={`${option}-${i}`}>{option}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newData.map((option, i: number) => (
                <tr key={i}>
                  <td className={`py-2 px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''}`}>{option.equipmentName}</td>
                  <td className={`py-2 px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''}`}>{option.equipmentID}</td>
                  <td className={`py-2 px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''}`}>{option.productTypeName}</td>
                  <td className={`py-2 px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''}`}>{option.manufacturerName}</td>
                  <td className={`py-2 px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''}`}>{option.equipmentModelName}</td>
                  <td className={`pt-[6px] px-6 ${i > 0? 'border-0 border-t border-[#E6E6E6] border-solid' : ''} flex flex-row items-center`}>
                    <Link to={`/plant-information/equipmentAttachments/${option.plantEquipmentId}`} onClick={onLinkClick}>
                      <img src={fileTab} className="pencil" alt="" />
                    </Link>
                    <span className="text-dark-blue font-bold">{option.equipmentAttachmentCount > 0? option.equipmentAttachmentCount : '-'}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p style={{ marginTop: "0" }}>No equipment to display</p>
      )}

      {button}
    </>
  );
}

export default PlantSiteTable;
