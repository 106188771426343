import axios, { AxiosResponse } from "axios";
import { MutationFunction, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { apiPortalContactUrl } from "../endpoints/endpoints";
import { useContext } from 'react';
import { Store } from "../Store";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, PortalContact, Link } from "../utils/interface";


declare type GetAllContactsParameters = ['contacts', {selectedUsersGroupId: number}];
const getAllContacts = async (): Promise<PortalContact[]> => {
    const response: AxiosResponse<CPApiResponse<PortalContact[]>> = await axios(`${apiPortalContactUrl}/List`);
    const user = handleAxiosResponse(response);
    return user;
};
export const useGetAllContacts = (options: Omit<UseQueryOptions<PortalContact[], unknown, PortalContact[], GetAllContactsParameters>, "queryKey" | "queryFn"> = {}) => {
    const {selectedUserGroup} = useContext(Store);
    return useQuery<PortalContact[], unknown, PortalContact[], GetAllContactsParameters>(['contacts', {selectedUsersGroupId: selectedUserGroup.id}], getAllContacts, options);
};

const createContact: MutationFunction<PortalContact, PortalContact> = async (contact: PortalContact): Promise<PortalContact> => {
    const response: AxiosResponse<CPApiResponse<PortalContact>> = await axios(apiPortalContactUrl, {
        method: 'POST',
        data: {
            firstName: contact.firstName,
            lastName: contact.lastName,
            role: contact.role,
            email: contact.email,
            displayOrder: contact.displayOrder,
            company: contact.company,
            jobTitle: contact.jobTitle,
            secondaryPhone: contact.secondaryPhone,
            primaryPhone: contact.primaryPhone,
            userGroupId: contact.userGroupId,
            primaryPhoneTypeId: contact.primaryPhoneTypeId,
            secondaryPhoneTypeId: contact.secondaryPhoneTypeId,
        }
    });
    return handleAxiosResponse(response);
};

export const useCreateContact = (options: Omit<UseMutationOptions<PortalContact, unknown, PortalContact>, 'mutationFn'> = {}) => {
    return useMutation(createContact, options)
};

const updateContact: MutationFunction<PortalContact, PortalContact> = async (data: PortalContact): Promise<PortalContact> => {
    const response: AxiosResponse<CPApiResponse<PortalContact>> = await axios(`${apiPortalContactUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};

export const useUpdateContact = (options: Omit<UseMutationOptions<PortalContact, unknown, PortalContact>, 'mutationFn'> = {}) => {
    return useMutation(updateContact, options)
};


const deleteContact: MutationFunction<boolean, number> = async (id: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiPortalContactUrl}/${id}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};

export const useDeleteContact = (options: Omit<UseMutationOptions<boolean, unknown, number>, 'mutationFn'> = {}) => {
    return useMutation(deleteContact, options)
};

