import tableClasses from "./DisplayLikeTable.module.css";
import classes from "./Triggers.module.css";
import { EmailTrigger } from "../../../utils/interface";

type EmailTriggerOperationNameMap = {
	[Property in EmailTrigger["operationTypeName"]]: string
};

function ModuleNotificationTriggers({
	moduleNotificationTriggers,
	notificationTriggers,
	setNotificationTriggers,
}: {
	moduleNotificationTriggers: Array<EmailTrigger>;
	notificationTriggers: Array<EmailTrigger>;
	setNotificationTriggers: React.Dispatch<React.SetStateAction<Array<EmailTrigger>>>;
}) {
	const handleChange = (newTriggerValue: boolean, targetProperty: string, updatedTrigger: EmailTrigger) => {
		setNotificationTriggers(
			notificationTriggers.map((trigger: any) => ({
				...trigger,
				[targetProperty]: trigger.emailTriggerId === updatedTrigger.emailTriggerId ? newTriggerValue : trigger[targetProperty],
			}))
		);
	};

	const operationTypeNames: EmailTriggerOperationNameMap = {
		Add: 'Added',
		Delete: 'Deleted',
		Edit: 'Edited',
		Reply: 'Replied'
	};

	return (
		<div className={`${tableClasses.table} ${classes.triggers}`}>
			<div>
				<div>
					<div>Alert When:</div>
					<div>Internal Users</div>
					<div>External Users</div>
				</div>
			</div>
			<div>
				{moduleNotificationTriggers
					.filter(d => d.operationTypeName === 'Add')
					.sort((a, b) => (a.operationTypeId > b.operationTypeId ? 1 : -1))
					.map((notificationTrigger, index) => (
						<div key={index}>
							<div>{notificationTrigger.operationTypeName ? `Content is ${operationTypeNames[notificationTrigger.operationTypeName]}`  : <span>Op. ID: {notificationTrigger.operationTypeId} (nameless)</span>}</div>
							<div>
								<input type="checkbox" checked={notificationTrigger.forInternalUsers} onChange={(e) => handleChange(e.target.checked, "forInternalUsers", notificationTrigger)} />
							</div>
							<div>
								<input type="checkbox" checked={notificationTrigger.forExternalUsers} onChange={(e) => handleChange(e.target.checked, "forExternalUsers", notificationTrigger)} />
							</div>
						</div>
					))
				}
			</div>
		</div>
	);
}

export default ModuleNotificationTriggers;
