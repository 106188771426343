import { FunctionComponent, ReactNode } from "react";
import { AuthorizationContext } from "./AuthorizationContext";

interface UnauthorizeTemplateProps {
    children: ReactNode;
}

export const UnauthorizedTemplate: FunctionComponent<UnauthorizeTemplateProps> = ({children}) => (
    <AuthorizationContext.Consumer>
        {({hasPortalAccess}) => !hasPortalAccess && children}
    </AuthorizationContext.Consumer>
);
