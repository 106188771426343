import { DiscussionInterface, Report } from "../../utils/interface";

import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { MouseEventHandler, useContext, useId, useState } from "react";
import classes from "./Reports.module.css";
import ImageImports from "../../utils/ImageImports";

import { Store } from "../../Store";
import { ColumnDefinition } from "./Discussion";


interface ReportRowTableProps {
  data: DiscussionInterface;
  columnDefinitions: ColumnDefinition[];
  tooltipActions: tooltipActionItem[];
  unread: boolean;
}

function DiscussionRowTable({ data, columnDefinitions, tooltipActions,  unread }: ReportRowTableProps) {
  const { ellipsis, tooltip: tooltipImage } = ImageImports;
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const Tooltip = () => {
    return (
      <div
        className="row-item text-l cursor-pointer"
        style={{ color: "#00749E" }}
        onBlur={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(false);
          }
        }}
        tabIndex={1}
        onClick={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(true);
            setToolTipContent && setToolTipContent(<>Tooltip data</>);
          }
        }}
      >
        <strong>View</strong>
      </div>
    );
  };

  return (
    <tr className={unread ? classes.unread : ""}>    
      {columnDefinitions.map((columnDefinition) => (
        <td key={columnDefinition.key} className={`${classes.data_point} ${columnDefinition.className}`} style={columnDefinition.style}>
          {columnDefinition.component ? (
            <columnDefinition.component report={data} tooltip={<Tooltip />} />
          ) : (
            <div>
              <>{data[columnDefinition.key]}</>
            </div>
          )}
        </td>
      ))}
      {tooltipActions.length > 0 && (
        <td>
          <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
            <img src={ellipsis} alt="More Actions" />
            <BulkActionTooltip shown={isTooltipVisible} parentId={tooltipId} setShown={setIsTooltipVisible} listItems={tooltipActions} data={[data]} isDeleted={data.isDeleted} />
          </div>
        </td>
      )}
    </tr>
  );
}

export default DiscussionRowTable;
