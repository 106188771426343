import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ChangeEventHandler, useId, useRef } from "react";
import { toBase64 } from "../../../../utils/FileUtils";

import ImageImports from "../../../../utils/ImageImports";
import { ImagePayload } from "../nodes/ImageNode";
import { INSERT_IMAGE_COMMAND } from "../Plugins/ImagePlugin";
const { attachment } = ImageImports;

interface IToolbarImageUploadProps {
    addFile?: (attachment: File) => void;
}

const ToolbarImageUpload = ({ addFile }: IToolbarImageUploadProps) => {
    const [editor] = useLexicalComposerContext();
    const id = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const attachFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
        const el = e.currentTarget;
        if (el.files) {
            const item = el.files.item(0);
            if (item && addFile) {
                addFile && addFile(item);
            } else if (item && addFile === undefined) {
                const src = await toBase64(item)
                const image: ImagePayload = {
                    altText: item.name,
                    src: src,
                }
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, image);
            }
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    }

    return (<>
        <button onClick={() => inputRef.current?.click()} className={`toolbar-item`} aria-label="Upload File">
            <img src={attachment} alt="Upload File" className={"icon"} style={{ height: '80%' }} />
            <input ref={inputRef} id={id} type="file" style={{ display: "none" }} onChange={attachFile} />
        </button>
    </>
    );
};

export default ToolbarImageUpload;
