import React, { useContext, useState } from "react";
import { AxiosError } from "axios";
import { Store } from "../../../Store";
import { CPApiResponse, KPI } from "../../../utils/interface";
import classes from "./ESR.module.css";
import { useQuery_AllPlantsKPIs, useQuery_PlantKPIs } from "./ESRHttpServices";
import KPIsContainer from "./KPIsContainer";

const KPITabs = ({ setErrorData }: { setErrorData: (errorData: any) => void }) => {
	const { selectedPlant } = useContext(Store);

	const [KPITabIndex, setKPITabIndex] = useState(0);
	const [plantKPIs, setPlantKPIs] = useState<KPI>();
	const [allPlantKPIs, setAllPlantKPIs] = useState<KPI>();

	const onSuccessPlantKPIs = ({ data }: CPApiResponse<KPI>) => !plantKPIs && setPlantKPIs(data);
	const onSuccessAllPlantKPIs = ({ data }: CPApiResponse<KPI>) => !allPlantKPIs && setAllPlantKPIs(data);
	const onError = (error: AxiosError<string>) => setErrorData(error);

	useQuery_PlantKPIs(selectedPlant.plantId, onSuccessPlantKPIs, onError);
	useQuery_AllPlantsKPIs(onSuccessAllPlantKPIs, onError);
	return (
		<div>
			<div className={`${classes.KPITabs}`}>
				<div onClick={() => setKPITabIndex(0)} className={`${KPITabIndex === 0 ? classes.selected : classes.unselected}`}>
					Brunswick County
				</div>
				<div onClick={() => setKPITabIndex(1)} className={`${KPITabIndex === 1 ? classes.selected : classes.unselected}`}>
					All Customers
				</div>
			</div>
			{KPITabIndex === 0 ? <KPIsContainer KPI={plantKPIs} /> : KPITabIndex === 1 && <KPIsContainer KPI={allPlantKPIs} />}
		</div>
	);
};

export default KPITabs;
