import { AxiosError } from 'axios';
import { QueryClient } from 'react-query';
import { useOnError } from '../customHooks/useOnError';

export function GenerateQueryClient(): QueryClient {
  // const setErrorData = useOnError();
  return new QueryClient({
    defaultOptions: {
      queries: {        
        onError: (error:AxiosError<Error> | unknown) => {
          // setErrorData(error);
        },
        retry:false,
        // staleTime: 30000, 
        // cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime        
        // refetchOnMount: false,
        refetchOnWindowFocus: false,
        // refetchOnReconnect: false,
      },
      mutations: {
        onError: (error:AxiosError<Error> | unknown) => {
          // setErrorData(error);
        },
      },
    },
  });
}

export const queryClient = GenerateQueryClient();








