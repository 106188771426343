import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import Link from "../../components/Link/Link";
import { PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import useWindowSize from "../../customHooks/useWindowSize";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { DocumentGroup } from "../../utils/interface";
import Reports from "../Reports/Reports";
import classes from "./Documents.module.css";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";

function DocumentsGroupContainer() {
  const outletContext = useOutletContext<DocumentsModuleOutletInterface>();
  const { documentModule, documentGroups } = outletContext;
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '');
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [selectedReportType, setSelectedReportType] = useState<DocumentGroup>();

  const handleGroupType = (group: DocumentGroup) => {
    setSelectedReportType(group);
    navigate(`../${group.id}`);
  };

  useEffect(() => {
    setSelectedReportType(documentGroups.find(d => d.id === groupId));
  }, [])

  useEffect(() => {
    if ((documentGroups.length > 0 && documentGroups.findIndex((g) => g.id === groupId) === -1)) {
      navigate(`../${documentGroups[0].id}`);
      
    }
  }, [groupId, documentGroups]);

  const sortGroupFn = useMemo(() => {
    return getSortObjectsByKeyFn<DocumentGroup>('displayOrder', 'asc');
  }, []);

  let documentGroupSelection: ReactElement = <></>;
  if (documentGroups) {
    if (isMobile || isTablet) {
      documentGroupSelection = (
        <div className={classes.flex_column}>
          Report Type
          <div className="borderDropdown">
            <PlainDropDown onSelection={handleGroupType}
                           options={documentGroups}
                           value={selectedReportType?.id}
                           className={`relative`}
                           selectClass={`flex flex-row items-center border border-solid border-[#999] rounded pt-1 pr-2 !pb-1 pl-4 text-sm justify-between`}
                           optionsClass="flex flex-col flex-nowrap p-1 bg-white rounded max-h-80 !overflow-y-scroll"
                           dropdownStyles={{overflowY: 'auto'}}
                           itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20 w-full" />
          </div>          
        </div>
      );
    } else {
      documentGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {documentGroups.sort(sortGroupFn).map((documentGroupItem) => (
            <Link
              className={`${classes.document_group_link} ${groupId === documentGroupItem.id ? classes.active : ""}`}
              key={documentGroupItem.id}
              to={`../${documentGroupItem.id}`}
            >
              {documentGroupItem.name}
            </Link>
          ))}
        </div>
      );
    }
  }

  return (
	<div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
	{documentGroupSelection}
		<Reports documentModule={documentModule} documentGroups={documentGroups} pagePermissions={outletContext.pagePermissions} setPagePermissions={outletContext.setPagePermissions} />
		<Outlet context={outletContext} />
	</div>
  );
}

export default DocumentsGroupContainer;
