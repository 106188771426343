import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalSearch } from "../../utils/interface";
import PaginationBasic from "../UI/Pagination/PaginationBasic/PaginationBasic";
import SearchItem from "./SearchItem/SearchItem";
import classes from "./SearchItems.module.css";

interface SearchItemsProps {
  filteredItems: GlobalSearch[];
  paginationData: {
    totalPosts: number;
    perPagePost: number;
    postsPerPage: number;
    currentPage: number;
    paginate(): void;
    next_paginate(): void;
    prev_paginate(): void;
  };
}
const SearchItems = ({ filteredItems, paginationData }: SearchItemsProps) => {
  return (
    <div className={`${classes.searchItemWrapper}`}>
      {filteredItems && filteredItems.length > 0 ? (
        filteredItems.map((searshResult, i) => (
          <Link key={searshResult.fileId} to={`/shared-document/${searshResult.fileId}`} className={classes.searchItem} target="_blank">
            <SearchItem searchItem={searshResult} />
          </Link>
        ))
      ) : (
        <div className="card" style={{ padding: "24px" }}>
          Apologies, we couldn’t find any matches for that keyword. Please check your spelling or try a new search.
        </div>
      )}

      {filteredItems.length > 0 && (
        <div className={`col-to-row font14 ${classes.paginationWrapper}`}>
          <div className={`row-item ${classes.rowItem}`}>
            <div className="inline-flex">
              Displaying {Math.min(paginationData.postsPerPage, 
                paginationData.totalPosts - (paginationData.currentPage-1)*paginationData.postsPerPage)} of {paginationData.totalPosts} Results
            </div>
          </div>
          <div className={`${classes.paginationFlex} align-center`}>
            <PaginationBasic
              totalPosts={paginationData.totalPosts}
              postsPerPage={paginationData.postsPerPage}
              currentPage={paginationData.currentPage}
              paginate={paginationData.paginate}
              next_paginate={paginationData.next_paginate}
              prev_paginate={paginationData.prev_paginate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchItems;
