
import { InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Outlet } from "react-router-dom";
import { NoPortalAccess } from "../../containers/PortalAccess/NoPortalAccess";
import PortalAccessRequest from "../../containers/PortalAccess/PortalAccessRequest";
import { scopes } from "../../utils/Auth/msalConfig";
import { AuthorizedTemplate } from "./AuthorizedTemplate";
import { UnauthorizedTemplate } from "./UnauthorizedTemplate";


export const PrivateRoutes = () => {
    // this starts the authentication process
    useMsalAuthentication(InteractionType.Redirect, {
        scopes,
        redirectUri: '/'
    });
    return <AuthenticatedTemplate>
        <AuthorizedTemplate>
            <Outlet />
        </AuthorizedTemplate>
        <UnauthorizedTemplate>
            <NoPortalAccess />
        </UnauthorizedTemplate>
    </AuthenticatedTemplate>;
};