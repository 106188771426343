import { useContext } from "react";
import { Store } from "../../Store";
import { PlantConfigData, TypeDTO, UnitGetAllDTO } from "../../utils/interface";
import { AxiosError } from "axios";
import { useGetAll_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import {
  useApiUnitConfigurationTypes,
  useApiGetManuFacturerTypes,
  useApiPlantFuelType,
  useApiProductType,
  useApiEquipmentModelNameTypes,
} from "../../containers/PlantInformation/TypesHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { useSelectedPlant } from "../../customHooks/useSelectedPlant";
import { useGetPlantConfig } from "../../customHooks/PlantHttpServices";

const usePlantSiteDetailsApiCalls = () => {
  const {
    setUnitConfigurationTypes,
    setEquipmentModelNameTypes,
    setProductType,
    setPlantConfig,
    setFuelType,
    setManufacturerType,
    setUnitGetAll,
    unitGetAll
  } = useContext(Store);
  const setErrorData = useOnError();

  const {selectedPlant} = useSelectedPlant();

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData Start//
  ///////////////////////////////////////////

  const onSuccessPlantConfig = (data: PlantConfigData) => {
    setPlantConfig && setPlantConfig({
      message: '',
      permissions: 0,
      success: true,
      data
    });
  };

  useGetPlantConfig((selectedPlant?.plantId || 0), {
    onSuccess: onSuccessPlantConfig
  });
  

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType Start
  ///////////////////////////////////////////

  const onSuccess = (data: { data: TypeDTO }) => {
    setFuelType && setFuelType(data?.data);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiPlantFuelType(onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}
  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType Start
  ///////////////////////////////////////////

  const onSuccessUnitData = (data: UnitGetAllDTO) => { 
    setUnitGetAll && setUnitGetAll(data);
  };
  const onErrorUnitData = (error: AxiosError<string>) => {
    setErrorData(error);
    setUnitGetAll &&
    setUnitGetAll({
        ...unitGetAll,
        data: [],
      });
  };
  useGetAll_UnitData((selectedPlant?.plantId || 0), onSuccessUnitData, onErrorUnitData);


  ///////////////////////////////////////////
  // Fetch useApiPlantFuelType End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiManufacturerGetAll Start
  ///////////////////////////////////////////

  const onSuccessManufacturerGetAll = (data: TypeDTO) => {
    setManufacturerType && setManufacturerType(data);
  };
  const onErrorManufacturerGetAll = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiGetManuFacturerTypes(onSuccessManufacturerGetAll, onErrorManufacturerGetAll);
  ///////////////////////////////////////////
  // Fetch useApiManufacturerGetAll End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiProductType Start
  ///////////////////////////////////////////

  const onSuccessProductType = (data: TypeDTO) => {
    setProductType && setProductType(data);
  };
  const onErrorProductType = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useApiProductType(onSuccessProductType, onErrorProductType);

  ///////////////////////////////////////////
  // Fetch useApiProductType End
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessUnitConfigTypes = (data: TypeDTO) => {
    setUnitConfigurationTypes && setUnitConfigurationTypes(data);
  };
  const onErrorUnitConfigTypes = (error: any) => {
    setErrorData(error);
  };

  useApiUnitConfigurationTypes(onSuccessUnitConfigTypes, onErrorUnitConfigTypes);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessFuelType = (data: TypeDTO) => {
    setFuelType && setFuelType(data);
  };
  const onErrorFuelType = (error: any) => {
    setErrorData(error);
  };

  useApiPlantFuelType(onSuccessFuelType, onErrorFuelType);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  ///////////////////////////////////////////
  // Fetch Types Start
  ///////////////////////////////////////////

  const onSuccessEquipmentModelName = (data: TypeDTO) => {
    setEquipmentModelNameTypes && setEquipmentModelNameTypes(data);
  };
  const onErrorEquipmentModelName = (error: any) => {
    setErrorData(error);
  };

  useApiEquipmentModelNameTypes(onSuccessEquipmentModelName, onErrorEquipmentModelName);

  ///////////////////////////////////////////
  // Fetch Types End
  ///////////////////////////////////////////

  return null;
};

export default usePlantSiteDetailsApiCalls;
