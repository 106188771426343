import classes from "./ListApiMethods.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import useWindowSize from "../../customHooks/useWindowSize";

interface HeaderButtonsInterface {
  addButtonText: string;
  customClass: string;
  addButtonClass: string;
  manageButtonClass: string;
}

function HeaderButtons(props: HeaderButtonsInterface) {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  return (
    <div className={`${classes.header_buttons} ${isMobile ? classes.flex_column : classes.flex_row} ${props.customClass}`}>
      <Button
        className={`darkBlue add-button ${props.addButtonClass}`}
        text={props.addButtonText}
        onClick={() => navigate("./add")}
        style={{ order: isMobile || isTablet ? "-1" : "unset" }}
      />
    </div>
  );
}

HeaderButtons.defaultProps = {
  addButtonText: "Add Document",
  customClass: "",
  addButtonClass: "",
  manageButtonClass: "",
};

export default HeaderButtons;
