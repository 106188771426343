import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import { updateAccountSettings } from "../../containers/PlantInformation/DashboardHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import { HeaderData } from "../../utils/interface";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Navbar.module.css";

function AccountSettingsModal({ headerData, closeModal }: { headerData: HeaderData; closeModal: () => void }) {
	const { mobileNavToggle, toggleMobileNav } = useStore();
	const {
		setAccountSettings,
		accountSettings: { data: accountSettings },
		setShowError,
	} = useContext(Store);

	const [defaultPlant, setDefaultPlant] = useState<{id: number, name: string}>();
	const [optedOut, setOptedOut] = useState<boolean>(false);

	const { isDesktop, isTablet } = useWindowSize();

	const plants = useMemo(() => {
		return headerData.user.plants.map((p) => ({ id: p.baseOrgId, name: p.name }));
	}, [headerData]);

	useEffect(() => {
		setOptedOut(accountSettings.optedOut);
		setDefaultPlant(plants.find(p => p.id === accountSettings.defaultBaseOrgId));
	}, [accountSettings]);

	const saveSettings = async () => {
		setAccountSettings?.(await updateAccountSettings({ ...accountSettings, defaultBaseOrgId: (defaultPlant?.id || 0), optedOut: optedOut }));
		setShowError?.({
			isError: true,
			title: "Your account settings have been successfully updated.",
			ErrorType: "success",
		});

		if (!isDesktop) {
			toggleMobileNav && toggleMobileNav(!mobileNavToggle);
		}

		closeModal();
	};

	const modalSize = useMemo(() => {
		if (isDesktop) return 'md';
		return 'full';
	}, [isDesktop]);

	return (
		<ModalGlobal modalSize={modalSize} modaltitle={`Account & Settings`} isModalHeader={true} modalClose={closeModal}>
			<div className={`${classes.accountModalBody}`}>
				<div>
					<strong>Account{!isDesktop && ' Details'}</strong>
				</div>
				<div className={`${classes.accountModalCard}`}>
					<div className="nav-item login-details" style={{ justifyContent: "flex-start" }}>
						<div className="initials">
							<img src={headerData.user.pictureUrl} alt="" />
						</div>
						<div>
							<div className="name">
								{accountSettings.fName} {accountSettings.lName}
							</div>
						</div>
					</div>
					<div className={`mt-4 flex gap-1 ${isTablet? 'flex-row' : 'flex-col'} ${!isDesktop? 'ml-12' : ''}`}>
						{!isDesktop && <strong>E-mail:</strong>}
						<span>{accountSettings.email}</span>
					</div>
					<div className={`mt-4 ${!isDesktop? 'ml-12' : ''}`}>
            If you have any questions or difficulties updating your information, <a href={`mailto:ugportaladmin@amer.mhps.com`} className="no-underline text-ocean">contact</a> the Users' Group Administrator for assistance.
					</div>
				</div>
				<div style={{ marginTop: 40 }}>
					{isDesktop && <strong>Settings</strong>}
					<form>
						<div className={`${isDesktop ? classes.settingsFormForDesktop : classes.settingsFormForTabletAndMobile}`}>
							<div>
								<div>Default Plant Site</div>
								<div className={`w-full`}>
									<PlainDropDown selectClass={`flex flex-row items-center border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between ${isDesktop? 'border-0 border-b' : 'border rounded'} ${isTablet? 'w-1/2' : 'w-full'}`}
													optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
													dropdownStyles={{overflow: 'auto'}}
													itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
													options={plants}
													onSelection={setDefaultPlant}
													value={defaultPlant?.id || false}
													defaultText="Select" />
								</div>
							</div>
							<div style={{ marginTop: isDesktop ? 0 : 20 }}>
								<div>Email Settings</div>
								<div>
									<label>
										<input type="checkbox" checked={!optedOut} onChange={() => setOptedOut(!optedOut)} /> Opt In to All Email Alerts
									</label>
								</div>
							</div>
						</div>
						<div className={`${isDesktop ? classes.flex_row : ""}`} style={{ margin: "30px 0px 0px 0px" }}>
							<Button
								className={`darkBlue`}
								disabled={!defaultPlant?.id || (defaultPlant?.id === accountSettings?.defaultBaseOrgId && optedOut === accountSettings.optedOut)}
								text={"Save"}
								onClick={saveSettings}
							/>
							{isDesktop && <>&nbsp;&nbsp;&nbsp;</>}
							<Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={closeModal} />
						</div>
					</form>
				</div>
			</div>
		</ModalGlobal>
	);
}
export default AccountSettingsModal;
