import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { apiEmailTriggerUrl } from "../endpoints/endpoints";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, EmailTrigger } from "../utils/interface";



interface EmailTriggerCrudMethods<T1> {
	get: () => Promise<EmailTrigger[]>;
	update: (data: EmailTrigger[]) => Promise<EmailTrigger[]>;
}

export const EmailTriggerApi: EmailTriggerCrudMethods<CPApiResponse<EmailTrigger|EmailTrigger[]|boolean>> = {
	get: async (): Promise<EmailTrigger[]> => {
		const response = await axios({
			url: `${apiEmailTriggerUrl}/GetAllEmailTriggers`,
			method: "GET",
		});
		
		return response.data.data;
	},
	update: async (data: EmailTrigger[]): Promise<EmailTrigger[]> => {
		const response = await axios({
			url: `${apiEmailTriggerUrl}/UpdateAllEmailTriggers`,
			method: "PUT",
			data: data,
		});

		return handleAxiosResponse(response);
	}
};

export const useGetAllEmailTriggers = () => {
    return useQuery(['getEmailTriggers'], () => EmailTriggerApi.get());
};

export const useUpdateEmailTriggers = () => {
    return useMutation(EmailTriggerApi.update);
};