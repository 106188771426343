import parseHtml from "html-react-parser";
import React, { useContext, useEffect, useId, useMemo, useState } from "react";
import Moment from "react-moment";
import { Store } from "../../../Store";
import TextAvatar from "../../../components/Avatar/TextAvatar";
import Button from "../../../components/Button/Button";
import EmptyModal from "../../../components/Modals/EmptyModal";
import BulkActionTooltip from "../../../components/PopoutTooltip/BulkActionTooltip";
import Editor from "../../../components/UI/RichTextEditor/Editor";
import { useOnError } from "../../../customHooks/useOnError";
import useWindowSize from "../../../customHooks/useWindowSize";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import ImageImports from "../../../utils/ImageImports";
import { UGMessage } from "../../../utils/interface";
import { useDelete_UGThreadMessage, useUpdate_UGThreadMessage } from "../discussionApiMethod";

const Message = ({ msg }: MessageInterface) => {
  const { ellipsis, trash, file_edit } = ImageImports;
  const { header } = useContext(Store);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [isSameUser, setIsSameUser] = useState<boolean>(false);
  const [showEditSuccess, setShowEditSuccess] = useState<boolean>(false);
  const [showDeleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string|null>(null);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const [editMessage, setEditMessage] = useState<string>();

  useEffect(() => {
    const isUserValid = header.data.user.userId === msg.createdById;
    setIsSameUser(isUserValid);
  }, [header]);

  const { mutate: deleteMessage } = useDelete_UGThreadMessage();
  const tooltipActions = [];

  if ((isSameUser || header.data.user.portalAdmin) && !msg.isDeleted) {
    if (isSameUser) {
      tooltipActions.push({
        text: "Edit",
        img: file_edit,
        onClick: (e: React.MouseEvent, data?: any) => {
          setEditMessage(msg.content);
          setErrorMsg(null);
        }
      });
    }
    tooltipActions.push({
      text: "Delete Post",
      img: trash,
      onClick: (e: React.MouseEvent, data?: any) => {
        setDeleteConfirm(true);        
      },
    });
  }

  const handleDelete = async () => {
    await deleteMessage(msg.id, {
      onSuccess() {
        setDeleteConfirm(false);
        setShowDeleteSuccess(true);
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  const setErrorData = useOnError();
  const { mutate: UpdateUGThreadMessage } = useUpdate_UGThreadMessage();
  const handleUpdateMessage = async (content: string) => {
    await UpdateUGThreadMessage(
      { id: msg.id, content },
      {
        onSuccess: () => {
          setEditMessage(undefined);
          setShowEditSuccess(true);
          setErrorMsg(null);
        },
        onError: (error: any) => {
          //setErrorData(error);
          setErrorMsg("There was an error updating your message. Please try again.");
        },
      }
    );
  };

  const handleClose = () => {
    queryClient.invalidateQueries([queryKeys.discussionGroups]);
    setShowEditSuccess(false);
  }

  const handleDeleteSuccessClose = () => {
    queryClient.invalidateQueries([queryKeys.discussionGroups]);
    setShowDeleteSuccess(false);
  }

  const tooltipId = useId().replaceAll(":", "_");
  return (
    <div className={`flex flex-col gap-6 p-6 pt-[16px] w-full !border border-solid !border-gray-10 rounded ${msg.isDeleted ? 'opacity-50' : 'opacity-100'}`}>
      {editMessage && <EditMessage initialContent={editMessage} onClose={() => setEditMessage(undefined)} onSave={handleUpdateMessage} errorMsg={errorMsg} />}
      {showEditSuccess && <EditSuccess onClose={handleClose} />}
      {showDeleteConfirm && <DeletePostConfirmation onClose={() => setDeleteConfirm(false)} onDelete={handleDelete} msg={msg} />}
      {showDeleteSuccess && <DeleteSuccess onClose={handleDeleteSuccessClose}  msg={msg} />}
      <div className="flex flex-row items-start md:items-center justify-between w-full !pb-0">
        <div className="flex flex-row items-start md:items-center justify-start gap-3 !pb-0">
          <TextAvatar userName={msg.createdBy} className="w-[24px] h-[24px] text-[9.6px]" />
          <div className="flex flex-col md:flex-row gap-3 items-start md:items-center grow !pb-0">
            <div className="text-black whitespace-nowrap">{msg.createdBy}</div>
            <span className="w-[5px] h-[5px] bg-center bg-gray-30 rounded-full border-none hidden md:inline-block" />
            <Moment className="text-gray-50 text-sm whitespace-nowrap -ml-8 md:-ml-0" date={msg.created} format="MM/DD/YYYY , h:mm A" />
          </div>
        </div>
        <div className="flex flex-row items-start md:items-center justify-end grow-0">
          {( tooltipActions.length > 0) && (
            <div id={tooltipId} className="flex flex-row justify-end grow-0 hover:cursor-pointer" onClick={showTooltip}>
              <img src={ellipsis} alt="More Actions" />
              <BulkActionTooltip
                shown={isTooltipVisible}
                parentId={tooltipId}
                setShown={setIsTooltipVisible}
                listItems={tooltipActions}
                displayRight={true}
                data={[msg]}
              />
            </div>
          )}
        </div>
      </div> 
      <div className="mx-0 md:mx-8 text-black text-sm">
        {msg.content && parseHtml(msg.content)}
      </div>
    </div>
  );
};

interface MessageInterface {
  msg: UGMessage;
}
export default Message;

const EditMessage = ({initialContent, errorMsg, onClose, onSave}: {initialContent: string, errorMsg: string|null, onClose: () => void, onSave: (content: string) => void}) => {
  const maxCharacterCount = 2000;
  const [content, setContent] = useState<string>();
  const [maxCharacterError, setMaxCharacterError] = useState<boolean>(false);
  const {isDesktop} = useWindowSize();

  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const handleContentChange = (val: string) => {
    setMaxCharacterError(false);
    if (val.length > maxCharacterCount) {
      setMaxCharacterError(true);
    }
    if (val !== content) {
      setContent(val);
    }
  }

  const width = useMemo(() => {
    let width = 'max-w-[820px]';
    if (!isDesktop) {
      width = 'w-11/12';
    }
    return width;
  }, [isDesktop]);

  return <EmptyModal 
    body={
      <>
        <Editor handleChange={handleContentChange} content={initialContent} />
        {(maxCharacterError || errorMsg) && (
        <div className="flex align-center pb-8 !mt-[-40px]">
          <div className="text-error-800">
            {errorMsg 
            ? <>{errorMsg}</>
            : <>Message currently exceeds the size limit of {maxCharacterCount} characters (including formatting characters not shown on screen).</>
            }
          </div>
        </div>
      )}
      </>
    } 
    footer={
      <div className="flex gap-4">
        <Button className="darkBlue" text="Save Changes" disabled={content === initialContent || !content?.length || content === '<p class="editor-paragraph"><br></p>' ? true : false || maxCharacterError} onClick={() => onSave(content || '')} />
        <Button className="whiteBtn" text="Cancel" onClick={onClose} />
      </div>
    } 
    heading="Edit Discussion Post" 
    onClose={onClose} 
    shown={true} 
    className={width} 
    footerClass="mt-[-60px]" />
};


const EditSuccess = ({onClose}: {onClose: () => void}) => {
  return (
    <EmptyModal 
      body={(
        <span className="self-center">Your discussion post has been successfully updated.</span>
      )} 
      footer={(
        <div className="flex flex-row !pb-0 gap-4 !justify-center">
          <Button className="darkBlue" text="Done" onClick={onClose} />
        </div>
      )} 
      heading="Changes Saved" 
      footerClass="flex flex-row justify-center text-center"
      onClose={onClose} 
      shown={true} 
      className="w-11/12 md:w-full max-w-[564px]" 
      headingClass="pl-16 text-center"
    />
  );
};

const DeletePostConfirmation = ({msg, onClose, onDelete}: {msg: UGMessage, onClose: () => void, onDelete: () => void}) => {
  return (
    <EmptyModal 
      body={(
        <div className="flex flex-col gap-6 !pb-0">
          <div>Are you sure you want to delete this post from the discussion for "{msg.title}"? This will also delete any attached documents. This cannot be undone.</div>
          <div className="text-sm text-grey">Post from {msg.createdBy}</div>
        </div>
        
      )}
      footer={(
        <div className="flex flex-row !pb-0 gap-4">
            <Button className="redDelete" text="Yes, Delete" onClick={onDelete} />
            <Button className="whiteBtn" text="Cancel" onClick={onClose} />
        </div>
      )}
      heading="Delete Post"
      onClose={onClose} shown={true} width={564} 
    />
  );
};

const DeleteSuccess = ({msg, onClose}: {msg: UGMessage, onClose: () => void}) => {
  return (
    <EmptyModal 
      body={(
        <span className="self-center text-center">You successfully deleted the post from the discussion for “{msg.title}”.</span>
      )} 
      footer={(
        <div className="flex flex-row !pb-0 gap-4 !justify-center">
          <Button className="darkBlue" text="Done" onClick={onClose} />
        </div>
      )} 
      heading="Post Successfully Deleted" 
      footerClass="flex flex-row justify-center text-center"
      onClose={onClose} 
      shown={true} 
      className="w-11/12 md:w-full max-w-[564px]" 
      headingClass="pl-0 md:pl-16 text-center"
    />
  );
};