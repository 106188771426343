import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { string } from "yup";
import { Store } from "../../../Store";
import Alert from "../../../components/Alert/Alert";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import EmptyModal from "../../../components/Modals/EmptyModal";
import PlantSiteDetailsTop from "../../../components/PlantSiteDetails/PlantSiteDetailsTop/PlantSiteDetailsTop";
import PlantSiteTable from "../../../components/Tables/PlantSiteTable";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import { useAddUserGroupToBaseOrg, useGetBaseOrgUserGroups, useGetPlantConfig, useRemoveUserGroupFromBaseOrg } from "../../../customHooks/PlantHttpServices";
import { useGetUsersGroups } from "../../../customHooks/UserGroupHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { usePageRequiresInternalAdmin } from "../../../customHooks/usePageRequiresInternalAdmin";
import useWindowSize from "../../../customHooks/useWindowSize";
import ImageImports from "../../../utils/ImageImports";
import { BaseOrgGroupDTO, BaseOrgGroupUserGroup, PlantConfigData, PlantEquipmentsDATA, PlantEquipmentsDTO, UnitDATA, UnitGetAllDTO } from "../../../utils/interface";
import { pick } from "../../../utils/pick";
import { useGetAllByUnitID_PlantEquipments } from "../../PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../../PlantInformation/UnitHttpServices";
import classes from './UserManagementHeader.module.css';

interface ConfirmRemoveGroupAssociationModalProps {
    props: {
        shown: boolean;
        index: number;
        plantName?: string;
        userGroupName?: string;
        operation: any;
    }
}

export const ConfirmRemoveGroupAssociationModal = (props: ConfirmRemoveGroupAssociationModalProps) => {
    const { isMobile } = useWindowSize();
    const { shown, plantName, userGroupName, operation } = props.props;

    let width: number = 564;
    if (isMobile) {
        width = 300;
    }
    let modalBody: ReactElement | undefined;
    let modalFooter: ReactElement | undefined;
    if (shown) {
        modalBody = (
            <>
                <div className="grid gap-4 grid-cols-1">
                    <span className="text-base">{`Are you sure you want to remove ${plantName}’s association to the ${userGroupName} Users’ Group? This cannot be undone.`}</span>
                </div>
            </>
        );

        modalFooter = (
            <>
                <div className="flex flex-row !pb-0 items-start gap-4">
                    <Button className="redDelete text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text="Yes, Remove" onClick={() => operation({ ...props.props, shown: false, confirm: true })} />
                    <Button className="whiteBtn text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text="Cancel" onClick={() => operation({ ...props.props, shown: false })} />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={shown} body={modalBody} footer={modalFooter} heading="Remove Users' Group Association?" onClose={() => operation({ ...props.props, shown: false })} width={width} footerClass={classes.modal_footer} />
    );
};

function PlantManagement() {
    const { plantConfig, setPlantConfig, } = useContext(Store);
    const [localAssocUserGroups, setLocalAssocUserGroups] = useState<any[]>([]);
    const [associatedUserGroups, setAssociatedUserGroups] = useState<BaseOrgGroupUserGroup[]>([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [unitCount, setUnitCount] = useState<number>(0);
    const [selectedOrgGroups, setSelectedOrgGroups] = useState<number[]>([]);
    const [savedOrgGroups, setSavedOrgGroups] = useState<number[]>([]);
	const [addAnotherOrgGroup, setAddAnotherOrgGroup] = useState<number>();
    const {plantId: urlPlantId} = useParams();
    const [systemDetails, setSystemDetails] = useState<{esrPlantName: string, tomoniDashPlantName: string, spLegacyID: string}>();
    const { Arrow, help } = ImageImports;
    const [modalProps, setModalProps] = useState<{
        index: number;
        shown: boolean;
        plantName: string;
        userGroupName: string;
        operation: any;
        confirm: boolean;
    }>({
        index: 0,
        shown: false,
        plantName: '',
        userGroupName: '',
        operation: undefined,
        confirm: false,
    });
    const plantId = Number(urlPlantId)
    usePageRequiresInternalAdmin();
    const { refetch: refetchUserGroups, data: orgGroups, isFetching: isFetchingUserGroup, isLoading: isLoadingUserGroup } = useGetUsersGroups();
    const { mutateAsync: addUserGroupToBaseOrg, isSuccess: isSuccessAddUserGropup, isLoading: isLoadingAddUserGroup, reset: resetAddUserGroupMutation } = useAddUserGroupToBaseOrg();
    const { mutateAsync: removeUserGroupFromBaseOrg, isSuccess: isSuccessDeleteUserGroup, isLoading: isLoadingRemoveUserGroup, reset: resetRemoveUserGroupMutation } = useRemoveUserGroupFromBaseOrg();

    useGetPlantConfig((plantId), {
        onSuccess: (data: PlantConfigData) => {
            setModalProps({ ...modalProps, plantName: data?.baseOrgName })
            setPlantConfig && setPlantConfig({
                message: '',
                permissions: 0,
                success: true,
                data
            });
        }
    });

    const addRemoveUserGroupBaseOrgData = useMemo(() => {
		return {
			baseOrgId: plantConfig.data.baseOrgId,
			orgGroupId: 0
		};
	}, [plantConfig]);

    const { refetch: refetchAssociatedGroups } = useGetBaseOrgUserGroups((plantConfig.data.baseOrgId), {
        onSuccess: (groups: BaseOrgGroupDTO) => {
            const { baseOrgUserGroups, spLegacyID, tomoniDashPlantName, esrPlantName } = groups;
            setSystemDetails({
                esrPlantName,
                spLegacyID,
                tomoniDashPlantName
            });

            const ogIds = (baseOrgUserGroups || []).map(d => d.userGroupId);
			setSelectedOrgGroups(ogIds);
			setSavedOrgGroups(ogIds);
            return setAssociatedUserGroups(baseOrgUserGroups)
        },
        enabled: plantConfig.data.baseOrgId > 0
    });

    const selectableOrgGroups: DropDownOptionItem[] = useMemo(() => {
		if (orgGroups) {
			return orgGroups.map(d => pick(d, ['id', 'name']));
		}
		return [];
	}, [orgGroups]);

    // list is built of two arrays mapped one after the other 
    // userGroups then a local array the user can build of UserGroups    
    const handleRemoveAssociation =  async (i?: number, local?: boolean) => {
        const index = i || modalProps.index;
        await removeUserGroupFromBaseOrg({
            ...addRemoveUserGroupBaseOrgData,
            userGroupId: associatedUserGroups.find(g => g.userGroupId === savedOrgGroups[index])?.id || 0
        });
        refetchAssociatedGroups();
        refetchUserGroups();
    }

    useEffect(() => {
        if (modalProps.confirm) {
            handleRemoveAssociation();
            setModalProps({ ...modalProps, confirm: false });
        }
    }, [modalProps.confirm]);

    useEffect(() => {
        setLoading(isFetchingUserGroup || isLoadingUserGroup || isLoadingAddUserGroup || isLoadingRemoveUserGroup);
    }, [isFetchingUserGroup, isLoadingUserGroup, isLoadingAddUserGroup, isLoadingRemoveUserGroup]);

    const UserGroupSection = () => {
        return (
            <div className="flex flex-col">
                <p className="font-normal text-sm my-1">Users' Group*</p>
            
                {/* associated groups */}
                {loading ? <Loader /> : <>
                    {selectedOrgGroups?.length > 0 && selectedOrgGroups.map((group, index) => (
                        <div className="flex flex-col md:flex-row !justify-start" key={`user-group-section-${index}`}>
                            <div className="w-full md:w-1/2 lg:w-1/4">
                                <PlainDropDown
                                    value={group}
                                    className=' pr-2 mt-2 pl-2 border border-solid border-[#999] rounded shadow-none'
                                    selectClass={`flex flex-row !p-1 border-0 justify-between border border-solid border-[#999] w-full rounded !shadow-none `}
                                    optionsClass="p-4 bg-white rounded" 
                                    itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                    defaultText="Select a Group"
                                    options={selectableOrgGroups}
                                    // If we want to exlude groups that have already been selected, this is how we would do it
                                    //options={[{ id: 0, name: 'Select User\'s Group' }, ...selectableOrgGroups.filter(d => (!selectedOrgGroups.includes(d.id as number) && (addAnotherOrgGroup !== (d.id as number)) || (group === d.id)))]} 
                                    onSelection={(item: (DropDownOptionItem)) => {
                                        const si = [...selectedOrgGroups];
                                        si.splice(index, 1, item.id as number);
                                        setSelectedOrgGroups(si);
                                    }}
                                />
                            </div>
                            <div className="w-full md:w-1/2 lg:w-3/4">
                                <Button
                                    className="bg-transparent font-bold text-base outline-none focus:outline-none mt-1.5 text-dark-blue !justify-start md:justify-center pl-0 md:pl-6"
                                    onClick={() =>
                                        setModalProps({
                                            ...modalProps,
                                            index: index,
                                            shown: true,
                                            userGroupName: orgGroups?.find(d => d.id === group)?.name || '',
                                            operation: setModalProps
                                        })}
                                    text='Remove Association'
                                />
                            </div>
                        </div>
                    ))}
                                    
                    {/* indices populated by user action - until 'Save' they are separate array */}
                    {addAnotherOrgGroup !== undefined && (
                        <div className="flex flex-col md:flex-row !justify-start">
                            <div className="w-full md:w-1/2 lg:w-1/4">
                                <PlainDropDown 
                                    onSelection={(item: (DropDownOptionItem)) => {
                                        setAddAnotherOrgGroup(item.id as number);
                                    }}
                                    className='pt-1 pr-2 !pb-1 mt-2 pl-4 border border-solid border-[#999] rounded shadow-none'
                                    options={[{ id: 0, name: 'Select User\'s Group' }, ...selectableOrgGroups.filter(d => !selectedOrgGroups.includes(d.id as number))]}
                                    value={addAnotherOrgGroup}
                                    selectClass={`${classes.flex_row} ${classes.select} !shadow-none !p-0`}
                                    optionsClass={`${classes.flex_column} ${classes.select_options}`}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <Button className={`whiteBtn mt-8`} disabled={addAnotherOrgGroup !== undefined} text={`Add to ${selectedOrgGroups.length > 0 ? 'Another ' : ''}Users' Group`} onClick={() => setAddAnotherOrgGroup(0)} />
                    </div>
                </>}
            </div>
        )
    }

    const content = [
        {
            element:
                <React.Fragment key='organization-associations-optional-1'>
                    <div className="flex flex-col">
                        <div className="flex flex-col items-start grow text-2xl font-bold">
                            Organization Associations (Optional)
                        </div>
                        <div className="flex flex-col items-start grow w-full md:w-2/3">
                            <p className="font-normal text-sm my-1">This plant can be added to an infinite list of organization groups. All active Mitsubishi Power organizations can be managed from the <Link to="/administration/group-management" className="no-underline text-ocean">Group Management</Link> page.</p>
                        </div>
                        <div className="flex flex-col items-start grow">
                            <hr className="my-8 text-[#B3B3B3]" />
                        </div>
                    </div>
                </React.Fragment>
        },
        {
            element: 
                <React.Fragment key="user-group-section-2">
                    <div className="flex flex-col">
                        <div className="text-2xl font-bold">
                            Users' Group Association(s)
                        </div>
                        <div className="w-full md:w-2/3">
                            <p className="font-normal text-sm my-1">This plant can be added to an infinite list of organization groups. All active Mitsubishi Power organizations can be managed from the <Link to="/administration/group-management" className="no-underline text-ocean">Group Management</Link> page.</p>
                        </div>
                        <div className="items-start">
                            <UserGroupSection />
                        </div>
                        <div className="flex flex-col items-start grow">
                            <hr className="my-8 text-[#B3B3B3]" />
                        </div>
                    </div>
                </React.Fragment>
        },
        {
            element:
                <React.Fragment key='system-details-3'>
                    <div className="flex flex-col">
                        <div className="text-2xl font-bold">
                            System Details
                        </div>
                        
                            <p className="text-sm my-2"><span className="font-bold">Legacy SharePoint ID:</span> {systemDetails?.spLegacyID}</p>
                            <p className="text-sm my-2"><span className="font-bold">ESR Plant Name:</span> {systemDetails?.esrPlantName}</p>
                            <p className="text-sm my-2"><span className="font-bold">TOMONI Dashboard Plant Name:</span> {systemDetails?.tomoniDashPlantName}</p>
                            <hr className="my-8 text-[#B3B3B3]" />
                    </div>
                    
                </React.Fragment>
        }
    ]

    // toggles header userGroups refetch after .map(multiple assoc set at same time) finalizes 
    const handleSave = async() => {
        if (plantConfig.data.baseOrgId) {
            let removeUserGroups = savedOrgGroups.filter(x => selectedOrgGroups.indexOf(x) === -1);
            let addUserGroups = selectedOrgGroups.filter(x => savedOrgGroups.indexOf(x) === -1);
            let addUserGroupRequest:Array<any>, removeUserGroupsRequest:Array<any> = [];
            
            if (addUserGroups) {
                addUserGroupRequest = addUserGroups.map( async g => {
                    return await addUserGroupToBaseOrg({
                        ...addRemoveUserGroupBaseOrgData,
                        userGroupId: g
                    });
                });
            }

            if (removeUserGroups) {
                removeUserGroupsRequest = removeUserGroups.map(async g => {
                    return await removeUserGroupFromBaseOrg({
                        ...addRemoveUserGroupBaseOrgData,
                        userGroupId: associatedUserGroups.find(ug => ug.userGroupId === g)?.id || 0
                    });
                });
            }

            if (addAnotherOrgGroup && !savedOrgGroups.includes(addAnotherOrgGroup)) {
                    await addUserGroupToBaseOrg({
                        ...addRemoveUserGroupBaseOrgData,
                        userGroupId: addAnotherOrgGroup
                    });
            }

            let promiseExecution = async () => {
                let finishRemoving = await Promise.all(removeUserGroupsRequest);
                let finishAdding = await Promise.all( addUserGroupRequest);
                if (finishRemoving && finishAdding) {
                    refetchAssociatedGroups();
                    refetchUserGroups();
                    setAddAnotherOrgGroup(undefined);
                }
            };

            promiseExecution().catch(err => setError(err));
        }
    }

    const handleCancelChanges = () => {
        refetchAssociatedGroups();
        refetchUserGroups();
        resetAddUserGroupMutation();
        resetRemoveUserGroupMutation();
        setAddAnotherOrgGroup(undefined);
    };

    return (
        <div className="flex flex-col p-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            <div className="flex flex-row items-start gap-6 self-stretch justify-between">
                <Link to="/administration/plant-management" className="no-underline text-dark-blue visited:text-dark-blue focus:text-dark-blue">
                    <div className="flex flex-row  items-center">
                        <img src={Arrow} title="Back to Users' Group Portal Users" className="rotate-180 h-6 w-6" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                        <span className="font-bold text-sm">Back to Mitsubishi Power Plants</span>
                    </div>
                </Link>
            </div>
            <div className="flex flex-row !pb-0 items-start my-8 self-stretch">
                <div className="flex flex-col gap-2">
                    <span className="font-bold text-2xl">{plantConfig?.data?.baseOrgName} Details</span>
                </div>
            </div>
            <div className="flex flex-col !pb-0 items-start self-stretch">
                {unitCount === 0 && 
                    <div className="w-full my-2">
                        <Alert dismissible={false} type="warning" onClose={() => { }}>
                            This plant currently does not have any units configured. Unit configurations are required for external customers to add relevant equipment information.
                        </Alert>
                    </div>
                }

                {error && 
                    <div className="w-full my-2">
                        <Alert dismissible={true} type="error" onClose={() => setError(null)}>{error!.message}</Alert>
                    </div>
                }

                {(isSuccessAddUserGropup || isSuccessDeleteUserGroup) && 
                    <div className="w-full my-2">
                        <Alert onClose={() => {
                            resetAddUserGroupMutation();
                            resetRemoveUserGroupMutation();
                        }} type="success">
                            {isSuccessAddUserGropup ? 'Associated User\'s Groups have been successfully updated.' : isSuccessDeleteUserGroup ? 'Associated User\'s Group has been successfully removed.' : 'Changes Saved.'}
                        </Alert>
                    </div>
                }
            </div>
            <ConfirmRemoveGroupAssociationModal props={modalProps} />
            <div className="flex flex-row !pb-0 items-start self-stretch">
                <div className="flex flex-col items-stretch">
                    {content.map(section => section.element)}
                </div>
            </div>
            <div className="flex flex-row !pb-0 items-start self-stretch">
                <div className="flex flex-col items-stretch w-full">
                    <PlantSiteDetailsTop selectedPlantConfig={plantConfig.data} />
                    <div className="flex flex-col items-start grow w-full">
                        <hr className="my-8 text-[#B3B3B3]" />
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-stretch w-full">
                <ConfigurationDetails plantId={plantId} baseOrgId={plantConfig.data.baseOrgId} setUnitCount={setUnitCount} />
                <div className="flex flex-col items-start grow w-full">
                    <hr className="my-8 text-[#B3B3B3]" />
                </div>
            </div>
            <div className="flex flex-col md:flex-row !pb-0 gap-x-4">
                <Button disabled={!((selectedOrgGroups !== savedOrgGroups) || ((addAnotherOrgGroup !== undefined) && addAnotherOrgGroup > 0))} className="darkBlue" text="Save Changes" onClick={handleSave} />
                {((selectedOrgGroups !== savedOrgGroups) || ((addAnotherOrgGroup !== undefined) && addAnotherOrgGroup > 0)) && 
                    <Button className="whiteBtn" text="Cancel" onClick={handleCancelChanges} />
                }
            </div>
        </div>
    );
}

export default PlantManagement;

const tableColumns = ["Equipment Name", "Equipment ID", "Product Type", "Manufacturer", "Model Name", "Nameplates"];

// TODO: ConfigurationDetails is not responsive and causes horizontal scroll in mobile. This needs to be fixed.
const ConfigurationDetails = ({plantId, baseOrgId, setUnitCount}: {plantId: number, baseOrgId: number, setUnitCount: React.Dispatch<React.SetStateAction<number>>}) => {
    const setErrorData = useOnError();
    const [units, setUnits] = useState<(UnitDATA & {id: number})[]>([]);
    const [selectedUnit, setSelectedUnit] = useState<UnitDATA & {id: number}>();
    const [unitEquipment, setUnitEquipment] = useState<PlantEquipmentsDTO>();

    useGetAll_UnitData(plantId, ({data}: UnitGetAllDTO) => {
        setUnits(data.map(d => ({...d, id: d.unitId})));
    }, setErrorData);

    useEffect(() => {
        if (units.length > 0) {
            setSelectedUnit(units[0]);
        }
        setUnitCount(units.length);
    }, [units]);

    useGetAllByUnitID_PlantEquipments((selectedUnit?.id || 0), (data: PlantEquipmentsDTO) => setUnitEquipment(data), (d: any) => {
        setUnitEquipment(undefined);
        setErrorData(d);
    });
    return (
        <div className="flex flex-col items-start gap-6">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-xl">Configuration Details</span>
                <span className="text-sm">Please note units cannot be deleted if equipment records exist within them.</span>
            </div>
            <div className="flex flex-col items-start gap-4 self-stretch">
                {units.length === 0? (
                    <span>No units to display.</span>
                ) : (<>
                    <div className="flex flex-col items-start gap-2">
                        <span>Unit Name</span>
                        <PlainDropDown options={units}
                                       onSelection={d => setSelectedUnit(d)}
                                       value={selectedUnit? selectedUnit.id : false}
                                       className="relative"
                                       selectClass="flex flex-row items-center !py-[6px] pr-2 pl-4 text-xs justify-between rounded w-full border border-solid border-[#999] whitespace-nowrap min-w-[240px] cursor-pointer"
                                       itemClass="py-2 px-4 cursor-pointer rounded hover:bg-primary-20 font-bold text-sm"
                                       optionsClass="flex flex-col p-4 bg-white rounded max-h-80 w-full" />
                    </div>
                    {selectedUnit !== undefined && (
                        <>
                            <div className="flex flex-col items-start gap-1">
                                <span className="font-bold text-sm">Notes</span>
                                <span className="text-sm">{selectedUnit.notes || '--'}</span>
                            </div>
                            <div className="p-6 bg-neutral rounded w-full">
                                <div className="flex flex-row items-start gap-4">
                                    <ConfigItem title="Unit Configuration" value={selectedUnit.unitConfigurationName} />
                                    <ConfigItem title="Output Potential" value={`${selectedUnit.totalMW} Mw`} />
                                    <ConfigItem title="Fuel Type" value={selectedUnit.fuelTypeName} />
                                    <ConfigItem title="Direct Ownership" value={selectedUnit.directOwnership} />
                                    <ConfigItem title="Parent/Group Owner" value={selectedUnit.directOwnership} />
                                    <ConfigItem title="CO Date" value={selectedUnit.coDate} />
                                </div>
                            </div>
                            {unitEquipment && <PlantSiteTable tableColumns={tableColumns} tableData={unitEquipment} onLinkClick={() => sessionStorage.setItem('baseOrgId', `${baseOrgId}`)} />}
                        </>
                    )}
                </>)}
            </div>
        </div>
    );
};

const ConfigItem = ({title, value}: {title: string, value: string}) => (
    <div className="flex flex-col items-start gap-1 grow">
        <span className="font-bold text-sm">{title}</span>
        <span className="text-sm">{value}</span>
    </div>
);