import React, { useContext } from 'react';
import { Link as ReactRouterDomLink, LinkProps as ReactRouterDomLinkProps } from 'react-router-dom';
import { Store } from '../../Store'

interface LinkProps extends ReactRouterDomLinkProps {
    to: string;
}

function Link(props: LinkProps) {
    const {setActiveLocation, toggleSidebar} = useContext(Store);

    const handleLinkClick = () => {
        toggleSidebar && toggleSidebar(false);
        setActiveLocation && setActiveLocation(props.to);
    };

    return <ReactRouterDomLink to={props.to} className={props.className} onClick={handleLinkClick}>{props.children}</ReactRouterDomLink>
}

export default Link;