
import React, {useRef, MouseEventHandler, useContext} from 'react';
import classes from "./EmptyModal.module.css";
import ImageImports from "../../utils/ImageImports";
import useWindowSize from '../../customHooks/useWindowSize';
import ErrorCard from '../ErrorCard/ErrorCard';
import { Store } from '../../Store';
const {closeImg} = ImageImports;

function EmptyModal({shown, heading, body, footer, footerClass, headingClass, onClose, className = ''}: EmptyModalProps) {
    const ref = useRef<HTMLDivElement>(null);
    const {isMobile} = useWindowSize();
    const { showModalError } = useContext(Store);
    
    return (
        <>
            {shown && (
                <> 
                    <div className={classes.Backdrop} onClick={onClose}></div>
                    <div ref={ref} className={`${classes.ModalContainer} ${isMobile? classes.IsMobile : ''} ${className}`} 
                    >
                        <div className={classes.ModalHeader}>
                            <div className={`${classes.HeadingText} ${headingClass ?? ''}`}>{heading}</div>
                            <div className={classes.CloseIcon} onClick={onClose}>
                                <img src={closeImg} alt="Close" />
                            </div>
                        </div>
                        {showModalError.isError && <ErrorCard ErrorMessage={showModalError.title} ErrorType={showModalError.ErrorType} />}
                        {body}
                        <div className={`${classes.ModalFooter} ${footerClass ?? ''}`}>
                            {footer}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

interface EmptyModalProps {
    shown: boolean;
    heading: string;
    body: any;
    footer: any;
    onClose: MouseEventHandler;
    width?: number;
    footerClass?: string;
    headingClass?: string;
    className?: string;
}

EmptyModal.defaultProps = {
    width: 700,
    footerClass: '',
    headingClass: ''
};

export default EmptyModal;