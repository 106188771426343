export const fontSizeOptions: [string, string][] = [
    ['12px', '12px'],
    ['13px', '13px'],
    ['14px', '14px'],
    ['15px', '15px'],
    ['16px', '16px'],
    ['17px', '17px'],
    ['18px', '18px'],
    ['19px', '19px'],
    ['20px', '20px'],
    ['21px', '21px'],
    ['22px', '22px'],
    ['23px', '23px'],
    ['24px', '24px'],
    ['25px', '25px'],
    ['26px', '26px'],
    ['27px', '27px'],
    ['28px', '28px'],
    ['29px', '29px'],
    ['30px', '30px'],
    ['31px', '31px'],
    ['32px', '32px'],
    ['33px', '33px'],
    ['34px', '34px'],
    ['35px', '35px'],
    ['36px', '36px'],
    ['37px', '37px'],
    ['38px', '38px'],
    ['39px', '39px'],
    ['40px', '40px'],
    ['45px', '45px'],
    ['50px', '50px'],
    ['55px', '55px'],
    ['60px', '60px'],
    ['65px', '65px'],
    ['70px', '70px'],
    ['75px', '75px'],
    ['80px', '80px'],
];

export const fontFamilyOptions: [string, string][] = [
    ['Arial', 'Arial'],
    ['Courier New', 'Courier New'],
    ['Georgia', 'Georgia'],
    ['Times New Roman', 'Times New Roman'],
    ['Trebuchet MS', 'Trebuchet MS'],
    ['Verdana', 'Verdana'],
];

export const blockTypeOptions: [string, string][] = [
    ['h1', 'Large Header'],
    ['h2', 'Medium Header'],
    ['h3', 'Small Header'],
    // ['h1', 'H1'],
    // ['h2', 'H2'],
    // ['h3', 'H3'],
    // ['h4', 'H4'],
    // ['h5', 'H5'],
    // ['h6', 'H6'],
    ["paragraph", 'Normal'],
    ["quote", 'Quote'],
];