import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import { PortalContact } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";
import { validate } from "../../utils/EmailValidation";

const { tooltip } = ImageImports;


export const AddEditContact = ({contact, onClose, onSave, onDelete}: {contact: PortalContact, onClose: () => void, onSave: (contact: PortalContact) => void, onDelete: (contact: PortalContact) => void}) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [displayOrder, setDisplayOrder] = useState<number>(0);
    const [company, setCompany] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [secondaryPhone, setSecondaryPhone] = useState<string>('');
    const [primaryPhone, setPrimaryPhone] = useState<string>('');
    const [canSave, setCanSave] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        setFirstName(contact.firstName || '');
        setLastName(contact.lastName || '');
        setRole(contact.role || '');
        setEmail(contact.email || '');
        setDisplayOrder(contact.displayOrder || 0);
        setCompany(contact.company || '');
        setJobTitle(contact.jobTitle || '');
        setSecondaryPhone(contact.secondaryPhone || '');
        setPrimaryPhone(contact.primaryPhone || '');
    }, [contact]);

    const isEditing = useMemo(() => {
        return contact.id > 0;
    }, [contact]);

    useEffect(() => {
        const checks: (() => boolean)[] = [
            () => !!formRef.current && formRef.current.checkValidity(),
            () => firstName.length > 0,
            () => lastName.length > 0,
            () => displayOrder.toString().length > 0,
            () => email.length > 0,
            () => validate(email),
            () => Object.entries({
                    firstName, lastName, role, email, displayOrder, company, jobTitle, secondaryPhone, primaryPhone
                }).toString() !== Object.entries({
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    role: contact.role,
                    email: contact.email,
                    displayOrder: contact.displayOrder,
                    company: contact.company,
                    title: contact.jobTitle,
                    secondaryPhone: contact.secondaryPhone,
                    primaryPhone: contact.primaryPhone
                }).toString()
        ];
        let canSave = true;
        for (let i = 0; i < checks.length && canSave === true; i++) {
            canSave = checks[i]();
        }

        setCanSave(canSave);
    }, [firstName, lastName, role, email, displayOrder, company, jobTitle, secondaryPhone, primaryPhone, contact, formRef]);

    const update = useCallback(() => {
        onSave({
            ...contact,
            firstName, lastName, role, email, displayOrder, company, jobTitle, secondaryPhone, primaryPhone
        });
    }, [firstName, lastName, role, email, displayOrder, company, jobTitle, secondaryPhone, primaryPhone, contact]);

    return (
        <EmptyModal body={(
                <form ref={formRef} className="w-full">
                    <div className="flex flex-col items-start gap-8 self-stretch">
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>First Name*</span>
                            </div>
                            <input type="text"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                required
                                placeholder="First name"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Last Name*</span>
                            </div>
                            <input type="text"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                required
                                placeholder="Last name"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Role*</span>
                                <MiniTooltip text="Enter the role the Contact holds in the User Group.">
                                    <img src={tooltip} alt="Link URL" className="h-4 w-4" />
                                </MiniTooltip>
                            </div>
                            <input type="text"
                                value={role}
                                onChange={e => setRole(e.target.value)}
                                required
                                placeholder="Chairman"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Display Order*</span>
                                <MiniTooltip text="Please enter the numeric placement for the Contact to be displayed.">
                                    <img src={tooltip} alt="Display Order" className="h-4 w-4" />
                                </MiniTooltip>
                            </div>
                            <input type="number"
                                value={displayOrder}
                                onChange={e => setDisplayOrder(Number(e.target.value))}
                                required
                                min={1}
                                placeholder="0"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Company</span>
                            </div>
                            <input type="text"
                                value={company}
                                onChange={e => setCompany(e.target.value)}
                                placeholder="Mitsubishi Power Americas"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Job Title</span>
                                <MiniTooltip text="Enter the Job Title the person holds in the Contact's Organization.">
                                    <img src={tooltip} alt="Job Title" className="h-4 w-4" />
                                </MiniTooltip>
                            </div>
                            <input type="text"
                                value={jobTitle}
                                onChange={e => setJobTitle(e.target.value)}
                                placeholder="Job Title or Description"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Email*</span>
                            </div>
                            <input type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                                placeholder="example@domain.com"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Office Phone</span>
                            </div>
                            <input type="tel"
                                value={secondaryPhone}
                                onChange={e => setSecondaryPhone(e.target.value)}
                                pattern="^\+?[0-9]{0,2}[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                placeholder="(000) 000-0000"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                        <div className="flex flex-row !pb-0 gap-6 self-stretch">
                            <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                                <span>Mobile Phone</span>
                            </div>
                            <input type="tel"
                                value={primaryPhone}
                                onChange={e => setPrimaryPhone(e.target.value)}
                                pattern="^\+?[0-9]{0,2}[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                placeholder="(000) 000-0000"
                                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                                style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                        </div>
                    </div>
                </form>
            )}
            footer={(
                <>
                    <div className="flex flex-row !pb-0 gap-4 justify-center">
                        <Button className="darkBlue" text={isEditing? 'Save Changes' : 'Add Contact'} disabled={!canSave} onClick={update} />
                        {isEditing && <Button className="redDelete" text="Delete" onClick={() => onDelete(contact)} />}
                    </div>
                    <span className="font-light text-sm">*Required fields</span>
                </>
            )}
            heading={isEditing? 'Edit Contact' : 'Add New Contact'}
            onClose={onClose} shown={true} />
    );
};



export const AddEditContactConfirmation = ({contact, onClose}: {contact: PortalContact, onClose: () => void}) => {
    const {selectedUserGroup} = useContext(Store);

    const wasEditing = useMemo(() => {
        return contact.id > 0;
    }, [contact]);

    // don't know if recording.userGroupId will be an array or number at this point... 
    let bodyText: string = '';
    let footerButtons: ReactNode = '';
    let headingText = '';
    if (wasEditing) {
        headingText = 'Changes Saved';
        bodyText = `The contact details for ${contact.firstName} ${contact.lastName} have been successfully updated.`;
        footerButtons = (
            <Button className="darkBlue" text="Done" onClick={onClose} />
        );
    } else {
        bodyText = `${contact.firstName} ${contact.lastName} was added to the ${selectedUserGroup.name} Users' Group Group Officers list.`;
        headingText = 'Contact Successfully Added';
        footerButtons = (
            <Button className="darkBlue" text="Done" onClick={onClose} />
        );
    }

    return (
        <EmptyModal body={(
                <span className="self-center">{bodyText}</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    {footerButtons}
                </div>
            )}
            heading={headingText}
            headingClass="pl-16 text-center"
            onClose={onClose} shown={true} width={564} />
    );
};