import React, { ReactElement, useContext } from "react";
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from "../../../components/Modals/EmptyModal";
import { DiscussionGroupInterface } from "../../../utils/interface";
import Button from "../../../components/Button/Button";
import useWindowSize from "../../../customHooks/useWindowSize";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import { useOutletContext } from "react-router-dom";
import { Store } from "../../../Store";

interface DeleteGroupConfirmationModalProps {
  shown: boolean;
  reportType: DiscussionGroupInterface | null;
  onClose: () => void;
  onDelete: () => void;
}

function DeleteGroupConfirmationModal(props: DeleteGroupConfirmationModalProps) {
  const { isMobile } = useWindowSize();
  const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();
  const {selectedUserGroup} = useContext(Store);
  
  let width: number = 564;
  if (isMobile) {
    width = 300;
  }

  let modalBody: ReactElement | undefined;
  let modalFooter: ReactElement | undefined;
  if (props.shown) {
    modalBody = (
      <>
        <span className={classes.is_mobile}>
        Are you sure you want to delete the discussion category {props?.reportType?.name} from the {selectedUserGroup.name} in the Users' Group Portal? Deleting this Discussion Category will remove all discussion threads attached to it. This cannot be undone.
        </span>
      </>
    );

    modalFooter = (
      <>
        <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
          <Button className="redDelete" text="Yes, Delete" onClick={props.onDelete} />
          <Button className="whiteBtn" text="Cancel" onClick={props.onClose} />
        </div>
      </>
    );
  }

  return (
    <EmptyModal
      shown={props.shown}
      body={modalBody}
      footer={modalFooter}
      heading="Delete Discussion Category?"
      onClose={props.onClose}
      width={width}
      footerClass={classes.modal_footer}
    />
  );
}

export default DeleteGroupConfirmationModal;
