import React, { ReactElement, useContext } from "react";
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from "../../../components/Modals/EmptyModal";
import { DiscussionGroupInterface, DocumentGroup } from "../../../utils/interface";
import Button from "../../../components/Button/Button";
import useWindowSize from "../../../customHooks/useWindowSize";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import { useOutletContext } from "react-router-dom";
import { Store } from "../../../Store";

interface DeletedGroupConfirmationModalProps {
  shown: boolean;
  reportType: DiscussionGroupInterface | null;
  onClose: () => void;
}

function DeletedGroupConfirmationModal(props: DeletedGroupConfirmationModalProps) {
  const { isMobile } = useWindowSize();
  const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();
  const { selectedUserGroup } = useContext(Store);
  let width: number = 564;
  if (isMobile) {
    width = 300;
  }

  let modalBody: ReactElement | undefined;
  let modalFooter: ReactElement | undefined;
  if (props.shown) {
    modalBody = (
      <>
        <span> 
          You successfully deleted the discussion category {props?.reportType?.name} from the {selectedUserGroup.name} Users’ Group.
        </span>
      </>
    );

    modalFooter = (
      <>
        <div className={`${classes.deleted_group_modal_button_group} ${classes.flex_row}`}>
          <Button className="darkBlue" text="Done" onClick={props.onClose} />
        </div>
      </>
    );
  }

  return (
    <EmptyModal
      shown={props.shown}
      body={modalBody}
      footer={modalFooter}
      heading="Discussion Category Successfully Deleted"
      onClose={props.onClose}
      width={width}
    />
  );
}

export default DeletedGroupConfirmationModal;
