import axios, { AxiosResponse } from "axios";
import { apiMitsubishiPowerReport, omManualApiUrl, technicalBulletinApiUrl, apiPartsCatalog, userApiUrl } from "../../endpoints/endpoints";
import { CPApiResponse, PlantPermissionedReports, Report, PartsCatalogInterface, User } from "../../utils/interface";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";

// interface starts here //
export interface ListModuleApiMethod<T> {
  getAll: () => Promise<T>;
}
// end interface //z

// User Management section starts here //
export const UserManagementCRUD: ListModuleApiMethod<AxiosResponse<User[]>> = {
  getAll: async (): Promise<AxiosResponse<User[]>> => {
    const response = await axios(`${userApiUrl}/GetList`);
    return response.data;
  },
};
// end User Management section //
