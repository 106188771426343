import { ErrorMessage, Field, useField } from 'formik';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Store } from '../../../../Store';
import ImageImports from '../../../../utils/ImageImports';
import classes from "./RadioButtonsGrouped.module.css";

const RadioButtonsGrouped = (props:RadioButtonsGroupedProps) => {
    const stateContext = useContext(Store);
    const { tooltip } = ImageImports;
    const [field,meta] = useField(props);
      
    return (
        <div className={`${classes.form_field_row}`} >
          <label htmlFor={props.name} className={classes.input_label}>
            {props.labelName}
            {props.showTooltip && (
              <div
                className={classes.cursor_pointer}
                // onBlur={() => {
                //   if (stateContext.toggleTooltip !== undefined) {
                //     stateContext.toggleTooltip(false);
                //   }
                // }}
                tabIndex={1}
                onClick={() => {
                  if (stateContext.toggleTooltip !== undefined) {
                    stateContext.toggleTooltip(!stateContext.toolTip);
                  }
                }}
              >
                <img
                  src={tooltip}
                  className={classes.tooltip_icon}
                  alt="tool tip"
                />
              </div>
            )}
          </label>
          <div className={props.display === "block" ?  `${classes.form_group} ${classes.form_groupBlock}` : `${classes.form_group} ${classes.form_groupInline}` }>
            <div style={{width:"100%"}}       
            className={meta.touched && meta.error ? `${classes.errorField}` : ``}
            >
            <label>
              <Field type="radio" name={props.name}  value={props.firstRadioValue}  />
               <span className={classes.radiobtnname}>{props.firstRadioName}</span>
            </label>
            <label>
              <Field type="radio" name={props.name} value={props.SecondRadioValue} />              
              <span className={classes.radiobtnname}>{props.SecondRadioName}</span>
            </label>
            </div>
            {/* <ErrorMessage name={props.name}>
              {(msg) => <div className={classes.error_text}>{msg}</div>}
            </ErrorMessage> */}
          </div>
        </div>
      );
}

interface RadioButtonsGroupedProps {
    name: string;
    labelName: string;
    placeholder?: string;
    showTooltip: boolean;
    className:string | "";    
    firstRadioName:string;
    firstRadioValue:string | boolean;
    SecondRadioName:string;
    SecondRadioValue:string | boolean;       
    display?:"inline" | "block";         
  }

export default RadioButtonsGrouped

interface RadioButtonGroupOption {
  label: string;
  value: string|number;
}
interface RadioButtonGroupProps {
  name: string;
  options: RadioButtonGroupOption[];
  onChange: (value: string) => void;
  value: string|number|undefined;
}
export const RadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = ({name, options, onChange, value}) => {

  return (
    <div className={classes.radioButtonGroup}>
      {options.map(opt => (
        <label key={opt.value}>
          <input type="radio" name={name} value={opt.value} checked={value === opt.value} onChange={e => onChange(e.target.value)} />
          <span className={classes.radiobtnname}>{opt.label}</span>
        </label>
      ))}
    </div>
  );
};