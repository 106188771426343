import {
  apiAccountSettings,
  apiDocuments,
  apiHeader,
  apiIssueReports,
  apiPartsAndServices,
  apiPlantSiteDetails,
} from "../../endpoints/endpoints";
import {
  AccountSettingsDTO,
  AccountSettingsData,
  DocumentsDTO,
  HeaderDTO,
  IssueReportsDTO,
  PartsAndServicesDTO,
  PlantSiteDetailsDTO
} from "../../utils/interface";

import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { Store } from "../../Store";
import { queryKeys } from "../../react-query/queryKeys";

declare type GetHeaderParameters = [typeof queryKeys.header, {baseOrg: number | undefined, userGroupId: number}];
const getHeader = async ({queryKey}: QueryFunctionContext<GetHeaderParameters>): Promise<AxiosResponse<HeaderDTO>> => {
  const [_key, {baseOrg}] = queryKey;
  const response = await axios(`${apiHeader}`, {
    headers: {
      BaseOrg: (baseOrg ?? '') as string
    }
  });
  return response.data;
}

const getAccountSettings = async (): Promise<AxiosResponse<AccountSettingsDTO>> => {
	const response = await axios(`${apiAccountSettings}`);
	return response.data;
};

const getTD = async (): Promise<AxiosResponse<DocumentsDTO>> => {
  const response = await axios(`${apiDocuments}`);
  return response.data;
};

const getPS = async (): Promise<AxiosResponse<PartsAndServicesDTO>> => {
  const response = await axios(`${apiPartsAndServices}`);
  return response.data;
};

const getIR = async (): Promise<AxiosResponse<IssueReportsDTO>> => {
  const response = await axios(`${apiIssueReports}`);
  return response.data;
};

export const updateAccountSettings = async (data: AccountSettingsData): Promise<AccountSettingsDTO> => {
  const formData = new FormData();
  formData.append('defaultBaseOrgId', `${data.defaultBaseOrgId}`);
  formData.append('defaultBaseOrgName', data.defaultBaseOrgName);
  formData.append('email', data.email);
  formData.append('fName', data.fName);
  formData.append('lName', data.lName);
  formData.append('optedOut', `${data.optedOut}`);
  const response = await axios({
    url: `${apiAccountSettings}`,
    method: "PUT",
    data: formData,
  });
  return response.data;
};

export const useGet_HeaderData = (onSuccess: any, onError: any) => {
  const {selectedPlant, selectedUserGroup} = useContext(Store);
  return useQuery<AxiosResponse<HeaderDTO>, unknown, boolean, GetHeaderParameters>([queryKeys.header, {userGroupId: selectedUserGroup.id, baseOrg: selectedPlant.baseOrgId}], getHeader, {
    refetchOnWindowFocus:true,
    onSuccess,
    onError
  });
};

export const useGet_AccountSettings = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.accountSettings, getAccountSettings, {
    onSuccess,
    onError,
  });
};

export const useGet_IssueReports = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.issueReports, getIR, {
    onSuccess,
    onError,
  });
};

export const useGet_PartsAndServices = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.partsAndServices, getPS, {
    onSuccess,
    onError,
  });
};

export const useGet_Documents = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.documents, getTD, {
    onSuccess,
    onError,
  });
};


declare type getSelectedPlantDetailsParameters = [string, {plantId: number}];
const getSelectedPlantDetails = async ({queryKey}: QueryFunctionContext<getSelectedPlantDetailsParameters>): Promise<PlantSiteDetailsDTO> => {
    const [_key, {plantId}] = queryKey;
    if (!plantId) {
        return Promise.reject(new Error('Invalid Plant Id'));
    }

    const response: AxiosResponse<PlantSiteDetailsDTO> = await axios(`${apiPlantSiteDetails}/${plantId}`);
    return response.data;
};

export const useSelectedPlantDetails = (onSuccess: any, onError: any) => {
  const {selectedPlant} = useContext(Store);
  
  return useQuery<PlantSiteDetailsDTO, unknown, PlantSiteDetailsDTO, getSelectedPlantDetailsParameters>([queryKeys.plantSiteDetails, {plantId: selectedPlant.plantId}], getSelectedPlantDetails, {
    onSuccess,
    onError,
    enabled: selectedPlant.plantId > 0
  });
};
