import { FunctionComponent, useEffect } from "react";
import { generatePath, Params, useNavigate, useParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { ModuleIDs } from "../../utils/moduleIDs";

const moduleRoutes = {
    [ModuleIDs.GeneralDocuments]: '/technical-documentation/General Documents/*',
    [ModuleIDs.UserGroupDocuments]: '/technical-documentation/User Group Documents/*',
    [ModuleIDs.TechnicalBulletins]: '/technical-documentation/Technical Bulletins/*'
};

type ModuleIdType = keyof typeof moduleRoutes;

export const ModuleRedirector: FunctionComponent = () => {
    const navigate = useNavigate();
    const {moduleId, ...params} = useParams() as Readonly<Params<string>> & {moduleId: ModuleIdType};

    useEffect(() => {
        if (moduleRoutes.hasOwnProperty(moduleId)) {
            try {
                const p = generatePath(moduleRoutes[moduleId], params);
                navigate(p);
            } catch (err) {
                console.error(err);
            }
        }
    }, [moduleId]);

    return <ErrorPage />;
};