import React, { FunctionComponent } from "react";


interface DragDropFieldInfoProps {
  fileTypes: string;
  containerClassName?: string;
}

const DragDropFieldInfo: FunctionComponent<DragDropFieldInfoProps> = ({fileTypes, containerClassName}: DragDropFieldInfoProps) => {
  return (
    <div className={`text-sm ${containerClassName}`}>
      <p className="mt-0 mb-3 text-sm font-black-200">Please ensure uploaded files are change protected and watermarked “proprietary and confidential”.</p>
      <p className="m-0 text-sm font-black-200">Accepted file types: {fileTypes}</p>
      <p className="m-0 text-sm font-black-200">Maximum file upload size: 1GB*</p>
      <p className="m-0 text-ocean text-sm">*Please Note: The max file size for users outside of the Mitsubishi Power Network may be less than 1GB.</p>
    </div>
  );
}

DragDropFieldInfo.defaultProps = {
  fileTypes: ".PDF, .DOC, .XLSX, .JPG, .PNG"
}

export default DragDropFieldInfo;
