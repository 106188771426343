import { AxiosError } from "axios";
import { useState } from "react";
import { useAvatar_PlantContact } from "../../../containers/PlantInformation/PlantContactHttpServices";

import useWindowSize from "../../../customHooks/useWindowSize";

import { toBase64 } from "../../../utils/FileUtils";
import { PlantContact } from "../../../utils/interface";
import { phoneNumberFormater } from "../../../utils/phoneNumberFormater";

import classes from "./SiteContact.module.css";
const SiteContact = ({ contactlist }: SiteContactProps) => {
  const { isDesktop } = useWindowSize();
  const [base64Image, setbase64Image] = useState("");

  const onSuccess = (data: any) => {
    toBase64(data)
      .then((base64Image: any) => {
        setbase64Image(base64Image);
      })
      .catch((error) => {
        setbase64Image("");
      });
  };
  const onError = (error: AxiosError<string>) => {
    setbase64Image("");
  };
  useAvatar_PlantContact(contactlist.avatarSize > 0, contactlist.plantContactId, onSuccess, onError);
  return (
    <>
      {isDesktop && (
        <div className={classes.panel_tbl}>
          <div className={classes.panel_tbl_cell}>
            <div className={classes.profiledetails}>
              <div className={classes.avatarWrap} style={{ marginRight: "15px" }}>
                {base64Image.length > 0 ? (
                  <img style={{ maxWidth: "100%" }} src={base64Image} alt={contactlist.firstName} />
                ) : (
                  <span className={classes.avatarDefault}>
                    {contactlist.firstName.slice(0, 1)}
                    {contactlist.lastName.slice(0, 1)}
                  </span>
                )}
              </div>
              <div>
                <div className={classes.contactname}>
                  <strong>
                    {contactlist.firstName} {contactlist.lastName}
                  </strong>
                </div>
                <div className={classes.jobtitle}>{contactlist.companyName}</div>
                <div className={classes.jobtitle}>{contactlist.jobTitle}</div>
              </div>
            </div>
          </div>
          <div className={classes.panel_tbl_cell}>
            <div>
              {contactlist.primaryPhone && (
                <>
                  <strong>{contactlist.primaryPhoneType}: </strong>
                  {contactlist.primaryPhone && phoneNumberFormater(contactlist.primaryPhone)}
                </>
              )}
            </div>
            <div>
              {contactlist.secondaryPhone && (
                <>
                  <strong>{contactlist.secondaryPhoneType}: </strong> {contactlist.secondaryPhone && phoneNumberFormater(contactlist.secondaryPhone)}
                </>
              )}
            </div>
          </div>
          <div className={classes.panel_tbl_cell}>
            <div>
              {" "}
              <strong>Email: </strong>
              {contactlist.email}
            </div>
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className={classes.panel_tbl}>
          <div className={classes.avatarcol}>
            <div className={classes.avatarWrap} style={{ marginRight: "15px" }}>
              {base64Image.length > 0 ? (
                <img style={{ maxWidth: "100%" }} src={base64Image} alt={contactlist.firstName} />
              ) : (
                <span className={classes.avatarDefault}>
                  {contactlist.firstName.slice(0, 1)}
                  {contactlist.lastName.slice(0, 1)}
                </span>
              )}
            </div>
          </div>
          <div>
            <div className={classes.panel_tbl_cell}>
              <div className={classes.profiledetails}>
                <div>
                  <div className={classes.contactname}>
                    <strong>
                      {contactlist.firstName} {contactlist.lastName}
                    </strong>
                  </div>
                  <div className={classes.jobtitle}>{contactlist.jobTitle}</div>
                </div>
              </div>
            </div>
            <div className={classes.panel_tbl_cell}>
              <div>
                {contactlist.primaryPhone && (
                  <>
                    <strong>{contactlist.primaryPhoneType}: </strong>
                    {contactlist.primaryPhone && phoneNumberFormater(contactlist.primaryPhone)}
                  </>
                )}
              </div>
              <div>
                {contactlist.secondaryPhone && (
                  <>
                    <strong>{contactlist.secondaryPhoneType}: </strong>{" "}
                    {contactlist.secondaryPhone && phoneNumberFormater(contactlist.secondaryPhone)}
                  </>
                )}
              </div>
            </div>
            <div className={classes.panel_tbl_cell}>
              <div>
                {" "}
                <strong>Email: </strong>
                {contactlist.email}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface SiteContactProps {
  contactlist: PlantContact;
}

export default SiteContact;
