import useWindowSize from "../../../customHooks/useWindowSize";
import Button from "../../../components/Button/Button";
import classes from "../ListApiMethods.module.css";

function UserManagementHeader() {
  const { isDesktop } = useWindowSize();
  return (
    <div style={{justifyContent:"space-between"}} className={`flex flex-row gap-6 `}>
      <div className={`flex flex-col gap-6`}>
        <div className={'font-bold text-2xl'}>Customer Portal</div>
        <div className={'gap-6 text-[font-size:var(--fs14px)]'}>
        See below for a list of all users of the Customer Portal (active & inactive):
        </div>

        {!isDesktop && (
          <div className={`${classes.header_buttons}`}>
            <Button className="darkBlue" text="Add New User" />
          </div>
        )}
      </div>
      {isDesktop && (
        <div className={`${classes.header_buttons}`}>
          <Button className="darkBlue" text="Add New User" />
        </div>
      )}
    </div>
  );
}

export default UserManagementHeader;
