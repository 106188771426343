import { FunctionComponent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../Button/Button";

export const Forbidden: FunctionComponent = () => {
    const navigate = useNavigate();
    const { toggleTooltip } = useContext(Store);

    useEffect(() => {
      toggleTooltip && toggleTooltip(false);
    }, [toggleTooltip]);

    return (
        <div className="w-full">
            <div className="flex flex-col mx-4 p-8 relative mt-12 gap-6">
                <h1>Access Denied</h1>
                <p className="text-lg mblx-2">Unfortunately, you do not have permission to access this page or resource. If you think you require access to this page, please contact your Mitsubishi Power administrator.</p>
                <div><Button className="darkBlue" text={'Return to Home Page'} onClick={() => navigate('/')} /></div>
            </div>
        </div>
    )
}