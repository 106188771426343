import React, { CSSProperties, FunctionComponent, MouseEvent, ReactElement, useContext, useEffect, useId, useMemo, useRef, useState } from "react";

import "react-dates/initialize";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Store } from "../../Store";
import Arrow from "../../assets/arrow-right.svg";
import Button from "../../components/Button/Button";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import usePagination from "../../customHooks/usePagination";
import { DataWithPermissions, DocumentGroup, Permissions, PlantEquipmentsDATA, PlantPermissionedReports, Report } from "../../utils/interface";
import ReportRowAccordion from "./ReportRowAccordion";
import ReportRowTable from "./ReportRowTable";
import classes from "./Reports.module.css";

import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { DocumentModuleInterface } from "../Documents/DocumentsContainer";

import { AxiosError } from "axios";
import Moment from "react-moment";
import { useMutation, useQuery } from "react-query";
import DeleteDocumentConfirmation from "./DeleteDocumentConfirmation";
import DeleteDocumentModal from "./DeleteDocumentModal";
import MoveDocumentConfirmation from "./MoveDocumentConfirmation";
import MoveDocumentModal from "./MoveDocumentModal";

import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import { PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";

import Dropdown from "../../components/Dropdown/Dropdown";
import Pill from "../../components/Pill/Pill";
import { downloadMultipleFiles, downloadMultipleFilesByFileId } from "../../customHooks/FileHttpServices";

import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../customHooks/useOnError";
import { DocumentsModuleOutletInterface } from "../Documents/DocumentsModuleContainer";

import dayjs from "dayjs";
import { Pagination } from "../../components/List/Pagination";
import Loader from "../../components/Loader/Loader";
import { DownloadMultipleFilesModal } from "../../components/Modals/DownloadMultipleFilesModal";
import { useBulkUpdateItemsAsRead } from "../../components/NotificationPane/NotificationHttpServices";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import ToggleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";
import useFileViewer from "../../customHooks/useFileViewer";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { useStore } from "../../useStore";
import { ModuleIDs } from "../../utils/moduleIDs";
import "./React.Dates.Override.css";
import { useGetDocumentTypes } from "./ReportsHttpServices";

export interface ColumnDefinition {
  title: string;
  key: keyof Report;
  style: CSSProperties;
  component?: FunctionComponent<{ report: Report; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

const { magnifyGlassBlack, filter, download, copyLink, downArrow, trash, blueTick, file_edit } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

interface ReportsProps {
  documentModule: DocumentModuleInterface;
  documentGroups: DocumentGroup[];
  pagePermissions: Permissions;
  setPagePermissions: React.Dispatch<React.SetStateAction<Permissions>>
}

const copyLinkBaseUrl = `${window.location.origin}/shared-document`;

const sortOptions = [
  {
    id: 1,
    name: "Modified: Newest to Oldest",
    key: "updated",
    direction: "desc",
  },
  {
    id: 2,
    name: "Modified: Oldest to Newest",
    key: "updated",
    direction: "asc",
  },
  {
    id: 3,
    name: "Published: Newest to Oldest",
    key: "created",
    direction: "desc",
  },
  {
    id: 4,
    name: "Published: Oldest to Newest",
    key: "created",
    direction: "asc",
  },
  {
    id: 5,
    name: "Name: A to Z",
    key: "displayFileName",
    direction: "asc",
  },
  {
    id: 6,
    name: "Name: Z to A",
    key: "displayFileName",
    direction: "desc",
  },
];

const Reports: FunctionComponent<ReportsProps> = ({ documentModule, documentGroups, pagePermissions, setPagePermissions }) => {
  const stateContext = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();
  const tooltipPortalRef = useRef(null);
  const [checkedRows, setCheckedRows] = useState<Report[]>([]);
  const [isBulkActionTooltipVisible, setIsBulkActionTooltipVisible] = useState<boolean>(false);
  const { setShowError, plantConfig, setReport, selectedUserGroup, header: { data: { notifications }} } = stateContext;
  const [tableData, setTableData] = useState<Report[]>([]);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '');
  const setErrorData = useOnError();
  const [filters, setFilters] = useState<Filters>({});
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const { 
    header: {
      data: { user }
    }
  } = useStore();

  // deleting documents
  const [documentsToDelete, setDocumentsToDelete] = useState<Report[]>();
  const [deletionCount, setDeletionCount] = useState<number>(0);

  // moving documents
  const [documentsToMove, setDocumentsToMove] = useState<Report[]>();
  const [moveConfirmationMeta, setMoveConfirmationMeta] = useState<{ count: number; from: string; to: string }>();

  const { mutate: bulkUpdateItemsAsRead } = useBulkUpdateItemsAsRead();

  // copy document link
  const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);
  const [showCopyLinkSuccess, setShowCopyLinkSuccess] = useState<boolean>(false);
  const [copyLinkData, setCopyLinkData] = useState<Report[]>([]);
  const [allVersionsDownload, setAllVersionsDownload] = useState<number>();
  const { handleFileView, isLoading } = useFileViewer();
  const {
    pagedData,
    setData,
    setFilter,
    pageCount,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
    filterFn,
    totalRecords
  } = usePagination<Report>(16, ["displayFileName","documentType"]);

  useEffect(() => {
    setSortDirection('desc');
    setSortKey('updated');
  }, []);

  useEffect(() => {
    setFilters({ ...filters, isDeleted: (user?.portalAdmin || pagePermissions.canAdmin) ? ["Show Deleted"] : undefined });
  }, [user]);

  const handleFile = (report: Report) => {
    const filetype = report.fileModel.fileType.contentType;
    const filePreviewTypes = ["image/jpeg", "application/pdf", "image/jpeg", "image/png"];
    const isValid = filePreviewTypes.indexOf(filetype);
    if (isValid === -1) {
      handleFileView(report.fileId, report.globalId, { isDownload: true, fileName: report.displayFileName! });
    } else {
      handleFileView(report.fileId, report.globalId);
    }
  };

  let tableColumns: ColumnDefinition[];
  tableColumns = [
    {
      title: "Document Name",
      key: "displayFileName",
      component: ({ report }) => {
        if (report.displayFileName) return <div style={{ color: '#00749E' }}>{report.displayFileName}{" "}{report.isDeleted && "(Deleted)"}</div>;
        return <>N/A</>;
      },
      className: classes.document_name,
      style: {
        flexBasis: "24%",
      },
    },
    {
      title: "Report ID",
      key: "globalId",
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Equipment",
      key: "associatedEquipment",
      component: ({ report }) => <>{report.associatedEquipment?.map((e) => e.name).join(",")}</>,
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Published",
      key: "created",
      component: ({ report }) => {
        if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Last Modified",
      key: "updated",
      component: ({ report }) => {
        if (report.updated) return <Moment date={report.updated} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
  ];

  if (documentModule.id === 2) {
    tableColumns = [
      {
        title: "Document Name",
        key: "displayFileName",
        component: ({ report }) => {
          if (report.displayFileName) return <div style={{ color: '#00749E' }}>{report.displayFileName}{" "}{report.isDeleted && "(Deleted)"}</div>;
          return <>N/A</>;
        },
        className: classes.document_name,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Equipment",
        key: "associatedEquipment",
        component: ({ report }) => <>{report.associatedEquipment?.map((e) => e.name).join(",")}</>,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Published",
        key: "created",
        component: ({ report }) => {
          if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Details",
        key: "fileId",
        component: ({ report, tooltip }) => {
          if (tooltip) {
            return tooltip;
          }
          return <>""</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
    ];
  }

  if (documentModule.id === ModuleIDs.GeneralDocuments) {
    tableColumns = [
      {
        title: "Document Name",
        key: "displayFileName",
        component: ({ report }) => {
          if (report.displayFileName) {
            return (
              <div className="inline-block">
                <span onClick={() => handleFile(report)} className="text-ocean no-underline hover:cursor-pointer">{report.displayFileName} {report.isDeleted && "(Deleted)"}</span>
              </div>
            );
          }
          return <>N/A</>;
        },
        className: classes.document_name,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Document Type",
        key: "documentType",
        component: ({ report }) => {
          return <>{report.documentType}</>
        },
        style: {
          flexBasis: "18%",
        },
      },
      {
        title: "Published",
        key: "created",
        component: ({ report }) => {
          if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
      {
        title: "Last Modified",
        key: "updated",
        component: ({ report }) => {
          if (report.updated) return <Moment date={report.updated} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
    ];
  }

  const { mutate: deleteMutation } = useMutation((ids: number[]) => documentModule.documentApi.bulkDelete(ids, "User Deleted"), {
    onSuccess: (data: boolean) => {
      refetchReports();
    },
    onError: (error: AxiosError<string>) => {
      setShowError &&
        setShowError({
          isError: true,
          title: error.message,
          ErrorType: "danger",
        });
    },
  });
  const { mutate: moveMutation } = useMutation(
    (data: { ids: number[]; newItemTypeId: number }) => documentModule.documentApi.bulkMove(data.ids, data.newItemTypeId),
    {
      onSuccess: (data: boolean) => {
        refetchReports();
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );

  const group = documentGroups.find((dg) => dg.id === groupId);
  const { isFetching, refetch: refetchReports } = useQuery(["reports", "getReports", plantConfig.data.baseOrgId, group?.id, selectedUserGroup.id], () => documentModule.documentApi.getAll(group?.id, plantConfig.data.baseOrgId, documentModule.moduleId), {
    onSuccess: ({permissions, data}: DataWithPermissions<Report[]>) => {
      setPagePermissions(permissions);
      setTableData(data);
      setData(data);
    },
    enabled: !!group,
  });

  const confirmMove = (from: string, to: DocumentGroup) => {
    if (documentsToMove) {
      moveMutation({ ids: documentsToMove.map((d) => d.globalId), newItemTypeId: to.id });
      setMoveConfirmationMeta({
        count: documentsToMove.length,
        from,
        to: to.name,
      });
    }
    setCheckedRows([]);
    setDocumentsToMove(undefined);
  };

  const confirmDelete = () => {
    if (documentsToDelete) {
      deleteMutation(documentsToDelete.map((d) => d.globalId));
      setDeletionCount(documentsToDelete.length);
    }
    setCheckedRows([]);
    setDocumentsToDelete(undefined);
  };

  useEffect(() => {
    setShowCopyLinkModal(copyLinkData.length > 0);
  }, [copyLinkData]);


  // add additional tool tip actions to this array
  // the click event is passed to each onClick event handler as the first parameter
  // the second parameter is always an array of ReportRowData items - even when performing actions on a single row
  // `documentModule.documentApi` & `documentModule.documentGroupApi` point to api methods specific to the module (Technical Bulletins/General Documents/User Group Documents)
  // each module's documentApi & documentGroupApi methods should have the same signature so the methods can be called the same way here
  // example, here we should be able to call documentModule.documentApi.delete(reportRow) and the underlying method for that module should know what endpoint to hit and how the request should look.

  const tooltipActions: tooltipActionItem<Report>[] = [
    {
      text: "Select All",
	    disabled: checkedRows.length === tableData.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        setCheckedRows([...tableData]);
      },
    },
    {
      text: "Deselect All",
	    disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        setCheckedRows([]);
      },
    },
    {
      text: "Mark Read",
	    disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        bulkUpdateItemsAsRead(d.map(d => d.globalId));
		    setCheckedRows([]);
	    },
    },
    {
      text: "Download",
      img: download,
      showOnlyOn: ["ItemActions"],
      visible: d => !d.isDeleted,
      onClick: async (event: MouseEvent, d: Report[]) => {
        const gIds = d.map((d) => d.globalId);
        setAllVersionsDownload(gIds.length);
        await downloadMultipleFiles(gIds);
        setAllVersionsDownload(undefined);
      },
    },
    {
      text: "Copy Link",
      img: copyLink,
      showOnlyOn: ["ItemActions"],
      visible: d => !d.isDeleted,
      onClick: (event: MouseEvent, d: Report[]) => {
        event.preventDefault();
        setCopyLinkData(d);
      },
    },
  ];


  if (pagePermissions.canEdit || pagePermissions.canAdmin || user.portalAdmin) {
    tooltipActions.push({
      text: "Move",
      disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToMove(data);
        setIsBulkActionTooltipVisible(false);
      },
    });
  }
  if (pagePermissions.canEdit || pagePermissions.canAdmin || user.portalAdmin) {
    tooltipActions.push({
      text: "Edit",
      showOnlyOn: ["ItemActions"],
      visible: d => !d.isDeleted,
      img: file_edit,
      onClick: (event: MouseEvent, data: Report[]) => {
        if (data.length < 0) return;
        setReport && setReport(data[0]);
        navigate('edit');
      },
    });
  }

  
  if (pagePermissions.canDelete || pagePermissions.canAdmin || user.portalAdmin) {
    tooltipActions.push({
      text: "Delete",
      disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToDelete(data);
        setIsBulkActionTooltipVisible(false);
        // deleteMutation(data.map(d => d.id));
      },
    });
    tooltipActions.push({
      text: "Delete",
      img: trash,
      showOnlyOn: ["ItemActions"],
      visible: d => !d.isDeleted,
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToDelete(data);
        setIsBulkActionTooltipVisible(false);
        // deleteMutation(data.map(d => d.id));
      },
    });
  }

  if (pagePermissions.canAdmin || user.portalAdmin) {
    tooltipActions.push({
      text: "Download All Versions",
      img: download,
      showOnlyOn: ["ItemActions"],
      onClick: async (event: MouseEvent, data: Report[]) => {
        const fileIds = data.reduce((carry: number[], current: Report) => {
          let arrTemp = [...carry];
          if (current.fileId) {
            arrTemp.push(current.fileId);
          }
          if (current.previousFiles) {
            arrTemp.push(...current.previousFiles?.map(d => d.id));
          }
          return arrTemp;
        }, []);
        setAllVersionsDownload(fileIds.length);
        await downloadMultipleFilesByFileId(fileIds);
        setAllVersionsDownload(undefined);
      }
    });
  }

  const toggleItemChecked = (row: Report): void => {
    let tmpCheckedRows = [...checkedRows],
      index = tmpCheckedRows.findIndex((item) => item.globalId === row.globalId);
    if (index > -1) {
      tmpCheckedRows.splice(index, 1);
    } else {
      tmpCheckedRows.push(row);
    }
    setCheckedRows(tmpCheckedRows);
  };
  // console.log("🚀 ~ file: Reports.tsx ~ line 250 ~ toggleItemChecked ~ checkedRows", checkedRows)
  const allRowsChecked = tableData.length > 0 && checkedRows.length === tableData.length;

  const toggleAllRowsChecked = () => {
    if (allRowsChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows([...tableData]);
    }
  };

  useEffect(() => {
    setCloseFilters(f => f + 1);
  }, [filters]);

  useEffect(() => {
    setData([]);
    setTableData([]);
  }, [groupId]);

  const filterReports = (report: Report): boolean => {
    let matches = true;
    if (filters.startDate) {
      matches = moment(report.created).toDate() > filters.startDate;
    }
    if (matches && filters.endDate) {
      matches = moment(report.created).toDate() < filters.endDate;
    }
    if (matches && filters.equipment && filters.equipment.length > 0) {
      const filteredEquipmentIds = filters.equipment.map((d) => d.plantEquipmentId);
      const eqIds = (report.associatedEquipment || []).map((d) => d.equipmentId);
      let hasEq = false;
      for (let i = 0; i < eqIds.length && hasEq === false; i++) {
        hasEq = filteredEquipmentIds.includes(eqIds[i]);
      }
      matches = hasEq;
    }
    if (matches && filters.iStatus?.length) {
      matches = filters.iStatus.includes(report.implementationStatus);
    }
    if (matches && (user?.portalAdmin || pagePermissions.canAdmin) && !filters.isDeleted?.includes("Show Deleted")) {
      matches = report.isDeleted === false;
    }
    if (matches && filters.documentType) {
      matches = report.documentTypeId === filters.documentType.id;
    }
    return matches;
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
    } else {
      setFilter("");
    }
  }, [searchText]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    option?: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  let filterCount = 0;
  if (filters.equipment) {
    filterCount = filters.equipment.length;
  }

  if (filters.dateFilter) {
    filterCount++;
  }

  if (filters.iStatus?.length) {
    filterCount += filters.iStatus?.length;
  }
  if ((user?.portalAdmin || pagePermissions.canAdmin) && filters.isDeleted?.length) {
    filterCount += filters.isDeleted?.length;
  }
  if (filters.documentType) {
    filterCount++;
  }
  const tooltipId = useId().replaceAll(":", "_");

  const sortStyles: CSSProperties = useMemo(() => {
    const styles: CSSProperties = {};
    if (tableData.length === 0) {
      styles.pointerEvents = 'none';
      styles.border = 'none';
      styles.backgroundColor = '#F2F2F2';
      styles.cursor = 'default';
    }
    return styles;
  }, [tableData]);

  const bulkActionItems = useMemo(() => {
    return tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("BulkActions"))
                        .map(d => ({
                          ...d,
                          disabled: d.disabled || (d.text === 'Delete' && checkedRows.map(d => d.isDeleted).filter(d => d).length > 0)
                        }));
  }, [tooltipActions, checkedRows]);

  return (
    <div
      className={`${classes.flex_column} ${isDesktop ? classes.is_desktop : ""} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""
        }`}
      style={{ flexGrow: 1 }}
    >
      {/* Delete document modals */}
      {documentsToDelete && (
        <DeleteDocumentModal
          documentModule={documentModule}
          documents={documentsToDelete}
          onClose={() => setDocumentsToDelete(undefined)}
          confirm={confirmDelete}
        />
      )}
      {deletionCount > 0 && (
        <DeleteDocumentConfirmation documentModule={documentModule} deletionCount={deletionCount} onClose={() => setDeletionCount(0)} />
      )}
      {/* Move document modals */}
      {documentsToMove && (
        <MoveDocumentModal
          documentGroupName={group?.name || ""}
          documentGroups={documentGroups}
          documents={documentsToMove}
          onClose={() => setDocumentsToMove(undefined)}
          move={confirmMove}
        />
      )}
      {moveConfirmationMeta && (
        <MoveDocumentConfirmation moveConfirmationMeta={moveConfirmationMeta} onClose={() => setMoveConfirmationMeta(undefined)} />
      )}
      {allVersionsDownload !== undefined && allVersionsDownload > 0 && <DownloadMultipleFilesModal type="Document" fileCount={allVersionsDownload} />}
      {isLoading ? (
        <Loader containerStyle={{ marginTop: "100px", marginBottom: "100px" }} />
      ) : (
      <div className={`${classes.report_container} ${classes.flex_column}`}>
        <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
          <div className={`${classes.flex_row} ${classes.options_left}`}>
            {(isDesktop && !isTabletLandscape) && (
              <div className="nameplate-dropdown">
                <Dropdown dropdownStyle={sortStyles}
                  style={{
                    cursor: tableData.length === 0 ? 'default' : undefined
                  }}
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={handleSort}
                  selectValue={"Modified: Newest to Oldest"}
                  selectedOption={sortSelectedOption}
                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container} ${tableData.length === 0 ? classes.search_keyword_disabled : ''}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={classes.search_keyword}
                value={searchText}
                placeholder="Search documents"
                onChange={(e) => setSearchText(e.target.value)}
                disabled={tableData.length === 0}
              />
            </div>
            {!isMobile && (
              <div>
                {tableData.filter(filterFn).length} {tableData.filter(filterFn).length === 1 ? "Result" : "Results"}
              </div>
            )}
          </div>
          <div className={classes.filter_button} style={{ position: "relative" }}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} user={user!} />}
              disabled={tableData.length === 0}
            />
          </div>
        </div>
        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.iStatus?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      iStatus: filters.iStatus?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}
            
            {(user?.portalAdmin || pagePermissions.canAdmin) &&
              filters.isDeleted?.map((status) => (
                <Pill
                  className={classes.filter_pill}
                  key={status}
                  dismissible={true}
                  onDismiss={() =>
                    setFilters((filters) => {
                      return {
                        ...filters,
                        isDeleted: filters.isDeleted?.filter((d) => d !== status),
                      };
                    })
                  }
                  type="default"
                >
                  {status}
                </Pill>
              ))}
            {filters.equipment?.map((filteredEquipment) => (
              <Pill
                className={classes.filter_pill}
                key={filteredEquipment.plantEquipmentId}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      equipment: filters.equipment?.filter((d) => d.plantEquipmentId !== filteredEquipment.plantEquipmentId),
                    };
                  })
                }
                type="default"
              >
                {filteredEquipment.equipmentName}
              </Pill>
            ))}
            {filters.dateFilter && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, dateFilter: undefined, startDate: undefined })}
                type="default"
              >
                {DatePublishedFilters.find((d) => d.value === filters.dateFilter)?.label}
              </Pill>
            )}
            
            {filters.documentType && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, documentType: null })}
                type="default"
              >
                {filters.documentType.name}
              </Pill>
            )}
          </div>
        )}
        <div className={`${classes.results_container} ${classes.flex_column}`}>
          {isDesktop ? (
            <>
              <table className={classes.report_table}>
                <thead>
                  <tr>
                    <th>
                      <div className={`${classes.checkmark_container} ${classes.flex_row}`}>
                        <Checkbox isChecked={allRowsChecked} onChange={toggleAllRowsChecked} />
                        <div id={tooltipId} className={`${classes.bulk_action_button} ${classes.has_checked_rows}`}>
                          <img
                            src={downArrow}
                            alt="Bulk Actions"
                            onClick={() => setIsBulkActionTooltipVisible(!isBulkActionTooltipVisible)}
                          />
                            <BulkActionTooltip
                              shown={isBulkActionTooltipVisible}
                              setShown={setIsBulkActionTooltipVisible}
                              parentId={tooltipId}
                              listItems={bulkActionItems}
                              data={checkedRows}
                            />
                        </div>
                      </div>
                    </th>
                    {tableColumns.map((col) => (
                      <th key={col.title} style={col.style}>{col.title}</th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pagedData.filter(filterReports).map((row) => {
                    const actions = tooltipActions.filter(d => d.visible === undefined || d.visible(row));
                    return (
                      <ReportRowTable key={row.globalId}
                        unread={notifications.filter((alert) => alert.itemId === row.globalId && !alert.read).length > 0}
                        data={row}
                        columnDefinitions={tableColumns}
                        tooltipActions={actions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes('ItemActions'))}
                        isChecked={checkedRows.map((item) => item.globalId).includes(row.globalId)}
                        onRowChecked={() => toggleItemChecked(row)} />
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <div className={`${classes.flex_row} ${classes.accordion_heading} mb-6`}>
                <div className="">
                  <div className={`${classes.checkmark_container} ${classes.flex_row}`}>
                    <div className={classes.checkbox}>
                      <input type="checkbox" checked={allRowsChecked} onChange={() => { }} />
                      <span className={classes.checkmark} onClick={toggleAllRowsChecked} />
                    </div>
                    <div id={tooltipId} className={`${classes.bulk_action_button} ${classes.has_checked_rows}`}>
                      <img
                        src={downArrow}
                        alt="Bulk Actions"
                        onClick={() => setIsBulkActionTooltipVisible(!isBulkActionTooltipVisible)}
                      />
                        <BulkActionTooltip
                          shown={isBulkActionTooltipVisible}
                          setShown={setIsBulkActionTooltipVisible}
                          parentId={tooltipId}
                          listItems={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("BulkActions"))}
                          data={checkedRows}
                        />
                    </div>
                  </div>
                </div>
                <span>Report Name</span>
              </div>
              {pagedData.map((row) => {
                const actions = tooltipActions.filter(d => d.visible === undefined || d.visible(row));
                return (
                  <ReportRowAccordion
                    unread={notifications.filter((alert) => alert.itemId === row.globalId && !alert.read).length > 0}
                    key={row.globalId}
                    data={row}
                    isChecked={checkedRows.map((item) => item.globalId).includes(row.globalId)}
                    onRowChecked={() => toggleItemChecked(row)}
                    columnDefinitions={tableColumns.filter((column) => column.key !== "displayFileName")}
                    tooltipPortalRef={tooltipPortalRef}
                    tooltipActions={actions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                  />
                );
              })}
            </>
          )}
          {tableData.length > 0 ? (
            <Pagination
              currentPage={currentPageNumber}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              nextPage={nextPage}
              pageCount={pageCount}
              pageNumbers={pageButtonNumbers}
              previousPage={previousPage}
              resultCount={pagedData.length}
              resultsPerPage={resultsPerPage}
              setCurrentPage={setCurrentPageNumber}
              setResultsPerPage={setResultsPerPage}
              totalRecords={totalRecords} 
            />
          ) : isFetching ? (
            <div>Loading...</div>
          ) : (
            <div>No reports to display.</div>
          )}
        </div>
      </div>
      )}
      <div ref={tooltipPortalRef} />

      {showCopyLinkModal && copyLinkData.length &&
        <ModalGlobal
          modalSize="sm"
          modaltitle={`Copy Link to "${copyLinkData[0].displayFileName}"`}
          isModalHeader={true}
          modalClose={() => {
            setShowCopyLinkSuccess(false);
            setCopyLinkData([]);
          }}
        >
          <div className={`flex justify-between`}>
            <input type="text" style={{ color: 'black', width: '60%' }} className={`${classes.copy_link_textbox}`} disabled defaultValue={`${copyLinkBaseUrl}/${copyLinkData[0].fileId}`} />

            <Button
              className={`darkBlue text-sm`}
              text="Copy Link"
              preImg={<img src={copyLink} alt="Copy Link" className="invert hover:invert mr-2 h-5 w-5" />}
              onClick={() => {
                navigator.clipboard.writeText(`${copyLinkBaseUrl}/${copyLinkData[0].fileId}?userGroupId=${selectedUserGroup.id}`);
                setShowCopyLinkSuccess(true);
                setTimeout(() => {
                  setShowCopyLinkSuccess(false);
                  setCopyLinkData([]);
                }, 2000);
              }}
            />
          </div>

          {showCopyLinkSuccess &&
            <div className="flex justify-start content-start mt-4 text-sm">
              <img src={blueTick} alt="Link successfully copied" className="mr-2" />
              <span className="text-[#00749E]">Link successfully copied</span>
            </div>
          }
        </ModalGlobal>
      }

    </div>
  );
};

export default Reports;

const DatePublishedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "custom",
  },
];
interface Filters {
  equipment?: PlantEquipmentsDATA[];
  startDate?: Date;
  endDate?: Date;
  dateFilter?: string;
  unit?: number | false;
  documentType?: DocumentTypeOption | null;
  iStatus?: string[];
  isDeleted?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  user: any;
}
interface DocumentTypeOption {
  id: number; 
  name: string 
}
const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters, user }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const { plantConfig } = useContext(Store);
  const { hasOnePermission } = usePermissions();
  const [iStatus, setIStatus] = useState<{ value: boolean; name: string }[]>([]);
  const [dateFilter, setDateFilter] = useState<string>();  
  const [startDate, setStartDate] = useState(dayjs(null));
  const [endDate, setEndDate] = useState(dayjs(null));
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const { documentModule, documentGroups } = useOutletContext<DocumentsModuleOutletInterface>();
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState<DocumentTypeOption[]>([]);
  const [documentType, setDocumentType] = useState<DocumentTypeOption | null>(null);
  const [isDeleted, setIsDeleted] = useState<{ value?: boolean; name: string }[]>([
    {
      value: filters?.isDeleted?.includes("Show Deleted"),
      name: "Show Deleted",
    },
  ]);

  useGetDocumentTypes({
    onSuccess: (data) => {
      setDocumentTypeOptions(
        data.map((dt) => ({
          id: dt.id,
          name: dt.name,
        }))
      );
    },
    enabled: true,
  });

  const group = documentGroups.find((dg: { id: number; }) => dg.id === groupId);
  useQuery(
    [plantConfig.data.baseOrgId, "reports", group?.id, "getReports"],
    () => documentModule.documentApi.getAll(group?.id, plantConfig.data.baseOrgId),
    {
      onSuccess: (data: PlantPermissionedReports) => {
        setIStatus([]);
        if (documentModule.id !== ModuleIDs.TechnicalBulletins) return;

        var uniqueArray = data.items.map((r) => {
          return { value: false, name: r.implementationStatus };
        });

        if (!uniqueArray) return;
        uniqueArray = uniqueArray.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
        setIStatus(
          uniqueArray.map((s) => {
            var fil = filters.iStatus?.filter((p) => p == s.name);
            if (fil?.length && fil?.length > 0) s.value = true;
            return s;
          })
        );
      },
      enabled: !!group,
    }
  );

  useEffect(() => {
    if (filters.documentType) {
      setDocumentType(filters.documentType);
    }
    if (filters.dateFilter) {
      setDateFilter(filters.dateFilter);
      if (filters.dateFilter === "custom") {
        if (filters.startDate) {
          setStartDate(dayjs(filters.startDate));
        }
        if (filters.endDate) {
          setEndDate(dayjs(filters.endDate));
        }
      }
    }

    setIsDeleted(
      isDeleted.map((s) => {
        var fil = filters.isDeleted?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);
    const filters: Filters = {};
    if (documentType) {
      filters.documentType = documentType;
    }
    if (dateFilter) {
      if (dateFilter === "custom" && startDate && endDate) {
        filters.startDate = startDate.startOf("day").toDate();
        filters.endDate = endDate.endOf("day").toDate();
      } else {
        const startDate = moment().startOf("day");
        startDate.subtract(moment.duration(dateFilter));
        filters.startDate = startDate.toDate();
      }
      filters.dateFilter = dateFilter;
    }
    if (iStatus?.find((s) => s.value === true)) {
      filters.iStatus = iStatus.filter((s) => s.value).map((s) => s.name);
    }
    if (user.portalAdmin || hasOnePermission('UserGroupDocuments', 'admin')) {
      if (isDeleted?.find((s) => s.value === true)) {
        filters.isDeleted = isDeleted.filter((s) => s.value).map((s) => s.name);
      } else {
        filters.isDeleted = undefined;
      }
    }
    onFiltersApplied(filters);
  };

  const canApplyFilters =
    ((dateFilter) && !(dateFilter === "custom" && (!startDate || !endDate))) ||
    documentType ||
    filtersChanged ||
    iStatus.find((s) => s.value === true) ||
    isDeleted.find((s) => s.value !== undefined);

  const selectOptionE = (selectOption: string) => {
    const newState = iStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIStatus(newState);
  };

  const selectOptionD = (selectOption: string) => {
    const newState = isDeleted.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsDeleted(newState);
  };

  return (
    <div className="flex flex-col items-stretch p-6 gap-6 whitespace-nowrap min-w-[300px] max-h-[60vh] overflow-y-scroll !no-scrollbar filterContainer !overflow-x-visible">
      {(user?.portalAdmin || hasOnePermission('UserGroupDocuments', 'admin')) && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Show Deleted:</label>
          <div className={classes.checkboxWrapper}>
            {isDeleted.map((status, index) => (
              <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                <ToggleSwitch isChecked={status.value || false} label={status.name} onChange={() => selectOptionD(status.name)} />
              </div>
            ))}
          </div>
        </div>
      )}
      {iStatus.length > 0 && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Implementation Status:</label>
          <div className={classes.checkboxWrapper}>
            {iStatus
              .filter((filter) => filter?.name?.length > 0)
              .map((status, index) => (
                <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                  <Checkbox isChecked={status.value} label={status.name} onChange={() => { }} />
                </div>
              ))}
          </div>
        </div>
      )}
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Document Type:</label>
        <PlainDropDown
          className="relative hover:cursor-pointer"
          defaultText="Select a unit"
          selectClass={`flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded ${!documentType && 'text-[#999]'}`}
          optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full !overflow-y-visible"
          itemClass="py-[14px] px-6 cursor-pointer hover:!bg-primary-20 "
          itemSelectedClass="bg-primary-20"
          onSelection={(documentType) => setDocumentType(documentType?.id ? documentType : null)}
          value={documentType?.id}
          options={[{ id: false, name: "Select document type" }, ...documentTypeOptions]}
        />
      </div>      
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Date Published:</label>
        <RadioButtonGroup onChange={setDateFilter} name="date_published" options={DatePublishedFilters} value={dateFilter} />
      </div>
      {dateFilter === "custom" && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          showEndCalendar={showEndCalendar}
          showStartCalendar={showStartCalendar}
          startName="startDate"
          endName="endDate"
          startDateChange={setStartDate}
          endDateChange={setEndDate}
          setToggleEndCalendarDisplay={setShowEndCalendar}
          setToggleStartCalendarDisplay={setShowStartCalendar}
          inputClassName="w-full"
        />
      )}
      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
