import React, { useState, useContext } from "react";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import Dropdown from "../../components/Dropdown/Dropdown";
import useWindowSize from "../../customHooks/useWindowSize";
import { useOnError } from "../../customHooks/useOnError";
import { QuoteRequestFilter as QuoteRequestFilterInterface, QuoteRequestListData, QuoteRequestListDTO } from "../../utils/interface";
import QuoteRequestItem from "./QuoteRequestsItem";
import QuoteRequestFilter from "./QuoteRequestsFilter";
import QuoteRequestSearch from "./QuoteRequestsSearch";
import { QuoteRequestApi, useQuery_QuoteRequestItems } from "./QuoteRequestsHttpServices";
import classes from "./QuoteRequests.module.css";


const QuoteRequestList = () => {
const [qrList,setQrL] = useState<QuoteRequestListData[] | []>([]);
	const {
		QuoteRequestItems: {
			data: qrd
		},
		setQuoteRequestItems,
	} = useContext(Store);
	// const { data } = data || { items: [] };
	const [sortSelectedOption, setSortSelectedOption] = useState<string | null>("Newest to Oldest");
	const [searchText, setSearchText] = useState("");
	const [appliedFilters, setAppliedFilters] = useState<QuoteRequestFilterInterface>({});

	const { isDesktop, isMobile } = useWindowSize();

	const { selectedPlant } = useContext(Store);

	const setErrorData = useOnError();

	const onSuccess = (data: QuoteRequestListDTO) =>  {
		if(data.success){
			setQuoteRequestItems && setQuoteRequestItems(data);
			setQrL(data.data);
		}
	}
	const onError = (error: AxiosError<string>) => setErrorData(error);

	useQuery_QuoteRequestItems(selectedPlant.plantId, onSuccess, onError);

	const { refetch: refetchQuoteRequests } = useQuery(queryKeys.QuoteRequestItems, () => QuoteRequestApi.query(selectedPlant.plantId), {
		onSuccess,
		onError,
	});


	

	const filterData = (QuoteRequest: any) => {
		return (
			(appliedFilters?.includeDeleted === undefined || !QuoteRequest.isDeleted || appliedFilters?.includeDeleted) &&
			(!appliedFilters?.priority || QuoteRequest.priority === appliedFilters?.priority) &&
			((!appliedFilters?.includeOpen && !appliedFilters?.includeClosed) || (QuoteRequest.isOpen ? appliedFilters?.includeOpen : appliedFilters?.includeClosed)) &&
			((!appliedFilters?.includeNoReplies && !appliedFilters?.includeOneToFiveReplies && !appliedFilters?.includeMoreThanFiveReplies) ||
				(QuoteRequest.messageCount === 0
					? appliedFilters?.includeNoReplies
					: QuoteRequest.messageCount > 0 && QuoteRequest.messageCount < 5
					? appliedFilters?.includeOneToFiveReplies
					: QuoteRequest.messageCount > 5
					? appliedFilters?.includeMoreThanFiveReplies
					: true)) &&
			(!appliedFilters?.system || QuoteRequest.system === appliedFilters?.system) &&
			(!appliedFilters?.posted?.from || new Date(QuoteRequest.created).getTime() >= appliedFilters?.posted?.from?.getTime()) &&
			(!appliedFilters?.posted?.to || new Date(QuoteRequest.created).getTime() < new Date(new Date(appliedFilters?.posted?.to).setDate(appliedFilters?.posted?.to.getDate() + 1))?.getTime()) &&
			(!appliedFilters?.modified?.from || new Date(QuoteRequest.updated).getTime() >= appliedFilters?.modified?.from?.getTime()) &&
			(!appliedFilters?.modified?.to || new Date(QuoteRequest.created).getTime() < new Date(new Date(appliedFilters?.modified?.to).setDate(appliedFilters?.modified?.to.getDate() + 1))?.getTime())
		);
	};

	return (
		<div className="flex flex-col grow-1 justify-start rounded-b-lg ">
			
			<hr />
			<div className={`${!isMobile ? classes.flex_row_space_between : ""}`}>
				<div className={`${!isMobile ? classes.flex_row : ""} ${classes.options_left}`} style={{ marginBottom: 20 }}>
					{isDesktop && (
						<div className="nameplate-dropdown">
							<Dropdown
								dropdowntype={"Sort by:"}
								handleDropDownSelect={() => {}}
								selectValue={"Select"}
								selectedOption={sortSelectedOption}
								setSelectedOption={(sortOption: string | null) => setSortSelectedOption(sortOption)}
								options={[
									{
										id: 1,
										name: "Newest to Oldest",
									},
									{
										id: 2,
										name: "Oldest to Newest",
									},
								]}
							/>
						</div>
					)}
					<QuoteRequestSearch searchText={searchText} setSearchText={setSearchText} />
					{!isMobile && (
						<div>
							<div className={`${classes.table_icon}`}>{`${qrList?.length} Results`}</div>
						</div>
					)}
				</div>
				<div style={{ marginBottom: 12 }}>
					<QuoteRequestFilter appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
				</div>
			</div>
			<div className={`${isDesktop ? classes.table : ""}`}>
				{isDesktop && (
					<div>
						<div>
							<div>ID</div>
							<div>Type</div>
							<div>Request Description</div>
							<div>Equipment</div>
							<div>Requestor</div>
							<div>Submitted On</div>
							<div>Date Quote Needed</div>
							<div>Priority</div>
							<div>Status</div>
							<div>Details</div>
						</div>
					</div>
				)}
				<div>
				{qrd
						?.filter(filterData)
						?.filter((QuoteRequest: QuoteRequestListData) => QuoteRequest.description.toLowerCase().includes(searchText.toLowerCase()))
						?.map((QuoteRequest: QuoteRequestListData, index: number) => (
							<QuoteRequestItem key={index} index={index} QuoteRequest={{...QuoteRequest }} refetchQuoteRequest={refetchQuoteRequests} />
						))}
				</div>
				
			</div>
		</div>
	);
};

export default QuoteRequestList;
