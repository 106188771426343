import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";


export const usePageRequiresInternalAdmin = () => {
  const {header: {data: {user}}} = useContext(Store);
  const navigate = useNavigate();
  
  useEffect(() => {
      if (user.userId > 0 && (!user.mpInternal || !user.portalAdmin)) {
          navigate('/', {replace: true});
      }
  }, [user]);
};