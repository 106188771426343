
import React, {ReactElement} from 'react';
import classes from "./ManageRoles.module.css";
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import EmptyModal from '../Modals/EmptyModal';
import { Role } from '../../utils/interface';

interface DeleteRoleConfirmationModalProps {
    shown: boolean;
    role: Role;
    onClose: () => void;
    onDelete: () => void;
}

function DeleteRoleConfirmationModal({shown, role, onClose, onDelete}: DeleteRoleConfirmationModalProps) {
    const {isMobile} = useWindowSize();

    let width: number = 564;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (shown) {
        modalBody = (
            <>
                <span className={classes.is_mobile}>Are you sure you want to delete the role "{role.name}" Customer Portal? This cannot be undone.</span>
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                    <Button className="redDelete" text="Yes, Delete" onClick={onDelete} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={shown} body={modalBody} footer={modalFooter} heading="Delete Role?" onClose={onClose} width={width} footerClass={classes.modal_footer} />
    );
}

export default DeleteRoleConfirmationModal;