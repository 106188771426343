import { useContext } from "react";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import classes from "./TextInputField.module.css";
import ImageImports from "../../../../utils/ImageImports";
import { Store } from "../../../../Store";
import React from "react";

const TextInputField = (props: TextInputField) => {
  const [field,meta] = useField(props);
  const stateContext = useContext(Store);
  const { tooltip } = ImageImports;

  let styles ;
  if(props.className === "marginbottom0"){
    styles = {
      marginBottom:0
    }
  }
  return (
    <div className={`${classes.form_field_row}`} style={styles}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.showTooltip && (
          <div
            className={classes.cursor_pointer}
            // onBlur={() => {
            //   if (stateContext.toggleTooltip !== undefined) {
            //     stateContext.toggleTooltip(false);
            //   }
            // }}
            tabIndex={1}
            onClick={() => {
              if (stateContext.toggleTooltip !== undefined) {
                stateContext.toggleTooltip(!stateContext.toolTip);
              }
            }}
          >
            <img
              src={tooltip}
              className={classes.tooltip_icon}
              alt="tool tip"
            />
          </div>
        )}
      </label>
      <div className={`${classes.form_group}` }>
        <Field
          id={props.name}
          type={props.type}
          className={meta.touched && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}
          name={props.name}
          disabled={props.disabled}
          placeholder={props.placeholder}
          autoComplete="off"
        />
        {
          props.details && <small className={classes.small} style={{marginTop:"8px"}}>{props.details}</small>
        }
        
        {/* <ErrorMessage name={props.name}>
          {(msg) => <div className={classes.error_text}>{msg}</div>}
        </ErrorMessage> */}
      </div>
    </div>
  );
};

interface TextInputField {
  name: string;
  labelName: string;
  placeholder?: string;
  showTooltip: boolean;
  disabled?:boolean;
  className:string | "";
  details?:string;
  type: "text" | "date" | "number" |"email" | "tel" | "textarea" 
}

TextInputField.defaultProps = {
  showTooltip: false,
  disabled:false,
  type:"text"
};

export default TextInputField;
