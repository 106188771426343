import { PlantConfigDTO, PlantConfigData } from "../../utils/interface";
import { apiPlantConfig, apiPlantConfigImage } from "../../endpoints/endpoints";

import axios, {  AxiosResponse } from "axios";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { useContext } from "react";
import { Store } from "../../Store";
import { useUserGroupIdHeader } from "../../customHooks/useUserGroupIdHeader";

export interface PlantConfigCrudMethods<T, TD, B> {
  update: (data: TD) => Promise<T>;
  create: (data: TD) => Promise<T>;
  getFile: (plantId: number, baseOrgId?: number) => Promise<B>;
}

export const PlantConfigApi: PlantConfigCrudMethods<AxiosResponse<PlantConfigDTO>, PlantConfigData, any> = {

  update: async (data: PlantConfigData): Promise<AxiosResponse<PlantConfigDTO>> => {
    const response = await axios({
      url: `${apiPlantConfig}/${data.plantId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  create: async (data: PlantConfigData): Promise<AxiosResponse<PlantConfigDTO>> => {
    const response = await axios({
      url: `${apiPlantConfig}/${data.plantId}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  getFile: async (plantId: number, baseOrgId?: number): Promise<any> => {
    const response = await axios({
      url: `${apiPlantConfigImage}/${plantId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        baseorg: `${baseOrgId}`
      }
    }); 
    return response.data;
  }  
};


declare type getSelectedPlantConfigParameters = [string, {plantId: number, baseOrgId: number}];
const getSelectedPlantConfig = async ({queryKey}: QueryFunctionContext<getSelectedPlantConfigParameters>): Promise<PlantConfigDTO> => {
    const [_key, {plantId}] = queryKey;
    if (!plantId) {
        return Promise.reject(new Error('Invalid Plant Id'));
    }
    const response: AxiosResponse<PlantConfigDTO> = await axios(`${apiPlantConfig}/${plantId}`);
    return response.data;
};
export const usePlantConfigData = (onSuccess: any, onError: any) => {
  const {selectedPlant} = useContext(Store);
  const hasUserGroupIdForApiCalls = useUserGroupIdHeader();
  return useQuery([queryKeys.plantConfig, {plantId: selectedPlant.plantId, baseOrgId: selectedPlant.baseOrgId}], getSelectedPlantConfig, {
    enabled: selectedPlant.plantId > 0 && hasUserGroupIdForApiCalls,
    onSuccess,
    onError,
  });
};

export const useGetFile_PlantConfigData = (plantId: number, onSuccess: any, onError: any, baseOrgId?: number) => {
  return useQuery([queryKeys.plantConfigImage, plantId], () => PlantConfigApi.getFile(plantId, baseOrgId), {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};

export const useUpdate_PlantConfigData = () => {
  return useMutation(PlantConfigApi.update);
};
