import { pick } from "./pick";

export const searchJsonObjects = (searchIn: any, searchText: string): any => {
  let results = searchIn.filter((item: any) => {
      return searchJsonObject(item, searchText);
  });
  
  return results;
}

function searchJsonObject<T>(obj: T, searchText: string): boolean {
  for (let key in obj) {
      if (`${obj[key]}`.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
          return true;
      }
  }
  return false;
}
export {searchJsonObject};

export const searchArrayObjects = (objKeysToSearch: string[], ArrayObject: Object[], searchText: string): any => {
  let results = ArrayObject.filter((item: any) => {
    return (
      objKeysToSearch.some((key) => item[key].toLowerCase().includes(searchText.toLowerCase()))
    )
  });
  return results;
}



export const searchJsonObjectKeys = <T, C extends keyof T>(obj: T, searchText: string, searchKeys: C[]): boolean => {
  return searchJsonObject(pick(obj, searchKeys), searchText);
};