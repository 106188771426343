import { useOutletContext } from 'react-router-dom';
import Link from '../../components/Link/Link';
import ImageImports from '../../utils/ImageImports';
import { OutletInterface } from './ThreadContainer';
import classes from './Thread.module.css';
import Message from './Message';
import useWindowSize from '../../customHooks/useWindowSize';
import {useState, useEffect, useContext} from 'react';
import { ThreadMessage } from '../../utils/interface';
import Pill from '../../components/Pill/Pill';
import { Store } from '../../Store';
import ParticipantList from './ParticipantList';

interface ReportsProps {}

const {Arrow, userGroup: icon} = ImageImports;

const getParticipantsFromMessage = (message: ThreadMessage): string[] => {
	if (message.createdBy){
		let participants = [message.createdBy];
		if (message.messages) {
			message.messages.map(message => {
				participants = participants.concat(getParticipantsFromMessage(message));
			});
		}
		// only return unique participants...
		return participants.filter((value, index, self) => self.indexOf(value) === index);
	}
	else 
	{
		return [];
	}
}

function Thread(props: ReportsProps) {
    const {setToolTipContent, toolTip, toggleTooltip} = useContext(Store);
    const {message, parentLocation, title} = useOutletContext<OutletInterface>();
    const [participants, setParticipants] = useState<string[]>([]);
    const [participantLabel, setParticipantLabel] = useState<string>('Participants');
    const {isMobile} = useWindowSize();

    const showParticipants = () => {
        setToolTipContent && setToolTipContent(<ParticipantList users={participants} />);
        toggleTooltip && toggleTooltip(!toolTip);
    }

    useEffect(() => {
        if (message) {
            setParticipants(getParticipantsFromMessage(message));
        }
    }, [message]);

    useEffect(() => {
        setParticipantLabel(`${participants.length} Participant${participants.length === 1? '' : 's'}`)
    }, [participants]);

    return (
        <div className={`${classes.thread} ${classes.flex_column}`}>
            <div className={`${classes.top_section} ${classes.flex_column}`}>
                <Link to={parentLocation} className={`${classes.back_container} ${classes.flex_row}`}>
                    <img src={Arrow} className="back-arrow" alt={`Back to All ${title}`} />
                    Back to All {title}
                </Link>
                <div className={`${classes.heading_container}`}>
                    <div className={`${classes.heading}`}>
                        Discussion for {title} #{message?.id}: {message?.title}{' '}
                            <Pill type="success">Closed</Pill>
                    </div>
                    {!isMobile && (
                        <div className={`${classes.heading_support_text}`}>
                            {message?.content} 
                        </div>
                    )}
                </div>
                <div className={`${classes.line}`} />
            </div>
            <div className={`${classes.flex_column}`}>
                <div className={`${classes.message_container} ${classes.flex_column}`}>
                    <div className={`${classes.contributors} ${isMobile? classes.flex_column : classes.flex_row}`}>
                        <div>
                            <b>Start of Discussion</b> (oldest to newest)
                        </div>
                        <div className={`${classes.participants} ${classes.flex_row}`} onClick={showParticipants}>
                            <img src={icon} alt={participantLabel} />
                            {participantLabel}
                        </div>
                    </div>
                    <div className={`${classes.discussion} ${classes.flex_column}`}>
                        {message?.messages?.map(threadMessage => (
                            <Message message={threadMessage} key={threadMessage.id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Thread;
