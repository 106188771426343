import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { apiAnnouncementUrl } from "../../endpoints/endpoints";
import { Store } from "../../Store";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { Announcement, CPApiResponse } from "../../utils/interface";
import { AnnouncementContainer } from "./AnnouncementContainer";

declare type GetAnnouncementsParameters = ['announcements', {userGroupId: number}];
const getAnnouncements = async ({queryKey}: QueryFunctionContext<GetAnnouncementsParameters>): Promise<Announcement[]> => {
    const [_key, {userGroupId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<Announcement[]>> = await axios(`${apiAnnouncementUrl}/List/${userGroupId}`);
    return handleAxiosResponse(response);
};
export const useGetAnnoucements = (options: Omit<UseQueryOptions<Announcement[], unknown, Announcement[], GetAnnouncementsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    const {selectedUserGroup} = useContext(Store);
    return useQuery<Announcement[], unknown, Announcement[], GetAnnouncementsParameters>(['announcements', {userGroupId: selectedUserGroup.id}], getAnnouncements, options);
};


const createAnnouncement: MutationFunction<Announcement, Announcement> = async (announcement: Announcement): Promise<Announcement> => {
    const response: AxiosResponse<CPApiResponse<Announcement>> = await axios(apiAnnouncementUrl, {
        method: 'POST',
        data: {
            displayOrder: announcement.displayOrder,
            messageContent: announcement.messageContent,
            title: announcement.title,
            userGroupId: announcement.userGroupId > 0? announcement.userGroupId : undefined
        }
    });
    return handleAxiosResponse(response);
};

export const useCreateAnnouncement = (options: Omit<UseMutationOptions<Announcement, AxiosError<string>, Announcement>, 'mutationFn'> = {}) => {
    const {mutate, isError, isSuccess, reset} = useMutation<Announcement, AxiosError<string>, Announcement>(createAnnouncement, options);
    return {
        createAnnouncement: mutate,
        isCreateAnnouncementError: isError,
        isCreateAnnouncementSuccess: isSuccess,
        resetCreateAnnouncement: reset
    };
};

const updateAnnouncement: MutationFunction<Announcement, Announcement> = async (announcement: Announcement): Promise<Announcement> => {
    const response: AxiosResponse<CPApiResponse<Announcement>> = await axios(`${apiAnnouncementUrl}/${announcement.id}`, {
        method: 'PUT',
        data: announcement
    });
    return handleAxiosResponse(response);
};

export const useUpdateAnnouncement = (options: Omit<UseMutationOptions<Announcement, AxiosError<string>, Announcement>, 'mutationFn'> = {}) => {
    const {mutate, isError, isSuccess, reset} = useMutation<Announcement, AxiosError<string>, Announcement>(updateAnnouncement, options);
    return {
        updateAnnouncement: mutate,
        isUpdateAnnouncementError: isError,
        isUpdateAnnouncementSuccess: isSuccess,
        resetUpdateAnnouncement: reset
    };
};

const deleteAnnouncement: MutationFunction<boolean, Announcement> = async (announcement: Announcement): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiAnnouncementUrl}/${announcement.id}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};

export const useDeleteAnnouncement = (options: Omit<UseMutationOptions<boolean, AxiosError<string>, Announcement>, 'mutationFn'> = {}) => {
    const {mutate, isError, isSuccess, reset} = useMutation<boolean, AxiosError<string>, Announcement>(deleteAnnouncement, options);
    return {
        deleteAnnouncement: mutate,
        isDeleteAnnouncementError: isError,
        isDeleteAnnouncementSuccess: isSuccess,
        resetDeleteAnnouncement: reset
    };
};