import { Announcement } from "../../utils/interface";
import ImageImports from "../../utils/ImageImports";
import Moment from "react-moment";
import { RequiresAdmin } from "../Auth/RequiresAdmin";

const { announcement: announcementIcon, pencil } = ImageImports;

interface AnnouncementItemProps {
    announcement: Announcement;
    onEdit: (announcement: Announcement) => void;
}
export const AnnouncementItem = ({announcement, onEdit}: AnnouncementItemProps) => {
    return (
        <div className="flex flex-col items-start p-6 gap-8 bg-white shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] rounded-lg h-full">
            <div className="flex flex-col items-start gap-6 self-stretch">
                <div className="flex flex-row !pb-0 justify-between pr-2 gap-4 self-stretch">
                    <div className="bg-primary-20 rounded h-10 w-10 flex items-center justify-center">
                        <img src={announcementIcon} alt="Announcement" style={{filter: 'var(--svgFilterLightBlueButton)'}} />
                    </div>
                    <RequiresAdmin>
                        <img src={pencil} alt="Announcement" style={{filter: 'var(--svgFilterLightBlueButton)'}} className="cursor-pointer" onClick={() => onEdit(announcement)} />
                    </RequiresAdmin>
                </div>
                <span className="font-bold text-2xl">{announcement.title}</span>
                <div className="flex flex-col items-start gap-2 self-stretch">
                    <span className="text-sm text-[#808080]"><Moment date={announcement.modified ?? announcement.created ?? undefined} format="MM/DD/YYYY" /></span>
                    <span className="text-sm">{announcement.messageContent}</span>
                </div>
            </div>
        </div>
    );
};