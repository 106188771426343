import { AxiosError } from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import {
  useDelete_PlantEquipmentAttachment,
  useGet_PlantEquipmentAttachment,
} from "../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { apiGetEquipmentAttachmentFile } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { IdObj, PlantEquipmentAttachmentDTO } from "../../utils/interface";
import ErrorCard from "../ErrorCard/ErrorCard";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Nameplate.module.css";

const NamePlateDeleteModal = ({ namePlateData, IdObj }: NamePlateDeleteModalProps) => {
  const stateContext = useContext(Store);
  const { isModal, setIsModal, plantEquipmentsAttachment, setPlantEquipmentsAttachment,showError } = stateContext;
  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  //{***************************************************************************************************************************}

  const onSuccessEquipmentsAttachment = (data: PlantEquipmentAttachmentDTO) => {
    setPlantEquipmentsAttachment && setPlantEquipmentsAttachment(data);
  };
  const onErrorEquipmentsAttachment = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useGet_PlantEquipmentAttachment(IdObj.attachmentid, true, onSuccessEquipmentsAttachment, onErrorEquipmentsAttachment);

  //{***************************************************************************************************************************}

  const { mutate: deleteAttachments } = useDelete_PlantEquipmentAttachment();

  const handleDeleteAttachment = () => {
    deleteAttachments(IdObj, {
      onError: (error: unknown) => {
        setErrorData(error);
      },
      onSuccess: (responseData) => {
        queryClient.invalidateQueries([queryKeys.plantEquipment, IdObj.equipmentid]);
        setIsModal!({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDeleteSuccess,
            isNameplate: true,
          },
        });
      },
    });
  };
  const handleModalClose = () => {
    setIsModal!({
      ...CloseAllModal,
    });
  };

  let deleteModalContent;

  deleteModalContent = (

    <>
    {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
      <div className={classes.deleteModalText}>
        Are you sure you want to delete the attachment “{plantEquipmentsAttachment.data.name}”? This cannot be undone.
      </div>

      <div className={classes.row}>
        <div className={classes.imageWrapperLeft}>
          <img style={{ maxWidth: "100%" }} src={`${apiGetEquipmentAttachmentFile}/${IdObj.attachmentid}`} alt={namePlateData.name} />
        </div>
        <div className={classes.imageWrapperRight}>
          <div className={classes.tbl}>
            <div className={classes.tbl_cell}>File Type</div>
            <div className={classes.tbl_cell}>{plantEquipmentsAttachment.data.fileTypeName}</div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tbl_cell}>Date Uploaded</div>
            <div className={classes.tbl_cell}>{`${moment(plantEquipmentsAttachment.data.created).format("YYYY-MM-DD")}`}</div>
          </div>
        </div>
      </div>
      <div className="flex align-center">
        <div className="flex-grow-1 flex">
          <button type="button" className="primaryBtn redDelete" onClick={handleDeleteAttachment}>
            Yes Delete
          </button>
          <button onClick={handleModalClose} className="primaryBtn whiteBtn marginLeft16">
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  return (
    <ModalGlobal modaltitle={"Delete Namplate Asset"} isModalHeader={true}>
      {deleteModalContent}
    </ModalGlobal>
  );
};

interface NamePlateDeleteModalProps {
  IdObj: IdObj;
  namePlateData: {
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  };
}

export default NamePlateDeleteModal;
