import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Store } from "../../Store";
import { useGetAllByUnitID_PlantEquipments, useGet_PlantEquipment } from "../../containers/PlantInformation/EquipmentsHttpServices";
import { useGet_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { PlantEquipmentDTO, PlantEquipmentsDTO, UnitDTO } from "../../utils/interface";
import EquipmentAccordian from "../Accordian/EquipmentAccordian/EquipmentAccordian";
import Dropdown from "../Dropdown/Dropdown";
import PlantSiteTable from "../Tables/PlantSiteTable";
import PlantSiteDataBlock from "./PlantSiteDataBlock/PlantSiteDataBlock";
import "./PlantSiteDetails.css";
import classes from "./PlantSiteDetails.module.css";
import PlantSiteDetailsTop from "./PlantSiteDetailsTop/PlantSiteDetailsTop";
import usePlantSiteDetailsApiCalls from "./usePlantSiteDetailsApiCalls";

const tableColumns = ["Equipment Name", "Equipment ID", "Product Type", "Manufacturer", "Model Name", "Nameplates"];

function PlantOverview({ overview }: OverviewProps) {
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number>(0);
  const [, setSelectedOption] = useState<string | null>("");
  /////////////////////////////////////////
  // API Calls For PlantSiteDetails Start//
  /////////////////////////////////////////

  usePlantSiteDetailsApiCalls();

  /////////////////////////////////////////
  // API Calls For PlantSiteDetails End//
  /////////////////////////////////////////


  const [paramsUnit, setParamsUnit] = useState(0);

  const { isMobile, isTablet, isDesktop, isTabletLandscape } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedPlant,
    setNameplate,
    unitTypes,
    setUnitTypes,
    unitGetAll,
    setEquipmentTypes,
    unit,
    setUnit,
    setPlantEquipment,
    plantEquipment,
    plantEquipments,
    setPlantEquipments,
    setSelectedPlantDetails,
    selectedPlantDetails,
  } = useContext(Store);
  const setErrorData = useOnError();

  const { plantId } = selectedPlantDetails?.data;

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments Start
  ///////////////////////////////////////////

  const onSuccessPlantEquipment = (data: PlantEquipmentDTO) => {
    setPlantEquipment && setPlantEquipment(data);
  };
  const onErrorPlantEquipment = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  useGet_PlantEquipment(selectedEquipmentId as number, onSuccessPlantEquipment, onErrorPlantEquipment);

  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  //{***************************************************************************************************************************}

  //{***************************************************************************************************************************}

  const deleteSuccess = `${plantEquipment.data.equipmentName} was successfully deleted from the Brunswick County equipment list.
  Would you like to add another piece of equipment at this time?`;

  const openEquipmentsAttachments = (plantEquipmentId: number) => {
    setNameplate && setNameplate({ id: plantEquipmentId });
  };

  useEffect(() => {
    if (unitGetAll && unitGetAll.data.length > 0) {
      setSearchParams({unitId: `${unitGetAll.data[0].unitId}`});
      setParamsUnit(unitGetAll.data[0].unitId);
      const UnitSelectOptions = unitGetAll.data.map((unit) => {
        return {
          id: unit.unitId,
          name: unit.name,
        };
      });

      if (UnitSelectOptions) {
        setUnitTypes && setUnitTypes({ data: [...UnitSelectOptions] });
      }
    } else {
      setSearchParams();
      setUnitTypes && setUnitTypes({ data: [] });
      setUnit &&
        setUnit({
          ...unit,
          data: null,
        });
      setParamsUnit(0);
      setPlantEquipments &&
        setPlantEquipments({
          ...plantEquipments,
          data: [],
        });
      setEquipmentTypes && setEquipmentTypes({ data: [] });
    }
  }, [setUnitTypes, unitGetAll]);

  useEffect(() => {
    const currentParams = searchParams.get("unitId");
    if (currentParams) {
      setParamsUnit(parseInt(currentParams));
    }
  }, [paramsUnit, searchParams]);

  //{***************************************************************************************************************************}

  const onSuccessUnitData = (data: UnitDTO) => {
    setUnit && setUnit(data);
  };

  const onErrorUnitData = (error: AxiosError<string>) => {
    setUnit &&
      setUnit({
        ...unit,
        data: null,
      });
    setErrorData(error);
  };

  useGet_UnitData(paramsUnit, onSuccessUnitData, onErrorUnitData);

  // couldnt test locally - intent is to clear selectedPlantDetails on failure (currently holding prev plant data) -- will remove comment on test confirm
  useEffect(() => {
    if (!selectedPlantDetails?.success && plantId !== selectedPlant.plantId)
      setSelectedPlantDetails &&
        setSelectedPlantDetails({
          ...selectedPlantDetails,
          data: {
            plantId: selectedPlant.plantId,
            plantLoad: "",
            ambientTemperatureFahrenheit: "",
            ambientTemperatureCelsius: "",
            lastOutage: undefined,
            nextPlannedOutage: undefined,
          },
        });
  }, [selectedPlant, plantId, selectedPlantDetails, setSelectedPlantDetails]);

  //{***************************************************************************************************************************}

  const onSuccessPlantEquipments = (data: PlantEquipmentsDTO) => {
    setPlantEquipments && setPlantEquipments(data);
    const plantEquipmentTypes = data.data.map((equipment) => {
      return {
        id: equipment.plantEquipmentId,
        name: equipment.equipmentName,
      };
    });
    if (plantEquipmentTypes) {
      setEquipmentTypes && setEquipmentTypes({ data: [...plantEquipmentTypes] });
    }
  };
  const onErrorPlantEquipments = (error: AxiosError<string>) => {
    setPlantEquipments &&
      setPlantEquipments({
        ...plantEquipments,
        data: [],
      });
    setEquipmentTypes && setEquipmentTypes({ data: [] });
    setErrorData(error);
  };
  useGetAllByUnitID_PlantEquipments(paramsUnit, onSuccessPlantEquipments, onErrorPlantEquipments);

  //{***************************************************************************************************************************}

  const handleUnitSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    const sortedtypedata = e.currentTarget.getAttribute("data-id");
    if (sortedtypedata) {
      const newParams = { unitId: sortedtypedata };
      setSearchParams(newParams);
      setParamsUnit(parseInt(sortedtypedata));
    }
  };

  return (
    <>
      {
        <div className={`card card-county plant-detail ${classes.remove_outline} ${overview ? "" : "plant-details-main row-to-col"}`}>
          <PlantSiteDetailsTop />

          {unit.data && (
            <>
              <hr />
              <section>
                <div className={classes.plant_info_header}>
                  <strong>Configuration Details</strong>
                  <p>Plant Arrangement and Unit</p>
                </div>
                <div className={classes.unitWrapper}>
                  <div className={`${classes.flex} ${classes.actionwrapper} ${classes.justify_space_between}`}>
                    {/*************     Need to look at this response to no units being returned                          jlh ************************/}
                    {/*************     Causing user to think the function is broken and unresponsive                     jlh ************************/}
                    {/*************     Should return "no Units Avialable not add the "disable" class to the dropdown     jlh ************************/}
                    <div
                      className={unit.data ? `nameplate-dropdown  inline-flex unitDropdown` : `nameplate-dropdown disabled inline-flex unitDropdown`}
                    >
                      <Dropdown
                        dropdowntype={""}
                        options={unit.data ? unitTypes.data : []}
                        handleDropDownSelect={handleUnitSelection}
                        className="flex flex-row pt-1 pr-2 pl-1 lg:min-w-[240px] justify-between border border-solid border-[#999] w-full rounded"
                        selectedOption={unit.data ? unit.data.name : "No Units Avialable"}
                        setSelectedOption={setSelectedOption!}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div className="col-to-row config-details-cta">
                <div className="row-to-col flex-grow-1" style={{ paddingRight: "24px" }}>
                  <span className={isTabletLandscape || isTablet || isMobile ? "marginBot24" : "marginBot32"}>
                    <PlantSiteDataBlock label={"Notes"} value1={unit.data && unit.data.notes ? unit.data.notes : "--"} />
                  </span>
                </div>
              </div>

              <div className="col-to-row config-details config-section-wrapper flex-start marginBot32">
                <div className="row-to-col config-section">
                  <div className="config-header">Unit Configuration</div>
                  <div className="config-detail">{unit.data ? unit.data.unitConfigurationName : "--"}</div>
                </div>
                <div className="row-to-col config-section">
                  <div className="config-header">Output Potential</div>
                  <div className="config-detail">{unit.data ? unit.data.totalMW : "--"}</div>
                </div>
                <div className="row-to-col config-section">
                  <div className="config-header">Fuel Type</div>
                  <div className="config-detail">{unit.data ? unit.data.fuelTypeName : "--"}</div>
                </div>
                <div className="row-to-col config-section">
                  <div className="config-header">Direct Ownership</div>
                  <div className="config-detail">{unit.data ? unit.data.directOwnership : "--"}</div>
                </div>
                <div className="row-to-col config-section">
                  <div className="config-header">Parent/Group Owner</div>
                  <div className="config-detail">{unit.data ? unit.data.parentGroupOwnership : "--"}</div>
                </div>
                <div className="row-to-col config-section">
                  <div className="config-header">CO Date</div>
                  <div className="config-detail">{unit.data ? unit.data.coDate : "--"}</div>
                </div>
              </div>

              {isDesktop ? (
                <PlantSiteTable tableColumns={tableColumns} tableData={plantEquipments} openEquipmentsAttachments={openEquipmentsAttachments} />
              ) : (
                <EquipmentAccordian panels={plantEquipments} openEquipmentsAttachments={openEquipmentsAttachments} />
              )}
            </>
          )}
        </div>
      }
    </>
  );
}
interface OverviewProps {
  overview: boolean;
}
export default PlantOverview;
