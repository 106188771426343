import React, { useRef, useState } from "react";
import Portal from "../../../Portal";
import classes from "./MiniTooltip.module.css";

const position = (p: string) => {
  return {
    current: p,
    flip() {
      if (this.current === "left") return "right";
      if (this.current === "right") return "left";
      if (this.current === "top") return "bottom";
      else return "top";
    },
    isXaxis() {
      return this.current === "left" || this.current === "right";
    },
    isYaxis() {
      return this.current === "top" || this.current === "bottom";
    },
  };
};

interface pointInterface {
  x: number | null;
  y: number | null;
  reset?: (p: any) => void;
  restrictRect?: (recBoundry: any) => void;
}

interface recBoundryInterface {
  l: number;
  t: number;
  r: number;
  b: number;
}

const point = () => ({
  x: null,
  y: null,
  reset(p: any) {
    
    this.x = p.x;
    this.y = p.y;
  },
  restrictRect(recBoundry: any) {
    if(!(this && this.x && this.y)) return;
    if (this.x < recBoundry.l) this.x = recBoundry.l;
    else if (this.x > recBoundry.r) this.x = recBoundry.r;
    if (this.y < recBoundry.t) this.y = recBoundry.t;
    else if (this.y > recBoundry.b) this.y = recBoundry.b;
  },
});
const getPoint = (el: EventTarget & HTMLElement, tt: HTMLElement, placement: string, space: number): { x: number | null; y: number | null } => {
  let repeatLoopCounter = 0;
  const pt: any = point();

  //this is just the boundry the tooltip should respect. if not it will flip;
  const recBoundry: recBoundryInterface = {
    l: space,
    t: space,
    r: document.body.clientWidth - (tt.clientWidth + space),
    b: window.innerHeight - (tt.clientHeight + space),
  };

  const elRect = el?.getBoundingClientRect();

  return (function repeat(placement) {
    repeatLoopCounter++;
    const pos = position(placement);

    switch (pos.current) {
      case "left":
        pt.x = elRect.left - (tt.offsetWidth + space);
        pt.y = elRect.top + (el.offsetHeight - tt.offsetHeight) / 2;
        break;
      case "right":
        pt.x = elRect.right + space;
        pt.y = elRect.top + (el.offsetHeight - tt.offsetHeight) / 2;
        break;
      case "top":
        pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
        pt.y = elRect.top - (tt.offsetHeight + space);
        break;
      default:
        pt.x = elRect.left + (el.offsetWidth - tt.offsetWidth) / 2;
        pt.y = elRect.bottom + space;
    }

    // calling getPoint function inside getPoint function can create endless loop below condition will block it will also addd counter to loop max 3 times
    if (repeatLoopCounter < 3) {
      // if the boundry is not respected we will call getPoint fuc again to flip the position
      if (
        // checking if tooltip is in  boundry on x axis
        (pos.isXaxis() && pt.x && (pt.x < recBoundry.l || pt.x > recBoundry.r)) ||
        // checking if tooltip is in  boundry on y axis
        (pos.isYaxis() && pt.y && (pt.y < recBoundry.t || pt.y > recBoundry.b))
      ) {
        pt.reset(repeat(pos.flip()));
      }
    }

    // if tooltip overflow the viewport push that tool tip inside the our boundry
    pt.restrictRect(recBoundry);

    return pt;
  })(placement);
};

const MiniTooltip = ({ text, children, placement = "top", space = 10, disabled = 0 }: MiniTooltipProps) => {
  const [show, setShow] = useState<number>(0);
  const posRef = useRef<any>({ x: 0, y: 0 });
  const tooltipRef = useRef<any>();

  const handleMover = (e: React.MouseEvent<HTMLElement>) => {
    setShow(1);
    posRef.current = getPoint(e.currentTarget, tooltipRef.current, placement, space);
  };
  const handleMOut = () => {
    setShow(0);    
  };
  return (
    <>
      {/* if tooltip is disabled just return the children without modifying it */}
      {disabled
        ? children
        : React.cloneElement(children as React.ReactElement<any>, {
            onMouseOver: handleMover,
            onMouseOut: handleMOut,
          })}
      {/* if tooltip is disabled hide it */}
      {disabled || (
        <Portal>
          <span
            ref={tooltipRef}
            className={classes.tooltipDropdown}
            style={{
              top: `${posRef.current.y}px`,
              left: `${posRef.current.x}px`,
              opacity: `${show}`,              
              transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1) !important",
              transitionDelay: `${show ? 0.02 : 0.03}s !important`,
              transformOrigin: `${position(placement).flip()}`,
              transform: `scale(${show ? 1 : 0.6})`,
            }}
          >
            {text}
          </span>
        </Portal>
      )}
    </>
  );
};

interface MiniTooltipProps {
  text: string;
  space?: number;
  children?: React.ReactNode;
  placement?: "bottom" | "top" | "right" | "left";
  disabled?: 0 | 1;
}
export default MiniTooltip;
