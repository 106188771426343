import { group } from "console";
import React, { CSSProperties, FunctionComponent, ReactElement, useContext, useEffect, useId, useRef, useState } from "react";
import classes from "../Reports/Reports.module.css";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Pill from "../../components/Pill/Pill";
import useWindowSize from "../../customHooks/useWindowSize";
import usePagination from "../../customHooks/usePagination";
import ImageImports from "../../utils/ImageImports";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import { CPApiResponse, ListGroup, UserColumnDefinition, User } from "../../utils/interface";
import ListRowTable from "./ListRowTable";
import { useQuery } from "react-query";
import { ListModuleInterface } from "./AdministrationListContainer";
import { useParams } from "react-router-dom";
import { useOnError } from "../../customHooks/useOnError";
import { Store } from "../../Store";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";

interface ReportsProps {
  listModule: ListModuleInterface;
  listGroups: ListGroup[];
}
const Lists: FunctionComponent<ReportsProps> = ({ listModule, listGroups }) => {
  const { magnifyGlassBlack, filter, Arrow } = ImageImports;
  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filters>({});

  const ButtonWithTooltip = withTooltip(Button);
  const [tableData, setTableData] = useState<User[]>([]);
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const tooltipPortalRef = useRef(null);
  const params = useParams();
  const groupId = String(params.groupId || "");
  const setErrorData = useOnError();

  let sortOptions = [
    {
      id: 1,
      name: "Modified: Newest to Oldest",
      key: "updated",
      direction: "desc",
    },
    {
      id: 2,
      name: "Modified: Oldest to Newest",
      key: "updated",
      direction: "asc",
    },
    {
      id: 3,
      name: "Published: Newest to Oldest",
      key: "created",
      direction: "desc",
    },
    {
      id: 4,
      name: "Published: Oldest to Newest",
      key: "created",
      direction: "asc",
    },
    {
      id: 5,
      name: "First Name: A to Z",
      key: "fName",
      direction: "asc",
    },
    {
      id: 6,
      name: "First Name: Z to A",
      key: "fName",
      direction: "desc",
    },
    {
      id: 5,
      name: "Last Name: A to Z",
      key: "lName",
      direction: "asc",
    },
    {
      id: 6,
      name: "Last Name: Z to A",
      key: "lName",
      direction: "desc",
    },
    {
      id: 7,
      name: "Clear Sort",
      key: "created",
      direction: "asc",
    },
  ];

  if (listModule.id === 1) {
    sortOptions = [
      {
        id: 1,
        name: "First Name: A to Z",
        key: "fName",
        direction: "asc",
      },
      {
        id: 2,
        name: "First Name: Z to A",
        key: "fName",
        direction: "desc",
      },
      {
        id: 3,
        name: "Last Name: A to Z",
        key: "lName",
        direction: "asc",
      },
      {
        id: 4,
        name: "Last Name: Z to A",
        key: "lName",
        direction: "desc",
      },
      {
        id: 5,
        name: "Clear Sort",
        key: "created",
        direction: "asc",
      },
    ];
  }

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
    } else {
      setFilter("");
    }
  }, [searchText]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {

    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  useEffect(() => {
    setSortDirection("asc");
    setSortKey("lName");
    setSortSelectedOption('Last Name: Z to A')
  }, []);
  let tableColumns: UserColumnDefinition[];
  tableColumns = [
    {
      title: "User ID",
      key: "id",
      className: classes.document_name,
      style: {
        flexBasis: "24%",
      },
    },
    {
      title: "First Name",
      key: "fName",
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Last Name",
      key: "lName",
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "email",
      key: "email",
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Account Status",
      key: "accountDisabled",
      component: ({ report }) => <>{report.accountDisabled ? "Deactivated" : "Active"}</>,
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "",
      key: "manage",
      component: ({ report }) => <span style={{ color: "#3C5D69", fontWeight: "bold" }}>Manage</span>,
      style: {
        flexBasis: "18%",
        cursor: 'pointer'
      },
    },
  ];

  const { isFetching, refetch: refetchReports } = useQuery(["User List", groupId], listModule.ListApi.getAll, {
    onSuccess: (userData: CPApiResponse<User[]>) => {
      if (groupId === listGroups[0].name) {
        setTableData(userData.data.filter((d) => d.mpInternal === true));
        setData(userData.data.filter((d) => d.mpInternal === true));
      } else {
        setTableData(userData.data.filter((d) => d.mpInternal === false));
        setData(userData.data.filter((d) => d.mpInternal === false));
      }
    },
    onError: (error: unknown) => {
      setTableData([]);
      setData([]);
      setErrorData(error);
    },
  });

  const {
    pagedData,
    setData,
    data,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<any>();

  useEffect(() => {
    setCloseFilters((f) => f + 1);
  }, [filters]);

  let filterCount = 0;

  if (filters.iStatus?.length) {
    filterCount += filters.iStatus?.length;
  }

  const tooltipId = useId().replaceAll(":", "_");

  const filterReports = (report: User): boolean => {
    let matches = true;
    let MatchText = ""
    if (report.accountDisabled) {
      MatchText = "Deactivated";
    } else {
      MatchText = "Active";
    }

    if (matches && filters.iStatus?.length) {
      matches = filters.iStatus.includes(MatchText);
    }
    return matches;
  };




  return (
    <div
      className={`${classes.flex_column} ${isDesktop ? classes.is_desktop : ""} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""
        }`}
      style={{ flexGrow: 1 }}
    >
      <div className={`${classes.report_container} ${classes.flex_column}`}>
        <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
          <div className={`${classes.flex_row} ${classes.options_left}`}>
            {isDesktop && !isTabletLandscape && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={handleSort}
                  selectValue={sortSelectedOption || ''}
                  selectedOption={sortSelectedOption}
                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={classes.search_keyword}
                value={searchText}
                placeholder="Search documents"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {!isMobile && (
              <div>
                {tableData.length} {tableData.length === 1 ? "Result" : "Results"}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} />}
            />
          </div>
        </div>
        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.iStatus?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      iStatus: filters.iStatus?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}
          </div>
        )}
        <div className={`${classes.results_container} ${classes.flex_column}`}>
          {isDesktop ? (
            <>
              <table className={classes.report_table}>
                <thead>
                  <tr>
                    {tableColumns.map((col) => (
                      <th key={col.title} style={col.style}>
                        {col.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pagedData.filter(filterReports).map((row) => (
                    <ListRowTable
                      key={row.id}
                      unread={false}
                      data={row}
                      columnDefinitions={tableColumns}
                      tooltipActions={[]}
                      isChecked={false}
                      onRowChecked={function (event: React.MouseEvent<Element, MouseEvent>): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <></>
            // <>
            //   <div className={`${classes.flex_row} ${classes.accordion_heading}`}>
            //     <span>Report Name</span>
            //   </div>
            //   {pagedData.map((row) => (
            //     <ReportRowAccordion
            //           unread={false}
            //           key={row.globalId}
            //           data={row}
            //           tooltipPortalRef={tooltipPortalRef} columnDefinitions={[]}
            //     />
            //   ))}
            // </>
          )}
          {tableData.length > 0 ? (
            <div className={`${classes.pagination} ${isDesktop || isTablet ? classes.flex_row : classes.flex_column}`}>
              <div className={`${classes.flex_row} ${classes.pagination_left}`}>
                <span className={classes.result_count}>
                  {Math.min(resultsPerPage, tableData.length)} of {tableData.length} Result{tableData.length !== 1 && "s"}
                </span>
                {tableData.length > 16 &&
                  <Button
                    className="whiteBtn show_all"
                    text={"Show All"}
                    onClick={() => setResultsPerPage(tableData.length)}
                    style={{ order: isMobile ? "-1" : "unset" }}
                  />
                }
              </div>
              <div className={`${classes.flex_row} ${classes.page_numbers}`}>
                <img
                  src={Arrow}
                  className="pagination-left-arrow"
                  alt="arrow"
                  onClick={() => {
                    if (hasPreviousPage) {
                      previousPage();
                    }
                  }}
                />
                {pageButtonNumbers.map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={`${classes.page_number} ${pageNumber === currentPageNumber ? classes.active : ""}`}
                    onClick={() => {
                      setCurrentPageNumber(pageNumber);
                    }}
                  >
                    {pageNumber}
                  </span>
                ))}
                <img
                  src={Arrow}
                  className="pagination-right-arrow"
                  alt="arrow"
                  onClick={() => {
                    if (hasNextPage) {
                      nextPage();
                    }
                  }}
                />
              </div>
            </div>
          ) : false ? (
            <div>Loading...</div>
          ) : (
            <div>No reports to display.</div>
          )}
        </div>
      </div>
      <div ref={tooltipPortalRef} />
    </div>
  );
};

export default Lists;

interface Filters {
  iStatus?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
}
const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const { selectedPlant, plantConfig } = useContext(Store);
  const [iStatus, setIStatus] = useState<{ value: boolean; name: string }[]>([
    { value: false, name: "Active" },
    { value: false, name: "Deactivated" },
  ]);

  const applyFilters = () => {
    setFiltersChanged(false);
    const filters: Filters = {};


    if (iStatus?.find(s => s.value == true)) {
      filters.iStatus = iStatus.filter(s => s.value).map(s => s.name);
    }


    onFiltersApplied(filters);
  };
  const canApplyFilters = (iStatus.find(s => s.value === true));

  const selectOptionE = (selectOption: string) => {
    const newState = iStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIStatus(newState);

  };

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Account Status:</label>
        <div className={classes.checkboxWrapper}>
          {iStatus.map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
              <Checkbox isChecked={status.value} label={status.name} onChange={() => { }} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
