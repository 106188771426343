import { FunctionComponent, HTMLAttributes } from "react";
import { downloadFileByFileId } from "../../customHooks/FileHttpServices";

const partsListTemplateFileId = process.env.REACT_APP_PARTS_LIST_TEMPLATE_FILE_ID as number|undefined;

export const PartsListTemplateLink: FunctionComponent<HTMLAttributes<HTMLAnchorElement> & {text: string}> = ({text, ...props}) => {
    const handleLinkClick = () => {
        if (partsListTemplateFileId) {
            downloadFileByFileId(partsListTemplateFileId);
        }
    };
    return (
        <span onClick={handleLinkClick}>
            <a {...props} className="text-ocean cursor-pointer">{text}</a>
        </span>
    );
}