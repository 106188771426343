import axios, { AxiosError, AxiosRequestConfig } from "axios";
import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Store } from "../../Store";
import { useGet_AccountSettings, useGet_HeaderData } from "../../containers/PlantInformation/DashboardHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { CpEventListener, subscribe, unsubscribe } from "../../utils/Events/CustomEvents";
import ImageImports from "../../utils/ImageImports";
import { AccountSettingsDTO, HeaderDTO, UserGroup } from "../../utils/interface";
import MobileMenu from "../MobileMenu/MobileMenu";
import Tooltip from "../PopoutTooltip/Tooltip";
import SearchGlobal from "../Search/SearchGlobal";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import AccountSettingsModal from "./AccountSettingsModal";
import "./Navbar.css";
import classes from "./Navbar.module.css";
import SignOutModal from "./SignOutModal";

function Navbar({ headerHeight }: NavbarProps) {
  const { ugLogo, menuOpenIcon } = ImageImports;
  const { isDesktop } = useWindowSize();
  const {
    setHeader,
    setUserGroups,
    setAccountSettings,
    accountSettings: { data: accountSettings },
    mobileNavToggle,
    toggleMobileNav,
    header: { data },
  } = useContext(Store);
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
  const [modal, setModal] = useState<ReactElement | undefined>();

  const modals = {
    account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
    signOut: <SignOutModal closeModal={() => setModal(undefined)} />,
  };

  
  useEffect(() => {
    const showAccountModal: CpEventListener<'toggleAccountSettingsModal'> = evt => {
      if (evt.detail === true) {
        setModal(modals.account);
      } else {
        setModal(undefined);
      }
    };
    
    subscribe('toggleAccountSettingsModal', showAccountModal);
    return () => unsubscribe('toggleAccountSettingsModal', showAccountModal);
  }, [data, modals.account]);

  const setErrorData = useOnError();

  // Fetch Plant Details Start

  const onSuccess = (data: HeaderDTO) => {
    setHeader && setHeader(data);
    setUserGroups &&
      setUserGroups([
        {
          id: 0,
          description: "",
          name: "Users' Group Home",
          plants: [],
          enableTechnicalBulletinList: false,
        },
        ...data.data.user.userGroups.map((d) => ({
          plants: [],
          description: "",
          enableTechnicalBulletinList: false,
          ...d,
        })),
      ]);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useGet_HeaderData(onSuccess, onError);
  // Fetch Plant Details End

  useGet_AccountSettings((accountSettings: AccountSettingsDTO) => setAccountSettings?.(accountSettings), setErrorData);

  let desktopHeaderContent = (
    <>
      <div className="nav-item">
        <SearchGlobal />
        <UserGroupDropDown />
      </div>
      <div className="nav-item login-details">
        <div className="initials" onClick={() => setShowAccountMenu(!showAccountMenu)}>
          <img src={data.user.pictureUrl} alt="" className="hover:cursor-pointer" />
          <Tooltip shown={showAccountMenu} style={{}} backdrop={true}>
            <div className={classes.accountDropdown}>
              <div className={`${classes.flex_row} ${classes.flex_column}`}>
                <div className="initials">
                  <img src={data.user.pictureUrl} alt="" />
                </div>{" "}
                {accountSettings.email}{" "}
              </div>
              <div className="flex flex-col gap-5">
                <div className="inline-block"><span onClick={() => setModal(modals.account)} className="hover:cursor-pointer">Account & Settings</span></div>
                <div className="inline-block"><span onClick={() => setModal(modals.signOut)} className="hover:cursor-pointer">Sign Out</span></div>
              </div>
            </div>
          </Tooltip>
        </div>
        <div>
          <div className="name">
            {data.user.firstName} {data.user.lastName}
          </div>
        </div>
      </div>
    </>
  );

  if (!isDesktop) {
    desktopHeaderContent = <></>;
  }
  useEffect(() => {
    mobileNavToggle ? document.body.classList.add("overflow-hidden"): document.body.classList.remove("overflow-hidden");
  }, [mobileNavToggle]);

  return (
    <>
      <div className="navbar">
        <div className="nav-item">
          <Link to={"/"} className="link_text">
            <img src={ugLogo} className="App-logo" alt="logo" />
          </Link>
        </div>
        {desktopHeaderContent}
        <div className="nav-item">
          <div className="nav__action_wrp">
            {isDesktop && <div style={{ minWidth: 40 }}></div>}
            {!isDesktop && (
              <div
                className="nav__action"
                onClick={() => {
                  toggleMobileNav?.(!mobileNavToggle);
                }}
              >
                <img src={menuOpenIcon} alt="open menu" className="cursor-pointer" />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isDesktop && (
        <>
          <UserGroupDropDown />
          <div className="py-2"></div>
          <MobileMenu />
        </>
      )}
      {modal}
    </>
  );
}

interface NavbarProps {
  headerHeight?: number;
}

export default Navbar;

const UserGroupDropDown = () => {
  const { userGroups, setSelectedUserGroup, selectedUserGroup } = useContext(Store);
  const [searchParams, setSearchParams] = useSearchParams();
  const ugSearchParam = searchParams.get('userGroupId');
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const ugString = sessionStorage.getItem("selectedUserGroup");
      if (ugString) {
        try {
          const ug = JSON.parse(ugString);
          config.headers = {
            ...config.headers,
            UserGroup: ug.id,
          };
        } catch (e) {
          console.error(e);
        }
      }
      return config;
    });
  }, []);

  useEffect(() => {
    const ugString = sessionStorage.getItem("selectedUserGroup");
    let ug: UserGroup = {
      id: 0,
      name: "Users' Group Home",
      description: "",
      enableTechnicalBulletinList:  false,
      plants: [],
    };
    if (ugString) {
      ug = JSON.parse(ugString);
    }
    
    if (userGroups.length > 0) {
      let selectedGroup; 
      if (ugSearchParam) {
        selectedGroup = userGroups.find(u => parseInt(ugSearchParam) === u.id);
      } else {
        selectedGroup = userGroups.find(u => ug.id === u.id);
      }

      ug = selectedGroup || ug;
      sessionStorage.setItem("selectedUserGroup", JSON.stringify(ug));
    }
    setSelectedUserGroup?.(ug);
  }, [userGroups, setSelectedUserGroup, ugSearchParam]);

  const handleUserGroupSelection = useCallback(
    (group: DropDownOptionItem) => {
      const ug = userGroups.find((d) => d.id === group.id);

      if (ug && setSelectedUserGroup) {
        sessionStorage.setItem("selectedUserGroup", JSON.stringify(ug));
        if (ugSearchParam) {
          setSearchParams(params => {
            params.delete('userGroupId');
            return params;
          })
        }
        setSelectedUserGroup({ ...ug, description: "" });

        if (location.pathname.includes('discussionSubject')) {
          navigate('/discussions/Discussions');
        }
      }
    },
    [userGroups, setSelectedUserGroup, ugSearchParam, location]
  );

  return (
    <PlainDropDown
      selectClass="flex flex-row items-center !py-[6px] pr-2 pl-4 text-xs justify-between rounded w-full border border-solid border-[#999] whitespace-nowrap min-w-[240px] cursor-pointer"
      className="relative"
      optionsClass="flex flex-col flex-nowrap p-4 bg-white rounded max-h-80 w-full"
      dropdownStyles={{ overflow: "auto" }}
      itemClass="py-2 px-4 cursor-pointer rounded hover:bg-primary-20 font-bold text-sm"
      options={userGroups}
      onSelection={handleUserGroupSelection}
      value={selectedUserGroup.id}
      defaultText="Select Users' Group"
    />
  );
};
