import React, { useContext, useRef, useState } from "react";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Nameplate.module.css";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { EquipmentAttachmentsApi, useGetImage_PlantEquipmentAttachment } from "../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import Loader from "../Loader/Loader";
import { PrintPDF, toBase64 } from "../../utils/FileUtils";
import { useOnError } from "../../customHooks/useOnError";
import { AxiosError } from "axios";

const NamePlateViewModal = ({ namePlateData }: NamePlateViewModalProps) => {
  const stateContext = useContext(Store);
  const { isModal, setIsModal } = stateContext;
  const { closeImg, download, print, logo } = ImageImports;
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState("");

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment Start
  ///////////////////////////////////////////

  const onSuccess = (data: Blob) => {
    setAttachmentBlob(data);
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isFetching } = useGetImage_PlantEquipmentAttachment(namePlateData.equipmentAttachmentId, onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}
  const downloadEmployeeData = () => {
    if (attachmentBlob) {
      EquipmentAttachmentsApi.download(attachmentBlob, namePlateData.name, namePlateData.ext);
    }
  };

  const handlePrint = () => {
    attachmentBlob && PrintPDF(attachmentBlob);
  };

  const handleModalClose = () => {
    setIsModal!({
      ...CloseAllModal,
      isView: { ...isModal.isView, isNameplate: false },
    });    
  };

  return (
    <ModalGlobal modaltitle={namePlateData.name} isModalHeader={false} modalSize={"lg"}>
      <>
        <div className={classes.modalTitle}>
          <div className={classes.flexGrow1}>
            <div style={{ marginBottom: "10px" }}>{namePlateData.name}</div>
            <div style={{ fontSize: "14px", color: "#666666", fontWeight: "400" }}>{namePlateData.fileSize}</div>
          </div>
          <span className={classes.close_modal} onClick={downloadEmployeeData}>
            <img src={download} alt="" />
          </span>
          <span className={classes.close_modal}>
            <img src={print} onClick={handlePrint} alt="" />
          </span>
          <span className={classes.close_modal} onClick={handleModalClose}>
            <img src={closeImg} alt="" />
          </span>
        </div>
        <div>
          <div className={classes.imageWrapper}>
            {isFetching ? <Loader containerStyle={{marginBottom: '80px'}} /> : <img style={{ maxWidth: "100%" }} src={imgBase64} alt={namePlateData.name} />}
          </div>
        </div>
      </>
    </ModalGlobal>
  );
};

interface NamePlateViewModalProps {
  namePlateData: {
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  };
}

export default NamePlateViewModal;
