import { Report } from "../../utils/interface";
import { ColumnDefinition } from "./Reports";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { MouseEventHandler, useContext, useId, useState } from "react";
import classes from "./Reports.module.css";
import ImageImports from "../../utils/ImageImports";
import ReportsToolTipData from "./ReportsToolTipData/ReportsToolTipData";
import { Store } from "../../Store";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";

interface ReportRowTableProps {
  data: Report;
  columnDefinitions: ColumnDefinition[];
  tooltipActions: tooltipActionItem[];
  isChecked: boolean;
  onRowChecked: () => void;
  unread: boolean;
}

function ReportRowTable({ data, columnDefinitions, tooltipActions, isChecked, onRowChecked, unread }: ReportRowTableProps) {
  const { ellipsis, tooltip: tooltipImage } = ImageImports;
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const Tooltip = () => {
    return (
      <div
        className="row-item text-l cursor-pointer"
        style={{color: "#00749E"}}
        onBlur={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(false);
          }
        }}
        tabIndex={1}
        onClick={() => {
          if (toggleTooltip !== undefined) {
            toggleTooltip(true);
            setToolTipContent && setToolTipContent(<ReportsToolTipData data={data} />);
          }
        }}
      >
         <strong>View</strong>
      </div>
    );
  };

  return (
    <tr style={{ color: data.isDeleted ? 'var(--secondaryGrey)' : 'inherit'}} className={unread? classes.unread: ''}>
      <td>
        <Checkbox isChecked={isChecked} onChange={onRowChecked} />
      </td>
      {columnDefinitions.map((columnDefinition) => (
        <td key={columnDefinition.key} className={`${classes.data_point} ${columnDefinition.className}`} style={columnDefinition.style}>
          {columnDefinition.component ? <columnDefinition.component report={data} tooltip={<Tooltip />} /> : <div><>{data[columnDefinition.key]}</></div>}
        </td>
      ))}
      <td>
        <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
          <img src={ellipsis} alt="More Actions" />
          <BulkActionTooltip shown={isTooltipVisible} parentId={tooltipId} setShown={setIsTooltipVisible} listItems={tooltipActions} data={[data]} />
        </div>
      </td>
    </tr>
  );
}

export default ReportRowTable;
