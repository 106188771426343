import React, { useEffect, useState } from 'react';
import { Role } from '../../utils/interface';
import classes from "./ManageRoles.module.css";
import ImageImports from "../../utils/ImageImports";
import DeleteRoleConfirmationModal from './DeleteRoleConfirmationModal';
import {useSortable,} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Button from '../Button/Button';
import { DropDownOptionItem, PlainDropDown } from '../UI/Form/DropDownSelect/PlainDropDown';

const {pencil, dragReorder} = ImageImports;

interface ManageRoleListItemProps {
    role: Role;
    onSave: (role: Role) => void;
    onDelete: (role: Role) => void;
    editToggled: (isEditing: boolean) => void;
}

export const ManageRoleListItem = ({role, editToggled, onSave, onDelete}: ManageRoleListItemProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [isMpInternal, setIsMpInternal] = useState<boolean>(false);
    const [roleType, setRoleType] = useState<string>('');
    const [roleTypeId, setRoleTypeId] = useState<number|false>(0);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [showDeleteRoleConfirmationModal, setShowDeleteRoleConfirmationModal] = useState<boolean>(false);
    const roleTypeOptions: DropDownOptionItem[] = [
        { id: 1, name: "Internal Mitsubishi Power role", value: 'internal' },
        { id: 2, name: "External Mitsubishi Power role", value: 'external' },
    ];

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef
    } = useSortable({id: role.id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const cancelEdit = () => {
        setName(role.name);
        setIsMpInternal(role.mpInternal);
        const roleTypeOption = roleTypeOptions.find((roleTypeOption) => roleTypeOption.id === (role.mpInternal ? 1 : 2)) || roleTypeOptions[1];
        setRoleType(roleTypeOption.name);
        setRoleTypeId(roleTypeOption.id);
        closeEdit();
    };

    const closeEdit = () => {
        setIsEditing(false);
        editToggled(false);
    };

    const startEditing = () => {
        setIsEditing(true);
        editToggled(true);
    };

    const save = () => {
        onSave({...role, name, mpInternal: isMpInternal});
        closeEdit();
    };
    const deleteReportType = () => {
        setShowDeleteRoleConfirmationModal(false);
        closeEdit();
        onDelete(role);
    };

    useEffect(() => {
        setName(role.name);
        setIsMpInternal(role.mpInternal);
        const roleTypeOption = roleTypeOptions.find((roleTypeOption) => roleTypeOption.id === (role.mpInternal ? 1 : 2)) || roleTypeOptions[1];
        setRoleType(roleTypeOption.name);
        setRoleTypeId(roleTypeOption.id);
    }, [role]);


    useEffect(() => {
        setIsDirty((role.name === name && isMpInternal === role.mpInternal) || !name.length || !roleType.length);
    }, [name, roleType, roleTypeId, isMpInternal, role]);

    return (
        <div className={`${classes.report_type_list_item} ${classes.flex_row} items-center`} ref={setNodeRef} style={style} {...attributes}>
            <div ref={setActivatorNodeRef} {...listeners} className={`${classes.move_icon} ${classes.flex_row}`}>
                <img src={dragReorder} alt="Reorder Roles" />
            </div>
            {showDeleteRoleConfirmationModal && <DeleteRoleConfirmationModal shown={showDeleteRoleConfirmationModal}
                                                                               onClose={() => {setShowDeleteRoleConfirmationModal(false)}}
                                                                               onDelete={deleteReportType}
                                                                               role={role} />}
            <div className={`${classes.report_type_inner} ${isEditing? classes.flex_column : classes.flex_row} `}>
                <div className={`${classes.input_wrapper} ${classes.flex_column}`}>
                    {isEditing && (
                        <span className={`${classes.edit_group_name} ${classes.flex_row}`}>Role Name*</span>
                    )}
                    <input type="text" className={`${classes.flex_row} ${isEditing ? '!text-black' : ''}`} value={name} readOnly={!isEditing} onChange={e => {setName(e.target.value)}} />
                    <div className={`flex-none grow items-stretch order-2 !text-xs`}>{role.assignmentCount} assignment{role.assignmentCount === 1 ? '' : 's'} across all plants</div>
                    {!isEditing && (
                        <div className={`flex-none grow items-stretch order-3 !text-sm mt-3`}>{roleType}</div>
                    )}
                </div>
                
                {isEditing? (
                    <>
                        <div className={`${classes.input_wrapper} ${classes.flex_column}`}>
                            <span className={`${classes.edit_group_name} ${classes.flex_row}`}>Role Type*</span>
                            <PlainDropDown
                                className={`${classes.select_container} !text-black !placeholder-black grow w-full md:w-1/2 lg:w-1/3`}
                                selectClass={`${classes.select_class} !text-black !placeholder-black`}
                                optionsClass={classes.select_options_class}
                                onSelection={(type) => {
                                    setRoleTypeId(type.id);
                                    setRoleType(type.name);
                                    setIsMpInternal(type.id === 1);
                                }}
                                value={roleTypeOptions.map((d) => d.id).includes(roleTypeId) ? roleTypeId : 0}
                                options={roleTypeOptions}
                                defaultText="Select Role Type"
                            />
                        </div>
                        <div className={`${classes.edit_button_group} ${classes.flex_row}`}>
                            <div className={`${classes.edit_left_button_group} ${classes.flex_row}`}>
                                <Button className="darkBlue" text="Save Changes" onClick={save} disabled={isDirty} />
                                {/* Delete is disabled unless there are no role assignments assigned to the role. */}
                                <Button className="redDelete" text="Delete Role" disabled={role.assignmentCount > 0} onClick={() => setShowDeleteRoleConfirmationModal(true)} />
                            </div>
                            <Button className="whiteBtn" text="Cancel" onClick={cancelEdit} />
                        </div>
                    </>
                ) : (
                    <div className={`${classes.edit_icon} mt-1`} onClick={startEditing}>
                        <img src={pencil} alt="Edit" />
                    </div>
                )}
            </div> 
        </div>
    );
};