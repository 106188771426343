import { useState, useContext } from 'react';
import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { AuthorizationType, CPApiResponse, UserModulePermission, UserPermission, ModuleNames } from "../utils/interface";
import { userApiUrl } from '../endpoints/endpoints';
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { Store } from "../Store";
import { ModuleIDs } from '../utils/moduleIDs';
import { pick } from '../utils/pick';

declare type GetUserPermissionsParameters = ['permissions'];
const getUserPermissions = async (): Promise<UserModulePermission[]> => {
    const response: AxiosResponse<CPApiResponse<UserModulePermission[]>> = await axios(`${userApiUrl}/GetPermissionsForUserGroup`);
    return handleAxiosResponse(response);
};
export const useGetUserPermissions = (options: Omit<UseQueryOptions<UserModulePermission[], unknown, UserModulePermission[], GetUserPermissionsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<UserModulePermission[], unknown, UserModulePermission[], GetUserPermissionsParameters>(['permissions'], getUserPermissions, {
        ...options,
        cacheTime: 3600000 // 1 hour = 1000 * 60 * 60
    });
}


declare type ModulePermissionMap = Object & {
    [Property in ModuleNames]: UserPermission
};

const buildDefaultPermissions = (): ModulePermissionMap => {
    return Object.keys(ModuleIDs).filter(d => isNaN(+d)).reduce((carry, current) => {
        return {
            ...carry,
            [current]: {
                canAdd: false,
                canAdmin: false,
                canDelete: false,
                canEdit: false,
                canView: false
            }
        }
    }, {} as ModulePermissionMap)
};

export const usePermissions = () => {
    const { selectedUserGroup: { id: userGroupId }, header: { data: { user: { portalAdmin } } } } = useContext(Store);
    const [permissions, setPermissions] = useState<ModulePermissionMap>(buildDefaultPermissions());
    useGetUserPermissions({
        onSuccess: perms => {
            setPermissions(existingPermissions => {
                perms.forEach(perm => {
                    const x = ModuleIDs[perm.moduleId] as ModuleNames;
                    existingPermissions[x] = pick(perm, ['canAdd', 'canAdmin', 'canDelete', 'canEdit', 'canView']);
                });
                return existingPermissions;
            })
        },
        enabled: userGroupId > 0
    });

    function hasPermission(moduleName: ModuleNames, type: AuthorizationType): boolean {
        return permissions[moduleName][`can${type.charAt(0).toUpperCase() + type.slice(1)}` as `can${Capitalize<string & AuthorizationType>}`] || permissions[moduleName].canAdmin || portalAdmin;
    }

    function hasOnePermission(moduleName: ModuleNames, type: AuthorizationType): boolean;
    function hasOnePermission(moduleNames: ModuleNames[], type: AuthorizationType): boolean;
    function hasOnePermission(moduleName: ModuleNames, types: AuthorizationType[]): boolean;
    function hasOnePermission(moduleNames: ModuleNames[], types: AuthorizationType[]): boolean;
    function hasOnePermission(moduleNames: ModuleNames|ModuleNames[], types: AuthorizationType|AuthorizationType[]): boolean;
    function hasOnePermission(moduleNames: ModuleNames|ModuleNames[], types: AuthorizationType|AuthorizationType[]): boolean {
        if (!Array.isArray(moduleNames)) {
            moduleNames = [moduleNames];
        }
        if (!Array.isArray(types)) {
            types = [types];
        }
        let hasOne = false;
        for (let i = 0; i < moduleNames.length && hasOne === false; i++) {
            for (let j = 0; j < types.length && hasOne === false; j++) {
                hasOne = hasPermission(moduleNames[i], types[j]);
            }
        }
        return hasOne;
    }

    return {
        permissions,
        hasPermission,
        hasOnePermission
    };
};