import { useContext, useState } from "react";

import Editor from "../../../components/UI/RichTextEditor/Editor";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { Store } from "../../../Store";
import { DiscussionInterface } from "../../../utils/interface";
import { useCreate_UGThreadMessage } from "../discussionApiMethod";


const maxCharacterCount = 2000;

const MessageAddForm = ({ discussionThread }: MessageAddFormProps) => {
  const { faq } = useContext(Store);

  const setErrorData = useOnError();

  const [faqText, setFaqText] = useState<string>("");
  const [clearEditor, setClearEditor] = useState<number>(0);
  const [maxCharacterError, setMaxCharacterError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string|null>(null);
  const { mutate: CreateUgThreadMessage } = useCreate_UGThreadMessage();

  const handleUpdateFaq = () => {
    CreateUgThreadMessage(
      {
        title: discussionThread ? discussionThread.title : "",
        threadId: discussionThread ? discussionThread.threadId : 0,
        content: faqText,
      },
      {
        onSuccess(data) {
          discussionThread && queryClient.invalidateQueries([queryKeys.discussionGroups, discussionThread?.globalItemId]);  
          setClearEditor(ce => ++ce);
          setErrorMsg(null);
        },
        onError(error: any) {
          setErrorMsg(error.response.data);
          setErrorData(error);
        },
      }
    );
  };

  const handleContentChange = (val: string) => {
    setMaxCharacterError(false);
    if (val.length > maxCharacterCount) {
      setMaxCharacterError(true);
    }
    if (val !== faq.faqText) {
      setFaqText(val);
    }
  }
  
  return (
    <div>
      <label>Post to Discussion </label>
      <Editor reset={clearEditor} handleChange={handleContentChange} content={faq?.faqText} />
      
      {(maxCharacterError || errorMsg) && (
        <div className="flex align-center  mb-5">
          <div className="text-error-800">
            {errorMsg 
            ? <>{errorMsg}</>
            : <>Message currently exceeds the size limit of {maxCharacterCount} characters (including formatting characters not shown on screen).</>
            }
          </div>
        </div>
      )}
      
      <div className="flex align-center">
        <div className="flex-grow-1 flex">
          <button type="button" className="primaryBtn  darkBlue" onClick={handleUpdateFaq} disabled={!faqText.length || faqText === '<p class="editor-paragraph"><br></p>' ? true : false || maxCharacterError}>
            Post
          </button>
          <button type="button" className="primaryBtn whiteBtn marginLeft16" onClick={() => setClearEditor(ce => ++ce)}>
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

interface MessageAddFormProps {
  discussionThread: DiscussionInterface | null;
}

export default MessageAddForm;
