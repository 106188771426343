import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { MutationFunction, QueryFunctionContext, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { apiPageContentUrl } from "../endpoints/endpoints";
import { Store } from "../Store";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, PageContent } from "../utils/interface";

declare type GetPageContentParameters = ['pageContent', {userGroupId: number}];
const getPageContent = async ({queryKey}: QueryFunctionContext<GetPageContentParameters>): Promise<PageContent> => {
    const [_key, {userGroupId}] = queryKey;
    try {

        const response: AxiosResponse<CPApiResponse<PageContent>> = await axios(`${apiPageContentUrl}`, {
            headers: {
                UserGroup: userGroupId
            }
        });
        return handleAxiosResponse(response);
    } catch (e) {}
    return Promise.reject('Request Failed');
};

export const useGetPageContent = (options: Omit<UseQueryOptions<PageContent, unknown, PageContent, GetPageContentParameters>, 'queryKey' | 'queryFn'> = {}) => {
    const {selectedUserGroup} = useContext(Store);
    return useQuery<PageContent, unknown, PageContent, GetPageContentParameters>(['pageContent', {userGroupId: selectedUserGroup.id}], getPageContent, options);
};

const createPageContent: MutationFunction<PageContent, PageContent> = async (pageContent: PageContent): Promise<PageContent> => {
    const response: AxiosResponse<CPApiResponse<PageContent>> = await axios(apiPageContentUrl, {
        method: 'POST',
        data: {
            contentTitle: pageContent.contentTitle,
            contentMessage: pageContent.contentMessage,
            userGroupId: pageContent.userGroupId
        }
    });
    return handleAxiosResponse(response);
};

export const useCreatePageContent = (options: Omit<UseMutationOptions<PageContent, unknown, PageContent>, 'mutationFn'> = {}) => {
    return useMutation(createPageContent, options);
};

const updatePageContent: MutationFunction<PageContent, PageContent> = async (pageContent: PageContent): Promise<PageContent> => {
    const response: AxiosResponse<CPApiResponse<PageContent>> = await axios(`${apiPageContentUrl}`, {
        method: 'PUT',
        data: pageContent
    });
    return handleAxiosResponse(response);
};

export const useUpdatePageContent = (options: Omit<UseMutationOptions<PageContent, unknown, PageContent>, 'mutationFn'> = {}) => {
    return useMutation(updatePageContent, options);
};