import React, {ReactElement} from 'react';
import classes from "./ManageRoles.module.css";
import { Role } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import EmptyModal from '../Modals/EmptyModal';

type ModalType = "edit" | "delete" | "";

interface EditDeleteRoleSuccessModalProps {
    shown: boolean;
    role: Role;
    type: ModalType;
    onClose: () => void;
}

function EditDeleteRoleSuccessModal({role, type, shown, onClose}: EditDeleteRoleSuccessModalProps) {
    const {isMobile} = useWindowSize();

    let width: number = 564;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (shown) {
        if (type === 'delete') {
            modalBody = (
                <>
                    <span>You successfully deleted the role "{role.name}" for all plants in the Customer Portal.</span>
                </>
            );
        }
        else {
            modalBody = (
                <>
                    <span>The role "{role.name}" has been successfully updated.</span>
                </>
            );
        }
        

        modalFooter = (
            <>
                <div className={`${classes.deleted_group_modal_button_group} ${classes.flex_row}`}>
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={shown} body={modalBody} footer={modalFooter} heading={`Role Successfully ${type === 'delete' ? "Deleted" : 'Updated'}`} onClose={onClose} width={width} />
    );
}

export default EditDeleteRoleSuccessModal;