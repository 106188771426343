import useWindowSize from "../../../customHooks/useWindowSize"; 
import HeaderButtons from "../HeaderButtons";

function DataReportHeader() {
  const { isDesktop } = useWindowSize();
  
  return (
    <div style={{justifyContent:"space-between"}} className={`flex flex-row gap-6 `}>
      <div className={`flex flex-col gap-6`}>
        {/* <div className={headerClasses.header_title}>{selectedPlant.name} Bulletins</div> */}
        <div className={'font-bold text-2xl'}>Data & Reporting</div>
        <div className={'gap-6 text-[font-size:var(--fs14px)]'}>
        Click here to view system-generated reports regarding plants and other information.
        </div>

        {!isDesktop && (
          <HeaderButtons manageButtonClass={""} addButtonClass={""} addButtonText="Generate Report" />
        )}
      </div>
      {isDesktop && (
        <HeaderButtons manageButtonClass={""} addButtonClass={""} addButtonText="Generate Report" />
      )}
    </div>
  );
}

export default DataReportHeader;
