import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import duration from 'dayjs/plugin/duration';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import { useGetAllByUnitID_PlantEquipments } from "../../containers/PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import { downloadFile, downloadMultipleFiles, downloadMultipleFilesByFileId } from "../../customHooks/FileHttpServices";
import { useBulkDelete, useGetTechnicalBulletins } from "../../customHooks/TechnicalBulletinHttpServices";
import useFileViewer from "../../customHooks/useFileViewer";
import { usePermissions } from "../../customHooks/useUserPermissions";
import ImageImports from "../../utils/ImageImports";
import { Permissions, PlantConfigData, PlantEquipmentsDATA, PlantEquipmentsDTO, TechnicalBulletin, TechnicalBulletinGroup, UnitDATA, UnitGetAllDTO } from "../../utils/interface";
import Button from "../Button/Button";
import { BulkItemActionDefinition, ItemActionDefinition, ItemListColumnDefinition, SortOptionsDefinition } from "../List/ItemList";
import { TabbedSections } from "../List/TabbedSections";
import { DownloadMultipleFilesModal } from "../Modals/DownloadMultipleFilesModal";
import EmptyModal from "../Modals/EmptyModal";
import Pill, { PillType } from "../Pill/Pill";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import MultiSelectDropDown from "../UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import { Toggle } from "../UI/Form/Toggle/Toggle";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import "./DatePickerOverride.css";
dayjs.extend(duration);
const { download, copyLink, ellipsis, blueTick, Arrow, trash } = ImageImports;

type ImplementationStatus = string;
interface FilterStructure {
    implementationStatus: ImplementationStatus[];
    unit: number | false;
    equipment: {id: number, name: string}[];
    dateFilter?: string;
    startDate?: Dayjs;
    endDate?: Dayjs;
    showDeleted: boolean;
};

interface TechnicalBulletinsProps {
    groups: TechnicalBulletinGroup[];
    selectedGroupId: number;
    selectedPlant: PlantConfigData;
}

export const TechnicalBulletins = ({groups, selectedGroupId, selectedPlant}: TechnicalBulletinsProps) => {
    const [bulletins, setBulletins] = useState<TechnicalBulletin[]>([]);
    const [copyBulletinLink, setCopyBulletinLink] = useState<TechnicalBulletin>();
    const {setShowError, setToolTipContent, toggleTooltip, header: {data: {user}}} = useContext(Store);
    const [allVersionsDownload, setAllVersionsDownload] = useState<number>();
    const [units, setUnits] = useState<UnitDATA[]>([]);
    const [implementationStatuses, setImplementationStatuses] = useState<ImplementationStatus[]>([]);
    const { hasOnePermission } = usePermissions();
    const { handleFileView, isLoading } = useFileViewer();

    // deleting documents
    //const [documentsToDelete, setDocumentsToDelete] = useState<TechnicalBulletin[]>();
    //const [deletionCount, setDeletionCount] = useState<number>(0);

    const [permissions, setPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const { header: { data: { notifications } } } = useContext(Store);

    const {isFetching, refetch} = useGetTechnicalBulletins(selectedGroupId, selectedPlant.baseOrgId, {
        onSuccess: ({data, permissions}) => {
            const bulletins = data.map(d => ({
                ...d,
                id: d.globalId,
                isRead: notifications.filter(notification => notification.itemId === d.globalId && !notification.read).length === 0,
            }));
            setBulletins(bulletins);
            setPermissions(permissions);
            const uniqueImplementationStatuses = bulletins.map(d => d.implementationStatus.toLowerCase())
                                                          .filter((value, index, array) => array.indexOf(value) === index)
                                                          .filter(d => d.length > 0)
                                                          .sort();
            setImplementationStatuses(uniqueImplementationStatuses);
        },
        enabled: !!selectedGroupId
    });

    useEffect(() => {
        setBulletins(bulletins => bulletins.map(d => ({
            ...d,
            isRead: notifications.filter(notification => notification.itemId === d.globalId && !notification.read).length === 0
        })));
    }, [notifications]);

    /**
     * PLEASE NOTE: Delete Tech Bulletins funtionality was intentionaly removed from UGP since documents are sync from CP.
     */

    const sections = useMemo(() => {
        return groups.map(g => ({
            path: `/technical-bulletins/${g.id}`,
            text: g.name
        }));
    }, [groups]);

    const group = useMemo(() => {
      return groups.find(d => d.id === selectedGroupId);
    }, [groups, selectedGroupId]);

    useGetAll_UnitData(
        selectedPlant.plantId,
        (data: UnitGetAllDTO) => {
            setUnits(data.data);
        },
        () => { }
    );

    const handleFile = (report: TechnicalBulletin) => {
      const filetype = report.fileModel.fileType.contentType;
      const filePreviewTypes = ["image/jpeg", "application/pdf", "image/jpeg", "image/png"];
      const isValid = filePreviewTypes.indexOf(filetype);
      if (isValid === -1) {
        handleFileView(report.fileId, report.globalId, { isDownload: true, fileName: report.displayFileName! });
      } else {
        handleFileView(report.fileId, report.globalId);
      }
    };

    const columns: ItemListColumnDefinition<TechnicalBulletin>[] = useMemo(() => {
        return [
            {
                title: 'Document Name',
                key: 'displayFileName',
                component: ({ data }) => {
                  if (data.displayFileName) {
                    return (
                      <div className="inline-block">
                        <span onClick={() => handleFile(data)} className="text-ocean no-underline hover:cursor-pointer">{data.displayFileName} {data.isDeleted && "(Deleted)"}</span>
                      </div>
                    );
                  }
                  return <>N/A</>;
                }
            },
            {
                title: 'Equipment',
                key: 'associatedEquipment',
                component: (({data}) => <>{data.associatedEquipment? data.associatedEquipment.map(d => d.name).join(', ') : ''}</>)
            },
            {
                title: 'Published',
                key: 'created',
                component: (({data}) => <Moment date={data.created} format="MM/DD/YYYY" />)
            },
            {
                title: 'Details',
                key: 'globalId',
                component: (({data}) => <span className="text-dark-blue font-bold text-sm cursor-pointer" onClick={() => {
                    setToolTipContent && setToolTipContent(<ViewTechnicalBulletin bulletin={data} />);
                    toggleTooltip && toggleTooltip(true);
                }}>View</span>)
            },
        ];
    }, []);

    const sortableProperties: SortOptionsDefinition<TechnicalBulletin>[] = useMemo(() => {
        return [
            {
              id: 1,
              name: 'Modified: Newest to Oldest',
              key: 'updated',
              direction: 'desc',
            },
            {
              id: 2,
              name: 'Modified: Oldest to Newest',
              key: 'updated',
              direction: 'asc',
            },
            {
              id: 3,
              name: 'Published: Newest to Oldest',
              key: 'created',
              direction: 'desc',
            },
            {
              id: 4,
              name: 'Published: Oldest to Newest',
              key: 'created',
              direction: 'asc',
            },
            {
              id: 5,
              name: 'Name: A to Z',
              key: 'displayFileName',
              direction: 'asc',
            },
            {
              id: 6,
              name: 'Name: Z to A',
              key: 'displayFileName',
              direction: 'desc',
            },
            {
              id: 7,
              name: 'Clear Sort',
              key: 'created',
              direction: 'asc',
            },
        ];
    }, []);

    const actions: ItemActionDefinition<TechnicalBulletin>[] = [
        {
            text: <div className="flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap font-bold">
                <img src={download} alt="Download" style={{ filter: 'var(--svgFilterDarkBlueButton)' }} />
                <span>Download</span>
            </div>,
            onClick(event, data) {
                downloadFile(data.globalId);
            },
            visible: tb => !tb.isDeleted
        },
        {
            text: <div className="flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap font-bold">
                <img src={copyLink} alt="Copy Link" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                <span>Copy Link</span>
            </div>,
            onClick(event, data) {
                setCopyBulletinLink(data);
            },
            visible: tb => !tb.isDeleted
        },
    ];

    if (hasOnePermission('TechnicalBulletins',  'admin') || user.portalAdmin) {
      actions.push({
        text: <div className="flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap font-bold">
            <img src={download} alt="Download" style={{filter: 'var(--svgFilterDarkBlueButton)' }} />
            <span>Download All Versions</span>
        </div>,
        async onClick(event, data) {
            const fileIds = [data.fileId, ...(data.previousFiles || []).map(d => d.id)];
            setAllVersionsDownload(fileIds.length);
            await downloadMultipleFilesByFileId(fileIds);
            setAllVersionsDownload(undefined);
        }
      });
    }

    const bulkActions: BulkItemActionDefinition<TechnicalBulletin>[] = [
        {
            text: ({selectedItems}) => {
                return (
                    <div className={`flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap font-bold ${selectedItems.length > 0? 'text-dark-blue cursor-pointer' : 'text-[#cdd9dd]'}`}>
                        <img src={download} alt="Download" style={{filter: selectedItems.length > 0? 'var(--svgFilterDarkBlueButton)' : 'var(--svgFilterLightBlueButton)'}} />
                        <span>Download</span>
                    </div>
                )
            },
            onClick(event, data) {
                downloadMultipleFiles(data.map(d => d.globalId));
            },
            disabled(...selectedBulletins) {
                return selectedBulletins.length === 0;
            },
        }
    ];

    const [filters, setFilters] = useState<FilterStructure>({
        implementationStatus: [],
        unit: false,
        equipment: [],
        showDeleted: user.portalAdmin
    });
    const filterCount = useMemo(() => {
        let c = filters.implementationStatus.length;
        c += filters.equipment.filter(d => d.id > 0).length;
        if (filters.dateFilter) {
            c++;
        }
        if (filters.showDeleted) {
            c++;
        }
        return c;
    }, [filters]);

    const filterTechnicalBulletins = useCallback((tb: TechnicalBulletin): boolean => {
        let matches = true;
        if (matches && filters.implementationStatus.length > 0) {
            matches = filters.implementationStatus.includes(tb.implementationStatus.toLowerCase());
        }
        if (matches && filters.equipment.length > 0) {
            const eqIds = filters.equipment.filter(d => d.id > 0).map(d => d.id);
            matches = (tb.associatedEquipment || []).filter(d => eqIds.includes(d.equipmentId)).length > 0
        }
        if (matches && filters.dateFilter) {
            let startDate = dayjs();
            let endDate = dayjs();
            if (filters.dateFilter === 'custom') {
                if (filters.startDate) {
                    startDate = filters.startDate;
                }
                if (filters.endDate) {
                    endDate = filters.endDate;
                }
            } else {
                const duration = dayjs.duration(filters.dateFilter);
                startDate = startDate.subtract(duration);
            }
            matches = dayjs(tb.created).isAfter(startDate) && dayjs(tb.created).isBefore(endDate);
        }
        if (matches && !filters.showDeleted) {
            matches = !tb.isDeleted;
        }
        return matches;
    }, [filters, bulletins]);
    
    return (
        <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            {copyBulletinLink && <CopyLinkModal bulletin={copyBulletinLink} onClose={() => setCopyBulletinLink(undefined)} />}
            {allVersionsDownload !== undefined && allVersionsDownload > 0 && <DownloadMultipleFilesModal type="Discussion" fileCount={allVersionsDownload} />}

            <div className="flex flex-col gap-2">
                <span className="font-bold text-2xl">{selectedPlant.baseOrgName} Bulletins</span>
                <div className="flex flex-row !pb-0">
                    <span className="text-sm lg:basis-7/12">This page contains links to the Mitsubishi Power Technical Bulletins for your turbine generator.</span>
                </div>
            </div>
            
            <TabbedSections sections={sections}
                        data={(bulletins ?? []).filter(filterTechnicalBulletins)}
                        columns={columns}
                        itemActions={actions}
                        itemActionText={() => <img src={ellipsis} alt="More Actions" className="cursor-pointer" />}
                        bulkActions={bulkActions}
                        sortValues={sortableProperties}
                        deletedItem="isDeleted"
                        loading={isFetching || isLoading}
                        readItemKey="isRead"
                        filterContent={<Filters filters={filters} onFiltersUpdated={setFilters} units={units} implementationStatuses={implementationStatuses} />}
                        filterCount={filterCount}
                        filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />}
                        accordionTitle={u => u.displayFileName} searchKeys={['applicability', 'implementationStatus', 'scope', 'displayFileName', 'summaryGuideline']} />
        </div>
    );
};

const CopyLinkModal = ({bulletin, onClose}: {bulletin: TechnicalBulletin, onClose: () => void}) => {
    const { selectedUserGroup } = useContext(Store);
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    return (
        <ModalGlobal modalSize="sm"
                    modaltitle={`Copy Link to "${bulletin.displayFileName}"`}
                    isModalHeader={true}
                    modalClose={onClose}>
            <div className={`flex justify-between`}>
            <input type="text"
                defaultValue={bulletin.fileCopyLink}
                disabled
                placeholder="Recording Type"
                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                style={{boxShadow: '0 1px 0 #B3B3B3'}} />

            <Button className={`darkBlue text-sm`}
                    text="Copy Link"
                    preImg={<img src={copyLink} alt="Copy Link" className="invert hover:invert mr-2 h-5 w-5" />}
                    onClick={() => {
                        navigator.clipboard.writeText(`${bulletin.fileCopyLink}?userGroupId=${selectedUserGroup.id}`);
                        setCopySuccess(true);
                    }} />
            </div>
            {copySuccess &&
                <div className="flex justify-start content-start mt-4 text-sm">
                    <img src={blueTick} alt="Link successfully copied" className="mr-2" />
                    <span className="text-[#00749E]">Link successfully copied</span>
                </div>
            }
        </ModalGlobal>
    );
};

const ViewTechnicalBulletin = ({ bulletin }: {bulletin: TechnicalBulletin}) => {

    const pillType: PillType = useMemo(() => {
        if (bulletin.implementationStatus.toLowerCase() === 'completed') {
            return 'success';
        }
        if (bulletin.implementationStatus.toLowerCase() === 'not performed') {
            return 'dkBlue';
        }
        return 'warning';
    }, [bulletin]);

    return (
      <div className="flex flex-col items-start gap-4 self-stretch">
        <span className="font-bold text-xl">Details for {bulletin.displayFileName}</span>
        <span className="text-sm">Please find the details for “{bulletin.displayFileName}” below.</span>
        <div className="flex flex-col items-start gap-6">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-sm">Implementation Status</span>
                <Pill type={pillType}>{bulletin.implementationStatus}</Pill>
            </div>
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-sm">Scope</span>
                <span className="text-sm">{bulletin.scope}</span>
            </div>
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-sm">Summary</span>
                <span className="text-sm">{bulletin.summaryGuideline}</span>
            </div>
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-sm">Document Group</span>
                <span className="text-sm">{bulletin.reportType}</span>
            </div>
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold text-sm">Last Modified</span>
                <Moment date={bulletin.updated} format="DD/MM/YYYY" className="text-sm" />
            </div>
            <Link to={`/shared-document/${bulletin.fileId}`} className="no-underline text-dark-blue font-bold flex flex-row gap-[10px]" target="_blank" rel="noreferrer">
                <span>View the full document</span>
                <img src={Arrow} style={{filter: 'var(--svgFilterDarkBlueButton)'}}  alt="View Full Document" />
            </Link>
        </div>
      </div>
    );
  };


  const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {

    return (
        <div className={`flex gap-2`}>
            {filters.showDeleted && (
                <Pill className={``}
                    dismissible={true}
                    onDismiss={() => onFiltersUpdated({ ...filters, showDeleted: false })}
                    type="default"
                >
                    Show Deleted
                </Pill>
            )}
            {filters.implementationStatus.map(status => (
                <Pill key={status} dismissible={true} className={`capitalize`}
                        onDismiss={() => {
                            onFiltersUpdated({...filters, implementationStatus: filters.implementationStatus.filter(d => d !== status)})
                        }}
                        type="default">
                    {status}
                </Pill>
            ))}
            {filters.equipment.filter(d => d.id > 0).map(equipment => (
                <Pill key={equipment.id} dismissible={true} className={`capitalize`}
                        onDismiss={() => {
                            onFiltersUpdated({...filters, equipment: filters.equipment.filter(d => d.id !== equipment.id)})
                        }}
                        type="default">
                    {equipment.name}
                </Pill>
            ))}
            {filters.dateFilter && (
                <Pill className={``}
                      dismissible={true}
                      onDismiss={() => onFiltersUpdated({ ...filters, dateFilter: undefined, startDate: undefined, endDate: undefined })}
                      type="default"
                >
                    {DatePublishedFilters.find((d) => d.value === filters.dateFilter)?.label}
                </Pill>
            )}
        </div>
    );
};

const DatePublishedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "custom",
  },
];

const Filters = ({filters, units, implementationStatuses, onFiltersUpdated}: {filters: FilterStructure, units: UnitDATA[], implementationStatuses: ImplementationStatus[], onFiltersUpdated: (data: FilterStructure) => void}) => {
    const [selectedImplementationStatuses, setSelectedImplementationStatus] = useState<ImplementationStatus[]>([]);
    const [selectedUnit, setSelectedUnit] = useState<number | false>(false);
    const [selectedEquipment, setSelectedEquipment] = useState<{id: number, name: string}[]>([]);
    const [equipment, setEquipment] = useState<PlantEquipmentsDATA[]>([]);
    const isFirstRender = useRef<{id: number, name: string}>();
    const [dateFilter, setDateFilter] = useState<string>();  
    const [startDate, setStartDate] = useState(dayjs(null));
    const [endDate, setEndDate] = useState(dayjs(null));
    const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
    const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
    const [showDeleted, setShowDeleted] = useState<boolean>(false);
    const { header: {data: {user}} } = useContext(Store);

    useGetAllByUnitID_PlantEquipments(
        selectedUnit || 0,
        (data: PlantEquipmentsDTO) => {
            setEquipment(data.data);
            if (filters.equipment.length > 0) {
                const items = [...selectedEquipment];
                const tmpItem = items.splice(0, 1).pop();
                if (tmpItem) {
                    setSelectedEquipment(items);
                    isFirstRender.current = tmpItem;
                }
            }
        },
        undefined
    );

    useEffect(() => {
        if (isFirstRender.current !== undefined) {
            handleEquipmentSelection([], isFirstRender.current.id);
        }
    }, [equipment]);

    useEffect(() => {
        setSelectedImplementationStatus(filters.implementationStatus);
        setSelectedUnit(filters.unit);
        setSelectedEquipment(filters.equipment);
        setDateFilter(filters.dateFilter);
        setStartDate(dayjs(filters.startDate));
        setEndDate(dayjs(filters.endDate));
        setShowDeleted(filters.showDeleted);
    }, [filters]);

    const unitOptions: DropDownOptionItem[] = [
        { id: false, name: "Select a unit" },
        ...(units || []).map((unit) => ({ id: unit.unitId, name: unit.name })),
    ];

    const equipmentOptions: {id: number, name: string}[] = useMemo(() => {
        const productTypes = Object.entries(Object.fromEntries(equipment.map(d => [d.productType * -1, `All ${d.productTypeName}`])));
        const equipmentOptions: {id: number, name: string}[] = [
            {id: 0, name: 'All Equipment'},
            ...productTypes.map(d => ({
                id: Number(d[0]),
                name: `${d[1]}`
            })),
            ...equipment.map(d => ({
                id: Number(d.plantEquipmentId),
                name: d.equipmentName
            }))
        ];
        return equipmentOptions;
    }, [equipment]);

    const handleEquipmentSelection = (selections: number[], toggled: number) => {
        const items = [...selectedEquipment];
        const index = items.findIndex(d => {
            return d.id === toggled;
        });

        const toggledOption = equipmentOptions.find(d => d.id === toggled);
        if (toggledOption === undefined) return;

        if (index === -1) {
            items.push(toggledOption);
        } else {
            items.splice(index, 1);
        }

        if (toggled < 0) {
            const productTypeId = toggled * -1;
            const productTypeEquipmentIds = equipment.filter(d => d.productType === productTypeId)
                                                    .map(d => ({id: d.plantEquipmentId, name: d.equipmentName}));
            if (index === -1) {
                items.push(...productTypeEquipmentIds.filter(d => !items.map(d => d.id).includes(d.id)));
            } else {
                productTypeEquipmentIds.forEach(value => {
                    const index = items.findIndex(d => d.id === value.id);
                    if (index > -1) {
                        items.splice(index, 1);
                    }
                })
            }
        }

        if (toggled > 0) {
            const toggledEquipment = equipment.find(d => d.plantEquipmentId === toggled);
            if (toggledEquipment === undefined) return;
            const productType = {id: toggledEquipment.productType, name: `All ${toggledEquipment.productTypeName}`};
            const productTypeEquipment = equipment.filter(d => d.productType === productType.id).map(d => d.plantEquipmentId);
            const equipmentIntersection = items.filter(d => productTypeEquipment.includes(d.id));
            if (index === -1) {
                // user clicked a checkbox resulting in all product type equipment being selected
                if (productTypeEquipment.length === equipmentIntersection.length) {
                    items.push({
                        id: productType.id * -1,
                        name: productType.name
                    })
                }
            } else {
                // user clicked a checkbox resulting in 0 product type equipment being selected
                if (equipmentIntersection.length !== productTypeEquipment.length) {
                    const productTypeIdIndex = items.findIndex(d => d.id === (productType.id * -1));
                    if (productTypeIdIndex > -1) {
                        items.splice(productTypeIdIndex, 1);
                    }
                }
            }
        }

        if (toggled === 0) {
            if (index === -1) {
                items.push(...equipmentOptions.filter(d => !items.map(d => d.id).includes(d.id)));
            } else {
                items.splice(0, items.length);
            }
        } else {
            if (index === -1) {
                const equipmentObjs = equipment.map(d => ({id: d.plantEquipmentId, name: d.equipmentName}));
                const missingEquipment = equipmentObjs.filter(d => !items.map(d => d.id).includes(d.id));
                if (missingEquipment.length === 0) {
                    items.push({id: 0, name: 'All Equipment'});
                }
            } else {
                const allIndex = items.findIndex(d => d.id === 0);
                if (allIndex > -1) {
                    items.splice(allIndex, 1);
                }
            }
        }

        setSelectedEquipment(items);
    };

    const selectionText = useMemo(() => {
        if (selectedEquipment.map(d => d.id).includes(0)) return 'All Equipment';
        if (selectedEquipment.length === 1) {
            return equipmentOptions.find(d => d.id === selectedEquipment[0].id)?.name;
        }
        if (selectedEquipment.filter(d => d.id < 0).length === 1) {
            return selectedEquipment.find(d => d.id < 0)?.name;
        }
        return 'Multiple equipment options selected';
    }, [selectedEquipment]);

    const toggleImplementationStatus = useCallback((status: ImplementationStatus) => {
        const statuses = [...selectedImplementationStatuses];
        const index = statuses.findIndex(d => d === status);
        if (index === -1) {
            statuses.push(status);
        } else {
            statuses.splice(index, 1);
        }
        setSelectedImplementationStatus(statuses);
    }, [selectedImplementationStatuses]);

    const applyFilters = useCallback(() => {
        onFiltersUpdated({
            implementationStatus: selectedImplementationStatuses,
            unit: selectedUnit,
            equipment: selectedEquipment,
            dateFilter,
            startDate: dateFilter? startDate : undefined,
            endDate: dateFilter? endDate : undefined,
            showDeleted
        });
    }, [selectedImplementationStatuses, selectedUnit, selectedEquipment, dateFilter, startDate, endDate, showDeleted])

    const filtersChanged = useMemo(() => {
        const obj1 = Object.entries({
            implementationStatus: filters.implementationStatus,
            equipment: filters.equipment.sort(),
            dateFilter: filters.dateFilter,
            startDate: filters.startDate,
            endDate: filters.endDate,
            showDeleted: filters.showDeleted
        });
        const obj2 = Object.entries({
            implementationStatus: selectedImplementationStatuses,
            equipment: selectedEquipment.sort(),
            dateFilter,
            startDate: dateFilter? startDate : undefined,
            endDate: dateFilter? endDate : undefined,
            showDeleted
        });
        return obj1.toString() !== obj2.toString();
    }, [filters, selectedImplementationStatuses, selectedEquipment, dateFilter, startDate, endDate, showDeleted]);

    const isValidForm = useMemo(() => {
        return selectedUnit === false || selectedEquipment.filter(d => d.id > 0).length > 0
    }, [selectedUnit, selectedEquipment]);

    const canApplyFilters = useMemo(() => {
        return isValidForm && filtersChanged;
    }, [isValidForm, filtersChanged]);

    return (
        <div className="flex flex-col items-stretch p-6 gap-6 whitespace-nowrap min-w-[300px] max-h-[60vh] overflow-auto filterContainer">
            {user.portalAdmin && (
                <div className="flex flex-col items-start gap-2">
                    <span className="font-bold">Show Deleted:</span>
                    <Toggle className="m-0 scale-[.7812]" checked={showDeleted} onChange={() => setShowDeleted(d => !d)} />
                </div>
            )}
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Implementation Status:</span>
                {implementationStatuses.map(status => (
                    <Checkbox key={status} isChecked={selectedImplementationStatuses.includes(status)} onChange={() => toggleImplementationStatus(status)} label={status} labelClass="capitalize" />
                ))}
            </div>
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Unit Association:</span>
                <PlainDropDown className="w-full relative"
                               selectClass={`flex flex-row items-center border border-solid border-[#999] rounded pt-1 pr-2 !pb-1 pl-4 text-sm justify-between cursor-pointer ${selectedUnit === false? 'text-[#999]' : ''}`}
                               optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
                               itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                               onSelection={(unit) => {
                                    setSelectedEquipment([]);
                                    setSelectedUnit(unit.id)
                               }}
                               value={unitOptions.map((d) => d.id).includes(selectedUnit) ? selectedUnit : false}
                               options={unitOptions} />
            </div>
            <div className="flex flex-col items-start gap-2">
                <label>Equipment Association:</label>
                <MultiSelectDropDown className="w-full relative"
                                     selectClass={`flex flex-row items-center border border-solid border-[#999] rounded pt-1 pr-2 !pb-1 pl-4 text-sm justify-between ${selectedEquipment.length === 0? 'text-[#999]' : ''} ${selectedUnit === false? '' : 'cursor-pointer'}`}
                                     optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
                                     itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                     onSelection={handleEquipmentSelection}
                                     value={selectedEquipment.map(d => d.id)}
                                     disabled={selectedUnit === false}
                                     disabledClass={`bg-neutral border-0`}
                                     noSelectionText="Select equipment"
                                     hasSelectionText={selectionText}
                                     options={selectedUnit ? equipmentOptions : []} />
            </div>
            <div className="flex flex-col items-start gap-2">
                <label>Date Published:</label>
                <RadioButtonGroup onChange={setDateFilter} name="date_published" options={DatePublishedFilters} value={dateFilter} />
            </div>
            {dateFilter === "custom" && (
                <div className="">
                    <DateRangePicker startDate={startDate}
                                    endDate={endDate}
                                    showEndCalendar={showEndCalendar}
                                    showStartCalendar={showStartCalendar}
                                    startName="startDate"
                                    endName="endDate"
                                    startDateChange={setStartDate}
                                    endDateChange={setEndDate}
                                    setToggleEndCalendarDisplay={setShowEndCalendar}
                                    setToggleStartCalendarDisplay={setShowStartCalendar}
                                    containerStyles={{}}
                                    inputClassName="w-full"
                                    />
                </div>
            )}
            <div>
                <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
            </div>
        </div>
    );
};