import { Outlet, useOutletContext } from "react-router-dom";
import classes from "./discussions.module.css";
import { DiscussionGroupInterface, DocumentGroup, Permissions } from "../../utils/interface";
import useWindowSize from "../../customHooks/useWindowSize";
import { DiscussionModuleInterface, DocumentsOutletInterface } from "./DiscussionsContainer";

export interface DiscussionModuleOutletInterface extends DocumentsOutletInterface {
  documentModule: DiscussionModuleInterface;
  documentGroups: DiscussionGroupInterface[];
  setDocumentGroups: React.Dispatch<React.SetStateAction<DiscussionGroupInterface[]>>;
  groupPermissions: Permissions;
  pagePermissions: Permissions;
  setPagePermissions: React.Dispatch<React.SetStateAction<Permissions>>;
}
function DiscussionModuleContainer() {
  const outletContext = useOutletContext<DiscussionModuleOutletInterface>();
  const { documentGroups, setDocumentGroups } = outletContext;
  const { isMobile } = useWindowSize();

  return (
    <div className={`${classes.module_container} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""}`}>
      <Outlet context={{ ...outletContext, documentGroups, setDocumentGroups }} />
    </div>
  );
}

export default DiscussionModuleContainer;
