import { CPApiResponse, User, KPI } from "../../../utils/interface";
import { KPIsApiUrl, userApiUrl } from "../../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../../../react-query/queryKeys";

interface UserCrudMethods<T1> {
	getUser: (userId: number) => Promise<T1>;
}

const UserApi: UserCrudMethods<AxiosResponse<CPApiResponse<User>>> = {
	getUser: async (userId: number): Promise<AxiosResponse<CPApiResponse<User>>> => {
		const response = await axios({
			url: `${userApiUrl}/${userId}`,
			method: "GET",
		});
		return response.data;
	},
};

export const useQuery_User = (baseOrgId: number, onSuccess: any, onError: any) => {
	return useQuery(queryKeys.user, () => UserApi.getUser(baseOrgId), {
		onSuccess,
		onError,
	});
};

interface KPICrudMethods<T1, T2> {
	getPlantKPIs: (plantId: number) => Promise<T1>;
	getAllPlantsKPIs: () => Promise<T2>;
}

const KPIApi: KPICrudMethods<AxiosResponse<CPApiResponse<KPI>>, AxiosResponse<CPApiResponse<KPI>>> = {
	getPlantKPIs: async (plantId: number): Promise<AxiosResponse<CPApiResponse<KPI>>> => {
		const response = await axios({
			url: `${KPIsApiUrl}/${plantId}`,
			method: "GET",
		});
		return response.data;
	},
	getAllPlantsKPIs: async (): Promise<AxiosResponse<CPApiResponse<KPI>>> => {
		const response = await axios({
			url: `${KPIsApiUrl}/GetAll`,
			method: "GET",
		});
		return response.data;
	},
};

export const useQuery_PlantKPIs = (plantId: number, onSuccess: any, onError: any) => {
	return useQuery(queryKeys.KPI, () => KPIApi.getPlantKPIs(plantId), {
		onSuccess,
		onError,
	});
};

export const useQuery_AllPlantsKPIs = (onSuccess: any, onError: any) => {
	return useQuery(queryKeys.KPI, () => KPIApi.getAllPlantsKPIs(), {
		onSuccess,
		onError,
	});
};
