import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext } from 'react';
import { QueryFunctionContext, UseQueryOptions, useQuery } from "react-query";
import { Store } from "../Store";
import { apiFile, apiMessageAttachment, apiUgFile } from "../endpoints/endpoints";
import { queryKeys } from "../react-query/queryKeys";



export const downloadFile = (globalItemId: number): Promise<void> => {
    return downloadFileByUrl(`${apiUgFile}/DownloadFile/${globalItemId}`)
};

export const downloadMessageAttachment = async (messageAttachmentId?: number): Promise<void> => {
    return downloadFileByUrl(`${apiMessageAttachment}/Download/${messageAttachmentId}`);
};

export const downloadMessageAttachmentByFileId = (fileIds: number[]): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < fileIds.length; i++) {      
        promises.push(downloadFileByFileId(fileIds[i]));
    } 
    return Promise.all(promises);
};

export const downloadMultipleFiles = (globalItemIds: number[]): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < globalItemIds.length; i++) {      
        promises.push(downloadFile(globalItemIds[i]));
    }
    return Promise.all(promises);
};

export const downloadFileByUrl = async (url: string): Promise<void> => {
    const a = document.createElement('a');
    a.style.display = 'none';
    const response: AxiosResponse<Blob> = await axios(url, {
        responseType: 'blob'
    });

    const contentDisposition = response.headers['content-disposition'];
    const idIndex = url.split('/').length - 1;
    const globalIdFromUrl = url.split('/')[idIndex];
    const defaultFileName = `${globalIdFromUrl}.${response?.data?.type.split('/')[1]}`;

    let fileName;
    if (contentDisposition) {
        fileName = contentDisposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        fileName = decodeURI(fileName);
    }
        
    if (response?.data?.size > 0 && response?.data?.type) {
        a.href = URL.createObjectURL(response.data);
        a.download = fileName || defaultFileName;
        document.body.append(a);
        a.click();
    }
};

export const downloadStaticFile = (globalItemId: number): void => {
	downloadFileByUrl(`${apiFile}/DownloadStaticFile/${globalItemId}`);
};

export const downloadFileByFileId = (fileId: number): Promise<void> => {
    return downloadFileByUrl(`${apiUgFile}/DownloadFileByFileId/${fileId}`);
};
export const downloadMultipleFilesByFileId = (fileIds: number[]): Promise<void[]> => {
    const promises: Promise<void>[] = [];
    for (let i = 0; i < fileIds.length; i++) {
        promises.push(downloadFileByFileId(fileIds[i]));
    }
    return Promise.all(promises);
};


declare type GetFileContentsParameters = ['fileContents', {url: string | undefined}];
declare type E = AxiosError | Error;
const getFileContents = async ({queryKey}: QueryFunctionContext<GetFileContentsParameters>): Promise<AxiosResponse<Blob>> => {
  const [_key, {url}] = queryKey;
  if (!url) {
    return Promise.reject(new Error('Invalid URL'));
  }
  const response: AxiosResponse<Blob> = await axios(url, {
    responseType: 'blob'
  });
  return response;
};

export const useGetFileContents = (url: string, options: Omit<UseQueryOptions<AxiosResponse<Blob>, E, AxiosResponse<Blob>, GetFileContentsParameters>, "queryKey" | "queryFn"> = {}) => {
    return useQuery<AxiosResponse<Blob>, E, AxiosResponse<Blob>, GetFileContentsParameters>(['fileContents', {url}], getFileContents, {
        ...options,
        enabled: !!url && options.enabled
    });
}

export interface FileRequestCrudMethods<A> {
  getFileAsAxiosResponseBlob: (id: number) => Promise<A>;
}

export const FileRequestCrudHttpServices: FileRequestCrudMethods<
  AxiosResponse<Blob>
> = {
  getFileAsAxiosResponseBlob: async (id: number): Promise<AxiosResponse<Blob>> => {
    const response = await axios({
      url: `${apiUgFile}/DisplayFileByFileId/${id}`,
      method: "GET",
      responseType: "blob",
    });
    
    return response;
  }
};

export const useGetFileAxiosResponseBlob = (id: number, options: Omit<UseQueryOptions<AxiosResponse<Blob>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<AxiosResponse<Blob>>([queryKeys.File, id], () => FileRequestCrudHttpServices.getFileAsAxiosResponseBlob(id), {
    ...options,
  });
};