import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../../Store";
import { TakasagoBulletins } from "../../components/TechnicalBulletins/TakasagoBulletins";
import { TechnicalBulletins } from "../../components/TechnicalBulletins/TechnicalBulletins";
import { useGetSubTypes } from "../../customHooks/TechnicalBulletinHttpServices";
import { usePageRequiresUserGroupSelection } from "../../customHooks/usePageRequiresUserGroupSelection";
import { useSelectedPlant } from "../../customHooks/useSelectedPlant";
import { TechnicalBulletinGroup } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";

interface TechnicalBulletinsContainerProps {

}

export const TechnicalBulletinsContainer = ({ }: TechnicalBulletinsContainerProps) => {
  const { selectedUserGroup } = useContext(Store);
  const [showPlantDropdown, setShowPlantDropdown] = useState<boolean>(false);
  usePageRequiresUserGroupSelection();

  useEffect(() => {
    setShowPlantDropdown(selectedUserGroup.enableTechnicalBulletinList);
  }, [selectedUserGroup]);

  return (
    <MainContent title="Technical Bulletins" breadCrumbs={['Documents', 'Technical Bulletins']} showPlantDropdown={showPlantDropdown}>
      <TechnicalBulletinGroups showTechBulletins={showPlantDropdown} />
    </MainContent>
  );
};

interface TechnicalBulletinGroupsProps {
  showTechBulletins: boolean;
}
const TechnicalBulletinGroups = ({showTechBulletins}: TechnicalBulletinGroupsProps) => {
    const {selectedPlant} = useSelectedPlant();
    const { selectedUserGroup } = useContext(Store);
    const {group} = useParams();
    const groupId = useMemo(() => Number(group), [group]);
    const [groups, setGroups] = useState<TechnicalBulletinGroup[]>();

    const navigate = useNavigate();
    const selectedBaseOrg = useMemo(() => {
        return (selectedPlant?.baseOrgId || 0);
    }, [selectedPlant]);

    const {isFetching} = useGetSubTypes(selectedBaseOrg, {
        onSuccess: (data: TechnicalBulletinGroup[]) => {
            setGroups(data);
            if (data.length > 0 && (!groupId || !data.map(d => d.id).includes(groupId))) {
                navigate(`./${data[0].id}`);
            }
        },
        enabled: !!selectedBaseOrg
    });

    if (showTechBulletins)
    {
      return (
        <>
          {groups && selectedBaseOrg && selectedPlant && !isFetching && <TechnicalBulletins groups={groups} selectedGroupId={groupId} selectedPlant={selectedPlant} />}
        </>
      );
    }

    return <TakasagoBulletins />
};