import { FunctionComponent, ReactElement, useCallback, useMemo, useState } from "react";
import ImageImports from '../../utils/ImageImports';
import { FunctionOrType } from "../../utils/interface";
import Button from "../Button/Button";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import { ItemListProps } from "./ItemList";

const { downArrow, ellipsis } = ImageImports;

type TableListProps<T> = Pick<ItemListProps<T>, 'bulkActions' | 'itemActions' | 'itemActionText' | 'readItemKey' | 'columns' | 'data' | 'noResultsText' | 'deletedItem' | 'loading'> & { selectedItems: T[], onItemSelectionChange: (item: T) => void, onSelectAllItems: () => void };

export const TableList = <T extends { id: number }>({ bulkActions, itemActions, itemActionText, columns, data, noResultsText, readItemKey, deletedItem, loading, selectedItems, onItemSelectionChange, onSelectAllItems }: TableListProps<T>) => {
    const [forceClose, setForceClose] = useState<number>(0);
    const ItemActionTooltip = useMemo(() => {
        if (itemActionText) {
            //return withTooltip(itemActionText);
            return withTooltip(Button);
        }
    }, [itemActionText]);

    const BulkActionTooltip = useMemo(() => {
        if (bulkActions) {
            /* return withTooltip(() => <button className="border-none bg-transparent cursor-pointer">
                <img src={downArrow} alt="Bulk Actions" className="h-6 w-6 mb-[-8px]" />
            </button>); */
            return withTooltip(Button);
        }
    }, [bulkActions]);

    const isItemRead = useCallback((item: T) => {
        return !readItemKey || item[readItemKey];
    }, [readItemKey]);

    const isDeleted = useCallback((item: T): boolean => {
        if (deletedItem) {
            if (typeof deletedItem === 'function') {
                return deletedItem(item);
            } else {
                return !!item[deletedItem];
            }
        }
        return false;
    }, [deletedItem]);

    const getBoolVal = useCallback((v: ((d: T) => boolean) | boolean, d: T): boolean => {
        if (typeof v === 'boolean') {
            return v;
        }
        return v(d);
    }, []);

    // super uncool way to do this but I need it work now
    const getBoolVal2 = (v: FunctionOrType<boolean, T[]>): boolean => {
        if (typeof v === 'boolean') {
            return v;
        }
        return v(...selectedItems);
    };

    const getActionText = (ActionItem: string | ReactElement | FunctionComponent<{selectedItems: T[]}>) => {
        if (typeof ActionItem === 'function') {
            return <ActionItem selectedItems={selectedItems} />
        }
        return ActionItem;
    };

    const selectableItems = data.filter(i => !isDeleted(i));

    return (
            <table className="w-full border-collapse">
                <thead>
                    <tr>
                        {(bulkActions?.length || 0) > 0 && (
                            <th className="bg-[#F2F2F2] p-3 text-left">
                                <div className="flex flex-row !pb-0 items-center shrink">
                                    <Checkbox isChecked={selectableItems.length === selectedItems.length && selectableItems.length > 0} onChange={onSelectAllItems} />
                                    {BulkActionTooltip && bulkActions && (
                                        <BulkActionTooltip 
                                          className="border-none bg-transparent cursor-pointer !p-0"
                                          text={<img src={downArrow} alt="Bulk Actions" className="h-6 w-6 mb-[-8px]" />}
                                          forceClose={forceClose} 
                                          tooltipContent={<div className="p-6 rounded-lg">
                                            <div className="flex flex-col items-start gap-6">
                                                {bulkActions.map((action, i) => {
                                                    const disabled = action.disabled !== undefined && getBoolVal2(action.disabled);
                                                    return (
                                                        <span key={`action-${i}`} onClick={(e) => {
                                                            if (!disabled) {
                                                                action.onClick(e, selectedItems);
                                                                setForceClose(fc => ++fc);
                                                            }
                                                        }}>{getActionText(action.text)}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>} />
                                    )}
                                </div>
                            </th>
                        )}
                        {columns.map(column => <th key={column.key as string} className="bg-[#F2F2F2] font-bold p-3 text-left">{column.title}</th>)}
                        {(itemActions?.length || 0) > 0 && <th className="bg-[#F2F2F2] font-bold p-3 text-left"></th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => {
                        const applicableItemActions = (itemActions || []).filter(itemAction => {
                            return itemAction.visible === undefined || getBoolVal(itemAction.visible, item);
                        });
                        const hasSomeEnabledActionItems = (itemActions || []).filter(itemAction => {
                          return !(itemAction.disabled && getBoolVal(itemAction.disabled, item));
                        });
                        return (
                            <tr key={item.id} style={{ color: isDeleted(item)? 'var(--primaryGreyFont)' : 'inherit'}}>
                                {(bulkActions?.length || 0) > 0 && (
                                    <td className={`${isItemRead(item) ? '' : 'bg-[#00749e]/5'} p-3`}>
                                        <Checkbox isChecked={selectedItems.map(d => d.id).includes(item.id)} onChange={() => onItemSelectionChange(item)} disabled={isDeleted(item)} />
                                    </td>
                                )}
                                {columns.map((column, ci) => (
                                    <td key={column.key as string} className={`p-3 text-left text-sm ${i > 0 ? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item) ? '' : 'bg-[#00749e]/5'}  ${!isItemRead(item) && ci === 0 ? 'font-bold' : ''}`}>
                                        {column.component ? <column.component data={item} /> : <div className={column.className ?? ''}><>{item[column.key]}</></div>}
                                    </td>
                                ))}
                                {ItemActionTooltip && (applicableItemActions.length) > 0 && (
                                    <td className={`p-3 text-left text-sm ${i > 0 ? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item) ? '' : 'bg-[#00749e]/5'}`}>
                                      {hasSomeEnabledActionItems.length > 0 && (
                                        <ItemActionTooltip 
                                          className='border-none bg-transparent cursor-pointer !p-0'
                                          text={
                                            <img src={ellipsis} alt="More Actions" className="cursor-pointer" />
                                          }
                                          toggleClickWatcher={false}
                                          forceClose={forceClose} 
                                          tooltipContent={<div className="p-6 rounded-lg"
                                        >
                                            <div className="flex flex-col items-start gap-6">
                                                {applicableItemActions.map((action, i) => {
                                                    const disabled = action.disabled !== undefined && getBoolVal(action.disabled, item);
                                                    return (
                                                        <span key={`action-${i}`} className={`${disabled? 'text-[var(--greyButton)]' : 'text-dark-blue cursor-pointer'}`} onClick={(e) => {
                                                            if (!disabled) {
                                                                action.onClick(e, item);
                                                                setForceClose(fc => ++fc);
                                                            }
                                                        }}>{action.text}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>} />
                                      )}
                                    </td>
                                )}
                                {(itemActions?.length || 0) > 0 && <td></td>}
                            </tr>
                        )
                    })}
                    {!loading && data.length === 0 && (
                        <tr>
                            <td colSpan={99} className="px-2 py-3">{noResultsText}</td>
                        </tr>
                    )}
                </tbody>
            </table>
    );
};