import React, { useCallback, useContext, useMemo } from 'react';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { Store } from '../../Store';
import './Sidebar.css';

import { useIsInternalAdmin } from '../../customHooks/useIsInternalAdmin';
import ImageImports from "../../utils/ImageImports";

const {documentSearch,discussion,plant,home,openClose,privacy_icon, cameraSquare, Arrow} = ImageImports;
;
interface sidebarRouteObj {
  id: string,
  title: string,
  icon: string,
  cName: string,
  subRoutes: Array<{ route: string, title: string, requiresAdmin?: boolean, requiresUsersGroupSelection?: boolean}>,
  requiresAdmin: boolean,
  requiresUsersGroupSelection: boolean,
  highlightIfRouteMatches: RegExp,
  showNonRouteTitle?: boolean,
};

function Sidebar() {
  const stateContext = useContext(Store)
  const { sidebarToggle : isSidebarOpen, toggleSidebar, selectedUserGroup } = stateContext;


  const sidebarRoutes: sidebarRouteObj[] = [
    {
      id: "home",
      title: "Home",
      icon: home,
      cName: "sidebar-item",
      subRoutes: [{ route: "/", title: "Home" }],
      requiresAdmin: false,
      requiresUsersGroupSelection: false,
      highlightIfRouteMatches: /^\/(home)?$/
    },
    {
      id: "techDoc",
      title: "Documents",
      icon: documentSearch,
      cName: "sidebar-item",
      subRoutes: [
        { route: '/technical-bulletins', title: "Technical Bulletins", requiresUsersGroupSelection: true},
        { route: '/technical-documentation/General Documents', title: selectedUserGroup.id > 0? "Users' Group Documents" : "General Documents"},
      ],
      requiresAdmin: false,
      requiresUsersGroupSelection: false,
      highlightIfRouteMatches: /^\/technical-documentation\//
    },
    {
      id: 'recordings',
      title: 'Recordings',
      icon: cameraSquare,
      cName: 'sidebar-item',
      subRoutes: [
        { route: '/recordings', title: 'Recordings' }
      ],
      requiresAdmin: false,
      requiresUsersGroupSelection: true,
      highlightIfRouteMatches: /^\/recordings/
    },
    {
      id: "discussions",
      title: "Discussions",
      icon: discussion,
      cName: "sidebar-item",
      subRoutes: [
        { route: generatePath('/discussions/:moduleName', {moduleName: 'Discussions'}), title: "Discussions"},
      ],
      requiresAdmin: false,
      requiresUsersGroupSelection: true,
      highlightIfRouteMatches: /^\/discussions\//
    },
    {
      id: "plantSite",
      title: "Plant Information",
      icon: plant,
      cName: "sidebar-item",
      subRoutes: [{ route: generatePath('/:param', {param: 'plant-information'}), title: "Plant Information" }],
      requiresAdmin: false,
      requiresUsersGroupSelection: true,
      highlightIfRouteMatches: /^\/plant-information/,
    },
    {
      id: "administration",
      title: "Administration",
      icon: privacy_icon,
      cName: "sidebar-item",
      subRoutes: [
        {route: '/administration/group-management', title: "Group Management"},
        {route: '/administration/plant-management', title: "Plant Management"},
        {route: '/administration/role-configuration', title: "Role Configuration"},
        {route: '/administration/user-management', title: "User Management"},
        {route: '/administration/email-alert-management', title: "Email Alert Management"},
      ],
      requiresAdmin: true,
      requiresUsersGroupSelection: false,
      highlightIfRouteMatches: /^\/administration\//
    },
  ];

  const isInternalAdmin = useIsInternalAdmin();
  const adminFilter = useCallback(({requiresAdmin}: {requiresAdmin?: boolean}): boolean => {
    if (requiresAdmin === undefined || requiresAdmin === false) {
      return true;
    }
    return isInternalAdmin;
  }, [isInternalAdmin]);

  const usersGroupFilter = useCallback(({requiresUsersGroupSelection}: {requiresUsersGroupSelection?: boolean}): boolean => {
    if (requiresUsersGroupSelection === undefined || requiresUsersGroupSelection === false) {
      return true;
    }
    return selectedUserGroup.id > 0;
  }, [selectedUserGroup]);

  return (
    <div className='sidebar-wrapper'>
      <div className={isSidebarOpen ? "sidebar active" : "sidebar"}>
        <nav className={`sidebar-menu ${isSidebarOpen ? "active" : ""}`}>
          <ul className='sidebar-menu-items'>
            <li className={`sidebar-item ${isSidebarOpen? 'active' : ''}`}
                onClick={() => {
                  toggleSidebar && toggleSidebar(!isSidebarOpen);
                }}
              >
                <Link to="#" className='menu-toggle open-close a-close'>
                  <img src={openClose} className={`sidebar-icon ${isSidebarOpen? 'flip' : ''}`} alt="" />
                  {isSidebarOpen? <span className='toggle-text'>Close</span> : undefined}
                </Link>
              </li>
            {sidebarRoutes.filter(adminFilter).filter(usersGroupFilter).map((route: sidebarRouteObj) => isSidebarOpen? <RouteMenuItemOpen key={route.id} route={route} adminFilter={adminFilter} usersGroupFilter={usersGroupFilter} /> : <RouteMenuItemClosed key={route.id} route={route} adminFilter={adminFilter} usersGroupFilter={usersGroupFilter} />)}
          </ul>
        </nav>
      </div>

    </div>
  );
}

export default Sidebar;

const RouteMenuItemOpen = ({route, adminFilter, usersGroupFilter}: {
  route: sidebarRouteObj,
  adminFilter: (route: {requiresAdmin?: boolean}) => boolean,
  usersGroupFilter: (route: {requiresUsersGroupSelection?: boolean}) => boolean
}) => {
  const {pathname} = useLocation();
  const stateContext = useContext(Store);
  const { toggleSidebar } = stateContext;
  const matched = route.highlightIfRouteMatches.test(pathname);

  const subRoutes = useMemo(() => {
    return route.subRoutes.filter(adminFilter).filter(usersGroupFilter);
  }, [route.subRoutes, adminFilter, usersGroupFilter]);

  return (
    <li key={route.id}
        className={`flex flex-row items-center expanded sidebar-item ${matched ? " active-location noHover" : ''} overflow-hidden ${subRoutes.length === 1 && !route.showNonRouteTitle? '' : 'has-sub-routes'}`}
        tabIndex={1}>
      {(subRoutes.length === 1 && !route.showNonRouteTitle) ? (
        <>
        <Link to={route.subRoutes[0].route} className="text-white flex flex-row items-center whitespace-nowrap no-underline" onClick={() => toggleSidebar?.(false)}>
          <img src={route.icon}
              className={`sidebar-icon ${matched ? "active-location-icon" : ""}`} alt={route.title} />
          <span className={`sidebar-menu-txt inline-flex flex-row justify-between items-center active ${matched ? "active-text" : "text"}`}>
            {route.title}
          </span>
        </Link>
        </>
      ) : (
        <>
          <img src={route.icon}
              className={`sidebar-icon ${matched ? "active-location-icon" : ""}`} alt={route.title} />
          <span className={`sidebar-menu-txt flex flex-row justify-between items-center active ${matched ? "active-text" : "text"}`}>
            {route.title}
            <img src={Arrow} alt="open" style={{filter: matched? 'var(--svgFilterWhite)' : 'var(--svgFilterGrey)'}} />
          </span>
          <div className="sub-route-wrapper active">
            <div className="inner-wrapper">
              {subRoutes.map((subRoute, i: number) => {
                return (
                  <div className="" key={i}>
                    <Link to={subRoute.route} className="sub-route"
                      onClick={(e) => {
                        if (toggleSidebar) {
                          toggleSidebar(false);
                        }
                        // This will hide the menu when you click on a link
                        const el = e.currentTarget.closest<HTMLDivElement>('.sub-route-wrapper');
                        if (el) {
                          el.style.display = 'none';
                          setTimeout(() => {
                            el.style.display = '';
                          }, 0);
                        }
                      }}
                    >
                      {subRoute.title}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </li>
  );
};

const RouteMenuItemClosed = ({route, adminFilter, usersGroupFilter}: {
  route: sidebarRouteObj,
  adminFilter: (route: {requiresAdmin?: boolean}) => boolean,
  usersGroupFilter: (route: {requiresUsersGroupSelection?: boolean}) => boolean
}) => {
  const {pathname} = useLocation();
  const stateContext = useContext(Store);
  const { toggleSidebar } = stateContext;
  const matched = route.highlightIfRouteMatches.test(pathname);

  return (
    <li key={route.id}
        className={`flex flex-row items-center sidebar-item ${matched ? "active-location noHover" : ''}`}
        tabIndex={1}>
      <img src={route.icon}
           className={matched ? "sidebar-icon active-location-icon" : "sidebar-icon"} alt={route.title} />
      <div className="sub-route-wrapper">
        <div className="inner-wrapper">
          {(route.subRoutes.filter(adminFilter).filter(usersGroupFilter).length > 1 || route.showNonRouteTitle) && (
            <div className="">
              <div className="sidebar-parent-nonroute">
                {route.title}
              </div>
            </div>
          )}

          {route.subRoutes.filter(adminFilter).filter(usersGroupFilter).map((subRoute, i: number) => {

            return (
              <div className="" key={i}>
                <Link to={subRoute.route} className="sub-route"
                  onClick={(e) => {
                    if (toggleSidebar) {
                      toggleSidebar(false);
                    }
                    // This will hide the menu when you click on a link
                    const el = e.currentTarget.closest<HTMLDivElement>('.sub-route-wrapper');
                    if (el) {
                      el.style.display = 'none';
                      setTimeout(() => {
                        el.style.display = '';
                      }, 0);
                    }
                  }}
                >
                  {subRoute.title}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </li>
  );
};