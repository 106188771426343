import React from "react";
import spinner from "../../assets/loader.gif";

const Loader = (props: LoaderProps) => {
	const containerStyle = props.containerStyle || {
		marginBottom: "40px",
		marginTop: "0px",
	};
	
	return (
		<div style={containerStyle} className="loader">
			<img style={props.iconStyle} src={spinner} alt="loading" />{" "}
		</div>
	);
};

interface LoaderProps {
	containerStyle?: React.CSSProperties;
	iconStyle?: React.CSSProperties;
}

export default Loader;
