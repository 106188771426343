import React from "react";
import { PlantContact } from "../../../utils/interface";
import "./SiteContactAccordian.css";
import SiteContactAccordianPanel from "./SiteContactAccordianPanel/SiteContactAccordianPanel";

const SiteContactAccordian = ({
  panels,
}: SiteContactAccordianProps) => {
  
  return (
    <div className="accordion" role="tablist">
      {panels &&
        panels.map((panel, index) => (
          <SiteContactAccordianPanel
            key={panel.id}
            index={index + 1}  
            {...panel}
          />
        ))}
    </div>
  );
};

interface SiteContactAccordianProps {
  panels:
    | {
        id: number;
        contact_type: string;
        contact_list: PlantContact[];
      }[]
    | null; 
}

export default SiteContactAccordian;
