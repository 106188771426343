import React, {useState, ReactElement} from 'react';
import classes from "./ManageRoles.module.css";
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import EmptyModal from '../Modals/EmptyModal';
import { DropDownOptionItem, PlainDropDown } from '../UI/Form/DropDownSelect/PlainDropDown';

interface AddNewRoleModalProps {
    shown: boolean;
    onCreate: (name: string, mpInternal: boolean) => void;
    onClose: () => void;
}

function AddNewRoleModal(props: AddNewRoleModalProps) {
    const [itemTypeCatalogName, setItemTypeCatalogName] = useState<string>('');
    const [mpInternal, setMpInternal] = useState<boolean>(false);
    const [roleType, setRoleType] = useState<string>('');
    const [roleTypeId, setRoleTypeId] = useState<number|false>(0);
    
    const {isMobile} = useWindowSize();

    const roleTypeOptions: DropDownOptionItem[] = [
        { id: 1, name: "Internal Mitsubishi Power role", value: 'internal' },
        { id: 2, name: "External Mitsubishi Power role", value: 'external' },
    ];

    let width = 500;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <div className={`${classes.modal_body_input_group} ${classes.flex_row} items-center !gap-y-4`}>
                    <span>Role Name*</span>
                    <div className={`${classes.modal_body_input_container} ${classes.flex_column}`}>
                        <input type="text" required className={classes.modal_body_input} placeholder="ex. LTSA Manager" value={itemTypeCatalogName} onChange={e => setItemTypeCatalogName(e.target.value)} />
                    </div>
                </div>
                <div className={`${classes.modal_body_input_group} ${classes.flex_row} items-center`}>
                    <span>Role Type*</span>
                    <div className={`${classes.input_wrapper} ${classes.flex_column}`}>
                        <PlainDropDown
                                className={`${classes.select_container} !text-black !placeholder-black grow w-full`}
                                selectClass={`${classes.select_class} !text-black !placeholder-black`}
                                optionsClass={classes.select_options_class}
                                onSelection={(type) => {
                                    setRoleTypeId(type.id);
                                    setRoleType(type.name);
                                    setMpInternal(type.id === 1);
                                }}
                                value={roleTypeOptions.map((d) => d.id).includes(roleTypeId) ? roleTypeId : 0}
                                options={roleTypeOptions}
                                defaultText="Select Role Type"
                            />
                    </div>
                </div>
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                    <button className="darkBlue primaryBtn" type="submit" disabled={!itemTypeCatalogName.length || !roleType.length}>Add Role</button>
                    <Button className={`whiteBtn`} text="Cancel" onClick={props.onClose} />
                </div>
                <span className={classes.modal_footer_required_fields}>*Required Fields</span>
            </>
        );
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            props.onCreate(itemTypeCatalogName, mpInternal);
            props.onClose();
            setItemTypeCatalogName('');
        }}>
            <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Add New Role" width={width} onClose={props.onClose} footerClass={classes.modal_foote} />
        </form>
    );
}

export default AddNewRoleModal;