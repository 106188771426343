import headerClasses from './PowerReport.module.css';
import { useContext } from 'react';
import {Store} from '../../../Store';
import HeaderButtons from '../HeaderButtons';
import useWindowSize from '../../../customHooks/useWindowSize';

function GeneralDocumentsHeader() {
    const {selectedUserGroup} = useContext(Store);
    const {isDesktop} = useWindowSize();
    return (
        <div className={`flex flex-row gap-6 justify-between items-center ${isDesktop? '' : headerClasses.not_desktop}`}>
            <div className={`flex flex-col gap-6 `}>
                <div className={'font-bold text-2xl'}>
                    {selectedUserGroup.id > 0? `${selectedUserGroup.name}` : 'General'} Documents
                </div>
                <div className={headerClasses.group_header_text}>
                    Plant, Unit and Equipment Reports.
                </div>
                {!isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button}
                                              addButtonClass={headerClasses.add_button}
                                              addButtonText='Add Document'
                                              groupPermissionModule={selectedUserGroup.id > 0? 'UserGroupDocumentsGroups' : 'GeneralDocumentGroups'}
                                              permissionModule={selectedUserGroup.id > 0? 'UserGroupDocuments' : 'GeneralDocuments'} />}
            </div>
            {isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button}
                                         addButtonClass={headerClasses.add_button}
                                         addButtonText='Add Document'
                                         groupPermissionModule={selectedUserGroup.id > 0? 'UserGroupDocumentsGroups' : 'GeneralDocumentGroups'}
                                         permissionModule={selectedUserGroup.id > 0? 'UserGroupDocuments' : 'GeneralDocuments'} />}
        </div>
    );
}

export default GeneralDocumentsHeader;