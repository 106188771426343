// the other DragDropField in this directory has a bunch of code relating to avatar images..

import { useField, useFormikContext } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toBase64 } from "../../../../utils/FileUtils";
import Alert from "../../../Alert/Alert";
import classes from "./DragDropField.module.css";

export const maxFileSizeValidator = (file: File, maxFileSize?: number) => {
    // 2MB
    //if (!maxFileSize) maxFileSize = 2000000;
    // Temporarily increasing max file size to 100 mb so Velitchka can test backend uploads on larger files
    if (!maxFileSize || maxFileSize < 1000000000) maxFileSize = 1000000000;
    if (file.size > maxFileSize) {
        return {
            code: "file-too-large",
            message: `File Size is larger than ${formatFileSize(maxFileSize)}.`
        };
    }

    return null;
}

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
    if (bytes == 0) return '0 Bytes';
    const k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const DragDropFieldFormik = (props: DragDropFieldFormikProps) => {
    const [file, setFile] = useState<File>();
    const { values, setFieldValue, isSubmitting } = useFormikContext<any>();
    const [field, meta] = useField(props.name);
    const [preview, setPreview] = useState<string>();

    const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
            // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':[],
        },
        validator: maxFileSizeValidator,
        multiple: false,
        onDrop: (acceptedFiles: any) => {
            acceptedFiles.map((file: File) => {
                toBase64(file).then((base64Image: string) => {
                    props.onChange(file);
                    setPreview(base64Image);
                    props.displayName && setFieldValue(props.displayName, file.name);
                    setFieldValue(props.name, file);
                }).catch((err: Error) => {
                    console.error(err)
                    props.onChange(undefined);
                    setPreview(undefined);
                    setFieldValue(props.name, '');
                });
            })
        },
    });

    const FileRejectionItems = () => {
        if (fileRejections.length > 0) {
            return (
                <>
                    {fileRejections.map(({ file, errors }) => (
                        <div className="flex flex-col w-full" key={file.name}>
                            <Alert type="error" onClose={() => { }} dismissible={false}>
                                {`Upload Error: ${file.name} - ${formatFileSize(file.size)}`}
                            </Alert>

                            <ul>
                                {errors.map(e => (
                                    <li key={e.code} className="text-[#991B1B]">{e.message}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </>
            );
        }

        return <></>;
    };

    useEffect(() => {
        setPreview(undefined);
        if (props.attachment) {
            toBase64(props.attachment).then((base64Image: string) => {
                setPreview(base64Image);
            }).catch((err: Error) => {
                setPreview(undefined);
            });
        }
    }, [props.attachment]);

    let previewContainer: ReactElement = (
        <div style={{ textAlign: "center", color: "#3C5D69" }}>
            <p>Drag &amp; drop file here or</p>
            <strong>Browse for File</strong>
        </div>
    );
    if (isDragActive) {
        previewContainer = <p>Drop file here</p>;
    }
    if (preview) {
        if (preview.includes('data:image')) {
            previewContainer = <img src={preview} style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(preview || '')} />;
        }
        if (preview.includes('data:application')) {
            previewContainer = <iframe src={preview}></iframe>
        }
    } else {
        if (props.previewBase24?.includes('data:image')) {
            previewContainer = <img src={props.previewBase24} style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(preview || '')} />;
        }
        if (props.previewBase24?.includes('data:application')) {
            previewContainer = <iframe src={props.previewBase24}></iframe>
        }
    }

    return (
        <div className={classes.form_group}>
            <FileRejectionItems />
            <div {...getRootProps({
                className: `${(meta.touched && meta.error) || fileRejections.length ? `${classes.dropzone} ${classes.errorField} ${classes.error_text}` : `${classes.dropzone}`}`
            }
            )}>
                <input {...getInputProps()} />
                {previewContainer}
            </div>
        </div>
    );
};

interface DragDropFieldFormikProps {
    onChange: (file: File | undefined) => void;
    attachment: File | Blob | undefined;
    name: string;
    displayName: string;
    previewBase24?: string;
}
DragDropFieldFormik.defaultProps = {
    displayName: "",
    previewBase24: ""
}

export default DragDropFieldFormik;
