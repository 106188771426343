import axios, { AxiosError, AxiosResponse } from 'axios';
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { apiEmailOptOut, apiModulesUrl, apiRoleModulePermissionsUrl, apiRolesUrl, apiUGRolesUrl, apiURRoleModulePermissionsUrl, baseOrgRoleApiUrl, reactivateUgUserApiUrl, reactivateUserApiUrl, ugUserApiUrl, userApiUrl } from '../endpoints/endpoints';
import { handleAxiosResponse } from '../utils/handleAxiosResponse';
import { CPApiResponse, Module, PlantUserGroup, Role, RoleModulePermission, User, UserRole, UserRoleAssignments } from '../utils/interface';



declare type GetUserParameters = ['user', {userId: number}];
const getUser = async ({queryKey}: QueryFunctionContext<GetUserParameters>): Promise<User> => {
    const [_key, {userId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<User>> = await axios(`${ugUserApiUrl}/${userId}`);
    const user = handleAxiosResponse(response);
    return user;
};
export const useGetUser = (userId: number, options: Omit<UseQueryOptions<unknown, unknown, User, GetUserParameters>, "queryKey" | "queryFn"> = {}) => {
    return useQuery<unknown, unknown, User, GetUserParameters>(['user', {userId}], getUser, {
        enabled: userId > 0,
        ...options
    });
};

const updateUser = async (user: User): Promise<User> => {
    const response: AxiosResponse<CPApiResponse<User>> = await axios(`${ugUserApiUrl}/${user.id}`, {
        method: 'PUT',
        data: user
    });
    return handleAxiosResponse(response);
};
export const useUpdateUser = (options: Omit<UseMutationOptions<User, AxiosError<string>, User>, 'queryKey' | 'queryFn'> = {}) => {
    return useMutation(updateUser, options);
};

declare type GetAllUsersParameters = ['users'];
const getAllUsers = async (): Promise<User[]> => {
    const response: AxiosResponse<CPApiResponse<User[]>> = await axios(`${userApiUrl}/GetList`);
    return handleAxiosResponse(response);
};
export const useGetAllUsers = (options: Omit<UseQueryOptions<User[], unknown, User[], GetAllUsersParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<User[], unknown, User[], GetAllUsersParameters>(['users'], getAllUsers, options);
}

declare type SearchUserByEmailParameters = ['users', {email: string}];
const searchUserByEmail = async ({queryKey}: QueryFunctionContext<SearchUserByEmailParameters>): Promise<User> => {
    const [_key, {email}] = queryKey;
    const response: AxiosResponse<CPApiResponse<User>> = await axios(`${ugUserApiUrl}/${email}`);
    return handleAxiosResponse(response);
}
export const useSearchUserByEmail = (email: string, options: Omit<UseQueryOptions<User, {message: string}, User, SearchUserByEmailParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<User, {message: string}, User, SearchUserByEmailParameters>(['users', {email}], searchUserByEmail, {
        enabled: false,
        ...options
    });
};

export const createUser: MutationFunction<User, User> = async (data: User): Promise<User> => {
    const response: AxiosResponse<CPApiResponse<User>> = await axios(ugUserApiUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};


declare type GetRolesParameters = ['roles'];
const getRoles = async (): Promise<Role[]> => {
    const response: AxiosResponse<CPApiResponse<Role[]>> = await axios(`${apiUGRolesUrl}/List`);
    return handleAxiosResponse(response);
};

export const useGetRoles = (options: Omit<UseQueryOptions<unknown, unknown, Role[], GetRolesParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, Role[], GetRolesParameters>(['roles'], getRoles, options);
};



declare type CreateRoleParameters = {
    name: string;
    mpInternal: boolean;
    description?: string;
};
const createRole: MutationFunction<Role, CreateRoleParameters> = async (data: CreateRoleParameters): Promise<Role> => {
    const response: AxiosResponse<CPApiResponse<Role>> = await axios(apiUGRolesUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};
export const useCreateRole = (options: Omit<MutationOptions<Role, unknown, CreateRoleParameters>, 'mutationFn'> = {}) => useMutation(createRole, options);

const updateRole: MutationFunction<Role, Role> = async (data: Role): Promise<Role> => {
    const response: AxiosResponse<CPApiResponse<Role>> = await axios(`${apiUGRolesUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};
export const useUpdateRole = (options: Omit<MutationOptions<Role, unknown, Role>, 'mutationFn'> = {}) => useMutation(updateRole, options);

const deleteRole: MutationFunction<boolean, number> = async (id: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiUGRolesUrl}/${id}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};
export const useDeleteRole = (options: Omit<MutationOptions<boolean, unknown, number>, 'mutationFn'> = {}) => useMutation(deleteRole, options);

const reorderRoles: MutationFunction<boolean, Array<{id: number, displayOrder: number}>> = async (data: Array<{id: number, displayOrder: number}>): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiUGRolesUrl}/ReOrder`, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};
export const useReorderRoles = (options: Omit<MutationOptions<boolean, unknown, Array<{id: number, displayOrder: number}>>, 'mutationFn'> = {}) => useMutation(reorderRoles, options);

declare type GetModulesParameters = ['modules'];
const getModules = async (): Promise<Module[]> => {
    const response: AxiosResponse<CPApiResponse<Module[]>> = await axios(`${apiModulesUrl}/GetAll`);
    return handleAxiosResponse(response);
};

export const useGetModules = (options: Omit<UseQueryOptions<unknown, unknown, Module[], GetModulesParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, Module[], GetModulesParameters>(['modules'], getModules, options);
};

declare type GetRoleModulePermissionsParameters = ['roleModulePermissions', {roleId: number}];
const getRoleModulePermissions = async ({queryKey}: QueryFunctionContext<GetRoleModulePermissionsParameters>): Promise<RoleModulePermission> => {
    const [_key, {roleId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<RoleModulePermission>> = await axios(`${apiURRoleModulePermissionsUrl}/GetAllByRoleId?RoleId=${roleId}`);
    return handleAxiosResponse(response);
};
export const useGetRoleModulePermissions = (roleId: number, options: Omit<UseQueryOptions<RoleModulePermission, {message: string}, RoleModulePermission, GetRoleModulePermissionsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<RoleModulePermission, {message: string}, RoleModulePermission, GetRoleModulePermissionsParameters>(['roleModulePermissions', {roleId}], getRoleModulePermissions, options);
};

export const updateRoleModulePermissions: MutationFunction<RoleModulePermission, RoleModulePermission> = async (data: RoleModulePermission): Promise<RoleModulePermission> => {
    const response: AxiosResponse<CPApiResponse<RoleModulePermission>> = await axios(`${apiURRoleModulePermissionsUrl}/UpdateAllByRole`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};



declare type GetUserRoleAssignmentParameters = ['user.roles', {userId: number}];
const getUserRoleAssignments = async ({queryKey}: QueryFunctionContext<GetUserRoleAssignmentParameters>): Promise<UserRoleAssignments> => {
    const [_key, {userId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<UserRoleAssignments>> = await axios(`${userApiUrl}/GetUserRoleAssignments/${userId}`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetUserRoleAssignments = (userId: number, options: Omit<UseQueryOptions<unknown, unknown, UserRoleAssignments, GetUserRoleAssignmentParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, UserRoleAssignments, GetUserRoleAssignmentParameters>(['user.roles', {userId}], getUserRoleAssignments, options);
}

export interface UserCrudMethods<T> {
    reactivate: (userId: number) => Promise<T>;
    delete: (userId: number) => Promise<T>;
}

export const UsersApi: UserCrudMethods<
    boolean
> = {
    delete: async (userId: number): Promise<boolean> => {
	    const response = await axios({
		    url: `${ugUserApiUrl}/${userId}`,
			method: "DELETE",
		});
		return response.data;
	},
    reactivate: async (userId: number): Promise<boolean> => {
	    const response = await axios({
		    url: `${reactivateUgUserApiUrl}/${userId}`,
			method: "PUT",
		});
		return handleAxiosResponse(response);
	},
};

export const useDelete_User = () => {
    return useMutation(UsersApi.delete);
};

export const useReactivate_User = () => {
    return useMutation(UsersApi.reactivate);
};

declare type UpdateUserOrganizationRolesParameters = {
    userId: number,
    roles: UserRole[]
};
export const updateUserOrganizationRoles: MutationFunction<UserRoleAssignments, UpdateUserOrganizationRolesParameters> = async ({userId, roles}: UpdateUserOrganizationRolesParameters) => {
    const response: AxiosResponse<CPApiResponse<UserRoleAssignments>> = await axios(`${userApiUrl}/AddUpdateUserBaseOrgRoles/${userId}`, {
        method: 'PUT',
        data: roles
    });
    return handleAxiosResponse(response);
};

declare type RemoveUserAssignmentParameters = {
    baseOrgRoleId: number,
};
export const removeUserAssignment: MutationFunction<boolean, RemoveUserAssignmentParameters> = async ({baseOrgRoleId}: RemoveUserAssignmentParameters) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${baseOrgRoleApiUrl}/${baseOrgRoleId}`, {
        method: 'DELETE',
        
    });
    return handleAxiosResponse(response);
};

// /GetPlantUserGroups

declare type GetPlantUserGroupsParameters = ['plant.userGroups', {userId: number}];
const getPlantUserGroups = async ({queryKey}: QueryFunctionContext<GetPlantUserGroupsParameters>): Promise<PlantUserGroup[]> => {
    const [_key, {userId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<PlantUserGroup[]>> = await axios(`${ugUserApiUrl}/GetPlantUserGroups/${userId}`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetPlantUserGroups = (userId: number, options: Omit<UseQueryOptions<unknown, unknown, PlantUserGroup[], GetPlantUserGroupsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, PlantUserGroup[], GetPlantUserGroupsParameters>(['plant.userGroups', {userId}], getPlantUserGroups, options);
};

export const emailOptOut: MutationFunction<boolean> = async () => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiEmailOptOut}/OptOut`, {
        method: 'POST',
        
    });
    return handleAxiosResponse(response);
};

const getIsOptedOut = async (): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiEmailOptOut}/CheckOptedOut`);
    return handleAxiosResponse(response);
};

export const useGetIsOptedOut = (options: Omit<UseQueryOptions<boolean>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['emailoptout'], getIsOptedOut, options);
}