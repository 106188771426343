import ReactDOM from 'react-dom'



const Portal = (props:portalProps) => {
  return ReactDOM.createPortal(props.children,document.body);
}

interface portalProps{
  children: React.ReactNode;

}

export default Portal