import { useContext } from "react";
import { Store } from "../../Store";
import { PortalContact } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";


export const DeleteContactConfirm = ({contact, onClose, onDelete}: {contact: PortalContact, onClose: () => void, onDelete: (contact: PortalContact) => void}) => {
    const {selectedUserGroup} = useContext(Store);

    return (
        <EmptyModal body={(
                <span className="self-center">Are you sure you want to delete the selected contact from the {selectedUserGroup.name} Users' Group? This cannot be undone.</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 gap-4">
                    <Button className="redDelete" text="Yes, Delete" onClick={() => onDelete(contact)} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            )}
            heading="Delete Contact"
            onClose={onClose} shown={true} width={564} />
    );
};

export const DeletedContactConfirmation = ({contact, onClose}: {contact: PortalContact, onClose: () => void}) => {
    const {selectedUserGroup} = useContext(Store);

    return (
        <EmptyModal body={(
                <span className="self-center">The contact "{contact.firstName} {contact.lastName}" has been successfully deleted from {selectedUserGroup.name}.</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Contact Successfully Deleted"
            headingClass="pl-16 text-center"
            onClose={onClose} shown={true} width={564} />
    );
};