import { InterfaceFormDataPlantEquipment, deleteEquipmentDTO, deleteInterface, PlantEquipmentDTO, PlantEquipmentsDTO } from "../../utils/interface";
import { apiGetAllEquipmentsByUnitID, apiPlantEquipment, apiGetAllEquipmentsByPlantID } from "../../endpoints/endpoints";

import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

export interface EquipmentCrudMethods<T, TA, TD,DD, D, DR> {
  get: (id: number) => Promise<T>;
  getAllByUnitId: (id: number) => Promise<TA>;
  getAllByPlantId: (id: number) => Promise<TA>;
  create: (data: TD) => Promise<DD>;
  update: (data: TD) => Promise<DD>;
  delete: (data: D) => Promise<DR>;
}

export const EquipmentsApi: EquipmentCrudMethods<
  AxiosResponse<PlantEquipmentDTO>,
  AxiosResponse<PlantEquipmentsDTO>,
  InterfaceFormDataPlantEquipment,
  PlantEquipmentDTO,
  deleteEquipmentDTO,
  AxiosResponse<deleteInterface>
> = {
  get: async (unitID: number): Promise<AxiosResponse<PlantEquipmentDTO>> => {
    const response = await axios({
      url: `${apiPlantEquipment}/${unitID}`,
      method: "GET",
    });
    return response.data;
  },
  getAllByUnitId: async (unitID: number): Promise<AxiosResponse<PlantEquipmentsDTO>> => {
    const response = await axios({
      url: `${apiGetAllEquipmentsByUnitID}`,
      method: "GET",
      params: {
        UnitID: unitID,
      },
      headers: {
        baseorg: 0
      }
    });
    return response.data;
  },
  getAllByPlantId: async (plantId:number): Promise<AxiosResponse<PlantEquipmentsDTO>> => {
    const response = await axios({
      url: `${apiGetAllEquipmentsByPlantID}`,
      method: "GET",
      params: {
        PlantId: plantId,
      },
    });
    return response.data;
  },
  create: async (data: InterfaceFormDataPlantEquipment): Promise<PlantEquipmentDTO> => {
    const response = await axios({
      url: `${apiPlantEquipment}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    });
    return response.data;
  },
  update: async (data: InterfaceFormDataPlantEquipment): Promise<PlantEquipmentDTO> => {
    const response = await axios({
      url: `${apiPlantEquipment}/${data.plantEquipmentId}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },     
      data: data,
    });
    return response.data;
  },
  delete: async (data: deleteEquipmentDTO): Promise<AxiosResponse<deleteInterface>> => {
    const response = await axios({
      url: `${apiPlantEquipment}/${data.plantEquipmentId}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {       
        DeletionReason: data.deletionReason,
      },
    });
    return response.data;
  },
};





export const useGet_PlantEquipment = (id: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.plantEquipment, id], () => EquipmentsApi.get(id), {
    enabled: !!id,
    retry: 0,
    onSuccess,
    onError,
  });
};

export const useGetAllByUnitID_PlantEquipments = (unitID: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.plantEquipments, unitID], () => EquipmentsApi.getAllByUnitId(unitID), {
    enabled: !!unitID,
    onSuccess,
    onError,
    
  });
};
export const useGetAllByPlantID_PlantEquipments = (plantId: number, onSuccess: any, onError: (err: AxiosError<any>) => void ) => {
  return useQuery([queryKeys.plantEquipments, plantId], () => EquipmentsApi.getAllByPlantId(plantId), {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};

export const useCreate_PlantEquipment = () => {
  return useMutation(EquipmentsApi.create);
};

export const useUpdate_PlantEquipment = () => {
  return useMutation(EquipmentsApi.update);
};

export const useDelete_PlantEquipment = () => {
  return useMutation(EquipmentsApi.delete);
};