import { FunctionComponent, useContext } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import classes from "./Reports.module.css";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { DiscussionModuleInterface } from "./DiscussionsContainer";
import { DiscussionInterface } from "../../utils/interface";

interface Props {
  discussionModule: DiscussionModuleInterface;
  discussion: DiscussionInterface;
  onClose: () => void;
  confirm: () => void;
}

const DeleteDiscussionModal: FunctionComponent<Props> = ({ discussionModule, discussion, onClose, confirm }) => {
  const { selectedPlant, setIsModal } = useContext(Store);
  const close = () => {
    setIsModal && setIsModal!({ ...CloseAllModal });
  };

  return (
    <ModalGlobal
      modaltitle={`Delete Discussion?`}
      isModalHeader={true}
      modalClose={() => {
        close();
        onClose();
      }}
    >
      <div className={`${classes.delete_modal_body} ${classes.flex_column}`}>
        <p>
          Are you sure you want to delete {discussionModule.name} discussion from the {selectedPlant.name} Users’ Group? This cannot be undone.
        </p>

        <ul>
          <li key={discussion.id}>{discussion.title}</li>
        </ul>
        <div className={classes.flex_row}>
          <Button
            className={`redDelete`}
            disabled={false}
            text={"Yes, Delete"}
            onClick={() => {
              close();
              confirm();
            }}
          />
          <Button
            className={`whiteBtn`}
            disabled={false}
            text={"Cancel"}
            onClick={() => {
              close();
              onClose();
            }}
          />
        </div>
      </div>
    </ModalGlobal>
  );
};

export default DeleteDiscussionModal;
