
import React from 'react';
import Link from '../../components/Link/Link';
import { useOutletContext } from 'react-router-dom';
import { OutletInterface } from './ThreadContainer';

interface ThreadListProps {}

function ThreadList(props: ThreadListProps) {
    const {messages} = useOutletContext<OutletInterface>();

    // show list of issue reports in a better way than this....
    return <ul>
        {messages.map(message => (
            <li key={message.id}>
                <Link to={`${message.id}`}>{message.title}</Link>
            </li>
        ))}
    </ul>
}

export default ThreadList;