import React from "react";
import dayjs, { Dayjs } from "dayjs";
import ImageImports from "../../../../../utils/ImageImports";
import { changeDateMonth } from "../utils";
import "./DatePickerSelector.css";
import clsx from "clsx";
const { Arrow } = ImageImports;

export interface IDatePickerSelectorProps {
    shownDate: Dayjs;
    setShownDate: React.Dispatch<React.SetStateAction<Dayjs>>;
}

export const DatePickerSelector: React.FC<IDatePickerSelectorProps> = ({
    shownDate,
    setShownDate
}) => {
    const handleIconClick = (isNextMonth: boolean) => {
        return () => {
            setShownDate(changeDateMonth(shownDate, isNextMonth));
        };
    };

    return (
        <div className={"DatePickerSelector"}>
            <div
                className={clsx(
                    "DatePickerSelector__icon",
                    "DatePickerSelector__iconLeft"
                )}
                onClick={handleIconClick(false)}
            >
                <img src={Arrow} alt="Previous Month" />
            </div>

            <div className={"DatePickerSelector__date"}>
                {dayjs(shownDate).format("MMMM YYYY")}
            </div>

            <div
                className={clsx(
                    "DatePickerSelector__icon",
                    "DatePickerSelector__iconRight"
                )}
                onClick={handleIconClick(true)}
            >
                <img src={Arrow} alt="Next Month" />
            </div>
        </div>
    );
};
