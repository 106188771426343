import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { MutationFunction, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { apiLinkUrl } from "../endpoints/endpoints";
import { Store } from "../Store";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, Link } from "../utils/interface";


declare type GetAllLinksParameters = ['links', {selectedUserGroupId: number}];
const getAllLinks = async (): Promise<Link[]> => {
    const response: AxiosResponse<CPApiResponse<Link[]>> = await axios(`${apiLinkUrl}/List`);
    const user = handleAxiosResponse(response);
    return user;
};
export const useGetAllLinks = (options: Omit<UseQueryOptions<Link[], unknown, Link[], GetAllLinksParameters>, "queryKey" | "queryFn"> = {}) => {
    const {selectedUserGroup} = useContext(Store);
    return useQuery<Link[], unknown, Link[], GetAllLinksParameters>(['links', {selectedUserGroupId: selectedUserGroup.id}], getAllLinks, options);
};

const createLink: MutationFunction<Link, Link> = async (link: Link): Promise<Link> => {
    const response: AxiosResponse<CPApiResponse<Link>> = await axios(apiLinkUrl, {
        method: 'POST',
        data: {
            title: link.title,
            url: link.url,
            displayOrder: link.displayOrder,
            userGroupId: link.userGroupId > 0? link.userGroupId : null
        }
    });
    return handleAxiosResponse(response);
};

export const useCreateLink = (options: Omit<UseMutationOptions<Link, unknown, Link>, 'mutationFn'> = {}) => {
    return useMutation(createLink, options)
};

const updateLink: MutationFunction<Link, Link> = async (data: Link): Promise<Link> => {
    const response: AxiosResponse<CPApiResponse<Link>> = await axios(`${apiLinkUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};

export const useUpdateLink = (options: Omit<UseMutationOptions<Link, unknown, Link>, 'mutationFn'> = {}) => {
    return useMutation(updateLink, options)
};


const deleteLink: MutationFunction<boolean, number> = async (id: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiLinkUrl}/${id}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};

export const useDeleteLink = (options: Omit<UseMutationOptions<boolean, unknown, number>, 'mutationFn'> = {}) => {
    return useMutation(deleteLink, options)
};

