import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import { MainContent } from "../MainContent/MainContent";
import { ManageUser } from "./ManageUser";

export const ManageUserContainer = () => {
    usePageRequiresInternalAdmin();
    const {userId} = useParams();

    const nUserId = useMemo(() => {
        return +(userId || 0);
    }, [userId]);

    return <MainContent breadCrumbs={['Administration', 'User Management']} title="User Management">
        <ManageUser userId={nUserId} />
    </MainContent>
}