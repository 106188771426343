
import React, {ReactElement, useContext} from 'react';
import classes from "./ManageRoles.module.css";
import { Role } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';
import { Store } from '../../Store';
import Button from '../Button/Button';
import EmptyModal from '../Modals/EmptyModal';

interface NewRoleConfirmationModalProps {
    shown: boolean;
    role: Role;
    onClose: () => void;
    addNewRole: () => void;
}

function NewRoleConfirmationModal({shown, role, onClose, addNewRole}: NewRoleConfirmationModalProps) {
    const {isMobile} = useWindowSize();
    const {selectedPlant} = useContext(Store);
    
    let width: number = 564;
    if (isMobile) {
        width = 300;
    }
    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (shown) {
        modalBody = (
            <>
                <span className={classes.modal_body_text}>"The role {role.name}" was successfully added</span>
                <span className={classes.modal_body_text}>Would you like to add another role to the page?</span>
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                    <Button className="darkBlue" text="Add New Role" onClick={addNewRole} />
                    <Button className="whiteBtn" onClick={onClose} text="Done" />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={shown} body={modalBody} footer={modalFooter} heading="Role Successfully Added" onClose={onClose} width={width} footerClass={classes.modal_footer} />
    );
}

export default NewRoleConfirmationModal;