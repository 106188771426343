import { Report, UserColumnDefinition, User } from "../../utils/interface";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { MouseEventHandler, useContext, useId, useState } from "react";
import classes from "./list.module.css";
import ImageImports from "../../utils/ImageImports";
import { Store } from "../../Store";

interface ListRowTableProps {
  data: User;
  columnDefinitions: UserColumnDefinition[];
  tooltipActions: tooltipActionItem[];
  isChecked: boolean;
  onRowChecked: MouseEventHandler;
  unread: boolean;
}

function ListRowTable({ data, columnDefinitions, tooltipActions, isChecked, onRowChecked, unread }: ListRowTableProps) {
  const { tooltip: tooltipImage } = ImageImports;
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  return (
    <tr className={unread ? classes.unread : ""}>      
      {columnDefinitions.map((columnDefinition) => (
        <td key={columnDefinition.key} className={`${classes.data_point} ${columnDefinition.className}`} style={columnDefinition.style}>
          {columnDefinition.component ? (
            <columnDefinition.component report={data} />
          ) : (
            <>{data[columnDefinition.key]}</>
          )}
        </td>
      ))}      
    </tr>
  );
}

export default ListRowTable;
