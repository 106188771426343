import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import EditDeleteRoleSuccessModal from "../../components/ManageRoles/EditDeleteRoleSuccessModal";
import { ManageRoles } from "../../components/ManageRoles/ManageRoles";
import { useCreateRole, useDeleteRole, useGetRoles, useReorderRoles, useUpdateRole } from "../../customHooks/UserHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import { Role } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";

type ModalType = "edit" | "delete" | "";
export const ManageRolesContainer = () => {
    usePageRequiresInternalAdmin();
    const [roleData, setRoleData] = useState<Role[]>([]);
    const [showEditDeleteRoleSuccessModal, setShowEditDeleteRoleSuccessModal] = useState<Role|null>(null);
    const [successModalType, setSuccessModalType] = useState<ModalType>('');
    const setErrorData = useOnError();

    const {mutate: createRole} = useCreateRole({
        onSuccess: () => getRoles(),
        onError: (error: unknown) => {
            setErrorData(error);
            window.scrollTo(0, 0);
        },
    });
    const {mutate: reorderRoles} = useReorderRoles({
        onSuccess: () => getRoles(),
        onError: (error: unknown) => {
            setErrorData(error);
            window.scrollTo(0, 0);
        }
    });
    const {mutate: updateRole} = useUpdateRole();
    const {mutate: deleteRole} = useDeleteRole();

    const {data: roles, refetch: getRoles} = useGetRoles({
        onSuccess: roles => {
            setRoleData(roles.sort(function(a, b){return a.displayOrder - b.displayOrder}));
        }
    });

    const addNewRole = useCallback((name: string, mpInternal: boolean) => {
        createRole({name, mpInternal});
    }, []);

    const editRole = useCallback((role: Role) => {
        updateRole(role, {
            onSuccess: () => {
                getRoles();
                setShowEditDeleteRoleSuccessModal(role);
                setSuccessModalType('edit');
            },
            onError: (error: unknown) => {
                setErrorData(error);
                window.scrollTo(0, 0);
            },
        });
    }, []);

    type ReorderParams = {id: number, displayOrder: number};
    
    const onReorder = useCallback((params: ReorderParams[]) => {
        reorderRoles(params);
    }, []);

    const handleDeleteRole = useCallback((role: Role) => {
        deleteRole(role.id, {
            onSuccess: () => {
                getRoles();
                setShowEditDeleteRoleSuccessModal(role);
                setSuccessModalType('delete');
            },
            onError: (error: unknown) => {
                setErrorData(error);
                window.scrollTo(0, 0);
            },
        });
    }, []);

    const userManagementLink = useMemo(() => {
        return (
            <div className="flex flex-col">
                <div>A role cannot be deleted until all assignments within it have been re-assigned to another role.</div>
                <div>Individual user role assignments can be configured on the <Link to="/administration/user-management" className="no-underline text-[#00749E] hover:text-[#00749E] visited:text-[#00749E] hover:underline">User Management</Link> page.</div>
            </div>
        );
    }, []);

    return (
        <MainContent title="Role Configuration" breadCrumbs={['Administration', 'Role Configuration']}>
            {showEditDeleteRoleSuccessModal && (
                <EditDeleteRoleSuccessModal
                    shown={true}
                    role={showEditDeleteRoleSuccessModal}
                    type={successModalType}
                    onClose={() => setShowEditDeleteRoleSuccessModal(null)}
                />
            )}
            {roleData && <ManageRoles addButtonText="Add Role"
                                       backLabel="Back to Users' Group Portal Role Definitions"
                                       items={roleData}
                                       onAdd={addNewRole}
                                       onDelete={handleDeleteRole}
                                       onEdit={editRole}
                                       onReorder={onReorder}
                                       pageDescription={userManagementLink}
                                       pageSubHeading="Manage Roles (All Plants)" />
            }
        </MainContent>
    );
};