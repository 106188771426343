import Loader from "../Loader/Loader";
import EmptyModal from "./EmptyModal";

interface DownloadMultipleFilesModalProps {
    fileCount: number;
    type: 'Discussion' | 'Document'
}
type AttachmentTypeMap = {
    [Property in DownloadMultipleFilesModalProps["type"]]: string
};

const attachmentTypeMap: AttachmentTypeMap = {
    Discussion: 'Discussion Attachments',
    Document: 'Document Versions'
};

export const DownloadMultipleFilesModal = ({fileCount, type}: DownloadMultipleFilesModalProps) => {
    return (
        <EmptyModal shown={true}
                    body={<div className="w-full text-center">Attachments will be downloaded to your browser.</div>}
                    onClose={() => {}}
                    footer={<Loader containerStyle={{marginBottom: 0}} />}
                    footerClass="pt-0"
                    heading={`Preparing ${fileCount} ${attachmentTypeMap[type]}...`} />
    );
};