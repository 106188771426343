import React, { useContext } from "react";
import { Store } from "../../Store";
import { generatePath, Link, Outlet, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import RelatedLinks from "../../components/RelatedLinks/RelatedLinks";
import classes from "./SupportContainer.module.css";

function SupportContainer() {
  const storeContext = useContext(Store);
  const { supportModuleName } = useParams();
  const { setActiveLocation, showError } = storeContext;

  const handleRefresh = () => {
    window.location.reload();
  };

  const relatedData = {
    relatedTitle: "Related Information",
    relatedSections: [
      {
        title: "Engineering Support Requests",
        description: "Request technical information or send questions to Mitsubishi Power Americas on technical topics. Responses will be documented here along with the requests.",
        buttonLink: "View ESRs",
        route: generatePath('/support/:supportModuleName', {supportModuleName:"Engineering Support Requests"})
      },
      {
        title: "User Group Documents",
        description: "Users' Group Portal has a list of documents for customers with Mitsubishi Power Equipment.",
        buttonLink: "View User Group Documents",
        route: generatePath('/technical-documentation/:moduleName', {moduleName: 'User Group Documents'})
      },
      {
        title: "Issue Reports",
        description: "This page contains the reports issued by the Mitsubishi Power TOMONI HUB.",
        buttonLink: "View Issue Reports",
        route: "/issue-reports",
      }
    ],
  };

  return (
    <div className="column flex-grow-1">
      <div className="content">
        <div className="path breadcrumb">
          <Link to={"/"} className="link_text">
            Home
          </Link>{" "}
          / Support / {supportModuleName}
        </div>
        <div className="login-header">{supportModuleName}</div>
        {showError.isError && (
          <ErrorCard
            ErrorMessage={showError.title}
            onRefresh={handleRefresh}
            ErrorType={showError.ErrorType}
          />
        )}
		<div className={classes.card}>
			<Outlet />
		</div>
      </div>
      <RelatedLinks data={relatedData} />
      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
}

export default SupportContainer;
