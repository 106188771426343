import React, { FunctionComponent, ReactElement, useContext, useId, useState } from "react";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import ellipsis from "../../assets/3_dots_horizontal.svg";
import blue_tick from "../../assets/blue_tick.svg";
import copy_link from "../../assets/copy_link.svg";
import documentChatIcon from "../../assets/document-chat.svg";
import download from "../../assets/download.svg";
import trash from "../../assets/trash.svg";
import Accordion from "../../components/Accordian/Accordion";
import Button from "../../components/Button/Button";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { downloadMultipleFiles } from "../../customHooks/FileHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { apiUgFile } from "../../endpoints/endpoints";
import { QuoteRequestListData } from "../../utils/interface";
import classes from "./QuoteRequests.module.css";
import { QuoteRequestApi } from "./QuoteRequestsHttpServices";

const QuoteRequestItem: FunctionComponent<{
	QuoteRequest: QuoteRequestListData;
	index: number;
	refetchQuoteRequest: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<unknown, unknown>>;
}> = ({ QuoteRequest, index, refetchQuoteRequest }) => {
	const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
	const [modal, setModal] = useState<ReactElement>();

	const navigate = useNavigate();

	const { isDesktop, isTablet } = useWindowSize();

	const { selectedPlant } = useContext(Store);

	const tooltipId = useId().replaceAll(":", "_");;

	const tooltipActions: tooltipActionItem[] = [
		{
			text: "Download",
			img: download,
			onClick: (e, QuoteRequest) => {
				downloadMultipleFiles([QuoteRequest.globalId]);
			},
		},
		{
			text: "Delete",
			img: trash,
			onClick: async (e, QuoteRequest) => {
				if (QuoteRequest) {
					setModal(
						<ModalGlobal modalSize="sm" modaltitle={`Delete Documents`} isModalHeader={true} modalClose={() => setModal(undefined)}>
							<div style={{ textAlign: "justify" }}>
								<p>
									{`Are you sure you want to delete issue report ${QuoteRequest.id} from ${selectedPlant.name}? This will also delete the attached discussion board. This cannot be undone.`}
									<br />
									<br />
									{`Please note: deleting this issue report from Customer Portal will not delete it from the TOMONI Dashboard.`}
								</p>
								<p>
									<div style={{ color: "#666666", marginTop: 20 }}>
										{QuoteRequest.globalId}&nbsp;&nbsp;&nbsp;{QuoteRequest.displayFileName}
									</div>
								</p>
								<div style={{ marginTop: 40 }} className={classes.flex_row}>
									<Button
										className={`redDelete`}
										disabled={false}
										text={"Yes, Delete"}
										onClick={async () => {
											const { data: success } = await QuoteRequestApi.delete(QuoteRequest.globalId);
											if (success) {
												refetchQuoteRequest();
												setModal(
													<ModalGlobal modaltitle={`Document Successfully Deleted`} isModalHeader={true} modalClose={() => setModal(undefined)} modalSize="sm">
														<div>
															<p>
																{`You successfully deleted issue report ${QuoteRequest.id} (and its attached discussion board) from ${selectedPlant.name}. This did not delete the issue report from the TOMONI Dashboard.`}
															</p>
															<div style={{ marginTop: 40 }}>
																<Button className={`darkBlue`} disabled={false} text={"Done"} onClick={() => setModal(undefined)} />
															</div>
														</div>
													</ModalGlobal>
												);
											}
										}}
									/>
									&nbsp;&nbsp;&nbsp;
									<Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => setModal(undefined)} />
								</div>
							</div>
						</ModalGlobal>
					);
				}
			},
		},
		{
			text: "Copy Link",
			img: copy_link,
			onClick: (e, QuoteRequest) => {
				if (QuoteRequest) {
					const link = `${apiUgFile}/DownloadFile/${QuoteRequest.globalId}`;
					setModal(
						<ModalGlobal modalSize="sm" modaltitle={`Copy Link to ${QuoteRequest.displayFileName}`} isModalHeader={true} modalClose={() => setModal(undefined)}>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<input type="text" className={`${classes.copy_link_textbox}`} disabled defaultValue={link} />
								&nbsp;
								<Button
									className={`darkBlue`}
									text="Copy Link"
									style={{ width: "50%" }}
									onClick={() => {
										navigator.clipboard.writeText(link);
										toast("Link successfully copied");
										setTimeout(() => setModal(undefined), 2000);
									}}
								/>
								{false && (
									<div style={{ fontSize: "14px", color: "#00749E", display: "flex", alignItems: "center", textAlign: "left", marginTop: "16px" }}>
										<img src={blue_tick} alt="" /> Link successfully copied
									</div>
								)}
							</div>
						</ModalGlobal>
					);
				}
			},
		},
	];

	// const tooltipActions = Array<tooltipActionItem>;

	const QuoteRequestRow = (props: any) => {
		const [isOpen, setIsOpen] = useState<boolean>(false); // props.index === 0
		return isDesktop ? (
			<div>{props.fields}</div>
		) : (
			<Accordion
				isDeleted={false}
				titleContent={props.title}
				isOpen={isOpen}
				headerStyle={{ paddingLeft: 24, fontSize: 14, fontWeight: 700 }}
				contentStyle={{}}
				toggleOpen={() => setIsOpen(!isOpen)}
			>
				<div className={`${classes.accordion} ${isTablet ? "isTablet" : ""}`}>{props.fields}</div>
			</Accordion>
		);
	};

	return (
		<>
			<QuoteRequestRow
				index={index}
				title={QuoteRequest.outageYear}
				fields={
					<>
						<div title="ID">
							<div>{QuoteRequest.quoteRequestId}</div>
						</div>
						<div title="Type">
							<div>{QuoteRequest.itemTypeId}</div>
						</div>
						<div title="Request Description">
							<div>{QuoteRequest.description}</div>
						</div>						
						<div title="Equipment">
							<div>{QuoteRequest.equipmentId}</div>
						</div>
						<div title="Requestor">
							<div>{QuoteRequest.customerContactId}</div>
						</div>
						<div title="Submitted On">
							<div>{new Date(QuoteRequest.outageYear).toLocaleDateString()}</div>
						</div>
						<div title="Date Quote Needed">
							<div>{new Date(QuoteRequest.quoteNeededDate).toLocaleDateString()}</div>
						</div>
						<div title="Priority">
							<div>{QuoteRequest.quotePriorityId}</div>
						</div>
						
						<div title="Status">
							<div>
								<div className={`${classes.table_icon}`}>
									{/* <img src={QuoteRequest.isOpen ? open_indicator : closed_indicator} alt="" /> */}
								</div>
								{QuoteRequest.outageYear}
							</div>
						</div>
						<div title="Details">
							
							<div onClick={(e) => setShowContextMenu(!showContextMenu)} style={{ position: "relative", color: "#3C5D69" }}>
								<img src={ellipsis} alt="" /> {!isDesktop ? <strong>More actions</strong> : <></>}
								<BulkActionTooltip shown={showContextMenu} setShown={setShowContextMenu} parentId={tooltipId} listItems={tooltipActions} data={QuoteRequest} />
							</div>
						</div>
					</>
				}
			/>
			{modal}
		</>
	);
};

export default QuoteRequestItem;
