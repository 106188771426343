import { RecordingLibrary } from "../../components/RecordingLibrary/RecordingLibrary";
import { usePageRequiresUserGroupSelection } from "../../customHooks/usePageRequiresUserGroupSelection";
import { MainContent } from "../MainContent/MainContent";

interface RecordingLibraryContainerProps {

}

export const RecordingLibraryContainer = ({}: RecordingLibraryContainerProps) => {
    usePageRequiresUserGroupSelection();
    return (
        <MainContent title="Recordings" breadCrumbs={['Recordings']}>
            <RecordingLibrary />
        </MainContent>
    );
};