import React, { CSSProperties, useContext, useEffect, useMemo, useRef } from "react";
import { Link } from 'react-router-dom';
// import Button from '../Button/Button';
import linkedin from "../../assets/linkedin-logo.svg";
import useWindowSize from "../../customHooks/useWindowSize";
import { Store } from "../../Store";
import { PrivacyPolicy, TermsOfUse } from "../../utils/globalVariables";
import "./Footer.css";

function Footer() {
  const {selectedUserGroup} = useContext(Store);

  const hasGroupSelected = useMemo(() => {
    return selectedUserGroup.id > 0;
  }, [selectedUserGroup]);

  return (
    <div className="card footer">
      <div className="column row-item gap-10">
        <div className="flex flex-col lg:flex-row !pb-0 gap-5 lg:gap-20">
          <div className={`flex flex-col gap-[6px] font-bold`}>
            <div className="text-fade">
              <strong className="">Users' Group Portal</strong>
            </div>
            <div className="">
              <Link to="/" className="text-white no-underline" >Home</Link>
            </div>
            {hasGroupSelected? (
              <>
                <div className="">
                  <Link to="/technical-documentation/General Documents" className="text-white no-underline" >Documents</Link>
                </div>
                {/* <div className="">
                  <Link to="/recordings" className="text-white no-underline" >Recordings</Link>
                </div> */}
                <div className="">
                  <Link to="/discussions/Discussions" className="text-white no-underline" >Discussions</Link>
                </div>
                {/* <div className="">
                  <Link to="/plant-information" className="text-white no-underline" >Plant Information</Link>
                </div> */}
              </>
            ) : (
              <div className="">
                <Link to="/technical-documentation/General Documents" className="text-white no-underline" >Documents</Link>
              </div>
            )}
          </div>
          <div className={`flex flex-col gap-[6px]`}>
            <div className="text-fade">
              <strong className="">Mitsubishi Power Links</strong>
            </div>
            <div className="">
              <a href="https://www.mhi.com/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Heavy Industries</a>
            </div>
            <div className="">
              <a href="https://power.mhi.com/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Power</a>
            </div>
            <div className="">
              <a href="https://power.mhi.com/group/aero/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Power Aero LLC</a>
            </div>
            <div className="">
              <a href="https://power.mhi.com/regions/emea/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Power Europe</a>
            </div>
            <div className="">
              <a href="https://power.mhi.com/regions/amer/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Power Americas</a>
            </div>
            <div className="">
              <a href="https://power.mhi.com/regions/apac/" target='_blank' className="text-white no-underline" rel="noreferrer" >Mitsubishi Power Asia Pacific</a>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row !pb-0 self-stretch justify-between">
          <div className="text-fade order-2 md:order-1">
            &copy; Mitsubishi Power All Rights Reserved
          </div>
          <div className="flex flex-col md:flex-row !pb-0 gap-4 order-1 md:order-2 mb-[32px] md:mb-[0px]">
              <span><a href={PrivacyPolicy} target='_blank' className="text-white no-underline" rel="noreferrer" >Privacy Policy</a></span>
              <span><a href={TermsOfUse} target='_blank' className="text-white no-underline" rel="noreferrer" >Terms of Use</a></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
