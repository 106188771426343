import { CSSProperties, useMemo } from "react";
import useWindowSize from "../../customHooks/useWindowSize";
import { Announcement } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { AnnouncementItem } from "./AnnouncementItem";

interface AnnouncementListProps {
    announcements: Announcement[];
    onEdit: (announcement: Announcement) => void;
}
export const AnnouncementList = ({announcements, onEdit}: AnnouncementListProps) => {
    const {isTablet, isDesktop} = useWindowSize();

    const sortFn = useMemo(() => {
        return getSortObjectsByKeyFn<Announcement>('displayOrder');
    }, []);

    const columnSpacing: number = 24;

    let tileStyles: CSSProperties = {
        width: '100%'
    };
    if (isTablet) {
        tileStyles.width = undefined;
        tileStyles.flexBasis = `calc((100% - ${columnSpacing}px) / 2)`;
    }
    if (isDesktop) {
        tileStyles.width = undefined;
        tileStyles.flexBasis = `calc((100% - ${columnSpacing * 2}px) / 3)`;
    }

    return <div className={`flex flex-row !pb-0 items-start gap-6 self-stretch flex-wrap`}>
        {announcements.sort(sortFn).map(announcement => (
            <div style={tileStyles} key={announcement.id}>
                <AnnouncementItem announcement={announcement} onEdit={onEdit} />
            </div>
        ))}
    </div>;
};