import { AxiosResponse } from "axios";
import { CPApiResponse, DataWithPermissions, IssueReport, ModulePermissionResponseInterface, Permissions } from "./interface";
import { pick } from "./pick";

export const handleAxiosResponse = <T extends any> (response: AxiosResponse<CPApiResponse<T>>): T => {
    if (response.status >= 400) {
        throw response.statusText;
    }
    if (response.data.success !== true) {
        throw response.data.message;
    }
    return response.data.data;
};

export const handleModulePermissionResponse = <T extends any> (response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<T>>>): DataWithPermissions<T> => {
    const {items: data, ...permissions} = handleAxiosResponse(response);
    return {data, permissions};
};

const combinePermissions = (p1: Permissions, p2: Permissions): Permissions => {
    return {
        ...p1,
        canAdd: p1.canAdd || p2.canAdd,
        canAdmin: p1.canAdmin || p2.canAdmin,
        canDelete: p1.canDelete || p2.canDelete,
        canEdit: p1.canEdit || p2.canEdit,
        canView: p1.canView || p2.canView
    };
};
export const handleModulePermissionResponseMulti = <T extends any> (response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<T>[]>>): DataWithPermissions<T> => {
    let permissions: Permissions = {
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    };
    let data: T;
    const baseOrgsPermissions = handleAxiosResponse(response);
    let baseOrgPermissions = baseOrgsPermissions.shift();
    if (baseOrgPermissions) {
        data = baseOrgPermissions.items;
        permissions = pick(baseOrgPermissions, ['baseOrg', 'canAdd', 'canAdmin', 'canDelete', 'canEdit', 'canView', 'orgGroup', 'role']);
        while ((baseOrgPermissions = baseOrgsPermissions.shift()) !== undefined) {
            permissions = combinePermissions(permissions, pick(baseOrgPermissions, ['baseOrg', 'canAdd', 'canAdmin', 'canDelete', 'canEdit', 'canView', 'orgGroup', 'role']));
        }
        return {data, permissions};
    } else {
        throw "Failed to find Permissions";
    }
};