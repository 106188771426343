import { useEffect, useState } from "react";
import Accordion from "../../components/Accordian/Accordion";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { useGetAllEmailTriggers, useUpdateEmailTriggers } from "../../customHooks/EmailManagementHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import { EmailTrigger } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";
import ModuleNotificationTriggers from "./ModuleSpecific/ModuleNotificationTriggers";

export const EmailAlertManagementContainer = () => {
    usePageRequiresInternalAdmin();
    const {isDesktop, isMobile} = useWindowSize();
    const { data: notificationTriggers, refetch: refreshNotificationTriggers } = useGetAllEmailTriggers();
    const { mutate: UpdateTriggers, isSuccess: updateTriggerSuccess, reset: resetUpdateTriggerMutation } = useUpdateEmailTriggers();
    const [ notificationTriggerForm, setNotificationTriggerForm] = useState<EmailTrigger[]>([]);
    const [openedPanelIndex, setOpenedPanelIndex] = useState<string|number>(-1);
    const [canSave, setCanSave] = useState<boolean>(true);
    const setErrorData = useOnError();

    useEffect(() => {
		setNotificationTriggerForm(notificationTriggers || []);
	}, [notificationTriggers]);

    useEffect(() => {
		const checkTriggerChanges = JSON.stringify(notificationTriggers) !== JSON.stringify(notificationTriggerForm);

		setCanSave(checkTriggerChanges);
	}, [notificationTriggerForm, notificationTriggers]);    

    const handleCancelChanges = () => {
		setNotificationTriggerForm(notificationTriggers || []);
	}

    const updateNotificationTriggers = async () => {
		if (notificationTriggerForm) {
            UpdateTriggers(notificationTriggerForm, {
                onSuccess: () => {
                    refreshNotificationTriggers();
                },
                onError: (error: unknown) => {
                    setErrorData(error);
                },
            });
		}
	};
    // Filter distinct notification groups for accordion label
    const notificationGroups = [...new Set(notificationTriggers && notificationTriggers.map(trigger => trigger.moduleName))];
    
    return <MainContent title="Email Alert Management" breadCrumbs={['Administration', 'Email Alert Management']}>
        <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            <div className="flex flex-row !pb-0 items-start gap-6 self-stretch justify-between">
                <div className="flex flex-col gap-2 lg:basis-2/3">
                    <span className="font-bold text-2xl">Email Alert Management</span>
                    <div className="flex flex-row !pb-0">
                        <span className="text-sm">Manage email alert settings for all Users’ Group Portal users.</span>
                    </div>
                </div>
            </div>
            <Alert dismissible={false} type="warning" onClose={() => {}}>Please note: any modifications made to email alerts below will be applied to all Users' Group Portal email alert notifications.</Alert>
            <hr className="m-0 text-[#B3B3B3]" />
            <div className={`flex gap-4 ${isDesktop? 'flex-row !pb-0' : 'flex-col'}`}>
                <div className={`flex flex-col ${isDesktop ? 'basis-48': ''}`}>
                    <div className={`h-10 no-underline text-sm font-bold flex flex-start items-center text-black border-solid border-0 border-l-4 border-[#567783] pl-3`}>
                        Email Alert Settings
                    </div>
                </div>
                
                <div className="flex flex-col gap-4 items-start grow">
                    <div className="flex flex-row gap-6 !pb-0 self-stretch items-center mb-6">
                        <div style={{ flexGrow: 1 }}>
                            <div className="text-xl">Discussion Email Alerts</div>
                            {updateTriggerSuccess && <Alert onClose={resetUpdateTriggerMutation} type="success">Email Alert Notifications have been successfully saved.</Alert>}
                        
                            {notificationGroups.map((module, moduleIndex)   => {
                                return (
                                    <div key={(module)} >
                                        <Accordion
                                            isDeleted={false}
                                            titleContent={`${module}`}
                                            isOpen={openedPanelIndex === (module)}
                                            toggleOpen={() => setOpenedPanelIndex(openedPanelIndex !== (module) ? module : -1)}
                                            headerStyle={{ borderRadius: 4, marginTop: 20, paddingLeft: 20, fontWeight: "bold" }}
                                            contentStyle={{}}
                                        >
                                            <ModuleNotificationTriggers
                                                moduleNotificationTriggers={notificationTriggerForm && notificationTriggerForm.filter((t) => t.moduleName === module) || []}
                                                notificationTriggers={notificationTriggerForm || []}
                                                setNotificationTriggers={setNotificationTriggerForm} 
                                            />
                                        </Accordion>	
                                    </div>
                                )})
                            }
                            
                            <hr />
                            <div className={`flex gap-2 ${isMobile? 'flex-col' : 'flex-row'}`}>
                                <Button className="primaryBtn darkBlue" text={"Save Changes"} disabled={!canSave} onClick={updateNotificationTriggers}></Button>
                                <Button className="primaryBtn whiteBtn" text={"Cancel"} disabled={!canSave} onClick={handleCancelChanges}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainContent>
};