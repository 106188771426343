import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateRoleModulePermissions, useGetRoleModulePermissions } from "../../customHooks/UserHttpServices";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import { ModulePermission, ModulePermissionType, Role } from "../../utils/interface";
import Accordion from "../Accordian/Accordion";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import { PropertiesOfType } from "../List/ItemList";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";

interface RoleConfigurationParameters {
    role: Role;
}

export const RoleConfiguration = ({role}: RoleConfigurationParameters) => {
    const [permissions, setPermissions] = useState<ModulePermission[]>([]);
    const [currentOpenModuleId, setCurrentOpenModuleId] = useState<number>(0);
    
    const {data: roleModulePermissions, refetch, isSuccess: isSuccessFetchPermissions} = useGetRoleModulePermissions(role.id, {
        onSuccess: (d) => {
            setPermissions(d?.items);
            setCurrentOpenModuleId(d?.items[0].parentModuleId || 0);
        }
    });

    const { mutate: updatePermissions, isLoading: isLoadingUpdatePermissions, isSuccess: isSuccessUpdatePermissions, reset: resetUpdatePermissionMutation } = useMutation(updateRoleModulePermissions, {
        onSuccess: () => {
            refetch();
            window.scrollTo(0, 0);
        }
    });

    const togglePermission = (itemIndex: number, permissionIndex: number, key: PropertiesOfType<ModulePermissionType, boolean>) => {
        const perms = [...permissions];
        perms[itemIndex].permissions[permissionIndex][key] = !perms[itemIndex].permissions[permissionIndex][key];
        setPermissions(perms);
    };

    const hasChanges = permissions !== roleModulePermissions?.items;

    const saveChanges = () => {
        if (roleModulePermissions) {
            updatePermissions({
                ...roleModulePermissions,
                items: permissions,
            });
        }
    };

    const resetPermissions = () => {
        refetch();
    };

    useEffect(() => {
        setCurrentOpenModuleId(0);
        resetUpdatePermissionMutation();
    }, [role]);
    usePageRequiresInternalAdmin();
    return (
        <div className="flex flex-col gap-4 items-stretch grow">
            {isSuccessUpdatePermissions && 
                <Alert dismissible={true} type="success" onClose={() => resetUpdatePermissionMutation()}>{`Users' group portal role definitions for role ${role.name} have been successfully updated.`}</Alert>
            }
            {isSuccessFetchPermissions && roleModulePermissions && roleModulePermissions.items.map((permission, itemIndex) => (
                <Accordion key={permission.parentModuleId}
                            toggleOpen={() => setCurrentOpenModuleId(permission.parentModuleId === currentOpenModuleId ? 0 : permission.parentModuleId)}
                            isOpen={permission.parentModuleId === currentOpenModuleId}
                            titleContent={permission.parentModuleName}
                            isDeleted={false}
                            contentStyle={{}}
                            headerStyle={{
                                paddingLeft: '10px',
                                fontWeight: 'bold',
                                borderRadius: '8px'
                            }}>
                    <table className="w-full pl-4 text-sm">
                        <thead>
                            <tr>
                                <th className="text-left w-auto sm:w-1/3 md:w-1/6">Sub-Module</th>
                                <th>Can Admin</th>
                                <th>Can Add</th>
                                <th>Can Edit</th>
                                <th>Can Delete</th>
                                <th>Can View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {permission.permissions.map((permission, permissionIndex) => (
                                <tr key={permissionIndex}>
                                    <td>{permission.moduleName}</td>
                                    <td><span className="flex flex-row !pb-0 justify-center"><Checkbox isChecked={permission.canAdmin} onChange={() => togglePermission(itemIndex, permissionIndex, 'canAdmin')} /></span></td>
                                    <td><span className="flex flex-row !pb-0 justify-center"><Checkbox isChecked={permission.canAdd} onChange={() => togglePermission(itemIndex, permissionIndex, 'canAdd')} /></span></td>
                                    <td><span className="flex flex-row !pb-0 justify-center"><Checkbox isChecked={permission.canEdit} onChange={() => togglePermission(itemIndex, permissionIndex, 'canEdit')} /></span></td>
                                    <td><span className="flex flex-row !pb-0 justify-center"><Checkbox isChecked={permission.canDelete} onChange={() => togglePermission(itemIndex, permissionIndex, 'canDelete')} /></span></td>
                                    <td><span className="flex flex-row !pb-0 justify-center"><Checkbox isChecked={permission.canView} onChange={() => togglePermission(itemIndex, permissionIndex, 'canView')} /></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Accordion>
            ))}
            <hr className="m-0 text-[#B3B3B3]" />
            <div className="flex flex-row !pb-0 gap-4">
                <Button className="darkBlue" disabled={!hasChanges || !roleModulePermissions} text="Save Changes" onClick={saveChanges} />
                <Button className="whiteBtn" text="Cancel" onClick={resetPermissions} />
            </div>
        </div>
    );
};
