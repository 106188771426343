import {
  deleteInterface,
  EquipmentAttachmentInitialValuesProps,
  IdObj,
  PlantEquipmentAttachmentDTO,
  PlantEquipmentAttachmentsDTO,
} from "../../utils/interface";
import { apiEquipmentAttachment, apiGetAllPlantEquipmentsAttachments, apiGetEquipmentAttachmentFile } from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

export interface EqAttachmentsCrudMethods<T, A, TD, OB, DR, R> {
  get: (id: number) => Promise<T>;
  getImage: (id: number) => Promise<Blob>;
  getAll: (id: number) => Promise<A>;
  create: (data: TD) => Promise<R>;
  update: (data: TD) => Promise<R>;
  delete: (IdObj: OB) => Promise<DR>;
  download: (file: File | Blob, downloadFileName: string, ext: string) => void;
}

export const EquipmentAttachmentsApi: EqAttachmentsCrudMethods<
  AxiosResponse<PlantEquipmentAttachmentDTO>,
  AxiosResponse<PlantEquipmentAttachmentsDTO>,
  EquipmentAttachmentInitialValuesProps,
  IdObj,
  AxiosResponse<deleteInterface>,
  PlantEquipmentAttachmentDTO
> = {
  get: async (attachmentID: number): Promise<AxiosResponse<PlantEquipmentAttachmentDTO>> => {
    const response = await axios({
      url: `${apiEquipmentAttachment}/${attachmentID}`,
      method: "GET",
    });
    return response.data;
  },
  getImage: async (attachmentID: number): Promise<Blob> => {
    const response = await axios({
      url: `${apiGetEquipmentAttachmentFile}/${attachmentID}`,
      method: "GET",
      params: {
        EquipmentAttachmentId: attachmentID,
      },
      responseType: "blob",
    });
    return response.data;
  },
  getAll: async (plantEquipmentId: number): Promise<AxiosResponse<PlantEquipmentAttachmentsDTO>> => {
    const response = await axios({
      url: `${apiGetAllPlantEquipmentsAttachments}`,
      method: "GET",
      params: {
        PlantEquipmentId: plantEquipmentId,
      },
    });
    return response.data;
  },
  create: async (data: EquipmentAttachmentInitialValuesProps): Promise<PlantEquipmentAttachmentDTO> => {
    const response = await axios({
      url: `${apiEquipmentAttachment}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  update: async (data: EquipmentAttachmentInitialValuesProps): Promise<PlantEquipmentAttachmentDTO> => {
    const response = await axios({
      url: `${apiEquipmentAttachment}/${data.EquipmentAttachmentId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  delete: async (IdObj: IdObj): Promise<AxiosResponse<deleteInterface>> => {
    const response = await axios({
      url: `${apiEquipmentAttachment}/${IdObj.attachmentid}`,
      method: "DELETE",
    });
    return response.data;
  },
  download: (file: File | Blob, downloadFileName: string, ext: string) => {
    //this will take a blob or a file type
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${downloadFileName}.${ext}`; //if API returns blob replace with filename from data
    document.body.append(a);
    a.click();
    a.remove();
  },
};

export const useGetAll_PlantEquipmentAttachment = (plantEquipmentId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.getAllPlantEquipmentsAttachments, plantEquipmentId], () => EquipmentAttachmentsApi.getAll(plantEquipmentId), {
    enabled: plantEquipmentId > 0,
    onSuccess,
    onError,
  });
};

export const useGet_PlantEquipmentAttachment = (attachmentID: number, isEdit: boolean, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.equipmentAttachment, attachmentID], () => EquipmentAttachmentsApi.get(attachmentID), {
    enabled: attachmentID > 0 && isEdit,
    onSuccess,
    onError,
  });
};

export const useGetImage_PlantEquipmentAttachment = (attachmentID: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.getEquipmentAttachmentFile, attachmentID], () => EquipmentAttachmentsApi.getImage(attachmentID), {
    enabled: !!attachmentID,
    cacheTime:0,
    staleTime:0,
    onSuccess,
    onError,
  });
};

export const useCreate_PlantEquipmentAttachment = () => {
  return useMutation(EquipmentAttachmentsApi.create);
};

export const useDelete_PlantEquipmentAttachment = () => {
  return useMutation(EquipmentAttachmentsApi.delete);
};

export const useUpdate_PlantEquipmentAttachment = () => {
  return useMutation(EquipmentAttachmentsApi.update);
};
