import { Link } from "../../utils/interface";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import ImageImports from "../../utils/ImageImports";
import { useCallback, useState } from "react";
import { AddEditLink, AddEditLinkConfirmation } from "./AddEditLink";
import { useQueryClient } from "react-query";
import EmptyModal from "../Modals/EmptyModal";
import Button from "../Button/Button";
import { useDeleteLink, useUpdateLink } from "../../customHooks/LinkHttpServices";

const { pencil, linkout } = ImageImports;


export const LinkDisplay = ({link}: {link: Link}) => {
    const [editLink, setEditLink] = useState<Link>();
    const [editedLink, setEditedLink] = useState<Link>();
    const [confirmDeleteLink, setConfirmDeleteLink] = useState<Link>();
    const [deletedLink, setDeletedLink] = useState<Link>();
    const queryClient = useQueryClient();
    const {mutate: updateLink} = useUpdateLink();
    const {mutate: deleteLinkApi} = useDeleteLink();

    const saveLink = useCallback((link: Link) => {
        updateLink(link, {onSuccess: (link: Link) => {
            setEditLink(undefined);
            setEditedLink(link);
            queryClient.invalidateQueries(['links']);
        }});
    }, []);

    const onDeleteLink = useCallback((link: Link) => {
        setEditLink(undefined);
        setConfirmDeleteLink(link);
    }, []);

    const deleteLink = useCallback((link: Link) => {
        // do the delete things
        // invalidate query for links...
        // queryClient.invalidateQueries('whatever the key is');
        deleteLinkApi(link.id, {
            onSuccess: () => {
                setConfirmDeleteLink(undefined);
                setDeletedLink(link);
            }
        });
    }, []);

    const closeDeletedLinkConfirmation = useCallback(() => {
        setDeletedLink(undefined);
        queryClient.invalidateQueries(['links']);
    }, []);

    return (
        <div className="flex flex-row !pb-0 justify-between self-stretch">
            {editLink && <AddEditLink link={editLink} onClose={() => setEditLink(undefined)} onSave={saveLink} onDelete={onDeleteLink} />}
            {editedLink && <AddEditLinkConfirmation link={editedLink} onAddAnother={() => {}} onClose={() => setEditedLink(undefined)} />}
            {confirmDeleteLink && <DeleteLink link={confirmDeleteLink} onClose={() => setConfirmDeleteLink(undefined)} onDelete={deleteLink} />}
            {deletedLink && <DeletedLink link={deletedLink} onClose={closeDeletedLinkConfirmation} />}
            <a className="flex flex-row !pb-0 items-center gap-2 no-underline text-black" href={link.url} target="_blank">
                <div className="bg-[#CDD9DD] flex justify-center items-center rounded-full h-6 w-6">
                    <img src={linkout} alt={link.title} className="h-4 w-4" />
                </div>
                <span className="font-bold text-sm">{link.title}</span>
            </a>
            <RequiresAdmin>
                <img src={pencil} alt="Edit" className="cursor-pointer" onClick={() => setEditLink(link)} />
            </RequiresAdmin>
        </div>
    );
};

const DeleteLink = ({link, onClose, onDelete}: {link: Link, onClose: () => void, onDelete: (link: Link) => void}) => {
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6">
                    <span>Are you sure you want to delete the "{link.title}" link from the Users' Group Portal home page? This cannot be undone.</span>
                </div>
            )}
            footer={(
                <div className="flex flex-row !pb-0 grow gap-4">
                    <Button className="redDelete" text="Yes, Delete" onClick={() => onDelete(link)} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            )}
            heading="Delete Link"
            onClose={onClose} shown={true} />
    );
};

const DeletedLink = ({link, onClose}: {link: Link, onClose: () => void}) => {
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6 text-center">
                    <span>The "{link.title}" link has been successfully deleted from the Users' Group Portal.</span>
                </div>
            )}
            footer={(
                <div className="flex flex-row !pb-0 grow gap-4 justify-center">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Link Successfully Deleted"
            headingClass="pl-16 text-center"
            onClose={onClose} shown={true} width={564} />
    );
};