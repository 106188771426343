import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import { RoleConfiguration } from "../../components/RoleConfiguration/RoleConfiguration";
import { useGetModules, useGetRoles } from "../../customHooks/UserHttpServices";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { Role, SelectOptionProps } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";

const {gear} = ImageImports;

export const RoleConfigurationContainer = ({isDefault}: {isDefault: boolean}) => {
    usePageRequiresInternalAdmin();
    const {isDesktop, isMobile} = useWindowSize();
    const [selectedRole, setSelectedRole] = useState<string|null>('');
    const {roleId} = useParams();
    const navigate = useNavigate();

    const buttonText = useMemo(() => {
        return <div className="text-sm flex flex-row !pb-0 items-center gap-[10px]">
            <img src={gear} alt="Manage Roles" className="h-6 w-6" style={{filter: 'var(--svgFilterWhite)'}} />
            <span>Manage Roles</span>
        </div>
    }, []);
    
    const {data: roles} = useGetRoles({
        onSuccess: (data) => {
            data.sort(function(a, b){return a.displayOrder - b.displayOrder});
            if (isDefault) {
                navigate(`./${data[0].id}`);
            }
        }
    });
    const {data: modules} = useGetModules({
        enabled: !isDefault
    });

    const dropdownOptions: SelectOptionProps[] = useMemo(() => {
        return (roles || []).map((s, i) => ({
            id: i,
            name: s.name as string
        }));
    }, [roles]);

    const role: Role|undefined = useMemo(() => {
        return (roles || []).find(d => d.id === Number(roleId));
    }, [roleId, roles]);

    const handleRoleSelection = useCallback((e: MouseEvent<HTMLDivElement>, option: SelectOptionProps) => {
        if (roles) {
            navigate(`/administration/role-configuration/${roles[option.id].id}`);
        }
    }, [roles]);

    useEffect(() => {
        setSelectedRole((roles || []).find(d => d.id === Number(roleId))?.name || '');
    }, [role]);
    
    return <MainContent title="Role Configuration" breadCrumbs={['Administration', 'Role Configuration']}>
        <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            <div className={`flex ${isMobile? 'flex-col' : 'flex-row !pb-0'} items-start gap-6 self-stretch justify-between`}>
                <div className="flex flex-col gap-2 lg:basis-2/3">
                    <span className="font-bold text-2xl">Users' Group Portal Role Definitions</span>
                    {!isMobile && <div className="flex flex-row !pb-0">
                        <span className="text-sm">Configure the permissions each role has for each Users' Group Portal site sub-module.</span>
                    </div>}
                </div>
                <Button text={buttonText} className="darkBlue" onClick={() => navigate('/administration/role-configuration/manage-roles')} />
            </div>
            <Alert dismissible={false} type="warning" onClose={() => {}}>Please note: any modifications made to roles below will be applied to all Users' Group Portal users assigned to that role.</Alert>
            <hr className="m-0 text-[#B3B3B3]" />
            <div className={`flex gap-4 ${isDesktop? 'flex-row !pb-0' : 'flex-col'}`}>
                {isDesktop? (
                    <div className="flex flex-col basis-48">
                        {(roles || []).map((role) => (
                            <NavLink key={role.id}
                                    to={`/administration/role-configuration/${role.id}`}
                                    className={({ isActive }) => 
                                        `h-10 no-underline text-sm font-bold flex flex-start items-center ${isActive ? 'text-black border-solid border-0 border-l-4 border-[#567783] pl-3' : 'text-[#999] pl-4'}`
                                    }>
                                {role.name}
                            </NavLink>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col">
                        Roles
                        <div className="borderDropdown">
                            <Dropdown style={{width:'100%'}}
                                    handleDropDownSelect={handleRoleSelection}
                                    selectedOption={selectedRole}
                                    setSelectedOption={setSelectedRole}
                                    options={dropdownOptions} 
                                    />
                        </div>
                    </div>
                )}
                {role && <RoleConfiguration role={role} />}
            </div>
        </div>
    </MainContent>
};