import { ReactNode, useContext, useMemo } from "react";
import { useGetUser } from "../../customHooks/UserHttpServices";
import { Store } from "../../Store";


export const RequiresAdmin = ({children}: {children: ReactNode}) => {
    const { header: { data: { user: { portalAdmin } } } } = useContext(Store);

    const isAdmin = useMemo(() => {
        return !!portalAdmin;
    }, [portalAdmin]);

    return (
        <>
            {isAdmin && children}
        </>
    );
};