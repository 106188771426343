
import { useCallback, useContext, useMemo, useState } from "react";
import { useCreateContact, useDeleteContact, useGetAllContacts, useUpdateContact } from "../../customHooks/PortalContactHttpServices";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import { PortalContact } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import Button from "../Button/Button";
import { AddEditContact, AddEditContactConfirmation } from "../Contacts/AddEditContact";
import { DeleteContactConfirm, DeletedContactConfirmation } from "../Contacts/DeleteContactConfirm";

const { pencil } = ImageImports;

export const Officers = () => {
    const {setToolTipContent, toggleTooltip, selectedUserGroup} = useContext(Store);
    const [addEditContact, setAddEditContact] = useState<PortalContact>();
    const [savedContact, setSavedContact] = useState<PortalContact>();
    const [deleteContactConfirm, setDeleteContactConfirm] = useState<PortalContact>();
    const [deletedContactConfirmation, setDeletedContactConfirmation] = useState<PortalContact>();
    const [tooltipContactId, setTooltipContactId] = useState<number>();

    const {data: contacts, refetch: refetchContacts} = useGetAllContacts();

    const {mutate: createContact} = useCreateContact();
    const {mutate: updateContact} = useUpdateContact();
    const {mutate: deleteContactApi} = useDeleteContact();

    const sortFn = getSortObjectsByKeyFn<PortalContact>('displayOrder');

    const defaultContact: PortalContact = useMemo(() => {
        return {
            id: 0,
            firstName: '',
            lastName: '',
            role: '',
            email: '',
            displayOrder: (Math.max(...[...(contacts?? []), {displayOrder: 0}].map(d => d.displayOrder)) + 1),
            company: '',
            jobTitle: '',
            secondaryPhone: '',
            primaryPhone: '',
            primaryPhoneTypeId: 2,
            primaryPhoneType: '',
            secondaryPhoneTypeId: 1,
            secondaryPhoneType: '',
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            created: '',
            createdBy: '',
            modified: '',
            modifiedBy: '',
            userGroupId: selectedUserGroup.id
        };
    }, [contacts, selectedUserGroup]);

    const openContact = (contact: PortalContact) => {
        toggleTooltip?.(true);
        setTooltipData(contact);
    }
    const setTooltipData = useCallback((contact: PortalContact) => {
        setTooltipContactId(contact.id);
        setToolTipContent?.(<ContactTooltipContent contact={contact} />);
    }, []);

    const saveCallback = useCallback((contact: PortalContact) => {
        setAddEditContact(undefined);
        setSavedContact(contact);
        refetchContacts();
        console.log(tooltipContactId, contact.id);
        if (tooltipContactId && tooltipContactId === contact.id) {
            setTooltipData(contact);
        }
    }, [tooltipContactId]);

    const saveContact = useCallback((contact: PortalContact) => {
        if (contact.id > 0) {
            updateContact(contact, {onSuccess: saveCallback});
        } else {
            createContact(contact, {onSuccess: () => saveCallback(contact)});
        }
    }, [tooltipContactId]);

    const deleteContact = useCallback((contact: PortalContact) => {
        // do the delete thing
        deleteContactApi(contact.id, {
            onSuccess: () => {
                setDeleteContactConfirm(undefined);
                setDeletedContactConfirmation(contact);
                refetchContacts();
            }
        });
    }, []);

    const confirmDeleteContact = useCallback((contact: PortalContact) => {
        setAddEditContact(undefined);
        setDeleteContactConfirm(contact);
    }, []);

    // setToolTipContent
    return (
        <div className={`flex flex-col items-start p-6 gap-6 bg-white shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] rounded-lg w-full ${contacts && contacts.length > 0? 'justify-between' : ''}`}>
            {addEditContact && <AddEditContact contact={addEditContact} onClose={() => setAddEditContact(undefined)} onDelete={confirmDeleteContact} onSave={saveContact} />}
            {savedContact && <AddEditContactConfirmation contact={savedContact} onClose={() => setSavedContact(undefined)} />}
            {deleteContactConfirm && <DeleteContactConfirm contact={deleteContactConfirm} onClose={() => setDeleteContactConfirm(undefined)} onDelete={deleteContact} />}
            {deletedContactConfirmation && <DeletedContactConfirmation contact={deletedContactConfirmation} onClose={() => setDeletedContactConfirmation(undefined)} />}
            <div className="flex flex-col gap-6 self-stretch">
                <span className="font-bold text-2xl">Group Officers</span>
                <div className="flex flex-col items-start self-stretch">
                    {/** Contact Information will be in a loop */}
                    {contacts && contacts.sort(sortFn).map(contact => (
                        <div key={contact.id} className="flex flex-col items-start pt-4 px-4 pb-0 gap-4 self-stretch">
                            <div className="flex flex-row !pb-0 items-start gap-4 self-stretch justify-between">
                                <span className="text-dark-blue font-bold text-sm cursor-pointer" onClick={() => openContact(contact)}>{contact.firstName} {contact.lastName}</span>
                                <RequiresAdmin>
                                    <img src={pencil} alt="Edit" className="cursor-pointer" onClick={() => setAddEditContact(contact)} />
                                </RequiresAdmin>
                            </div>
                            <div className="flex flex-col gap-1 self-stretch">
                                <div className="flex flex-row !pb-0 items-start gap-1 self-stretch">
                                    <span className="text-sm font-bold">Role:</span>
                                    <span className="text-sm">{contact.role}</span>
                                </div>
                                <div className="flex flex-row !pb-0 items-start gap-1 self-stretch">
                                    <span className="text-sm font-bold">Email:</span>
                                    <a href={`mailto:${contact.email}`} className="text-sm no-underline text-ocean">{contact.email}</a>
                                </div>
                            </div>
                            <hr className="m-0 text-[#B3B3B3]" />
                        </div>
                    ))}

                    {/** End Contact Information */}
                </div>
            </div>
            {/** button goes here */}
            <RequiresAdmin>
                <Button className="whiteBtn" text="Add Contact" onClick={() => setAddEditContact(defaultContact)} />
            </RequiresAdmin>
        </div>
    );
};

const ContactTooltipContent = ({contact}: {contact: PortalContact}) => {
    return (
        <div className="flex flex-col items-start gap-6 self-stretch">
            <span className="font-bold text-xl">{contact.firstName} {contact.lastName}</span>
            <ContactTooltipContentItem subHeading="Role" value={contact.role} />
            <ContactTooltipContentItem subHeading="Company" value={contact.company} />
            <ContactTooltipContentItem subHeading="Job Title" value={contact.jobTitle} />
            <ContactTooltipContentItem subHeading="E-mail" value={contact.email} />
            <ContactTooltipContentItem subHeading="Office" value={contact.secondaryPhone} />
            <ContactTooltipContentItem subHeading="Mobile" value={contact.primaryPhone} />
        </div>
    );
};

const ContactTooltipContentItem = <T extends keyof PortalContact>({subHeading, value}: {subHeading: string, value: PortalContact[T]}) => (
    <div className="flex flex-col items-start gap-2">
        <span className="font-bold text-sm">{subHeading}:</span>
        <span className="text-sm">{value}</span>
    </div>
);