import { ReactElement } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';

import classes from './ConfirmUserStatusChangeModal.module.css';
import EmptyModal from '../../components/Modals/EmptyModal';
import Button from '../../components/Button/Button';
import { UserRoleAssignments, UserRole } from '../../utils/interface';

interface ConfirmRemoveUserAssignmentModalProps {
    shown: boolean;
    user: UserRoleAssignments;
    assignment: UserRole;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmRemoveUserAssignmentModal = (props: ConfirmRemoveUserAssignmentModalProps) => {
    const {isMobile} = useWindowSize();
    
    let width: number = 564;
    if (isMobile) {
        width = 300;
    }
    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <div className="grid gap-4 grid-cols-1">
                    <span className="text-base">Are you sure you want to remove this user’s {props.assignment.roleName} assignment for {(props.assignment.baseOrgName || props.assignment.orgGroupName)}? 
                    Their access will be updated immediately. This cannot be undone.</span>
                </div>
                <div className="grid gap-1.5 grid-cols-1">
                    <div className="text-sm text-[#666666]">{props.user?.userFName} {props.user?.userLName}</div>
                    <div className="text-sm text-[#666666]">{props.assignment.roleName} for {(props.assignment.baseOrgName || props.assignment.orgGroupName)}</div>
                </div>                
            </>
        );

        modalFooter = (
            <>
                <div className="flex flex-row !pb-0 items-start gap-4"> {/* Actions */}
                    <Button className="redDelete text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text="Yes, Remove" onClick={props.onConfirm} />
                    <Button className="whiteBtn text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text="Cancel" onClick={props.onClose} />
                </div> 
            </>
        );
    }

    return (
        <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Remove Role Assignment?" onClose={props.onClose} width={width} footerClass={classes.modal_footer} />
    );
};