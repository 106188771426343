export enum ModuleIDs {
	Home = 1,
	UserGroupHome = 2,
	GeneralDocuments = 3,
	Documents = 4,
	TechnicalBulletins = 5,
	TechnicalBulletinsGroups = 6,
	UserGroupDocuments = 7,
	UserGroupDocumentsGroups = 8,
	Recordings = 9,
	Discussions = 10,
	DiscussionCategories = 11,
	DiscussionThreads = 12,
	DiscussionPosts = 13,
	PlantInformation = 14,
	Administration = 15,
	UserGroupManagement = 16,
	PlantManagement = 17,
	RoleConfiguration = 18,
	UserManagement = 19,
	EmailAlertManagement = 20,
	GeneralDocumentGroups = 21,
}