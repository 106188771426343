import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Store } from "../../Store";
import { useIsInternalAdmin } from "../../customHooks/useIsInternalAdmin";
import ImageImports from "../../utils/ImageImports";
import AccountSettingsModal from "../Navbar/AccountSettingsModal";
import SignOutModal from "../Navbar/SignOutModal";
import "./MobileMenu.css";
import MobileMenuHeader from "./MobileMenuHeader/MobileMenuHeader";
import MobileSubmenuLink from "./MobileSubmenuLink/MobileSubmenuLink";

const {  
  home,
  plant,
  documentSearch,
  cameraSquare,
  discussion,
  privacy_icon,
  } = ImageImports

  interface mobileRouteObject {
    id: string;
    title: string;
    path: string;
    icon: string;
    subRoutes?: Array<{ route: string, title: string, requiresAdmin?: boolean, requiresUsersGroupSelection?: boolean }>;
    requiresAdmin?: boolean;
    requiresUsersGroupSelection?: boolean;
  };

const mobileMenuRoutes: mobileRouteObject[] = [
  {
    id: "home",
    title: "Home",
    path: '/',
    icon: home,
    requiresAdmin: false,
    requiresUsersGroupSelection: false,
  },
  {
    id: "techDoc",
    title: "Documents",
    path: '',
    icon: documentSearch,
    subRoutes: [
      { route: '/technical-bulletins', title: "Technical Bulletins", requiresUsersGroupSelection: true},
      { route: '/technical-documentation/General Documents', title: "Users' Group Documents"},
    ],
    requiresAdmin: false,
    requiresUsersGroupSelection: false,
  },
  {
    id: 'recordings',
    title: 'Recordings',
    path: '/recordings',
    icon: cameraSquare,
    requiresAdmin: false,
    requiresUsersGroupSelection: true,
  },
  {
    id: "discussions",
    title: "Discussions",
    path: '/discussions/Discussions',
    icon: discussion,
    requiresAdmin: false,
    requiresUsersGroupSelection: true,
  },
  {
    id: "plantSite",
    title: "Plant Information",
    path: '/plant-information',
    icon: plant,
    requiresAdmin: false,
    requiresUsersGroupSelection: true,
  },
  {
    id: "administration",
    title: "Administration",
    path: '',
    icon: privacy_icon,
    subRoutes: [
      {route: '/administration/group-management', title: "Group Management"},
      {route: '/administration/plant-management', title: "Plant Management"},
      {route: '/administration/role-configuration', title: "Role Configuration"},
      {route: '/administration/user-management', title: "User Management"},
      {route: '/administration/email-alert-management', title: "Email Alert Management"},
    ],
    requiresAdmin: true,
    requiresUsersGroupSelection: false,
  },
];

function MobileMenu() {

  const navHeaderHeight = useRef<HTMLDivElement>(null);
  const [navScollHeight, setNavScollHeight] = useState(0);
  const stateContext = useContext(Store);
  const {
    accountSettings: { data: accountSettings },
    header: { data },
    selectedUserGroup
  } = useContext(Store);

  const [modal, setModal] = useState<ReactElement | undefined>();

  const isInternalAdmin = useIsInternalAdmin();
  const adminFilter = useCallback(({requiresAdmin}: {requiresAdmin?: boolean}): boolean => {
    if (requiresAdmin === undefined || requiresAdmin === false) {
      return true;
    }
    return isInternalAdmin;
  }, [isInternalAdmin]);

  const usersGroupFilter = useCallback(({requiresUsersGroupSelection}: {requiresUsersGroupSelection?: boolean}): boolean => {
    if (requiresUsersGroupSelection === undefined || requiresUsersGroupSelection === false) {
      return true;
    }
    return selectedUserGroup.id > 0;
  }, [selectedUserGroup]);
  
  const location = useLocation();

  const modals = {
    account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
    signOut: <SignOutModal closeModal={() => setModal(undefined)} />
  };


  useEffect(() => {
    let navh = window.innerHeight;
    let navhh = navHeaderHeight.current && navHeaderHeight.current.clientHeight;
    if (navh && navhh) {
      setNavScollHeight(navh - navhh)

    }
  }, [navScollHeight])

  const { mobileNavToggle, toggleMobileNav } = stateContext;

  return (

    <div className={mobileNavToggle ? "sidebar-mobile-wrapper active" : "sidebar-mobile-wrapper"}>
      <div ref={navHeaderHeight}>
        <MobileMenuHeader />
      </div>
      <nav style={{ 'height': navScollHeight - 30 }}>
        {mobileMenuRoutes.filter(adminFilter).filter(usersGroupFilter).map((menuItem, index) => {
          if (menuItem.subRoutes) {

            return <MobileSubmenuLink
              adminFilter={adminFilter}
              userGroupSelectionFilter={usersGroupFilter}
              index={index}
              key={menuItem.id}
              {...menuItem}
            />
          }

          return (
            <div className="mobile-nav-item" key={menuItem.id}>

              <Link
                to={menuItem.path}
                className={
                  location.pathname === menuItem.path ? "active-menu mobile-nav-links" : "mobile-nav-links"
                }
                onClick={() => {
                  toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                }}
              >
                <div className="menu-icon">
                  <img src={menuItem.icon} alt={menuItem.title} />
                </div>
                <div>{menuItem.title}</div>
              </Link>
            </div>
          );
        })}
        <hr className="mobile-hr" />
        <div className="Navbar_accountDropdown__fsGmU mobile-flex-column ">
          <div className="Navbar_flex_row__21ACR mobile-flex-row mobile-setting-links">
            <div className="initials bold">
              <img src={data.user.pictureUrl} alt="" />
            </div> 
            <div className="mobile-grow bold">
              {accountSettings.fName} {accountSettings.lName}
            </div>
          </div>
          <div className="cursor-pointer mobile-setting-links home-blue paddingL-12" onClick={() => setModal(modals.account)}>Account & Settings</div>
          <div className="cursor-pointer mobile-setting-links home-blue paddingL-12" onClick={() => setModal(modals.signOut)}>Sign Out</div>
        </div>
      </nav>
      {modal}
    </div>



  );
}

export default MobileMenu;