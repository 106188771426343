import { useContext } from "react";
import { Store } from "../../Store";
import { downloadStaticFile } from "../../customHooks/FileHttpServices";
import { tbwApplicationItemId } from "../../endpoints/endpoints";
import ImageImports from "../../utils/ImageImports";

const { linkout } = ImageImports;

export const TakasagoBulletins = () => {
  const { selectedUserGroup } = useContext(Store);
  return (
    <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
      <div className="flex flex-col gap-2">
        <span className="font-bold text-2xl">{selectedUserGroup.name} Bulletins</span>
        <div className="flex flex-row !pb-0">
          <span className="text-sm lg:basis-7/12">This page contains links to the Mitsubishi Power Technical Bulletins for your turbine generator.</span>
        </div>
      </div>
      
      <div className="flex flex-col items-start gap-2 self-stretch">
        <a href="https://www.gtnews.mhi.co.jp/en/" target="_blank" className="flex flex-row !pb-0 justify-end items-center gap-2 no-underline text-black" rel="noreferrer">
          <div className="bg-[#CDD9DD] flex justify-center items-center rounded-full h-6 w-6">
            <img src={linkout} alt="Takasago Technical Bulletin Website (TBW)" className="h-4 w-4" />
          </div>
          <span className="text-sm font-bold">Go to Takasago Technical Bulletin Website (TBW)</span>
        </a>
        <div className="flex flex-row !pb-0">
          <span className="text-sm lg:basis-7/12">
            The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine Service Engineering in Takasago,
            and requires a separate username and password. If you do not have those separate log-on credentials, you can apply for them by completing the form linked
            <span className="no-underline text-ocean cursor-pointer" onClick={() => {downloadStaticFile(tbwApplicationItemId)}}>
                {` here `}
            </span>
            and sending it to your LTSA Program Manager or Sales Manager.</span>
        </div>
      </div>
    </div>
  );
};
