import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Announcement, ShowErrorInterface } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";
import { AnnouncementList } from "./AnnouncementList";
import { useCreateAnnouncement, useDeleteAnnouncement, useGetAnnoucements, useUpdateAnnouncement } from "./AnnouncementsHttpServices";
import ImageImports from "../../utils/ImageImports";
import { Store } from "../../Store";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import ErrorCard from "../ErrorCard/ErrorCard";
import Loader from "../Loader/Loader";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";

const { tooltip } = ImageImports;

const defaultAnnouncement = {
    id: 0,
    title: '',
    messageContent: '',
    displayOrder: 0,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
    userGroupId: 0
};

export const AnnouncementContainer = () => {
    const [addUpdateAnnouncement, setAddUpdateAnnouncement] = useState<Announcement>();
    const [announcementConfirmation, setAnnouncementConfirmation] = useState<Announcement>();
    const [confirmDeleteAnnouncement, setConfirmDeleteAnnouncement] = useState<Announcement>();
    const {selectedUserGroup} = useContext(Store);
    const {data: announcements, refetch: refetchAnnouncements, isFetching} = useGetAnnoucements();
    const [addUpdateError, setAddUpdateError] = useState<ShowErrorInterface>();

    useEffect(() => {
        setAddUpdateError(undefined);
    }, [addUpdateAnnouncement]);

    const onSaveSuccess = useCallback((announcement: Announcement) => {
        setAddUpdateError(undefined);
        setAddUpdateAnnouncement(undefined);
        setAnnouncementConfirmation(announcement);
        refetchAnnouncements();
    }, []);

    const {deleteAnnouncement} = useDeleteAnnouncement();
    const {createAnnouncement} = useCreateAnnouncement({
        onError: (error: any) => {
            setAddUpdateError({
                isError: true,
                title: error.response.status  >= 400 ? error.response?.data : error.message,
                ErrorType: "danger",
              });
          }
    });
    const {updateAnnouncement} = useUpdateAnnouncement({
        onError: (error: any) => {
            setAddUpdateError({
                isError: true,
                title: error.response.status  >= 400 ? error.response?.data : error.message,
                ErrorType: "danger",
              });
          }
    });

    const saveAnnouncement = useCallback((announcement: Announcement) => {
        if (announcement.id > 0) {
            updateAnnouncement(announcement, {
                onSuccess: onSaveSuccess
            });
        } else {
            createAnnouncement(announcement, {
                onSuccess: () => onSaveSuccess(announcement)
            });
        }
    }, []);

    const handleDeleteAnnouncement = useCallback((announcement: Announcement) => {
        // call api
        deleteAnnouncement(announcement, {
            onSuccess: () => {
                // this value must be set here (the api does nothing with it and does not populate it) for the modal sequence to show correctly
                announcement.deleted = true;
                setConfirmDeleteAnnouncement(undefined);
                setAnnouncementConfirmation(announcement);
                refetchAnnouncements();
            }
        });
    }, []);

    const openPostAnnouncement = useCallback(() => {
        setAddUpdateError(undefined);
        const a = {
            ...defaultAnnouncement,
            displayOrder: (Math.max(...[...(announcements?? []), {displayOrder: 0}].map(d => d.displayOrder)) + 1),
            userGroupId: selectedUserGroup.id
        };
        setAddUpdateAnnouncement(a)
    }, [announcements, selectedUserGroup]);

    const confirmDelete = useCallback((announcement: Announcement) => {
        setAddUpdateAnnouncement(undefined);
        setConfirmDeleteAnnouncement(announcement);
    }, []);

    return (
        <div className="flex flex-col items-start gap-8 self-stretch">
            {addUpdateAnnouncement && <AddUpdateAnnouncementModal onClose={() => setAddUpdateAnnouncement(undefined)} onSave={saveAnnouncement} onDelete={confirmDelete} announcement={addUpdateAnnouncement} error={addUpdateError} />}
            {announcementConfirmation && <AnnouncementConfirmationModal onClose={() => setAnnouncementConfirmation(undefined)} announcement={announcementConfirmation} />}
            {confirmDeleteAnnouncement && <DeleteAnnouncementConfirmationModal onClose={() => setConfirmDeleteAnnouncement(undefined)} onDelete={handleDeleteAnnouncement} announcement={confirmDeleteAnnouncement} />}
            <div className="flex flex-col md:flex-row !pb-0 justify-between gap-6 self-stretch">
                <span className="font-bold text-[28px]">Announcements</span>
                <RequiresAdmin><Button className="whiteBtn" text="Post Announcement" onClick={openPostAnnouncement} /></RequiresAdmin>
            </div>
            {announcements && announcements.length > 0 ? (
                <AnnouncementList announcements={announcements} onEdit={setAddUpdateAnnouncement} />
            ) : isFetching ? <Loader /> : 
                <span className="text-sm">No announcements to display.</span>
            }
        </div>
    );
};

interface AddUpdateAnnouncementModalProps {
    announcement: Announcement;
    onClose: () => void;
    onSave: (announcement: Announcement) => void;
    onDelete: (announcement: Announcement) => void;
    error: ShowErrorInterface | undefined;
}
const AddUpdateAnnouncementModal = ({announcement, onClose, onSave, onDelete, error}: AddUpdateAnnouncementModalProps) => {
    const [announcementValues, setAnnouncementValues] = useState<Announcement>(defaultAnnouncement);
    const [showError, setShowError] = useState<ShowErrorInterface>();
    useEffect(() => {
        if (announcement) {
            setAnnouncementValues(announcement);
        }
    }, [announcement]);

    useEffect(() => {
        setShowError(error);
    }, [error])

    const isValid = announcementValues.title.trim().length > 0 && announcementValues.messageContent.trim().length > 0 && announcementValues.displayOrder > 0;
    const hasChanged = Object.entries(announcementValues).toString() !== Object.entries(announcement).toString();
    const canSave = isValid && hasChanged;

    const isEdit = useMemo(() => announcement.id > 0, [announcement]);

    const body = (
        <div className="flex flex-col gap-6 self-stretch">
            {showError?.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} closeBtn={false}  />}
            <div className="flex flex-col gap-6 self-stretch">

                <div className="flex flex-col md:flex-row items-center !pb-0 gap-2 lg:gap-6">
                    <div className="w-full md:w-1/3 text-normal font-normal">
                        <span>Announcement Title*</span>
                        <MiniTooltip text="Please enter the Announcement Title.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <div className={`w-full md:w-2/3`}>
                        <input type="text"
                            value={announcementValues.title}
                            onChange={e => setAnnouncementValues({...announcementValues, title: e.target.value})}
                            placeholder="User Conference"
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base w-full"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center !pb-0 gap-2 lg:gap-6">
                    <div className="w-full md:w-1/3 text-normal font-normal">
                        <span>Announcement Message*</span>
                        <MiniTooltip text="Please enter the Announcement Message.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <div className={`w-full md:w-2/3`}>
                        <textarea value={announcementValues.messageContent}
                            onChange={e => setAnnouncementValues({...announcementValues, messageContent: e.target.value})}
                            placeholder="A message about the conference."
                            rows={2}
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base w-full"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row items-center !pb-0 gap-2 lg:gap-6">
                    <div className="w-full md:w-1/3 text-normal font-normal">
                        <span>Display Order*</span>
                        <MiniTooltip text="Please enter the numeric placement for the Announcement to be displayed.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <div className={`w-full md:w-2/3`}>
                        <input type="number"
                            value={announcementValues.displayOrder}
                            onChange={e => setAnnouncementValues({...announcementValues, displayOrder: Number(e.target.value)})}
                            placeholder="1"
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base w-full"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                </div>

                <div className="flex flex-col md:invisible items-start !pb-0 gap-2">
                    <span className="font-light text-sm">*Required Fields</span>
                </div>                
            </div>
        </div>
    );

    const footer = (
        <div className="flex flex-col md:flex-row !pb-0 justify-between w-full items-center">
            <div className="flex flex-row !pb-0 gap-x-4">
                <Button className="darkBlue mb-0" text={isEdit? "Save Changes" : "Add Announcement"} onClick={() => onSave(announcementValues)} disabled={!canSave} />
                {isEdit && <Button className="redDelete mb-0" text="Delete" onClick={() => onDelete(announcementValues)} />}
            </div>
            <span className="font-light text-sm invisible md:visible">*Required Fields</span>
        </div>
    );

    return <EmptyModal body={body}
                    footer={footer}
                    heading={isEdit? "Edit Announcement" : "Post an Announcement"}
                    onClose={onClose}
                    shown={true} 
                    footerClass="pt-3" />
};

interface AnnouncementConfirmationModalProps {
    announcement: Announcement;
    onClose: () => void;
}
const AnnouncementConfirmationModal = ({announcement, onClose}: AnnouncementConfirmationModalProps) => {
    
    const wasEdited = announcement.id > 0;

    let body = `The "${announcement.title}" announcement has been added to the Users' Group Portal home page.`;
    let heading = 'Announcement Successfully Posted';
    if (wasEdited) {
        body = `The details for "${announcement.title}" announcement have been successfully updated.`;
        heading = 'Changes Saved';
    }
    if (announcement.deleted) {
        body = `The "${announcement.title}" announcement has been deleted from the Users' Group Portal.`;
        heading = 'Announcement Successfully Deleted';
    }

    const footer = (
        <Button className="darkBlue" text="Done" onClick={onClose} />
    );

    return <EmptyModal body={<span>{body}</span>}
                    footer={footer}
                    heading={heading}
                    headingClass="text-center pl-16"
                    footerClass="justify-center"
                    onClose={onClose}
                    width={568}
                    shown={true} />
};

interface DeleteAnnouncementConfirmationModalProps {
    announcement: Announcement;
    onClose: () => void;
    onDelete: (announcement: Announcement) => void;
}
const DeleteAnnouncementConfirmationModal = ({announcement, onClose, onDelete}: DeleteAnnouncementConfirmationModalProps) => {

    const footer = (
        <div className="flex flex-row !pb-0 gap-4">
            <Button className="redDelete" text="Yes, Delete" onClick={() => onDelete(announcement)} />
            <Button className="whiteBtn" text="Cancel" onClick={onClose} />
        </div>
    );

    return <EmptyModal body={<span>Are you sure you want to delete the "{announcement.title}" announcement from the Users&apos; Group Portal home page? This cannot be undone.</span>}
                    footer={footer}
                    heading="Delete Announcement"
                    onClose={onClose}
                    shown={true} />
};
