import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { useOutletContext, useParams, useNavigate, Link, Outlet } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import useWindowSize from "../../customHooks/useWindowSize";
import Discussions from "./Discussion";
import { DiscussionModuleOutletInterface } from "./DiscussionModuleContainer";
import classes from "./discussions.module.css";


function DiscussionGroupContainer() {
  const outletContext = useOutletContext<DiscussionModuleOutletInterface>();
  const { documentModule, documentGroups } = outletContext;
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '0');
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [selectedReportType, setSelectedReportType] = useState<string | null>(null);

  const handleGroupType = (e: React.MouseEvent<HTMLDivElement>) => {
    const groupId = e.currentTarget.getAttribute("data-id");
    if(!groupId) return;
    const filteredGroupId = documentGroups.filter((e) => e.id === parseInt(groupId));
    //console.log("🚀 ~ file: DiscussionGroupContainer.tsx ~ line 24 ~ handleGroupType ~ filteredGroupId", filteredGroupId)
    navigate(`../${filteredGroupId[0].id}`);
  };

  useEffect(() => {
    setSelectedReportType(documentGroups.find(d => d.id === groupId)?.name || null);
  })

  useEffect(() => {
    if ((documentGroups.length > 0 && documentGroups.findIndex((g) => g.id === groupId) === -1)) {
      navigate(`../${documentGroups[0].id}`);
    } else if (documentGroups.length === 0 && groupId > 0) {
      navigate('..');
    }
  }, [groupId, documentGroups]);

  let documentGroupSelection: ReactElement = <></>;
  if (documentGroups) {
    if (isMobile || isTablet) {
      documentGroupSelection = (
        <div className={classes.flex_column}>
          Category Type
          <div className="borderDropdown">
            <Dropdown         
              style={{width:'100%'}}  
              handleDropDownSelect={handleGroupType}
              selectedOption={selectedReportType}
              setSelectedOption={setSelectedReportType}
              options={documentGroups}
            />
          </div>          
        </div>
      );
    } else {
      documentGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {documentGroups.map((documentGroupItem) => (
            <Link
              className={`${classes.document_group_link} ${groupId === documentGroupItem.id ? classes.active : ""}`}
              key={documentGroupItem.id}
              to={`../${documentGroupItem.id}`}
            >
              {documentGroupItem.name}
            </Link>
          ))}
        </div>
      );
    }
  };

  const ModuleHeaderContent = useMemo(() => {
    return documentModule.moduleHeaderContent;
  }, [documentModule]);

  return (
    <>
      <div className={`${classes.top_section} ${classes.flex_column}`}><ModuleHeaderContent /></div>
      <div className={`${classes.line}`} />
      <div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
        {documentGroupSelection}
        <Discussions documentModule={documentModule} documentGroups={documentGroups} />
        <Outlet context={outletContext} />
      </div>
    </>
  );
}

export default DiscussionGroupContainer;
