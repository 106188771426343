import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import {
    $getSelectionStyleValueForProperty, $isAtNodeEnd, $isParentElementRTL, $patchStyleText, $wrapNodes
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection, $isTextNode, CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_CRITICAL, ElementNode, FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND, INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND, RangeSelection, SELECTION_CHANGE_COMMAND, TextNode
} from "lexical";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ImageImports from "../../../../utils/ImageImports";
import '../theme.css';
import { getSelectedNode } from "../utils/getSelectedNode";
import { sanitizeUrl } from "../utils/sanitizeUrl";
import ToolbarImageUpload from "../utils/ToolbarImageUpload";
import { blockTypeOptions, fontFamilyOptions, fontSizeOptions } from "../utils/toolbarOptions";
import Select from "../utils/toolbarSelect";

const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    check: 'Check List',
    h1: 'Large Header',
    h2: 'Medium Header',
    h3: 'Small Header',
    // h1: 'Heading 1',
    // h2: 'Heading 2',
    // h3: 'Heading 3',
    // h4: 'Heading 4',
    // h5: 'Heading 5',
    // h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
};

const Alignments = { left: "left", right: "right", center: "center", justify: "justify" };

interface IToolbarProps {
    refresh?: number;
    allowUploads?: boolean;
    externalImage?: boolean;
    uploadFile?: (attachment: File) => void;
}

export default function ToolbarPlugin({ refresh, allowUploads, externalImage, uploadFile }: IToolbarProps): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] =
        useState<keyof typeof blockTypeToBlockName>('paragraph');
    const [fontSize, setFontSize] = useState<string>('15px');
    const [fontFamily, setFontFamily] = useState<string>('Arial');
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isRTL, setIsRTL] = useState(false);
    const [alignment, setAlignment] = useState<keyof typeof Alignments>("left");

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsRTL($isParentElementRTL(selection));

            const getDefaultFontSize = () => {
                let type: keyof typeof blockTypeToBlockName;
                if ($isTextNode(element)) type = element.getType() as keyof typeof blockTypeToBlockName;
                else if ($isListNode(element)) type = element.getListType() as keyof typeof blockTypeToBlockName;
                else if ($isHeadingNode(element)) type = element.getTag() as keyof typeof blockTypeToBlockName;
                else type = 'paragraph'
                switch (type) {
                    case 'h1': return '40px';
                    case 'h2': return '28px';
                    case 'h3': return '24px';
                    case 'paragraph': return '15px';
                }
            }

            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
              } else {
                setIsLink(false);
              }
            if (elementDOM !== null) {
                const formatType: keyof typeof Alignments = element.getFormatType() || "left";
                if (formatType) setAlignment(formatType);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode,
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
                
                setFontSize(
                    $getSelectionStyleValueForProperty(selection, 'font-size', getDefaultFontSize()),
                )
                setFontFamily(
                    $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
                );
            }
        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            activeEditor.registerCommand<boolean>(
                CAN_UNDO_COMMAND,
                (payload) => {
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
            activeEditor.registerCommand<boolean>(
                CAN_REDO_COMMAND,
                (payload) => {
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
        );
    }, [activeEditor, updateToolbar]);

    useEffect(() => {
        if (editor) editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined); 
    }, [refresh])

    const applyStyleText = useCallback(
        (styles: Record<string, string>) => {
            activeEditor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, styles);
                }
            });
        },
        [activeEditor],
    );

    const insertLink = useCallback(() => {
        if (!isLink) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
        } else {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
      }, [editor, isLink]);

    const onFontSizeSelect = useCallback(
        (e: ChangeEvent) => {
            const temp = (e.target as HTMLSelectElement).value;
            applyStyleText({ 'font-size': temp });
            setFontSize(temp);
        },
        [applyStyleText],
    );

    const onFontFamilySelect = useCallback(
        (e: ChangeEvent) => {
            applyStyleText({ 'font-family': (e.target as HTMLSelectElement).value });
        },
        [applyStyleText],
    );

    const formatBulletList = () => {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const { type_bold, type_italic, type_strikethrough, type_underline, text_center, text_left, text_right, text_outdent, text_indent, justify, list_ol, list_ul, copyLink } = ImageImports;

    const handleBlockSelection = useCallback(
        (e: ChangeEvent) => {
            let temp = (e.target as HTMLSelectElement).value;

            const formatParagraph = () => {
                if (blockType !== 'paragraph') {
                    setBlockType('paragraph');
                    editor.update(() => {
                        const selection = $getSelection();

                        if ($isRangeSelection(selection)) {
                            $wrapNodes(selection, () => $createParagraphNode());
                        }
                    });
                }
            };

            const formatLargeHeading = () => {
                if (blockType !== "h1") {
                    editor.update(() => {
                        const selection = $getSelection();

                        if ($isRangeSelection(selection)) {
                            $wrapNodes(selection, () => $createHeadingNode("h1"));
                        }
                    });
                }
                setFontSize('40px')
            };

            const formatMediumHeading = () => {
                if (blockType !== "h2") {
                    editor.update(() => {
                        const selection = $getSelection();
                        if ($isRangeSelection(selection)) {
                            $wrapNodes(selection, () => $createHeadingNode("h2"));
                        }
                    });
                }
                setFontSize('28px')
            };

            const formatSmallHeading = () => {
                if (blockType !== "h3") {
                    editor.update(() => {
                        const selection = $getSelection();

                        if ($isRangeSelection(selection)) {
                            $wrapNodes(selection, () => $createHeadingNode("h3"));
                        }
                    });
                }
                setFontSize('24px')
            };

            const formatQuote = () => {
                if (blockType !== "quote") {
                    setBlockType('quote');
                    editor.update(() => {
                        const selection = $getSelection();

                        if ($isRangeSelection(selection)) {
                            $wrapNodes(selection, () => $createQuoteNode());
                        }
                    });
                }
            };
            if (temp === 'paragraph') formatParagraph();
            else if (temp === 'quote') formatQuote();
            else if (temp === 'h1') formatLargeHeading();
            else if (temp === 'h2') formatMediumHeading();
            else if (temp === 'h3') formatSmallHeading();
        },
        [blockType, editor],
    );

    return (
        <div className="toolbar" ref={toolbarRef}>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                className={"toolbar-item" + (isBold ? " active" : "")}
                aria-label="Format Bold"
                type="button"
            >
                <img src={type_bold} alt="bold" className={"icon"} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                className={"toolbar-item" + (isItalic ? " active" : "")}
                aria-label="Format Italics"
                type="button"
            >
                <img src={type_italic} alt="italic" className={"icon"} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                className={"toolbar-item" + (isUnderline ? " active" : "")}
                aria-label="Format Underline"
                type="button"
            >
                <img src={type_underline} alt="underline" className={"icon"} />
            </button>
            <button
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                className={"toolbar-item" + (isStrikethrough ? " active" : "")}
                aria-label="Format Strikethrough"
                type="button"
            >
                <img src={type_strikethrough} alt="strikethrough" className={"icon"} />
            </button>
            <>
                <>
                    <Select
                        className="toolbar-item"
                        onChange={handleBlockSelection}
                        options={blockTypeOptions}
                        value={blockType}
                    />
                </>
                <>
                    <Select
                        className="toolbar-item"
                        onChange={onFontSizeSelect}
                        options={fontSizeOptions}
                        value={fontSize}
                    />
                </>
                <>
                    <Select
                        className="toolbar-item"
                        onChange={onFontFamilySelect}
                        options={fontFamilyOptions}
                        value={fontFamily}
                    />
                </>
            </>
            <>
                <button className="toolbar-item" onClick={formatBulletList} type="button">
                    <img src={list_ul} alt="unordered-list" className={`icon bullet-list ${blockType === 'bullet' ? 'active' : ''}`} />
                    {blockType === "bullet" && <span className="active" />}
                </button>
                <button className="toolbar-item" onClick={formatNumberedList} type="button">
                    <img src={list_ol} alt="ordered-list" className={`icon numbered-list ${blockType === 'number' ? 'active' : ''}`} />
                    {blockType === "number" && <span className="active" />}
                </button>
                <button className="toolbar-item" onClick={() =>
                    editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)} type="button">
                    <img src={text_indent} alt="" className={`icon indent ${isRTL ? 'active' : ""}`} />
                    {isRTL && <span className="active" />}
                </button>
                <button className="toolbar-item" onClick={() =>
                    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)} type="button">
                    <img src={text_outdent} alt="" className={`icon outdent ${!isRTL ? 'active' : ""}`} />
                    {!isRTL && <span className="active" />}
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                    }}
                    className={`toolbar-item ${alignment === "left" ? "active" : ""}`}
                    aria-label="Left Align"
                    type="button"
                >
                    <img src={text_left} alt="text align left" className={"icon"} />
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                    }}
                    className={`toolbar-item ${alignment === "center" ? "active" : ""}`}
                    aria-label="Center Align"
                    type="button"
                >
                    <img src={text_center} alt="text align center" className={"icon"} />
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                    }}
                    className={`toolbar-item ${alignment === "right" ? "active" : ""}`}
                    aria-label="Right Align"
                    type="button"
                >
                    <img src={text_right} alt="text align right" className={"icon"} />
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                    }}
                    className={`toolbar-item ${alignment === "justify" ? "active" : ""}`}
                    aria-label="Justify Align"
                    type="button"
                >
                    <img src={justify} alt="text justify" className={"icon"} />
                </button>
                {allowUploads && <ToolbarImageUpload addFile={externalImage ? uploadFile : undefined} />}{" "}
                <button
                    onClick={insertLink}
                    className={'toolbar-item spaced' + (isLink ? ' active' : '')}
                    aria-label="Insert link"
                    title="Insert link"
                    type="button">
                    <img src={copyLink} alt="link" className={"icon"} />
                </button>
            </>
        </div>
    );
}
