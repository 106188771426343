import { UnitDTO, UnitDATA, UnitGetAllDTO } from "../../utils/interface";
import { apiUnit, apiUnitGetAll } from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

export interface UnitCrudMethods<T,TD,TGA> {
  get: (id: number) => Promise<T>;
  getAll: (id: number) => Promise<TGA>;
  update: (data: TD) => Promise<T>;
  create: (data: TD) => Promise<T>;
}

export const UnitApi: UnitCrudMethods<AxiosResponse<UnitDTO>, UnitDATA,AxiosResponse<UnitGetAllDTO>> = {
  get: async (plantId: number): Promise<AxiosResponse<UnitDTO>> => {
    const response = await axios({
      url: `${apiUnit}/${plantId}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (plantId: number): Promise<AxiosResponse<UnitGetAllDTO>> => {
    const response = await axios({
      url: `${apiUnitGetAll}`,
      method: "GET",
      params:{
        plantId: plantId,
      },
      headers: {
        baseorg: 0
      }
    });
    return response.data;
  },
  update: async (data: UnitDATA): Promise<AxiosResponse<UnitDTO>> => {
    const response = await axios({
      url: `${apiUnit}/${data.unitId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  create: async (data: UnitDATA): Promise<AxiosResponse<UnitDTO>> => {
    const response = await axios({
      url: `${apiUnit}/${data.plantId}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  }
 
};

export const useGet_UnitData = (unitID: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.unit, unitID], () => UnitApi.get(unitID), {
    enabled: !!unitID,
    onSuccess,
    onError,
  });
};

export const useGetAll_UnitData = (plantId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.unitGetAll, plantId], () => UnitApi.getAll(plantId), {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};

export const useUpdate_UnitData = () => {
  return useMutation(UnitApi.update);
};

export const useCreate_UnitData = () => {
  return useMutation(UnitApi.create);
};
