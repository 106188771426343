import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import "./DatePickerCalendar.css";

export interface IDatePickerCalendarProps {
  shownDate: Dayjs;
  selectedDate: Dayjs;
  startDate: Dayjs;
  endDate: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onChange: (newDate: Dayjs) => void;
}

export const DatePickerCalendar: React.FC<IDatePickerCalendarProps> = ({
  shownDate,
  selectedDate,
  startDate,
  endDate,
	minDate,
  maxDate, 
  onChange
}) => {
  const todayObj = dayjs();
  const [dayObj, setDayObj] = useState(shownDate);

  useEffect(() => {
    setDayObj(shownDate);
  }, [shownDate]);

  useEffect(() => {
    if (dayjs(selectedDate, 'MM/DD/YYYY', true).isValid()) {
      setDayObj(selectedDate);
    }
    else {
      setDayObj(dayjs());
    } 
  }, [selectedDate]);
   
  const firstDayOfSelectedMonth = dayObj.startOf('month');
  const selectedMonthDayOfWeek = firstDayOfSelectedMonth.day();
  const weekDayOfLastDayCurrentMonth = dayObj.endOf('month').day();
  
  const getRange = (length: number) => {
    return Array.from({ length: length }, (value, index) => index);
  }

  const calendarRange: Array<Dayjs> = [];
  getRange(selectedMonthDayOfWeek).map(i => {
    return calendarRange.push(firstDayOfSelectedMonth.subtract(selectedMonthDayOfWeek - i, "day"));
  });

  getRange(dayObj.daysInMonth()).map(i => {
    return calendarRange.push(firstDayOfSelectedMonth.add(i, "day"));
  });

  getRange(6 - weekDayOfLastDayCurrentMonth).map(i => {
    return calendarRange.push(dayObj.endOf('month').add(i + 1, 'day'));
  });

  return (
    <>
      <div className={"DatePickerCalendar__header"}>
        {calendarRange.map((day, i) => (
          <div key={`header-${i}`}>
          {i < 7 &&
            <div className={"dayCell font-bold"}>
              {day.format("dd").charAt(0)}
            </div>
          }
          </div>
        ))}
      </div>
      <div className="dayContainer">
        {calendarRange.map((day, i) => (
          <div 
            key={i}
            className={clsx("dayCell",
              `${(day.isSame(todayObj, 'day')) ? 'DatePickerCalendar__dayCell_today' : ''}`,
              {
                  DatePickerCalendar__dayCell_selected: (dayjs(day).isSame(selectedDate, 'day')) || (startDate && day.isSame(startDate, 'day')) || (endDate && day.isSame(endDate, 'day'))
              },
              `${(((minDate && day.isBefore(minDate)) || (maxDate && day.isAfter(maxDate)))) ? 'dayCell--faded' : ''}`,
              `${(((startDate && day.isAfter(startDate)) && (endDate && day.isBefore(endDate)))) ? 'DatePickerCalendar__dayCell_selected' : ''}`
            )}
            onClick={() => {
              if ((!minDate || !minDate.isValid() || day.isAfter(minDate) || day.isSame(minDate)) && (!maxDate || !maxDate.isValid() || day.isBefore(maxDate) || day.isSame(maxDate))) {
                  onChange(day)
              }
            }}
          >
            {day.date()}
          </div>
        ))}
      </div>
    </>
  );
};
