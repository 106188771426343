import { useNavigate, useOutletContext } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";
import React, { FunctionComponent, useContext, useState } from "react";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { useCreateNewGeneralDocument, useUpdateGeneralDocument } from "../Reports/ReportsHttpServices";
import { useQueryClient } from "react-query";
import Button from "../../components/Button/Button";
import classes from "./Documents.module.css";
import { useOnError } from "../../customHooks/useOnError";
import { AxiosError } from "axios";
import Alert from "../../components/Alert/Alert";
import Loader from "../../components/Loader/Loader";

const dupDocErrMsg = 'The document could not be attached because the name is already in use. Please change the filename on the original attachment and try again.';

interface AddDocumentContainerProps{
  isEdit:boolean;
}

const AddDocumentContainer: FunctionComponent<AddDocumentContainerProps> = ({isEdit}) => {
  
  const { documentModule, documentGroups } = useOutletContext<DocumentsModuleOutletInterface>();

  const navigate = useNavigate();
  const { addDocumentModalBodyContent: DocumentModalBodyContent } = documentModule;
  const { setIsModal, selectedUserGroup } = useContext(Store);
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const [addDocumentError, setAddDocumentError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    mutate: AddDocument,
    isSuccess,
    data: reportData,
    
    reset,
  } = (useCreateNewGeneralDocument)({
    onMutate: () => {
      setIsLoading(true);
      setAddDocumentError(undefined);
    },
    onError: (error: AxiosError<string>) => {
      setErrorData(error);
      setIsLoading(false);
      if (error.response?.data.includes('rename')) {
        setAddDocumentError(dupDocErrMsg);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(["reports"]);
      setIsLoading(false);
    },
  });

  const {
    mutate: UpdateDocument,
    isSuccess: isEdited,
    data: editedReportData
  } = (useUpdateGeneralDocument)({
    onMutate: () => {
      setIsLoading(true);
      setAddDocumentError(undefined);
    },
    onError: (error: AxiosError<string>) => {
      
      setErrorData(error);
      setIsLoading(false);

      if (error.response?.data.includes('rename')) {
        setAddDocumentError(dupDocErrMsg);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(["reports"]);
      setIsLoading(false);
    },
  })

  const handleCloseModal = () => { 
    setIsModal && setIsModal!({ ...CloseAllModal });
    navigate("..");
  };

  return (
    <>
      {(isLoading) &&
        <div className="fixed top-0 left-0 w-full h-screen z-40 !overflow-hidden opacity-50 bg-gray-100 flex flex-col items-center justify-center">
          <Loader />
        </div>
      }

      <ModalGlobal
        modaltitle={isSuccess || isEdited ? `Document Successfully ${isEdit ? "Edited" : "Added"}` : `${isEdit ? "Edit" : "Add"} ${documentModule.addEditHeaderName}`}
        isModalHeader={true}
        isTitleCenter={isSuccess || isEdited}
        modalClose={handleCloseModal}
        modalSize={isSuccess || isEdited? 'sm' : ''}
      >
        {!isEdit && documentModule.addModalHeaderContent.length > 0 && (
            <div>
              {documentModule.addModalHeaderContent.map((e, i) => {
                return (
                  <p key={i} className={classes.modal_body_text}>{e}</p>
                )
              })}
            </div>
          )}
        {isSuccess && reportData && (
          <div className="flex flex-col items-start gap-6">
            <p className="m-0">
              {reportData.displayName} was successfully added to the {documentModule.name} page for {selectedUserGroup.name}. Would you like to add another
              document at this time?
            </p>
            <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons} self-stretch justify-center`}>
              <Button className={`darkBlue`} text="Add Another Document" onClick={reset} />
              <Button className={`whiteBtn`} text="Done" onClick={() => navigate("..")} />
            </div>
          </div>
        )}
        {isEdited && editedReportData && (
          <div className="flex flex-col items-start gap-6">
            <p className="m-0">
              The document details for "{editedReportData.displayFileName}" have been successfully updated.
            </p>
            <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons} self-stretch justify-center`}>
              <Button className={`darkBlue`} text="Done" onClick={() => navigate("..")} />
            </div>
          </div>
        )}
        {!isSuccess && !isEdited && (
          <>
            {addDocumentError && <div className="mb-4"><Alert dismissible={false} onClose={() => {}} type="error">{addDocumentError}</Alert></div>}
            <DocumentModalBodyContent documentModule={documentModule} isEdit={isEdit} documentGroups={documentGroups} onSave={isEdit? UpdateDocument : AddDocument} siteUnits={[]} setAddDocumentError={setAddDocumentError} />
          </>
        )}
      </ModalGlobal>
    </>
  );
};

export default AddDocumentContainer;
