import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
// import { Link } from 'react-router-dom';

import classes from "./DropDownCustom.module.css";

import { Store } from "../../../../Store";
import ImageImports from "../../../../utils/ImageImports";
import { ErrorMessage, useField, useFormikContext } from "formik";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";

function DropDownSelect(props: DropDownSelectProps) {

  const [selectToggle, setSelectToggle] = useState(false);
  const { values, setFieldValue,errors } = useFormikContext<any>();
  const [field,meta] = useField(props.name);

  const stateContext = useContext(Store);

  const selectButtonRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState<{ id: number | boolean | string | null; name: string }>({ id: "0", name: "" });
  const { downArrow, tooltip } = ImageImports;
  //   const options = ["option 1", "option 2", "option 3", "option 4"];
  const toggleSelect = () => setSelectToggle(!selectToggle);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== selectButtonRef.current) {
        if (!path[0].classList.contains("drop-arrow")) {
          setSelectToggle(false);
        }
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, [props.defaultValue, values, stateContext]);

  useEffect(() => {
    if((props.defaultValue === null || props.defaultValue === "" || props.defaultValue === undefined) && props.placeholder) {
      setSelectedValue({ ...selectedValue, id: 0, name: props.placeholder });
    }
    else if (props.defaultValue !== null && props.options.length >= 0) {
      const updatedValue = props.options.filter((data) => data.id === props.defaultValue);
      setSelectedValue({ ...updatedValue[0] });
    }
    else {
      if (props.placeholder) {
        setSelectedValue({ ...selectedValue, id: 0, name: props.placeholder });
      }
    }
  }, []);

  return (
    <div>
      <div className={classes.form_field_row}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
        <div className={classes.form_group}>
          <div        
          className={meta.touched && meta.error ? `${classes.input_box} ${classes.errorField} ${classes.error_text}` : `${classes.input_box}`}
          tabIndex={0}
          onKeyUp={e => {
            if (e.code.toLowerCase() === 'space') {
              !props.disabled && toggleSelect();
            }
          }}
          >
            <div
              className={props.disabled ? `${classes.form_control} ${classes.disabled} ${classes.selectDropdown}` : `${classes.form_control}  ${classes.selectDropdown}` }
              ref={selectButtonRef}      
              style={selectedValue.id === 0 ? {color:"#999"} : {color:"#000"}}    
              onClick={toggleSelect}
            >
              
               {selectedValue.name}
            
              <img src={downArrow} className={`drop-arrow ${selectToggle? classes.selectArrowActive : classes.selectArrow}`} alt="" />
            </div>
          </div>
          <div
            className={
              // selectToggle ? `${classes.input_box} dropdown active` : `${classes.input_box} dropdown`
              selectToggle ? `${classes.dropdown} ${classes.isActive}` : `${classes.dropdown} `
            }
          >
            <div className={classes.options_wrapper}>
              {props.options.map((option, i) => {
                return (
                  <div
                    className={classes.options}
                    key={i}
                    tabIndex={selectToggle? 0 : -1}
                    onKeyUp={e => {
                      if (e.code.toLowerCase() === 'space') {
                        setSelectedValue({ ...selectedValue, id: option.id, name: option.name });
                        if(option.id === -1){
                          setFieldValue(props.name, null);
                        }else{
                          setFieldValue(props.name, option.id);
                        }

                        if(props.getSelectedOption){
                          props.getSelectedOption(option.id);
                        }
                        setSelectToggle(false);
                      }
                    }}
                    onClick={(e) => {
                      setSelectedValue({ ...selectedValue, id: option.id, name: option.name });
                      if(option.id === -1){
                        setFieldValue(props.name, null);
                      }else{
                        setFieldValue(props.name, option.id);
                      }
                      
                      if(props.getSelectedOption){
                        props.getSelectedOption(option.id);
                      } 
                    }}
                  >
                    {option.name}
                  </div>
                );
              })}
            </div>
          </div>
          {/* <ErrorMessage name={props.name}>{(msg) => <div className={classes.error_text}>{msg}</div>}</ErrorMessage> */}
        </div>
      </div>
    </div>
  );
}

interface DropDownSelectProps {
  options: {
    id: number | boolean | string | null;
    name: string;
  }[];
  defaultValue?: number | boolean | string | undefined;
  name: string;
  labelName: string;
  disabled:boolean;
  placeholder?:string;
  getSelectedOption?:Dispatch<SetStateAction<number | boolean | string | null>>;
  tooltip?:{   
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?:number;
    text:string;
  };
}
DropDownSelect.defaultProps = {
  disabled:false,
};

export default DropDownSelect;
