
import axios, {AxiosResponse} from "axios";

import {apiMitsubishiPowerReport, ugthreadcategory, omManualApiUrl, technicalBulletinApiUrl, apiPartsCatalog } from "../../endpoints/endpoints";
import { CPApiResponse, DiscussionGroupCreatable, DiscussionGroupInterface, DiscussionGroupUpdate } from "../../utils/interface";

function handleAxiosResponse<T = any> (response: AxiosResponse<CPApiResponse<T>>): T {
    if (response.status >= 400)
        throw response.statusText;
    if (response.data.success !== true)
        throw response.data.message;
    return response.data.data;
}

export interface CrudMethods<T,A> {
    get: (id: number) => Promise<T>;
    update: (data: DiscussionGroupUpdate) => Promise<T>;
    create: (data: DiscussionGroupCreatable) => Promise<T>;
    del: (data: T) => Promise<boolean>;
    getAll: (id: number) => Promise<A>;
    generateReport: (itemTypeId:number) => Promise<Blob>;
    getGenerateReportLink: (itemTypeId:number) => string;
}

export const DiscussionApiCrud: CrudMethods<DiscussionGroupInterface,DiscussionGroupInterface[]> = {
    get: async (id: number): Promise<DiscussionGroupInterface> => {
        const response: AxiosResponse<CPApiResponse<DiscussionGroupInterface>> = await axios(`${ugthreadcategory}/GetAllByUGId/${id}`); 
        return handleAxiosResponse(response);
    },

    update: async (data: DiscussionGroupUpdate): Promise<DiscussionGroupInterface> => {
        const response: AxiosResponse<CPApiResponse<DiscussionGroupInterface>> = await axios(`${ugthreadcategory}/${data.id}`, {
            method: 'PUT',
            data
        });
        return handleAxiosResponse(response);
    },

    create: async (data: DiscussionGroupCreatable): Promise<DiscussionGroupInterface> => {
        const response: AxiosResponse<CPApiResponse<DiscussionGroupInterface>> = await axios(ugthreadcategory, {
            method: 'POST',
            data
        });
        return handleAxiosResponse(response);
    },

    del: async (data: DiscussionGroupUpdate): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${ugthreadcategory}/${data.id}`, {
            method: 'DELETE'
        });
        return handleAxiosResponse(response);
    },
    getAll: async (id: number): Promise<DiscussionGroupInterface[]> => {
        const response: AxiosResponse<CPApiResponse<DiscussionGroupInterface[]>> = await axios(`${ugthreadcategory}/${id}`); 
        return handleAxiosResponse(response);
    },
    generateReport: async (itemTypeId: number): Promise<Blob> => {        
        const response: AxiosResponse<Blob> = await axios(`${apiMitsubishiPowerReport}/GenerateUsageReport?ItemTypeId=${itemTypeId}`);
        if (response.status >= 400) {
            throw response.statusText;
        }
        return response.data;
    },
    getGenerateReportLink: (itemTypeId): string => {
        return `${apiMitsubishiPowerReport}/GenerateUsageReport?ItemTypeId=${itemTypeId}`;
    }
};


// Parts Catalog section ends here

