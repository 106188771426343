import React, { useEffect, useRef, useState } from "react";

import PopoutToolTip from "../../PopoutTooltip/PopoutTooltip";
import classes from "../Nameplate.module.css";
import ImageImports from "../../../utils/ImageImports";
import moment from "moment";
import { IdObj } from "../../../utils/interface";
import { EquipmentAttachmentsApi, useGetImage_PlantEquipmentAttachment } from "../../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { PrintPDF, toBase64 } from "../../../utils/FileUtils";
import { AxiosError } from "axios";
import { useOnError } from "../../../customHooks/useOnError";
import { useIsFetching } from "react-query";
import Skeleton from "react-loading-skeleton";

interface NameplateCardProps {
  id: number;
  IdObj: IdObj;
  fileName: string;
  fileType: string;
  lastUpdated: string;
  desc: string;
  getIdOpenViewModal(id: number): void;
  ext: string;
}

function NameplateCard(props: NameplateCardProps) {
  // Image imports;
  const { ellipsis, trash, file_edit, download, print, spinner } = ImageImports;
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState("");
  const isFetching = useIsFetching();

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment Start
  ///////////////////////////////////////////

  const onSuccess = (data: Blob) => {
    setAttachmentBlob(data);
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isLoading: attachmentIsLoading } = useGetImage_PlantEquipmentAttachment(props.IdObj.attachmentid, onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  const popoutToolTipListItems = [

    {
      img: download,
      text: "Download",
      onclick: (IdObj: IdObj) => {
        if (attachmentBlob) {
          EquipmentAttachmentsApi.download(attachmentBlob, props.fileName, props.ext);
        }
      },
    },
    {
      img: print,
      text: "Print",
      onclick: (IdObj: IdObj) => {
        if (attachmentBlob) {
          PrintPDF(attachmentBlob);
        }
      },
    },

  ];

  // const stateContext = useContext(Store);
  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);
  return (
    <>
      <div className={classes.nameplate_title}>
        { (
          <div className={classes.nameplate_content}>
            <div className={classes.nameplate_ellipse}>
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                onClick={() => {
                  setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              
              
              <PopoutToolTip listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={props.id} IdObj={props.IdObj} />
            </div>
            {
                isFetching > 0 ? (
                  <Skeleton height={200} />
                ) :  <div
                className={`${classes.col_item} ${classes.imgPrevWrapper}`}
                onClick={() => props.getIdOpenViewModal(props.id)}
                style={{ backgroundImage: `url(${imgBase64})` }}
              >
                {/* <img className={classes.nameplate_img} onClick={() => props.getIdOpenViewModal(props.id)} src={props.img} alt="img" /> */}
              </div>}
            
            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1">{props.fileName}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                Last Modified: {`${moment(props.lastUpdated).format("MMM DD YYYY")}`}
              </div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>Description: {props.desc}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NameplateCard;
