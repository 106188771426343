import axios, { AxiosResponse } from "axios";
import { useCallback, useContext } from "react";
import { QueryFunctionContext, useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useUserGroupIdHeader } from "../../customHooks/useUserGroupIdHeader";
import { getNotificationTypesApiUrl, updateNotificationsAsRead, updateItemsAsRead, updateNotificationsAsDismissed } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { CPApiResponse, Notification, NotificationType } from "../../utils/interface";

declare type GetNotificationTypesParameters = ['NotificationTypes', {}];

const getNotificationTypes = async ({queryKey}: QueryFunctionContext<GetNotificationTypesParameters>): Promise<NotificationType[]> => {
    const response: AxiosResponse<CPApiResponse<NotificationType[]>> = await axios(getNotificationTypesApiUrl);
    const notificationTypes = handleAxiosResponse(response);
    return notificationTypes;
};

export const useGetNotificationTypes = (options: Omit<UseQueryOptions<unknown, unknown, NotificationType[], GetNotificationTypesParameters>, "queryKey" | "queryFn"> = {}) => {
    const hasUserGroupForHeader = useUserGroupIdHeader();
    return useQuery<unknown, unknown, NotificationType[], GetNotificationTypesParameters>(['NotificationTypes', {}], getNotificationTypes, {
        ...options,
        enabled: hasUserGroupForHeader
    });
};

const bulkUpdateNotificationsAsRead = async (notificationIds: number[]) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(updateNotificationsAsRead, {
        method: 'POST',
        data: {
            notificationIds
        }
    });
    return handleAxiosResponse(response);
};

export const useBulkUpdateNotificationAsRead = () => {
    return useMutation<boolean, unknown, number[]>(bulkUpdateNotificationsAsRead);
};

const bulkUpdateItemsAsRead = async (globalItemIds: number[]) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(updateItemsAsRead, {
        method: 'POST',
        data: {
            globalItemIds
        }
    });
    return handleAxiosResponse(response);
};

export const useBulkUpdateItemsAsRead = (options?: Omit<UseMutationOptions<boolean, unknown, number[]>, 'mutationFn'>) => {
    const queryClient = useQueryClient();
    return useMutation<boolean, unknown, number[]>(bulkUpdateItemsAsRead, {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.header]);
        },
        ...options
    });
};

export const useMarkItemNotificationAsRead = () => {
    const {header} = useContext(Store);
    const queryClient = useQueryClient();
    const {mutate} = useBulkUpdateItemsAsRead({
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.header);
        }
    });
    return useCallback((globalItemId: number) => {
        const notifications: Notification[] = [];
        if (notifications.find(n => n.itemId === globalItemId && n.read === false)) {
            mutate([globalItemId]);
        }
    }, [header]);
};

const dismissNotification = async (baseOrgId: number, notificationIds: number[]) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${updateNotificationsAsDismissed}?BaseOrg=${baseOrgId}`, {
        method: 'DELETE',
        data: notificationIds
    });
    return handleAxiosResponse(response);
};

export const useDismissNotifications = (options?: Omit<UseMutationOptions<boolean, unknown, number[]>, 'mutationFn'>) => {
    const {selectedPlant} = useContext(Store);
    return useMutation<boolean, unknown, number[]>(dismissNotification.bind(null, selectedPlant.baseOrgId), options);
};