import { ChangeEvent, FunctionComponent, useContext, useState } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { DocumentGroup, Report } from "../../utils/interface";
import classes from './Reports.module.css';
import { CloseAllModal } from "../../utils/CloseAllModal";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { pick } from "../../utils/pick";

interface Props {
    documentGroupName: string;
    documentGroups: DocumentGroup[]
    documents: Report[];
    onClose: () => void;
    move: (from: string, to: DocumentGroup) => void;
}

const MoveDocumentModal: FunctionComponent<Props> = ({ documentGroupName, documentGroups, documents, onClose, move }) => {
    const { selectedPlant, setIsModal } = useContext(Store);
    const [selectedGroup, setSelectedGroup] = useState<DocumentGroup>();
    const close = () => {
        setIsModal && setIsModal!({ ...CloseAllModal });
    };

    const handleGroupSelection = (item: DropDownOptionItem) => {
        const selectedGroup = documentGroups.find(d => d.id === item.id);
        setSelectedGroup(selectedGroup);
    };

    return (
        <ModalGlobal modaltitle={`Move (${documents.length}) Document(s)?`} isModalHeader={true} modalClassName="overflow-visible" modalClose={() => {
            close();
            onClose();
        }}>
            <div className={`${classes.move_modal_body} ${classes.flex_column}`}>
                <p>Please select a new destination document group for the following {selectedPlant.name} document(s):</p>
                <ul>
                    {documents.map(doc => (
                        <li key={doc.globalId}>{doc.displayFileName}</li>
                    ))}
                </ul>
                <div className="flex flex-row items-center">
                    <span className="basis-1/3">Move From</span>
                    <PlainDropDown options={[]}
                        defaultText={documentGroupName}
                        className="pointer-events-none w-full bg-neutral rounded"
                        selectClass="py-3 pr-2 pl-4 h-12 flex flex-row justify-between items-center"
                        onSelection={() => { }} />
                </div>
                <div className="flex flex-row items-center">
                    <span className={`basis-1/3`}>Move To*</span>
                    <PlainDropDown
                        options={documentGroups.filter(d => d.name !== documentGroupName).map(d => pick(d, ['id', 'name']))}
                        // value={selectedGroup?.id}
                        // style={{ paddingRight: "7px" }}
                        // defaultText="Select destination document group"
                        // className={`${classes.move_select_container} ${!!selectedGroup?.id ? classes.move_selected : classes.move_placeholder}`}
                        // selectClass={`${classes.flex_row} ${classes.move_select}`}
                        // optionsClass={`${classes.flex_column} ${classes.move_select_options}`}
                        // onSelection={handleGroupSelection}

                        value={selectedGroup?.id}
                        className={`${classes.select_container} ${!!selectedGroup?.id ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select} pl-4`}
                        optionsClass={`${classes.flex_column} max-w-[300px] sm:max-w-[384px] md:max-w-[460px] ${classes.move_select_options}`}
                        // options={[{ id: false, name: 'Select destination document group' }, ...documentGroupOptions]}
                        onSelection={handleGroupSelection} 
                    />
                </div>
                <div className={`${classes.flex_row} ${classes.move_document_footer}`}>
                    <div className={`${classes.flex_row} ${classes.move_document_footer_left}`}>
                        <Button className={`darkBlue`} disabled={!selectedGroup} text={"Move Documents"} onClick={() => {
                            close();
                            if (selectedGroup) {
                                move(documentGroupName, selectedGroup);
                            }
                        }} />
                        <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => {
                            close();
                            onClose();
                        }} />
                    </div>
                    <span>*Required Fields</span>
                </div>
            </div>
        </ModalGlobal>
    );
}

export default MoveDocumentModal;