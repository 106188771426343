import React, { useContext } from "react";
import { Store } from "../../Store";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import RelatedPnSinformation from "../../components/PartNServices/RelatedPnSinformation";

function PartsAndServiceContainer() {
  return (
    <div className="column flex-grow-1">
      <div className="content">
        <Outlet />
        <div>
          <RelatedPnSinformation />
        </div>
      </div>
      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
}

export default PartsAndServiceContainer;
