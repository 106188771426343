import React, { useEffect, useContext, useState } from "react";
import { Store } from "../../../Store";
import "./MobileMenuHeader.css";
import ImageImports from "../../../utils/ImageImports";
import { useNavigate, useParams } from "react-router-dom";
import SearchGlobal from "../../Search/SearchGlobal";

function MobileMenuHeader() {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [text, settext] = useState<string>("");
  const { menuCloseIcon, logo, magnifyGlass, closeImg } = ImageImports;
  const stateContext = useContext(Store);
  const { mobileNavToggle, toggleMobileNav } = stateContext;
  const { searchText: urlData } = useParams();

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onCickSearch = () => {
    handleApiSearch();
  };
  const onSubmitSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleApiSearch();
  };
  const clearSearch = () => {
    setSearchText("");
  };

  function handleApiSearch() {
    navigate(`search/${searchText}`);
    toggleMobileNav && toggleMobileNav(!mobileNavToggle);
  }

  useEffect(() => {
    mobileNavToggle ? document.body.classList.add("overflow-hidden"): document.body.classList.remove("overflow-hidden");
  }, [mobileNavToggle]);

  return (
    <>
      <div className="navbar">
        <div className="nav-item">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="nav-item">
          <div className="nav__action_wrp">
            <div
              className="nav__action"
              onClick={() => {
                toggleMobileNav?.(!mobileNavToggle);            
              }}
            >
              <img src={menuCloseIcon} alt="open menu" className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>

      <div className="input-box keysearch">
        <div className="search__icon">
          <img src={magnifyGlass} className="" onClick={onCickSearch} alt="search" />
        </div>
        <form style={{ width: "100%" }} onSubmit={onSubmitSearch}>
          <input className="form-control" name={text} value={searchText} id="searchStr" onChange={handleSearchText} placeholder={"Keyword Search"} />
        </form>
        <div className="close__icon" onClick={clearSearch}>
          <img src={closeImg} className="" alt="close" />
        </div>
      </div>
    </>
  );
}

export default MobileMenuHeader;
