
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button"
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports"
import { AuthorizationContext } from '../../components/Auth/AuthorizationContext';

const { logo, dangerIcon } = ImageImports

const maxRetryAttempts = 5;

export const NoPortalAccess = () => {
    const { recheckPortalAccess, hasCheckedForPortalAccess } = useContext(AuthorizationContext);
    const [retryAttempts, setRetryAttempts] = useState<number>(0);

    useEffect(() => {
        const interval: NodeJS.Timer|undefined = retryAttempts < maxRetryAttempts? setTimeout(() => {
            if (retryAttempts < maxRetryAttempts) {
                setRetryAttempts(attempts => ++attempts);
                recheckPortalAccess();
            } else {
                clearTimeout(interval);
            }
        }, 4000) : undefined;
        return () => clearTimeout(interval);
    }, [retryAttempts]);

    const navigate = useNavigate();
    const {isMobile} = useWindowSize();
    return <div className="absolute w-full h-full bg-primary-20 flex justify-center flex-row">
        <div className={`w-full md:w-[392px] bg-white self-center p-8 ${hasCheckedForPortalAccess ? '' : 'hidden'}`}>
            <div className="flex flex-col items-start">
                <img className="w-[130px] h-[32px]" src={logo} alt="Mitsubishi Power" />
                <div className="pt-10 pb-6 gap-1 flex flex-row items-start">
                    <img className="h-8 w-8" src={dangerIcon} alt="Forbidden" />
                    <span className="text-error-800 text-base">
                        Apologies, it looks like you don&apos;t have access to this portal. To request access, please submit a Portal Access Request Form below.
                    </span>
                </div>
                <div className={`gap-[9px] pt-6 flex justify-center items-start self-stretch ${isMobile? 'flex-col' : 'flex-row'}`}>
                    <Button className="bg-dark-blue darkBlue" text={'Request Access'} onClick={() => navigate('/portal-access')} />
                    <Button className="whiteBtn" text={'Cancel'} onClick={() => navigate('/splash')} />
                </div>
            </div>
        </div>
    </div>
}