import { useContext, useMemo } from "react";
import { Store } from "../Store";


/**
 * An Internal Admin also refered to as Portal Admin is a user who has both the MPInternal flag AND PortalAdmin flag set to true in the users table. 
 * This SHOULD NOT return users who ONLY have the portalAdmin flag true.
 * 
 * In a real world situation an "external user" (MPInternal=false) will never be a Portal Admin.
 * 
 */
export const useIsInternalAdmin = () => {
  const {header: {data: {user: {mpInternal, portalAdmin}}}} = useContext(Store);

  const isInternalAdmin = useMemo(() => {
      return mpInternal && portalAdmin;
  }, [mpInternal, portalAdmin]);

  return isInternalAdmin;
};