import useWindowSize from './useWindowSize';

const useHeaderHeight = (AppHeaderHeight:React.RefObject<HTMLDivElement>) =>{
  
    const {isMobile,isTablet,isDesktop} = useWindowSize();
    let headerHeight ;
    let headerHeightPadding;

    if(isDesktop){
        headerHeight = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight + 48;
        headerHeightPadding = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight + 48;
     }else if(isTablet){
       headerHeight = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight;
       headerHeightPadding = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight + 20;
     }else if(isMobile){
       headerHeight = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight;
       headerHeightPadding = AppHeaderHeight.current && AppHeaderHeight.current.clientHeight + 16;
     }

   return {headerHeight,headerHeightPadding}
}

export default useHeaderHeight;