import { MainContent } from "../MainContent/MainContent";
import { PlantManagementList } from "./PlantManagementList";


export const PlantListContainer = () => {
    return (
        <MainContent title="Plant Management" breadCrumbs={['Administration', 'Plant Management']}>
            <PlantManagementList />
        </MainContent>
    );
};