import React from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import Button from '../Button/Button';
import { useNavigate } from "react-router-dom";

import './RelatedLinks.css'

interface RelatedLinksProps {
  data: {
    relatedTitle: string
    relatedSections: Array<{
      title: string,
      description: string,
      buttonLink: string,
      route: string
    }>
  }
}

function RelatedLinks(props: RelatedLinksProps) {
  const navigate = useNavigate();
    const {isMobile, isTablet} = useWindowSize();

	return (
    <div className='relatedLinks'>
      <div className='relatedLinks_header'>
        {props.data.relatedTitle}
      </div>
      <div className='relatedLinks_content' style={{flexFlow: (isMobile || isTablet) ? 'column' : 'row'}}>
        {
          props.data.relatedSections.map((obj, i) => {
            return (
              <div className='column relatedLinks_item' key={i} style={{flexGrow: 1, flexBasis: 0}}>
                <div className='relatedLinks_title'>{obj.title}</div>
                <div className='relatedLinks_paragraph'>{obj.description}</div>
                <div className=''>
                  <Button className="whiteBtn" text={obj.buttonLink} onClick={() => navigate(obj.route)} />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>

  );
}

export default RelatedLinks;
