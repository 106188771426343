import {
  PlantContactDTO,
  ContactFormInterface,
  deleteInterface,
  CPApiResponse,
  PlantContact,
} from "../../utils/interface";
import {
  apiPlantContacts,
  apiPlantContact,
  apiPlantContactGetAvatar,
} from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";

export interface PlantContactCrudMethods<T,TD,RD,D> {
  get: (PlantContactId: number) => Promise<T>;
  getImage: (PlantContactId: number) => Promise<string>;
  create: (data: TD) => Promise<RD>;
  update: (data: TD) => Promise<RD>;
  delete: (PlantContactId: number) => Promise<D>;
}

export const PlantContactApi: PlantContactCrudMethods<AxiosResponse<PlantContactDTO>, ContactFormInterface,PlantContactDTO,deleteInterface> = {
  get: async (PlantContactId : number): Promise<AxiosResponse<PlantContactDTO>> => {
    const response = await axios({
      url: `${apiPlantContact}/${PlantContactId}`,
      method: "GET",      
    });
    return response.data;
  },
  create: async (data: ContactFormInterface): Promise<PlantContactDTO> => {
    const response = await axios({
      url: `${apiPlantContact}`,
      method: "POST",
      params: {
        PlantId: data.plantId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  update: async (data: ContactFormInterface): Promise<PlantContactDTO> => {
    const response = await axios({
      url: `${apiPlantContact}/${data.PlantContactId}`,
      method: "PUT",    
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  delete: async (PlantContactId:number): Promise<deleteInterface> => {
    const response = await axios({
      url: `${apiPlantContact}/${PlantContactId}`,
      method: "DELETE",     
    });
    return response.data;
  },
  getImage: async (PlantContactId: number): Promise<any> => {
    const response = await axios({
      url: `${apiPlantContactGetAvatar}/${PlantContactId}`,
      method: "GET",   
      responseType: "blob",
    }); 

    return response.data;    
  },
};


export const useGet_PlantContact = (PlantContactId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.plantContact, PlantContactId], () => PlantContactApi.get(PlantContactId), {
    enabled: !!PlantContactId,
    onSuccess,
    onError,
  });
};

declare type GetAllPlantContactsParameters = ['plantContacts', {plantId: number}];
const getAll = async ({queryKey}: QueryFunctionContext<GetAllPlantContactsParameters>): Promise<PlantContact[]> => {
  const [_key, {plantId}] = queryKey;
  const response: AxiosResponse<CPApiResponse<PlantContact[]>> = await axios({
    url: `${apiPlantContacts}`,
    method: "GET",
    params: {
      PlantId: plantId,
    },
  });
  return handleAxiosResponse(response);
};
export const useGetAll_PlantContact = (plantId: number, onSuccess: any, onError: any) => {
  return useQuery(['plantContacts', {plantId}], getAll, {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};
export const useAvatar_PlantContact = (isFetch:boolean,PlantContactId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.plantContactAvatar, PlantContactId], () => PlantContactApi.getImage(PlantContactId), {
    enabled: isFetch,
    onSuccess,
    onError,
  });
};

export const useCreate_PlantContact = () => {
  return useMutation(PlantContactApi.create);
};

export const useUpdate_PlantContact = () => {
  return useMutation(PlantContactApi.update);
};

export const useDelete_PlantContact = () => {
  return useMutation(PlantContactApi.delete);
};