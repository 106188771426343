import React, { useContext, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Store } from "../../Store";
import { useGetFileContents } from "../../customHooks/FileHttpServices";
import { apiUgFile } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import ErrorCard from "../ErrorCard/ErrorCard";
import Loader from "../Loader/Loader";
import classes from "./FileReader.module.css";

function FileReader() {
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const { setShowError, showError, selectedUserGroup } = useContext(Store);
  const navigate = useNavigate();

  const { fileId, fileURI, globalItemId, staticDocumentGlobalItemId } = useParams();
  const [searchParams] = useSearchParams();
  const ugSearchParam = searchParams.get('userGroupId');

  let url = `${apiUgFile}/DisplayFileByFileId/${fileId}`;
  let downloadUrl = `${apiUgFile}/DownloadFileByFileId/${fileId}`
  if (fileURI) {
    url = decodeURIComponent(fileURI);
  }
  if (globalItemId) {
    url = `${apiUgFile}/DisplayFile/${globalItemId}`;
    downloadUrl = `${apiUgFile}/DownloadFile/${globalItemId}`;
  }
  if (staticDocumentGlobalItemId) {
    url = `${apiUgFile}/DownloadStaticFile/${staticDocumentGlobalItemId}`;
  }

  const {isLoading} = useGetFileContents(url, {
    enabled: !!url && (!ugSearchParam || selectedUserGroup.id === parseInt(ugSearchParam)),
    onSuccess: async (response) => {
      if(response.headers["content-type"].indexOf("application/json") > -1) {
        const permissionResp = JSON.parse(await response.data.text());
        if (!permissionResp.success) {
          setFileErrorMessage('There was an error loading the requested file.');
          return;
        }
      }
      
      let contentType = response.headers["content-type"];
      // Migrated files appear to be zip files
      if (contentType === 'application/x-7z-compressed') {
        setFileErrorMessage('The file you requested is not a viewable file. Please try downloading it.');
      }

      if (globalItemId && !isNaN(+globalItemId)) {
        
        queryClient.invalidateQueries(queryKeys.header);
        queryClient.clear();
      }

      window.location.replace(URL.createObjectURL(response.data));
      navigate('/', {
        state: {
          successMessage: 'Browser is unable to display document preview. Document has been downloaded.'
        }
      });
    },
    onError: (error) => {
      setShowError &&
        setShowError({
          title: error.message,
          isError: true,
          ErrorType: "danger",
        });
        setFileErrorMessage('The file you requested can not be found');
    }
  })

  return (
    <>
      <div className="content flex w-full">
        {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={showError.ErrorType} />}
        <div
          className={` ${classes.card}`}
          style={isLoading ? { display:"flex",height: "400px", justifyContent: "center", padding: "25px" } : { padding: "25px", minHeight: "100px", display: "block" }}
        >
          {isLoading ? <Loader /> : showError.isError ? <p>{fileErrorMessage}</p> : <></>}
        </div>
      </div>
    </>
  );
}
export default FileReader;
