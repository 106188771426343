
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { usePermissions } from '../../customHooks/useUserPermissions';
import useWindowSize from '../../customHooks/useWindowSize';
import { useStore } from '../../useStore';
import ImageImports from '../../utils/ImageImports';
import { ModuleNames } from '../../utils/interface';
import classes from './Documents.module.css';

const {gear} = ImageImports;

interface HeaderButtonsInterface {
    addButtonText: string;
    customClass: string;
    addButtonClass: string;
    manageButtonClass: string;
    groupPermissionModule: ModuleNames|ModuleNames[];
    permissionModule: ModuleNames|ModuleNames[];
}

function HeaderButtons(props: HeaderButtonsInterface) {
  const navigate = useNavigate();
  const {isMobile, isTablet} = useWindowSize();
  const location = useLocation();
  const {hasOnePermission} = usePermissions();
  const { 
    header: {
      data: { user }
    }
  } = useStore();

  return (
    <div className={` w-full lg:w-auto ${classes.header_buttons} ${isMobile? classes.flex_column : classes.flex_row} ${props.customClass}`}>
        {(hasOnePermission(props.groupPermissionModule, ['admin','add','edit']) || user.portalAdmin) && 
          <Button className={`whiteBtn mb-0 manage-button ${props.manageButtonClass}`} text={<div className={classes.manageButtonImage}><img src={gear} alt="Manage Document Groups" /> Manage Document Groups</div>} onClick={() => navigate('./manage-groups')} />
        }
        {hasOnePermission(props.permissionModule, 'add') && <Button className={`darkBlue mb-0 add-button ${props.addButtonClass}`} text={props.addButtonText} onClick={() => navigate(`${location.pathname}/add`)} style={{ order: (isMobile || isTablet) ? '-1' : 'unset' }} />}
    </div>
  );
}

HeaderButtons.defaultProps = {
    addButtonText: "Add Document",
    customClass: '',
    addButtonClass: '',
    manageButtonClass: ''
};

export default HeaderButtons;