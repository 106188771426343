import React, { FunctionComponent, ReactElement, CSSProperties } from "react";
// import { Link } from 'react-router-dom';
import "./Button.css";
import clsx from "clsx";
import ImageImports from "../../utils/ImageImports";

const {spinner} = ImageImports;

interface buttonProps {
  className: string;
  style?: CSSProperties;
  textClassName?: string;
  text: string|ReactElement;
  preImg?: ReactElement;
  img?: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit";
  showLoading?: boolean;
}



const Button: FunctionComponent<buttonProps> = ({showLoading = false, textClassName = '', ...props}: buttonProps) => {
  return (
    <button
      disabled={props.disabled}
      type={props.type}
      className={"primaryBtn " + props.className}
	  style={props.style} 
      onClick={(e) => {
        e.preventDefault();
        props.onClick && props.onClick();
      }}
    >
      {props.preImg && props.preImg} {" "}
      <span className={clsx({
        [textClassName]: textClassName.length > 0,
        invisible: showLoading
      })}> {props.text}</span>
      {showLoading && <img src={spinner} style={{
        position: 'absolute',
        height: '20px'
      }} alt="loading"/>}
      {props.img && props.img}
    </button>
  );
}

Button.defaultProps = {
  type: "button"
}

export default Button;
