import { useFormikContext } from "formik";
import { useState } from "react";
import DatePickerField from "../UI/BasicForm/DatePickerField/DatePickerField";
import RadioButtonsGrouped from "../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped";
import SelectField from "../UI/BasicForm/SelectField/SelectField";
import TextAreaField from "../UI/BasicForm/TextAreaField/TextAreaField";
import TextInputField from "../UI/BasicForm/TextInputField/TextInputField";
import classes from "./QuoteRequestForm.module.css";
import QuoteRequestFormStepThree from "./QuoteRequestFormStepThree";

import dayjs, { Dayjs } from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetAll_OutageSeasons,
  useGetAll_PartsRequestReasons,
  useGetAll_RequestPriority
} from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { RequestQuoteFormInterface } from "../../utils/interface";
import YearPickerField from "../UI/BasicForm/DatePickerField/YearPickerField";

const QuoteRequestFormStepTwo = (props: stepTwoInterface) => {
  const { values, submitForm, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [requestPriority, setRequestPriority] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a priority" }]);
  const [partsRequestReasons, setPartsRequestReasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a reason" }]);
  const [outageSeasons, setOutageSeasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a outage seasons" }]);

  const handleOnchange = (date: Date) => {
    setStartDate(date);
    setFieldValue("quoteRequest.outageYear", date.getFullYear());
  };

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (requestPriority: { data: [{ id: number; order: number; title: string }] }) => {
    setRequestPriority(
      requestPriority.data.map((option: { id: number; order: number; title: string }) => ({ id: option.id, name: option.title.toLowerCase() }))
    );
  };
  const onErrorRP = (error: any) => {
    setRequestPriority([]);
  };
  useGetAll_RequestPriority(onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessPRR = (partsRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setPartsRequestReasons(
      partsRequestReasons.data.map((option: { reasoningId: number; name: string; description: string }) => ({
        id: option.reasoningId,
        name: option.name.toLowerCase(),
      }))
    );
  };
  const onErrorPRR = (error: any) => {
    setPartsRequestReasons([]);
  };
  useGetAll_PartsRequestReasons(onSuccessPRR, onErrorPRR);
  // Api fetch end-------------------------------------------------------//
  // Api fetch start-----------------------------------------------------//
  const onSuccessOS = (partsRequestReasons: { data: [{ outageSeasonId: number; name: string; description: string }] }) => {
    setOutageSeasons(
      partsRequestReasons.data.map((option: { outageSeasonId: number; name: string; description: string }) => ({
        id: option.outageSeasonId,
        name: option.name.toLowerCase(),
      }))
    );
  };
  const onErrorOS = (error: any) => {
    setOutageSeasons([]);
  };
  useGetAll_OutageSeasons(onSuccessOS, onErrorOS);
  // Api fetch end-------------------------------------------------------//

  return (
    <>
      <div>
        <TextAreaField name="quoteRequest.description" height={64} labelName="Request Description*" placeholder="Description" className=""  />
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField defaultValue={0} options={requestPriority} name={"quoteRequest.quotePriorityId"} labelName={"Quote Turnaround Priority*"} />
        </div>
        <div className={classes.flexCol}>
          <TextInputField
            type="date"
            name="quoteRequest.quoteNeededDate"
            labelName="Quote Needed Date*"
            className=""
            placeholder="Quote Needed Date"
          />
        </div>
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField defaultValue={0} options={requestPriority} name={"quoteRequest.partsPriorityId"} labelName={"Parts Delivery Priority*"} />
        </div>
        <div className={classes.flexCol}>
          <TextInputField
            type="date"
            name="quoteRequest.partsNeededDate"
            labelName="Parts Needed Date*"
            className=""
            placeholder="Quote Needed Date"
          />
        </div>
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            options={partsRequestReasons}
            name={"quoteRequest.partsRequestReasoningId"}
            labelName={"Parts Request Reasoning*"}
          />
        </div>
        <div className={classes.flexCol}></div>
      </div>
      <div className={classes.flexCol}>
        <div>
          <RadioButtonsGrouped
            name="quoteRequest.isPlannedOutage"
            display="block"
            firstRadioName="Yes"
            firstRadioValue="true"
            SecondRadioName="No"
            SecondRadioValue="false"
            labelName="Is this quote related to a planned outage?*"
            className=""
            showTooltip={true}
          />
        </div>
      </div>
      {values.quoteRequest.isPlannedOutage === "true" && (
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <SelectField defaultValue={2} options={outageSeasons} name={"quoteRequest.outageSeasonId"} labelName={"Outage Season*"} />
          </div>
          <div className={classes.flexCol}>
            {/* <DatePickerField
              name="quoteRequest.outageYear"
              dateFormat={"yyyy"}
              handleOnchange={handleOnchange}
              setStartDate={setStartDate}
              startDate={startDate}
              labelName="Outage Year*"
              className=""
              placeholder="Quote Needed Date"
            /> */}
            <YearPickerField
              name="quoteRequest.outageYear"
              dateFormat={"yyyy"}
              handleOnchange={handleOnchange}
              setStartDate={setStartDate}
              startDate={startDate}
              labelName="Outage Year*"
              className=""
              placeholder="Select Outage Year"
            />
          </div>
        </div>
      )}

      <hr />
      <QuoteRequestFormStepThree handleSubmitNew={props.handleSubmitNew} />
    </>
  );
};

interface stepTwoInterface {
  handleSubmitNew?(): void;
}

export default QuoteRequestFormStepTwo;
