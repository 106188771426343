import { useContext } from "react";
import Alert from "../../components/Alert/Alert";
import { AnnouncementContainer } from "../../components/Announcements/AnnouncementContainer";
import { GroupDescription } from "../../components/HomePage/GroupDescription";
import { Links } from "../../components/HomePage/Links";
import { Officers } from "../../components/HomePage/Officers";
import { Store } from "../../Store";
import { MainContent } from "../MainContent/MainContent";
import { HomeComponentProps } from "./Home";

export const HomeWithGroup = ({successMessage, clearSuccessMessage}: HomeComponentProps) => {
    const {selectedUserGroup} = useContext(Store);
    return (
        <MainContent title={`${selectedUserGroup.name} Users' Group`}>
          <div className="flex flex-col items-start gap-10">
            {successMessage && (
              <div className="mb-2 w-full">
                <Alert type="success" onClose={clearSuccessMessage}>
                  <span>
                    {successMessage}
                  </span>
                </Alert>
              </div>
            )}
            <AnnouncementContainer />
            <GroupDescription />
            <div className="flex flex-col items-start gap-6 w-full">
                <span className="font-bold text-[28px]">Resources</span>
                <div className="flex flex-col lg:flex-row !pb-0 items-stretch gap-6 self-stretch">
                    <Officers />
                    <Links />
                </div>
            </div>
          </div>
        </MainContent>
      );
}