import React, { useContext, useMemo } from "react";
import { Store } from "../../../Store";
import ImageImports from "../../../utils/ImageImports";
import classes from "./PlantSiteDetailsTop.module.css";
import PlantSiteDataBlock from "../PlantSiteDataBlock/PlantSiteDataBlock";
import useWindowSize from "../../../customHooks/useWindowSize";
import { useIsFetching } from "react-query";
import Skeleton from "react-loading-skeleton";
import { queryKeys } from "../../../react-query/queryKeys";
import { toBase64 } from "../../../utils/FileUtils";
import { AxiosError } from "axios";
import { useGetFile_PlantConfigData } from "../../../containers/PlantInformation/PlantConfigHttpServices";
import { useSelectedPlant } from "../../../customHooks/useSelectedPlant";
import { PlantConfigData } from "../../../utils/interface";

const { linkout } = ImageImports;

const PlantSiteDetailsTop = ({selectedPlantConfig}: {selectedPlantConfig?: PlantConfigData}) => {
  const {
    plantConfigImage,
    setPlantConfigImage,
    plantConfig: {
      data: { websiteURL, owner, address1, address2, address3, frequency, mhiRegion, notesComments, file, operators, lastOutage, nextPlannedOutage },
    },
  } = useContext(Store);
  const { isDesktop, isTablet, isMobile } = useWindowSize();
  const isFetching = useIsFetching([queryKeys.plantSiteDetails]);
  const {selectedPlant: selectedPlantFromContext} = useSelectedPlant();

  let ImageStyle;

  const selectedPlant = useMemo(() => {
    return selectedPlantConfig? selectedPlantConfig : selectedPlantFromContext;
  }, [selectedPlantConfig, selectedPlantFromContext]);

  if (selectedPlant?.plantId) {
    ImageStyle = {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${plantConfigImage.file})`,
    };
  }

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData //
  ///////////////////////////////////////////

  const onSuccessPlantConfigImage = (data: any) => {
    toBase64(data)
      .then((base64Image: any) => {
        setPlantConfigImage &&
          setPlantConfigImage({
            file: base64Image,
          });
      })
      .catch((error) => {
        setPlantConfigImage &&
          setPlantConfigImage({
            file: "",
          });
      });
  };
  const onErrorPlantConfigImage = (error: AxiosError<string>) => {
    setPlantConfigImage &&
      setPlantConfigImage({
        file: "",
      });
  };

  const { isLoading: isLoadingImage } = useGetFile_PlantConfigData((selectedPlant?.plantId || 0), onSuccessPlantConfigImage, onErrorPlantConfigImage, (selectedPlant?.baseOrgId || 0));

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData End//
  ///////////////////////////////////////////

  const plantdetailsjsx = {
    address: <PlantSiteDataBlock label={"Address"} value1={address1} value2={address2} value3={address3} />,
    operators: <PlantSiteDataBlock label={"Operator"} value1={operators} />,

    frequency: <PlantSiteDataBlock className={frequency? '' : 'text-error-800'} label={"Frequency"} value1={frequency ? frequency : 'Unavailable'} />,
    lastPlannedOutrage: (
      <PlantSiteDataBlock className={lastOutage? '' : 'text-error-800'} label={"Last Planned Outage"} value1={lastOutage ? lastOutage : "Unavailable"} />
    ),
    nextPlannedOutrage: (
      <PlantSiteDataBlock
        className={nextPlannedOutage? '' : 'text-error-800'}
        label={"Next Planned Outage"}
        value1={nextPlannedOutage ? nextPlannedOutage : "Unavailable"}
      />
    ),
    owner: <PlantSiteDataBlock label={"Owner"} value1={owner} />,
    mhiRegion: <PlantSiteDataBlock label={"MHI Region"} value1={mhiRegion} />,
    notes: <PlantSiteDataBlock label={"Notes"} value1={notesComments} />,
  };

  return (
    <>
      <section>
        <div className={classes.tblCol1}>
          <div className={classes.tblCell}>
            <div className={classes.plName}>
              <h2>{selectedPlant?.baseOrgName}</h2>  
            </div>
          </div>
        </div>
      </section>
      {isDesktop && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
              </div>
            </div>
            <div className={classes.tblCell}>
              <div>{plantdetailsjsx.frequency}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.nextPlannedOutrage}</div>
            </div>
            <div className={classes.tblCell}>
              <div className="row-to-col  font14 flex-grow-1">
                {isFetching > 0 ? (
                  <Skeleton height={56} />
                ) : (
                  <a style={{ color: "#666", textDecoration: "none" }} href={websiteURL} target="_blank" rel="noreferrer noopener">
                    <div className={classes.flex}>
                      <div className={classes.iconWrapper}>
                        <img src={linkout} className="plt-icon" alt="" />
                      </div>
                      <b className="header flex-grow-1">View Website</b>
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
              <div>{plantdetailsjsx.address}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
      {isTablet && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                <div className={classes.mapWrp}>
                  {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
                </div>
              </div>
            </div>
            <div className={classes.tblCell}>
              <div>{plantdetailsjsx.frequency}</div>
            </div>
          </div>
          {isFetching > 0 ? (
            <Skeleton height={56} style={{ marginBottom: "24px" }} />
          ) : (
            <>
              <div className={classes.tbl}>
                <div className={classes.tblCell}>
                  <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
                  <div>{plantdetailsjsx.nextPlannedOutrage}</div>
                </div>
                <div className={classes.tblCell}>
                  <div className="row-to-col  font14 flex-grow-1">
                    <a style={{ color: "#666", textDecoration: "none" }} href={websiteURL} target="_blank" rel="noreferrer noopener">
                      <div className={classes.flex}>
                        <div className={classes.iconWrapper}>
                          <img src={linkout} className="plt-icon" alt="" />
                        </div>
                        <b className="header flex-grow-1">View Website</b>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={`${classes.tbl} ${classes.half}`}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
            </div>
            <div className={classes.tblCell}>
              <div>{plantdetailsjsx.address}</div>
            </div>

            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                <div className={classes.mapWrp}>
                  {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
                </div>
              </div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.frequency}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.nextPlannedOutrage}</div>
            </div>
        
            <div className={classes.tblCell}>
              <div className="row-to-col  font14 flex-grow-1">
                <div className={` ${classes.marginBottom24}`}>
                  <a style={{ color: "#666", textDecoration: "none" }} href={websiteURL} target="_blank" rel="noreferrer noopener">
                    <div className={`${classes.flex}`}>
                      <div className={classes.iconWrapper}>
                        <img src={linkout} className="plt-icon" alt="" />
                      </div>
                      <b className="header flex-grow-1">View Website</b>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.address}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PlantSiteDetailsTop;
