import React, { useContext, useEffect, useState } from "react";
import { DataWithPermissions, DiscussionGroupInterface, DiscussionPermissionedInterface, DocumentGroup, PlantPermissionedReports, Report } from "../../../utils/interface";
import classes from "./ManageDocumentGroups.module.css";
import ImageImports from "../../../utils/ImageImports";
import DeleteGroupConfirmationModal from "./DeleteGroupConfirmationModal";
import Button from "../../../components/Button/Button";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useOutletContext } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";
import { useQuery } from "react-query";
import { Store } from "../../../Store";

const { pencil, dragReorder } = ImageImports;

interface ReportTypeListItemProps {
  reportType: DiscussionGroupInterface;
  onSave: (reportType: DiscussionGroupInterface) => void;
  onDelete: (reportType: DiscussionGroupInterface) => void;
  editToggled: (isEditing: boolean) => void;
}

function ReportTypeListItem({ reportType, editToggled, onSave,  onDelete }: ReportTypeListItemProps) {
  const [documents, setDocuments] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();
  const [name, setName] = useState<string>("");
  const [showDeleteGroupConfirmationModal, setShowDeleteGroupConfirmationModal] = useState<boolean>(false);
  const getReport = documentModule.documentApi.getAll;
  const { plantConfig } = useContext(Store);

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: reportType.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const cancelEdit = () => {
    setName(reportType.name);
    closeEdit();
  };

  const closeEdit = () => {
    setIsEditing(false);
    editToggled(false);
  };

  const startEditing = () => {
    setIsEditing(true);
    editToggled(true);
  };

  const save = () => {
    onSave({ ...reportType, name: name.toLowerCase() });
    closeEdit();
  };

  const deleteReportType = () => {
    setShowDeleteGroupConfirmationModal(false);
    closeEdit();
    onDelete(reportType);
  };

  useQuery(["Discussions", reportType.id], () => documentModule.documentApi.getAll(reportType.id), {
    onSuccess: (data: Report[]) => {
      setDocuments(data.length);
    },
    enabled: !!reportType.id,
  });

  useEffect(() => {
    setName(reportType.name);

  
    
  }, [reportType]);

  return (
    <div className={`${classes.report_type_list_item} ${classes.flex_row}`} ref={setNodeRef} style={style} {...attributes}>
      <div ref={setActivatorNodeRef} {...listeners} className={`${classes.move_icon} ${classes.flex_row}`}>
        <img src={dragReorder} alt="Reorder Report Groups" />
      </div>
      {showDeleteGroupConfirmationModal && (
        <DeleteGroupConfirmationModal
          shown={showDeleteGroupConfirmationModal}
          onClose={() => {
            setShowDeleteGroupConfirmationModal(false);
          }}
          onDelete={deleteReportType}
          reportType={reportType}
        />
      )}
      <div className={`${classes.report_type_inner} ${isEditing ? classes.flex_column : classes.flex_row}`}>
        <div className={`${classes.input_wrapper} ${classes.flex_column}`}>
          {isEditing && <span className={`${classes.edit_group_name} ${classes.flex_row}`}>{reportType.name}</span>}
          <input
            type="text"
            className={classes.flex_row}
            value={name}
            readOnly={!isEditing}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div className={classes.help_text}>
            {documents} discussion{documents === 1 ? "" : "s"}
          </div>
        </div>
        {isEditing ? (
          <>
            <div className={`${classes.edit_button_group} ${classes.flex_row}`}>
              <div className={`${classes.edit_left_button_group} ${classes.flex_row}`}>
                <Button className="darkBlue" text="Save Changes" onClick={save} disabled={reportType.name === name} />             
                <Button
                  className="redDelete"
                  text="Delete Discussion Category"
                  disabled={documents > 0}
                  onClick={() => setShowDeleteGroupConfirmationModal(true)}
                />
              </div>
              <Button className="whiteBtn" text="Cancel" onClick={cancelEdit} />
            </div>
          </>
        ) : (
          <div className={`${classes.edit_icon}`} onClick={startEditing}>
            <img src={pencil} alt="Edit" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportTypeListItem;
