export const baseURL = process.env.REACT_APP_API_URL as string;
export const tbwApplicationItemId = Number(process.env.REACT_APP_TBW_APPLICATION_ITEM_ID || '0');

export const apiHeader = `${baseURL}/api/ins-usergroup-portal/Header`;
export const apiHeaderSearch = `${baseURL}/api/Header/Search`;

export const apiAccountSettings = `${baseURL}/api/AccountSettings`;


export const apiHealth = `${baseURL}/api/Health`;


export const apiPartsAndServices = `${baseURL}/api/Home/PartsAndServices`;
export const apiDocuments = `${baseURL}/api/Home/TechnicalDocumentation`;
export const apiIssueReports = `${baseURL}/api/Home/IssueReports`;
export const apiQuoteRequests = `${baseURL}/api/Home/QuoteRequests`;
export const apiPlantSiteDetails = `${baseURL}/api/Home/PlantSiteDetails`;

export const apiFile = `${baseURL}/api/File`;
export const apiUgFile = `${baseURL}/api/ins-usergroup-portal/File`;

export const apiPlantConfig = `${baseURL}/api/ins-usergroup-portal/PlantConfig`;
export const apiPlantConfigImage = `${baseURL}/api/ins-usergroup-portal/PlantConfig/GetFile`;
export const apiPlantConfigByUserGroup = `${baseURL}/api/ins-usergroup-portal/PlantConfig/ListByUserGroup`;


export const apiUnit = `${baseURL}/api/Unit`;
export const apiUnitGetAll = `${baseURL}/api/ins-usergroup-portal/Unit/GetAllByPlantId`;


export const apiPlantProfileTypes = `${baseURL}/api/PlantProfileType/GetAll`;
export const apiPlantFuelTypes = `${baseURL}/api/FuelType/GetAll`;
export const apiProductTypes= `${baseURL}/api/ProductType/GetAll`;
export const apiEquipmentAttachmentTypes = `${baseURL}/api/EquipmentAttachmentType/GetAll`;
export const apiAssociatedUnitTypes= `${baseURL}/api/AssociatedUnit/GetAll`;
export const apiManufacturerTypes = `${baseURL}/api/Manufacturer/GetAll`;
export const apiUnitConfigurationTypes = `${baseURL}/api/UnitConfiguration/GetAll`;
export const apiContractBasisTypes = `${baseURL}/api/ContractBasis/GetAll`;
export const apigetEquipmentModelNameTypes = `${baseURL}/api/EquipmentModel/GetAll`;

export const apiGetFAQ = `${baseURL}/api/FAQ/GetFAQ`;
export const apiUpdateFAQ = `${baseURL}/api/FAQ/UpdateFAQ`;

export const apigetAllQuoteRequestTypes = `${baseURL}/api/cpitem/quote-request/GetAllQuoteRequestTypes`;
export const apigetAllRequestPriority = `${baseURL}/api/RequestPriority/GetAll`;
export const apiGetAllOutageSeasons = `${baseURL}/api/cpitem/quote-request/GetOutageSeasons`;
export const apiGetAllPartsRequestReasons = `${baseURL}/api/cpitem/quote-request/GetPartsRequestReasons`;
export const apiGetAllQuoteTypes = `${baseURL}/api/cpitem/quote-request/GetAllQuoteTypes`;
export const apiquoterequest = `${baseURL}/api/cpitem/quote-request`;
export const apiquoterequestCreateAttachment = `${baseURL}/api/cpitem/quote-request/CreateAttachment`;


export const apiPlantEquipment= `${baseURL}/api/PlantEquipment`
export const apiGetAllEquipmentsByPlantID= `${baseURL}/api/PlantEquipment/GetAllByPlantId`
export const apiGetAllEquipmentsByUnitID= `${baseURL}/api/ins-usergroup-portal/PlantEquipment/GetAllByUnitID`
export const apiGetAllPlantEquipmentsAttachments= `${baseURL}/api/EquipmentAttachment/GetAllByPlantEquipmentId`
export const apiEquipmentAttachment= `${baseURL}/api/EquipmentAttachment`
export const apiGetEquipmentAttachmentFile= `${baseURL}/api/EquipmentAttachment/GetFile`
export const apiSiteUnits = `${baseURL}/api/Unit`;

export const apiPlantContacts= `${baseURL}/api/ins-usergroup-portal/PlantContact/GetAllByPlantId`
export const apiPlantContact= `${baseURL}/api/ins-usergroup-portal/PlantContact`
export const apiPlantContactGetAvatar= `${baseURL}/api/ins-usergroup-portal/PlantContact/GetAvatar`


export const apiMitsubishiPowerReportType = `${baseURL}/api/MitsubishiPowerReportType`;
export const apiMitsubishiPowerReport = `${baseURL}/api/MitsubishiPowerReport`;
export const apiReportDownloadFile = `${baseURL}/api/ins-usergroup-portal/File/DownloadFile`;

export const apiGeneralDocuments = `${baseURL}/api/ins-usergroup-portal/general-document`;


export const apiItemTypeCatalog = `${baseURL}/api/ItemTypeCatalog`;
export const apiPartsCatalog = `${baseURL}/api/cpitem/parts-catalog`;

export const apiMessage = `${baseURL}/api/message-threads/Message`;
export const apiMessageThreads = `${baseURL}/api/message-threads/Thread`;
export const apiMessageAttachment = `${baseURL}/api/message-threads/MessageAttachment`;

export const omManualApiUrl = `${baseURL}/api/cpitem/om-manual`;
export const technicalBulletinApiUrl = `${baseURL}/api/cpitem/technical-bulletin`;

export const issueReportApiUrl = `${baseURL}/api/cpitem/issue-report`;
export const QuoteRequestApiUrl = `${baseURL}/api/cpitem/quote-request`;
export const getSearchResults = `${baseURL}/api/ins-usergroup-portal/Search/GetSearchResults`;

export const userApiUrl = `${baseURL}/api/roles-and-permissions/User`;
export const ugUserApiUrl = `${baseURL}/api/ins-usergroup-portal/User`;
export const apiEmailOptOut = `${baseURL}/api/EmailOptOut`;
export const ugBaseOrgApiUrl = `${baseURL}/api/ins-usergroup-portal/BaseOrg`;
export const reactivateUserApiUrl = `${baseURL}/api/roles-and-permissions/User/ReactivateUser`;
export const reactivateUgUserApiUrl = `${ugUserApiUrl}/ReactivateUser`;
export const apiRolesUrl = `${baseURL}/api/roles-and-permissions/Role`;
export const apiModulesUrl = `${baseURL}/api/roles-and-permissions/Module`;
export const apiRoleModulePermissionsUrl = `${baseURL}/api/roles-and-permissions/role-module-permission`;
export const controllerApiUrl = `${baseURL}/api/controller`;

export const KPIsApiUrl = `${baseURL}/api/ESR`;

export const updateNotificationsAsRead = `${baseURL}/api/Notification/BulkUpdateAsRead`;
export const updateItemsAsRead = `${baseURL}/api/Notification/BulkMarkAsRead`;
export const updateNotificationsAsDismissed = `${baseURL}/api/Header/BulkDismissNotifications`;
export const getNotificationTypesApiUrl = `${baseURL}/api/Notification/GetNotificationTypes`;


export const apiAnnouncementUrl = `${baseURL}/api/ins-usergroup-portal/announcement`;
export const apiRecordingsUrl = `${baseURL}/api/ins-usergroup-portal/recordinglibrary`;
export const apiTechnicalBulletinUrl = `${baseURL}/api/ins-usergroup-portal/technical-bulletin`;
export const orgGroupApiUrl = `${baseURL}/api/ins-usergroup-portal/OrgGroup`;
export const userGroupApiUrl = `${baseURL}/api/roles-and-permissions/UserGroup`;
export const apiLinkUrl = `${baseURL}/api/ins-usergroup-portal/link`;
export const apiPortalContactUrl = `${baseURL}/api/ins-usergroup-portal/portalcontact`;
export const apiPageContentUrl = `${baseURL}/api/ins-usergroup-portal/page-content`;

export const ugthreadcategory = `${baseURL}/api/cpitem/ugthreadcategory`;
export const getUgthread = `${baseURL}/api/cpitem/ugthread`;
export const getUgthreadMessage = `${baseURL}/api/ugthread/ug-message-threads/Message`;

export const userGroupApiEndpoint = `${baseURL}/api/roles-and-permissions/UserGroup`;

export const apiUGRolesUrl = `${baseURL}/api/ins-usergroup-portal/Role`;
export const apiURRoleModulePermissionsUrl = `${baseURL}/api/ins-usergroup-portal/role-module-permission`;

export const baseOrgRoleApiUrl = `${baseURL}/api/roles-and-permissions/user-baseorg-role`;
export const apiGenerateAccessRequestReportUrl = `${controllerApiUrl}/GeneratePortalAccessRequestReport`;
export const apiGenerateUserReportUrl = `${baseURL}/api/ins-usergroup-portal/BaseOrg/GenerateUserAccessReport`;

export const apiEmailTriggerUrl = `${baseURL}/api/role-and-permissions/EmailTrigger`;
export const apiCountryOfCitizenship = `${baseURL}/api/CountryOfCitizenship`;