import React, { useState, ReactElement } from "react";
import ImageImports from "../../utils/ImageImports";
import classes from "./ManageRoles.module.css";
import AddNewRoleModal from "./AddNewRoleModal";
import NewRoleConfirmationModal from "./NewRoleConfirmationModal";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import useWindowSize from "../../customHooks/useWindowSize";
import { Role } from "../../utils/interface";
import Alert from "../Alert/Alert";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { ManageRoleListItem } from "./ManageRoleListItem";

const { Arrow } = ImageImports;

interface ManageRolesParameters {
  onAdd: (name: string, mpInternal: boolean) => void;
  onDelete: (item: Role) => void;
  onEdit: (item: Role) => void;
  onReorder: (items: {id: number, displayOrder: number}[]) => void;
  items: Role[];
  backLabel: string;
  pageSubHeading: string;
  pageDescription: string|ReactElement;
  addButtonText: string;
}

export const ManageRoles = ({items, backLabel, pageSubHeading, pageDescription, addButtonText, onAdd, onDelete, onEdit, onReorder}: ManageRolesParameters) => {
  const { isMobile, isTablet } = useWindowSize();
  const [showAddNewRoleModal, setShowAddNewRoleModal] = useState<boolean>(false);
  const [selectedItemTypeCatalog, setSelectedItemTypeCatalog] = useState<Role>();
  const [openEdits, setOpenEdits] = useState<number>(0);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const createNewRole = (name: string, mpInternal: boolean) => {
    onAdd(name, mpInternal);
    setSelectedItemTypeCatalog({
      name,
      id: 0,
      description: '',
      displayOrder: 0,
      assignmentCount: 0,
      mpInternal: mpInternal
    });
  };

  const saveRole = (itemType: Role) => {
    onEdit(itemType)
  };

  const deleteRole = (itemType: Role) => {
    onDelete(itemType);
  };


  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(({ id }) => id === active.id);
      const newIndex = items.findIndex(({ id }) => id === over.id);
      let tmpTypes: Role[] = arrayMove(items, oldIndex, newIndex);

      // now we need to update the orders..
      // loop through report types and reassign orders
      for (let i = 0, displayOrder = 1; i < tmpTypes.length; i++, displayOrder++) {
        tmpTypes[i].displayOrder = displayOrder;
      }
      // update the database too
      onReorder(tmpTypes.map(({ id, displayOrder }) => ({ id, displayOrder })));
    }
  };

  return (
    <div
      className={`${classes.manage_document_groups} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""}`}
    >
      {showAddNewRoleModal && (
        <AddNewRoleModal shown={showAddNewRoleModal} onCreate={createNewRole} onClose={() => setShowAddNewRoleModal(false)} />
      )}
      {selectedItemTypeCatalog && (
        <NewRoleConfirmationModal
          shown={true}
          onClose={() => {
            setSelectedItemTypeCatalog(undefined);
          }}
          addNewRole={() => {
            setSelectedItemTypeCatalog(undefined);
            setShowAddNewRoleModal(true);
          }}
          role={selectedItemTypeCatalog}
        />
      )}
      <div className={`${classes.intro} ${classes.flex_column}`}>
        <Link className={`${classes.back_container} ${classes.flex_row}`} to="/administration/Role-Configuration">
          <img src={Arrow} className="back-arrow" alt={`Back to ${backLabel}`} />
          Back to {backLabel}
        </Link>
        <Alert onClose={() => {}} type={"warning"} dismissible={false}>
          Please note: any modifications made to roles below will be applied to all assignments across all groups in the Users’ Group Portal.
        </Alert>
        <div className={`${classes.section_title_cta} ${classes.flex_row}`}>
          <div className={`${classes.section_title}`}>
            <span className={`${classes.section_heading}`}>{pageSubHeading}</span>
            <span className={`${classes.section_description}`}>{pageDescription}</span>
          </div>
          <div className={`${classes.cta_container}`}>
            <Button className={`darkBlue`} disabled={openEdits > 0} text={addButtonText} onClick={() => setShowAddNewRoleModal(true)} />
          </div>
        </div>
      </div>

      <div className={`${classes.report_types_container} ${classes.flex_column}`}>
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext strategy={verticalListSortingStrategy} items={items}>
            {items.map((item) => (
              <ManageRoleListItem role={item}
                                key={item.id}
                                onSave={saveRole}
                                editToggled={(isEditing) => {
                                  isEditing ? setOpenEdits(openEdits + 1) : setOpenEdits(openEdits - 1);
                                }}
                                onDelete={deleteRole}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
