import React, { useContext, useEffect, useState, useMemo } from "react";
import { Store } from "../../Store";
import SiteContactAccordian from "../Accordian/SiteContactAccordian/SiteContactAccordian";
import classes from "./SiteContacts.module.css";
import { useGetAll_PlantContact } from "../../containers/PlantInformation/PlantContactHttpServices";

import { PlantContactsDTO } from "../../utils/interface";

import { AxiosError } from "axios";

import Skeleton from "react-loading-skeleton";
import { useSelectedPlant } from "../../customHooks/useSelectedPlant";
const SiteContacts = () => {
  const { setAccordian_tab} = useContext(Store);
  const {selectedPlant} = useSelectedPlant();

  //{***************************************************************************************************************************}

  const onSuccess = (data: PlantContactsDTO) => {
    setAccordian_tab?.(0);
  };
  const onError = (error: AxiosError<string>) => {
    setAccordian_tab?.(0);
  };
  const { isFetching, data: plantContacts } = useGetAll_PlantContact((selectedPlant?.plantId || 0), onSuccess, onError);

  //{***************************************************************************************************************************}

  const accordionData = useMemo(() => {
    if (plantContacts) {
      return [
        {
          id: 0,
          contact_type: "Mitsubishi Power Contacts",
          contact_list: plantContacts.filter(d => d.isMitsubishiContact),
        },
        {
          id: 1,
          contact_type: `${selectedPlant?.baseOrgName} Site Contacts`,
          contact_list: plantContacts.filter(d => !d.isMitsubishiContact),
        },
      ];
    } else {
      return [
        {
          id: 0,
          contact_type: "Mitsubishi Power Contacts",
          contact_list: []
        },
        {
          id: 1,
          contact_type: `${selectedPlant?.baseOrgName} Site Contacts`,
          contact_list: []
        }
      ]
    }
  }, [plantContacts]);


  return (
    <div className={classes.sitecontact}>
      <div className={classes.row}>
        <div className={classes.col}>
          <p>
          Internal and External Contacts
          </p>
        </div>       
      </div>
      
      {isFetching ? (
        <>
          <Skeleton height={56} style={{ marginBottom: "10px" }} />
          <Skeleton height={56} />
        </>
      ) : (
        <SiteContactAccordian panels={accordionData} />
      )}
    </div>
  );
};

export default SiteContacts;
