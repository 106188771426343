import { FunctionComponent, MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
import usePagination from "../../customHooks/usePagination";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from '../../utils/ImageImports';
import { FunctionOrType } from "../../utils/interface";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import Pill from "../Pill/Pill";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import { AccordionList } from "./AccordionList";
import { Pagination } from "./Pagination";
import { TableList } from "./TableList";

const { magnifyGlassBlack, filter, closeImg } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

export interface ItemListColumnDefinition<T> {
    title: string;
    key: keyof T;
    component?: FunctionComponent<{ data: T }>;
    className?: string;
}

// add default: true, to sortableProperty index
export interface SortablePropertiesDefinition<T> {
    key: keyof T;
    label: string;
    direction: 'asc' | 'desc';
    default?: boolean;
    manipulateFn?: (data: T[keyof T]) => any;
}
export type SortOptionsDefinition<T> = {
    [K in keyof T]: {
        id: number;
        key: K;
        name: string;
        direction: 'asc' | 'desc';
        default?: boolean;
        manipulateFn?: (data: T[K]) => any;
    }
}[keyof T];

export interface BulkItemActionDefinition<T> {
    text: string | ReactElement | FunctionComponent<{selectedItems: T[]}>;
    onClick: (event: MouseEvent, data: T[]) => void;
    disabled?: FunctionOrType<boolean, T[]>;
}

export interface ItemActionDefinition<T> {
    text: string | ReactElement;
    onClick: (event: MouseEvent, data: T) => void;
    disabled?: ((data: T) => boolean) | boolean;
    visible?: ((data: T) => boolean) | boolean;
}

export type PropertiesOfType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
export interface ItemListProps<T> {
    columns: ItemListColumnDefinition<T>[];
    data: T[];
    sortValues?: SortOptionsDefinition<T>[];
    bulkActions?: BulkItemActionDefinition<T>[];
    itemActions?: ItemActionDefinition<T>[];
    itemActionText?: FunctionComponent;
    deletedItem?: (((data: T) => boolean) | PropertiesOfType<T, boolean>);
    readItemKey?: PropertiesOfType<T, boolean>;
    accordionTitle?: (data: T) => string;
    filterContent?: ReactElement;
    filterCount?: number;
    filters?: ReactElement;
    searchBoxPlaceholder?: string;
    noResultsText?: string;
    loading?: boolean;
    searchKeys?: (keyof T)[]
}

export const ItemList = <T extends { id: number }>({
    data,
    columns,
    sortValues,
    bulkActions,
    itemActions,
    itemActionText,
    deletedItem,
    readItemKey,
    accordionTitle,
    filterContent,
    filterCount,
    filters,
    searchBoxPlaceholder,
    noResultsText,
    loading,
    searchKeys,
}: ItemListProps<T>) => {
    const [sortSelectedOption, setSortSelectedOption] = useState<number | false>(false);
    const [sortOptions, setSortOptions] = useState<DropDownOptionItem[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const { isDesktop, isMobile } = useWindowSize();
    const [closeFilters, setCloseFilters] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const {
        pagedData,
        setData,
        totalRecords,
        setFilter: setPaginationFilter,
        pageButtonNumbers,
        hasPreviousPage,
        previousPage,
        pageCount,
        hasNextPage,
        nextPage,
        setCurrentPageNumber,
        currentPageNumber,
        resultsPerPage,
        setResultsPerPage,
        setSortKey,
        setSortDirection,
        setSortManipulateFn,
        filterFn
    } = usePagination<T>(16, searchKeys);

    useEffect(() => {
        setData(data);
        setSelectedItems([]);
        setCurrentPageNumber(1)
    }, [data]);

    useEffect(() => {
      setCloseFilters(f => f + 1);
    }, [filters]);

    const handleSort = useCallback((item: SortablePropertiesDefinition<T> & { id: number | false }) => {
        setSortManipulateFn(() => item.manipulateFn);
        setSortKey(item.key);
        setSortDirection(item.direction);
        setSortSelectedOption(item.id)
    }, []);

    useEffect(() => {
        const options: typeof sortOptions & SortablePropertiesDefinition<T>[] = [];
        if (sortValues) {
            options.push(...sortValues.map((d, i) => ({ ...d, id: i })));
        }
        setSortOptions(options);
        if (options.length > 0) {
            if (!!(sortValues?.filter(opt => opt.default).length)) {
                handleSort(options[sortValues?.findIndex(val => val.default)])
            } else handleSort(options[0]);
        }
    }, [handleSort, sortValues]);

    useEffect(() => {
        setPaginationFilter(filterText.trim());
    }, [filterText]);

    const toggleSelectedItem = useCallback((selectedItem: T) => {
        const items = [...selectedItems];
        const i = items.findIndex(d => d.id === selectedItem.id);
        if (i >= 0) {
            items.splice(i, 1);
        } else {
            items.push(selectedItem);
        }
        setSelectedItems(items);
    }, [selectedItems]);
    const isDeleted = useCallback((item: T): boolean => {
      if (deletedItem) {
          if (typeof deletedItem === 'function') {
              return deletedItem(item);
          } else {
              return !!item[deletedItem];
          }
      }
      return false;
  }, [deletedItem]);
  
  
    const toggleAllItems = () => {
      const items = pagedData.filter(i => !isDeleted(i));
      if (selectedItems.length === items.length) {
          setSelectedItems([]);
      } else {
          setSelectedItems(items);
      }
    };

    return (
    loading ? <Loader /> : 
        <div className="flex flex-col gap-2 md:gap-4 items-start grow">
            <div className={`flex ${isMobile? 'flex-col' : 'flex-row !pb-0'} gap-6 self-stretch items-center`}>
                <div className="flex flex-row items-start gap-4 !pb-0">
                    {isDesktop && <PlainDropDown
                        onSelection={(e: unknown) => handleSort(e as SortablePropertiesDefinition<T> & { id: number | false })}
                        options={sortOptions}
                        value={sortSelectedOption}
                        selectClass={`flex flex-row pt-1 pr-2 !pb-1 pl-4 rounded lg:min-w-[240px] justify-between ${data.length > 0 ? 'border border-solid border-[#999]' : 'text-[#666] pointer-events-none bg-[#F2F2F2]'}`}
                        optionsClass="p-4 bg-white rounded" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
						style={{ cursor: "pointer" }} />
                    }
                    <div className={`flex flex-row h-12 lg:h-auto items-center pr-4 !pb-0 rounded self-stretch ${data.length > 0 ? 'border border-solid border-[#999]' : 'text-[#666] pointer-events-none bg-[#F2F2F2]'}`}>
                        <img src={magnifyGlassBlack} className="px-2" alt="Keyword search" />
                        <input type="text"
                            className="outline-none border-none text-sm"
                            value={filterText}
                            placeholder={searchBoxPlaceholder}
                            disabled={data.length === 0 && filterText.length === 0}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                        {filterText.length > 0 && (
                            <img src={closeImg} alt="Clear Search" className="h-4 cursor-pointer" onClick={() => setFilterText('')} />
                        )}
                    </div>
                </div>
                {!isMobile && <div className="flex flex-row items-start gap-[10px] grow !pb-0 text-sm">
                    {data.filter(filterFn).length} {data.filter(filterFn).length === 1 ? "Result" : "Results"}
                </div>}
                {(filterContent || !isDesktop) && <div className="flex flex-row items-start gap-[10px]">
                    <ButtonWithTooltip
                        className={`whiteBtn mb-0`}
                        wrapperClassName={`w-full`}
                        disabled={data.length === 0 && filterCount === 0}
                        text={
                            <>
                                {filterCount && filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                            </>
                        }
                        img={<img src={filter} alt="filter" className="bottom pl-2" style={{ filter: data.length === 0 ? 'var(--svgFilterDarkGray)' : undefined }} />}
                        textClassName="filter-text"
                        forceClose={closeFilters}
                        tooltipContent={isDesktop && filterContent ? filterContent : isDesktop ? <></> : (
                            <div className="flex flex-col">
                                <PlainDropDown
                                    onSelection={(e: unknown) => handleSort(e as SortablePropertiesDefinition<T> & { id: number | false })}
                                    options={sortOptions}
                                    value={sortSelectedOption}
                                    selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-4 border border-solid border-[#999] rounded"
                                    optionsClass="p-4 bg-white rounded" />
                                {filterContent}
                            </div>
                        )}
                    />
                </div>}
            </div>
            {filters}
           <div className="flex flex-col gap-0 md:gap-6 self-stretch items-stretch">
                {isDesktop ?
                        <TableList data={pagedData}
                            columns={columns}
                            loading={loading}
                            bulkActions={bulkActions}
                            itemActions={itemActions}
                            itemActionText={itemActionText}
                            readItemKey={readItemKey}
                            selectedItems={selectedItems}
                            noResultsText={noResultsText}
                            onItemSelectionChange={toggleSelectedItem}
                            deletedItem={deletedItem}
                            onSelectAllItems={toggleAllItems} />
                        : <AccordionList data={pagedData}
                            accordionTitle={accordionTitle}
                            columns={columns}
                            bulkActions={bulkActions}
                            itemActions={itemActions}
                            deletedItem={deletedItem}
                            selectedItems={selectedItems}
                            onItemSelectionChange={toggleSelectedItem}
                            onSelectAllItems={toggleAllItems} />
                }
            </div>
            {data.length > 0 ? (
                <Pagination
                    currentPage={currentPageNumber}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    pageNumbers={pageButtonNumbers}
                    previousPage={previousPage}
                    resultCount={pagedData.length}
                    resultsPerPage={resultsPerPage}
                    setCurrentPage={setCurrentPageNumber}
                    setResultsPerPage={setResultsPerPage}
                    totalRecords={totalRecords} />
            ) : ''}
        </div>
    );
};

ItemList.defaultProps = {
    searchBoxPlaceholder: 'Search documents',
    noResultsText: 'No Results Found',
    loading: true
};