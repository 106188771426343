import classes from "../Documents.module.css";
import headerClasses from "./TechBulletinHeader.module.css";
import { useContext } from "react";
import { Store } from "../../../Store";
import HeaderButtons from "../HeaderButtons";
import useWindowSize from "../../../customHooks/useWindowSize";
import ImageImports from "../../../utils/ImageImports";

function TechBulletinHeader() {
  const { selectedPlant } = useContext(Store);
  const { isDesktop } = useWindowSize();
  const { linkout } = ImageImports;
  return (
    <div className={`${classes.flex_row} ${headerClasses.header} ${isDesktop ? "" : headerClasses.not_desktop}`}>
      <div className={`${headerClasses.header_content} ${classes.flex_column}`}>
        <div className={headerClasses.header_title}>{selectedPlant.name} Bulletins</div>
        <div className={headerClasses.group_header_text}>
          Mitsubishi Power Technical Bulletins for gas turbines, steam turbines, and generators in the indicated plant sites 
        </div>
        <div>
        <div className={headerClasses.flex}>
                  <div className={headerClasses.iconWrapper}>
                    <a target="_blank" href={"#"} rel="noreferrer noopener">
                      <img src={linkout} className="plt-icon" alt="" />
                    </a>
                  </div>
                  <b className="header flex-grow-1">
                    <a style={{ color: "#000", textDecoration: "none" }} href={"https://www.gtnews.mhi.co.jp/en/"} target="_blank" rel="noreferrer noopener">
                    Go to Takasago Technical Bulletin Website (TBW)
                    </a>
                  </b>
                </div>
                <p>The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine
                    Service Engineering in Takasago, and requires a separate username and password. If you do not have those separate log-on
                    credentials, you can apply for them by completing the form linked <a href="https://www.gtnews.mhi.co.jp/en/" >here</a> and sending it to your LTSA Program Manager or Sales Manager.
                </p>
        </div>
        {!isDesktop && (
          <HeaderButtons manageButtonClass={headerClasses.manage_button}
                         addButtonClass={headerClasses.add_button}
                         addButtonText="Add Technical Document"
                         groupPermissionModule="TechnicalBulletinsGroups"
                         permissionModule="TechnicalBulletins" />
        )}
      </div>
      {isDesktop && (
        <HeaderButtons manageButtonClass={headerClasses.manage_button}
                       addButtonClass={headerClasses.add_button}
                       addButtonText="Add Technical Document"
                       groupPermissionModule="TechnicalBulletinsGroups"
                       permissionModule="TechnicalBulletins" />
      )}
    </div>
  );
}

export default TechBulletinHeader;
