
import {useEffect, useState} from 'react';
import { Outlet, useParams, useLocation, useResolvedPath, resolvePath } from 'react-router-dom';
import RelatedLinks from '../../components/RelatedLinks/RelatedLinks';
import Footer from '../../components/Footer/Footer';
import Link from '../../components/Link/Link';
import classes from './Thread.module.css';
import { ThreadMessage } from '../../utils/interface';
import { useThreads } from '../../customHooks/useThreads';
import useWindowSize from '../../customHooks/useWindowSize';

const relatedData = {
    relatedTitle: "Related Information",
    relatedSections: [
      {
        title: "Engineering Support Requests",
        description: "Request technical information or send questions to Mitsubishi Power Americas on technical topics. Responses will be documented here along with the requests.",
        buttonLink: "View ESRs",
        route: ""
      },
      {
        title: "Frequently Asked Questions",
        description: "This list of commonly asked questions and answers relate to topics such as Product Information, Technical Information and Portal Content",
        buttonLink: "View Frequently Asked Questions",
        route: ""
      }
    ]
  }
  

interface ThreadContainerProps {
    globalItemId: number,
    title: string
}

export interface OutletInterface {
    messages: ThreadMessage[],
    message: ThreadMessage,
    parentLocation: string,
    title: string
}

function ThreadContainer({globalItemId, title}: ThreadContainerProps) {
    const {threadId} = useParams();
    const {getItemThreads} = useThreads();
    const [messages, setMessages] = useState<ThreadMessage[]>([]);
    const location = useLocation();
    const resolvedPath = useResolvedPath(location.pathname);
    const {pathname: parentLocation} = resolvePath('..', resolvedPath.pathname);
    const {isMobile} = useWindowSize();

    let message;
    if (threadId) {
        message = messages.find(mes => mes.id === Number.parseInt(threadId));
    }

    useEffect(() => {
		if (globalItemId && !messages.length) {
			(async () => setMessages((await getItemThreads(globalItemId)).data[0].messages))();
        }
    }, []);

    return (
        <div className={`${classes.flex_column} ${classes.thread_container} ${isMobile? classes.is_mobile : ''}`}>
            <div className='content'>
                <div className='path breadcrumb'>
                    <Link to={"/"} className="link_text">Home</Link> /{" "}
                    
                    {message? (
                        <>
                            <Link to={parentLocation} className="link_text">{title}</Link> /{" "}
                            {message.title}
                        </>
                    ) : (
                        <>{title}</>
                    )}
                </div>
                <div className='login-header'>
                    {title}
                </div>
                <div className={`card ${classes.flex_column} ${classes.thread_container_outlet}`}>
                    <Outlet context={{messages, message, parentLocation, title}} />
                </div>
            </div>

            <RelatedLinks data={relatedData} />

            <div className='content flex-grow-0 flex-end'>
                <Footer />
            </div>
        </div>
    );
}

export default ThreadContainer;