import useWindowSize from "../../../customHooks/useWindowSize";
import HeaderButtons from "../HeaderButtons";

function OrganizationManagementHeader() {
  const { isDesktop } = useWindowSize();
  
  return (
    <div style={{justifyContent:"space-between"}} className={`flex flex-row gap-6 `}>
      <div className={`flex flex-col gap-6`}>
        {/* <div className={headerClasses.header_title}>{selectedPlant.name} Bulletins</div> */}
        <div className={'font-bold text-2xl'}>Role Configuration</div>
        <div className={'gap-6 text-[font-size:var(--fs14px)]'}>
        Mitsubishi Power Organizations, Customers and Associations can be granted access to the various parts of the portal
        </div>
 
        {!isDesktop && (
          <HeaderButtons manageButtonClass={""} addButtonClass={""} addButtonText="Generate Report" />
        )}
      </div>
      {isDesktop && (
        <HeaderButtons manageButtonClass={""} addButtonClass={""} addButtonText="Generate Report" />
      )}
    </div>
  );
}

export default OrganizationManagementHeader;
