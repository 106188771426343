import React, { FocusEventHandler, RefObject, useContext, useEffect, useRef } from 'react';
import { Store } from "../../Store";
import close from '../../assets/close.svg';
import useWindowSize from '../../customHooks/useWindowSize';
import './ToolTip.css';

interface ToolTipProps{
  headerHeight?:number;
}
function ToolTip({headerHeight}:ToolTipProps) {
  const {toolTip, toggleTooltip, toolTipContent} = useContext(Store);
  const {isDesktop} = useWindowSize();
  const wrapperRef = useRef(null);

  const blur: FocusEventHandler<HTMLDivElement> = (e) => {
    if (e.relatedTarget === null ) {
      toggleTooltip?.(false);
      document.body.focus();
    }
  };

  function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside({ target }: MouseEvent) {
        if (ref.current && !ref.current.contains(target as Node)) {
          toggleTooltip && toggleTooltip(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
    
  if (toolTip) {
    return (
      <div 
        className='tooltip-wrapper'
        ref={wrapperRef}
        tabIndex={1}
        onMouseOver={(e) => {
          e.currentTarget.focus({preventScroll: true});
          if (!toolTip) {
            toggleTooltip && toggleTooltip(true);
          }
        }}
        onBlur={blur}
      >
        <div className={toolTip ? "tooltip active" : "tooltip"}>
          <nav  className={toolTip ? "tooltip-menu active" : "tooltip-menu"}>
            <div className='tooltip-menu-items'>
              <div className={`close ${toolTip ? "active" : ""}`} onClick={() => toggleTooltip && toggleTooltip(!toolTip)}>
                  <img src={close} className='close-icon' alt='close icon' />
              </div>
              <div className={"tooltip-content-wrapper"}>
                {toolTipContent}
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return null;
}

export default ToolTip;