import React from "react";
import { KPI } from "../../../utils/interface";
import ImageImports from "../../../utils/ImageImports";
import classes from "./ESR.module.css";

const KPIsContainer = ({ KPI }: { KPI?: KPI }) => {
	const { help } = ImageImports;
	return (
		<div className={`${classes.KPIsContainer}`}>
			<div>
				Average Turnaround Time <img src={help} style={{ width: 16, position: "relative", marginBottom: -2 }} alt="" /> {KPI?.avgTurnAroundTime}d
			</div>
			<div>
				Requests Delivered by Promise Date <img src={help} style={{ width: 16, position: "relative", marginBottom: -2 }} alt="" /> {KPI?.requestsDeliveredOnTime} of {KPI?.numberOfRequests} (
				{Math.round(((KPI?.requestsDeliveredOnTime || 0) / (KPI?.numberOfRequests || 0)) * 100)}%)
			</div>
		</div>
	);
};

export default KPIsContainer;
