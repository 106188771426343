import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { UserGroup } from "../utils/interface";


export const usePageRequiresUserGroupSelection = () => {
    const {selectedUserGroup} = useContext(Store);
    const navigate = useNavigate();
    
    useEffect(() => {
        const ugString = sessionStorage.getItem('selectedUserGroup');
        let ug: UserGroup = {
            id: 0,
            name: 'Users\' Group Home',
            description: '',
            plants: [],
            enableTechnicalBulletinList: false,
        };
        if (ugString) {
            ug = JSON.parse(ugString);
        }
        if (ug.id === 0) {
            navigate('/', {replace: true});
        }
    }, [selectedUserGroup]);
};