import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import TextInputField from "../UI/BasicForm/TextInputField/TextInputField";
import TextAreaField from "../UI/BasicForm/TextAreaField/TextAreaField";
import SelectField from "../UI/BasicForm/SelectField/SelectField";
import classes from "./QuoteRequestForm.module.css";
import RadioButtonsGrouped from "../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped";
import { Link } from "react-router-dom";
import { PlantEquipmentsDATA, PlantEquipmentsDTO, RequestQuoteFormInterface, SiteUnit } from "../../utils/interface";
import AttachPartDocumentModal from "./AttachPartDocumentModal";
import AttachNameplateAssetModal from "./AttachNameplateAssetModal";
import AttachmentCard from "./AttachmentCard";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import useWindowSize from "../../customHooks/useWindowSize";
import { usePlantSiteUnits } from "../../containers/Reports/ReportsHttpServices";
import Dropdown from "../Dropdown/Dropdown";
import { useGetAllByUnitID_PlantEquipments } from "../../containers/PlantInformation/EquipmentsHttpServices";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import { PartsListTemplateLink } from "./PartsListTemplateLink";

const QuoteRequestFormStepThree = (props: stepThreeInterface) => {
  const { values,  setFieldValue, resetForm, errors, touched } = useFormikContext<RequestQuoteFormInterface>();
  const [Quantity, setQuantity] = useState<any[]>([]);
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const { isMobile } = useWindowSize();
  const { data: Units } = usePlantSiteUnits();
  const [selectedUnit, setSelectedUnit] = useState<{ id: number; name: string }>({ id: 0, name: "Select a unit " });
  const [selectedProductType, setSelectedProductType] = useState<{ id: number; name: string }>({ id: 0, name: "Select a product type" });
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<{ id: number; name: string }>({ id: 0, name: "Select an equipment" });

  const [equipmentsByUnit, setEquipmentsByUnit] = useState<{ id: number; name: string; productType: number }[]>([]);
  const [productType, setProductType] = useState<{ id: number; name: string }[]>([]);
  const [equipmentByProductType, setEquipmentByProductType] = useState<{ id: number; name: string }[]>([]);

  const [, setSelectedOption] = useState<string | null>("");
  const [, setSelectedProduct] = useState<string | null>("");

  const [siteUnits, setSiteUnits] = useState<SiteUnit[]>([]);

  const handleUnitSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    const sortedtypedata = e.currentTarget.getAttribute("data-id");

    if (!sortedtypedata) return;
    const selUnit = unitTypes.data.filter((u) => u.id === parseInt(sortedtypedata));
    setSelectedUnit({ ...selUnit[0] });
    setFieldValue("isUnitSelected", true);
  };
  const handleProductType = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectedProductID = e.currentTarget.getAttribute("data-id");
    if (!selectedProductID) return;
    const product = productType.filter((u) => u.id === parseInt(selectedProductID));
    setSelectedProductType({ ...product[0] });
    const equipments = equipmentsByUnit
      .filter((u) => {
        return u.productType === parseInt(selectedProductID);
      })
      .map((option) => ({ id: option.id, name: option.name }));
    setEquipmentByProductType(equipments);
  };
  const handleEquipmentSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectedEquipmentID = e.currentTarget.getAttribute("data-id");
    if (!selectedEquipmentID) return;
    const EquipmentType = equipmentByProductType.filter((u) => u.id === parseInt(selectedEquipmentID));
    setSelectedEquipmentType({ ...EquipmentType[0] });
    const equipmentId = parseInt(selectedEquipmentID);
    setFieldValue("quoteRequest.equipmentId", equipmentId);
    setFieldValue("isProductSelected", true);
  };

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (plantEquipments: PlantEquipmentsDTO) => {
    setEquipmentsByUnit(
      plantEquipments.data.map((option: PlantEquipmentsDATA) => ({
        id: option.plantEquipmentId,
        name: option.equipmentName,
        productType: option.productType,
      }))
    );

    setProductType(
      plantEquipments.data
        .map((option: PlantEquipmentsDATA) => ({ id: option.productType, name: option.productTypeName }))
        .filter((v, i, a) => a.findIndex((d) => d.id === v.id) === i)
    );
  };
  const onErrorRP = (error: any) => {
    setProductType && setProductType([]);
    setSelectedProductType && setSelectedProductType({ id: 0, name: "Select a product type" });
    setSelectedEquipmentType && setSelectedEquipmentType({ id: 0, name: "Select a equipment" });
    setEquipmentsByUnit([]);
  };
  useGetAllByUnitID_PlantEquipments(selectedUnit.id, onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  useEffect(() => {
    setQuantity([...new Array(50)]);
    
  }, []);

  useEffect(() => {
    if (Units) {
      setSiteUnits(Units);
      const type = Units.map((unit) => ({ id: unit.unitId, name: unit.name }));
      setUnitTypes && setUnitTypes({ data: [...type] });
    } else {
      setSiteUnits([]);
      setUnitTypes && setUnitTypes({ data: [{ id: 0, name: "Select a unit " }] });
    }
  }, [Units]);

  return (
    <>
      <div className={`${classes.qrwrpper}`}>
        <div>
          <h4>Equipment & Part Details</h4>
        </div>
        <p className={classes.para}>
        Please provide part(s) information ONLY for the unit selected above. Please create a separate quote for each unit.
        </p>
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <div>
              <label>Site Unit*</label>
            </div>
            <div
              className={
                siteUnits.length > 0 ? `nameplate-dropdown  inline-flex unitDropdown` : `nameplate-dropdown disabled inline-flex unitDropdown`
              }
            >
              <Dropdown
                dropdowntype={""}
                options={siteUnits ? unitTypes.data : []}
                handleDropDownSelect={handleUnitSelection}
                selectedOption={selectedUnit.name}
                isError={errors?.isUnitSelected && touched?.isUnitSelected ? true : false}
                setSelectedOption={setSelectedOption!}
              />
            </div>
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <div>
              <label>Product Type*</label>
            </div>
            <div className={productType.length > 0 ? `nameplate-dropdown  flex unitDropdown` : `nameplate-dropdown disabled flex unitDropdown`}>
              <Dropdown
                dropdowntype={""}
                style={{ width: "100%" }}
                options={productType ? productType : []}
                handleDropDownSelect={handleProductType}
                selectedOption={selectedProductType.name}
                setSelectedOption={setSelectedProduct!}
                isError={errors?.isProductSelected && touched?.isProductSelected ? true : false}
              />
            </div>
          </div>
          <div className={classes.flexCol}>
            <div>
              <label>Equipment Name*</label>
            </div>
            <div className={equipmentsByUnit.length > 0 ? `nameplate-dropdown  flex unitDropdown` : `nameplate-dropdown disabled flex unitDropdown`}>
              <Dropdown
                dropdowntype={""}
                style={{ width: "100%" }}
                options={equipmentByProductType ? equipmentByProductType : []}
                handleDropDownSelect={handleEquipmentSelect}
                isError={errors.quoteRequest?.equipmentId && touched.quoteRequest?.equipmentId ? true : false}
                selectedOption={selectedEquipmentType.name}
                setSelectedOption={setSelectedProduct!}
              />
            </div>
          </div>
        </div>
        <div>
          <p>
            Note: If the third-party equipment you are looking for does not appear in the dropdown list above, please navigate to your plant’s
            equipment list in the <Link to={"/plant-information"}>Plant Information</Link> page and add it. If you require Mitsubishi Power equipment
            to be added, please contact your Mitsubishi Power representative.
          </p>
        </div>
        <div>
          <FieldArray name="quoteRequest.quoteRequestParts">
            {({ insert, remove, push }) => (
              <div>
                {!isMobile && (
                  <div className={classes.quoteRequestPartsWrp}>
                    <div className={classes.col1}>Part Number</div>
                    <div className={classes.col2}>Part Description</div>
                    <div className={classes.col3}>Quantity</div>
                    <div className={classes.col4}></div>
                  </div>
                )}

                {values?.quoteRequest?.quoteRequestParts.length > 0 &&
                  values.quoteRequest.quoteRequestParts.map((friend, index) => (
                    <div className={classes.quoteRequestPartsWrp} key={index}>
                      <div className={classes.col1}>
                        {isMobile && <div className={classes.col1}>Part Number</div>}
                        <Field
                          name={`quoteRequest.quoteRequestParts.${index}.partNumber`}
                          className={`${classes.input}`}
                          placeholder="Ex. G1-23524-07"
                          type="text"
                        />
                      </div>
                      <div className={classes.col2}>
                        {isMobile && <div className={classes.col2}>Part Description</div>}
                        <Field
                          name={`quoteRequest.quoteRequestParts.${index}.description`}
                          className={`${classes.input}`}
                          placeholder="Ex. Gasket"
                          type="text"
                        />
                      </div>
                      <div className={classes.col3}>
                        {isMobile && <div className={classes.col3}>Quantity</div>}
                        <Field name={`quoteRequest.quoteRequestParts.${index}.quantity`} as="select">
                          {Quantity.length > 0 &&
                            Quantity.map((a, i) => {
                              return (
                                <option key={`${i}-qty`} value={i}>
                                  {i}
                                </option>
                              );
                            })}
                          {/* <option key={index} value={"1"}>1</option>; */}
                        </Field>
                      </div>
                      {values.quoteRequest.quoteRequestParts.length > 1 && (
                        <div className={classes.col4}>
                          <div className={classes.clear} onClick={() => remove(index)}>
                            clear
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <button
                  type="button"
                  className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
                  onClick={() => push({ partNumber: "", description: "", quantity: 0 })}
                >
                  Add More Parts
                </button>
              </div>
            )}
          </FieldArray>
        </div>
        <hr />
        <div>
          <h4>Attach a Parts Document (Optional)</h4>
        </div>
        <p className={classes.para}>
          Please use this space to upload a parts list document with details such as part numbers, descriptions and quantity needed. You may organize
          your parts list details using the Mitsubishi Power-provided <PartsListTemplateLink text="Parts List Template" />.
        </p>
        {!values.partsDocumentBase64 && (
          <button
            type="button"
            className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
            onClick={() =>
              setIsModal &&
              setIsModal({
                ...CloseAllModal,
                isAdd: { ...isModal.isAdd, isPartsListDocument: true },
              })
            }
          >
            Attach Document
          </button>
        )}

        {values.partsDocumentBase64 && (
          <AttachmentCard
            fileName={values.partsDocument.name}
            image={values.partsDocumentBase64}
            fileType={"sdsdasd"}
            ext={".png"}
            lastUpdated={"MM/DD/YYYY"}
            id={1}
          />
        )}

        <hr />
        <div>
          <h4>Attach a Nameplate Asset</h4>
        </div>
        <p className={classes.para}>
          Mitsubishi Power to provide content here.  
        </p>
        {
          !values.nameplateBase64 && <button
          type="button"
          style={
            (errors?.nameplate?.file && touched?.nameplate?.file) || (errors?.nameplate?.name && touched?.nameplate?.name)
              ? { backgroundColor: "#FEE2E2", color: "#991B1B", borderColor: "#FEE2E2" }
              : {}
          }
          className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
          onClick={() =>
            setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isAttachNameplateAsset: true },
            })
          }
        >
          Attach Nameplate
        </button>
        }
        
        {values.nameplateBase64 && (
          <AttachmentCard
            fileName={values.nameplate.name}
            image={values.nameplateBase64}
            fileType={"sdsdasd"}
            ext={".png"}
            lastUpdated={"MM/DD/YYYY"}
            id={2}
          />
        )}

        <hr />

        <div>
          <TextAreaField
            name="quoteRequest.additionalComments"
            height={126}
            labelName="Additional Comment(s):"
            placeholder="Add comment(s)"
            className=""            
          />
        </div>

        {isModal.isAdd.isPartsListDocument && <AttachPartDocumentModal />}
        {isModal.isAdd.isAttachNameplateAsset && <AttachNameplateAssetModal />}
        {isModal.isAddedSuccess.isQuoteRequest && (
          <FormSuccess
            modaltitlename={values.quoteRequest.quoteRequestType === 0 ? `Parts Quote Request Submitted` : "Service Quote Request Submitted"}
            modaltitle={""}
            isTitleCenter={true}
            comment={`Your ${
              values.quoteRequest.quoteRequestType ? "parts quote " : "service quote"
            }request was successfully submitted.  `}
            modalSize={"sm"}
            button_done={true}
            onClick={props.handleSubmitNew}
            button_addmore={true}
            buttonName="Submit Another Request"
          />
        )}
      </div>
    </>
  );
};

interface stepThreeInterface {
  handleSubmitNew?(): void;
}

export default QuoteRequestFormStepThree;
