import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import Alert from "../../components/Alert/Alert";
import { ItemList, ItemListColumnDefinition, SortOptionsDefinition } from "../../components/List/ItemList";
import Pill from "../../components/Pill/Pill";
import { useGetAllPlantsConfig } from "../../customHooks/PlantHttpServices";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import { PlantConfigData } from "../../utils/interface";

interface FilterStructure {
    unitAssociation: undefined|'none'|'some';
}
type ListablePlantConfigData = PlantConfigData & {id: number};
export const PlantManagementList = () => {
    usePageRequiresInternalAdmin();
    const [plants, setPlants] = useState<ListablePlantConfigData[]>([]);
    const [filters, setFilters] = useState<FilterStructure>({
        unitAssociation: undefined
    });
    const {selectedUserGroup} = useContext(Store);

    const {isFetching} = useGetAllPlantsConfig({
        onSuccess: configs => {
            setPlants(configs.map(d => ({
                ...d, id: d.plantId
            })));
        }
    });

    const columns: ItemListColumnDefinition<ListablePlantConfigData>[] = useMemo(() => {
        return [
            {
                title: 'Plant Name',
                key: 'baseOrgName'
            },
            {
                title: 'Units',
                key: 'units',
                component: (({data}) => <>{(data.units?.length || 0)}</>)
            },
            {
                title: 'Owner',
                key: 'owner'
            },
            {
                title: 'Operator',
                key: 'operators'
            },
            {
                title: 'Region',
                key: 'mhiRegion'
            },
            {
                title: 'Frequency',
                key: 'frequency'
            },
            {
                title: 'Details',
                key: 'plantId',
                component: (({data}) => <Link to={`${data.plantId}`} className="no-underline text-ocean font-bold">View</Link>)
            }
        ];
    }, []);

    const sortableProperties: SortOptionsDefinition<ListablePlantConfigData>[] = useMemo(() => {
        return [
            {
                id: 0,
                name: 'Sort by: A - Z',
                key: 'baseOrgName',
                direction: 'asc',
                manipulateFn: (d) => d.toLowerCase()
            },
            {
                id: 1,
                name: 'Sort by: Z - A',
                key: 'baseOrgName',
                direction: 'desc',
                manipulateFn: (d) => d.toLowerCase()
            },
            {
                id: 2,
                name: 'Sort by: Newest to Oldest',
                key: 'plantId',
                direction: 'desc'
            },
            {
                id: 3,
                name: 'Sort by: Oldest to Newest',
                key: 'plantId',
                direction: 'asc'
            }
        ];
    }, []);

    const filterCount = useMemo(() => {
        let c = 0;
        if (filters.unitAssociation !== undefined) {
            c++;
        }
        return c;
    }, [filters]);

    const filterFn = useCallback((plant: ListablePlantConfigData) => {
        if (filters.unitAssociation !== undefined) {
            if (filters.unitAssociation === 'none') {
                return (plant.units || []).length === 0;
            } else {
                return (plant.units || []).length > 0;
            }
        }
        return true;
    }, [filters]);

    return (
        <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            <div className="flex flex-row !pb-0 items-start gap-6 self-stretch justify-between">
                <div className="flex flex-col gap-2">
                    <span className="font-bold text-2xl">Mitsubishi Power Plants</span>
                    <div className="flex flex-row !pb-0">
                        <span className="text-sm">See below for high-level information about Plants & Customers</span>
                    </div>
                </div>
            </div>
            <Alert dismissible={false} type="warning" onClose={() => {}}>Please contact a Customer Portal administrator should any of the below plants require modifications.</Alert>
            <hr className="m-0 text-[#B3B3B3]" />
            <ItemList data={plants.filter(filterFn)}
                        columns={columns}
                        loading={isFetching}
                        sortValues={sortableProperties}
                        accordionTitle={u => u.baseOrgName}
                        filterContent={<Filters filters={filters} onFiltersUpdated={setFilters} />}
                        filterCount={filterCount}
                        filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />} />
        </div>
    );
};
const Filters = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    return (
        <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Unit Associations:</span>
                <label className="flex flex-row !pb-0 gap-2 items-start">
                    <input type="radio"
                            name="created"
                            value="none"
                            onChange={e => onFiltersUpdated({...filters, unitAssociation: e.target.value as FilterStructure['unitAssociation']})}
                            checked={filters.unitAssociation === 'none'} />
                    No Unit Associations
                </label>
                <label className="flex flex-row !pb-0 gap-2 items-start">
                    <input type="radio"
                            name="created"
                            value="some"
                            onChange={e => onFiltersUpdated({...filters, unitAssociation: e.target.value as FilterStructure['unitAssociation']})}
                            checked={filters.unitAssociation === 'some'} />
                    1 ≥ Unit Associations
                </label>
            </div>
        </div>
    );
};

const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    const unitAssociationsMap = {
        none: 'No Unit Associations',
        some: '1 ≥ Unit Associations'
    };
    return (
        <div className="">
            {filters.unitAssociation !== undefined? (
            <Pill dismissible={true}
                    onDismiss={() => onFiltersUpdated({...filters, unitAssociation: undefined})}
                    type="default">
                {unitAssociationsMap[filters.unitAssociation]}
            </Pill>
            ) : ''}
        </div>
    );
};