import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Report } from "../../../utils/interface";
import classes from "./ReportsToolTipData.module.css";

interface ToolTipDataProps {
  data: Report;
}

const ReportsToolTipData = ({ data }: ToolTipDataProps) => {
  return (
    <div className={classes.wrp}>
      <div className={classes.row}>
        <h2 className={classes.title}> Details for {data.displayFileName}</h2>
      </div>
      <div className={classes.row}>
        <p>Please find the details for “{data.displayFileName}” below.</p>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.badgeLabel}>Implementation Status</div>
        {
          data.implementationStatus.toLowerCase() === "completed" ? 
          <div className={classes.badgeCompleted}>{data.implementationStatus}</div>
          : data.implementationStatus.toLowerCase() === "not performed" ? 
          <div className={classes.badgeNotPerformed}>{data.implementationStatus}</div> :
          <div className={classes.badgePartiallyPerformed}>{data.implementationStatus}</div>
        }
        
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Scope</div>
        <div className={classes.details}>{data.scope} </div>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Summary</div>
        <div className={classes.details}> {data.summaryGuideline} </div>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Document Group</div>
        <div className={classes.details}>{data.reportType}</div>
      </div>
      <div className={`${classes.row} ${classes.mb26}`}>
        <div className={classes.label}>Last Modified</div>
        <div className={classes.details}>
          <Moment date={data.updated} format="DD/MM/YYYY" />
        </div>
      </div>
      <div className={classes.row}>
        <Link to={""}>View the full document</Link>
      </div>
    </div>
  );
};

export default ReportsToolTipData;
