import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import {
  useCreateNewMitsubishiPowerReport,
  usePlantSiteUnits,
  useUpdateMitsubishiPowerReport,
  useCreateNewOMManual,
  useUpdateOMManual,
} from "../Reports/ReportsHttpServices";
import { useQueryClient } from "react-query";
import Button from "../../components/Button/Button";
import classes from "./discussions.module.css";
import { useOnError } from "../../customHooks/useOnError";
import { DiscussionInterface } from "../../utils/interface";
import { ModuleIDs } from "../../utils/moduleIDs";
import { DiscussionModuleOutletInterface } from "./DiscussionModuleContainer";
import { boolean } from "yup/lib/locale";

interface AddDocumentContainerProps {
  isEdit: boolean;
}

const AddDiscussionContainer: FunctionComponent<AddDocumentContainerProps> = ({ isEdit }) => {
  const { documentModule, documentGroups } = useOutletContext<DiscussionModuleOutletInterface>();

  const navigate = useNavigate();
  const { addDocumentModalBodyContent: DocumentModalBodyContent } = documentModule;
  const { setIsModal ,selectedUserGroup } = useContext(Store);
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const [apiResponses, setApiResponses] = useState<{ isEdited: boolean; isSuccess: boolean; discussionData?: DiscussionInterface }>({
    isEdited: false,
    isSuccess: false,
  });
  const { isEdited, isSuccess, discussionData } = apiResponses;

  const handleCloseModal = () => {
    setIsModal && setIsModal!({ ...CloseAllModal });
    navigate("..");
  };


  
  return (
    <ModalGlobal
      modaltitle={isSuccess || isEdited ? `Discussion Successfully ${isEdit ? "Edited" : "Added"}` : `${isEdit ?  "Edit Discussion Details"   : documentModule.addEditHeaderName}`}
      isModalHeader={true}
      modalClassName="overflow-auto"
      modalSize={isSuccess || isEdited ? "xs" :"md"}
      isTitleCenter={isSuccess ? true : false}
      modalClose={handleCloseModal}
    >
      {!isEdit && documentModule.addModalHeaderContent.length > 0 && (
        <div>
          {documentModule.addModalHeaderContent.map((e, i) => {
            return (
              <p key={i} className={classes.modal_body_text}>
                {e}
              </p>
            );
          })}
        </div>
      )}
      {isSuccess && discussionData && (
        <div>
          <p style={{textAlign:"center"}}>
            {discussionData.title} was successfully added to the {documentModule.name} page for {selectedUserGroup.name}. 
          </p>
          <div style={{justifyContent:"center"}} className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
            {/* <Button className={`darkBlue`} text="Add Another Document" onClick={() => {}} /> */}
            <Button className={`darkBlue`} text="Done" onClick={() => navigate("..")} />
          </div>
        </div>
      )}
      {isEdited && discussionData && (
        <div>
          <p style={{textAlign:"center"}}>The discussion details for {discussionData.title} have been successfully updated.</p>
          <div style={{justifyContent:"center"}} className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
            <Button className={`darkBlue`} text="Done" onClick={() => navigate("..")} />
          </div>
        </div>
      )}
      {!isSuccess && !isEdited && (
        <DocumentModalBodyContent
          documentModule={documentModule}
          isEdit={isEdit}
          documentGroups={documentGroups}       
          siteUnits={[]}
          setApiResponses={setApiResponses}
        />
      )}
    </ModalGlobal>
  );
};

export default AddDiscussionContainer;
