import axios, { AxiosResponse } from "axios";
import { apiMitsubishiPowerReport, omManualApiUrl, technicalBulletinApiUrl, apiPartsCatalog, apiGeneralDocuments } from "../../endpoints/endpoints";
import { CPApiResponse, PlantPermissionedReports, Report, PartsCatalogInterface, DataWithPermissions, CanPermissionedItems, ModulePermissionResponseInterface } from "../../utils/interface";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../../utils/handleAxiosResponse";

// interface starts here //
export interface DocumentModuleApiMethods<T> {
  getAll: (itemTypeId?: number, baseOrgId?: number, moduleId?: number) => Promise<DataWithPermissions<T[]>>;
  get: (globalItemId?: number) => Promise<T>; // changed from report to T - if issues arise this maybe why //
  bulkDelete: (ids: number[], reason: string) => Promise<boolean>;
  bulkMove: (ids: number[], newItemTypeId: number) => Promise<boolean>;
}
// end interface //


// Technical Bulletins section starts here //
export const TechnicalBulletins: DocumentModuleApiMethods<Report> = {
  getAll: async (itemTypeId?: number, baseOrgId?: number, moduleId?: number): Promise<DataWithPermissions<Report[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Report[]>[]>> = await axios(
      `${technicalBulletinApiUrl}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${moduleId}&BaseOrgId=${baseOrgId}`
    );
    return handleModulePermissionResponseMulti(response);
  },
  get: async (globalItemIds: number | undefined): Promise<Report> => {
    const response: AxiosResponse<CPApiResponse<Report>> = await axios(`${technicalBulletinApiUrl}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${technicalBulletinApiUrl}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${technicalBulletinApiUrl}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end Technical Bulletins section //


// General Documents section starts here //
export const GeneralDocuments: DocumentModuleApiMethods<Report> = {
  
  getAll: async (itemTypeId?: number, baseOrgId?: number, moduleId?: number): Promise<DataWithPermissions<Report[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Report[]>[]>> = await axios(
      `${apiGeneralDocuments}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${moduleId}&BaseOrgId=${baseOrgId}`
    );
    return handleModulePermissionResponseMulti(response);
  },
  get: async (globalItemIds: number | undefined): Promise<Report> => {
    const response: AxiosResponse<CPApiResponse<Report>> = await axios(`${apiGeneralDocuments}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiGeneralDocuments}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiGeneralDocuments}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end General Documents section //