import { CSSProperties, MouseEventHandler, MutableRefObject, useContext, useEffect, useId, useState } from "react";
import Accordion from "../../components/Accordian/Accordion";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import useWindowSize from "../../customHooks/useWindowSize";

import ReactDOM from "react-dom";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import { DiscussionInterface } from "../../utils/interface";
import { ColumnDefinition } from "./Discussion";
import classes from "./Reports.module.css";

const { ellipsis } = ImageImports;

interface DiscussionRowAccordionProps {
  data: DiscussionInterface;
  columnDefinitions: ColumnDefinition[];
  tooltipPortalRef: MutableRefObject<null>;
  tooltipActions: tooltipActionItem[];
  unread: boolean;
}

function DiscussionRowAccordion({ data, columnDefinitions, tooltipPortalRef, tooltipActions, unread }: DiscussionRowAccordionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    const closeTooltip = () => setIsTooltipVisible(false);
    window.addEventListener("resize", closeTooltip);
    return () => window.removeEventListener("resize", closeTooltip);
  }, []);

  return (
    <Accordion
      isDeleted={data.isDeleted}
      titleContent={<DiscussionRowAccordionTitle unread={unread} reportName={data.title} />}
      isOpen={isOpen}
      headerStyle={{}}
      contentStyle={{}}
      toggleOpen={toggleOpen}
    >
      <div className={`${classes.result_container} ${classes.flex_column} ${unread ? classes.unread : ""}`}>
        <table className={`${classes.report_table} ${classes.report_table_accordion}`}>
          <tbody>
            {columnDefinitions.map((columnDefinition) => (
              <tr key={columnDefinition.key} className={classes.data_point}>
                <th>{columnDefinition.title}</th>
                <td>
                  {columnDefinition.component ? (
                    <columnDefinition.component report={data} />
                  ) : (
                    <div>
                      <>{data[columnDefinition.key]}</>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
                  <img src={ellipsis} alt="More Actions" /> <span className="font-bold text-dark-blue">More Actions</span>
                  {tooltipPortalRef.current &&
                    ReactDOM.createPortal(
                      <BulkActionTooltip
                        shown={isTooltipVisible}
                        setShown={setIsTooltipVisible}
                        parentId={tooltipId}
                        listItems={tooltipActions}
                        data={[data]}
                      />,
                      tooltipPortalRef.current
                    )}
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>
  );
}

export default DiscussionRowAccordion;

interface AccordionTitleProps {
  reportName: string;
  unread: boolean;
}

function DiscussionRowAccordionTitle({ reportName, unread }: AccordionTitleProps) {
  return <div className={`${classes.flex_row} ${classes.accordion_title} ${unread ? classes.unread : ""}`}>{reportName}</div>;
}
