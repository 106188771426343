import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import { RequestQuoteDTO, RequestQuoteFormInterface } from "../../utils/interface";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";

const AttachPartDocumentModal = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<RequestQuoteFormInterface>();
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const [previewBase24, setPreviewBase24] = useState<string>("");
  const [attachment, setAttachment] = useState<File | Blob>();
  const { trash } = ImageImports;

  const handleAddDocument = () => {
    setIsModal && setIsModal({...CloseAllModal});
  }

  useEffect(() => {
    if (values.nameplate.file) {
      setPreviewBase24(values.partsDocumentBase64);
    }      
}, [attachment]);

  useEffect(() => {
    if (attachment) {
        toBase64(attachment).then((base64Image: string) => {
          setFieldValue("partsDocumentBase64",base64Image);
        }).catch((err: Error) => {
          setFieldValue("partsDocumentBase64","");
        });
    }      
}, [attachment]);
  return (
    <ModalGlobal modaltitle={"Add a Parts List Document"} modalSize={"md"} isModalHeader={true}>
      <div>
        <>
          <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
            <span className={classes.input_label}>Attachment*</span>
            <div className={classes.form_group}>
              <input type="text" name="partsDocument.name" disabled className={classes.input} style={{}} value={values.partsDocument.name} />
              <img
                className={classes.deleteFile}
                onClick={() => {
                  setFieldValue("partsDocument.name", "");
                  setAttachment(undefined);
                }}
                src={trash}
                alt="Remove Attachment"
              />
            </div>
          </div>
          <DragDropFieldFormik name="partsDocument.file" onChange={setAttachment} displayName={"partsDocument.name"} previewBase24={previewBase24} attachment={attachment} />
          <div className={`${classes.form_field_row}`}>
            <div className={classes.warning_text}>
              <div>Accepted file types: .PDF, .DOC, .XLSX, .JPG, .PNG</div>
               <div>Maximum file upload size: 2 MB</div> 
            </div>
          </div>
          <div className="flex align-center">
            <div className="flex-grow-1 flex">
              <button type={"button"} className="primaryBtn darkBlue" 
              disabled={values.partsDocument.file === ""}
              onClick={handleAddDocument}>
                Add Document
              </button>
              {/* <button type={"button"} className="primaryBtn whiteBtn marginLeft16" onClick={() => {alert()}}>
                Cancel
              </button> */}
            </div>
            <span>*Required field</span>
          </div>
        </>
      </div>
    </ModalGlobal>
  );
};

export default AttachPartDocumentModal;
