import React, { FunctionComponent } from "react";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import classes from "./QuoteRequests.module.css";

const { magnifyGlassBlack } = ImageImports;

const IssueReportSearch: FunctionComponent<{
	searchText: string;
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
}> = ({ searchText, setSearchText }) => {
	const { isMobile } = useWindowSize();

	return (
		<div className={`${classes.flex_row} ${classes.search_keyword_container}`} style={{ width: isMobile ? "100%" : "initial" }}>
			<img src={magnifyGlassBlack} className="" alt="keyword search" />
			<input className={classes.search_keyword} value={searchText} placeholder="Search documents" onChange={(e) => setSearchText(e.target.value)} />
		</div>
	);
};
export default IssueReportSearch;
