import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import classes from "./PlantInformation.module.css";
import { MainContent } from "../MainContent/MainContent";
import { usePageRequiresUserGroupSelection } from "../../customHooks/usePageRequiresUserGroupSelection";

function PlantInformation() {
  usePageRequiresUserGroupSelection();
  const location = useLocation();

  return (
    <MainContent title="Plant Information" breadCrumbs={['Plant Information']} showPlantDropdown={true}>
      <div className={` ${classes.tab} tab col-to-row flex-start`}>
        <NavLink
          to="plant-site-information"
          end
          className={() =>
            location.pathname.includes("/equipmentAttachments/") ||
            location.pathname.toLowerCase().endsWith("/plant-information") ||
            location.pathname.toLowerCase().endsWith("/plant-information/plant-site-information")
              ? `outline-none ${classes.tabItem}  ${classes.marginRight36} ${classes.activeTab}`
              : `outline-none ${classes.tabItem}  ${classes.marginRight36} `
          }
        >
          Plant Site Details
        </NavLink>
        <NavLink to="site-contacts" className={({ isActive }) => (isActive ? `outline-none ${classes.tabItem} ${classes.activeTab}` : `outline-none ${classes.tabItem}`)}>
          Site Contacts
        </NavLink>
      </div>
      <br />
      <Outlet />
    </MainContent>
  );
}

export default PlantInformation;
