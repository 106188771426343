import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { deleteInterface, QuoteRequestListDTO } from "../../utils/interface";
import { apiquoterequest } from "../../endpoints/endpoints";

interface QuoteRequestCrudMethods<T1, T2> {
	query: (baseOrgId: number) => Promise<T1>;
	delete: (globalId: number) => Promise<T2>;
}

export const QuoteRequestApi: QuoteRequestCrudMethods<AxiosResponse<QuoteRequestListDTO>, AxiosResponse<deleteInterface>> = {
	query: async (plantid: number): Promise<AxiosResponse<QuoteRequestListDTO>> => {
		const response = await axios({
			url: `${apiquoterequest}/${730}`,
			method: "GET",
		});
		return response.data;
	},
	delete: async (globalId: number): Promise<AxiosResponse<deleteInterface>> => {
		const response = await axios({
			url: `${apiquoterequest}/DeleteByGlobalItemId/${globalId}`,
			method: "DELETE",
		});
		return response.data;
	},
};

export const useQuery_QuoteRequestItems = (plantid: number, onSuccess: any, onError: any) => {
	return useQuery(queryKeys.QuoteRequestItems, () => QuoteRequestApi.query(plantid), {
		onSuccess,
		onError,
	});
};
