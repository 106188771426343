import React, {MouseEventHandler, ReactElement} from "react";

import classes from "./Alert.module.css";
import ImageImports from "../../utils/ImageImports";
import { Link } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";

const {dangerIcon, closeError, alarmWarning, info, success} = ImageImports;

const Alert = (props:AlertProps) => {
  const {isDesktop} = useWindowSize();
  const typeImageMapping = {
    warning: () => <img src={alarmWarning} alt="" />,
    error: () => <img src={dangerIcon} alt="" />,
    info: () => <img src={info} alt="" />,
    success: () => <img src={success} alt="Success" />
  };
  return (
    <div className={`${classes.alert} ${classes[props.type]} ${isDesktop? classes.isDesktop : ''}` } >
      <div className={classes.alertInner}>
        {" "}
        <span className={classes.icon}>
          {typeImageMapping[props.type]()}
        </span>{" "}
        <span className={classes.message}>{props.children}</span>
      </div>
      {props.dismissible && <Link to={"#"} onClick={props.onClose} className={classes.link}><img src={closeError} alt="dismiss" /></Link>}
    </div>
  );
};


interface AlertProps {
  children: ReactElement|string;
  onClose: MouseEventHandler;
  type: 'warning'|'error'|'info'|'success';
  dismissible: boolean;
}

Alert.defaultProps = {
  dismissible: true,
}

export default Alert;