/**
 * This is a wrapper for window.localStorage
 * This wrapper implements expiry
 * 
 * There's a subtle difference in the setItem method.
 * Using this API, there's a 3rd parameter which is an moment.duration string which will be used to set the expiration date of the item you're storing
 * 
 * Upon calling getItem for an item that exists and is expired, getItem will behave as if the item does not exist (return null) and it will delete the item from storage
 * 
 */

import moment from "moment";


 class ExpirableLocalStorageValue {
    public value: string;
    private expiry: number;
    
    public constructor(v: string, e?: number)
    {
        if (e) {
            this.value = v;
            this.expiry = e;
        } else {
            try {
                const {value, expiry} = JSON.parse(v);
                this.value = value;
                this.expiry = expiry;
            } catch (e) {
                throw e;
            }
        }
    }

    public get isExpired(): boolean
    {
        const n = Number(moment().format('X'));
        return n > this.expiry;
    }

    public toString(): string
    {
        return JSON.stringify(this);
    }
}

export default class LocalStorage {
    private readonly storage: Storage;
    private static instance: LocalStorage;
    private constructor(getStorage = (): Storage => window.localStorage)
    {
        this.storage = getStorage();
    }

    private static getInstance(): LocalStorage
    {
        if (LocalStorage.instance === undefined) {
            LocalStorage.instance = new LocalStorage();
        }
        return LocalStorage.instance;
    }

    public static getItem(key: string): any | null
    {
        const itemValue = LocalStorage.getInstance().storage.getItem(key);
        if (itemValue === null) return null;

        const item = new ExpirableLocalStorageValue(itemValue);
        if (item.isExpired) {
            LocalStorage.getInstance().storage.removeItem(key);
            return null;
        }
        
        return item.value;
    }

    /**
     * 
     * @param key 
     * @param value 
     * @param expiry moment.duration string. Default: 'P30D'
     */
    public static setItem(key: string, value: any, expiry: string = 'P30D'): void
    {
        const n = Number(moment().add(moment.duration(expiry)).format('X'));
        const v = new ExpirableLocalStorageValue(value, n);
        LocalStorage.getInstance().storage.setItem(key, `${v}`)
    }

    public static key(n: number): string | null
    {
        return LocalStorage.getInstance().storage.key(n);
    }

    public static removeItem(key: string): void
    {
        LocalStorage.getInstance().storage.removeItem(key);
    }

    public static clear(): void
    {
        LocalStorage.getInstance().storage.clear();
    }
}