import React, { useContext, useState } from 'react';
import { Store } from '../../Store'
import { IdObj } from '../../utils/interface';

import './PopoutToolTip.css'

interface PopoutToolTipProps {
  id: number;
  IdObj?:IdObj;
  displayTrigger: boolean;
  listItems: Array<{
    img: string;
    text: string;
    onclick?(id:IdObj | number): void;
  }>
}




function PopoutToolTip(props: PopoutToolTipProps) {
  // const stateContext = useContext(Store);

  return (
    <div className='actionToolTip' style={props.displayTrigger ? { display: "flex" } : { display: "none" }}>
      <div className='action-icons'>
        {
          props.listItems.map((obj, i) => {
            return (

              <div className='action-iconitem' onMouseDown={() => obj.onclick!({equipmentid:props.IdObj!.equipmentid,attachmentid:props.IdObj!.attachmentid})} key={i}>
                <img id={obj.text + "-img-" + props.id} className='action-icon' alt={obj.text} src={obj.img}
                  onMouseEnter={() => {
                    let text = document.getElementById(obj.text + "-text-" + props.id)
                    if (text) {
                      text.classList.add("actionTextHover")
                    }
                    let img = document.getElementById(obj.text + "-img-" + props.id)
                    if (img) {
                      img.classList.add("actionIconHover")
                    }
                  }}
                  onMouseLeave={() => {
                    let text = document.getElementById(obj.text + "-text-" + props.id)
                    if (text) {
                      text.classList.remove("actionTextHover")
                    }
                    let img = document.getElementById(obj.text + "-img-" + props.id)
                    if (img) {
                      img.classList.remove("actionIconHover")
                    }
                  }}
                />
              </div>
            )
          })
        }
      </div>
      <div className='action-content'>
        {
          props.listItems.map((obj, i) => {
            return (
              <div className='action-item' onMouseDown={() => obj.onclick!({equipmentid:props.IdObj!.equipmentid,attachmentid:props.IdObj!.attachmentid})} key={i}>
                <span id={obj.text + "-text-" + props.id} className='action-text' onMouseEnter={() => {
                  let text = document.getElementById(obj.text + "-text-" + props.id)
                  if (text) {
                    text.classList.add("actionTextHover")
                  }
                  let img = document.getElementById(obj.text + "-img-" + props.id)
                  if (img) {
                    img.classList.add("actionIconHover")
                  }
                }}
                  onMouseLeave={() => {
                    let text = document.getElementById(obj.text + "-text-" + props.id)
                    if (text) {
                      text.classList.remove("actionTextHover")
                    }
                    let img = document.getElementById(obj.text + "-img-" + props.id)
                    if (img) {
                      img.classList.remove("actionIconHover")
                    }
                  }}
                >{obj.text}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default PopoutToolTip;
