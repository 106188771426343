import { FunctionComponent, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import ImageImports from "../../utils/ImageImports";
import { FunctionOrType } from "../../utils/interface";
import Button from "../Button/Button";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import { AccordionListItem } from "./AccordionListItem";
import { ItemListProps } from "./ItemList";

const { downArrow } = ImageImports;

interface AccordionListProps<T> {
    data: T[];
    columns: ItemListProps<T>['columns'];
    bulkActions?: ItemListProps<T>['bulkActions'];
    itemActions?: ItemListProps<T>['itemActions'];
    deletedItem?: ItemListProps<T>['deletedItem'];
    readItemKey?: ItemListProps<T>['readItemKey'];
    accordionTitle?: ItemListProps<T>['accordionTitle'];
    selectedItems: T[];
    onItemSelectionChange: (item: T) => void;
    onSelectAllItems: () => void;
}

export const AccordionList = <T extends {id: number}>({data, selectedItems, onItemSelectionChange, onSelectAllItems, deletedItem, bulkActions, itemActions, ...props}: AccordionListProps<T>) => {
    const tooltipPortalRef = useRef(null);
    const [forceClose, setForceClose] = useState<number>(0);

    const BulkActionTooltip = useMemo(() => {
      if (bulkActions) {
          return withTooltip(Button);
      }
  }, [bulkActions]);

    const getBoolVal2 = (v: FunctionOrType<boolean, T[]>): boolean => {
        if (typeof v === 'boolean') {
            return v;
        }
        return v(...selectedItems);
    };

    const getActionText = (ActionItem: string | ReactElement | FunctionComponent<{selectedItems: T[]}>) => {
        if (typeof ActionItem === 'function') {
            return <ActionItem selectedItems={selectedItems} />
        }
        return ActionItem;
    };
    
    const isDeleted = useCallback((item: T): boolean => {
      if (deletedItem) {
          if (typeof deletedItem === 'function') {
              return deletedItem(item);
          } else {
              return !!item[deletedItem];
          }
      }
      return false;
  }, [deletedItem]);

  const selectableItems = data.filter(i => !isDeleted(i));

  return (
    <>
      {(bulkActions?.length || 0) > 0 && (
        <div className="flex flex-row justify-start items-center gap-3 !pb-0 px-3">
          <Checkbox isChecked={selectableItems.length === selectedItems.length && selectableItems.length > 0} onChange={onSelectAllItems} />
          {BulkActionTooltip && bulkActions && (
            <BulkActionTooltip 
              className="border-none bg-transparent cursor-pointer !p-0"
              text={<img src={downArrow} alt="Bulk Actions" className="h-6 w-6 mb-[-16px] md:mb-[-6px]" />}
              forceClose={forceClose} 
              tooltipContent={<div className="p-6 rounded-lg">
                <div className="flex flex-col items-start gap-6">
                  {bulkActions.map((action, i) => {
                    const disabled = action.disabled !== undefined && getBoolVal2(action.disabled);
                    return (
                      <span key={`action-${i}`} onClick={(e) => {
                        if (!disabled) {
                          action.onClick(e, selectedItems);
                          setForceClose(fc => ++fc);
                        }
                      }}>{getActionText(action.text)}</span>
                    )
                  })}
                </div>
              </div>} />
          )}
          <span className="font-bold">Report Name</span>
        </div>
      )}
      
      <div className="flex flex-col gap-6">
        {data.map((row) => (
          <AccordionListItem
            key={row.id}
            data={row}
            itemActions={itemActions}
            bulkActions={bulkActions}
            {...props}
            onRowChecked={() => onItemSelectionChange(row)}
            isChecked={selectedItems.map(d => d.id).includes(row.id)}
            deletedItem={deletedItem}
            tooltipPortalRef={tooltipPortalRef}
          />
        ))}
      </div>
      <div ref={tooltipPortalRef} />    
    </>
  ); 
};