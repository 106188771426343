import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { DateInputWithCalendarToggle } from '../DatePickerField/DatePickerFields';
import { CustomSingleDatePicker } from './DatePicker';

interface IDateRangePickerProps {
  startDate: Dayjs;
  endDate: Dayjs;
  showEndCalendar: boolean;
  showStartCalendar: boolean;
  startName: string;
  endName: string;
  startDateChange: (val: Dayjs) => void;
  endDateChange: (val: Dayjs) => void;
  setToggleStartCalendarDisplay: (x: boolean) => void;
  setToggleEndCalendarDisplay: (x: boolean) => void;
  containerStyles?: React.CSSProperties;
  inputClassName?: string;
}

const CustomDateRangePicker: React.FunctionComponent<IDateRangePickerProps> = ({
  startDate,
  endDate,
  showEndCalendar,
  showStartCalendar,
  startName,
  endName,
  startDateChange,
  endDateChange,
  setToggleStartCalendarDisplay,
  setToggleEndCalendarDisplay,
  containerStyles = {width: '130%'},
  inputClassName = 'w-full'
}) => {
  var customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  
  return (
    <div className='flex flex-col w-full'>
      <label htmlFor={startName}>From</label>
      <CustomSingleDatePicker
        selectedDate={startDate}
        onChange={val => {
          startDateChange(dayjs(val))
          showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)
        }}
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        openPicker={showStartCalendar}
        input={
          <DateInputWithCalendarToggle
            inputClassName={inputClassName}
            onBlur={() => showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)}
            value={dayjs(startDate, 'MM/DD/YYYY').isValid() ? dayjs(startDate).format('MM/DD/YYYY') : undefined}
            onChange={(val: Dayjs) => startDateChange(val)}
            toggleIcon={() => setToggleStartCalendarDisplay(!showStartCalendar)}  
            name={startName} 
          />
        }
      />
      <label htmlFor={endName}>To</label>
      <CustomSingleDatePicker
        selectedDate={endDate}
        startDate={startDate}
        endDate={endDate}
        onChange={val => {
          endDateChange(dayjs(val.endOf('day')))
          showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)
        }}
        openPicker={showEndCalendar}
        minDate={startDate}
        input={
          <DateInputWithCalendarToggle
            inputClassName={inputClassName}
            disabled={!dayjs(startDate, 'MM/DD/YYYY').isValid()}
            onBlur={() => showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)}
            value={dayjs(endDate, 'MM/DD/YYYY').isValid() ? dayjs(endDate).format('MM/DD/YYYY') : undefined}
            onChange={(val: Dayjs) => endDateChange(val.endOf('day'))}
            toggleIcon={() => setToggleEndCalendarDisplay(!showEndCalendar)}
            name={endName} 
          />
        }
      />
    </div>
  )
};

export default CustomDateRangePicker;
