import { FunctionComponent, HTMLAttributes, useRef } from "react";
import classes from "./Pill.module.css";
import ImageImports from "../../utils/ImageImports";

const { menuCloseIcon } = ImageImports;

export type PillType = "default" | "warning" | "success" | "primary" | "dkBlue";

interface PillProps extends HTMLAttributes<HTMLSpanElement> {
  type: PillType;
  dismissible?: boolean;
  onDismiss?: () => void;
  style?: React.CSSProperties;
}

const Pill: FunctionComponent<PillProps> = ({ type, dismissible, onDismiss, className, style, ...props }) => {
  const ref = useRef<HTMLSpanElement>(null);
  return (
    <span ref={ref} style={style} className={`${classes.pill} ${classes[type]} ${className}`} {...props}>
      {props.children}
      {dismissible && (
        <>
          {" "}
          <img
            src={menuCloseIcon}
            className={classes.pillIcon}
            alt=""
            
            onClick={() => {
              onDismiss && onDismiss();
            }}
          />
        </>
      )}
    </span>
  );
};

Pill.defaultProps = {
  dismissible: false,
  onDismiss: () => {},
};

export default Pill;
