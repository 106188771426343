import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "../../utils/interface";
import EmptyModal from "../Modals/EmptyModal";
import ImageImports from "../../utils/ImageImports";
import Button from "../Button/Button";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";

const { tooltip } = ImageImports;

export const AddEditLink = ({link, onClose, onSave, onDelete}: {link: Link, onClose: () => void, onSave: (link: Link) => void, onDelete: (link: Link) => void}) => {
    const [title, setTitle] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [displayOrder, setDisplayOrder] = useState<number>(0);

    useEffect(() => {
        setTitle(link.title);
        setUrl(link.url);
        setDisplayOrder(link.displayOrder);
    }, [link]);

    const isEditing = useMemo(() => {
        return link.id > 0;
    }, [link]);

    const canSave = useMemo(() => {
        const checks: (() => boolean)[] = [
            () => title.length > 0,
            () => url.length > 0,
            () => url.match(/http(s)?:\/\/.+\.[a-zA-Z]{2,}.*/) !== null,
            () => displayOrder.toString().length > 0,
            () => Object.entries({
                    title, url, displayOrder
                }).toString() !== Object.entries({
                    title: link.title,
                    url: link.url,
                    displayOrder: link.displayOrder
                }).toString()
        ];
        let canSave = true;
        for (let i = 0; i < checks.length && canSave === true; i++) {
            canSave = checks[i]();
        }

        return canSave;
    }, [title, url, displayOrder, link]);

    const update = useCallback(() => {
        onSave({
            ...link,
            title,
            url,
            displayOrder
        });
    }, [title, url, displayOrder, link]);

    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-8 self-stretch">
                    <div className="flex flex-row !pb-0 gap-6 self-stretch">
                        <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                            <span>Link Title*</span>
                            <MiniTooltip text="Please enter the Mitsubishi Power Business Unit name.">
                                <img src={tooltip} alt="Link Title" className="h-4 w-4" />
                            </MiniTooltip>
                        </div>
                        <input type="text"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            placeholder="Title"
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                    <div className="flex flex-row !pb-0 gap-6 self-stretch">
                        <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                            <span>URL*</span>
                            <MiniTooltip text="Please enter the Mitsubishi Power Business Unit URL.">
                                <img src={tooltip} alt="Link URL" className="h-4 w-4" />
                            </MiniTooltip>
                        </div>
                        <input type="text"
                            value={url}
                            onChange={e => setUrl(e.target.value)}
                            placeholder="https://website.com"
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                    <div className="flex flex-row !pb-0 gap-6 self-stretch">
                        <div className="flex flex-row !pb-0 gap-1 basis-52 items-center">
                            <span>Display Order*</span>
                            <MiniTooltip text="Please enter the numeric placement for the Business Unit name to be displayed.">
                                <img src={tooltip} alt="Display Order" className="h-4 w-4" />
                            </MiniTooltip>
                        </div>
                        <input type="number"
                            value={displayOrder}
                            onChange={e => setDisplayOrder(Number(e.target.value))}
                            placeholder="0"
                            className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                            style={{boxShadow: '0 1px 0 #B3B3B3'}} />
                    </div>
                </div>
            )}
            footer={(
                <>
                    <div className="flex flex-row !pb-0 gap-4 justify-center">
                        <Button className="darkBlue" text="Save Changes" disabled={!canSave} onClick={update} />
                        {isEditing && <Button className="redDelete" text="Delete" onClick={() => onDelete(link)} />}
                    </div>
                    <span className="font-light text-sm">*Required fields</span>
                </>
            )}
            heading={isEditing? 'Edit Link' : 'Add a Link'}
            onClose={onClose} shown={true} />
    );
};

export const AddEditLinkConfirmation = ({link, canAddAnother, onClose, onAddAnother}: {link: Link, canAddAnother: boolean, onClose: () => void, onAddAnother: () => void}) => {

    const wasEditing = useMemo(() => {
        return link.id > 0;
    }, [link]);

    let bodyText: string = '';
    let footerButtons: ReactNode = '';
    let headingText = '';
    if (wasEditing) {
        headingText = 'Changes Saved';
        bodyText = `The details for "${link.title}" have been successfully updated.`;
        footerButtons = (
            <Button className="darkBlue" text="Done" onClick={onClose} />
        );
    } else {
        bodyText = `The "${link.title}" link has been successfully added to the Users' Group Portal.${canAddAnother? ' Would you like to add another link at this time?' : ''}`;
        headingText = 'Link Successfully Added';
        footerButtons = (
            <>
                {canAddAnother && <Button className="darkBlue" text="Add Another Link" onClick={onAddAnother} />}
                <Button className="whiteBtn" text="Done" onClick={onClose} />
            </>
        );
    }

    return (
        <EmptyModal body={(
                <span className="self-center">{bodyText}</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    {footerButtons}
                </div>
            )}
            heading={headingText}
            headingClass={'pl-16 text-center'}
            onClose={onClose} shown={true} width={564} />
    );
};

AddEditLinkConfirmation.defaultProps = {
    canAddAnother: true
};