import React, { useCallback, useContext, useReducer } from "react";
import "react-loading-skeleton/dist/skeleton.css";

import {
  HeaderDTO,
  AccountSettingsDTO, 
  IssueReportsDTO,
  IssueReportItemsDTO,
  PartsAndServicesDTO,
  PlantConfigDTO,
  PlantSiteDetailsDTO,
  DocumentsDTO,
  TypeDTO,
  ModalsStateManagementDTO,
  PlantConfigImageDTO,
  PlantEquipmentDTO,
  PlantEquipmentsDTO,
  PlantEquipmentAttachmentsDTO,
  PlantEquipmentIdDTO,
  PlantEquipmentAttachmentDTO,
  ContactAvatarImageDTO,
  PlantContactsDTO,
  ShowErrorInterface,
  ContactAccordianInterface,
  FaqInterfaceDTO,
  UnitDTO,
  UnitGetAllDTO,
  OMManualSectionGetAllDTO,
  TypeDATA,
  SelectedPlantDTO,
  PagePermissions,
  Report,
  NotificationType,
  UserGroup,
} from "../../utils/interface";
import Layout from "./Layout/Layout";
import { Store } from "../../Store";
import "./AppProvider.css";

import { ActionType } from "../../Reducers/action-types";
import { reducer } from "../../Reducers/reducer";

export const AppProviders = () => {
  const stateContext = useContext(Store);
  const [storeState, dispatch] = useReducer(reducer, stateContext);

  const setSelectedPlant = useCallback((state: SelectedPlantDTO) => {
    dispatch({ type: ActionType.SELECTED_PLANT, payload: state });
  }, []);

  const setIssueReports = useCallback((state: IssueReportsDTO) => {
    dispatch({ type: ActionType.ISSUE_REPORTS, payload: state });
  }, []);

  const setIssueReportItems = useCallback((state: IssueReportItemsDTO) => {
    dispatch({ type: ActionType.ISSUE_REPORT_ITEMS, payload: state });
  }, []);

  const setHeader = useCallback((state: HeaderDTO) => {
    dispatch({ type: ActionType.HEADER, payload: state });
  }, []);

  const setNotificationTypes = useCallback((state: NotificationType[]) => {
    dispatch({ type: ActionType.NOTIFICATION_TYPES, payload: state });
  }, []);

  const setAccountSettings = useCallback((state: AccountSettingsDTO) => {
    dispatch({ type: ActionType.ACCOUNT_SETTINGS, payload: state });
  }, []);

  const setShowA2hs = useCallback((state: boolean) => {
    dispatch({ type: ActionType.A2HS, payload: state });
  }, []);

  const setPartsAndServices = useCallback((state: PartsAndServicesDTO) => {
    dispatch({ type: ActionType.PARTS_AND_SERVICES, payload: state });
  }, []);

  const setPlantConfig = useCallback((state: PlantConfigDTO) => {
    dispatch({ type: ActionType.PLANT_CONFIG, payload: state });
  }, []);

  const setContactAvatarImage = useCallback((state: ContactAvatarImageDTO[]) => {
    dispatch({ type: ActionType.CONTACT_AVATAR_IMAGE, payload: state });
  }, []);

  const setPlantEquipment = useCallback((state: PlantEquipmentDTO) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENT, payload: state });
  }, []);

  const setPlantEquipments = useCallback((state: PlantEquipmentsDTO) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENTS, payload: state });
  }, []);

  const setShowError = useCallback((state: ShowErrorInterface) => {
    dispatch({ type: ActionType.SHOW_ERROR, payload: state });
  }, []);
  const setShowModalError = useCallback((state: ShowErrorInterface) => {
    dispatch({ type: ActionType.SHOW_MODAL_ERROR, payload: state });
  }, []);

  const setPlantEquipmentId = useCallback((state: PlantEquipmentIdDTO) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENTS_ID, payload: state });
  }, []);

  const setPlantEquipmentsAttachments = useCallback((state: PlantEquipmentAttachmentsDTO) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENT_ATTACHMENTS, payload: state });
  }, []);

  const setPlantEquipmentsAttachment = useCallback((state: PlantEquipmentAttachmentDTO) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENT_ATTACHMENT, payload: state });
  }, []);

  const setPlantConfigImage = useCallback((state: PlantConfigImageDTO) => {
    dispatch({ type: ActionType.PLANT_CONFIG_IMAGE, payload: state });
  }, []);

  const setDocumentsDTO = useCallback((state: DocumentsDTO) => {
    dispatch({ type: ActionType.TECHNICAL_DOCUMENTATION, payload: state });
  }, []);

  const setFuelType = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.FUEL_TYPE, payload: state });
  }, []);

  const setManufacturerType = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.MANUFACTURER_TYPE, payload: state });
  }, []);

  const setProductType = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.PRODUCT_TYPE, payload: state });
  }, []);

  const setContractBasisTypes = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.CONTRACT_BASIS_TYPES, payload: state });
  }, []);

  const setEquipmentAttachmentType = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.EQUIPMENT_ATTACHMENT_TYPE, payload: state });
  }, []);



  const setNameplate = useCallback((state: { id: number }) => {
    dispatch({ type: ActionType.NAMEPLATE, payload: state });
  }, []);

  const setSelectedPlantDetails = useCallback((state: PlantSiteDetailsDTO) => {
    dispatch({ type: ActionType.SELECTED_PLANT_DETAILS, payload: state });
  }, []);

  const setTypePlantContacts = useCallback((state: PlantContactsDTO) => {
    dispatch({ type: ActionType.TYPE_PLANT_CONTACTS, payload: state });
  }, []);

  const setTypeMitsubishiContacts = useCallback((state: PlantContactsDTO) => {
    dispatch({ type: ActionType.TYPE_MITSUBISHI_CONTACTS, payload: state });
  }, []);

  const toggleTooltip = useCallback((state: boolean) => {
    dispatch({ type: ActionType.TOOLTIP, payload: state });
  }, []);

  const setToolTipContent = useCallback((state: React.ReactElement) => {
    dispatch({ type: ActionType.TOOLTIP_CONTENT, payload: state });
  }, []);

  const toggleSidebar = useCallback((state: boolean) => {
    dispatch({ type: ActionType.SIDEBAR_TOGGLE, payload: state });
  }, []);

  const setIsModal = useCallback((state: ModalsStateManagementDTO) => {
    dispatch({ type: ActionType.IS_MODAL, payload: state });
  }, []);

  const toggleMobileNav = useCallback((state: boolean) => {
    dispatch({ type: ActionType.MOBILE_NAV_TOGGLE, payload: state });
  }, []);

  const setAccordian_tab = useCallback((state: number) => {
    dispatch({ type: ActionType.ACCORDIAN_TAB, payload: state });
  }, []);

  const setActiveLocation = useCallback((location: string) => {
    dispatch({ type: ActionType.TRACK_LOCATION, payload: location });
  }, []);

  const setFaq = useCallback((state: FaqInterfaceDTO) => {
    dispatch({ type: ActionType.FAQ_CONTENT, payload: state });
  }, []);

  const setUnit = useCallback((state: UnitDTO) => {
    dispatch({ type: ActionType.UNIT, payload: state });
  }, []);

  const setUnitGetAll = useCallback((state: UnitGetAllDTO) => {
    dispatch({ type: ActionType.UNIT_GET_ALL, payload: state });
  }, []);

  const setUnitTypes = useCallback((state: TypeDATA) => {
    dispatch({ type: ActionType.UNIT_TYPES, payload: state });
  }, []);

  const setOMManualSectionGetAll = useCallback((state: OMManualSectionGetAllDTO) => {
    dispatch({ type: ActionType.OMMANUAL_SECTION_GET_ALL, payload: state });
  }, []);

  const setEquipmentTypes = useCallback((state: TypeDATA) => {
    dispatch({ type: ActionType.PLANT_EQUIPMENT_TYPES, payload: state });
  }, []);

  const setUnitConfigurationTypes = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.UNIT_CONFIGURATION_TYPES, payload: state });
  }, []);

  const setEquipmentModelNameTypes = useCallback((state: TypeDTO) => {
    dispatch({ type: ActionType.EQUIPMENT_MODAL_NAME_TYPES, payload: state });
  }, []);

  const setGlobalUniqueIds = useCallback((state: string[]) => {
    dispatch({type: ActionType.GLOBAL_UNIQUE_IDS, payload: state});
  }, []);

  const setReport = useCallback((state: Report) => {
    dispatch({type: ActionType.REPORT, payload: state});
  }, []);

  const setUserGroups = useCallback((payload: UserGroup[]) => {
    dispatch({type: ActionType.USER_GROUPS, payload});
  }, []);

  const setSelectedUserGroup = useCallback((payload: UserGroup) => {
    dispatch({type: ActionType.USER_GROUP, payload});
  }, []);

  const providerProps = {
    ...storeState,
    setPlantConfig,
    setUnit,
    setEquipmentTypes,
    setUnitTypes,
    setUnitGetAll,
    setOMManualSectionGetAll,
    setReport,
    setUnitConfigurationTypes,
    setFaq,
    setEquipmentModelNameTypes,
    setTypePlantContacts,
    setTypeMitsubishiContacts,
    setManufacturerType,
    setEquipmentAttachmentType,
    setPlantEquipment,
    setPlantEquipmentsAttachments,
    setPlantEquipmentsAttachment,
    setContractBasisTypes,
    setPlantEquipments,
    setPlantEquipmentId,
    setPlantConfigImage,
    setProductType,
    setShowError,
    setShowModalError,
    setAccordian_tab,
    setHeader,
    setNotificationTypes,
    setAccountSettings,
    setSelectedPlant,
    setIsModal,
    setDocumentsDTO,
    setNameplate,
    setFuelType,
    setPartsAndServices,
    toggleTooltip,
    setToolTipContent,
    toggleSidebar,
    toggleMobileNav,
    setActiveLocation,
    setSelectedPlantDetails,
    setIssueReports,
    setIssueReportItems,
    setContactAvatarImage,
    setGlobalUniqueIds,
    setUserGroups,
    setSelectedUserGroup,
    setShowA2hs,
  };

  return (
    <Store.Provider value={providerProps}>
      <Layout />
    </Store.Provider>
  );
};

export default AppProviders;
