
import React, {ReactElement, useContext} from 'react';
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from '../../../components/Modals/EmptyModal';
import { DocumentGroup } from '../../../utils/interface';
import Button from '../../../components/Button/Button';
import useWindowSize from '../../../customHooks/useWindowSize';
import { Store } from '../../../Store';
import { useOutletContext } from 'react-router-dom';
import { DocumentsModuleOutletInterface } from '../../Documents/DocumentsModuleContainer';

interface NewGroupConfirmationModalProps {
    shown: boolean;
    reportType: DocumentGroup|null;
    onClose: () => void;
    addNewGroup: () => void;
    addNewDocument: () => void;
}

function NewGroupConfirmationModal(props: NewGroupConfirmationModalProps) {
    const {isMobile} = useWindowSize();
    const {selectedPlant} = useContext(Store);
    const {documentModule} = useOutletContext<DocumentsModuleOutletInterface>();
    
    let width: number = 564;
    if (isMobile) {
        width = 300;
    }
    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <span className={classes.modal_body_text}>"{props.reportType?.name}" was successfully added as a new document group to the {documentModule.name} page. Users with the accepted permissions may now add documents to this group for customers.</span>
                <span className={classes.modal_body_text}>Would you like to add another document group to the {documentModule.name} page? You may also add a new document to "{props.reportType?.name}" for {selectedPlant.name}.</span>
                
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                    <Button className="darkBlue" text="Add New Document Group" onClick={props.addNewGroup} />
                    <Button className="whiteBtn" text="Add Document" onClick={props.addNewDocument} />
                    <button className={`${classes.done_button} ${classes.flex_row}`} onClick={props.onClose}>Done</button>
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Document Group Successfully Added" onClose={props.onClose} width={width} footerClass={classes.modal_footer} />
    );
}

export default NewGroupConfirmationModal;