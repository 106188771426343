import React, { useState, useContext, useEffect, useRef, MouseEvent, MouseEventHandler, StyleHTMLAttributes } from "react";
import { Store } from "../../Store";
import "./Dropdown.css";
import ImageImports from "../../utils/ImageImports";
import useScroll from "../../customHooks/useScroll";
import { SelectOptionProps, SelectPlantProps } from "../../utils/interface";

interface selectProps {
  className?: string;
  dropdowntype?: string;
  options: SelectOptionProps[] | SelectPlantProps[];
  isPlantSelect?: boolean;
  selectValue?: string;
  selectOptions?: Array<any>;
  isPlantSelection?:boolean;
  style?:React.CSSProperties;
  isError?: boolean;
  dropdownStyle?:React.CSSProperties;
  handleDropDownSelect(
    e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
	option?: any
  ): void;
  selectedOption: string | null;
  setSelectedOption: (option: string | null) => void;
}

Dropdown.defaultProps = {
  onScroll: false,
  isPlantSelection: false,
  isError: false,
};

function Dropdown(props: selectProps) {
  const [selectToggle, setSelectToggle] = useState(false);

  const selectButtonRef = useRef<HTMLDivElement>(null);
  const { downArrow } = ImageImports;
  const toggleSelect = () => setSelectToggle(!selectToggle);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== selectButtonRef.current) {
        if (path[0].id !== "drop-arrow") {
          setSelectToggle(false);
        }
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);

    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  return (
    <div style={props.style} className={"selectDropdownWrp dropDown"}>
      <div className="input-box select">
        <div
          className={`form-control selectbox  ${props.className}`}
          ref={selectButtonRef}
          id="selectDropdown"
          onClick={toggleSelect}
          style={{...props.dropdownStyle, ...(props.isError ? { backgroundColor: "#FEE2E2", color: "#991B1B" } : {})}}
        >
          {props.dropdowntype} {props.selectedOption ? props.selectedOption : props.selectValue}
          <img id="drop-arrow" src={downArrow} className={selectToggle ? "select-arrow active" : "select-arrow"} alt="" />
        </div>
      </div>
      <div
        className={
          selectToggle ? "input-box dropdown active" : "input-box dropdown"
        }
      >
        <div className="options-wrapper">         
          {props.isPlantSelection ?   props.options.map((option: any, i: number) => {           
            return (
              <div
                className="options"
                data-id={option.plantId}
                key={i}
                onClick={(e) => {
                  props.setSelectedOption(option.name);
                  props.handleDropDownSelect?.(e, option);
                }}
              >
                {option.name}
              </div>
            );
          }) : props.options.map((option: any, i: number) => {           
            return (
              <div
                className="options"
                data-id={option.id}
                key={i}
                onClick={(e) => {
                  props.setSelectedOption(option.name);
                  props.handleDropDownSelect?.(e, option);
                }}
              >
                {option.name}
              </div>
            );
          })}
          
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
