import axios, { AxiosResponse } from "axios";
import {
  apiMitsubishiPowerReport,
  getUgthread,
  getUgthreadMessage,
} from "../../endpoints/endpoints";
import {
  CPApiResponse,
  DiscussionInterface,
  createDiscussionThread,
  createMessage,
  UgMessageThreads,
  updateDiscussionThread,
  updateMessage,
} from "../../utils/interface";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

// interface starts here //
export interface DiscussionModuleApiMethods<T,D> {
  create: (data: createDiscussionThread) => Promise<T>;
  update: (data: updateDiscussionThread) => Promise<T>;
  createMessage: (data: createMessage) => Promise<D>;
  updateMessage: (data: updateMessage) => Promise<D>;
  deleteMessage: (id: number) => Promise<boolean>;
  getMessage: (id: number) => Promise<D>;
  getAll: (itemTypeId: number) => Promise<T[]>;
  get: (globalItemId: number) => Promise<T>; // changed from report to T - if issues arise this maybe why //
  delete: (id: number, reason: string) => Promise<boolean>;
  bulkMove: (ids: number[], newItemTypeId: number) => Promise<boolean>;
}
// end interface //

// General Documents section starts here //
export const DiscussionApiCrud: DiscussionModuleApiMethods<DiscussionInterface,UgMessageThreads> = {
  create: async (data: createDiscussionThread): Promise<DiscussionInterface> => {
    const response: AxiosResponse<CPApiResponse<DiscussionInterface>> = await axios(`${getUgthread}`, {
      method: "POST",
      data,
    });
    return handleAxiosResponse(response);
  },
  update: async (data: updateDiscussionThread): Promise<DiscussionInterface> => {
    const response: AxiosResponse<CPApiResponse<DiscussionInterface>> = await axios(`${getUgthread}/${data.globalItemId}`, {
      method: "PUT",
      data,
    });
    return handleAxiosResponse(response);
  },
  createMessage: async (data: createMessage): Promise<UgMessageThreads> => {
    const response: AxiosResponse<CPApiResponse<UgMessageThreads>> = await axios(`${getUgthreadMessage}`, {
      method: "POST",
      data,
    });
    return handleAxiosResponse(response);
  },
  updateMessage: async (data: updateMessage): Promise<UgMessageThreads> => {
    const response: AxiosResponse<CPApiResponse<UgMessageThreads>> = await axios(`${getUgthreadMessage}/${data.id}`, {
      method: "PUT",
      data,
    });
    return handleAxiosResponse(response);
  },
  deleteMessage: async (id: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${getUgthreadMessage}/${id}`, {
      method: "DELETE",    
    });
    return handleAxiosResponse(response);
  },
  getAll: async (itemTypeId?: number): Promise<DiscussionInterface[]> => {
    const response: AxiosResponse<CPApiResponse<DiscussionInterface[]>> = await axios(`${getUgthread}/GetAllByItemTypeId/${itemTypeId}`);
    return handleAxiosResponse(response);
  },
  get: async (globalItemId: number): Promise<DiscussionInterface> => {
    const response: AxiosResponse<CPApiResponse<DiscussionInterface>> = await axios(`${getUgthread}/${globalItemId}`);
    return handleAxiosResponse(response);
  },
  getMessage: async (id: number): Promise<UgMessageThreads> => {
    const response: AxiosResponse<CPApiResponse<UgMessageThreads>> = await axios(`${getUgthreadMessage}/${id}`);
    return handleAxiosResponse(response);
  },
  delete: async (globalItemId: number, deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${getUgthread}/${globalItemId}`, {
      method: "DELETE",
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiMitsubishiPowerReport}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};

export const useCreate_UGThread = () => {
  return useMutation(DiscussionApiCrud.create);
};
export const useCreate_UGThreadMessage = () => {
  return useMutation(DiscussionApiCrud.createMessage);
};
export const useUpdate_UGThreadMessage = () => {
  return useMutation(DiscussionApiCrud.updateMessage);
};
export const useDelete_UGThreadMessage = () => {
  return useMutation(DiscussionApiCrud.deleteMessage);
};
export const useUpdate_UGThread = () => {
  return useMutation(DiscussionApiCrud.update);
};

export const useGet_DiscussionThread = (globalItemId: number, options: Omit<UseQueryOptions<DiscussionInterface>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<DiscussionInterface>([queryKeys.discussionGroups, globalItemId], () => DiscussionApiCrud.get(globalItemId), {
    ...options,
  });
};
export const useGet_UGThreadMessage = (id: number, options: Omit<UseQueryOptions<UgMessageThreads>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<UgMessageThreads>([queryKeys.discussionGroups, id], () => DiscussionApiCrud.getMessage(id), {
    ...options,
  });
};
