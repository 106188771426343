import React, { useEffect, useState } from "react";
import { DiscussionInterface, UGMessage } from "../../../utils/interface";
import Message from "./Message";

const Messages = ({ discussionThread }: MessageProps) => {
  const [messages, setMessages] = useState<UGMessage[] | null>(null);

  useEffect(() => {
    discussionThread?.messages && setMessages(discussionThread?.messages);
  }, [discussionThread]);
  return (
    <>
      {messages &&
        messages.length > 0 &&
        messages.map((msg) => {
          return (
            <div key={msg.id}>
              <Message msg={msg} />
            </div>
          );
        })}
    </>
  );
};

interface MessageProps {
  discussionThread: DiscussionInterface | null;
}

export default Messages;
