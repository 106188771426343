import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Store } from "../../Store";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { ItemActionDefinition, ItemList, ItemListColumnDefinition, SortOptionsDefinition } from "../../components/List/ItemList";
import EmptyModal from "../../components/Modals/EmptyModal";
import Pill from "../../components/Pill/Pill";
import { createUserGroup, deleteUserGroup, updateUserGroup, useGetUsersGroups } from "../../customHooks/UserGroupHttpServices";
import { useOnModalError } from "../../customHooks/useOnModalError";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import { queryKeys } from "../../react-query/queryKeys";
import ImageImports from '../../utils/ImageImports';
import { UserGroup } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";

const { ellipsis, pencil, trash } = ImageImports;
interface FilterStructure {
    plants: 'any'|'none'|'some';
}
export const UsersGroupList = () => {
    const { header: {data: {user: {portalAdmin, mpInternal}}}, setShowError,setShowModalError, selectedUserGroup, setSelectedUserGroup } = useContext(Store);
    usePageRequiresInternalAdmin();
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [createdUserGroup, setCreatedUserGroup] = useState<UserGroup>();
    const [deleteConfirmation, setDeleteConfirmation] = useState<UserGroup>();
    const [deletedUserGroup, setDeletedUserGroup] = useState<UserGroup>();
    const [editUserGroup, setEditUserGroup] = useState<UserGroup>();
    const [editConfirmation, setEditConfirmation] = useState<UserGroup>();
    const [filters, setFilters] = useState<FilterStructure>({plants: 'any'});
    const queryClient = useQueryClient();
    const {isMobile} = useWindowSize();

    const {refetch: refetchUserGroups, data: orgGroups, isFetching} = useGetUsersGroups();
    const setErrorData = useOnModalError();

    const refetchHeader = useCallback(() => {
        queryClient.invalidateQueries([queryKeys.header, queryKeys.technicalbulletin]);
    }, [queryClient]);

    const {mutate: createOrganization, error: createOrgGroupError} = useMutation<UserGroup, AxiosError<string>, Pick<UserGroup, "name" | "description"  | "enableTechnicalBulletinList">, unknown>(createUserGroup, {
        onSuccess: (group) => {
            refetchUserGroups();
            setIsAdding(false);
            setCreatedUserGroup(group);
            refetchHeader();
        }
    });

    const {mutate: updateOrganization, error: updateOrgGroupError} = useMutation<UserGroup, AxiosError<string>, UserGroup, unknown>(updateUserGroup, {
        onSuccess: (group) => {
          if (selectedUserGroup && selectedUserGroup.id === group.id) {
            setSelectedUserGroup?.(group);
          }
          setEditUserGroup(undefined);
          setEditConfirmation(group);
          refetchHeader();
          refetchUserGroups();
        }
    });

    const {mutate: deleteOrganization} = useMutation<boolean, AxiosError<string>, number>(deleteUserGroup, {
        onSuccess: () => {
            refetchUserGroups();
            setDeleteConfirmation(undefined);
            setDeletedUserGroup(deleteConfirmation);
            refetchHeader();
        },
        onError(error) {
            setErrorData(error)
        },
    });

    const addAnother = useCallback(() => {
        setCreatedUserGroup(undefined);
        setDeletedUserGroup(undefined);
        setIsAdding(true);
    }, []);

    const filterGroups = useCallback((group: UserGroup): boolean => {
        const plantCount = group.plants? group.plants.length : 0;
        switch (filters.plants) {
            case 'any':
                return true;
            case 'none':
                return plantCount === 0;
            case 'some':
                return plantCount > 0;
        }
    }, [filters]);

    const filterCount = useMemo(() => {
        let c = 0;
        if (filters.plants !== 'any') {
            c++;
        }
        return c;
    }, [filters]);
    
    const columns: ItemListColumnDefinition<UserGroup>[] = [
        {
            title: 'Users\' Group Name',
            key: 'name'
        },
        {
            title: 'Description',
            key: 'description',
        },
        {
            title: 'Plant Associations',
            key: 'plants',
            component: (({data}) => <>{data.plants? data.plants.length : 0}</>)
        },
        {
          title: 'Technical Bulletins Visible',
          key: 'enableTechnicalBulletinList',
          component: (({data}) => <>{data.enableTechnicalBulletinList? 'Yes' : 'No'}</>)
        },
    ];
    const sortableProperties: SortOptionsDefinition<UserGroup>[] = [
        {
            id: 0,
            key: 'name',
            name: 'Name A-Z (Default)',
            direction: 'asc'
        },
        {
            id: 1,
            key: 'name',
            name: 'Name Z-A',
            direction: 'desc'
        },
        {
            id: 2,
            key: 'plants',
            name: 'Plant Associations: High to Low',
            direction: 'desc',
            manipulateFn: (data) => Array.isArray(data)? data.length : 0
        },
        {
            id: 3,
            key: 'plants',
            name: 'Plant Associations: Low to High',
            direction: 'asc',
            manipulateFn: (data) => Array.isArray(data)? data.length : 0
        }
    ];

    const actions: ItemActionDefinition<UserGroup>[] = [
        {
            text: <div className="flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap text-dark-blue cursor-pointer font-bold">
                <img src={pencil} alt="Edit Details" />
                <span>Edit Details</span>
            </div>,
            onClick(event, data) {
                setEditUserGroup(data);
            }
        }
    ];


    if (portalAdmin && mpInternal) {
        actions.push({
            text: <div className="flex flex-row !pb-0 items-center gap-[10px] whitespace-nowrap text-dark-blue cursor-pointer font-bold">
                <img src={trash} alt="Delete" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                <span>Delete</span>
            </div>,
            onClick(event, data) {
                setDeleteConfirmation(data);
            }
        });
    }
    
    const closeError = () => {    
        setShowError?.({
          title: "",
          isError: false,
          ErrorType: null,
        });
        setShowModalError?.({
          title: "",
          isError: false,
          ErrorType: null,
        });
    };
    return (
        <MainContent title="Group Management" breadCrumbs={['Administration', 'Group Management']}>
            <div className="w-full">
                {isAdding && <AddModal shown={isAdding} onClose={() => setIsAdding(false)} onAdd={createOrganization} error={createOrgGroupError} />}
                {createdUserGroup && <AddGroupConfirmation group={createdUserGroup} shown={true} onClose={() => setCreatedUserGroup(undefined)} onAddAnother={addAnother} />}
                {deleteConfirmation && <DeleteGroupConfirmation group={deleteConfirmation} shown={true} onClose={() => {setDeleteConfirmation(undefined);  closeError()}} onDelete={() => deleteOrganization(deleteConfirmation.id)} />}
                {deletedUserGroup && <DeletedGroup group={deletedUserGroup} onClose={() => {setDeletedUserGroup(undefined)}} onAddAnother={addAnother} />}
                {editUserGroup && <EditGroup group={editUserGroup} shown={true} onClose={() => setEditUserGroup(undefined)} onEdit={updateOrganization} error={updateOrgGroupError} />}
                {editConfirmation && <EditGroupConfirmation group={editConfirmation} onClose={() => setEditConfirmation(undefined)} />}
                
                    <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
                        <div className={`flex ${isMobile? 'flex-col' : 'flex-row !pb-0'} items-start gap-6 self-stretch`}>
                            <div className="flex flex-col items-start gap-2 grow">
                                <span className="font-bold text-2xl">
                                    Users' Groups
                                </span>
                                {!isMobile && <span className="text-sm">
                                    Below are the Users’ Groups that are active on this Portal.
                                </span>}
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-4 whitespace-nowrap">
                                <Button className="darkBlue" text="Add Users' Group" onClick={() => setIsAdding(true)} />
                            </div>
                        </div>
                        <hr className="m-0 text-[#B3B3B3]" />
                        <div className="self-stretch">
                            <ItemList data={(orgGroups ?? []).filter(filterGroups)}
                                columns={columns}
                                loading={isFetching}
                                sortValues={sortableProperties}
                                accordionTitle={g => g.name}
                                itemActions={actions}
                                itemActionText={() => <img src={ellipsis} alt="More Actions" className="cursor-pointer" />}
                                filterContent={<Filters filters={filters} onFiltersUpdated={setFilters} />}
                                filterCount={filterCount}
                                filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />} />
                        </div>
                    </div>
            </div>
        </MainContent>
    );
};

const AddModal = ({shown, error, onClose, onAdd}: {shown: boolean, error: AxiosError<string>|null, onClose: () => void, onAdd: (UserGroup: Pick<UserGroup, 'name' | 'description' | 'enableTechnicalBulletinList'>) => void}) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [enableTechnicalBulletin, setEnableTechnicalBulletin] = useState<string>('');

    useEffect(() => {
        setName('');
        setDescription('');
        setEnableTechnicalBulletin('');
    }, [shown]);

    const body = useMemo(() => {
        return <div className="flex flex-col items-start gap-y-6 self-stretch">
            {error && error.response && (
                <Alert type="error" onClose={() => {}} dismissible={false}>
                    {error.response.data ?? ''}
                </Alert>
            )}
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch mb-4 md:mb-0">
              <label className="w-full md:w-48" htmlFor="groupName">Users' Group Name*</label>
              <input 
                type="text"
                name="groupName"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Users' Group name"
                className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none w-full md:w-auto border-none grow text-base ${error? 'bg-[#FEE2E2] rounded' : ''}`}
                style={{boxShadow: `0 1px 0 ${error? '#FEE2E2' : '#B3B3B3'}`}} 
              />
            </div>
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch">
              <label className="w-full md:w-48" htmlFor="description">Description</label>
              <input 
                type="text"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Description"
                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow w-full md:w-auto text-base"
                style={{boxShadow: `0 1px 0 #B3B3B3`}}
              />
            </div>
            
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch">
              <span className="w-full md:w-48">Display Technical Bulletins*</span>
              <div className="flex flex-row items-center">
                <div className="flex flex-row grow items-center gap-4">
                  <div className="w-full">
                    <label>
                      <input 
                        type="radio" 
                        checked={enableTechnicalBulletin === 'yes'} 
                        value="yes" 
                        className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-none"
                        onChange={() => setEnableTechnicalBulletin('yes')}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <label className="ml-4">
                      <input 
                        type="radio" 
                        value="no" 
                        checked={enableTechnicalBulletin === 'no'} 
                        className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-none"
                        onChange={() => setEnableTechnicalBulletin('no')} 
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </div>
    }, [name, description, enableTechnicalBulletin, error]);

    const isValid = useMemo(() => {
        return name.trim().length > 0 && enableTechnicalBulletin.trim().length > 0;
    }, [name, enableTechnicalBulletin]);

    const add = useCallback(() => {
      const enableTechnicalBulletinList = enableTechnicalBulletin === 'yes' ? true : false;
        onAdd({name, description, enableTechnicalBulletinList});
    }, [name, description, enableTechnicalBulletin, onAdd]);

    return (
        <EmptyModal body={body} footer={(
            <div className="flex flex-row !pb-0 justify-between items-center pt-6 grow">
                <Button className="darkBlue" text="Add Users' Group" disabled={!isValid} onClick={add} />
                <span className="text-sm font-light text-right">*Required Fields</span>
            </div>
        )} heading="Add Users' Group" onClose={onClose} shown={shown} />
    );
};

const AddGroupConfirmation = ({shown, group, onClose, onAddAnother}: {shown: boolean, group: UserGroup, onClose: () => void, onAddAnother: () => void}) => {
    return (
        <EmptyModal body={(
                <span>"{group.name}" was successfully added to the global Mitsubishi Power organizations list. This organization is now able to be associated to plants. Would you like to add another organization at this time?</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    <Button className="darkBlue" text="Add Another Users' Group" onClick={onAddAnother} />
                    <Button className="whiteBtn" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Users' Group Successfully Added"
            headingClass="text-center"
            onClose={onClose} shown={shown} />
    );
};

const DeleteGroupConfirmation = ({shown, group, onClose, onDelete}: {shown: boolean, group: UserGroup, onClose: () => void, onDelete: () => void}) => {
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6">
                    <span>Are you sure you want to delete the selected Users’ Group from Mitsubishi Power? Any plant(s) associated to this organization will remain in the database. This cannot be undone.</span>
                    <div className="text-[#666] text-sm self-stretch">
                        {group.name}<br />
                        {group.description}
                    </div>
                </div>
            )}
            footer={(
                <div className="flex flex-row !pb-0 grow gap-4">
                    <Button className="redDelete" text="Yes, Delete" onClick={onDelete} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            )}
            heading="Delete Users' Group?"
            onClose={onClose} shown={shown} />
    );
}

const DeletedGroup = ({group, onClose, onAddAnother}: {group: UserGroup, onClose: () => void, onAddAnother: () => void}) => {
    return (
        <EmptyModal body={(
                <span>You successfully deleted the organization "{group.name}". Would you like to add another organization to Mitsubishi Power at this time?</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    <Button className="darkBlue" text="Add Users' Group" onClick={onAddAnother} />
                    <Button className="whiteBtn" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Users' Group Successfully Deleted"
            headingClass="text-center"
            onClose={onClose} shown={true} />
    );
};

const EditGroup = ({shown, error, group, onClose, onEdit}: {shown: boolean, error: AxiosError<string>|null, group: UserGroup, onClose: () => void, onEdit: (baseOrgGroup: UserGroup) => void}) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [enableTechnicalBulletinList, setEnableTechnicalBulletinList] = useState<boolean>(false);

    useEffect(() => {
        setName(group.name);
        setDescription(group.description ?? '');
        setEnableTechnicalBulletinList(group.enableTechnicalBulletinList);
    }, [group]);

    const body = useMemo(() => {
        return <div className="flex flex-col items-start gap-6 self-stretch">
            {error && error.response && (
                <Alert type="error" onClose={() => {}} dismissible={false}>
                    {error.response.data ?? ''}
                </Alert>
            )}
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch mb-4 md:mb-0">
              <label className="w-full md:w-48" htmlFor="groupName">Users' Group Name*</label>
              <input 
                type="text"
                name="groupName"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Users' Group name"
                className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base ${error? 'bg-[#FEE2E2] rounded' : ''}`}
                style={{boxShadow: `0 1px 0 ${error? '#FEE2E2' : '#B3B3B3'}`}} 
              />
            </div>
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch">
              <label className="w-full md:w-48" htmlFor="description">Description</label>
              <input 
                type="text"
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Description"
                className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                style={{boxShadow: '0 1px 0 #B3B3B3'}} 
              />
            </div>
            <div className="flex flex-col md:flex-row !pb-0 items-start md:items-center gap-x-6 gap-y-2 self-stretch">
              <span className="w-full md:w-48">Display Technical Bulletins*</span>
              <div className="flex flex-row items-center">
                <div className="flex flex-row grow items-center gap-4">
                  <div className="w-full">
                    <label>
                      <input 
                        type="radio" 
                        checked={enableTechnicalBulletinList} 
                        value="yes" 
                        className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-none"
                        onChange={() => setEnableTechnicalBulletinList(true)}
                      />
                      <span className="ml-1">Yes</span>
                    </label>
                    <label className="ml-4">
                      <input 
                        type="radio" 
                        value="no" 
                        checked={!enableTechnicalBulletinList} 
                        className="text-blue-600 bg-gray-100 border-gray-300 focus:ring-none"
                        onChange={() => setEnableTechnicalBulletinList(false)} 
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </div>
    }, [name, description, enableTechnicalBulletinList, error]);

    const isValid = useMemo(() => {
        return name.trim().length > 0 && typeof enableTechnicalBulletinList == "boolean";
    }, [name, enableTechnicalBulletinList]);

    const isDifferent = useMemo(() => {
        return name !== group.name || description !== (group.description ?? '') || enableTechnicalBulletinList !== group.enableTechnicalBulletinList;
    }, [name, description, enableTechnicalBulletinList, group]);

    const edit = useCallback(() => {
        onEdit({...group, name, description, enableTechnicalBulletinList});
    }, [name, description, enableTechnicalBulletinList, onEdit, group]);

    return (
        <EmptyModal body={body} footer={(
            <div className="flex flex-row !pb-0 justify-between items-center pt-6 grow">
                <Button className="darkBlue" text="Save Changes" disabled={!isValid || !isDifferent} onClick={edit} />
                <span className="text-sm font-light text-right">*Required Fields</span>
            </div>
        )} heading="Edit Users' Group Details" onClose={onClose} shown={shown} />
    );
};

const EditGroupConfirmation = ({group, onClose}: {group: UserGroup, onClose: () => void}) => {
    return (
        <EmptyModal body={(
                <span>The group details for "{group.name}" have been successfully updated.</span>
            )}
            footer={(
                <div className="flex flex-row !pb-0 justify-center grow gap-4">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Changes Saved"
            headingClass="text-center"
            onClose={onClose} shown={true} />
    );
};

const Filters = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    return (
        <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Plant Associations:</span>
                <label className="flex flex-row !pb-0 gap-2 items-start">
                    <input type="radio"
                            name="plantAssociations"
                            value="none"
                            onChange={e => onFiltersUpdated({...filters, plants: 'none'})}
                            checked={filters.plants === 'none'} />
                    No Plant Associations
                </label>
                <label className="flex flex-row !pb-0 gap-2 items-start">
                    <input type="radio"
                            name="plantAssociations"
                            value="some"
                            onChange={e => onFiltersUpdated({...filters, plants: 'some'})}
                            checked={filters.plants === 'some'} />
                    1 ≥ Plant Associations
                </label>
            </div>
        </div>
    );
};

const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    const plantAssociationMap = {
        some: '1 ≥ Plant Associations',
        none: 'No Plant Associations'
    };
    return (
        <div className="">
            {filters.plants !== 'any'? (
            <Pill dismissible={true}
                    onDismiss={() => onFiltersUpdated({...filters, plants: 'any'})}
                    type="default">
                {plantAssociationMap[filters.plants]}
            </Pill>
            ) : ''}
        </div>
    );
};