import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { Store } from "../../Store";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { DiscussionGroupInterface, DiscussionInterface, DocumentGroup, Permissions, SiteUnit } from "../../utils/interface";
import { ModuleIDs } from "../../utils/moduleIDs";
import classes from "./discussions.module.css";

import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { usePageRequiresUserGroupSelection } from "../../customHooks/usePageRequiresUserGroupSelection";
import { queryKeys } from "../../react-query/queryKeys";
import { MainContent } from "../MainContent/MainContent";
import AddEditDiscussion from "./ModuleSpecific/AddEditDiscussion";
import DiscussionHeader from "./ModuleSpecific/DiscussionHeader";
import { DiscussionModuleApiMethods, DiscussionApiCrud as discussionAPI } from "./discussionApiMethod";
import { CrudMethods, DiscussionApiCrud } from "./discussionGroupApiMethods";
const documentModules: DiscussionModuleInterface[] = [
  {
    id: ModuleIDs.Discussions,
    name: "Discussions",
    addEditHeaderName: "Start a Discussion",
    moduleHeaderContent: DiscussionHeader,
    addDocumentModalBodyContent: AddEditDiscussion,
    documentGroupApi: DiscussionApiCrud,
    documentApi: discussionAPI,
    addModalHeaderContent: [],
  },
];

export interface AddDocumentRequiredProps {
  setApiResponses:Dispatch<SetStateAction<{ isEdited: boolean; isSuccess: boolean; discussionData?: DiscussionInterface | undefined; }>>;
  documentGroups: DiscussionGroupInterface[];
  siteUnits: SiteUnit[];
  documentModule?: DiscussionModuleInterface;
  isEdit: boolean;
}

export interface DiscussionModuleInterface {
  name: string;
  id: number;
  addEditHeaderName: string;
  addModalHeaderContent: string[];
  moduleHeaderContent: React.FunctionComponent<{}>;
  addDocumentModalBodyContent: React.FunctionComponent<AddDocumentRequiredProps>;
  documentGroupApi: CrudMethods<DiscussionGroupInterface,DiscussionGroupInterface[]>;
  documentApi: DiscussionModuleApiMethods<any,any>;
}

export interface DocumentsOutletInterface {
  documentModules: DiscussionModuleInterface[];
  documentModule?: DiscussionModuleInterface;
}

interface DocumentsContainerProps {}

function DiscussionsContainer(props: DocumentsContainerProps) {
  usePageRequiresUserGroupSelection();
  const { isMobile } = useWindowSize();
  const { moduleName } = useParams();
  const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
  const { showError , selectedUserGroup} = useContext(Store);
  const [searchParams] = useSearchParams();
  const ugSearchParam = searchParams.get('userGroupId');
  const setErrorData = useOnError();
  
  const sortByDisplayOrderAsc = ({ displayOrder: a }: DocumentGroup, { displayOrder: b }: DocumentGroup): number => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  };

  useEffect(() => {
    setDocumentGroups([]);
  }, [moduleName]);

  const documentModule = documentModules.find((module) => module.name === moduleName);

  const { isFetching } = useQuery([queryKeys.discussionGroups,selectedUserGroup],
    documentModule ? () => documentModule.documentGroupApi.get(selectedUserGroup.id) : () => new Promise((resolve) => resolve([])),
    {
      // TODO The enabled function may need to change. We might need to check if user is on discussions before initializing this. 
      enabled:  ugSearchParam ? !!(ugSearchParam && Number(ugSearchParam) === selectedUserGroup.id) : !!selectedUserGroup.id,
      //enabled: !!selectedUserGroup.id,
      onSuccess: (response: DocumentGroup[]) => {
        setDocumentGroups(response.sort(sortByDisplayOrderAsc));
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );


  return (
    <MainContent title={documentModule?.name || ''} breadCrumbs={[documentModule?.name || '']}>
      <div className={`${classes.flex_column} ${classes.documentation_container} ${isMobile ? classes.is_mobile : ""}`}>
        {documentModule && (
          <div className={`card ${classes.flex_column} ${classes.documentation_container_outlet}`}>
            <div className={`${classes.module_container} ${classes.flex_column}`}>
              {!isFetching && <Outlet context={{ documentModules, documentModule, documentGroups: isFetching ? [] : documentGroups, setDocumentGroups }} />}
            </div>
          </div>
        )}
      </div>
    </MainContent>
  );
}

export default DiscussionsContainer;
