import { TypeDTO, RequestPriorityDTO, QuoteFormInterfaceDTO, RequestQuoteDTO } from "../../utils/interface";
import { apiGetAllOutageSeasons, apiGetAllPartsRequestReasons, apigetAllQuoteRequestTypes, apiGetAllQuoteTypes, apigetAllRequestPriority, apiquoterequest, apiquoterequestCreateAttachment } from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { useContext } from "react";

export interface QuoteRequestGetAllMethods<A, B, C> {
  getAllQuoteRequestType: () => Promise<A>;
  getAllRequestPriority: () => Promise<B>;
  getAllOutageSeasons: () => Promise<B>;
  getAllPartsRequestReasons: () => Promise<B>;
  getAllQuoteTypes: () => Promise<B>;
  // create: (data: string) => Promise<C>;
  // update: (data: string) => Promise<C>;
  //   delete: (data: D) => Promise<DR>;
}


export const QuoteRequestGetAllHttpServices: QuoteRequestGetAllMethods<
  AxiosResponse<TypeDTO>,
  AxiosResponse<RequestPriorityDTO>,
  QuoteFormInterfaceDTO
> = {
  getAllQuoteRequestType: async (): Promise<AxiosResponse<TypeDTO>> => {
    const response = await axios(`${apigetAllQuoteRequestTypes}`);
    return response.data;
  },
  getAllRequestPriority: async (): Promise<AxiosResponse<RequestPriorityDTO>> => {
    const response = await axios(`${apigetAllRequestPriority}`);
    return response.data;
  },
  getAllOutageSeasons: async ():  Promise<AxiosResponse<RequestPriorityDTO>>  => {
    const response = await axios(`${apiGetAllOutageSeasons}`);
    return response.data;
  },
  getAllPartsRequestReasons: async ():  Promise<AxiosResponse<RequestPriorityDTO>>  => {
    const response = await axios(`${apiGetAllPartsRequestReasons}`);
    return response.data;
  },
  getAllQuoteTypes: async ():  Promise<AxiosResponse<RequestPriorityDTO>>  => {
    const response = await axios(`${apiGetAllQuoteTypes}`);
    return response.data;
  },
};


export const useGetAll_QuoteRequestType = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllQuoteRequestType, QuoteRequestGetAllHttpServices.getAllQuoteRequestType, {
    onSuccess,
    onError,
  });
};
export const useGetAll_RequestPriority = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllRequestPriority, QuoteRequestGetAllHttpServices.getAllRequestPriority, {
    onSuccess,
    onError,
  });
};
export const useGetAll_OutageSeasons = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllOutageSeasons, QuoteRequestGetAllHttpServices.getAllOutageSeasons, {
    onSuccess,
    onError,
  });
};
export const useGetAll_PartsRequestReasons = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllPartsRequestReasons, QuoteRequestGetAllHttpServices.getAllPartsRequestReasons, {
    onSuccess,
    onError,
  });
};
export const useGetAll_QuoteTypes= (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.getAllQuoteTypes, QuoteRequestGetAllHttpServices.getAllQuoteTypes, {
    onSuccess,
    onError,
  });
};




export interface QuoteRequestCrudMethods<A> {
  create: (data: FormData) => Promise<A>;
  createQuoteRequestAttachment: (data: {
    quoteRequestId: number;
    name: string;
    quoteRequestAttachmentTypeId: number;
    file: string;
  }) => Promise<A>;
  //   delete: (data: D) => Promise<DR>;
}


export const QuoteRequestCrudHttpServices: QuoteRequestCrudMethods<QuoteFormInterfaceDTO> = {
  create: async (data: FormData): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiquoterequest}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  createQuoteRequestAttachment: async (data: {
    quoteRequestId: number;
    name: string;
    quoteRequestAttachmentTypeId: number;
    file: string;
  }): Promise<QuoteFormInterfaceDTO> => {
    const response = await axios({
      url: `${apiquoterequestCreateAttachment}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  //   update: async (data: string): Promise<QuoteFormInterfaceDTO> => {
  //     const response = await axios({
  //       url: `${apiquoterequest}/${data}`,
  //       method: "PUT",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     });
  //     return response.data;
  //   },
};

export const useCreate_RequestQuote = () => {
  return useMutation(QuoteRequestCrudHttpServices.create);
};
export const useCreate_QuoteRequestAttachment = () => {
  return useMutation(QuoteRequestCrudHttpServices.createQuoteRequestAttachment);
};


