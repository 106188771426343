import React from "react";
import { useIsFetching } from "react-query";
import classes from "./PlantSiteDataBlock.module.css";
import Skeleton from "react-loading-skeleton";
import { queryKeys } from "../../../react-query/queryKeys";

const PlantSiteDataBlock = (props: PlantSiteDataBlockprops) => {
  const isFetching = useIsFetching([queryKeys.plantSiteDetails]);
  return (
    <div className={`${classes.dataWrp} ${props.className}`}>
      {isFetching > 0 ? (
        <Skeleton height={56}  />
      ) : (
        <>
          <div className={classes.dataLabel}>
            <strong>{props.label}</strong>
          </div>
          {props.value1 && <div className={classes.dataValue}>{props.value1}</div>}
          {props.value2 && <div className={classes.dataValue}>{props.value2}</div>}
          {props.value3 && <div className={classes.dataValue}>{props.value3}</div>}
          {props.numberValue && <div className={classes.dataValueNumber}>{props.numberValue}</div>}
        </>
      )}
    </div>
  );
};

interface PlantSiteDataBlockprops {
  label: string;
  value1?: string;
  value2?: string;
  value3?: string;
  numberValue?: string;
  className?: string;
}

PlantSiteDataBlock.defaultProps = {
  className: ''
};

export default PlantSiteDataBlock;
