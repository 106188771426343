import axios, { AxiosError, AxiosResponse } from "axios";
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, useQuery, UseQueryOptions } from "react-query";
import { apiPlantConfig, apiPlantConfigByUserGroup, orgGroupApiUrl, ugBaseOrgApiUrl, userGroupApiEndpoint } from "../endpoints/endpoints";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { BaseOrgGroup, BaseOrgGroupDTO, CPApiResponse, PlantConfigData } from "../utils/interface";


declare type GetPlantConfigParameters = ['plantconfig', {plantId: number}];
const getPlantConfig = async ({queryKey}: QueryFunctionContext<GetPlantConfigParameters>): Promise<PlantConfigData> => {
    const [_key, {plantId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<PlantConfigData>> = await axios(`${apiPlantConfig}/${plantId}`);
    return handleAxiosResponse(response);
};
export const useGetPlantConfig = (plantId: number, options: Omit<UseQueryOptions<PlantConfigData, unknown, PlantConfigData, GetPlantConfigParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<PlantConfigData, unknown, PlantConfigData, GetPlantConfigParameters>(['plantconfig', {plantId}], getPlantConfig, options);
};

declare type GetPlantConfigByUserGroupParameters = ['plantconfig', {userGroupId: number}];
const getPlantConfigByUserGroup = async ({}: QueryFunctionContext<GetPlantConfigByUserGroupParameters>): Promise<PlantConfigData[]> => {
    const response: AxiosResponse<CPApiResponse<PlantConfigData[]>> = await axios(`${apiPlantConfigByUserGroup}`, {
        headers: {
            baseorg: 0
        }
    });
    return handleAxiosResponse(response);
};
export const useGetPlantConfigByUserGroup = (userGroupId: number, options: Omit<UseQueryOptions<PlantConfigData[], unknown, PlantConfigData[], GetPlantConfigByUserGroupParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<PlantConfigData[], unknown, PlantConfigData[], GetPlantConfigByUserGroupParameters>(['plantconfig', {userGroupId}], getPlantConfigByUserGroup, options);
};

declare type GetAllPlantsConfigParameters = ['plantConfig'];
const getAllPlantsConfig = async (): Promise<PlantConfigData[]> => {
    const response: AxiosResponse<CPApiResponse<PlantConfigData[]>> = await axios(`${apiPlantConfig}`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetAllPlantsConfig = (options: Omit<UseQueryOptions<PlantConfigData[], unknown, PlantConfigData[], GetAllPlantsConfigParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<PlantConfigData[], unknown, PlantConfigData[], GetAllPlantsConfigParameters>(['plantConfig'], getAllPlantsConfig, options);
}

declare type GetAllBaseOrgGroupsParameters = ['baseOrgGroups'];
const getAllBaseOrgGroups = async (): Promise<BaseOrgGroup[]> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup[]>> = await axios(orgGroupApiUrl);
    return handleAxiosResponse(response);
}

declare type AddUserGroupBaseOrgParameters = {
    userGroupId: number;
    baseOrgId: number;
};

export const updatePlantUserGroups = async (data: {baseOrg: number; userGroup: number;}): Promise<PlantConfigData> => {
    const response: AxiosResponse<CPApiResponse<PlantConfigData>> = await axios(`${userGroupApiEndpoint}/add-baseorg`, {
        method: 'POST',
        data: {
            userGroupId: data.userGroup, 
            baseOrgId: data.baseOrg,
        },
    });
    return handleAxiosResponse(response);
}

export const removeUserGroupAssoc = async (data: { baseOrg: number; userGroup: number; }): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${userGroupApiEndpoint}/BaseOrgUserGroup/Delete/${data.userGroup}`, {
        method: 'DELETE',
        data: {
            userGroupId: data.userGroup, 
            baseOrgId: data.baseOrg, 
        },
    });
    return handleAxiosResponse(response);
}


const addUserGroupToBaseOrg: MutationFunction<PlantConfigData, AddUserGroupBaseOrgParameters> = async (data: AddUserGroupBaseOrgParameters): Promise<PlantConfigData> => {
    const response: AxiosResponse<CPApiResponse<PlantConfigData>> = await axios(`${userGroupApiEndpoint}/add-baseorg`, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};

export const useAddUserGroupToBaseOrg = (options: Omit<MutationOptions<PlantConfigData, AxiosError<string>, AddUserGroupBaseOrgParameters>, 'mutateFn'> = {}) => {
    
    return useMutation(addUserGroupToBaseOrg, options);
};

const removeUserGroupFromBaseOrg: MutationFunction<boolean, AddUserGroupBaseOrgParameters> = async (data: AddUserGroupBaseOrgParameters): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${userGroupApiEndpoint}/BaseOrgUserGroup/Delete/${data.userGroupId}`, {
        method: 'DELETE',
        data
    });
    return handleAxiosResponse(response);
};

export const useRemoveUserGroupFromBaseOrg = (options: Omit<MutationOptions<boolean, AxiosError<string>, AddUserGroupBaseOrgParameters>, 'mutateFn'> = {}) => {
    return useMutation(removeUserGroupFromBaseOrg, options);
};

export const useGetAllBaseOrgGroups = (options: Omit<UseQueryOptions<BaseOrgGroup[], unknown, BaseOrgGroup[], GetAllBaseOrgGroupsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<BaseOrgGroup[], unknown, BaseOrgGroup[], GetAllBaseOrgGroupsParameters>(['baseOrgGroups'], getAllBaseOrgGroups, {
        initialData: [],
        ...options
    });
}

declare type GetBaseOrgUserGroupsParameters = ['baseOrgUserGroups', { baseOrgId: number }];
const getBaseOrgUserGroups = async ({ queryKey }: QueryFunctionContext<GetBaseOrgUserGroupsParameters>): Promise<BaseOrgGroupDTO> => {
    const [_key, {baseOrgId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<BaseOrgGroupDTO>> = await axios(`${ugBaseOrgApiUrl}/${baseOrgId}`);
    return handleAxiosResponse(response);
}

export const useGetBaseOrgUserGroups = (baseOrgId: number, options: Omit<UseQueryOptions<BaseOrgGroupDTO, unknown, BaseOrgGroupDTO, GetBaseOrgUserGroupsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<BaseOrgGroupDTO, unknown, BaseOrgGroupDTO, GetBaseOrgUserGroupsParameters>(['baseOrgUserGroups', { baseOrgId }], getBaseOrgUserGroups, options);
}

export const createBaseOrgGroup: MutationFunction<BaseOrgGroup, Pick<BaseOrgGroup, 'name' | 'description'>> = async (data: Pick<BaseOrgGroup, 'name' | 'description'>): Promise<BaseOrgGroup> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup>> = await axios(orgGroupApiUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};

export const updateBaseOrgGroup: MutationFunction<BaseOrgGroup, BaseOrgGroup> = async (data: BaseOrgGroup): Promise<BaseOrgGroup> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup>> = await axios(`${orgGroupApiUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
}


export const deleteBaseOrgGroup: MutationFunction<boolean, number> = async (orgGroupId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${orgGroupApiUrl}/${orgGroupId}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
}