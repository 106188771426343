
import React, { CSSProperties, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useCreatePageContent, useGetPageContent, useUpdatePageContent } from "../../customHooks/PageContentHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import Alert from "../Alert/Alert";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";
import { maxFileSizeValidator } from "../UI/Form/DragDropField/DragDropFieldFormik";
import Editor from "../UI/RichTextEditor/Editor";
import './GroupDescription.css';

const { pencil, arrowDown } = ImageImports;
const defaultContentMaxHeight = 696;

export const GroupDescription = () => {
    const [readMore, setReadMore] = useState<boolean>(false);
    const [isEditDescription, setIsEditDescription] = useState<boolean>(false);
    const { selectedUserGroup } = useContext(Store);
    const [content, setContent] = useState<string>('');
    const { isTablet, isDesktop } = useWindowSize();
    const [needsReadMore, setNeedsReadMore] = useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const { data: pageContent, refetch: getPageContent } = useGetPageContent();
    const { mutate: createPageContent } = useCreatePageContent({
        onSuccess: () => {
            setIsEditDescription(false);
            getPageContent();
        }
    });
    const { mutate: updatePageContent } = useUpdatePageContent({
        onSuccess: () => {
            setIsEditDescription(false);
            getPageContent();
        }
    });

    const containerStyle: CSSProperties = useMemo(() => {
        if (readMore) {
            return {};
        }
        return {
            maxHeight: `${defaultContentMaxHeight}px`
        };
    }, [readMore]);

    const saveChanges = useCallback((content: string) => {
        // save content
        if (pageContent && pageContent.id > 0) {
            updatePageContent({
                ...pageContent,
                contentMessage: content
            });
        } else {
            createPageContent({
                contentMessage: content,
                contentTitle: `${selectedUserGroup.name} Users' Group Description`,
                created: '',
                createdBy: '',
                globalItemId: 0,
                id: 0,
                userGroupId: selectedUserGroup.id
            });
        }
    }, [createPageContent, pageContent, selectedUserGroup, updatePageContent]);

    useEffect(() => {
        setContent(pageContent?.contentMessage || '')
    }, [pageContent]);

    useEffect(() => {
        if (contentRef.current) {
            setNeedsReadMore(contentRef.current?.getBoundingClientRect().height === defaultContentMaxHeight);
        }
    }, [content, contentRef]);

    return (
        <div className="flex flex-col items-start p-6 gap-6 bg-white shadow-[0_20px_25px_-5px_rgba(0,0,0,0.05)] rounded-lg w-full">
            {isEditDescription && <EditDescriptionModal onClose={() => setIsEditDescription(false)} saveChanges={saveChanges} content={content} onError={console.log} />}
            <div className="flex flex-row !pb-0 justify-between items-start gap-2 self-stretch">
                <span className="font-bold text-xl">{selectedUserGroup.name} Users&apos; Group Description</span>
                <RequiresAdmin><img src={pencil} alt="Edit" className="cursor-pointer" onClick={() => setIsEditDescription(true)} /></RequiresAdmin>
            </div>
            {/** RTE Content Goes Here */}
            <div ref={contentRef} className={`self-stretch rte-content ${isTablet ? 'is-tablet' : ''} ${isDesktop ? 'is-desktop' : ''}`} dangerouslySetInnerHTML={{ __html: content }} style={containerStyle} />
            {/** End RTE Content */}
            {!readMore && needsReadMore && (
                <div className="flex flex-row !pb-0 items-center gap-[10px] self-stretch cursor-pointer" onClick={() => setReadMore(true)}>
                    <span className="text-dark-blue font-bold text-sm">Read More</span>
                    <img src={arrowDown} alt="Read More" />
                </div>
            )}
        </div>
    );
};

const EditDescriptionModal = ({ content, onClose, saveChanges, onError }: { content: string, onClose: () => void, saveChanges: (content: string) => void, onError: (err: Error) => void }) => {
    const { selectedUserGroup } = useContext(Store);    
    const [descriptionText, setDescriptionText] = useState(content)

    const [, setAttachment] = useState<File>();
    const [attachmentError, setAttachmentError] = useState<string>();
    
    const addFile = (attachment: File) => {
        const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"]
        if (!allowedFileTypes.includes(attachment.type)) {
            setAttachmentError("File type not allowed");
        }
        else if (attachment) {
            // 64mb
            const fileTooLarge = maxFileSizeValidator(attachment, 67108864);
            if (!fileTooLarge) setAttachment(attachment);
            else setAttachmentError("File Size is larger than 1GB");
        }
    }
    const handleContentChange = (val: string) => {
        if (val !== descriptionText) {
            setDescriptionText(val)
        }
    };
    
    return (
        <ErrorBoundary onError={onError}>
             <EmptyModal 
             body={<>
                     {attachmentError && <Alert onClose={() => setAttachmentError(undefined)} type="error">{attachmentError}</Alert>}                        
                 <Editor handleChange={handleContentChange} content={descriptionText} allowUploads externalImage />
                 </>}
             footer={(
                 <div className="flex flex-row !pb-0 gap-4">
                     <Button className="darkBlue" text="Save Changes" onClick={() => saveChanges(descriptionText)} />
                     <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                 </div>
             )}
             heading={`Edit ${selectedUserGroup.name} Users' Group Description`}
             onClose={onClose}
             shown={true} />
        </ErrorBoundary>
    );
};

class ErrorBoundary extends React.Component<{ children: ReactElement, onError: (err: Error) => void }, { hasError: boolean }> {
    constructor(props: { children: ReactElement, onError: (err: Error) => void }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(err: Error) {
        return { hasError: true };
    }

    componentDidCatch(err: Error) {
        this.props.onError(err);
    }

    render() {
        if (this.state.hasError) {
            return <></>;
        }
        return this.props.children;
    }
}