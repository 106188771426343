import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import { usePermissions } from "../../customHooks/useUserPermissions";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import classes from "./discussions.module.css";

const { gear } = ImageImports;

interface HeaderButtonsInterface {
  addButtonText: string;
  customClass: string;
  addButtonClass: string;
  manageButtonClass: string;
}

function HeaderButtons(props: HeaderButtonsInterface) {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const { header: {data: {user}} } = useContext(Store);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '0');

  const {hasPermission, hasOnePermission} = usePermissions();

  return (
    <div className={`${classes.header_buttons} w-full justify-end items-center ${isMobile ? classes.flex_column : classes.flex_row} ${props.customClass}`}>
      {hasPermission('DiscussionCategories', 'edit') && (
        <Button
          className={`whiteBtn manage-button ${props.manageButtonClass}`}
          text={
            <div className={classes.manageButtonImage}>
              <img src={gear} alt="Manage Categories" /> Manage Categories
            </div>
          }
          onClick={() => groupId > 0? navigate("../manage-groups") : navigate('./manage-groups')}
        />
      )}
      {hasOnePermission(['Discussions', 'DiscussionPosts', 'DiscussionThreads'], 'add') && (
        <Button
          className={`darkBlue add-button ${props.addButtonClass}`}
          text={props.addButtonText}
          onClick={() => navigate("./adddiscussion")}
          style={{ order: isMobile || isTablet ? "-1" : "unset" }}
          disabled={groupId === 0}
        />
      )}
    </div>
  );
}

HeaderButtons.defaultProps = {
  addButtonText: " Start Discussion",
  customClass: "",
  addButtonClass: "",
  manageButtonClass: "",
};

export default HeaderButtons;
