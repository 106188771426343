
// add new events here, with the type of data to include in the event handler's 'detail' property
type EventDataMap = {
    toggleAccountSettingsModal: boolean;
};

export const subscribe = <T extends EventNames>(eventName: T, listener: CpEventListener<T>) => {
    document.addEventListener(eventName, listener);
};
export const unsubscribe = <T extends EventNames>(eventName: T, listener: CpEventListener<T>) => {
    document.removeEventListener(eventName, listener)
};
export const publish = <T extends EventNames>(eventName: T, detail: EventDataMap[T]) => {
    const event = new CustomEvent(eventName, {detail});
    document.dispatchEvent(event);
};
export interface CpEvent<T extends EventNames> extends CustomEvent<EventDataMap[T]> {};
export interface CpEventListener<T extends EventNames> {
    (evt: CpEvent<T>): void;
};

// don't need to change anything here.
type EventNames = keyof EventDataMap;
declare global {
    interface Document {
        addEventListener<T extends EventNames>(type: T, listener: (this: Document, ev: CpEvent<T>) => void): void;
        dispatchEvent<T extends EventNames>(ev: CpEvent<T>): void;
        removeEventListener<T extends EventNames>(type: T, listener: (this: Document, ev: CpEvent<T>) => void): void;
    }
}