import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DiscussionModuleOutletInterface } from "./DiscussionModuleContainer";


export const DiscussionsModuleIndex: FunctionComponent = () => {
    const {documentGroups, documentModule} = useOutletContext<DiscussionModuleOutletInterface>();
    const navigate = useNavigate();
    useEffect(() => {
        if (documentGroups.length > 0) {
            navigate(`./${documentGroups[0].id}`);
        }
    }, [documentGroups]);

    const ModuleHeaderContent = useMemo(() => {
      return documentModule.moduleHeaderContent;
    }, [documentModule]);

    return <>
        <div>
            <div className={``}><ModuleHeaderContent /></div>
        </div>
    </>;
};
