import React, { useState, ReactElement } from "react";
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from "../../../components/Modals/EmptyModal";
import Button from "../../../components/Button/Button";
import useWindowSize from "../../../customHooks/useWindowSize";
import { useOutletContext } from "react-router-dom";
import { DocumentsModuleOutletInterface } from "../../Documents/DocumentsModuleContainer";

interface AddNewDocumentGroupModalProps {
  shown: boolean;
  onCreate: (name: string) => void;
  onClose: () => void;
}

function AddNewDocumentGroupModal(props: AddNewDocumentGroupModalProps) {
  const [documentName, setDocumentName] = useState<string>("");
  const { isMobile } = useWindowSize();
  const { documentModule } = useOutletContext<DocumentsModuleOutletInterface>();

  let width: number | undefined;
  if (isMobile) {
    width = 300;
  }

  let modalBody: ReactElement | undefined;
  let modalFooter: ReactElement | undefined;
  if (props.shown) {
    modalBody = (
      <>
        <div className={`${classes.modal_body_input_group} ${classes.flex_row}`}>
          <span>Category Name*</span>
          <div className={`${classes.modal_body_input_container} ${classes.flex_column}`}>
            <input
              type="text"
              required
              className={classes.modal_body_input}
              placeholder="ex. Auxiliary Systems"
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          </div>
        </div>
      </>
    );

    modalFooter = (
      <>
        <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
          <button className="darkBlue primaryBtn" type="submit" disabled={!(documentName.length > 0)}>
            Add Category
          </button>
          <Button className={`whiteBtn`} text="Cancel" onClick={props.onClose} />
        </div>
        <span className={classes.modal_footer_required_fields}>*Required Fields</span>
      </>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onCreate(documentName);
        props.onClose();
        setDocumentName("");
      }}
    >
      <EmptyModal
        shown={props.shown}
        body={modalBody}
        footer={modalFooter}
        heading="Add New Discussion Category"
        width={width}
        onClose={props.onClose}
        footerClass={classes.modal_footer}
      />
    </form>
  );
}

export default AddNewDocumentGroupModal;
