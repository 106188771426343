import React, { useContext, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Store } from "../../Store";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import Footer from "../../components/Footer/Footer";
import Link from "../../components/Link/Link";
import usePlantSiteDetailsApiCalls from "../../components/PlantSiteDetails/usePlantSiteDetailsApiCalls";
import { useOnError } from "../../customHooks/useOnError";
import { usePageRequiresInternalAdmin } from "../../customHooks/usePageRequiresInternalAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import { ListGroup, SiteUnit } from "../../utils/interface";
import { MainContent } from "../MainContent/MainContent";
import { ListModuleApiMethod, UserManagementCRUD } from "./ListApiMethods";
import classes from "./ListApiMethods.module.css";
import DataReportHeader from "./ModuleSpecific/DataReportHeader";
import NotificationHeader from "./ModuleSpecific/NotificationHeader";
import OrganizationManagementHeader from "./ModuleSpecific/OrganizationManagementHeader";
import PlantManagementHeader from "./ModuleSpecific/PlantManagementListHeader";
import RoleConfigurationHeader from "./ModuleSpecific/RoleConfigurationHeader";
import UserManagementHeader from "./ModuleSpecific/UserManagementHeader";
const listModules: ListModuleInterface[] = [
  {
    id: 6,
    name: "Organization Management",
    addEditHeaderName: "Organization Management",
    moduleHeaderContent: <OrganizationManagementHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
  {
    id: 5,
    name: "Email Alert Management",
    addEditHeaderName: "Email Alert Management",
    moduleHeaderContent: <NotificationHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
  {
    id: 4,
    name: "Data & Reporting",
    addEditHeaderName: "Data & Reporting for All Plants",
    moduleHeaderContent: <DataReportHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
  {
    id: 3,
    name: "Plant Management",
    addEditHeaderName: "Plant Management",
    moduleHeaderContent: <PlantManagementHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
  {
    id: 2,
    name: "Role Configuration",
    addEditHeaderName: "Role Configuration",
    moduleHeaderContent: <RoleConfigurationHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
  {
    id: 1,
    name: "User Management",
    addEditHeaderName: "Customer Portal Users",
    moduleHeaderContent: <UserManagementHeader />,
    ListApi: UserManagementCRUD,
    addModalHeaderContent: [],
  },
];

export interface AddDocumentRequiredProps {
  onSave: (data: any) => void;
  listGroups: ListGroup[];
  siteUnits: SiteUnit[];
  listModule?: ListModuleInterface;
  isEdit: boolean;
}

export interface ListModuleInterface {
  name: string;
  id: number;
  addEditHeaderName: string;
  addModalHeaderContent: string[];
  moduleHeaderContent: React.ReactElement | string;
  ListApi: ListModuleApiMethod<any>;
}

export interface AdministrationListOutletInterface {
  listModules: ListModuleInterface[];
  listModule?: ListModuleInterface;
}

interface AdministrationListContainerProps {}

function AdministrationListContainer(props: AdministrationListContainerProps) {
  usePageRequiresInternalAdmin();
  const { isMobile } = useWindowSize();
  const { moduleName } = useParams();

  const [listGroups, setListGroups] = useState<ListGroup[]>([
    {
      title: "External Users",
      name:"external",
      isInternal: false,
    },
    {
      title: "Internal Users",
      name:"internal",
      isInternal: true,
    },
  ]);

  usePlantSiteDetailsApiCalls();

  const listModule = listModules.find((module) => module.name === moduleName);

  return (
    <MainContent title={listModule?.name || ''} breadCrumbs={['Administration', (listModule?.name || '')]}>
      <div className={`card flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch`}>
            <div className={`flex flex-col gap-6`}>
              <Outlet context={{ listModules, listModule, listGroups, setListGroups }} />
            </div>
          </div>   
    </MainContent>
  );
}

export default AdministrationListContainer;
