
import React, {ReactElement} from 'react';
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from '../../../components/Modals/EmptyModal';
import { DocumentGroup } from '../../../utils/interface';
import Button from '../../../components/Button/Button';
import useWindowSize from '../../../customHooks/useWindowSize';
import { DocumentsModuleOutletInterface } from '../../Documents/DocumentsModuleContainer';
import { useOutletContext } from 'react-router-dom';

interface DeleteGroupConfirmationModalProps {
    shown: boolean;
    reportType: DocumentGroup|null;
    onClose: () => void;
    onDelete: () => void;
}

function DeleteGroupConfirmationModal(props: DeleteGroupConfirmationModalProps) {
    const {isMobile} = useWindowSize();
    const {documentModule} = useOutletContext<DocumentsModuleOutletInterface>();

    let width: number = 564;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <span className={classes.is_mobile}>Are you sure you want to delete the document group "{props.reportType?.name}" from the {documentModule.name} page for all plants in the Customer Portal? This cannot be undone.</span>
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                    <Button className="redDelete" text="Yes, Delete" onClick={props.onDelete} />
                    <Button className="whiteBtn" text="Cancel" onClick={props.onClose} />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Delete Document Group?" onClose={props.onClose} width={width} footerClass={classes.modal_footer} />
    );
}

export default DeleteGroupConfirmationModal;