import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Store } from "../../../Store";
import { useBulkUpdateItemsAsRead } from "../../../components/NotificationPane/NotificationHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { usePermissions } from "../../../customHooks/useUserPermissions";
import { queryKeys } from "../../../react-query/queryKeys";
import { useStore } from "../../../useStore";
import ImageImports from "../../../utils/ImageImports";
import { DiscussionGroupInterface, DiscussionInterface } from "../../../utils/interface";
import ParticipantList from "../../Thread/ParticipantList";
import { DiscussionModuleOutletInterface } from "../DiscussionModuleContainer";
import headerClasses from "../ManageDiscussionGroups/ManageDocumentGroups.module.css";
import { useGet_DiscussionThread } from "../discussionApiMethod";
import classes from "../discussions.module.css";
import MessageAddForm from "./MessageAddForm";
import Messages from "./Messages";

const { Arrow, participants: participantsIcon } = ImageImports;

const DiscussionSubject = () => {
  const outletContext = useOutletContext<DiscussionModuleOutletInterface>();
  const { documentModule, documentGroups } = outletContext;
  const { selectedUserGroup, setToolTipContent, toggleTooltip, toolTip, header: {
    data: { user }
  } } = useContext(Store);
  const setErrorData = useOnError();
  const [globalItemIdState, setGlobalItemIdState] = useState<number>(0);
  const [discussionThread, setDiscussionThread] = useState<DiscussionInterface | null>(null);
  const [searchparams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
	const [participants, setParticipants] = useState<string[]>([]);
  const { hasOnePermission } = usePermissions();

  useEffect(() => {
    const globalItemId = searchparams.get("globalItemId");
    globalItemId && setGlobalItemIdState(parseInt(globalItemId));
  }, [searchparams]);

  function onlyUnique(value: any, index: any, self: string | any[]) {
    return self.indexOf(value) === index;
  }
  const showParticipants = () => {
    setToolTipContent && setToolTipContent(<ParticipantList users={participants} />);
    toggleTooltip && toggleTooltip(!toolTip);
  };

  const { mutate: updateItemAsRead } = useBulkUpdateItemsAsRead();
  useEffect(() => {
    if (globalItemIdState > 0) {
      updateItemAsRead([globalItemIdState]);
    }
  }, [globalItemIdState]);
  
  useEffect(() => {
    if (!discussionThread) return;
    setParticipants(discussionThread.messages.map((a) => a.createdBy).filter(onlyUnique));
  }, [discussionThread]);

  useQuery(
    [queryKeys.discussionGroups, selectedUserGroup.id],
    documentModule ? () => documentModule.documentGroupApi.get(selectedUserGroup.id) : () => new Promise((resolve) => resolve([])),
    {
      enabled: discussionThread ? true : false,
      onSuccess: (response: DiscussionGroupInterface[]) => {
        const categories = response?.filter((a) => a.id === discussionThread?.itemTypeId).map((a) => a.name);
        const category = categories.pop();
        category && setSelectedCategory(category);
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );

  useGet_DiscussionThread(globalItemIdState, {
    enabled: !!globalItemIdState,
    onSuccess(data) {
      setDiscussionThread(data);
    },
    onError(err) {
      setErrorData(err);
    },
  });

  return (
    <>
      <div className={`${headerClasses.intro} ${headerClasses.flex_column}`}>
        <Link className={`${headerClasses.back_container} ${headerClasses.flex_row}`} to="..">
          <img src={Arrow} className="back-arrow" alt={`Back to ${documentModule.name}`} />
          Back to {selectedCategory} Discussions
        </Link>

        <div className={`${headerClasses.section_title_cta} ${headerClasses.flex_row}`}>
          <div className={`${headerClasses.section_title}`}>
            <span className={`${headerClasses.section_heading}`}> {discussionThread?.title} </span>
          </div>
        </div>
      </div>
      <div className={`${classes.line}`} />
      <div className={classes.messageInfo}>
        <div style={{ fontSize: "14px" }}>
          <strong>Start of Discussion</strong> (oldest to newest)
        </div>
        <div className="ml-auto flex items-center justify-end text-[#567783] cursor-pointer" onClick={showParticipants}>
          <img src={participantsIcon} alt="participants" />{" "}
          <span className="pl-[10px]">
            {" "}
            {participants.length} participant{participants.length > 1 ? "s" : ""}{" "}
          </span>
        </div>
      </div>
      <Messages discussionThread={discussionThread} />
      {(hasOnePermission('DiscussionPosts', ['add','admin']) || user.portalAdmin) &&
        <MessageAddForm discussionThread={discussionThread} />
      }
      
    </>
  );
};

export default DiscussionSubject;
