import { AxiosError } from "axios";
import parseHtml from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import { useQuery_FAQ } from "../../containers/Support/SupportHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import { CPApiResponse, FaqInterfaceDTO } from "../../utils/interface";
import Button from "../Button/Button";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import SearchField from "../UI/Form/SearchField/SearchField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from './Faq.module.css';
import FormFaq from "./SupportForms/FormFaq";

const Faq = () => {
  const { pencilWhite } = ImageImports;
  const { setFaq, faq, isModal, header: { data: { user: { portalAdmin } } }, setIsModal } = useContext(Store);  
  const [searchText, setSearchText] = useState("");
  const [searchCount, setSearchCount] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const { isMobile } = useWindowSize();
  const [editFaq, setEditFaq] = useState(false);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  useEffect(() => {
    const tags = (["h1", "h2", "h3", "p", "ul", "ol", "div", "span"]);
    tags.map(tag => {
      const regex = new RegExp(`(<${tag}>)([^<]+)(</${tag}>)`, "g");      
      const tagsInDoc = faq?.faqText?.match(regex);
      if (!!(tagsInDoc?.length)) tagsInDoc.map(el => setIsDefaultValue(!(new DOMParser().parseFromString(el, 'text/html'))))
      else setIsDefaultValue(true)
    })   
  }, [faq])

  useEffect(() => {
    const procText = getHighlightedText(faq.faqText, searchText, classes.Highlight);
    setDisplayText(procText);
    setSearchCount((procText.match(/<\/mark>/gi) || []).length);
  }, [searchText]);

  const handleSearchText = (searchtext: string) => {
    setSearchText(searchtext);
  };

  const handleEditFaq = () => {
    setEditFaq(true);
  }

  const FaqSuccess = (responsedata: CPApiResponse<FaqInterfaceDTO>) => {
    if (responsedata?.data) {
      setFaq && setFaq(responsedata?.data);
      const procText = getHighlightedText(responsedata?.data.faqText, searchText, classes.Highlight);
      setDisplayText(procText);
    }
  }
  const FaqError = (error: AxiosError<any>) => {
    console.log(error.message);

  }
  useQuery_FAQ(FaqSuccess, FaqError);

  const getHighlightedText = (text: string, highlight: string, highlightClass: string) => {
    if (!highlight.trim()) {
      return text;
    }

    highlight = highlight.trim();
    highlight = highlight.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

    text = text.replaceAll("\\n", "").replaceAll("&nbsp;", "!!!");
    const tagRegExe = new RegExp(/>(.*?)</g);
    const highRegExe = new RegExp(highlight, "gi");
    var match = null;
    var startIndex = 0;
    var procText = "";
    var occurance = 0;

    while (match = tagRegExe.exec(text)) {
      procText += text.substring(startIndex, match.index);
      var subMatch = null;
      var subStartIndex = 0;
      var subProcText = "";
      while (subMatch = highRegExe.exec(match[0])) {
        subProcText += match[0].substring(subStartIndex, subMatch.index);
        subProcText += "<mark class='" + highlightClass + "'>" + subMatch[0] + "</mark>";
        subStartIndex = subMatch[0].length + subMatch.index;
        occurance++;
      }

      if (subStartIndex < match[0].length)
        subProcText += match[0].substring(subStartIndex, match[0].length);

      procText += subProcText;
      startIndex = match[0].length + match.index;
    }
    if (startIndex < text.length)
      procText += text.substring(startIndex, text.length);

    return procText.replace(/!!!/g, "&nbsp;");
  };

  return (
    <>
      <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
        {faq.faqText.length && <div className={classes.search_keyword_container}>
          <SearchField placeholder="Search frequently asked questions" handleSearchText={handleSearchText} showClose={true} />
        </div>}
        {
         searchText.length > 0 
            ?
            (searchCount === 1)
              ? 
                <div className={classes.results}>{searchCount} Search result for '{searchText}'</div> 
              : 
                <div className={classes.results}>{searchCount} Search results for '{searchText}'</div> 
            : 
            <></>
        }
      </div>
      {faq.faqText.length && <hr className={classes.hr} />}
      {
        faq.faqText.length > 0 && !isDefaultValue
          ?
          (searchText.length > 0 && searchCount === 0)
            ?
            <p className='p-8 pl-0'>Apologies, we couldn't find any matches for that keyword. Please check your spelling or try a new search.</p>
            :
            <article className="rdw-editor-main" style={{ marginBottom: "38px", height: "auto" }} >{parseHtml(displayText)}</article>
          :
          <p className='p-0 m-0 pb-16 pt-8'>No frequently asked questions to display.</p>
      }

      {portalAdmin && <Button
        className={"darkBlue"}
        text={"Edit Content"}
        preImg={<img src={pencilWhite} style={{ marginRight: "10px" }} alt="" />}
        onClick={handleEditFaq}
      ></Button>}
      {
        editFaq && <ModalGlobal modalSize="RTE" modaltitle={"Edit Content"} isModalHeader={true}  modalClose={() => setEditFaq(false)}>
          <FormFaq modalClose={() => setEditFaq(false)} />
        </ModalGlobal>
      }
      {
        isModal.isEditedSuccess.isFaq && <FormSuccess modaltitlename={"Changes Saved"} modaltitle={""} comment={"Content has been successfully updated."} isTitleCenter={true} modalSize={"sm"} button_done={true} button_addmore={false} />
      }
    </>
  );
};

export default Faq;
