import React, { useState,MouseEvent, useEffect,useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { NavLinksProps } from "../InterfaceMobile";
import ImageImports from '../../../utils/ImageImports';
import { Store } from "../../../Store";




const MobileSubmenuLink = (props: NavLinksProps) => { 
  const {menuOpen} = ImageImports;  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen); 
  const stateContext = useContext(Store);
  const {mobileNavToggle,toggleMobileNav} = stateContext;
  const [clicked, setClicked] = useState("0");  
  const handleToggle = (index:any) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <div className="mobile-nav-item" key={props.id}>
      <div
        className={`mobile-nav-links ${props.subRoutes? 'has-dropdown' : ''}`}
        onClick={() => handleToggle(props.index)}
      >
        <div className="menu-icon">
          <img src={props.icon} alt={props.title} />
        </div>
        <div>{props.title}</div>
        {props.subRoutes && (
          <img className={clicked === props.index ? " menu-toggle-icon active" : "menu-toggle-icon"} src={menuOpen} alt="open" />
        )}
      </div>
      
      <div 
      
      className={`${clicked === props.index ? "nav-dropdown" : "hide nav-dropdown"}`}
      >
      { 
        props.subRoutes && props.subRoutes.filter(props.adminFilter).filter(props.userGroupSelectionFilter).map((subrout,i) => (
            
            <Link 
              key={i}to={subrout.route}
              onClick={() => {                
                toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                setClicked("0")
              }}
              className="submobile-menu">              
            {subrout.title}
          </Link>
        ))
      }
      </div>
    </div>
  );
};

export default MobileSubmenuLink;