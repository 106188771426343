import RelatedLinks from '../../../components/RelatedLinks/RelatedLinks';
import Footer from '../../../components/Footer/Footer';
import classes from '../QuoteRequests.module.css';
import useWindowSize from '../../../customHooks/useWindowSize';
import QuoteRequests from '../QuoteRequests';

const relatedData = {
    relatedTitle: "Related Information",
    relatedSections: [
      {
        title: "Quote Requests",
        description: "This page is the central location for users to request parts and service quotations.",
        buttonLink: "View Quote Requests",
        route: ""
      },
      {
        title: "Frequently Asked Questions",
        description: "Commonly asked questions and answers related to topics such as Product Information, Technical Information and Portal Content.",
        buttonLink: "View Frequently Asked Questions",
        route: ""
      }
    ]
  }
 
interface QuoteRequestContainerProps {
    moduleId: number,
    title: string
}

export interface OutletInterface {
    parentLocation: string,
    title: string
}

function QuoteRequestContainer({moduleId, title}: QuoteRequestContainerProps) {
    const {isMobile} = useWindowSize();

    return (
        <div className={`flex-col grow ${isMobile? classes.is_mobile : ''}`}>
            <div className='content'>
                <QuoteRequests />
            </div>

            <RelatedLinks data={relatedData} />

            <div className='flex flex-col flex-wrap ml-12 mr-6 flex-grow-0 flex-end'>
                <Footer />
            </div>
        </div>
    );
}

export default QuoteRequestContainer;