import React, { useEffect, useState } from 'react';
import { DocumentGroup, ModuleNames } from '../../../utils/interface';
import classes from "./ManageDocumentGroups.module.css";
import ImageImports from "../../../utils/ImageImports";
import DeleteGroupConfirmationModal from './DeleteGroupConfirmationModal';
import Button from '../../../components/Button/Button';
import {useSortable,} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { usePermissions } from '../../../customHooks/useUserPermissions';

const {pencil, dragReorder} = ImageImports;

interface ReportTypeListItemProps {
    reportType: DocumentGroup;
    onSave: (reportType: DocumentGroup) => void;
    onGenrateReport: (ItemTypeId: number) => void;
    onDelete: (reportType: DocumentGroup) => void;
    editToggled: (isEditing: boolean) => void;
    groupModule: ModuleNames|ModuleNames[];
}

function ReportTypeListItem({reportType, editToggled, onSave,onGenrateReport, onDelete, groupModule}: ReportTypeListItemProps)  {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [showDeleteGroupConfirmationModal, setShowDeleteGroupConfirmationModal] = useState<boolean>(false);
    const {hasOnePermission} = usePermissions();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef
    } = useSortable({id: reportType.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const cancelEdit = () => {
        setName(reportType.name);
        closeEdit();
    };

    const closeEdit = () => {
        setIsEditing(false);
        editToggled(false);
    };

    const startEditing = () => {
        setIsEditing(true);
        editToggled(true);
    };

    const save = () => {
        onSave({...reportType, name});
        closeEdit();
    };

    const handleGenrateReport = () => {
        onGenrateReport(reportType.id);
        closeEdit();        
    };

    const deleteReportType = () => {
        setShowDeleteGroupConfirmationModal(false);
        closeEdit();
        onDelete(reportType);
    };

    useEffect(() => {
        setName(reportType.name);
    }, [reportType]);

    return (
        <div className={`${classes.report_type_list_item} ${classes.flex_row}`} ref={setNodeRef} style={style} {...attributes}>
            <div ref={setActivatorNodeRef} {...listeners} className={`${classes.move_icon} ${classes.flex_row}`}>
                <img src={dragReorder} alt="Reorder Report Groups" />
            </div>
            {showDeleteGroupConfirmationModal && <DeleteGroupConfirmationModal shown={showDeleteGroupConfirmationModal}
                                                                               onClose={() => {setShowDeleteGroupConfirmationModal(false)}}
                                                                               onDelete={deleteReportType}
                                                                               reportType={reportType} />}
            <div className={`${classes.report_type_inner} ${isEditing? classes.flex_column : classes.flex_row}`}>
                <div className={`${classes.input_wrapper} ${classes.flex_column}`}>
                    {isEditing && (
                        <span className={`${classes.edit_group_name} ${classes.flex_row}`}>{reportType.name}</span>
                    )}
                    <input type="text" className={classes.flex_row} value={name} readOnly={!isEditing} onChange={e => {setName(e.target.value)}} />
                    <div className={classes.help_text}>{reportType.documentsCount || 0} document{reportType.documentsCount === 1 ? '' : 's'} across all plants</div>
                </div>
                {isEditing? (
                    <>
                        <a href="#" onClick={handleGenrateReport} className={`${classes.generate_usage_report_link} ${classes.flex_row}`}>Generate Usage Report</a>
                        <div className={`${classes.edit_button_group} ${classes.flex_row}`}>
                            <div className={`${classes.edit_left_button_group} ${classes.flex_row}`}>
                                <Button className="darkBlue" text="Save Changes" onClick={save} disabled={reportType.name === name} />
                                {hasOnePermission(groupModule, 'delete') && (
                                    <Button className="redDelete" text="Delete Report Group" disabled={reportType.documentsCount > 0} onClick={() => setShowDeleteGroupConfirmationModal(true)} />
                                )}
                            </div>
                            <Button className="whiteBtn" text="Cancel" onClick={cancelEdit} />
                        </div>
                    </>
                ) : hasOnePermission(groupModule, 'edit')? (
                    <div className={`${classes.edit_icon}`} onClick={startEditing}>
                        <img src={pencil} alt="Edit" />
                    </div>
                ) : <></>}
            </div> 
        </div>
    );
};

export default ReportTypeListItem;