import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { DocumentsModuleOutletInterface } from "./DocumentsModuleContainer";


const DocumentsModuleIndex: FunctionComponent = () => {
    const {documentGroups} = useOutletContext<DocumentsModuleOutletInterface>();
    const navigate = useNavigate();
    useEffect(() => {
        if (documentGroups.length > 0) {
            navigate(`./${documentGroups[0].id}`);
        }
    }, [documentGroups]);

    return <>
        <div>
            <Loader />
        </div>
    </>;
};

export default DocumentsModuleIndex;