import { ReactElement, useEffect, useMemo, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import { ItemList, ItemListProps } from "./ItemList";

interface TabbedSectionDefinition {
    path: number|string,
    text: string|ReactElement
}

interface TabbedSectionsProps<T> extends ItemListProps<T> {
    sections: TabbedSectionDefinition[];
    sectionsLabel: string;
}

export const TabbedSections = <T extends {id: number}>({sections, sectionsLabel, ...tabSectionProps}: TabbedSectionsProps<T>) => {
    const {isDesktop} = useWindowSize();
    const [selectedSection, setSelectedSection] = useState<DropDownOptionItem & TabbedSectionDefinition>();
    const navigate = useNavigate();
    const location = useLocation();

    const dropdownOptions: (DropDownOptionItem & TabbedSectionDefinition)[] = useMemo(() => {
        return sections.map((s, i) => ({
            ...s,
            id: i,
            name: s.text as string
        }));
    }, [sections]);

    const handleSectionSelection = (section: DropDownOptionItem & TabbedSectionDefinition) => {
        setSelectedSection(section);
        navigate(`${section.path}`);
    };

    useEffect(() => {
        if (!isDesktop) {
            const foundSection = dropdownOptions.find(d => d.path.toString().toLowerCase() === location.pathname.toLowerCase())
            setSelectedSection(foundSection);
        }
    }, [isDesktop, location, dropdownOptions]);

    return (
        <div className={`flex gap-4 ${isDesktop? 'flex-row !pb-0' : 'flex-col'}`}>
            {isDesktop? (
                <div className="flex flex-col basis-48">
                    {sections.map((section) => (
                        <NavLink key={section.path}
                                to={`${section.path}`}
                                className={({ isActive }) =>
                                    `h-10 no-underline text-sm font-bold flex flex-start items-center ${isActive ? 'text-black border-solid border-0 border-l-4 border-[#567783] pl-3' : 'text-[#999] pl-4'}`
                                }>
                            {section.text}
                        </NavLink>
                    ))}
                </div>
            ) : (
              <div className={'flex flex-col gap-2'}>
                <span className="text-base">{sectionsLabel}</span>
                <div className="borderDropdown">
                  <PlainDropDown 
                    options={dropdownOptions}
                    onSelection={handleSectionSelection}
                    value={selectedSection?.id}
                    selectClass="flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full bg-white whitespace-nowrap min-w-[240px] outline-none cursor-pointer"
                    optionsClass="flex flex-col flex-nowrap p-1 bg-white rounded max-h-80 overflow-y-scroll"
                    className="relative"
                    dropdownStyles={{overflow: 'auto'}}
                    itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20 w-full"
                  />
                </div>
              </div>
            )}
            <ItemList {...tabSectionProps} />
        </div>
    );
};

TabbedSections.defaultProps = {
    sectionsLabel: 'Report Type'
};