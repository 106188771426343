import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { CPApiResponse, FaqInterfaceDTO } from "../../utils/interface";
import { apiGetFAQ, apiUpdateFAQ } from "../../endpoints/endpoints";

interface FAQCrudMethods<T1,T2> {
	query: () => Promise<T1>;
	update: (fromdata: FaqInterfaceDTO) => Promise<T2>;
}

export const FAQApi: FAQCrudMethods<AxiosResponse<FaqInterfaceDTO>, CPApiResponse<FaqInterfaceDTO>> = {
	query: async (): Promise<AxiosResponse<FaqInterfaceDTO>> => {
		const response = await axios({
			url: `${apiGetFAQ}`,
			method: "GET",
		});
		
		return response.data;
	},
	update: async (fromdata:FaqInterfaceDTO): Promise<CPApiResponse<FaqInterfaceDTO>> => {
		const response = await axios({
			url: `${apiUpdateFAQ}`,
			method: "PUT",
			data: fromdata,
		});
		return response.data;
	}
};

export const useQuery_FAQ = (onSuccess: any, onError: any) => {
	return useQuery(queryKeys.getFaqApi, FAQApi.query, {
		onSuccess,
		onError,
	});
};

export const useUpdate_FAQ = () => {
	return useMutation(FAQApi.update);
  };
