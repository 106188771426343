import { AxiosError } from "axios";
import { Form, Formik, FormikProps } from "formik";
import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useIsFetching, useMutation } from "react-query";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import Loader from "../../../components/Loader/Loader";
import DropDownSelect from "../../../components/UI/Form/DropDownSelect/DropDownSelect";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import Editor from "../../../components/UI/RichTextEditor/Editor";
import { reFetchDiscussionCategory } from "../../../customHooks/useItemTypeCatalog";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { Store } from "../../../Store";
import { DiscussionInterface } from "../../../utils/interface";
import { useCreate_UGThread, useCreate_UGThreadMessage, useGet_DiscussionThread, useUpdate_UGThread } from "../discussionApiMethod";
import { DiscussionModuleOutletInterface } from "../DiscussionModuleContainer";
import { AddDocumentRequiredProps } from "../DiscussionsContainer";
import "./AddEditDiscussion.css";

const AddEditDiscussion: FunctionComponent<AddDocumentRequiredProps> = ({ setApiResponses, isEdit }) => {
  const outletContext = useOutletContext<DiscussionModuleOutletInterface>();
  const { showError, selectedUserGroup } = useContext(Store);
  const { documentModule, documentGroups } = outletContext;

  const [faqText, setFaqText] = useState<string>("");
  const [globalItemId, setGlobalItemId] = useState<number>(0);
  const [isLoaded, setIsloaded] = useState<boolean>(false);
  const [clearEditor, setClearEditor] = useState<number>(0);
  const [selectedThread, setSelectedThread] = useState<DiscussionInterface | null>(null);
  const [searchparams] = useSearchParams();
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.discussionGroups]);
  const navigate = useNavigate();
  const {groupId} = useParams();
  const currentItemTypeId = useMemo(() => (Number(groupId) || 0), [groupId]);

  const categoryOptions = useMemo(() => {
    return [
      {id: 0, name: "Select discussion category"},
      ...documentGroups.filter(d => !!d).map(d => ({id: d.id, name: d.name}))
    ];
  }, [documentGroups]);

  useEffect(() => {
    const globalItemId = searchparams.get("id");
    globalItemId && setGlobalItemId(parseInt(globalItemId));
  }, [searchparams]);

  useGet_DiscussionThread(globalItemId, {
    enabled: !!globalItemId,
    onSuccess(data) {
      setSelectedThread(data);
    },
    onError(err) {
      setErrorData(err);
    },
  });

  const { mutate: CreateUgThread, isLoading: isCreating } = useCreate_UGThread();
  const { mutate: CreateUgThreadMessage, isLoading: isCreatingMessage } = useCreate_UGThreadMessage();
  const { mutate: UpdateUgThreadMessage, isLoading: isUpdatingMessage } = useUpdate_UGThread();

  const { mutate: deleteMutation } = useMutation((id: number) => documentModule.documentApi.delete(id, "User Deleted"), {
    onSuccess: (data: boolean) => {
      reFetchDiscussionCategory(selectedUserGroup);
    },
    onError: (error: AxiosError<string>) => {
      setErrorData(error);
    },
  });

  const handleDelete = () => {
    if(selectedThread){
      deleteMutation(selectedThread.globalItemId,{
        onSuccess(data) {
          navigate("..")
        },
      });
    }
  }

  let initialValues: { title: string; itemTypeId: number; threadId: number; content?: string };

  initialValues = {
    title: selectedThread?.title ? selectedThread?.title : "",
    itemTypeId: selectedThread?.id ? selectedThread?.itemTypeId : 0,
    threadId: selectedThread?.threadId ? selectedThread?.threadId : 0,
  };

  if (!isEdit) {
    initialValues = { title: "", itemTypeId: 0, threadId: 0, content: "" };
  }

  const handleContentChange = (val: string) => {
    if (val !== faqText) {
      setFaqText(val);
    }
  }
  
  return (
    <div>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          itemTypeId: Yup.number().positive().required(),
          title: Yup.string().required(),
        })}
        onSubmit={(values, actions) => {
          if (isEdit) {
            UpdateUgThreadMessage(
              {
                ...values,
                globalItemId: selectedThread?.globalItemId ? selectedThread?.globalItemId : 0,
              },
              {
                onSuccess(data) {
                  setApiResponses((prev) => ({
                    ...prev,
                    isEdited: true,
                    discussionData: data,
                  }));
                  reFetchDiscussionCategory(selectedUserGroup);
                },
                onError(error) {
                  setErrorData(error);
                },
              }
            );
          } else {
            CreateUgThread(values, {
              onSuccess(data) {
                setApiResponses((prev) => ({
                  ...prev,
                  isSuccess: true,
                  discussionData: data,
                }));
                if (data.threadId && faqText.length) {
                  CreateUgThreadMessage(
                    {
                      title: data.title,
                      threadId: data.threadId,
                      content: faqText,
                    },
                    {
                      onSuccess(data) {
                        reFetchDiscussionCategory(selectedUserGroup);
                      },
                      onError(error) {
                        setErrorData(error);
                      },
                    }
                  );
                } else {
                  reFetchDiscussionCategory(selectedUserGroup);
                }
              },
              onError(error) {
                setErrorData(error);
              },
            });
          }
        }}
      >
        {(formikProps: FormikProps<any>) => {

          return (
            <Form>
              {isFetching > 0 ? (
                <Loader />
              ) : (
                <>
                  {" "}
                  {categoryOptions.length > 0 && (
                    <div>
                      <DropDownSelect
                        name="itemTypeId"
                        options={categoryOptions}
                        placeholder="Select discussion category"
                        labelName="Discussion Category*"
                        defaultValue={isEdit ? selectedThread?.itemTypeId : 0}
                      />
                    </div>
                  )}
                  <div>
                    <TextInputField
                      name="title"
                      labelName="Subject*"
                      className=""
                      placeholder="Enter discussion subject"
                      tooltip={{
                        text: "Enter discussion subject",
                      }}
                    />
                  </div>
                  {!isEdit && (
                    <div>
                      <label>Post to Discussion*</label>
                        <Editor reset={clearEditor} handleChange={handleContentChange} />
                    </div>
                  )}
                </>
              )}
              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  {isEdit ? (
                    <div className="flex gap-4">
                      <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                        Save Post
                      </button>
                      <button type="button" className="primaryBtn redDelete" onClick={handleDelete}>
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-4">
                      <button type="submit" className="primaryBtn darkBlue" disabled={ (!faqText.length || faqText === '<p class="editor-paragraph"><br></p>') || (!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid) ? true : false}>
                        Post
                      </button>
                        <button type="button" className="primaryBtn whiteBtn" onClick={() => setClearEditor(ce => ++ce)}>
                        Clear
                      </button>
                    </div>
                  )}
                </div>
                <span>*Required field</span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEditDiscussion;
