import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import SearchItems from "../../components/SearchItems/SearchItems";
import { GlobalSearch } from "../../utils/interface";

const Search = () => {
  const {currentPosts,paginationData} = useOutletContext<any>();  

  const [filteredPost,setFilteredPost] = useState<GlobalSearch[]>([]);

  useEffect(() => {
    if(currentPosts) {
      setFilteredPost(currentPosts);   
    }else{
      setFilteredPost([]); 
    }
  }, [currentPosts]);
  
  // console.log("🚀 ~ file: Search.tsx ~ line 8 ~ Search ~ filteredPost", filteredPost)
  // console.log("🚀 ~ file: Search.tsx ~ line 8 ~ Search ~ searchItems", searchItems)
  return <SearchItems filteredItems = {filteredPost} paginationData = {paginationData}/>;
};

export default Search;
