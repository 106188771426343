export const supportedFileTypes = () => {
  const excelTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/excel",
    "application/x-msexcel",
    "application/x-excel",
  ];
  const docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/doc",
    "application/ms-doc",
  ];
  const docTypeExtensions = [
    "doc",
    "dot",
    "docx",
    "dotx",
    "docm",
    "dotm",
  ];
  const excelTypeExtensions = [
    "xls",
    "xlt",
    "xla",
    "xlsx",
    "xltx",
    "xlsm",
    "xltm",
    "xlam",
    "xlsb",
  ];
  const excelTypesObject = excelTypes.reduce((acc: any, curr: any) => ((acc[curr] = []), acc), {});
  const docTypesObject = docTypes.reduce((acc: any, curr: any) => ((acc[curr] = []), acc), {});

  return {
    docTypes,
    excelTypes,
    docTypeExtensions,
    excelTypeExtensions,
    excelTypesObject,
    docTypesObject,
  };
};
