import { FunctionComponent, useContext } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { DocumentModuleInterface } from "../Documents/DocumentsContainer";
import classes from './Reports.module.css';
import {CloseAllModal} from "../../utils/CloseAllModal";

interface Props {
    moveConfirmationMeta: {count: number, from: string, to: string};
    onClose: () => void;
}

const MoveDocumentConfirmation: FunctionComponent<Props> = ({moveConfirmationMeta, onClose}) => {
    const {selectedPlant, setIsModal} = useContext(Store);
    const close = () => {        
        setIsModal && setIsModal!({ ...CloseAllModal });
        onClose();
    };
    return (
        <ModalGlobal titleClassName="text-center" modaltitle={`(${moveConfirmationMeta.count}) Document(s) Successfully Moved`} isModalHeader={true} modalClose={close} modalSize="sm">
            <div className={`${classes.delete_confirm_body} ${classes.flex_column}`}>
                <p>You successfully moved document(s) from the "{moveConfirmationMeta.from}" group to the "{moveConfirmationMeta.to}" group.</p>
                <div className={classes.flex_row}>
                    <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={close} />
                </div>
            </div>
        </ModalGlobal>
    );
}

export default MoveDocumentConfirmation;