import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import DarkLinks from "../../../components/RelatedLinks/DarkLinks";
import ImageImports from "../../../utils/ImageImports";

const {userGroup2, logo} = ImageImports;


export const SplashPage: FunctionComponent = () => {
  return (
  <div className="">
    <div className="pt-6 pb-10 px-8 md:p-6 lg:py-10 lg:px-[162px] flex flex-col gap-x-8 relative bg-primary-20 rounded-top">
      <div>
        <Link className="link_text" to="/home">
          <img src={logo} className="nopointer w-40 mb-2" alt="logo" />
        </Link>
      </div>
      <div>
          <h1 className="text-4xl lg:text-40 pt-4 font-bold">Welcome, Owners and Operators of power plant equipment supported
          by Mitsubishi Power</h1>
          <p className="text-sm lg:text-2xl mblx-2">The Users' Groups that represent the Owners and Operators of power plant equipment supported by Mitsubishi Power have worked with Mitsubishi Power to establish this web portal to enhance communication and mutual support among the community of Users and with Mitsubishi Power.</p>
      </div>
    </div>   
    <div className="pt-6 pb-10 px-8 md:p-6 lg:py-10 lg:px-[162px] relative bg-white rounded-bottom" >
        {/* Bottom Section of the Splash Page */}
        <div className="flex flex-col justify-center">
          <div className="">
            <div className="mx-0">
                <div>
                  <h1 className="text-xl lg:text-2xl font-bold">Sign into the Portal</h1>
                    <p className="text-base mblx-2">You will be prompted to sign in once with your corporate account that you selected to access the Users' Groups Web Portal. If you require access, you can submit a request <Link to="/portal-access" >here</Link>.</p>
                </div>
                  <DarkLinks data={relatedData} />
            </div>
          </div>
        </div>
    </div>
  </div>    
  );
};

const relatedData = {
  relatedTitle: "",
  relatedSections: [
    {
      IconUrl: userGroup2,
      title: "Users' Group Portal",
      description: "Contains presentations made at Mitsubishi Power Users' Groups Conferences by Users and Mitsubishi, discussion boards on topics of interest to participating Users, periodic status updates and webinars provided by Mitsubishi, fleet and plant site information and User Community contacts.",
      buttonLink: "Go to Users' Group Portal",
      route: "/"
    }
  ]
};

export default SplashPage;
