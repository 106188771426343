import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import { RequestQuoteDTO, RequestQuoteFormInterface } from "../../utils/interface";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";

const AttachNameplateAssetModal = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<RequestQuoteFormInterface>();
  const [attachment, setAttachment] = useState<File | Blob>();
  const [previewBase24, setPreviewBase24] = useState<string>("");
  console.log("🚀 ~ file: AttachNameplateAssetModal.tsx ~ line 15 ~ AttachNameplateAssetModal ~ attachment", attachment)
 
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const { trash } = ImageImports;

  const handleAddDocument = () => {
    setIsModal && setIsModal({...CloseAllModal});
  }
  const handleCancel = () => {
    setIsModal && setIsModal({...CloseAllModal});
  }

  useEffect(() => {
    if (values.nameplate.file) {
      setPreviewBase24(values.nameplateBase64);
    }      
}, [attachment]);

  useEffect(() => {
    if (attachment) {
        toBase64(attachment).then((base64Image: string) => {
          setFieldValue("nameplateBase64",base64Image);
        }).catch((err: Error) => {
          setFieldValue("nameplateBase64","");
        });
    }      
}, [attachment]);
  return (
    <ModalGlobal modaltitle={"Add a Nameplate Asset"} modalSize={"md"} isModalHeader={true}>
      <div>
        <>
        <div>
            <TextInputField name="nameplate.name" labelName="Name*" className="" placeholder="ex Nameplate" />
          </div>
          <DragDropFieldFormik name="nameplate.file" onChange={setAttachment}  attachment={attachment} previewBase24={previewBase24}/>
          <div className={`${classes.form_field_row}`}>
            <div className={classes.warning_text}>
              <span>Accepted file types: PDF, JPG, PNG</span>
              <span>Max file size: 2MB</span>
            </div>
          </div>
          <div className="flex align-center">
            <div className="flex-grow-1 flex">
              <button type={"button"} className="primaryBtn darkBlue" onClick={handleAddDocument}>
              Add Nameplate
              </button>
              <button type={"button"} className="primaryBtn whiteBtn marginLeft16" onClick={handleCancel}>
                Cancel
              </button>
            </div>
            <span>*Required field</span>
          </div>
        </>
      </div>
    </ModalGlobal>
  );
};

export default AttachNameplateAssetModal;
