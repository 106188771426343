import React, { useContext, useEffect, useState } from "react";
import classes from "./QuoteRequestForm.module.css";
import ImageImports from "../../utils/ImageImports";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import QuoteRequestFormStepTwo from "./QuoteRequestFormStepTwo";
import { Form, Formik, FormikHelpers } from "formik";
import { PlantContactsDTO, RequestQuoteFormInterface } from "../../utils/interface";
import RadioButtonsGrouped from "../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped";
import TextInputField from "../UI/BasicForm/TextInputField/TextInputField";
import SelectField from "../UI/BasicForm/SelectField/SelectField";
import { useOnError } from "../../customHooks/useOnError";
import * as Yup from "yup";
import {
  useCreate_QuoteRequestAttachment,
  useCreate_RequestQuote,
  useGetAll_QuoteRequestType,
  useGetAll_QuoteTypes,
} from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import Loader from "../Loader/Loader";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import { useGetAll_PlantContact } from "../../containers/PlantInformation/PlantContactHttpServices";
import { AxiosError } from "axios";

const QuoteRequestFormStepOne = () => {
  
  const { plantConfig, setIsModal, isModal,selectedPlant } = useContext(Store);
  const setErrorData = useOnError();

  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState<{ api1: boolean; api2: boolean; api3: boolean }>({
    api1: false,
    api2: false,
    api3: false,
  });

  const [selectedQuoteType, setSelectedQuoteType] = useState<string | null>(null);
  const [isGeneralRequestSelected, setIsGeneralRequestSelected] = useState<boolean>(false);
  const [quoteRequestId, setQuoteRequestId] = useState<number>(0);

  const [contactType, setContactType] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select Customer" }]);
  const [requestTypeOption, setRequestTypeOption] = useState<{ id: number; name: string }[]>([]);
  const [selectedrequestType, setSelectedrequestType] = useState<{ id: number; name: string }>({ id: -1, name: "Select a request type" });
  const [quoteTypeOption, setQuoteTypeOption] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a quote type" }]);

  
  // Api fetch start-----------------------------------------------------//
  const onSuccessQRT = (quoteRequestType: any) => {
    setRequestTypeOption(quoteRequestType.data);
  };
  const onErrorQRT = () => {};
  useGetAll_QuoteRequestType(onSuccessQRT, onErrorQRT);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessQT = (quoteType: any) => {
    setQuoteTypeOption(quoteType.data.map((d: { quoteTypeId: number; name: string }) => ({ id: d.quoteTypeId, name: d.name })));
  };
  const onErrorQT = () => {};
  useGetAll_QuoteTypes(onSuccessQT, onErrorQT);
  // Api fetch end-------------------------------------------------------//


    //{***************************************************************************************************************************}

    const onSuccess = (plantContacts: PlantContactsDTO) => {      
      const isPlantsiteContact = plantContacts.data.filter((a) => {
        if (plantContacts) {
          return a.isMitsubishiContact === true;
        }
        return a;
      }).map((contact) =>  ({id:contact.plantContactId, name:`${contact.firstName} ${contact.lastName}`}) );
    

      if(isPlantsiteContact.length > 0){
        setContactType(isPlantsiteContact);
      }else{
        setContactType([]);
      }
      
    };
    const onError = (error: AxiosError<string>) => {
      setPlantContacts &&
        setPlantContacts({
          success: false,
          message: "",
          permissions: 0,
          data: [],
        });
      // setErrorData(error);
    };
    const { isLoading } = useGetAll_PlantContact(selectedPlant.plantId, onSuccess, onError);
  
    //{***************************************************************************************************************************}

  // Api mutate start-----------------------------------------------------//
  const { mutate: AddQuoteRequest, isLoading: isLoadingAddQuoteRequest, isSuccess: isAdded } = useCreate_RequestQuote();
  const { mutate: AddAttachment, isLoading: isLoadingAddAttachment } = useCreate_QuoteRequestAttachment();
  const { mutate: AddNameplate, isLoading: isLoadingAddNameplate } = useCreate_QuoteRequestAttachment();
  // Api mutate end-------------------------------------------------------//

  const handleOnSubmit = (values: RequestQuoteFormInterface, formikHelpers: FormikHelpers<RequestQuoteFormInterface>) => {
    const requestsFormData = new FormData();
    const partsDocumentFormData = new FormData();
    const nameplateFormData = new FormData();
    const requests: any = values.quoteRequest;
    const partsDocumentrequests: any = values.partsDocument;
    const nameplaterequests: any = values.nameplate;

    for (let request in requests) {
      if (request === "quoteRequestParts") {
        const aE = requests[request];
        aE.forEach((e: { partNumber: string; description: string; quantity: number }, i: number) => {
          requestsFormData.append(`quoteRequestParts[${i}].partNumber`, `${e.partNumber}`);
          requestsFormData.append(`quoteRequestParts[${i}].description`, `${e.description}`);
          requestsFormData.append(`quoteRequestParts[${i}].quantity`, `${e.quantity}`);
        });
      } else {
        requestsFormData.append(request, requests[request]);
      }
    }
    for (let partsDocumentrequest in partsDocumentrequests) {
      partsDocumentFormData.append(partsDocumentrequest, partsDocumentrequests[partsDocumentrequest]);
    }
    for (let nameplaterequest in nameplaterequests) {
      partsDocumentFormData.append(nameplaterequest, nameplaterequests[nameplaterequest]);
    }

    AddQuoteRequest(requestsFormData, {
      onSuccess(AddQuoteRequest: any) {
        setQuoteRequestId(AddQuoteRequest.data[0].quoteRequestId);
        setIsFormSubmitSuccess((prev) => ({ ...prev, api1: true }));
        if (AddQuoteRequest.data[0].quoteRequestId > 0 && values.partsDocument.file) {
          AddAttachment(
            {
              ...values.partsDocument,
              quoteRequestId: AddQuoteRequest.data[0].quoteRequestId,
            },
            {
              onSuccess(res: any) {
                setIsFormSubmitSuccess((prev) => ({ ...prev, api2: true }));
              },
              onError(error: unknown) {
                setErrorData(error);
                setIsFormSubmitSuccess((prev) => ({ ...prev, api2: false }));
                formikHelpers.setSubmitting(false);
              },
            }
          );
        }
        if (AddQuoteRequest.data[0].quoteRequestId > 0 && values.nameplate.file) {
          AddNameplate(
            {
              ...values.nameplate,
              quoteRequestId: AddQuoteRequest.data[0].quoteRequestId,
            },
            {
              onSuccess(res: any, variables, context) {
                setIsFormSubmitSuccess((prev) => ({ ...prev, api3: true }));
              },
              onError(error: unknown) {
                setErrorData(error);
                setIsFormSubmitSuccess((prev) => ({ ...prev, api3: false }));
                formikHelpers.setSubmitting(false);
              },
            }
          );
        }
      },
      onError(error: unknown) {
        setErrorData(error);
        setIsFormSubmitSuccess((prev) => ({ ...prev, api1: false }));
        formikHelpers.setSubmitting(false);
      },
    });
  };

  let initailValues: RequestQuoteFormInterface;

  initailValues = {
    quoteRequest: {
      quoteRequestId: 0,
      globalItemId: 0,
      baseOrgId: plantConfig.data.baseOrgId,
      itemTypeId: 0,
      description: "",
      quotePriorityId: 0,
      plantSiteId: plantConfig.data.plantId,
      quoteNeededDate: "",
      dateRecievedfromCustomer: "",
      partsPriorityId: 0,
      partsNeededDate: "",
      partsRequestReasoningId: 0,
      isPlannedOutage: "false",
      isCustomer: "false",
      outageSeasonId: 0,
      outageYear: 0,
      equipmentId: 0,
      customerContactId: 0,
      additionalComments: "",
      internalOutageId: "",
      file: "",
      quoteRequestParts: [
        {
          partNumber: "",
          description: "",
          quantity: 0,
        },
      ],
      quoteRequestEvents: [],
      quoteRequestType: null,
      quoteTypeId: null,
    
    },
    partsDocument: {
      quoteRequestId: quoteRequestId,
      name: "",
      quoteRequestAttachmentTypeId: 1,
      file: "",
    },
    nameplate: {
      quoteRequestId: quoteRequestId,
      name: "",
      quoteRequestAttachmentTypeId: 2,
      file: "",
    },
    isUnitSelected: null,
    isProductSelected: null,
    partsDocumentBase64: "",
    nameplateBase64: "",
  };

  useEffect(() => {
    if (isFormSubmitSuccess.api1 && isFormSubmitSuccess.api2 && isFormSubmitSuccess.api3) {
      setIsModal!({
        ...CloseAllModal,
        isAddedSuccess: { ...isModal.isAddedSuccess, isQuoteRequest: true },
      });
    } else if (isFormSubmitSuccess.api1 && isFormSubmitSuccess.api3) {
      setIsModal!({
        ...CloseAllModal,
        isAddedSuccess: { ...isModal.isAddedSuccess, isQuoteRequest: true },
      });
    }
    return () =>
      setIsModal!({
        ...CloseAllModal,
      });
  }, [isFormSubmitSuccess]);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initailValues}
        validationSchema={Yup.object().shape({
          quoteRequest: Yup.object().shape({
            quoteRequestType: Yup.number().positive().min(0).required(),
            quoteTypeId: Yup.number().positive().min(1).required(),
            description: Yup.string().required(),
            baseOrgId: Yup.number().positive().min(1).required(),
            itemTypeId: Yup.number().positive().min(1).required(),
            quotePriorityId: Yup.number().positive().min(1).required(),
            plantSiteId: Yup.number().positive().min(1).required(),
            quoteNeededDate: Yup.string().required(),
            dateRecievedfromCustomer: Yup.string().when("isCustomer", {
              is: (isCustomer: string) => isCustomer === "true",
              then: Yup.string().required(),
            }),
            partsPriorityId: Yup.number().positive().min(1).required(),
            partsNeededDate: Yup.string().required(),
            partsRequestReasoningId: Yup.number().positive().min(1).required(),
            isPlannedOutage: Yup.string().required(),
            isCustomer: Yup.string().required(),

            outageSeasonId: Yup.number().when("isPlannedOutage", {
              is: (isCustomer: string) => isCustomer === "true",
              then: Yup.number().positive().min(1).required(),
            }),
            outageYear: Yup.number().when("isPlannedOutage", {
              is: (isCustomer: string) => isCustomer === "true",
              then: Yup.number().positive().min(1).required(),
            }),
            equipmentId: Yup.number().positive().min(1).required(),
            customerContactId: Yup.number().when("isCustomer", {
              is: (isCustomer: string) => isCustomer === "true",
              then: Yup.number().positive().min(1).required(),
            }),
            internalOutageId: Yup.string().required(),
          }),
          nameplate: Yup.object().shape({
            name: Yup.string().required(),
            quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
            file: Yup.string().required(),
          }),
          isUnitSelected: Yup.boolean().required(),
          isProductSelected: Yup.boolean().required(),
        })}
        onSubmit={handleOnSubmit}
      >
        {(formikProps) => {
          console.log(formikProps.values.quoteRequest);
          
          
          const handleRequestType = (e: React.MouseEvent<HTMLDivElement>) => {
            const requestTypeSelected = e.currentTarget.getAttribute("data-id");
            if (!requestTypeSelected) return;
            const requestType = requestTypeOption.filter((u) => u.id === parseInt(requestTypeSelected));
            setSelectedrequestType(requestType[0]);
            formikProps.setFieldValue("quoteRequest.quoteRequestType", parseInt(requestTypeSelected));
            if (formikProps.values.quoteRequest.quoteRequestType === 0) {
              formikProps.setFieldValue("quoteRequest.itemTypeId", 16);
            } else {
              formikProps.setFieldValue("quoteRequest.itemTypeId", 17);
            }
          };
          const handleSubmitNew = () => {
            formikProps.resetForm();
            setIsModal!({
              ...CloseAllModal,
            });
            setIsGeneralRequestSelected(false);
          };
          return (
            <Form>
              {(isLoadingAddQuoteRequest || isLoadingAddAttachment || isLoadingAddNameplate) && (
                <ModalGlobal modaltitle={""} modalSize={"md"} isModalHeader={false}>
                  <Loader containerStyle={{marginTop: '50px'}} />
                </ModalGlobal>
              )}
              <>
                <hr />
                <div>
                  <h4>For Internal Mitsubishi Power Only</h4>
                </div>
                <div>
                  <RadioButtonsGrouped
                    name="quoteRequest.isCustomer"
                    display="inline"
                    firstRadioName="Yes"
                    firstRadioValue="true"
                    SecondRadioName="No"
                    SecondRadioValue="false"
                    labelName="Are you submitting this new quote request on behalf of a customer?*"
                    className=""
                    showTooltip={true}
                  />
                </div>
                {formikProps.values.quoteRequest.isCustomer === "true" && (
                  <div className={classes.flex}>
                    <div className={classes.flexCol}>
                      <TextInputField
                        type="date"
                        className="marginbottom0"
                        name="quoteRequest.dateRecievedfromCustomer"
                        labelName="Date Request Received by Customer*"
                        placeholder="04/21/2022"
                      />
                    </div>
                    <div className={classes.flexCol}>
                      <SelectField
                        defaultValue={0}
                        options={contactType}
                        name={"quoteRequest.customerContactId"}
                        labelName={"Designated Customer Contact*"}
                      />
                    </div>
                  </div>
                )}
                <div className={classes.flex}>
                  <div className={classes.flexCol}>
                    <TextInputField
                      type="text"
                      name="quoteRequest.internalOutageId"
                      showTooltip={true}
                      labelName="Internal Outage ID "
                      className="marginbottom0"
                      placeholder="Add internal outage ID"
                    />
                  </div>
                  <div className={classes.flexCol}></div>
                </div>

                <hr />
                <div>
                  <h4>General Request Details</h4>
                </div>
                <p className={classes.para}>
                This page is a central location for users to request parts and service quotations. Document requests made through the page and Mitsubishi Power Americas’ responses. All members of the Customer Portal group for the plant site will receive email notifications when requests are made, and responses are posted.
                </p>
                <div className={classes.flex}>
                  <div className={classes.flexCol}>
                    <div style={{ height: "32px" }}>
                      <label>Request Type*</label>
                    </div>
                    <div
                      className={
                        (requestTypeOption.length > 0 && !isGeneralRequestSelected ) ? `nameplate-dropdown  flex request_type` : `nameplate-dropdown disabled flex request_type`
                      }
                    >
                      <Dropdown
                        dropdowntype={""}
                        style={{ width: "100%" }}
                        isError={
                          formikProps.errors.quoteRequest?.quoteRequestType && formikProps.touched.quoteRequest?.quoteRequestType ? true : false
                        }
                        options={requestTypeOption ? requestTypeOption : []}
                       
                        handleDropDownSelect={handleRequestType}
                        selectedOption={selectedrequestType.name}
                        setSelectedOption={setSelectedQuoteType!}
                      />
                    </div>
                  </div>
                  <div className={classes.flexCol}>
                    <SelectField disabled ={isGeneralRequestSelected} defaultValue={0} options={quoteTypeOption} name={"quoteRequest.quoteTypeId"} labelName={"Quote Type*"} />
                  </div>
                </div>
                <hr />
                {!isGeneralRequestSelected && (
                  <>
                    <div className="flex align-center">
                      <div className="flex-grow-1 flex">
                        <Button
                          text={"Continue"}
                          type="button"
                          disabled={formikProps.values.quoteRequest.quoteRequestType === null || formikProps.values.quoteRequest.quoteTypeId === null}
                          onClick={() => setIsGeneralRequestSelected(true)}
                          className="primaryBtn darkBlue "
                        />
                        <Button
                          text="Cancel"
                          onClick={() => setIsGeneralRequestSelected(false)}
                          type="button"
                          className="primaryBtn whiteBtn marginLeft16"
                        />
                      </div>
                    </div>
                  </>
                )}

                {isGeneralRequestSelected && (
                  <>
                    <QuoteRequestFormStepTwo handleSubmitNew={handleSubmitNew} />
                    <hr />
                    <div className="flex align-center">
                      <div className="flex-grow-1 flex">
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Submit Quote Request
                        </button>
                        {/* <button type="submit" className="primaryBtn darkBlue">
                          Submit Quote Request
                        </button> */}
                        <Button
                          text="Cancel"
                          onClick={() => setIsGeneralRequestSelected(false)}
                          type="button"
                          className="primaryBtn whiteBtn marginLeft16"
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuoteRequestFormStepOne;
function setPlantContacts(data: PlantContactsDTO) {
  throw new Error("Function not implemented.");
}

