import React, { useContext, useEffect, useState } from "react";

import { Store } from "../../Store";
import NameplateCard from "./NameplateCard/NameplateCard";
import useWindowSize from "../../customHooks/useWindowSize";
import "./Nameplate.css";
import classes from "./Nameplate.module.css";
import ImageImports from "../../utils/ImageImports";
import NamePlateViewModal from "./NamePlateViewModal";
import NamePlateDeleteModal from "./NamePlateDeleteModal";
import { IdObj, EquipmentAttachmentModelDTO, PlantEquipmentDTO, UnitGetAllDTO, PlantEquipmentsDTO } from "../../utils/interface";
import { AxiosError } from "axios";
import PaginationBasic from "../UI/Pagination/PaginationBasic/PaginationBasic";
import { apiGetEquipmentAttachmentFile } from "../../endpoints/endpoints";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetAllByUnitID_PlantEquipments, useGet_PlantEquipment } from "../../containers/PlantInformation/EquipmentsHttpServices";
import Dropdown from "../Dropdown/Dropdown";
import { SortAttachmentArray } from "../../utils/Sortutils";
import { useGetAll_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import Loader from "../Loader/Loader";
import { useOnError } from "../../customHooks/useOnError";

function Nameplate() {
  //image imports
  const { Arrow } = ImageImports;

  const [attachmentList, setAttachmentList] = useState<EquipmentAttachmentModelDTO[]|[]>([]);
  const [currentPosts, setCurrentPosts] = useState<EquipmentAttachmentModelDTO[]|[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [perPagePostStart, setPerPagePostStart] = useState<number>(0);
  const [perPagePostEnd, setPerPagePostEnd] = useState<number>(0);
  const [postsPerPage] = useState(8);
  const [sortKey, setSortKey] = useState<string>('1');

  const navigate = useNavigate();
  const { plantEquipmentId } = useParams();

  const {
    plantInformation: { nameplate },
    selectedPlant,    
    plantEquipment,
    setPlantEquipment,
    setEquipmentTypes,
    equipmentTypes,
    plantEquipment: { data: equipmentData },
    plantEquipmentsAttachment,
    plantEquipments,
    setPlantEquipments,
    setUnitGetAll,
    unitGetAll,
    setUnitTypes,
    isModal,
    setIsModal,   
  } = useContext(Store);
  const setErrorData = useOnError();
  const { isDesktop, isMobile } = useWindowSize();
  const [attachmentID, setAttachmentID] = useState<number>(0);
  const [IdObj, setidObj] = useState<IdObj | null>(null);

  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
 
  const [filterSelectedOption, setFilterSelectedOption] = useState<string | null>(null);

  const [namePlateData, setNamePlateData] = useState<{
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  }>({
    equipmentAttachmentId: 0,
    name: "",
    fileTypeName: "",
    description: "",
    attachments: "",
    fileSize: "",
    ext: "",
  });
  const [paramsID, setParamsID] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();



  const getIdOpenViewModal = (id: number) => {
    const filteredData = equipmentData.equipmentAttachmentModels?.find((data, i) => data.equipmentAttachmentId === id);
    if (filteredData) {
      setNamePlateData &&
        setNamePlateData({
          equipmentAttachmentId: filteredData.equipmentAttachmentId,
          name: filteredData.name,
          fileTypeName: filteredData.fileTypeName,
          description: filteredData.description,     
          attachments: `${apiGetEquipmentAttachmentFile}/${filteredData.equipmentAttachmentId}`,
          fileSize: filteredData.displaySize,
          ext: filteredData.fileTypeObj.extension,
        });
      setIsModal!({
        ...CloseAllModal,
        isView: { ...isModal.isDelete, isNameplate: true },
      });
    }
  };




    //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGet_PlantEquipment Start
  ///////////////////////////////////////////

  const onSuccessEquipment = (data: PlantEquipmentDTO) => {
    setPlantEquipment && setPlantEquipment(data);
  };

  const onErrorEquipment = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  const { isLoading } = useGet_PlantEquipment(paramsID, onSuccessEquipment, onErrorEquipment);

  ///////////////////////////////////////////
  // Fetch useGet_PlantEquipment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetAll_UnitData Start
  ///////////////////////////////////////////

  const onSuccessUnitData = (data: UnitGetAllDTO) => {
    // console.log("onSuccess", data);
    setUnitGetAll && setUnitGetAll(data);
    const UnitSelectOptions = data.data.map((unit) => {
      return {
        id: unit.unitId,
        name: unit.name,
      };
    });
    if (UnitSelectOptions) {
      setUnitTypes && setUnitTypes({ data: [...UnitSelectOptions] });
    }
  };
  const onErrorUnitData = (error: AxiosError<string>) => {
    setErrorData(error);
    setUnitGetAll &&
    setUnitGetAll({
        ...unitGetAll,
        data: [],
      });
    setUnitTypes && setUnitTypes({ data: [] });
    navigateBack();
  };
  useGetAll_UnitData(selectedPlant.plantId, onSuccessUnitData, onErrorUnitData);

    ///////////////////////////////////////////
  // Fetch useGetAll_UnitData End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

    ///////////////////////////////////////////
  // Fetch useGetAllByUnitID_PlantEquipments Start
  ///////////////////////////////////////////

  const onSuccessPlantEquipments = (data: PlantEquipmentsDTO) => {
    setPlantEquipments && setPlantEquipments(data);
    const plantEquipmentTypes = data.data.map((equipment) => {
      return {
        id: equipment.plantEquipmentId,
        name: equipment.equipmentName,
      };
    });
    if (plantEquipmentTypes) {
      setEquipmentTypes && setEquipmentTypes({ data: [...plantEquipmentTypes] });
    }
  };
  const onErrorPlantEquipments = (error: AxiosError<string>) => {
    setPlantEquipments &&
      setPlantEquipments({
        ...plantEquipments,
        data: [],
      });
      setErrorData(error);
  };
  useGetAllByUnitID_PlantEquipments(plantEquipment.data.unitID, onSuccessPlantEquipments, onErrorPlantEquipments);

    ///////////////////////////////////////////
  // Fetch useGetAllByUnitID_PlantEquipments End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}


  useEffect(() => {
    if (plantEquipment.data.equipmentAttachmentModels && plantEquipment.data.equipmentAttachmentModels.length > 0) {
      setAttachmentList(plantEquipment.data.equipmentAttachmentModels);
    } else {
      setAttachmentList([]);
    }
  }, [plantEquipment]);

  const openAddAttachments = (id: number) => {
    setAttachmentID && setAttachmentID(id);
  };

  const handlePaginate = (pagenumber: number) => {
    setCurrentPage(pagenumber);
  };

  const handleNextPaginate = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const handlePrevPaginate = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const [sortOption,] = useState<
    {
      id: number;
      name: string;
    }[]
  >([
    {
      id: 1,
      name: "Last Modified",
    },
    {
      id: 2,
      name: "Last Added",
    },
    {
      id: 3,
      name: "File Name A-Z",
    },
    {
      id: 4,
      name: "File Name Z-A",
    },
  ]);

  // Get current posts  
  useEffect(() => {
    const SortedArray = SortAttachmentArray(sortKey, attachmentList) || [];
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsList = SortedArray?.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentPosts(currentPostsList);
    setPerPagePostStart(attachmentList.length ? indexOfFirstPost + 1 : 0);
    setPerPagePostEnd((currentPostsList && indexOfLastPost + currentPostsList.length - postsPerPage) || 0);
    setTotalPosts(attachmentList.length);    
  }, [attachmentList, sortKey, currentPage, postsPerPage]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement>) => {
    const sortedtypedata = e.currentTarget.getAttribute("data-id") || '1';
    setSortKey(sortedtypedata);
  };

  const handleEquipmentFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    const equipmentData = e.currentTarget.getAttribute("data-id");


    if (equipmentData) {
      const newParams = { id: equipmentData };
      setSearchParams(newParams);
      setParamsID(parseInt(equipmentData));
    }
  };

  useEffect(() => {
    if (plantEquipmentId && Number(plantEquipmentId) !== paramsID) {
      setParamsID(parseInt(plantEquipmentId));
    }
    const currentParams = searchParams.get("id");
    if (currentParams) {
      setParamsID(parseInt(currentParams));
    }
    setFilterSelectedOption(plantEquipment.data.equipmentName);
  }, [paramsID, searchParams, plantEquipment, plantEquipmentId]);

  const SortDropDown = (
    <Dropdown
      dropdowntype={"Sort by:"}
      handleDropDownSelect={handleSort}
      selectValue={"Select"}
      selectedOption={sortSelectedOption}
      setSelectedOption={setSortSelectedOption}
      options={sortOption}
    />
  );
  const FilterDropDown = (
    <Dropdown
      dropdowntype={"Equipment:"}
      handleDropDownSelect={handleEquipmentFilter}
      selectValue={"Select"}
      selectedOption={filterSelectedOption}
      setSelectedOption={setFilterSelectedOption}
      options={equipmentTypes.data}
    />
  );

  const navigateBack = (): void => {
    navigate(-1);
  };

  return (
    <div className="card row-to-col nameplate-card">
      <div className={` col-to-row ${classes.backto}`}>
        <div className={`col-item ${isMobile && "marginBot24"}`}>
          <span
            className={`go-back flex align-center `}
            onClick={navigateBack}
          >
            <img src={Arrow} className="back-arrow" alt="arrow" />
            Back to Configuration Details
          </span>
        </div>
        {!isDesktop && (
          <div className="nameplate-dropdown inline-flex" style={{ marginRight: "10px" }}>
            {FilterDropDown}
          </div>
        )}
      </div>

      <div className={`col-to-row align-item-center`}>
        <div className={`row-item nameplate-title `}>Viewing Equipment Attachments for {equipmentData.equipmentName}</div>
        {isDesktop && <div className="nameplate-dropdown">{FilterDropDown}</div>}
      </div>
      <hr />
      <div className="col-to-row marginBot52">
        <div className={`row-item ${isMobile && "marginBot24"}`}>
          <div className="nameplate-dropdown inline-flex">{SortDropDown}</div>
        </div>       
      </div>
      <div className={classes.nameplate_container}>
        {isLoading ? (
          <Loader />
        ) : currentPosts && currentPosts?.length > 0 ? (
          currentPosts?.map((obj, i) => (
            <NameplateCard
              key={i}             
              fileName={obj.name}
              fileType={""}
              ext={obj.fileTypeObj.extension}
              lastUpdated={obj.created}
              desc={obj.description}          
              getIdOpenViewModal={(id: number) => getIdOpenViewModal(id)}             
              id={obj.equipmentAttachmentId}
              IdObj={{ equipmentid: obj.equipmentId, attachmentid: obj.equipmentAttachmentId }}
            />
          ))
        ) : (
          <div style={{ marginLeft: "1%" }} className="col-to-row marginBot52">
            No Attachments Found for this Equipment
          </div>
        )}
      </div>

      <div className="col-to-row font14">
        <div className="row-item">
          <div className="inline-flex">
            Displaying {perPagePostStart} - {perPagePostEnd} of {totalPosts} Results
          </div>
        </div>
        <div className="flex align-center">
          <PaginationBasic
            totalPosts={totalPosts}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            paginate={handlePaginate}
            next_paginate={handleNextPaginate}
            prev_paginate={handlePrevPaginate}
          />
        </div>
      </div>
      {isModal.isView.isNameplate && <NamePlateViewModal namePlateData={namePlateData} />}
      {isModal.isDelete.isNameplate && <NamePlateDeleteModal IdObj={IdObj!} namePlateData={namePlateData} />}
    </div>
  );
}

export default Nameplate;
