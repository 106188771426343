import React, { ReactElement, useContext } from "react";
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from "../../../components/Modals/EmptyModal";
import { DiscussionGroupInterface } from "../../../utils/interface";
import Button from "../../../components/Button/Button";
import useWindowSize from "../../../customHooks/useWindowSize";
import { Store } from "../../../Store";

interface NewGroupConfirmationModalProps {
  shown: boolean;
  reportType: DiscussionGroupInterface | null;
  onClose: () => void;
  addNewGroup: () => void;
  addNewDocument: () => void;
}

function NewGroupConfirmationModal(props: NewGroupConfirmationModalProps) {
  const { isMobile } = useWindowSize();
  const { selectedUserGroup } = useContext(Store);

  let width: number = 564;
  if (isMobile) {
    width = 300;
  }
  let modalBody: ReactElement | undefined;
  let modalFooter: ReactElement | undefined;
  if (props.shown) {
    modalBody = (
      <>
        <span className={classes.modal_body_text}>
          {" "}
          {props.reportType?.name} was successfully added as a new discussion category to the {selectedUserGroup.name} Users’ Group. Users with the
          accepted permissions may now engage in discussion within this category.
        </span>
        <span className={classes.modal_body_text}>
          Would you like to add another discussion category to the {selectedUserGroup.name} Users’ Group? You may also start a new discussion thread
          in {props.reportType?.name}.
        </span>
      </>
    );

    modalFooter = (
      <>
        <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
          <Button className="darkBlue" text="Add New Discussion Category" onClick={props.addNewGroup} />
          <Button className="whiteBtn" text="Start Discussion" onClick={props.addNewDocument} />
          <button className={`${classes.done_button} ${classes.flex_row}`} onClick={props.onClose}>
            Done
          </button>
        </div>
      </>
    );
  }

  return (
    <EmptyModal
      shown={props.shown}
      body={modalBody}
      footer={modalFooter}
      heading="Discussion Category Successfully Added"
      onClose={props.onClose}
      width={width}
      footerClass={classes.modal_footer}
    />
  );
}

export default NewGroupConfirmationModal;
