import axios, { AxiosRequestConfig } from "axios";
import { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import Footer from "../../components/Footer/Footer";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { useGetPlantConfigByUserGroup } from "../../customHooks/PlantHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { PlantConfigData } from "../../utils/interface";
import { MainContentContext } from "./MainContentContext";

declare type BreadCrumbItem = string | ReactElement;
interface MainContentProps {
    title: string;
    description?: string;
    breadCrumbs?: BreadCrumbItem[];
    showPlantDropdown?: boolean;
    children: ReactNode;
}

// In sub pages you can use useSelectedPlant()
export const MainContent = ({title, description, breadCrumbs, showPlantDropdown, children}: MainContentProps) => {
    const {showError, header: {data: {user}}, selectedUserGroup} = useContext(Store);
    const [selectedPlant, setSelectedPlant] = useState<PlantConfigData>();
    const [hasSelectedPlant, setHasSelectedPlant] = useState<boolean>(false);

    const {isMobile} = useWindowSize();

    const sortPlantNames = getSortObjectsByKeyFn<PlantConfigData>('baseOrgName', 'asc');

    const {data: plantConfigs} = useGetPlantConfigByUserGroup(selectedUserGroup.id, {
        enabled: showPlantDropdown,
        onSuccess: (configs) => {
            if (configs.length > 0) {
                const lastSelectedPlant = Number(sessionStorage.getItem('baseOrgId') || 0);
                if (configs.map(d => d.baseOrgId).includes(lastSelectedPlant)) {
                    setSelectedPlant(configs.find(d => d.baseOrgId === lastSelectedPlant));
                } else {
                    const defaultSelectedPlant = configs.sort(sortPlantNames)[0];
                    setSelectedPlant(defaultSelectedPlant);
                    sessionStorage.setItem('baseOrgId', `${defaultSelectedPlant.baseOrgId}`);
                }
            }
        }
    });
    
    const plantOptions: DropDownOptionItem[] = useMemo(() => {
        if (plantConfigs) {
            return plantConfigs.sort(sortPlantNames).map(d => ({
                id: d.baseOrgId,
                name: d.baseOrgName
            }));
        }
        return [];
    }, [plantConfigs]);

    useEffect(() => {
        setHasSelectedPlant(selectedPlant !== undefined);
    }, [selectedPlant]);

    const changePlant = (item: DropDownOptionItem) => {
        sessionStorage.setItem('baseOrgId', `${(item.id || 0)}`);
        setSelectedPlant(plantConfigs?.find(d => d.baseOrgId === item.id));
    }

    useEffect(() => {
        const reqId = axios.interceptors.request.use((config: AxiosRequestConfig) => {
            config.headers = {
                ...config.headers,
                baseorg: showPlantDropdown? (sessionStorage.getItem('baseOrgId') || 0) : 0
            };
            return config;
        });
        return () => axios.interceptors.request.eject(reqId);
    }, [showPlantDropdown]);

    useEffect(() => {
        if (!showPlantDropdown) {
            setSelectedPlant(undefined);
        }
    }, [showPlantDropdown]);

    return (
        <MainContentContext.Provider value={{selectedPlant}}>
            <div className="flex flex-col grow gap-16 h-full justify-between">
                <div className="content">
                    {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={window.location.reload} ErrorType={showError.ErrorType} />}
                    <div className={`flex ${isMobile? 'flex-col' : 'flex-row !pb-0'} justify-between`}>
                        <div className="flex flex-col w-full">
                            {breadCrumbs && <div className="path breadcrumb">
                                <Link to={"/"} className="link_text">
                                    Home
                                </Link>{" / "}
                                {breadCrumbs.join(' / ')}
                            </div>}
                            <div className="flex flex-col items-start gap-2 w-full">
                                <div className="font-bold text-[40px] self-stretch">{title}</div>
                                {description && <div className="text-sm w-full lg:w-3/4">{description}</div>}
                            </div>
                        </div>
                        {showPlantDropdown && (
                            <div className="flex flex-col gap-2">
                                <span>Plant Site</span>
                                <PlainDropDown selectClass="flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full bg-white whitespace-nowrap min-w-[240px] outline-none cursor-pointer"
                                    optionsClass="flex flex-col flex-nowrap p-1 bg-white rounded max-h-80"
                                    className="relative"
                                    dropdownStyles={{overflow: 'auto'}}
                                    itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                    options={plantOptions}
                                    onSelection={changePlant}
                                    value={selectedPlant?.baseOrgId}
                                    defaultText="Select a plant" />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col mt-10 gap-6 self-stretch">
                        <MainContentContext.Consumer>
                            {(value) => (!showPlantDropdown || (showPlantDropdown && hasSelectedPlant)) && children}
                        </MainContentContext.Consumer>
                    </div>
                </div>
                <div className="content flex-grow-0 flex-end">
                    <Footer />
                </div>
            </div>
        </MainContentContext.Provider>
    );
};

MainContent.defaultProps = {
    showPlantDropdown: false
};