import React from "react";
import ImageImports from "../../../../utils/ImageImports";
import classes from "./PaginationBasic.module.css";

const PaginationBasic = ({
  postsPerPage,
  totalPosts,
  paginate,
  next_paginate,
  prev_paginate,
  currentPage,
}: PaginationBasicProps) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;
  const firstPage = pageNumbers[0];

  const { Arrow } = ImageImports;
  return (
    <div className="flex align-center">
      {currentPage !== 1 ? (
        <img
          src={Arrow}
          className="pagination-left-arrow"          
          onClick={prev_paginate}
          alt="arrow"
        />
      ) : <img
      src={Arrow}
      className="pagination-left-arrow"          
    
      alt="arrow"
    />
    
    }

      {pageNumbers.map((pagenumber, i) => (
        <span className={`${classes.index} ${currentPage === pagenumber && classes.active} `} key={i} onClick={() => paginate(pagenumber)}>
          {pagenumber}
        </span>
      ))}

      {currentPage !== lastPage ? (
        <img
          src={Arrow}
          className="pagination-right-arrow"
          onClick={next_paginate}
          alt="arrow"
        />
      ): <img
      src={Arrow}
      className="pagination-right-arrow"
    
      alt="arrow"
    />}
    </div>
  );
};

interface PaginationBasicProps {
  postsPerPage: number;
  totalPosts: number;
  paginate(a: number): void;
  next_paginate(): void;
  prev_paginate(): void;
  currentPage: number;
}

export default PaginationBasic;
