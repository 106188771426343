import axios, { AxiosError, AxiosResponse } from "axios";
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, useQuery, UseQueryOptions } from "react-query";
import { apiTechnicalBulletinUrl } from "../endpoints/endpoints";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../utils/handleAxiosResponse";
import { CanPermissionedItems, CPApiResponse, DataWithPermissions, ModulePermissionResponseInterface, ModulePermissions, TechnicalBulletin, TechnicalBulletinGroup } from "../utils/interface";

declare type GetSubTypesParameters = ['technicalbulletinsubtypes', {baseOrgId: number}];
const getSubTypes = async ({queryKey}: QueryFunctionContext<GetSubTypesParameters>): Promise<TechnicalBulletinGroup[]> => {
    const response: AxiosResponse<CPApiResponse<CanPermissionedItems<TechnicalBulletinGroup>>> = await axios(`${apiTechnicalBulletinUrl}/GetSubTypes`);
    return handleAxiosResponse(response).items;
};

export const useGetSubTypes = (baseOrgId: number, options: Omit<UseQueryOptions<TechnicalBulletinGroup[], unknown, TechnicalBulletinGroup[], GetSubTypesParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['technicalbulletinsubtypes', {baseOrgId}], getSubTypes, options);
};

declare type GetTechnicalBulletinsParameters = ['technicalbulletins', {itemTypeId: number, baseOrgId: number}];
const getTechnicalBulletins = async ({queryKey}: QueryFunctionContext<GetTechnicalBulletinsParameters>): Promise<DataWithPermissions<TechnicalBulletin[]>> => {
    const [_key, {itemTypeId, baseOrgId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<TechnicalBulletin[]>[]>> = await axios(`${apiTechnicalBulletinUrl}/get-items-list?ItemTypeId=${itemTypeId}&BaseOrgId=${baseOrgId}&ModuleId=5`);
    return handleModulePermissionResponseMulti(response);
};
export const useGetTechnicalBulletins = (itemTypeId: number, baseOrgId: number, options: Omit<UseQueryOptions<DataWithPermissions<TechnicalBulletin[]>, unknown, DataWithPermissions<TechnicalBulletin[]>, GetTechnicalBulletinsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['technicalbulletins', {itemTypeId, baseOrgId}], getTechnicalBulletins, options);
};

declare type BulkDeleteParameters = {globalItemIds: number[], deletionReason: string};
const bulkDelete: MutationFunction<boolean, BulkDeleteParameters> = async ({globalItemIds, deletionReason}): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiTechnicalBulletinUrl}/BulkDelete`, {
        method: "DELETE",
        data: {
            globalItemIds,
            deletionReason,
        },
    });
    return handleAxiosResponse(response);
};
export const useBulkDelete = (options: Omit<MutationOptions<boolean, AxiosError<string>, BulkDeleteParameters>, 'mutationFn'> = {}) => useMutation(bulkDelete, options);
