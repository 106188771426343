import React from "react";
import classes from "./ToggleSwitch.module.css";

const ToggleSwitch = ({ isChecked, label, onChange }: { isChecked: boolean; label: string; onChange(): void }) => {
  return (
    <div style={{ fontSize: "2px" }}>
      <div className={classes.toggleswitch}>
        <input type="checkbox" className={classes.checkbox} checked={isChecked} onChange={() => {}} id={label} />
        <label className={classes.label} htmlFor={label} onClick={onChange}>
          <span className={classes.inner} />
          <span className={classes.switch} />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
