import { useEffect, useState } from 'react';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $isLinkNode, AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $getNearestNodeFromDOMNode, $getRoot, $getSelection, $insertNodes, $isRangeSelection, EditorState, LexicalEditor } from 'lexical';
import { ImageNode } from './nodes/ImageNode';
import LexicalAutoLinkPlugin from './Plugins/AutoLinkPlugin';
import ImagePlugin from './Plugins/ImagePlugin';
import ListMaxIndentLevelPlugin from './Plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from './Plugins/ToolbarPlugin';
import Theme from './Theme';
import './theme.css';
import FloatingLinkEditorPlugin from './Plugins/FloatingLinkEditorPlugin';

function Placeholder() {
    return <div className="editor-placeholder">Enter some rich text...</div>;
}

interface IEditorProps {
    content?: string;
    allowUploads?: boolean;
    reset?: number;
    externalImage?: boolean;
    handleChange: (val: string) => void;
    uploadFile?: (attachment: File) => void;
}
const Editor = ({ handleChange, content, allowUploads, reset, externalImage, uploadFile }: IEditorProps) => {
    const [floatingAnchorElem, setFloatingAnchorElem] =
      useState<HTMLDivElement | null>(null);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem);
      }
    };

    const editorConfig = {
        namespace: "CustomRTE",
        theme: Theme,
        onError(error: any) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
        ]
    };

    const onChange = (editorState: EditorState, editor: LexicalEditor) => {
        editorState.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            handleChange(htmlString);
        });
    };
    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container">
                <ToolbarPlugin refresh={reset} allowUploads={allowUploads} uploadFile={uploadFile} externalImage={externalImage} />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<div ref={onRef}><ContentEditable className="editor-input" /></div>}
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ClearEditorPlugin />
                    <OnChangePlugin onChange={onChange} />
                    <HistoryPlugin />
                    <ListMaxIndentLevelPlugin maxDepth={7} />
                    <AutoFocusPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <ImagePlugin />
                    <ClickableLinkPlugin />
                    <LexicalAutoLinkPlugin />
                    {floatingAnchorElem && (
                        <>
                            <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                        </>
                    )}
                    <LoadInitialContent initialContent={content} />
                </div>
            </div>
        </LexicalComposer>
    );
}
export default Editor;
type Props = { initialContent?: string }   
 
export const LoadInitialContent = ({ initialContent }: Props) => {
    const [editor] = useLexicalComposerContext();
    

    useEffect(() => {
        if (!initialContent) { return; }
        editor.update(() => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(initialContent, "text/html");
            const nodes = $generateNodesFromDOM(editor, dom);
            $getRoot().clear().append(...nodes);
        });
    }, []);
    return null;
  };
type LinkFilter = (event: MouseEvent, linkNode: LinkNode) => boolean;

function ClickableLinkPlugin({
  filter,
  newTab = true,
}: {
  filter?: LinkFilter;
  newTab?: boolean;
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    function onClick(e: Event) {
      const event = e as MouseEvent | PointerEvent;
      const linkDomNode = getLinkDomNode(event, editor);

      if (linkDomNode === null) {
        return;
      }

      const href = linkDomNode.getAttribute('href');

      if (
        linkDomNode.getAttribute('contenteditable') === 'false' ||
        href === undefined
      ) {
        return;
      }

      // Allow user to select link text without following url
      const selection = editor.getEditorState().read($getSelection);
      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        return;
      }

      let linkNode = null;
      editor.update(() => {
        const maybeLinkNode = $getNearestNodeFromDOMNode(linkDomNode);

        if ($isLinkNode(maybeLinkNode)) {
          linkNode = maybeLinkNode;
        }
      });

      if (
        linkNode === null ||
        (filter !== undefined && !filter(event, linkNode))
      ) {
        return;
      }

      try {
        if (href !== null) {
          const isMiddle = event.type === 'auxclick' && event.button === 1;
          window.open(
            href,
            newTab || event.metaKey || event.ctrlKey || isMiddle
              ? '_blank'
              : '_self',
          );
          event.preventDefault();
        }
      } catch {
        // It didn't work, which is better than throwing an exception!
      }
    }

    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement,
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('click', onClick);
          prevRootElement.removeEventListener('auxclick', onClick);
        }

        if (rootElement !== null) {
          rootElement.addEventListener('click', onClick);
          rootElement.addEventListener('auxclick', onClick);
        }
      },
    );
  }, [editor, filter, newTab]);
  return null;
}

function isLinkDomNode(domNode: Node): boolean {
  return domNode.nodeName.toLowerCase() === 'a';
}

function getLinkDomNode(
  event: MouseEvent | PointerEvent,
  editor: LexicalEditor,
): HTMLAnchorElement | null {
  return editor.getEditorState().read(() => {
    const domNode = event.target as Node;

    if (isLinkDomNode(domNode)) {
      return domNode as HTMLAnchorElement;
    }

    if (domNode.parentNode && isLinkDomNode(domNode.parentNode)) {
      return domNode.parentNode as HTMLAnchorElement;
    }

    return null;
  });
}