import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className="flex flex-col grow-1 errorPage"  >
        <div className="content">
        <div   className={`login-header mb-[15px]`}  >Error 404 - Page Not Found</div>
        <p>Unfortunately, the requested URL was not found. Please try the primary navigation, keyword search, or navigate back to the home page.</p>
        <div className="mt-[15px] max-w-[200px]">
        <Link to="/" className="primaryBtn darkBlue">Return to Home Page</Link>
        </div>
        </div>
    </div>
  )
}

export default ErrorPage