import { useEffect } from 'react';
import {Outlet, useNavigate, useOutletContext, useParams} from 'react-router-dom';

import classes from './ListApiMethods.module.css';

import { DocumentGroup, ListGroup } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';
import { AdministrationListOutletInterface, ListModuleInterface } from './AdministrationListContainer';




export interface AdministrationListModuleOutletInterface extends AdministrationListOutletInterface {
    listModule: ListModuleInterface;
    listGroups: ListGroup[];
    setListGroups: React.Dispatch<React.SetStateAction<ListGroup[]>>;
}
function AdministrationListModuleContainer () {
    const outletContext = useOutletContext<AdministrationListModuleOutletInterface>();
    const {listGroups, setListGroups} = outletContext;
    const navigate = useNavigate();
    const params = useParams();
    const groupId = String(params.groupId || '');
    const {isMobile} = useWindowSize();  
   
    useEffect(() => {
        if ((listGroups.length > 0 && listGroups.findIndex((g) => g.name === groupId) === -1)) {
          navigate(`${listGroups[0].name}`);
        }
      }, [groupId, listGroups]);
    
    return (
        <div className={`${classes.module_container} ${classes.flex_column} ${isMobile? classes.is_mobile : ''}`}>
            <Outlet context={{...outletContext, listGroups, setListGroups}} />
            
        </div>
    );
}

export default AdministrationListModuleContainer;