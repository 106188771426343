import clsx from "clsx";
import React, { CSSProperties, HTMLProps, ReactElement, useEffect, useRef, useState } from 'react';
import useWindowSize from "../../customHooks/useWindowSize";
import './Tooltip.css';

export interface tooltipActionItem<T = any> {
    img?: string;
    text: string | ReactElement;
	disabled?: boolean;
    visible?: (d: T) => boolean;
    hideWhenDeleted?: boolean;
    onClick(e: React.MouseEvent, data?: any): void;
    showOnlyOn?: ("BulkActions" | "ItemActions")[];
}

interface toolTipProps extends HTMLProps<HTMLDivElement> {
    shown: boolean;
    setShown?: React.Dispatch<React.SetStateAction<boolean>>;
    data?: any,
    listItems: tooltipActionItem[],
    parentId: string;
    isDeleted?: boolean;
    displayRight?: boolean;
}

function BulkActionTooltip({ shown, setShown, data, parentId, listItems, displayRight = false, isDeleted = false, ...props }: toolTipProps) {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<CSSProperties>();
    const {isMobile, isDesktop} = useWindowSize();
    useEffect(() => {
        const handleClick = (e: any) => {
            if (parentId && e.target.closest(`#${parentId}`) === null) {
                setShown?.(false);
            }
        };
        document.body.addEventListener('click', handleClick);
        return () => document.body.removeEventListener('click', handleClick);
    }, [parentId]);

    useEffect(() => {
        if (shown) {
            const parentRect = document.getElementById(parentId)?.getBoundingClientRect();
            if (parentRect) {
                let right: number|undefined = window.innerWidth - (parentRect.right + parentRect.width / 2);
                let topOffset = 0;
                let left: number|string|undefined;
                if (!isDesktop) {
                    right = undefined;
                    topOffset = 104;
                    left = displayRight ? 'auto' : 0;
                }

                setStyles({
                    right,
                    left,
                    minWidth: isMobile? `calc(100% - ${parentRect.left * 2}px)` : undefined,
                    top: parentRect.bottom + window.scrollY + topOffset,
                    // marginTop: 'unset',
                    // marginLeft: 'unset',
                    // borderTopLeftRadius,
                    // visibility
                });
    
            }
        }
    }, [shown, parentId, window.innerWidth, isDesktop, isMobile]);

    const checkShouldDisable = (deleted: boolean, action: string) => {
        return deleted && action === 'Delete';
    }

    const deletedFilter = (actions: tooltipActionItem) => {
        return !(!!actions.hideWhenDeleted && isDeleted);
    };

    // hide edit button if a report is deleted
    const updatedListItems = listItems.filter((a) => {
        const lowercase = a.text.toString().toLowerCase();
        if (lowercase === "edit" && isDeleted) {
            return false;
        } else {
            return true;
        }
    })
    return (
        <>
            {shown && styles && (
                <div ref={tooltipRef} {...props} className={clsx({'tooltip-actions': 1})} style={{right: 0, ...styles}}>
                    {updatedListItems.filter(deletedFilter).map((listItem, i) => {
                        const shouldDisable = checkShouldDisable(isDeleted, String(listItem?.text));
                        return (
                            <div key={i} className={`${listItem.disabled || shouldDisable ? "disabled-tooltip-action-item" : "tooltip-action-item"}`} onClick={e => !listItem.disabled && !shouldDisable && listItem.onClick(e, data)}>
                                {listItem.showOnlyOn && listItem.showOnlyOn.includes('ItemActions') ? <img alt="Action" src={listItem.img} /> : null}
                                <span>{listItem.text}</span>
                            </div>
                        )
                    })
                    }
                </div>
            )}
        </>
    );
}

export default BulkActionTooltip;
