import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { Toggle } from "../../components/UI/Form/Toggle/Toggle";
import { useGetAllBaseOrgGroups, useGetAllPlantsConfig } from "../../customHooks/PlantHttpServices";
import { useGetRoles, useGetUser, useGetUserRoleAssignments, useDelete_User, useReactivate_User, updateUserOrganizationRoles, removeUserAssignment, useGetPlantUserGroups, useUpdateUser } from "../../customHooks/UserHttpServices";
import ImageImports from "../../utils/ImageImports";
import { queryKeys } from "../../react-query/queryKeys";
import { useQueryClient } from "react-query";
import { useOnError } from "../../customHooks/useOnError";
import { ConfirmUserStatusChangeModal } from './ConfirmUserStatusChangeModal';
import { ConfirmRemoveUserAssignmentModal } from './ConfirmRemoveUserAssignmentModal';
import { Store } from "../../Store";
import { User, UserRole, UserRoleAssignments } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import MiniTooltip from "../../components/UI/MiniTooltip/MiniTooltip";

const { Arrow, help } = ImageImports;

interface ManageUserProps {
    userId: number
}

export const ManageUser = ({userId}: ManageUserProps) => {
    const [showConfirmUserStatusChangeModal, setShowConfirmUserStatusChangeModal] = useState<boolean>(false);
    const [showConfirmRemoveUserAssignmentModal, setShowConfirmRemoveUserAssignmentModal] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const setErrorData = useOnError();
    const {header: {data: {user: currentUser}}} = useContext(Store);

    const nUserId: number = useMemo(() => +(userId || 0), [userId]);
    const [assignments, setAssignments] = useState<UserRole[]>([]);
    const [assignmentToRemove, setAssignmentToRemove] = useState<UserRole|null>(null);
    const [roleAssignments, setRoleAssignments] = useState<UserRoleAssignments>();
    const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);
    const [assignmentIndexToRemove, setAssignmentIndexToRemove] = useState<number|null>(null);

    const {refetch, data: user} = useGetUser(nUserId, {
        onSuccess: user => {
            setIsPortalAdmin(user.portalAdmin);
        }
    });
    const {mutate: updateUser} = useUpdateUser({
        onSuccess: () => refetch()
    });
    const {refetch: refreshUserRoleAssignments} = useGetUserRoleAssignments(nUserId, {
        onSuccess: (roleAssignments) => {
            setAssignments(roleAssignments.roles);
            setRoleAssignments(roleAssignments);
        }
    });

    const {data: roles} = useGetRoles();
    const {data: plants} = useGetAllPlantsConfig();
    const {data: baseOrgGroups} = useGetAllBaseOrgGroups();
    const {data: userRoleAssignments} = useGetUserRoleAssignments(nUserId);
    const {data: plantUserGroups, refetch: refetchPlantUserGroups} = useGetPlantUserGroups(nUserId);
    const { mutate: DeleteUser } = useDelete_User();
    const { mutate: ReactivateUser } = useReactivate_User();

    const {mutate: addUpdateUserOrganizationRoles, isSuccess: updatedRoleAssignments, reset: resetUpdateMutation, isError: updateAssignmentError} = useMutation(updateUserOrganizationRoles, {
        onSuccess: () => {
            refreshUserRoleAssignments();
        }
    });

    const {mutate: DeleteUserAssignment, isSuccess: roleAssignmentRemoved, reset: resetRemoveUserAssignmentMutation} = useMutation(removeUserAssignment, {
        onSuccess: () => {
            setAssignmentIndexToRemove(null);
            setAssignmentToRemove(null);
            refreshUserRoleAssignments();
            setShowConfirmRemoveUserAssignmentModal(false);
            resetUpdateMutation();
        }
    });

    const stashRoleChange = useCallback((i: number, userRole: UserRole) => {
        setAssignments(assignments => {
            assignments.splice(i, 1, userRole);
            return [...assignments];
        });
    }, [assignments]);

    const commitRoleChanges = useCallback(() => {
        addUpdateUserOrganizationRoles({userId: currentUser.userId, roles: assignments});
    }, [assignments]);

    const addNewRoleAssignment = useCallback(() => {
        setAssignments(assignments => {
            return [...assignments, {
                baseOrgId: null,
                orgGroupId: null,
                roleId: 0,
                any: false,
                baseOrgName: '',
                orgGroupName: '',
                roleName: '',
                userId: nUserId,
                id: 0
            }];
        })
    }, []);

    const orgOptionSortFn = useMemo(() => {
        return getSortObjectsByKeyFn<{id: number, name: string}>('name', 'asc', d => d.toString().toLowerCase());
    }, []);

    const orgOptions = useMemo(() => {
        const options: {id: number, name: string}[] = [];
        if (plants) {
            options.push(...plants.map(plant => ({
                id: plant.baseOrgId,
                name: plant.baseOrgName
            })));
        }
        if (baseOrgGroups) {
            options.push(...baseOrgGroups.map(group => ({
                id: group.id * -1,
                name: group.name
            })))
        }
        return options.sort(orgOptionSortFn);
    }, [plants, baseOrgGroups, orgOptionSortFn]);

    const roleOptions = useMemo(() => {
        const options: {id: number, name: string}[] = [];
        if (roles && user) {
            options.push(...roles.filter(d => d.mpInternal === user.mpInternal).map(role => ({
                id: role.id,
                name: role.name
            })));
        }
        return options;
    }, [roles, user]);

    const handleDeactivateUser = (user: User) => {
        DeleteUser(user.id, {
            onError: (error: unknown) => {
              setErrorData(error);
            },
            onSuccess: (responseData) => {
              queryClient.invalidateQueries([queryKeys.user]);
              refetch();
              setShowConfirmUserStatusChangeModal(false);
            },
          });
    };

    const handleReactivateUser = (user: User) => {
        ReactivateUser(user.id, {
            onError: (error: unknown) => {
              setErrorData(error);
            },
            onSuccess: (responseData) => {
              queryClient.invalidateQueries([queryKeys.user]);
              refetch();
              setShowConfirmUserStatusChangeModal(false);
            },
          });
    };

    const handleRemoveUserAssignment = useCallback(() => {
        if (assignmentIndexToRemove !== null) {
            setAssignments(assignments => {
                assignments.splice(assignmentIndexToRemove, 1);
                return [...assignments];
            });
        }

        if (assignmentToRemove?.id && assignmentToRemove?.id > 0) {
            DeleteUserAssignment({baseOrgRoleId: assignmentToRemove?.id || 0})
        } else {
            setShowConfirmRemoveUserAssignmentModal(false);
        }
    }, [assignmentToRemove, assignmentIndexToRemove, DeleteUserAssignment]);

    const canAddAnotherAssignment = useMemo(() => {
        return assignments.filter(a => {
            return !(a.roleId > 0 && (a.baseOrgId || a.orgGroupId));
        }).length === 0;
    }, [assignments]);


    const canSaveAssignments = canAddAnotherAssignment && (assignments !== roleAssignments?.roles || user?.portalAdmin !== isPortalAdmin);

    const hasConflictingRoles = useMemo(() => Object.values(assignments.reduce((carry: {[index: number]: number}, current) => {
        const key = ((current.orgGroupId || 0) * -1) || current.baseOrgId || 0;
        if (!carry.hasOwnProperty(key)) {
            carry[key] = 0;
        }
        carry[key]++;
        return carry;
    }, {})).filter(d => d > 1).length > 0, [assignments]);

    const saveUser = useCallback(() => {
        if (assignments !== roleAssignments?.roles) {
            commitRoleChanges();
        }
        if (user) {
            updateUser({
                ...user,
                portalAdmin: !!isPortalAdmin
            });
        }
    }, [user, isPortalAdmin, assignments, roleAssignments]);

    return (
        <div className="p-6 bg-white rounded-lg">
            {showConfirmUserStatusChangeModal && userRoleAssignments && user &&
                <ConfirmUserStatusChangeModal
                    shown={showConfirmUserStatusChangeModal} 
                    user={userRoleAssignments}
                    onConfirmDeactivate={() => handleDeactivateUser(user)} 
                    onConfirmReactivate={() => handleReactivateUser(user)} 
                    onClose={() => setShowConfirmUserStatusChangeModal(false)} 
                    actionType= {`${user.accountDisabled ? 'reactivate' : 'deactivate'}`}
                />
            }
            {showConfirmRemoveUserAssignmentModal && userRoleAssignments && assignmentToRemove && 
                <ConfirmRemoveUserAssignmentModal 
                    shown={showConfirmRemoveUserAssignmentModal} 
                    user={userRoleAssignments} 
                    assignment={assignmentToRemove}
                    onConfirm={handleRemoveUserAssignment} 
                    onClose={() => setShowConfirmRemoveUserAssignmentModal(false)} 
                />
            }
            {user && <div className="gap-8 flex flex-col items-start">
                <div className="flex flex-col items-start gap-8 w-full">
                    <Link to="/administration/user-management" className="no-underline text-dark-blue visited:text-dark-blue focus:text-dark-blue">
                        <div className="flex flex-row !pb-0 gap-[10px] items-center">
                            <img src={Arrow} title="Back to Users' Group Portal Users" className="rotate-180 h-6 w-6" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                            <span className="font-bold text-sm">Back to Users' Group Portal Users</span>
                        </div>
                    </Link>
                    {user.accountDisabled === true &&
                        <div className="flex flex-col items-start gap-8 w-full">
                            <Alert dismissible={false} type="info" onClose={() => {}}>
                                This user is currently deactivated.
                            </Alert>
                        </div>
                    }
                    {updatedRoleAssignments && <Alert onClose={resetUpdateMutation} type="success">Changes saved.</Alert>}
                    {roleAssignmentRemoved && <Alert onClose={resetRemoveUserAssignmentMutation} type="success">Changes saved.</Alert>}
                    <div className="flex flex-row !pb-0 items-start gap-6 self-stretch">
                        <div className="flex flex-col items-start gap-2 grow text-2xl font-bold">
                            {user.fName} {user.lName}
                        </div>
                    </div>
                    <hr className="m-0 text-[#B3B3B3]" />
                </div>
                <div className="flex flex-col items-stretch gap-8 w-full">
                    {/* User Details */}
                    <div className="flex flex-col items-start gap-8">
                        <div className="flex flex-col items-start gap-2">
                            <span className="font-bold text-xl">User Details</span>
                            <span className="text-base">Data originally fed from Active Directory. To change user details, please contact Mitsubishi Power IT.</span>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">First Name:</span>
                                <span>{user.fName}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">Last Name:</span>
                                <span>{user.lName}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">Email Address:</span>
                                <span>{user.email}</span>
                            </div>
                            <div className="flex flex-row !pb-0 items-start gap-6">
                                <span className="w-[120px]">User ID:</span>
                                <span>{user.id}</span>
                            </div>
                        </div>
                    </div>
                    {/* Role Assignments */}
                    <div className="flex flex-col items-start gap-8"> {/* Role_Assignments */}
                        <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6092 */}
                            <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6327 */}
                                <div className="flex flex-row !pb-0 items-center gap-4 self-stretch"> {/* Frame 6382 */}
                                    <Toggle checked={isPortalAdmin} onChange={e => setIsPortalAdmin(e.target.checked)} />
                                    <div className="flex flex-row !pb-0 gap-2 text-base items-center"> {/* Frame 6384 */}
                                        Enable Users' Group Portal Administrator permissions for this user
                                        <MiniTooltip text={"If enabled, this user will be granted the highest administration permissions, and will be able to access all pages, modules and data in this portal."} space={15} placement={"top"}>
                                            <img src={help} alt="Help" height="16px" width="16px" className="cursor-pointer" />
                                        </MiniTooltip>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start gap-2"> {/* Frame 6354 */}
                                    <span className="text-xl font-bold">Role Assignment(s)</span>
                                    <span className="">Role definitions can be managed in the Role Configuration page.</span>
                                </div>
                                {hasConflictingRoles && <Alert onClose={() => {}} type="warning" dismissible={false}>Some role assignments below have conflicting access permissions. Please note the user will be granted access according to the highest permission(s) allowed between the two or more roles.</Alert>}
                                {updateAssignmentError && <Alert onClose={() => {}} type="error" dismissible={false}>Changes cannot be saved. One or more role selections below are identical assignments. Please resolve and try saving changes again.</Alert>}
                                <div className="flex flex-col items-start self-stretch gap-6"> {/* Frame 6326 */}
                                    {/* This part will be in a loop */}
                                    {assignments.map((assignment, i) => (
                                        <RoleAssignmentItem key={i} 
                                            orgOptions={orgOptions} 
                                            roleOptions={roleOptions} 
                                            assignment={assignment} 
                                            onRemove={() => {
                                                setAssignmentToRemove(assignment);
                                                setAssignmentIndexToRemove(i);
                                                setShowConfirmRemoveUserAssignmentModal(true)
                                            }} 
                                            onAssignmentChange={userRole => stashRoleChange(i, userRole)} />
                                    ))}
                                    {/* End Loop Item */}
                                </div>
                            </div>
                            <Button className="whiteBtn" text="Add Another Assignment" disabled={!canAddAnotherAssignment} onClick={addNewRoleAssignment} />
                        </div>
                    </div>
                    {/* Users' Group Association(s) */}
                    <div className="flex flex-col items-start gap-6">
                        <div className="flex flex-col items-start gap-2 w-7/12">
                            <span className="font-bold text-xl">Users' Groups Association(s)</span>
                            <span>
                                This user has inherited access to the following Users' Groups based on their role assignments above. Users' Group associations can be managed in the{' '}
                                <Link to="/administration/plant-management" className="no-underline text-ocean">
                                    Plant Management
                                </Link>
                                {' '}section, on individual plant details pages.
                            </span>
                        </div>
                        <div className="flex flex-col gap-4 w-5/12">
                            {plantUserGroups && plantUserGroups.map(plantUserGroup => (
                                <div key={plantUserGroup.plantOrgName} className="flex flex-row !pb-0 gap-6">
                                    <span className="basis-1/2">{plantUserGroup.plantOrgName}</span>
                                    <div className="flex flex-col">
                                        {plantUserGroup.userGroups.length > 0? 
                                            plantUserGroup.userGroups.map((groupName, i) => (
                                                <span key={i}>{groupName}</span>
                                            ))
                                            : <span>&mdash;</span>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <hr className="m-0 text-[#B3B3B3]" />
                    <div className="flex flex-row !pb-0 items-start gap-4"> {/* Actions */}
                        <Button className="darkBlue" text="Save Changes" disabled={!canSaveAssignments} onClick={saveUser} />
                        <Button 
                            className={`${user.accountDisabled  === true ? 'darkBlue' : 'redDelete'}`} 
                            text={`${user.accountDisabled === true ? 'Reactivate' : 'Deactivate'} User`} 
                            onClick={() => setShowConfirmUserStatusChangeModal(true)} 
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

interface RoleAssignmentItemInterface {
    orgOptions: {id: number, name: string}[];
    roleOptions: {id: number, name: string}[];
    assignment: UserRole;
    onRemove: (onRemove: boolean) => void;
    onAssignmentChange: (assignment: UserRole) => void;
}
const RoleAssignmentItem = ({orgOptions, roleOptions, assignment, onRemove, onAssignmentChange}: RoleAssignmentItemInterface) => {
    const handleOrgChange = (val: DropDownOptionItem) => {
        if (val.id === false) {
            onAssignmentChange({...assignment, orgGroupId: null, baseOrgId: null});
        } else {
            if (val.id < 0) {
                onAssignmentChange({...assignment, orgGroupId: val.id * -1, baseOrgId: null, orgGroupName: val.name});
            } else {
                onAssignmentChange({...assignment, orgGroupId: null, baseOrgId: val.id, baseOrgName: val.name});
            }
        }
    };

    return ( 
        <div className="flex flex-row items-end gap-6 self-stretch">
            <div className="flex flex-row items-start gap-6 basis-[648px]">
                <div className="flex flex-col items-stretch gap-2 basis-1/2">
                    <span className="">Organization/Plant*</span>
                    <PlainDropDown selectClass="flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full cursor-pointer"
                                optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
                                dropdownStyles={{overflow: 'auto'}}
                                itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                options={orgOptions}
                                searchable={true}
                                onSelection={handleOrgChange}
                                value={assignment.orgGroupId? (assignment.orgGroupId * -1) : assignment.baseOrgId ?? false}
                                defaultText="Select an organization or plant" />
                </div>
                <div className="flex flex-col items-stretch gap-2 basis-1/2">
                    <span className="">Role Assignment*</span>
                    <PlainDropDown selectClass="flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full cursor-pointer"
                                optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
                                dropdownStyles={{overflow: 'auto'}}
                                itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                options={roleOptions}
                                onSelection={val => onAssignmentChange({...assignment, roleId: val.id? val.id: 0, roleName: val.name})}
                                value={assignment.roleId}
                                defaultText="Select a role assignment" />
                </div>
            </div>
            <div className="flex flex-row items-center gap-6">
                <Button 
                    className="text-[#E31F26] bg-transparent font-bold px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    text="Remove Assignment" 
                    onClick={() => onRemove(true)} 
                />
            </div>
        </div>
    );
};