import React, { ReactElement, useEffect, useState } from "react";
import classes from './ListApiMethods.module.css';
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import Link from "../../components/Link/Link";
import useWindowSize from "../../customHooks/useWindowSize";
import Reports from "../Reports/Reports";
import Dropdown from "../../components/Dropdown/Dropdown";
import { AdministrationListModuleOutletInterface } from "./AdministrationListModuleContainer";
import Lists from "./Lists";

function AdministrationGroupContainer() {
  const outletContext = useOutletContext<AdministrationListModuleOutletInterface>();
  const { listModule, listGroups } = outletContext;
  const params = useParams();
  const groupId = String(params.groupId || '');

  const { isMobile, isTablet } = useWindowSize();


  let listGroupSelection: ReactElement = <></>;

  
  if (listGroups) {
    if (isMobile || isTablet) {
      listGroupSelection = (
        <div className={classes.flex_column}>
          Mobile List filter pending
          {/* <div className="borderDropdown">
            <Dropdown         
              style={{width:'100%'}}  
              handleDropDownSelect={handleGroupType}
              selectedOption={selectedReportType}
              setSelectedOption={setSelectedReportType}
              options={listGroups}
            />
          </div>           */}
        </div>
      );
    } else {
      listGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {listGroups.map((listGroupItem,i) => (
            <Link
              className={`${classes.document_group_link} ${groupId === listGroupItem.name ? classes.active : ""}`}
              key={i}
              to={`../${listGroupItem.name}`}
            >
              {listGroupItem.title}
            </Link>
          ))}
        </div>
      );
    }
  }

  return (
    <>
      <div className={`${classes.top_section} ${classes.flex_column}`}>{listModule.moduleHeaderContent}</div>
      <div className={`${classes.line}`} />
      <div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
        {listGroupSelection}
        <Lists listModule={listModule} listGroups={listGroups}/>
        <Outlet context={outletContext} />
      </div>
    </>
  );
}

export default AdministrationGroupContainer;
