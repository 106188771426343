import {useId, useState} from 'react';
import classes from './Thread.module.css';
import {ThreadMessage} from '../../utils/interface';
import ImageImports from '../../utils/ImageImports';
import useWindowSize from '../../customHooks/useWindowSize';
import Moment from 'react-moment';
import BulkActionTooltip, {tooltipActionItem} from '../../components/PopoutTooltip/BulkActionTooltip';

const {ellipsis, avatar, attachment, download, copyLink, trash} = ImageImports;

function Message ({message}: MessageProps) {
    const {isMobile} = useWindowSize();
    const [showTooltip, setShowTooltip] = useState(false);

    const onDownloadClick = () => {console.log('downloading...')};

    const onCopyLinkClick = () => {console.log('copying...')};

    const onDeleteClick = () => {console.log('deleting...')};

    const tooltipId = useId().replaceAll(":", "_");

    const tooltipActions: tooltipActionItem[] = [
        {
            img: download,
            text: 'Download',
            onClick: onDownloadClick
        },
        {
            img: copyLink,
            text: 'Copy Link',
            onClick: onCopyLinkClick
        }
    ];

    // TODO: if this is my message or I'm an admin
    if (true) {
        tooltipActions.push({
            img: trash,
            text: 'Delete',
            onClick: onDeleteClick
        });
    }

    return (
        <div className={`${classes.individual_message_container} ${classes.flex_column}`} key={message.id}>
            {/* TODO: IF THE MESSAGE IS UNREAD ADD CLASS UNREAD */}
            <div className={`${classes.message} ${classes.flex_column} ${message.id === 7? classes.unread : ''}`}>
                <div className={`${classes.flex_row}`}>
                    <div className={`${classes.message_user} ${isMobile? classes.flex_column : classes.flex_row}`}>
                        <div className={`${classes.flex_row} ${classes.user_name}`}>
                            <img src={avatar} alt={message.createdBy} />
                            {message.createdBy}
                        </div>
                        {isMobile? '' : ' • '}
                        <span className={`${classes.date_time}`}><Moment date={message.created} format="lll" /></span>
                    </div>
                    <span id={tooltipId} tabIndex={1} className={`${classes.ellipsis} ${showTooltip? classes.shown : ''}`} onBlur={() => setShowTooltip(false)} onKeyUp={e => [13,32].includes(e.which) && setShowTooltip(!showTooltip)}>
                        <img src={ellipsis} onClick={() => setShowTooltip(!showTooltip)} />
                        <BulkActionTooltip shown={showTooltip} setShown={setShowTooltip} parentId={tooltipId} listItems={tooltipActions} />
                    </span> 
                </div>
                <div className={`${classes.message_content} ${classes.flex_row}`}>
                    {message.content}
                </div>
                {message.messageAttachments && (
                    <div className={`${classes.message_attachments} ${classes.flex_column}`}>
                        {message.messageAttachments.map(messageAttachment => (
                            <div key={messageAttachment.id} className={`${classes.message_attachment} ${classes.flex_row}`}>
                                <img src={attachment} alt={messageAttachment.originalFileName} title={messageAttachment.originalFileName} />
                                <span title={messageAttachment.originalFileName}>{messageAttachment.originalFileName}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {message.messages?.map(threadMessage => (
                <Message message={threadMessage} key={threadMessage.id} />
            ))}
        </div>
    );
}

interface MessageProps {
    message: ThreadMessage
}

export default Message;