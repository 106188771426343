import React from "react";
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { AuthorizationProvider } from "../components/Auth/AuthorizationProvider";
import { PrivateRoutes } from "../components/Auth/PrivateRoutes";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import { Forbidden } from "../components/ErrorPage/Forbidden";
import Faq from "../components/Faq/Faq";
import FileReader from "../components/FileReader/FileReader";
import FormSuccess from "../components/FormsPages/FormSuccess/FormSuccess";
import Nameplate from "../components/Nameplate/Nameplate";
import PlantSiteDetails from "../components/PlantSiteDetails/PlantSiteDetails";
import SiteContacts from "../components/SiteContacts/SiteContacts";
import { SvgViewer } from "../components/UI/SvgViewer";
import AdministrationGroupContainer from "../containers/Administration/AdministrationGroupContainer";
import AdministrationListContainer from "../containers/Administration/AdministrationListContainer";
import AdministrationListModuleContainer from "../containers/Administration/AdministrationListModuleContainer";
import { EmailAlertManagementContainer } from "../containers/Administration/EmailAlertManagementContainer";
import { ManageRolesContainer } from "../containers/Administration/ManageRolesContainer";
import { ManageUserContainer } from "../containers/Administration/ManageUserContainer";
import { PlantListContainer } from "../containers/Administration/PlantListContainer";
import PlantManagementContainer from "../containers/Administration/PlantManagementContainer";
import { RoleConfigurationContainer } from "../containers/Administration/RoleConfigurationContainer";
import { UserManagementContainer } from "../containers/Administration/UserManagementContainer";
import { UsersGroupList } from "../containers/Administration/UsersGroupList";
import AddDiscussionContainer from "../containers/Discussion/AddDiscussionContainer";
import DiscussionGroupContainer from "../containers/Discussion/DiscussionGroupContainer";
import DiscussionModuleContainer from "../containers/Discussion/DiscussionModuleContainer";
import DiscussionSubject from "../containers/Discussion/DiscussionSubject/DiscussionSubject";
import DiscussionsContainer from "../containers/Discussion/DiscussionsContainer";
import { DiscussionsModuleIndex } from "../containers/Discussion/DiscussionsModuleIndex";
import ManageDiscussionGroups from "../containers/Discussion/ManageDiscussionGroups/ManageDiscussionGroups";
import AddDocumentContainer from "../containers/Documents/AddDocumentContainer";
import DocumentsContainer from "../containers/Documents/DocumentsContainer";
import DocumentsGroupContainer from "../containers/Documents/DocumentsGroupContainer";
import DocumentsModuleContainer from "../containers/Documents/DocumentsModuleContainer";
import DocumentsModuleIndex from "../containers/Documents/DocumentsModuleIndex";
import { Home } from "../containers/Home/Home";
import PartsAndServiceContainer from "../containers/PartsAndService/PartsAndServiceContainer";
import PlantInformation from "../containers/PlantInformation/PlantInformation";
import PortalAccessRequest from "../containers/PortalAccess/PortalAccessRequest";
import { PortalAccessRequestSubmitted } from "../containers/PortalAccess/PortalAccessRequestSubmitted";
import { ModuleRedirector } from "../containers/Public/ModuleRedirector";
import QuoteRequestContainer from "../containers/QuoteRequests/ModuleSpecificInformation/QuoteRequestsContainer";
import QuoteRequests from "../containers/QuoteRequests/QuoteRequests";
import { RecordingLibraryContainer } from "../containers/RecordingLibrary/RecordingLibraryContainer";
import ManageDocumentGroups from "../containers/Reports/ManageReportGroups/ManageDocumentGroups";
import Search from "../containers/Search/Search";
import SearchContainer from "../containers/Search/SearchContainer";
import ESRContainer from "../containers/Support/ESR/ESRContainer";
import SupportContainer from "../containers/Support/SupportContainer";
import { TechnicalBulletinsContainer } from "../containers/TechnicalBulletins/TechnicalBulletinsContainer";
import Thread from "../containers/Thread/Thread";
import ThreadContainer from "../containers/Thread/ThreadContainer";
import ThreadList from "../containers/Thread/ThreadList";
import SplashPage from "../containers/Welcome/SplashPage/SplashPage";
import { msalFeatureFlag } from "../utils/Auth/msalConfig";

export const Router = ({ providers }: { providers: React.ReactElement }) => {
  const routes = [
    <Route element={<AuthorizationProvider />}>
      <Route element={msalFeatureFlag ? <PrivateRoutes /> : <Outlet />}>
        <Route path={"/"} element={providers}>
          <Route index element={<Home />} />
          <Route path="/OptOut" element={<Home isEmailOptOut={true} />} />
          <Route path={"/shared-document/:fileId"}>
            <Route index element={<FileReader />} />
            <Route path=":fileURI" element={<FileReader />} />
          </Route>
          <Route path="/static-document/:staticDocumentGlobalItemId" element={<FileReader />} />
          <Route path="/document/:globalItemId" element={<FileReader />} />
          <Route path="/plant-information" element={<PlantInformation />}>
            <Route index element={<PlantSiteDetails overview={false} />} />
            <Route path="plant-site-information" element={<PlantSiteDetails overview={false} />} />
            <Route path="equipmentAttachments/:plantEquipmentId" element={<Nameplate />} />
            <Route path="site-contacts" element={<SiteContacts />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="/quote-requests" element={<QuoteRequestContainer moduleId={6} title="Quote Requests" />} />
          <Route path="/quote-request" element={<ThreadContainer globalItemId={6} title="Quote Request" />}>
            <Route path=":threadId" element={<Thread />} />
            <Route index element={<ThreadList />} />
          </Route>
          <Route path="/search" element={<SearchContainer />}>
            <Route path=":searchText" element={<Search />} />
          </Route>
          <Route path="/parts&service" element={<PartsAndServiceContainer />}>
            <Route path={"QuoteRequests"} element={<QuoteRequests />} />
          </Route>

          <Route path="/technical-bulletins" element={<TechnicalBulletinsContainer />} />
          <Route path="/technical-bulletins/:group" element={<TechnicalBulletinsContainer />} />
          <Route path="/recordings" element={<RecordingLibraryContainer />} />

          <Route path="/technical-documentation" element={<DocumentsContainer />}>
            <Route path=":moduleName" element={<DocumentsModuleContainer />}>
              <Route path=":groupId" element={<DocumentsGroupContainer />}>
                <Route path="add" element={<AddDocumentContainer isEdit={false} />} />
                <Route path="edit" element={<AddDocumentContainer isEdit={true} />} />
              </Route>
              <Route index element={<DocumentsModuleIndex />} />
              <Route path="manage-groups" element={<ManageDocumentGroups />} />
            </Route>
          </Route>
          <Route path="/discussions" element={<DiscussionsContainer />}>
            <Route path=":moduleName" element={<DiscussionModuleContainer />}>
              <Route path=":groupId" element={<DiscussionGroupContainer />}>
                <Route path="adddiscussion" element={<AddDiscussionContainer isEdit={false} />} />
                <Route path="editdiscussion" element={<AddDiscussionContainer isEdit={true} />} />                
              </Route>
              <Route index element={<DiscussionsModuleIndex />} />
              <Route path="manage-groups" element={<ManageDiscussionGroups />} />
              <Route path="discussionSubject" element={<DiscussionSubject />} > 
                <Route path="edit" element={<FormSuccess modaltitlename={"testing"} modaltitle={""} comment={""} modalSize={"sm"} button_done={false} button_addmore={false} />} />  
              </Route>
            </Route>
          </Route>
          <Route path="/support" element={<SupportContainer />}>
            <Route path="faq/:supportModuleName" element={<Faq />} />
            <Route path="esr/:supportModuleName" element={<ESRContainer />} />
          </Route>
          <Route path="/administration/group-management" element={<UsersGroupList />} />
          <Route path="/administration/plant-management" element={<PlantListContainer />} />
          <Route path="/administration/plant-management/:plantId" element={<PlantManagementContainer />} />
          <Route path="/administration/role-configuration" element={<RoleConfigurationContainer isDefault={true} />} />
          <Route path="/administration/role-configuration/manage-roles" element={<ManageRolesContainer />} />
          <Route path="/administration/role-configuration/:roleId" element={<RoleConfigurationContainer isDefault={false} />} />
          <Route path="/administration/user-management" element={<UserManagementContainer section="default" />} />
          <Route path="/administration/user-management/:userId" element={<ManageUserContainer />} />
          <Route path="/administration/user-management/internal" element={<UserManagementContainer section="internal" />} />
          <Route path="/administration/user-management/external" element={<UserManagementContainer section="external" />} />
          <Route path="/administration/email-alert-management" element={<EmailAlertManagementContainer />} />
          <Route path="/administration" element={<AdministrationListContainer />}>
            <Route path=":moduleName" element={<AdministrationListModuleContainer />}>
              <Route path=":groupId" element={<AdministrationGroupContainer />} />
            </Route>
          </Route>
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/module/:moduleId/*" element={<ModuleRedirector />} />
        </Route>
      </Route>,
      <Route path="/portal-access" element={<PortalAccessRequest />} />,
      <Route path="/portal-access/submitted" element={<PortalAccessRequestSubmitted />} />,
      <Route path="/splash" element={<SplashPage />} />
    </Route>
  ];
  if (process.env.NODE_ENV === "development") {
    routes.push(<Route path="/svgs" element={<SvgViewer />} />)
  }
  return createBrowserRouter(createRoutesFromElements(routes));
};
