import React, { FunctionComponent, useEffect, useId, useState } from "react";
import useWindowSize from "../../customHooks/useWindowSize";
import Tooltip from "../../components/PopoutTooltip/Tooltip";
import Button from "../../components/Button/Button";
import ImageImports from "../../utils/ImageImports";
import { QuoteRequestFilter as QuoteRequestFilterInterface } from "../../utils/interface";
import classes from "./QuoteRequests.module.css";

const { filter } = ImageImports;

const QuoteRequestFilter: FunctionComponent<{
	appliedFilters: QuoteRequestFilterInterface;
	setAppliedFilters: React.Dispatch<React.SetStateAction<QuoteRequestFilterInterface>>;
}> = ({ appliedFilters, setAppliedFilters }) => {
	const [filters, setFilters] = useState<QuoteRequestFilterInterface>({});
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const id = useId().replaceAll(":", "_");;
	useEffect(() => {
		const handleClick = (e: any) => {
			if (e.target.closest(`#${id}`) === null) {
				setIsOpen(false);
			}
		};
		document.body.addEventListener("click", handleClick);
		return () => document.body.removeEventListener("click", handleClick);
	}, [id]);

	const { isDesktop } = useWindowSize();

	const buildDatesRange = (days: string) => {
		const today = new Date(new Date().setHours(0, 0, 0, 0));
		return {
			from: new Date(new Date(today).setDate(today.getDate() - Number(days))),
			to: today,
		};
	};

	return (
		<div id={id} className="has-tooltip" onClick={() => setIsOpen(!isOpen)} style={{ position: "relative" }}>
			<style type="text/css">
				{`
					h4 {
						white-space: nowrap;
						font-size: 16px;
						margin-bottom: 4px;
					}
					
					form > div {
						margin: 14px;
					}

					label {
						display: block
					}
				`}
			</style>
			<Button className="whiteBtn" text={isDesktop ? "Filter" : "Sort/Filter"} img={<img src={filter} alt="filter" className="bottom pl-2" />}></Button>
			
			<Tooltip shown={isOpen}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						setAppliedFilters(filters);
					}}
					onClick={(e) => e.stopPropagation()}
					style={{minWidth: 290}}
				>
					<div>
						<h4>Show Deleted:</h4>
						<label >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeDeleted: e.currentTarget.checked })} />
						</label>
					</div>
					<div>
						<h4>Priority</h4>
						<div >
							<select aria-label="Default select example" onChange={(e) => setFilters({ ...filters, priority: parseInt(e.currentTarget.value) })}>
								<option value={0}>Select priority</option>
								<option value={1}>High</option>
								<option value={2}>Low</option>
							</select>
						</div>
					</div>
					<div>
						<h4>Status:</h4>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeOpen: e.currentTarget.checked })} />
							Open
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeClosed: e.currentTarget.checked })} />
							Close
						</label>
					</div>
					<div>
						<h4>Number of Replies:</h4>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeNoReplies: e.currentTarget.checked })} />
							No Replies
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeOneToFiveReplies: e.currentTarget.checked })} />1 - 5 Replies
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="checkbox" onChange={(e) => setFilters({ ...filters, includeMoreThanFiveReplies: e.currentTarget.checked })} />5 &gt; Replies
						</label>
					</div>
					<div>
						<h4>System:</h4>
						<div >
							<select aria-label="Default select example" onChange={(e) => setFilters({ ...filters, system: e.currentTarget.value })}>
								<option value="">Select system</option>
								<option value="System 1">System 1</option>
								<option value="System 2">System 2</option>
							</select>
						</div>
					</div>
					<div>
						<h4>Date Posted:</h4>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" defaultValue={1} onChange={(e) => setFilters({ ...filters, posted: buildDatesRange(e.currentTarget.value) })} />
							Last 24 hours
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" defaultValue={7} onChange={(e) => setFilters({ ...filters, posted: buildDatesRange(e.currentTarget.value) })} />
							Last 7 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" defaultValue={30} onChange={(e) => setFilters({ ...filters, posted: buildDatesRange(e.currentTarget.value) })} />
							Last 30 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" defaultValue={60} onChange={(e) => setFilters({ ...filters, posted: buildDatesRange(e.currentTarget.value) })} />
							Last 60 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" defaultValue={90} onChange={(e) => setFilters({ ...filters, posted: buildDatesRange(e.currentTarget.value) })} />
							Last 90 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="posted" onChange={(e) => setFilters({ ...filters, posted: { ...(filters.posted || buildDatesRange("1")), custom: true } })} />
							Custom Range
						</label>
						{filters.posted?.custom && (
							<div >
								<div className={`${classes.filterDates}`}>
									<div>
										<input
											type="date"
											required
											value={`${filters.posted?.from?.getFullYear()}-${("0" + ((filters.posted?.from?.getMonth() || -1) + 1)).slice(-2)}-${(
												"0" + (filters.posted?.from?.getDate() || 1)
											).slice(-2)}`}
											onChange={(e) => {
												const [year, month, date] = [...e.currentTarget.value.split("-")];
												setFilters({
													...filters,
													posted: { ...filters.posted, from: new Date(Number(year), Number(month) - 1, Number(date)) },
												});
											}}
										/>
									</div>
									<div>
										<input
											type="date"
											required
											value={`${filters.posted?.to?.getFullYear()}-${("0" + ((filters.posted?.to?.getMonth() || -1) + 1)).slice(-2)}-${(
												"0" + (filters.posted?.to?.getDate() || 1)
											).slice(-2)}`}
											onChange={(e) => {
												const [year, month, date] = [...e.currentTarget.value.split("-")];
												setFilters({
													...filters,
													posted: { ...filters.posted, to: new Date(Number(year), Number(month) - 1, Number(date)) },
												});
											}}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<div>
						<h4>Las Modified:</h4>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" defaultValue={2} onChange={(e) => setFilters({ ...filters, modified: buildDatesRange(e.currentTarget.value) })} />
							Last 24 hours
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" defaultValue={7} onChange={(e) => setFilters({ ...filters, modified: buildDatesRange(e.currentTarget.value) })} />
							Last 7 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" defaultValue={30} onChange={(e) => setFilters({ ...filters, modified: buildDatesRange(e.currentTarget.value) })} />
							Last 30 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" defaultValue={60} onChange={(e) => setFilters({ ...filters, modified: buildDatesRange(e.currentTarget.value) })} />
							Last 60 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" defaultValue={90} onChange={(e) => setFilters({ ...filters, modified: buildDatesRange(e.currentTarget.value) })} />
							Last 90 Days
						</label>
						<label onClick={(e) => e.stopPropagation()} >
							<input type="radio" name="modified" onChange={(e) => setFilters({ ...filters, modified: { ...(filters.modified || buildDatesRange("1")), custom: true } })} />
							Custom Range
						</label>
						{/******************** Quote Request Filter Section ******************************/}
						{filters.modified?.custom && (
							<div >
								<div className={`${classes.filterDates}`}>
									<div>
										<input
											type="date"
											required
											value={`${filters.modified?.from?.getFullYear()}-${("0" + ((filters.modified?.from?.getMonth() || -1) + 1)).slice(-2)}-${(
												"0" + (filters.modified?.from?.getDate() || 1)
											).slice(-2)}`}
											onChange={(e) => {
												const [year, month, date] = [...e.currentTarget.value.split("-")];
												setFilters({
													...filters,
													modified: { ...filters.modified, from: new Date(Number(year), Number(month) - 1, Number(date)) },
												});
											}}
										/>
									</div>
									<div>
										<input
											type="date"
											required
											value={`${filters.modified?.to?.getFullYear()}-${("0" + ((filters.modified?.to?.getMonth() || -1) + 1)).slice(-2)}-${(
												"0" + (filters.modified?.to?.getDate() || 1)
											).slice(-2)}`}
											onChange={(e) => {
												const [year, month, date] = [...e.currentTarget.value.split("-")];
												setFilters({
													...filters,
													modified: { ...filters.modified, to: new Date(Number(year), Number(month) - 1, Number(date)) },
												});
											}}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<div>
						<button
							type="submit"
							className="primaryBtn darkBlue"
							disabled={
								!(Object.keys(filters) as (keyof typeof filters)[]).find((key) => filters[key]) &&
								!(Object.keys(appliedFilters) as (keyof typeof appliedFilters)[]).find((key) => appliedFilters[key])
							}
						>
							Apply
						</button>
					</div>
				</form>
			</Tooltip>
		</div>
	);
};

export default QuoteRequestFilter;
