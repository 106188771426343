import axios, {AxiosResponse} from "axios";
import { MutationFunction, useMutation, UseMutationOptions } from "react-query";
import {apiMessageThreads, apiMessage, apiMessageAttachment} from "../endpoints/endpoints";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../utils/handleAxiosResponse";
import {CPApiResponse, ThreadMessage, Thread, ThreadMessageAttachment, DataWithPermissions, ModulePermissionResponseInterface} from '../utils/interface';

export const useThreads = () => {

    const getItemThreads = async (globalItemId: number): Promise<DataWithPermissions<Thread[]>> => {
        const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Thread[]>[]>> = await axios(`${apiMessageThreads}/get-item-threads/${globalItemId}`);
        return handleModulePermissionResponseMulti(response);
    };

	const postThreadMessage = async (threadMessage: ThreadMessage): Promise<ThreadMessage> => {
		const response: AxiosResponse<CPApiResponse<ThreadMessage>> = await axios.post(`${apiMessage}`, threadMessage);
		if (response.data.success) return response.data.data;
		throw response.data.message;
	};

	const deleteThreadMessage = async (threadMessageId?: number): Promise<boolean> => {
		if (threadMessageId) {
			const response: AxiosResponse<CPApiResponse<ThreadMessage>> = await axios.delete(`${apiMessage}/${threadMessageId}`);
			return response.data.success;
		}
		else return false;
	};

	return { getItemThreads, postThreadMessage, deleteThreadMessage };
};

type CreateableThreadMessageAttachment = Pick<ThreadMessageAttachment, 'messageId' | 'originalFileName' | 'description'> & {attachment: File}
const createMessageAttachment: MutationFunction<ThreadMessage, CreateableThreadMessageAttachment> = async (messageAttachment: CreateableThreadMessageAttachment): Promise<ThreadMessage> => {
	const response: AxiosResponse<CPApiResponse<ThreadMessage>> = await axios.post(apiMessageAttachment, messageAttachment, {
	  headers: {
		"Content-Type": "multipart/form-data",
	  },
	});
	return handleAxiosResponse(response);
  };
export const useCreateMessageAttachment = (
	options: Omit<UseMutationOptions<ThreadMessage, unknown, CreateableThreadMessageAttachment, unknown>, "mutationKey" | "mutationFn"> = {}
  ) => {
	return useMutation("", createMessageAttachment, options);
  };