import React, { Dispatch, SetStateAction, useContext } from "react";
import classes from "./QuoteRequestForm.module.css";
import ImageImports from "../../utils/ImageImports";
import QuoteRequestFormStepOne from "./QuoteRequestFormStepOne";
import { Store } from "../../Store";
import ErrorCard from "../ErrorCard/ErrorCard";

interface QuoteRequestFormProps {
  setAddNewRequest: Dispatch<SetStateAction<boolean>>;
}
const QuoteRequestForm = (props: QuoteRequestFormProps) => {
  
  const storeContext = useContext(Store);

  const { showError } = storeContext;

  const { Arrow } = ImageImports;

  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <div>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
      <div>
        <div onClick={() => props.setAddNewRequest(false)} className={`${classes.backLink}`}>
          <img className={`back-arrow`} src={Arrow} alt="back" />
          Back to All Quote Requests
        </div>
        <div className={`${classes.qrwrpper}`}>
          <div className={`${classes.title}`}>
            <h2>
              <strong>Begin New Request for Quote</strong>
            </h2>
          </div>
          <p className={classes.para}>
            Mitsubishi Power to provide content here.  
          </p>

          <QuoteRequestFormStepOne />
        </div>
      </div>
    </div>
  );
};

export default QuoteRequestForm;
