import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { apiCountryOfCitizenship } from "../endpoints/endpoints";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { Country, CPApiResponse } from "../utils/interface";
import LocalStorage from "../utils/LocalStorage";

const countriesKey = 'countriesOfCitizenship';

declare type GetAllCountriesOfCitizenshipParameters = [typeof countriesKey];
const getAllCountriesOfCitizenship = async (): Promise<Country[]> => {
    const savedCountries: Country[]|null = LocalStorage.getItem(countriesKey);
    if (savedCountries === null) {
        const response: AxiosResponse<CPApiResponse<Country[]>> = await axios(`${apiCountryOfCitizenship}`);
        const countries = handleAxiosResponse(response);
        if (countries.length > 0) {
            LocalStorage.setItem(countriesKey, countries, 'PT30H');
        }
        return countries;
    }
    
    return savedCountries;
};
export const useGetAllCountriesOfCitizenship = (options: Omit<UseQueryOptions<Country[], unknown, Country[], GetAllCountriesOfCitizenshipParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<Country[], unknown, Country[], GetAllCountriesOfCitizenshipParameters>([countriesKey], getAllCountriesOfCitizenship, {
        cacheTime: 1000000,
        ...options
    });
}