import React, { CSSProperties, MouseEventHandler, MutableRefObject, useCallback, useEffect, useId, useMemo, useState } from "react";
import ImageImports from "../../utils/ImageImports";
import Accordion from "../Accordian/Accordion";
import Button from "../Button/Button";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import classes from './AccordionListItem.module.css';
import { ItemListProps, PropertiesOfType } from "./ItemList";

const { ellipsis } = ImageImports;

interface AccordionListItemProps<T> {
    data: T;
    columns: ItemListProps<T>['columns'];
    bulkActions?: ItemListProps<T>['bulkActions'];
    itemActions?: ItemListProps<T>['itemActions'];
    deletedItem?: ItemListProps<T>['deletedItem'];
    readItemKey?: ItemListProps<T>['readItemKey'];
    accordionTitle?: ItemListProps<T>['accordionTitle'];

    tooltipPortalRef: MutableRefObject<null>;
    isChecked: boolean;
    onRowChecked: MouseEventHandler;
}

export const AccordionListItem = <T extends {id: number}>({data, columns, deletedItem, tooltipPortalRef, bulkActions, isChecked, onRowChecked, readItemKey, accordionTitle, itemActions}: AccordionListItemProps<T>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contentStyle, setContentStyle] = useState<CSSProperties>({});

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const getDataBooleanValue = useCallback(<K extends PropertiesOfType<T, boolean>>(d: T, k: K): boolean => !!d[k], []);
    const isRead = useMemo(() => {
        return readItemKey === undefined || getDataBooleanValue(data, readItemKey);
    }, [data, readItemKey]);

    const isDeleted = useCallback((item: T): boolean => {
      if (deletedItem) {
          if (typeof deletedItem === 'function') {
              return deletedItem(item);
          } else {
              return !!item[deletedItem];
          }
      }
      return false;
  }, [deletedItem]);

    const ItemActionTooltip = useMemo(() => {
      if(itemActions) {
        return withTooltip(Button);
      }
    }, [itemActions]);

    const onTransitionEnd = useCallback(() => {
        setContentStyle({
            overflow: isOpen? 'visible' : 'hidden'
        })
    }, [isOpen])

    return (
        <Accordion isDeleted={isDeleted(data)}
                   titleContent={<AccordionTitle unread={!isRead} isChecked={isChecked} isDisabled={isDeleted(data)} reportName={typeof accordionTitle === 'function'? accordionTitle(data) : ''} onRowChecked={onRowChecked} hasBulkActions={bulkActions !== undefined && bulkActions.length > 0} />}
                   isOpen={isOpen}
                   headerStyle={{}}
                   contentStyle={isOpen? contentStyle : {overflow: 'hidden'}}
                   onTransitionEnd={onTransitionEnd}
                   toggleOpen={toggleOpen}>
                    <div className={`mb-4 ${classes.result_container} ${classes.flex_column} ${!isRead? classes.unread : ''}`}>
                      <table className={`${classes.report_table} ${classes.report_table_accordion}`}>
                        <tbody>
                        {columns.map(columnDefinition => (    
                          <tr key={columnDefinition.key as string} className={classes.data_point}>
                              <th className="text-left">{columnDefinition.title}</th>
                              <td>
                                  {columnDefinition.component? (<columnDefinition.component data={data} />) : (<div><>{data[columnDefinition.key]}</></div>)}
                              </td>
                          </tr>
                        ))}
                        {ItemActionTooltip && itemActions !== undefined && itemActions.length > 0? (
                            <tr>
                                <td>
                                    <div className={`flex flex-row items-center !pb-0`}>
                                        <ItemActionTooltip 
                                          className='border-none bg-transparent cursor-pointer !p-0 mb-0 pb-0'
                                          text={
                                            !isDeleted(data) ? 
                                            <div className="flex flex-row items-center gap-4"><img src={ellipsis} alt="More Actions" className="cursor-pointer" /> <span className="!font-bold text-dark-blue">More Actions</span></div>
                                            : ''
                                          } 
                                          toggleClickWatcher={false}
                                          tooltipContent={<div className="p-4 rounded-lg">
                                            <div className="flex flex-col items-start gap-4">
                                                {itemActions.map((action, i) => (
                                                    <span key={`action-${i}`} className="text-dark-blue" onClick={(e) => {
                                                        action.onClick(e, data);
                                                    }}>{action.text}</span>
                                                ))}
                                            </div>
                                        </div>} />
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        ) : ''}
                    </tbody>
                </table>
            </div>
        </Accordion>
    );
}

interface AccordionTitleProps {
    isChecked: boolean;
    reportName: string;
    onRowChecked: MouseEventHandler;
    unread: boolean;
    hasBulkActions: boolean;
    isDisabled?: boolean;
}

function AccordionTitle({isChecked, reportName, onRowChecked, unread, hasBulkActions, isDisabled = false}: AccordionTitleProps) {
    const checkRow: MouseEventHandler = (e) => {
        e.stopPropagation();
        onRowChecked(e);
    };
    return (
        <div className={`flex flex-row !p-3 items-center ${unread? 'font-bold' : ''}`}>
            {hasBulkActions && (
                <div className="pr-2" style={{flexBasis: '18px'}} onClick={checkRow}>
                    <Checkbox isChecked={isChecked} onChange={() => {}} disabled={isDisabled} />
                </div>
            )}
            {reportName} 
        </div>
    );
}