import dayjs from "dayjs";
import moment from "moment";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { Store } from "../../Store";
import { useCreateRecording, useDeleteRecording, useGetRecordingsByUserGroupParameters, useUpdateRecording } from "../../customHooks/RecordingHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from '../../utils/ImageImports';
import { Permissions, Recording } from "../../utils/interface";
import Button from "../Button/Button";
import { BulkItemActionDefinition, ItemActionDefinition, ItemList, ItemListColumnDefinition, SortOptionsDefinition } from "../List/ItemList";
import EmptyModal from "../Modals/EmptyModal";
import { useBulkUpdateItemsAsRead } from "../NotificationPane/NotificationHttpServices";
import Pill from "../Pill/Pill";
import DateRangePicker from "../UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import MultiSelectDropDown from "../UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem } from "../UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";

const { pencil, trash, ellipsis, tooltip } = ImageImports;

type FilterDate = 'custom'|'P90D'|'P60D'|'P30D'|'P7D'|'P1D';
interface FilterStructure {
    created?: FilterDate;
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
}

interface RecordingLibraryProps {

}

const DatePublishedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const RecordingLibrary = ({}: RecordingLibraryProps) => {
    const {selectedUserGroup, header: {data: {user}}} = useContext(Store);
    const [editRecording, setEditRecording] = useState<Recording>();
    const [editConfirmation, setEditConfirmation] = useState<Recording>();
    const [deleteConfirmation, setDeleteConfirmation] = useState<Recording[]>();
    const [deletedRecordings, setDeletedRecordings] = useState<Recording[]>();
    const [filters, setFilters] = useState<FilterStructure>({});
    const [recordings, setRecordings] = useState<Recording[]>([]);
    const [permissions, setPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const setErrorData = useOnError();

    const {isMobile} = useWindowSize();

    const emptyRecording: Recording = useMemo(() => ({
        created: '',
        id: 0,
        modified: '',
        recordingType: '',
        title: '',
        userGroupIds: [],
        websiteAddress: '',
        globalItemId: 0,
        isRead: true
    }), [selectedUserGroup]);
    
    const { header: { data: { notifications } } } = useContext(Store);

    const { refetch: refreshRecordingList, isFetching } = useGetRecordingsByUserGroupParameters(selectedUserGroup.id, {
        onSuccess: ({data, permissions}) => {
            setRecordings(data.map(d => ({
                ...d,
                isRead: notifications.filter(notification => notification.itemId === d.globalItemId && !notification.read).length === 0
            })));
            setPermissions(permissions);
        },
        enabled: selectedUserGroup.id > 0
    });
    const { mutate: UpdateRecording } = useUpdateRecording();
    const { mutate: CreateRecording } = useCreateRecording();
    const { mutate: DeleteRecordingMutation } = useDeleteRecording();
    
    useEffect(() => {
        setRecordings(recordings => recordings.map(d => ({
            ...d,
            isRead: notifications.filter(notification => notification.itemId === d.globalItemId && !notification.read).length === 0
        })));
    }, [notifications]);

    const { mutate: markItemAsRead } = useBulkUpdateItemsAsRead();
    
    const columns: ItemListColumnDefinition<Recording>[] = useMemo(() => {
        return [
            {
                title: 'Recording Name',
                key: 'title',
                component: (({data}) => <a href={data.websiteAddress} target="_blank" rel="noreferrer" style={{color: "#00749E", textDecoration: "none"}} onClick={() => markItemAsRead([data.globalItemId])}>{data.title}{data.deleted? ' (Deleted)' : ''}</a>)
            },
            {
                title: 'Recording Type',
                key: 'recordingType'
            },
            {
                title: 'Published',
                key: 'created',
                component: (({data}) => <Moment date={data.created} format="MM/DD/YYYY" />)
            }
        ];
    }, []);

    const sortableProperties: SortOptionsDefinition<Recording>[] = useMemo(() => {
        return [
            {
                id: 0,
                name: 'Sort by: Newest to Oldest',
                key: 'created',
                direction: 'desc'
            },
            {
                id: 1,
                name: 'Sort by: Oldest to Newest',
                key: 'created',
                direction: 'asc'
            }
        ];
    }, []);

    const actions: ItemActionDefinition<Recording>[] = [];
    const bulkActions: BulkItemActionDefinition<Recording>[] = [];

    if (permissions.canAdmin || permissions.canEdit || user.portalAdmin) {
        actions.push({
            text: <div className="flex flex-row items-center gap-[10px] whitespace-nowrap font-bold">
                <img src={pencil} alt="Edit Details" />
                <span>Edit</span>
            </div>,
            onClick(event, data) {
                setEditRecording(data);
            },
            disabled: (data) => {
                return data.deleted !== null;
            },
        });
    }

    if (permissions.canAdmin || permissions.canDelete || user.portalAdmin) {
        actions.push({
            text: <div className="flex flex-row items-center gap-[10px] whitespace-nowrap font-bold">
                <img src={trash} alt="Delete" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                <span>Delete</span>
            </div>,
            onClick(event, data) {
                setDeleteConfirmation([data]);
            },
            disabled: (data) => {
                return data.deleted !== null;
            },
        });
        bulkActions.push({
            text: ({selectedItems}) => {
                return (
                    <div className={`flex flex-row items-center gap-[10px] whitespace-nowrap font-bold ${selectedItems.length > 0? 'text-dark-blue cursor-pointer' : 'text-[#cdd9dd]'}`}>
                        <img src={trash} alt="Delete" style={{filter: selectedItems.length > 0? 'var(--svgFilterDarkBlueButton)' : 'var(--svgFilterLightBlueButton)'}} />
                        <span>Delete</span>
                    </div>
                );
            },
            onClick(event, data) {
                setDeleteConfirmation(data);
            },
            disabled(...selectedRecordings) {
                return selectedRecordings.length === 0;
            }
        });
    }

    const filterCount = useMemo(() => {
        let c = 0;
        if (filters.created !== undefined) {
            c++;
        }
        return c;
    }, [filters]);

    const filterRecordings = useCallback((recording: Recording): boolean => {
		switch (filters.created) {
			case undefined:
				return true;
		
			case "custom":
				return ((
					(filters.startDate || filters.endDate) 
					&& (!filters.startDate || filters.startDate?.isBefore(recording.created))
					&& (!filters.endDate || filters.endDate.add(1, 'day').isAfter(recording.created))
				) || false);
		
			default:
				const start = moment().startOf('day').subtract(filters.created);
            	return start.isBefore(moment(recording.created));
		}
    }, [filters]);

    const saveRecording = useCallback((recording: Recording) => {
        if (recording.id > 0) {
            // update recording
            UpdateRecording(recording, {
                onSuccess: () => {
                    refreshRecordingList();
                },
                onError: (error: unknown) => {
                    setErrorData(error);
                },
            });
        } else {
            recording.created = recording.modified = moment().toISOString();
            CreateRecording(recording, {
                onSuccess: () => {
                    refreshRecordingList();
                },
                onError: (error: unknown) => {
                    setErrorData(error);
                },
            });
        }
        // close add/edit modal
        setEditRecording(undefined);
        setEditConfirmation(recording);
    }, []);

    const addAnother = useCallback(() => {
        setEditConfirmation(undefined);
        setEditRecording(emptyRecording);
    }, []);

    const onDelete = useCallback((recording: Recording) => {
        setEditRecording(undefined);
        setDeleteConfirmation([recording]);
    }, []);

    const deleteRecordings = useCallback((recordings: Recording[]) => {

        recordings.map((recording: Recording) => {
            DeleteRecordingMutation(recording.id, {
                onSuccess: () => {
                    refreshRecordingList();
                },
                
            });
        });

        setDeleteConfirmation(undefined);
        setDeletedRecordings(recordings);
    }, []);

    return (
        <div className="flex flex-col p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch">
            {editRecording && <AddEditRecordingModal onClose={() => setEditRecording(undefined)} recording={editRecording} onSave={saveRecording} onDelete={onDelete} />}
            {editConfirmation && <EditConfirmation onClose={() => setEditConfirmation(undefined)} recording={editConfirmation} onAddAnother={addAnother} />}
            {deleteConfirmation && <DeleteRecording onClose={() => setDeleteConfirmation(undefined)} recordings={deleteConfirmation} onDelete={deleteRecordings} />}
            {deletedRecordings && <DeletedRecordingsConfirmation onClose={() => setDeletedRecordings(undefined)} recordings={deletedRecordings} />}
            <div className={`flex ${isMobile? 'flex-col' : 'flex-row'} items-start gap-6 self-stretch justify-between`}>
                <div className="flex flex-col gap-2 grow">
                    <span className="font-bold text-2xl">{selectedUserGroup.name} Recordings</span>
                    <div className="flex flex-row">
                        <span className="text-sm lg:basis-2/3">Please see below for published recordings from Webinars and Users' Conferences.</span>
                    </div>
                </div>
                {permissions.canAdmin || permissions.canAdd || user.portalAdmin? <Button className="darkBlue whitespace-nowrap" text="Add Recording Link" onClick={() => setEditRecording(emptyRecording)} /> : <></>}
            </div>
            <hr className="m-0 text-[#B3B3B3]" />
            <ItemList data={(recordings ?? []).filter(filterRecordings)}
                        columns={columns}
                        sortValues={sortableProperties}
                        loading={isFetching}
                        accordionTitle={u => u.title}
                        searchBoxPlaceholder="Search recordings"
                        itemActions={actions}
                        itemActionText={() => <img src={ellipsis} alt="More Actions" className="cursor-pointer" />}
                        bulkActions={bulkActions}
                        deletedItem={item => item.deleted !== undefined && item.deleted !== null}
                        readItemKey="isRead"
                        filterContent={<Filters filters={filters} setFilters={setFilters} onFiltersUpdated={setFilters} />}
                        filterCount={filterCount}
                        filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />} searchKeys={['recordingType', 'title']} />
        </div>
    );
};
const Filters = ({filters, setFilters, onFiltersUpdated}: {filters: FilterStructure, setFilters: React.Dispatch<React.SetStateAction<FilterStructure>>, onFiltersUpdated: (data: FilterStructure) => void}) => {
	const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
	const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<FilterDate|undefined>(undefined);  
  const [startDate, setStartDate] = useState(dayjs(null));
  const [endDate, setEndDate] = useState(dayjs(null));

  useEffect(() => {
    if (filters.created) {
      setDateFilter(filters.created);
      if (filters.created === "custom") {
        if (filters.startDate) {
          setStartDate(dayjs(filters.startDate));
        }
        if (filters.endDate) {
          setEndDate(dayjs(filters.endDate));
        }
      }
    }
  }, [filters]);

  const applyFilters = useCallback(() => {
    onFiltersUpdated({
        created: dateFilter,
        startDate: dateFilter? startDate : undefined,
        endDate: dateFilter? endDate : undefined, 
    });
  }, [dateFilter, startDate, endDate, onFiltersUpdated]);

  useEffect(() => {
    if (filters.created) {
      setDateFilter(filters.created);
      if (filters.created === "custom") {
        if (filters.startDate) {
          setStartDate(dayjs(filters.startDate));
        }
        if (filters.endDate) {
          setEndDate(dayjs(filters.endDate));
        }
      }
    }
  }, [filters]);


  const filtersChanged = useMemo(() => {
    const obj1 = Object.entries({
      created: filters.created,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    const obj2 = Object.entries({
      created: dateFilter,
      startDate: startDate,
      endDate: endDate,
    });
    return obj1.toString() !== obj2.toString();
  }, [filters, dateFilter, startDate, endDate]);

  const canApplyFilters = ((dateFilter) && filtersChanged);

	return (
        <div className="flex flex-col items-stretch p-6 gap-6 whitespace-nowrap min-w-[300px] max-h-[60vh] overflow-y-scroll overflow-x-hidden filterContainer">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Date Published:</span>
                <RadioButtonGroup onChange={setDateFilter} name="created" options={DatePublishedFilters} value={dateFilter} />
            </div>
            {dateFilter === "custom" && (
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                showEndCalendar={showEndCalendar}
                showStartCalendar={showStartCalendar}
                startName="startDate"
                endName="endDate"
                startDateChange={setStartDate}
                endDateChange={setEndDate}
                setToggleEndCalendarDisplay={setShowEndCalendar}
                setToggleStartCalendarDisplay={setShowStartCalendar}
              />
            )}
            <div>
                <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
            </div>
        </div>
    );
};

const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    const createdMap = {
        P1D: 'Last 24 Hours',
        P7D: 'Last 7 Days',
        P30D: 'Last 30 Days',
        P60D: 'Last 60 Days',
        P90D: 'Last 90 Days',
        custom: 'Custom',
    };
    return (
        <div className="">
            {filters.created !== undefined? (
            <Pill dismissible={true}
                    onDismiss={() => onFiltersUpdated({...filters, created: undefined})}
                    type="default">
                {createdMap[filters.created]}
            </Pill>
            ) : ''}
        </div>
    );
};

const AddEditRecordingModal = ({recording, onClose, onSave, onDelete}: {recording: Recording, onClose: () => void, onSave: (recording: Recording) => void, onDelete: (recording: Recording) => void}) => {
    const {header: {data: {user: {userGroups}}}} = useContext(Store);
    const [title, setTitle] = useState<string>('');
    const [recordingType, setRecordingType] = useState<string>('');
    const [userGroupIds, setUserGroupIds] = useState<number[]>([]);
    const [websiteAddress, setWebsiteAddress] = useState<string>('');
    const {isMobile} = useWindowSize();

    const groupOptions: (DropDownOptionItem & {id: number})[] = userGroups;

    useEffect(() => {
        setTitle(recording.title);
        setRecordingType(recording.recordingType);
        setWebsiteAddress(recording.websiteAddress ?? '');
        if (recording) {
            const selectedUserGroups = (recording.userGroupIds || []);
            if (selectedUserGroups.length > 0) {
                handleGroupSelection(selectedUserGroups, selectedUserGroups[0]);
            }
        }
    }, [recording]);

    const isEditing = useMemo(() => {
        return recording.id > 0;
    }, [recording]);

    const handleGroupSelection = (values: number[], toggled: number) => {
        let tmpValues = [...values];
        let tmpSiteEquipment = (userGroupIds || []);

        /**
         * Remove all grouped options from tmpValues
         * if toggled = 0 and orig values includes 0, select all
         * if toggled = 0 and orig values does not include 0, un select all
         * 
         */

        tmpValues = tmpValues.filter(v => v > 0);
        if (toggled === 0) {
            if (values.includes(0)) {
                tmpValues = tmpSiteEquipment;
            } else {
                tmpValues = [];
            }
        }
        tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

        setUserGroupIds(tmpValues);
    }   

    const saveRecording = useCallback(() => {
         onSave({
            ...recording,
            title,
            recordingType,
            userGroupIds,
            websiteAddress
        }); 
    }, [recording, title, recordingType, websiteAddress, userGroupIds]);

    const body = useMemo(() => {
        return (
            <div className={`flex flex-col items-start self-stretch ${isMobile? 'gap-10': 'gap-8'}`}>
                <div className={`flex self-stretch ${isMobile? 'flex-col gap-2' : 'flex-row gap-6'}`}>
                    <div className={`flex flex-row !pb-0 gap-1 items-center ${isMobile? '' : 'basis-52'}`}>
                        <span>Link Title*</span>
                        <MiniTooltip text="Please enter the Recording Name to be displayed.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <input type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Title"
                        className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none grow text-base border-0 border-b border-solid border-[#B3B3B3]" />
                </div>
                <div className={`flex self-stretch ${isMobile? 'flex-col gap-2' : 'flex-row gap-6'}`}>
                    <div className={`flex flex-row !pb-0 gap-1 items-center ${isMobile? '' : 'basis-52'}`}>
                        <span>Recording Type*</span>
                        <MiniTooltip text="Please enter the Recording Type.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <input type="text"
                        value={recordingType}
                        onChange={e => setRecordingType(e.target.value)}
                        placeholder="Recording Type"
                        className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none grow text-base border-0 border-b border-solid border-[#B3B3B3]" />
                </div>
                <div className={`flex self-stretch ${isMobile? 'flex-col gap-2' : 'flex-row gap-6'}`}>
                    <div className={`flex flex-row !pb-0 gap-1 items-center ${isMobile? '' : 'basis-52'}`}>
                        <span>Users' Group Audience(s)*</span>
                    </div>
                    
                    <MultiSelectDropDown className="relative outline-none border-none grow text-base"
                                        selectClass={`flex flex-row justify-between items-center pt-[5px] pr-2 !pb-[5px] pl-1 border-0 border-b border-solid border-[#B3B3B3] cursor-pointer ${userGroupIds ? '' : 'text-[#999]'}`}
                                        optionsClass="flex flex-col flex-nowrap p-1 bg-white rounded max-h-80 w-full"
                                        dropdownStyles={{overflow: 'auto'}}
                                        itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20 flex"
                                        onSelection={handleGroupSelection}
                                        hasSelectionText={userGroupIds.length > 1? 'Multiple Groups selected' : `${groupOptions.find(d => d.id === userGroupIds[0])?.name || '1 Group Selected'}`}
                                        noSelectionText="Select Users' Group Audience"
                                        options={groupOptions}
                                        value={userGroupIds ?? []} />
                </div>
                <div className={`flex self-stretch ${isMobile? 'flex-col gap-2' : 'flex-row gap-6'}`}>
                    <div className={`flex flex-row !pb-0 gap-1 items-center ${isMobile? '' : 'basis-52'}`}>
                        <span>Recording URL*</span>
                        <MiniTooltip text="Please enter the Recording URL.">
                            <img src={tooltip} alt="Help" className="h-4 w-4" />
                        </MiniTooltip>
                    </div>
                    <input type="url"
                        value={websiteAddress}
                        name="websiteAddress"
                        onChange={e => setWebsiteAddress(e.target.value)}
                        placeholder="https://website.com"
                        pattern="http(s)?:\/\/.+\.[a-zA-Z]+.*"
                        className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none grow text-base border-0 border-b border-solid border-[#B3B3B3]" />
                </div>
            </div>
        );
    }, [recording, title, recordingType, userGroupIds, websiteAddress, isMobile]);

    const canSave = useMemo(() => {
        const checks: (() => boolean)[] = [
            () => title.length > 0,
            () => recordingType.length > 0,
            () => userGroupIds.length > 0,
            () => (websiteAddress ?? '').length > 0,
            () => (websiteAddress ?? '').match(/http(s)?:\/\/.+\.[a-zA-Z]+.*/) !== null,
            () => Object.entries({
                    title, recordingType, userGroupIds, websiteAddress
                }).toString() !== Object.entries({
                    title: recording.title,
                    recordingType: recording.recordingType,
                    userGroupIds: recording.userGroupIds,
                    websiteAddress: recording.websiteAddress
                }).toString()
        ];
        let canSave = true;
        for (let i = 0; i < checks.length && canSave === true; i++) {
            canSave = checks[i]();
        }

        return canSave;
    }, [title, recordingType, userGroupIds, websiteAddress, recording]);

    return (
        <EmptyModal body={body}
            className="overflow-visible"
            footer={(
                <div className={`flex ${isMobile? 'flex-col' : 'flex-row justify-between w-full'}`}>
                    <div className={`flex ${isMobile? 'flex-col' : 'flex-row gap-4'}`}>
                        {isEditing? (
                            <>
                                <Button className="darkBlue" text="Save Changes" disabled={!canSave} onClick={saveRecording} />
                                <Button className="redDelete" text="Delete" onClick={() => onDelete(recording)} />
                            </>
                        ) : <Button className="darkBlue" text="Add Recording Link" disabled={!canSave} onClick={saveRecording} />}
                    </div>
                    <span className={`font-light text-sm ${isMobile? 'w-full text-center' : ''}`}>*Required fields</span>
                </div>
            )}
            heading={isEditing? 'Edit Recording Link' : 'Add a Recording Link'}
            onClose={onClose} shown={true} />
    );
};

const EditConfirmation = ({recording, onClose, onAddAnother}: {recording: Recording, onClose: () => void, onAddAnother: () => void}) => {
    const wasEditing = useMemo(() => {
        return recording.id > 0;
    }, [recording]);

    // don't know if recording.userGroupId will be an array or number at this point... 
    let bodyText: string = '';
    let footerButtons: ReactNode = '';
    let headingText = '';
    if (wasEditing) {
        headingText = 'Changes Saved';
        bodyText = `The recording details for "${recording.title}" have been successfully updated.`;
        footerButtons = (
            <Button className="darkBlue" text="Done" onClick={onClose} />
        );
    } else {
        bodyText = `"${recording.title}" was successfully added to multiple Users' Groups' recording libraries. Would you like to add another recording at this time?`;
        headingText = 'Recording Successfully Added';
        footerButtons = (
            <>
                <Button className="darkBlue" text="Add Another Recording Link" onClick={onAddAnother} />
                <Button className="whiteBtn" text="Done" onClick={onClose} />
            </>
        );
    }

    return (
        <EmptyModal body={(
                <span>{bodyText}</span>
            )}
            footer={(
                <div className="flex flex-row justify-center grow gap-4">
                    {footerButtons}
                </div>
            )}
            heading={headingText}
            headingClass={'text-center'}
            onClose={onClose} shown={true} width={564} />
    );
};

const DeleteRecording = ({recordings, onClose, onDelete}: {recordings: Recording[], onClose: () => void, onDelete: (recordings: Recording[]) => void}) => {
    const {selectedUserGroup} = useContext(Store);
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6">
                    <span>Are you sure you want to delete the selected recording(s) from {selectedUserGroup.name} Users' Group Recordings? This recording(s) will no longer be accessible to {selectedUserGroup.name} users. This cannot be undone.</span>
                    <div className="text-[#666] text-sm self-stretch">
                        {recordings.map(recording => (
                            <div key={recording.id}>{recording.title}</div>
                        ))}
                    </div>
                </div>
            )}
            footer={(
                <div className="flex flex-row grow gap-4">
                    <Button className="redDelete" text="Yes, Delete" onClick={() => onDelete(recordings)} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            )}
            heading={`Delete (${recordings.length}) Recordings`}
            onClose={onClose} shown={true} />
    );
};

const DeletedRecordingsConfirmation = ({recordings, onClose}: {recordings: Recording[], onClose: () => void}) => {
    const {selectedUserGroup} = useContext(Store);
    
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6 text-center">
                    <span>You successfully deleted recording(s) on the {selectedUserGroup.name} Users' Group Recordings page. This recording(s) is no longer accessible to {selectedUserGroup.name} users.</span>
                </div>
            )}
            footer={(
                <div className="flex flex-row grow gap-4 justify-center">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading={`(${recordings.length}) Recordings Successfully Deleted`}
            headingClass="text-center"
            onClose={onClose} shown={true} width={564} />
    );
};