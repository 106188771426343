import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCreateLink, useGetAllLinks, useUpdateLink } from "../../customHooks/LinkHttpServices";
import { Store } from "../../Store";
import { Link } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import Button from "../Button/Button";
import { AddEditLink, AddEditLinkConfirmation } from "../Links/AddEditLink";
import { LinkDisplay } from "../Links/LinkDisplay";

export const Links = () => {
    const { selectedUserGroup } = useContext(Store);
    const [addLink, setAddLink] = useState<Link>();
    const [addLinkConfirmation, setAddLinkConfirmation] = useState<Link>();
    const [links, setLinks] = useState<Link[]>([]);

    const {data: linkObjects, refetch: refetchLinks} = useGetAllLinks();
    useEffect(() => {
        setLinks(linkObjects || []);
    }, [linkObjects]);

    const {mutate: createLink} = useCreateLink();
    const {mutate: updateLink} = useUpdateLink();

    const defaultLink: Link = useMemo(() => {
        return {
            created: '',
            createdBy: '',
            displayOrder: (Math.max(...[...(links?? []), {displayOrder: 0}].map(d => d.displayOrder)) + 1),
            id: 0,
            modified: '',
            modifiedBy: '',
            title: '',
            url: '',
            userGroupId: selectedUserGroup.id
        };
    }, [links]);

    const sortFn = useMemo(() => {
        return getSortObjectsByKeyFn<Link>('displayOrder');
    }, []);

    const saveLinkCallback = useCallback((link: Link) => {
        setAddLink(undefined);
        setAddLinkConfirmation(link);
        refetchLinks();
    }, []);

    const saveLink = useCallback((link: Link) => {
        if (link.id > 0) {
            updateLink(link, {onSuccess: saveLinkCallback});
        } else {
            createLink(link, {onSuccess: () => saveLinkCallback(link)});
        }
    }, []);

    const addAnotherLink = useCallback(() => {
        setAddLinkConfirmation(undefined);
        setAddLink(defaultLink);
    }, [defaultLink]);

    return (
        <div className={`flex flex-col items-start p-6 gap-6 bg-white shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] rounded-lg w-full ${links.length > 0? 'justify-between' : ''}`}>
            {addLink && <AddEditLink link={addLink} onClose={() => setAddLink(undefined)} onDelete={() => {}} onSave={saveLink} />}
            {addLinkConfirmation && <AddEditLinkConfirmation link={addLinkConfirmation} onAddAnother={addAnotherLink} onClose={() => setAddLinkConfirmation(undefined)} />}
            <div className="flex flex-col gap-6 self-stretch">
                <span className="text-2xl font-bold">Links</span>
                {/** Loop over links */}
                {links.sort(sortFn).map(link => <LinkDisplay key={link.id} link={link} />)}
            </div>
            <RequiresAdmin>
                <Button className="whiteBtn" text="Add Link" onClick={addAnotherLink} />
            </RequiresAdmin>
        </div>
    );
};