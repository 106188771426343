import React, { CSSProperties, FunctionComponent, HTMLProps, MouseEventHandler, TransitionEventHandler, useEffect, useRef, useState } from "react";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import classes from './Accordion.module.css';

const { downArrow } = ImageImports;

interface AccordionProps extends HTMLProps<HTMLDivElement> {
    titleContent: (() => React.ReactElement) | React.ReactElement | string;
    isOpen: boolean;
    isDeleted: boolean;
    toggleOpen: MouseEventHandler;
    headerStyle: CSSProperties;
    contentStyle: CSSProperties;
    onTransitionEnd?: TransitionEventHandler
}


/**
 * # Accordion
 * You can't animate the css property 'height' from 0 to auto\
 * So when the accordion opens:
 * - Set the height of the content to auto
 * - Get the height of the content
 * - Immediately set the height back to 0
 * - Even on lower end devices, you will not see this.
 * 
 * When you have the height of the content, set the height to that number of pixels. 
 * - Sometimes, when the browser is working very fast, the transition of the height 0-auto-0-n would be so quick that the browser would not process the animation correctly
 * - To solve that, a very brief pause is put in-between setting the height to 0 and the final height.
 * - So the open process is actually 0-auto-0-20ms_pause-final height
 */


export const Accordion: FunctionComponent<AccordionProps> = ({onTransitionEnd, ...props}: AccordionProps) => {
    const {titleContent, children, isOpen, toggleOpen,isDeleted, headerStyle, contentStyle, ...divProps} = props;
    const ref = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<CSSProperties>();
    const {isMobile, isTablet, isDesktop} = useWindowSize();

    const close = () => setStyles(styles => ({...styles, height: 0}));
    const open = () => {
        const height = checkHeight();
        setTimeout(() => {
            setStyles(styles => ({...styles, height: `${height}px`}));
        }, 20);
    }

    // when we click to open/close, be sure we have a height set. If not, set the height in the styles back to auto
    const checkHeight = () => {
        let height = 0;
        if (ref.current) {
            ref.current.style.height = 'auto';
            const rect = ref.current.getBoundingClientRect();
            ref.current.style.height = '0';
            height = rect.height;
        }
        return height;
    };

    // when isOpen changes, open/close the content
    useEffect(() => {
        if (isOpen) {
            open();
        } else {
            close(); 
        }
    }, [isOpen]);

    // when the screen size changes, update the height
    useEffect(() => {
        if (isOpen) {
            close();
            open();
        }
    }, [isMobile, isTablet, isDesktop]);

    return (
        <div style={isDeleted ? {opacity:"0.5"} : {opacity:'1'}} className={`${classes.accordion} ${isOpen? classes.open : ''}`} {...divProps} onTransitionEnd={onTransitionEnd}>
            <div className={classes.accordion_header} onClick={toggleOpen} style={headerStyle}>
                <>
                {titleContent}
                <img src={downArrow} alt="Expand/Collapse" className={classes.dropdown_arrow} />
                </>
            </div>
            <div ref={ref} className={classes.accordion_content} style={{...contentStyle, ...styles}}>
                {children}
            </div>
        </div>
    );
} 

export default Accordion;

Accordion.defaultProps = {
    headerStyle: {},
    contentStyle: {}
};