import React, { useContext, useState } from "react";
import { AxiosError } from "axios";
import { Store } from "../../../Store";
import { CPApiResponse, User } from "../../../utils/interface";
import { useQuery_User } from "./ESRHttpServices";
import ImageImports from "../../../utils/ImageImports";
import classes from "./ESR.module.css";

const ESRLinks = ({ setErrorData }: { setErrorData: (errorData: any) => void }) => {
	const { linkout } = ImageImports;

	const { header } = useContext(Store);
	const [user, setUser] = useState<User>();

	const onSuccess = ({ data }: CPApiResponse<User>) => !user && setUser(data);
	const onError = (error: AxiosError<string>) => setErrorData(error);

	useQuery_User(header.data.user.userId, onSuccess, onError);

	return (
		<div style={{ fontSize: 14 }}>
			<div>
				<a href="https://www.gtnews.mhi.co.jp/en/" target="_blank" rel="noreferrer" className={`${classes.link}`}>
					<div className={`${classes.linkTitle}`}>
						<img src={linkout} className={`${classes.linkout}`} alt="" /> <span>Go to Internal ESR App</span>
					</div>
				</a>
				<div> Request technical information or send questions to Mitsubishi Power Americas on technical topics. Responses will be documented here along with the requests.
  </div>
			</div>
			{user?.mpInternal && (
				<div style={{ marginTop: 20 }}>
					<a href="https://www.gtnews.mhi.co.jp/en/" target="_blank" rel="noreferrer" className={`${classes.link}`}>
						<div className={`${classes.linkTitle}`}>
							<img src={linkout} className={`${classes.linkout}`} alt="" /> <span>Go to Takasago Technical Bulletin Website (TBW)</span>
						</div>
					</a>
					<div>
						The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine Service Engineering in Takasago, and requires a
						separate username and password.
					</div>
				</div>
			)}
		</div>
	);
};

export default ESRLinks;
