import { useEffect, useState } from "react"

const useWindowSize = () =>{
    
    const [windowSize, setWindowSize] = useState([window.innerHeight,window.innerWidth]);
    const [deviceUsed, setDeviceUsed] = useState({isMobile:false,isTablet:false,isDesktop:false,isTabletLandscape:false});
    
    const isMobileFun =() => {
        if(window.innerWidth <= 1180 && window.innerWidth >= 1024){
            setDeviceUsed({...deviceUsed,isMobile:false,isTablet:false,isDesktop:true,isTabletLandscape:true} )
        }
        else if(window.innerWidth <= 1023 && window.innerWidth >= 768){
            setDeviceUsed({...deviceUsed,isMobile:false,isTablet:true,isDesktop:false,isTabletLandscape:false} )
        }else if(window.innerWidth <= 767){
            setDeviceUsed({...deviceUsed,isMobile:true,isTablet:false,isDesktop:false,isTabletLandscape:false} )
        }else{
            setDeviceUsed({...deviceUsed,isMobile:false,isTablet:false,isDesktop:true,isTabletLandscape:false} )
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowSize([window.innerHeight,window.innerWidth])
            isMobileFun();
            
        }

        window.addEventListener('resize',handleResize);
        isMobileFun();
        
        return () => {
            window.removeEventListener('resize',handleResize);
        }
    },[]);
    // console.log(deviceUsed);
    return deviceUsed;
}

export default useWindowSize;