import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ToolTip from "../../../components/ToolTip/ToolTip";
import useHeaderHeight from "../../../customHooks/useHeaderHeight";
import useScroll from "../../../customHooks/useScroll";
import useWindowSize from "../../../customHooks/useWindowSize";

import { useRef } from "react";
import { Store } from "../../../Store";
import { AddToHomeScreen } from "../../../components/AddToHomeScreen/AddToHomeScreen";

function Layout() {
  const { isDesktop } = useWindowSize();
  const { onScroll100Active } = useScroll();
  const AppHeaderHeight = useRef<HTMLDivElement>(null);
  const { headerHeight, headerHeightPadding } = useHeaderHeight(AppHeaderHeight);
  const { userGroups } = useContext(Store);
  
  return (
    <div className="App lg:min-h-screen flex">
      <div
        className={`App-header w-full ${onScroll100Active ? "active" : ""}`}
        ref={AppHeaderHeight}
      >
        {headerHeight !== null && <Navbar headerHeight={headerHeight} />}

      </div>
      {headerHeightPadding !== null && (

        <div className="App-body w-full" style={{paddingTop: headerHeightPadding }}>
          {
            isDesktop && <Sidebar />
          }
          {/* CONTINUE LOADING THE APP WHEN WE HAVE A BASEORGID */}
          {userGroups.length && <Outlet />}
          <ToolTip headerHeight={headerHeightPadding} />

        </div>
      )}
      {/* <Footer /> */}
      <AddToHomeScreen />
    </div>
  );
}

export default Layout;
