import axios, { AxiosResponse } from "axios";
import { MutationFunction, useQuery, UseQueryOptions } from "react-query";
import { userGroupApiUrl } from "../endpoints/endpoints";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, UserGroup } from "../utils/interface";


declare type GetUsersGroupsParameters = ['usersgroups'];
const getUsersGroups = async (): Promise<UserGroup[]> => {
    const response: AxiosResponse<CPApiResponse<UserGroup[]>> = await axios(userGroupApiUrl);
    return handleAxiosResponse(response).map(d => ({
      ...d
    }));
}
export const useGetUsersGroups = (options: Omit<UseQueryOptions<UserGroup[], unknown, UserGroup[], GetUsersGroupsParameters>, 'queryKey' | 'queryFn'> = {}) => {
  return useQuery<UserGroup[], unknown, UserGroup[], GetUsersGroupsParameters>(['usersgroups'], getUsersGroups, {
      initialData: [],
      ...options
  });
}


export const createUserGroup: MutationFunction<UserGroup, Pick<UserGroup, 'name' | 'description'>> = async (data: Pick<UserGroup, 'name' | 'description'>): Promise<UserGroup> => {
  const response: AxiosResponse<CPApiResponse<UserGroup>> = await axios(userGroupApiUrl, {
      method: 'POST',
      data
  });
  return handleAxiosResponse(response);
};

export const updateUserGroup: MutationFunction<UserGroup, UserGroup> = async (data: UserGroup): Promise<UserGroup> => {
  const response: AxiosResponse<CPApiResponse<UserGroup>> = await axios(`${userGroupApiUrl}/${data.id}`, {
      method: 'PUT',
      data
  });
  return handleAxiosResponse(response);
}


export const deleteUserGroup: MutationFunction<boolean, number> = async (orgGroupId: number): Promise<boolean> => {
  const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${userGroupApiUrl}/${orgGroupId}`, {
      method: 'DELETE'
  });
  return handleAxiosResponse(response);
}