import React, { useState } from "react";
import classes from "../../components/QuoteRequestSurvey/QuoteRequestForm.module.css";
import Button from "../../components/Button/Button";
import QuoteRequestForm from "../../components/QuoteRequestSurvey/QuoteRequestForm";
import { Link } from "react-router-dom";
import QuoteRequestList from "./QuoteRequestsList";

const QuoteRequests = () => {
  const moduleName = "Quote Requests";
   const [addNewRequest, setAddNewRequest] = useState<boolean>(false);
  
  return (
    <>
      <div className="path breadcrumb">
        <Link to={"/"} className="link_text">
          Home
        </Link>{" "}
        / Parts and Service / {moduleName}
      </div>
      <div className="login-header">{moduleName}</div>
      <div className={classes.card}>
        {addNewRequest ? (
          <>           
            <QuoteRequestForm setAddNewRequest={setAddNewRequest}/>
          </>
        ) : (
          <div>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <div style={{ flex: "2" }}>
              <div style={{ padding: "0" }} className={`${classes.title}`}>
                <h2>
                  <strong>Brunswick County Requests for Quotation</strong>
                </h2>
              </div>
              <p className={classes.para}>
                This page is the central location for users to request parts and service quotations from Mitsubishi Power Americas. If the request is
                made through this page of the Mitsubishi Power Americas Customer Portal, the response from Mitsubishi Power Americas will be
                documented here along with the request and all members of the Customer Portal group for the specific plant site will get notification
                e-mails from the Customer Portal when any requests are made or responses are posted.
              </p>
            </div>
            <div style={{ flex: "1" }}>
              <Button style={{ marginLeft: "auto" }} onClick={() => setAddNewRequest(true)} className={"darkBlue add-button"} text={"Request New Quote"} />
            </div>
          </div>
            <div>
              <QuoteRequestList/>
            </div>
          </div>
        )}
            
      </div>
    </>
  );
};

export default QuoteRequests;
