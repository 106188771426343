import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import classes from "./SearchField.module.css";
import ImageImport from "../../../../utils/ImageImports";

const SearchField = (props: SearchFieldProps) => {
  const { magnifyGlassBlack, closeImg } = ImageImport;
  const [searchText, setSearchText] = useState<string>("");
  const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    props.handleSearchText(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
    props.handleSearchText("");
  };

  return (
    <div className={classes.inputsearchbox}>
      <button className={classes.btn} type="button">
        <img src={magnifyGlassBlack} className="" alt="keyword search" />
      </button>
      <input
        className={classes.formcontrol}
        id="mobileMenuSearch"
        placeholder={props.placeholder}
        onChange={handleSearchText}
        value={searchText}
      />
      {
        props.showClose ?
          <button className={`${classes.btn} ${classes.right_btn}`} type="button" onClick={clearSearch}>
            <img src={closeImg} className="" alt="close" />
          </button> : <></>
      }
    </div>
  );
};
interface SearchFieldProps {
  handleSearchText(searchtext: string): void;
  placeholder:string;
  showClose?: boolean;
  value?: string;
}
export default SearchField;
