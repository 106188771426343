
import "./Home.css";
import { MainContent } from "../MainContent/MainContent";
import { AnnouncementContainer } from "../../components/Announcements/AnnouncementContainer";
import { TermsOfUse } from "../../components/TermsOfUse/TermsOfUse";
import { HomeComponentProps } from "./Home";
import Alert from "../../components/Alert/Alert";

export const HomeNoGroup = ({successMessage, clearSuccessMessage}: HomeComponentProps) => {

  return (
    <MainContent title="Users' Group Portal" description="Welcome to the Mitsubishi Power Users' Groups homepage. Click above for the Users' Group you are interested in viewing. The content of each Group is driven by its officers and members.">
      <div className="flex flex-col items-start gap-10">
        {successMessage && (
          <div className="mb-2 w-full">
            <Alert type="success" onClose={clearSuccessMessage}>
              <span>
                {successMessage}
              </span>
            </Alert>
          </div>
        )}
        <AnnouncementContainer />
        <TermsOfUse />
      </div>
    </MainContent>
  );
}

