import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Store } from '../../../Store';
import Alert from '../../../components/Alert/Alert';
import useWindowSize from '../../../customHooks/useWindowSize';
import HeaderButtons from '../HeaderButtons';
import classes from "../discussions.module.css";
import headerClasses from './header.module.css';


function DiscussionHeader() {
    const {selectedUserGroup} = useContext(Store);
    const {isDesktop} = useWindowSize();
    const params = useParams();
    const groupId = Number.parseInt(params.groupId || '0');
    return (
        <div className={`${classes.flex_row} ${headerClasses.header} ${isDesktop? '' : headerClasses.not_desktop}`}>
            <div className={`${headerClasses.header_content} ${classes.flex_column}`}>
                <div className={headerClasses.header_title}>
                    {selectedUserGroup.name} Discussions
                </div>

                {groupId === 0 &&
                  <div className='w-full'>
                    <Alert dismissible={false} type="warning" onClose={() => {}}>Please note: You must add a discussion category before you can start a discussion.</Alert>
                  </div>
                }
                
                <div className={headerClasses.group_header_text}>
                See below for active discussions. Please contact your Mitsubishi Power representative to add a new discussion category.
                </div>
                {!isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={`${headerClasses.add_button}`} addButtonText='Start Discussion' />}
            </div>
            {isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText='Start Discussion' />}
        </div>
    );
}

export default DiscussionHeader;