import {Outlet, useParams} from 'react-router-dom';
import classes from './Documents.module.css';
import useWindowSize from '../../customHooks/useWindowSize';
import { CrudMethods, TechnicalBulletins, GeneralDocuments } from './documentGroupApiMethods';
import TechBulletinHeader from './ModuleSpecifics/TechBulletinHeader';
import AddEditGeneralDocument from './ModuleSpecifics/AddEditGeneralDocument';
import { TechnicalBulletins as tbReportApi, DocumentModuleApiMethods, GeneralDocuments  as gdApi } from './documentApiMethods';
import React, { useContext, useEffect, useState } from 'react';
import { DataWithPermissions, DocumentGroup, ModuleNames, Permissions, SiteUnit } from '../../utils/interface';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Store } from '../../Store';
import { useOnError } from '../../customHooks/useOnError';
import TechnicalAddDocument from './ModuleSpecifics/TechnicalAddDocument';
import { ModuleIDs } from '../../utils/moduleIDs';
import GeneralDocumentsHeader from './ModuleSpecifics/GeneralDocumentsHeader';
import { MainContent } from '../MainContent/MainContent';


export interface AddDocumentRequiredProps {
    onSave: (data: any) => void;
    documentGroups: DocumentGroup[];
    siteUnits: SiteUnit[];
    documentModule?:DocumentModuleInterface;
    isEdit: boolean;
    setAddDocumentError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface DocumentModuleInterface {
    name: string;
    section: string;
    id: number;
    addEditHeaderName: string;
    addModalHeaderContent: string[];
    moduleHeaderContent: React.FunctionComponent<{pagePermissions: Permissions, groupPermissions: Permissions}>;
    addDocumentModalBodyContent: React.FunctionComponent<AddDocumentRequiredProps>;
    documentGroupApi: CrudMethods<any, any, any>;
    documentApi: DocumentModuleApiMethods<any>;
    moduleId: ModuleIDs;
    groupModule: ModuleNames|ModuleNames[]
}

export interface DocumentsOutletInterface {
    documentModules: DocumentModuleInterface[];
    documentModule?: DocumentModuleInterface;
};

interface DocumentsContainerProps {}

function DocumentsContainer (props: DocumentsContainerProps) {
    const {isMobile} = useWindowSize();
    const {moduleName} = useParams();
    const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
    const [pagePermissions, setPagePermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const [groupPermissions, setGroupPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const {selectedUserGroup} = useContext(Store);
    const setErrorData = useOnError();

    const documentModules: DocumentModuleInterface[] = [
        {
            id: ModuleIDs.GeneralDocuments,
            name: selectedUserGroup.id > 0? "Users' Group Documents" : 'General Documents',
            section: 'General Documents',
            addEditHeaderName: 'a Document',
            moduleHeaderContent: GeneralDocumentsHeader,
            addDocumentModalBodyContent: AddEditGeneralDocument,
            documentGroupApi: GeneralDocuments,
            documentApi: gdApi,
            addModalHeaderContent: [],
            moduleId: selectedUserGroup.id > 0? ModuleIDs.UserGroupDocuments : ModuleIDs.GeneralDocuments,
            groupModule: selectedUserGroup.id > 0? 'UserGroupDocumentsGroups' : 'GeneralDocumentGroups'
        },
        {
            id: ModuleIDs.TechnicalBulletins,
            name: 'Technical Bulletins',
            section: 'Technical Bulletins',
            addEditHeaderName: 'a Mitsubishi Power Technical Document',
            moduleHeaderContent: TechBulletinHeader,
            addDocumentModalBodyContent: TechnicalAddDocument,
            documentGroupApi: TechnicalBulletins,
            documentApi: tbReportApi,
            addModalHeaderContent: [],
            moduleId: ModuleIDs.TechnicalBulletins,
            groupModule: 'TechnicalBulletinsGroups'
        }
    ];

    const sortByDisplayOrderAsc = ({displayOrder: a}: DocumentGroup, {displayOrder: b}: DocumentGroup): number => {
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    };

    useEffect(() => {
        setDocumentGroups([]);
    }, [moduleName]);

    const documentModule = documentModules.find(module => module.section === moduleName);
    const {isFetching} = useQuery(`documentGroups.get.${selectedUserGroup.name}`, (documentModule? documentModule.documentGroupApi.getAll : () => new Promise((resolve) => resolve([]))), {
        onSuccess: ({data, permissions}: DataWithPermissions<DocumentGroup[]>) => {
			setGroupPermissions(permissions);
            setDocumentGroups(data.sort(sortByDisplayOrderAsc) || []);
        },
        onError: (error: AxiosError<string>) => {
            setErrorData(error);
        }
    });

    return (
        <MainContent title={documentModule?.name || 'General Documents'} breadCrumbs={['Documents', documentModule ? documentModule.name :  '']} showPlantDropdown={false}>
            <div className={`${classes.flex_column} ${classes.documentation_container} ${isMobile? classes.is_mobile : ''}`}>
                <div className='flex flex-col  lg:p-6 gap-6 bg-white rounded-lg self-stretch shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)] items-stretch'>
                    <div className={`card ${classes.flex_column} ${classes.documentation_container_outlet}`}>
                        <Outlet context={{documentModules, documentModule, documentGroups: isFetching? [] : documentGroups, setDocumentGroups, groupPermissions, pagePermissions, setPagePermissions}} />
                    </div>
                </div>
            </div>
        </MainContent>
    );
}

export default DocumentsContainer;