import { MainContent } from '../MainContent/MainContent';
import PlantManagement from './ModuleSpecific/PlantManagement';

export interface IPlantManagementContainerProps {
}

export default function PlantManagementContainer(props: IPlantManagementContainerProps) {
    return (
        <MainContent title="Plant Management" breadCrumbs={['Administration', 'Plant Management']}>
            <PlantManagement /> 
        </MainContent>
    );
}
