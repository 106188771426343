import { useMemo } from 'react';
import { Outlet, useOutletContext, useLocation } from "react-router-dom";
import { DocumentsOutletInterface } from "./DocumentsContainer";
import classes from "./Documents.module.css";
import { DocumentModuleInterface } from "./DocumentsContainer";
import { DocumentGroup, Permissions } from "../../utils/interface";
import useWindowSize from "../../customHooks/useWindowSize";

export interface DocumentsModuleOutletInterface extends DocumentsOutletInterface {
	documentModule: DocumentModuleInterface;
	documentGroups: DocumentGroup[];
	setDocumentGroups: React.Dispatch<React.SetStateAction<DocumentGroup[]>>;
	groupPermissions: Permissions;
	pagePermissions: Permissions;
	setPagePermissions: React.Dispatch<React.SetStateAction<Permissions>>
}
function DocumentsModuleContainer() {
	const outletContext = useOutletContext<DocumentsModuleOutletInterface>();
	const { documentGroups, setDocumentGroups } = outletContext;
	const { isMobile } = useWindowSize();
	const location = useLocation();

	const ModuleHeaderContent = useMemo(() => {
		return outletContext.documentModule.moduleHeaderContent;
	}, [outletContext.documentModule]);

	return (
		<div className={`${classes.module_container} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""}`}>
			{outletContext.documentModule && !location.pathname.endsWith("manage-groups") && (
				<>
					<div className={`${classes.top_section} ${classes.flex_column}`}><ModuleHeaderContent groupPermissions={outletContext.groupPermissions} pagePermissions={outletContext.pagePermissions} /></div>
					<div className={`${classes.line}`} />
				</>
			)}
			<Outlet context={{ ...outletContext, documentGroups, setDocumentGroups }} />
		</div>
	);
}

export default DocumentsModuleContainer;
