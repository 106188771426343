import axios, { AxiosResponse } from "axios";
import { QueryFunctionContext, useQuery, UseQueryOptions, useMutation } from "react-query";
import { apiRecordingsUrl } from "../endpoints/endpoints";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../utils/handleAxiosResponse";
import { CPApiResponse, DataWithPermissions, Recording, ModulePermissionResponseInterface } from "../utils/interface";


declare type GetRecordingsByUserGroupParameters = ['recordings', {userGroupId: number}];
const getRecordingsByUserGroupParameters = async ({queryKey}: QueryFunctionContext<GetRecordingsByUserGroupParameters>): Promise<DataWithPermissions<Recording[]>> => {
    const [_key, {userGroupId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Recording[]>[]>> = await axios(`${apiRecordingsUrl}/List/${userGroupId}`);

    return handleModulePermissionResponseMulti(response);
};
export const useGetRecordingsByUserGroupParameters = (userGroupId: number, options: Omit<UseQueryOptions<DataWithPermissions<Recording[]>, unknown, DataWithPermissions<Recording[]>, GetRecordingsByUserGroupParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['recordings', {userGroupId}], getRecordingsByUserGroupParameters, options);
};


interface RecordingCrudMethods<T1> {
	get: (userGroupId: number) => Promise<Recording[]>;
	update: (data: Recording) => Promise<Recording>;
    create: (data: Recording) => Promise<Recording>;
    delete: (id: number) => Promise<boolean>;
}

export const RecordingApi: RecordingCrudMethods<CPApiResponse<Recording|Recording[]|boolean>> = {
	get: async (userGroupId: number): Promise<Recording[]> => {
		const response = await axios({
			url: `${apiRecordingsUrl}/List/${userGroupId}`,
			method: "GET",
		});
		
		return response.data;
	},
	update: async (data: Recording): Promise<Recording> => {
		const response = await axios({
			url: `${apiRecordingsUrl}/${data.id}`,
			method: "PUT",
			data: data,
		});

		return handleAxiosResponse(response);
	},
    create: async (data: Recording): Promise<Recording> => {
		const response = await axios({
			url: `${apiRecordingsUrl}`,
			method: "POST",
			data: data,
		});

		return handleAxiosResponse(response);
	},
    delete: async (id: number): Promise<boolean> => {
        const response = await axios(`${apiRecordingsUrl}/${id}`, {
            method: "DELETE",
          });

          return response.data;
	}
};

export const useGetAllRecordings = (userGroupId: number) => {
    return useQuery(['recordings', userGroupId], () => RecordingApi.get(userGroupId), {
        enabled: userGroupId > 0,
        
    });
};

export const useUpdateRecording = () => {
    return useMutation(RecordingApi.update);
};

export const useCreateRecording = () => {
    return useMutation(RecordingApi.create);
};

export const useDeleteRecording = () => {
    return useMutation(RecordingApi.delete);
};

