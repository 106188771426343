import React from 'react';
import Button from '../Button/Button';
import classes from "./RelatedPnSinformation.module.css";

const RelatedPnSinformation = () => {
  return (
    <div className={classes.ralterinfoWrapper}>
        <h2 className={classes.subTitle}>Related Parts & Service Information</h2>
        <div className={classes.flex}>
            <div className={classes.col}>
                <h4 >Parts Catalog</h4>
                <p>
                Click here to view open Quote Requests or to create new requests.  
                </p>
                <Button text={'View Parts Catalog'} className={'primaryBtn whiteBtn'} onClick={() => alert("View Parts Catalog")} />
            </div>
            <div className={classes.col}>
                <h4>Technical Documents</h4>
                <p>
                Click below for technical documents related to your Plant and Equipment and a link to the Technical Bulletin Web.
                </p>
                <Button text={'View Technical Documents'} className={'primaryBtn whiteBtn'} onClick={() => alert("View Technical Documents")} />
            </div>
            <div className={classes.col}>
                <h4>User Group Documents</h4>
                <p>
                Documents and groupings associated with this Users’ Group.  
                </p>
                <Button text={'View User Group Documents'} className={'primaryBtn whiteBtn'} onClick={() => alert("View User Group Documents")} />
            </div>
        </div>
    </div>
  )
}

export default RelatedPnSinformation