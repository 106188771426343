import clsx from "clsx";
import React, { FunctionComponent } from "react";


interface AvatarProps {
  userName: string|undefined;
  className?: string;
}
const avatarClasses = "w-[30px] h-[30px] flex justify-center items-center bg-[#567783] rounded-full border-0.6 border-[#E6ECEE] tracking-tighter font-bold text-white text-[10px] grow-0 shrink-0";

const TextAvatar: FunctionComponent<AvatarProps> = ({userName, className = ""}: AvatarProps) => {
  if (!userName) return <></>;

  const [firstname, lastname] = userName.split(" ");
  return (
    <div className={clsx(avatarClasses, className)}>
      {firstname.charAt(0).toUpperCase()} {lastname.charAt(0).toUpperCase()}
    </div>
  );
};


TextAvatar.defaultProps = {
  userName: undefined,
  className: "",
}

export default TextAvatar;