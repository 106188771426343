import axios, {AxiosResponse} from "axios";
import { useContext } from "react";
import {apiItemTypeCatalog} from "../endpoints/endpoints";
import { queryKeys } from "../react-query/queryKeys";
import { queryClient } from "../react-query/useQueryClientGet";
import { Store } from "../Store";
import {CPApiResponse, UserGroup} from '../utils/interface';

export const useItemTypeCatalog = () => {
    const {selectedUserGroup} = useContext(Store);

    const reorder = async (data: Array<{id: number, displayOrder: number}>): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiItemTypeCatalog}/ReOrder`, {
            method: 'POST',
            data
        });
        reFetchDiscussionCategory(selectedUserGroup);
        return response.data.data;
    };

    return {reorder};
};

export const reFetchDiscussionCategory = (selectedUserGroup: UserGroup) => {
    queryClient.invalidateQueries([queryKeys.discussionGroups,selectedUserGroup]);
    queryClient.invalidateQueries(`documentGroups.get.${selectedUserGroup.name}`);
}