import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DocumentGroup, PlantEquipmentsDATA, Report, SiteUnit, TypeDATA } from "../../../utils/interface";
import { AddDocumentRequiredProps } from "../DocumentsContainer";
import classes from "./TechnicalAddDocument.module.css";
import * as Yup from "yup";
import { useIsFetching, useQuery } from "react-query";
import DragDropFieldFormik from "../../../components/UI/Form/DragDropField/DragDropFieldFormik";
import ImageImports from "../../../utils/ImageImports";
import {
  useCreate_TBReport,
  useUpdate_TBReport,
  usePlantSiteUnitEquipment,
  useUnitsOfEquipmentIds,
  useGetFile_TBReport,
} from "../../Reports/ReportsHttpServices";
import { Formik, Form, FormikHelpers } from "formik";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import Loader from "../../../components/Loader/Loader";
import DropDownSelect from "../../../components/UI/Form/DropDownSelect/DropDownSelect";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";
import { Store } from "../../../Store";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { useOnError } from "../../../customHooks/useOnError";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";

const { trash } = ImageImports;

const TechnicalAddDocument: FunctionComponent<AddDocumentRequiredProps> = ({ documentGroups, onSave, siteUnits, documentModule, isEdit, setAddDocumentError }) => {
  const { showError, report, selectedPlant,plantConfig } = useContext(Store);
  const [isEquipments, setIsEquipments] = useState<boolean>(false);
  const [singleReport, setSingleReport] = useState<Report>();
  const [siteUnit, setSiteUnit] = useState<number[]>([]);
  const [associatedEquipment, setAssociatedEquipment] = useState<{ equipmentId: number }[] | []>([]);
  const setErrorData = useOnError();
  const navigate = useNavigate();
  const [attachment, setAttachment] = useState<File | Blob>();
  const [getUnit, setUnit] = useState<number>(0);
  const [equipment, setEquipment] = useState<number[]>([]);
  const [productTypes, setProductTypes] = useState<{ id: number; name: string }[]>([]);

  const IStatus = [
    {
      id: "Not Performed",
      name: "Not Performed",
    },
    {
      id: "Partially Performed",
      name: "Partially Performed",
    },
    {
      id: "Completed",
      name: "Completed",
    },
  ];

  // --------------------------------------//

  const { mutate: AddTBDocument, isLoading: isAdding, isSuccess: isCreated, reset } = useCreate_TBReport();
  const { mutate: EditTBDocument, isLoading: isEditing, isSuccess: isEdited } = useUpdate_TBReport();

  //---------------------------------------//

  const { isLoading: isLoadingReport, refetch: refetchReports } = useQuery(["getSingleReport", report.globalId], () => documentModule?.documentApi.get(report.globalId), {
    onSuccess: (data: Report) => {
      setSingleReport && setSingleReport(data);    
    },
    enabled: report.globalId > 0,
  });

  //---------------------------------------//

  useUnitsOfEquipmentIds(equipment, {
    onSuccess: (unitArray) => {
      setUnit(unitArray[0]);
      setSiteUnit([...unitArray]);
    },
  });

  //-------------------------------------//
  const { isFetching: fileLoading } = useGetFile_TBReport(report.globalId, {
    enabled: report.globalId > 0 && isEdit,
    onSuccess: (data: Blob) => {
      setAttachment(data);
    },
    onError: (error: any) => {},
  });

  //--------------------------------------//

  const { data: siteEquipment } = usePlantSiteUnitEquipment(siteUnit[0] || 0, {
    enabled: siteUnit.length > 0,
    onSuccess: () => {
      setIsEquipments(true);
    },
    onError: () => {
      setIsEquipments(false);
    },
    initialData: [],
  });

  const handleOnSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {

    const formData = new FormData();
    for (let value in values) {
      if (value === "AssociatedEquipment") {
        const aE = values[value];
        aE.forEach((e: { equipmentId: number }, i: number) => {
          formData.append(`AssociatedEquipment[${i}].equipmentId`, `${e.equipmentId}`);
        });
      } else {
        formData.append(value, values[value]);
      }
    }

    if (!isEdit) {
      AddTBDocument(formData, {
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
        onSettled(data: Report | undefined) {
          // console.log("🚀 ~ file: TechnicalAddDocument.tsx ~ line 133 ~ onSettled ~ data", data);
          queryClient.invalidateQueries(["reports"]);
        },
      });
    } else {
      EditTBDocument(formData, {
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
        onSettled(data: Report | undefined) {
          console.log("🚀 ~ file: TechnicalAddDocument.tsx ~ line 133 ~ onSettled ~ data", data);
          queryClient.invalidateQueries(["reports"]);
        },
      });
    }
  };

  useEffect(() => {
    setAddDocumentError(undefined);
  }, [attachment]);

  // get all unique product type ids and names
  useEffect(() => {
    let tmpProductTypes: { [index: number]: string } = {};
    if (siteEquipment) {
      tmpProductTypes = siteEquipment?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
        carry[current.productType] = current.productTypeName;
        return carry;
      }, {});
    }
    const ids = Object.keys(tmpProductTypes);
    const productTypes: { id: number; name: string }[] = [];
    for (let i = 0; i < ids.length; i++) {
      let key = Number.parseInt(ids[i]);
      productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
    }
    setProductTypes(productTypes);
  }, [siteEquipment]);

  function setAsosiatedEquipmentObject(equipmentIDArray: number[]) {
    return equipmentIDArray.filter((d) => d > 0).map((d) => ({ equipmentId: d }));
  }

  useEffect(() => {
    if (isEdit) {
      // if (report.globalItemId <= 0) navigate(-1);
      const selectedEquipments = report.associatedEquipment?.map((e) => e.equipmentId);
      if (selectedEquipments) {
        setEquipment([...selectedEquipments]);
        const eq = setAsosiatedEquipmentObject(selectedEquipments);
        setAssociatedEquipment([...associatedEquipment, ...eq]);
        setIsEquipments(true);
      } else {
        setIsEquipments(false);
      }
    }
  }, [report]);

  const equipmentOptions: { id: number; name: string }[] = [{ id: 0, name: "All Equipment" }, ...productTypes];

  if (siteEquipment) {
    for (let i = 0; i < siteEquipment.length; i++) {
      equipmentOptions.push({
        id: siteEquipment[i].plantEquipmentId,
        name: siteEquipment[i].equipmentName,
      });
    }
  }

  let selectionText = "Multiple equipment options selected";

  interface TBFormField {
    displayName: string;
    itemTypeId: number | null;
    BaseOrgId:number,
    globalItemId?: number | null;
    scope: string;
    file: string;
    summaryGuideline: string;
    implementationStatus: string;
    AssociatedEquipment: { equipmentId: number }[] | null;
  }
  let initialValues: TBFormField;
  let validationSchema;
  let valideationObject = {
    displayName: Yup.string().required("This field is required"),
    itemTypeId: Yup.number().required("This field is required"),
    scope: Yup.string().required("This field is required"),
    summaryGuideline: Yup.string().required("This field is required"),
    implementationStatus: Yup.string().required("This field is required"),
  };

  validationSchema = Yup.object({
    ...valideationObject,
  });
  if (!isEdit) {
    initialValues = {
      displayName: "",
      BaseOrgId:plantConfig.data.baseOrgId,
      itemTypeId: null,
      scope: "",
      file: "",
      summaryGuideline: "",
      implementationStatus: "",
      AssociatedEquipment: associatedEquipment ? associatedEquipment : null,
    };

    validationSchema = Yup.object({
      ...valideationObject,
      file: Yup.mixed().required(),
    });
  } else {
    initialValues = {
      displayName: report ? report?.displayFileName : "",
      BaseOrgId:plantConfig.data.baseOrgId,
      itemTypeId: singleReport ? singleReport?.itemTypeId : null,
      globalItemId: report ? report?.globalId : null,
      scope: report ? report?.scope : "",
      file: "",
      summaryGuideline: report ? report?.summaryGuideline : "",
      implementationStatus: report ? report?.implementationStatus : "",
      AssociatedEquipment: associatedEquipment ? associatedEquipment : null,
    };
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {(formikProps) => {
        const handleUnitSelection = (selectedOption: DropDownOptionItem) => {
          setSiteUnit(siteUnits.filter((u) => u.unitId === selectedOption.id).map((u) => u.unitId));
        };

        const handleEquipmentSelection = (values: number[], toggled: number) => {
          let tmpValues = [...values];
          let tmpSiteEquipment = siteEquipment || [];
          /**
           * Remove all grouped options from tmpValues
           * if toggled = 0 and orig values includes 0, select all
           * if toggled = 0 and orig values does not include 0, un select all
           *
           */
          tmpValues = tmpValues.filter((v) => v > 0);
          if (toggled === 0) {
            if (values.includes(0)) {
              tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
            } else {
              tmpValues = [];
            }
          }

          if (toggled < 0) {
            let productTypeId = toggled * -1;
            if (values.includes(toggled)) {
              // a group was selected, check everything in that group
              for (let i = 0; i < tmpSiteEquipment.length; i++) {
                if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
                  tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
                }
              }
              tmpValues.push(toggled);
            } else {
              // a group was deselected, un check everything in that group
              let productEquipment = tmpSiteEquipment.filter((eq) => eq.productType === productTypeId).map((eq) => eq.plantEquipmentId);
              tmpValues = tmpValues.filter((v) => !productEquipment.includes(v));
            }
          }

          // if everything is selected, check select all
          if (tmpValues.length > 0 && tmpValues.length === tmpSiteEquipment.length) {
            tmpValues.push(0);
            for (let i = 0; i < productTypes.length; i++) {
              tmpValues.push(productTypes[i].id);
            }
          }

          tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);
          if (tmpValues) {
            const objEq = setAsosiatedEquipmentObject(tmpValues);

            if (objEq.length > 0) {
              formikProps.setFieldValue("AssociatedEquipment", objEq);
            }
            setEquipment(tmpValues);
          }
        };

        return (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}

            {isEdited || isCreated ? (
              <div>
                <p>
                  {report.displayFileName} was successfully added to the {documentModule?.name} page for {selectedPlant.name}. Would you like to add
                  another document at this time?
                </p>
                <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
                  {isCreated && <Button className={`darkBlue`} text="Add Another Document" onClick={reset} />}
                  <Button className={`whiteBtn`} text="Done" onClick={() => navigate("..")} />
                </div>
              </div>
            ) : (
              <Form>
                {isAdding || isEditing || fileLoading ? (
                  <Loader />
                ) : (
                  <div>
                    <div>
                      <TextInputField
                        name="displayName"
                        disabled={true}
                        details={"Document name will auto-populate to match the attachment’s filename."}
                        labelName="Document Name*"
                        placeholder="First name"
                        className=""                        
                      />
                    </div>
                    <div>
                      <DropDownSelect                        
                        name="itemTypeId"
                        options={documentGroups}
                        labelName="Document Group*"
                        placeholder={isEdit ? "" : "Select document group"}
                        defaultValue={isEdit ? singleReport?.itemTypeId : undefined}
                      />
                    </div>
                    <div>
                      <TextInputField name="scope" labelName="Scope* " className="" placeholder="Describe scope"  />
                    </div>

                    <div>
                      <TextInputField name="summaryGuideline" labelName="Summary*" className="" placeholder="Add a summary"  />
                    </div>
                    <div>
                      <DropDownSelect                        
                        name="implementationStatus"
                        options={IStatus}
                        placeholder={isEdit ? "" : "Select implementation status"}
                        defaultValue={isEdit && report ? report.implementationStatus : ""}
                        labelName="Implementation Status*"
                      />
                    </div>
                    <div>
                      <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                        <span className={classes.input_label}>Associated Unit*</span>
                        <div className={classes.form_group}>
                          <PlainDropDown
                            value={isEdit ? getUnit : siteUnit![0]}
                            className={classes.select_container}
                            selectClass={`${classes.flex_row} ${classes.select}`}
                            optionsClass={`${classes.flex_column} ${classes.select_options}`}
                            options={[{ id: false, name: "Select site unit" }, ...siteUnits.map((unit) => ({ id: unit.unitId, name: unit.name }))]}
                            onSelection={handleUnitSelection}
                          />
                        </div>
                      </div>
                      {isEquipments && (
                        <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                          <span className={classes.input_label}>Associated Equipment*</span>
                          <div className={classes.form_group}>
                            <MultiSelectDropDown
                              value={equipment}
                              className={classes.select_container}
                              selectClass={`${classes.flex_row} ${classes.select}`}
                              optionsClass={`${classes.flex_column} ${classes.select_options}`}
                              options={equipmentOptions}
                              noSelectionText="Select equipment association(s)"
                              hasSelectionText={selectionText}
                              onSelection={handleEquipmentSelection}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div>                     
                        <>
                          <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                            <span className={classes.input_label}>Attachment*</span>
                            {attachment && (
                              <div className={classes.form_group}>
                                <input
                                  type="text"
                                  name="displayName"
                                  disabled
                                  className={classes.input}
                                  style={{}}
                                  value={formikProps.values.displayName}
                                />
                                <img
                                  className={classes.deleteFile}
                                  onClick={() => {
                                    formikProps.setFieldValue("displayName", "");
                                    setAttachment(undefined);
                                  }}
                                  src={trash}
                                  alt="Remove Attachment"
                                />
                              </div>
                            )}
                          </div>
                          <DragDropFieldFormik name="file" onChange={setAttachment} displayName={"displayName"} attachment={attachment} />
                          <div className={`${classes.form_field_row}`}>
                            <div className={classes.warning_text}>
                              <span>Accepted file types: PDF, JPG, PNG</span>
                              <span>Max file size: 2MB</span>
                            </div>
                          </div>
                        </>
                      
                    </div>
                  </div>
                )}
                <div className="flex align-center">
                  <div className="flex-grow-1 flex">
                    {isEdit ? (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Save Document
                        </button>
                        {/* <button
                          type="button"
                          className="primaryBtn redDelete marginLeft16"
                          onClick={() => navigate("../delete")}
                        >
                          Delete
                        </button> */}
                      </>
                    ) : (
                      <>
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                          Add Document
                        </button>
                        {/* <button
                              type="button"
                              className="primaryBtn whiteBtn marginLeft16"
                            >
                              cancel
                            </button> */}
                      </>
                    )}
                  </div>
                  <span>*Required field</span>
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>

    
  );
};

export default TechnicalAddDocument;
