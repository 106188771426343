import React, { CSSProperties, FunctionComponent, MouseEvent, ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";

import "react-dates/initialize";
import { Store } from "../../Store";
import Arrow from "../../assets/arrow-right.svg";
import Button from "../../components/Button/Button";
import { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import usePagination from "../../customHooks/usePagination";
import {
  DataWithPermissions,
  DiscussionGroupInterface,
  DiscussionInterface,
  DiscussionPermissionedInterface, DocumentGroup, OMManualSectionDATA,
  OMManualSectionGetAllDTO, Permissions, PlantEquipmentsDATA, PlantEquipmentsDTO, PlantPermissionedReports, UnitDATA,
  UnitGetAllDTO
} from "../../utils/interface";

import { createSearchParams, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import classes from "./Reports.module.css";

import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";

import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

import Moment from "react-moment";

import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import MultiSelectDropDown from "../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import { useGetAll_OMManualSectionData } from "../CPItemConfiguration/OMManualSectionHttpServices";
import { useGetAllByUnitID_PlantEquipments } from "../PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../PlantInformation/UnitHttpServices";

import Dropdown from "../../components/Dropdown/Dropdown";
import Pill from "../../components/Pill/Pill";

import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { useOnError } from "../../customHooks/useOnError";
import { DocumentsModuleOutletInterface } from "../Documents/DocumentsModuleContainer";

import dayjs, { Dayjs } from "dayjs";
import { Link } from "react-router-dom";
import { Pagination } from "../../components/List/Pagination";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import ToggleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { ModuleIDs } from "../../utils/moduleIDs";
import DeleteDiscussionModal from "./DeleteDiscussionModal";
import DiscussionRowAccordion from "./DiscussionRowAccordion";
import DiscussionRowTable from "./DiscussionRowTable";
import { DiscussionModuleInterface } from "./DiscussionsContainer";
import "./React.Dates.Override.css";

export interface ColumnDefinition {
  title: string;
  key: keyof DiscussionInterface;
  style: CSSProperties;
  component?: FunctionComponent<{ report: DiscussionInterface; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

const { magnifyGlassBlack, filter, download, copyLink, downArrow, trash, blueTick, file_edit, discussion_chat } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

interface ReportsProps {
  documentModule: DiscussionModuleInterface;
  documentGroups: DiscussionGroupInterface[];
}

type SortOptionsDefinition = {
    [K in keyof DiscussionInterface]: {
        id: number;
        key: K;
        name: string;
        direction: 'asc' | 'desc';
        default?: boolean;
        manipulateFn?: (data: DiscussionInterface[K]) => any;
    }
}[keyof DiscussionInterface];

const sortOptions: SortOptionsDefinition[] = [
  {
    id: 1,
    name: "Modified: Newest to Oldest",
    key: "updated",
    direction: "desc",
  },
  {
    id: 2,
    name: "Modified: Oldest to Newest",
    key: "updated",
    direction: "asc",
  },
  {
    id: 3,
    name: "Published: Newest to Oldest",
    key: "created",
    direction: "desc",
  },
  {
    id: 4,
    name: "Published: Oldest to Newest",
    key: "created",
    direction: "asc",
  },
  {
    id: 5,
    name: "Name: A to Z",
    key: "title",
    direction: "asc",
    manipulateFn: d => d.toLowerCase()
  },
  {
    id: 6,
    name: "Name: Z to A",
    key: "title",
    direction: "desc",
    manipulateFn: d => d.toLowerCase()
  }
];

const Discussions: FunctionComponent<ReportsProps> = ({ documentModule, documentGroups }) => {
  const stateContext = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();
  const tooltipPortalRef = useRef(null);
  const [isBulkActionTooltipVisible, setIsBulkActionTooltipVisible] = useState<boolean>(false);
  const { setShowError, plantConfig, setReport, header } = stateContext;
  const [tableData, setTableData] = useState<DiscussionInterface[]>([]);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const setErrorData = useOnError();
  const [filters, setFilters] = useState<Filters>({});
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const {hasPermission, hasOnePermission} = usePermissions();

  // deleting documents
  const [documentsToDelete, setDocumentsToDelete] = useState<DiscussionInterface>();

  // moving documents
  const [documentsToMove, setDocumentsToMove] = useState<DiscussionInterface[]>();
  const [moveConfirmationMeta, setMoveConfirmationMeta] = useState<{ count: number; from: string; to: string }>();
  // copy document link
  const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);

  const [copyLinkData, setCopyLinkData] = useState<DiscussionInterface[]>([]);
  const { header: { data: { notifications, user } } } = useContext(Store);
  
  const {
    pagedData,
    setData,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
    setSortManipulateFn,
    totalRecords,
    pageCount
  } = usePagination<DiscussionInterface>();

  useEffect(() => {
    setSortManipulateFn(undefined);
    setSortDirection("desc");
    setSortKey("updated");
  }, []);


 

  let tableColumns: ColumnDefinition[];
  tableColumns = [
    {
      title: "Discussion Subject",
      key: "title",
      component: ({ report }) => {
        if (report.title)
          return report.isDeleted? (
            <div className="text-ocean">
              {report.title} {report.isDeleted && "(Deleted)"}
            </div>
          ) : (
            <Link to={`../discussionSubject?globalItemId=${report.globalItemId}`} className="text-ocean no-underline">
              {report.title} {report.isDeleted && "(Deleted)"}
            </Link>
          );
        return <>N/A</>;
      },
      className: classes.document_name,
      style: {
        flexBasis: "24%",
      },
    },
    {
      title: "Date Created",
      key: "created",
      component: ({ report }) => {
        if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Created By",
      key: "createdBy",
      component: ({ report }) => {
        if (report.createdBy) return <div>{report.createdBy}</div>;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Last Modified",
      key: "updated",
      component: ({ report }) => {
        if (report.updated) return <Moment date={report.updated} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Replies",
      key: "messageCount",
      component: ({ report }) => {
        if (report.messageCount && !report.isDeleted) {
          return (
            <div
              onClick={() => {
                if (!hasOnePermission('DiscussionPosts', ['admin', 'view']) && !user.portalAdmin) {
                  navigate('/forbidden', { replace: true });
                } else {
                  navigate({
                    pathname: "../discussionSubject",
                    search: createSearchParams({
                      globalItemId: report.globalItemId.toString(),
                    }).toString(),
                  });
                }
              }}
              className="cursor-pointer flex items-center text-[#567783]"
            >
              {" "}
              <img src={discussion_chat} alt="replies" />
              <strong>{report.messageCount}</strong>
            </div>
          );
        } else if (report.isDeleted) {
          return (
            <div style={{ display: "flex", alignItems: "center", color: "#567783", opacity: "0.5" }}>
              <img src={discussion_chat} alt="replies" />
              <strong>0</strong>
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center", color: "#567783" }}>
              <img src={discussion_chat} alt="replies" />
              <strong>0</strong>
            </div>
          );
        }
      },
      style: {
        flexBasis: "18%",
      },
    },
  ];

  const { mutate: deleteMutation } = useMutation((id: number) => documentModule.documentApi.delete(id, "User Deleted"), {
    onSuccess: (data: boolean) => {
      refetchReports();
    },
    onError: (error: AxiosError<string>) => {
      setShowError &&
        setShowError({
          isError: true,
          title: error.message,
          ErrorType: "danger",
        });
    },
  });
  const { mutate: moveMutation } = useMutation(
    (data: { ids: number[]; newItemTypeId: number }) => documentModule.documentApi.bulkMove(data.ids, data.newItemTypeId),
    {
      onSuccess: (data: boolean) => {
        refetchReports();
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );

  const group: any = documentGroups.find((dg) => dg.id === groupId);
  const { isFetching, refetch: refetchReports } = useQuery(["Discussions", group?.id], () => documentModule.documentApi.getAll(group?.id), {
    onSuccess: (data: DiscussionInterface[]) => {

      setTableData(data);
      setData(data.filter(filterReports));
    },
    enabled: !!group,
  });

  useEffect(() => {
    if (group) {
      refetchReports();
    }
  }, [location]);

  const confirmMove = (from: string, to: DocumentGroup) => {
    if (documentsToMove) {
      moveMutation({ ids: documentsToMove.map((d) => d.id), newItemTypeId: to.id });
      setMoveConfirmationMeta({
        count: documentsToMove.length,
        from,
        to: to.name,
      });
    }

    setDocumentsToMove(undefined);
  };

  const confirmDelete = () => {
    if (documentsToDelete) {
      deleteMutation(documentsToDelete.globalItemId);
      // setDeletionCount(documentsToDelete.length);
    }

    setDocumentsToDelete(undefined);
  };

  useEffect(() => {
    setShowCopyLinkModal(copyLinkData.length > 0);
  }, [copyLinkData]);

  // add additional tool tip actions to this array
  // the click event is passed to each onClick event handler as the first parameter
  // the second parameter is always an array of ReportRowData items - even when performing actions on a single row
  // `documentModule.documentApi` & `documentModule.documentGroupApi` point to api methods specific to the module (Technical Bulletins/General Documents/User Group Documents)
  // each module's documentApi & documentGroupApi methods should have the same signature so the methods can be called the same way here
  // example, here we should be able to call documentModule.documentApi.delete(reportRow) and the underlying method for that module should know what endpoint to hit and how the request should look.
  const tooltipActions: tooltipActionItem[] = [];

  if (hasOnePermission(['DiscussionPosts', 'DiscussionThreads', 'Discussions'], 'edit')) {
    tooltipActions.push({
      text: "Edit Details",
      showOnlyOn: ["ItemActions"],
      img: file_edit,
      onClick: (event: MouseEvent, data: DiscussionInterface[]) => {
        if (data.length < 0) return;
        const id = `${data.pop()?.globalItemId}`;
        navigate({
          pathname: "editdiscussion",
          search: createSearchParams({
            id,
          }).toString(),
        });
      },
    });
  }

  if (hasOnePermission(['DiscussionPosts', 'DiscussionThreads', 'Discussions'], 'delete')) {
    tooltipActions.push({
      text: "Delete Discussion",
      hideWhenDeleted: true,
      img: trash,
      showOnlyOn: ["ItemActions"],
      onClick: (event: MouseEvent, data: DiscussionInterface[]) => {
        setDocumentsToDelete(data.pop());

        // deleteMutation(data.pop()?.globalItemId);
      },
    });
  }

  useEffect(() => {
    setCloseFilters((f) => f + 1);
    setData(tableData.filter(filterReports));
  }, [filters]);

  useEffect(() => {
    setData([]);
    setTableData([]);
  }, [groupId]);

  const filterReports = (report: DiscussionInterface): boolean => {
    let matches = true;
    if (filters.createdStartDate) {
      matches = moment(report.created).toDate() > filters.createdStartDate;
    }
    if (matches && filters.createdEndDate) {
      matches = moment(report.created).toDate() < filters.createdEndDate;
    }
    if (matches && filters.modifiedStartDate) {
      matches = moment(report.updated).toDate() > filters.modifiedStartDate;
    }
    if (matches && filters.modifiedEndDate) {
      matches = moment(report.updated).toDate() < filters.modifiedEndDate;
    }
    if (matches && filters.showDeleted !== true) {
      matches = !report.isDeleted;
    }

    return matches;
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
    } else {
      setFilter("");
    }
  }, [searchText]);

  useEffect(() => {
    if (header.data.user.portalAdmin) {
      setFilters(filters => ({
        ...filters,
        showDeleted: true
      }));
    }
  }, []);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    setSortManipulateFn(() => option.manipulateFn);
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  const filterCount = useMemo(() => {
    let filterCount = 0;
  
    if (filters.createdDateFilter) {
      filterCount++;
    }
    if (filters.modifiedDateFilter) {
      filterCount++;
    }
    if (filters.showDeleted) {
      filterCount++;
    }

    return filterCount;
  }, [filters]);

  return (
    <div
      className={`${classes.flex_column} ${isDesktop ? classes.is_desktop : ""} ${isMobile ? classes.is_mobile : ""} ${
        isTablet ? classes.is_tablet : ""
      }`}
      style={{ flexGrow: 1 }}
    >
      {/* Delete document modals */}
      {documentsToDelete && (
        <DeleteDiscussionModal
          discussionModule={documentModule}
          discussion={documentsToDelete}
          onClose={() => setDocumentsToDelete(undefined)}
          confirm={confirmDelete}
        />
      )}
      {/* {deletionCount > 0 && (
        <DeleteDiscussionConfirmation documentModule={documentModule} deletionCount={deletionCount} onClose={() => setDeletionCount(0)} />
      )} */}
      {/* Move document modals */}
      {/* {documentsToMove && (
        <MoveDocumentModal
          documentGroupName={group?.name || ""}
          documentGroups={documentGroups}
          documents={documentsToMove}
          onClose={() => setDocumentsToMove(undefined)}
          move={confirmMove}
        />
      )}
      {moveConfirmationMeta && (
        <MoveDocumentConfirmation moveConfirmationMeta={moveConfirmationMeta} onClose={() => setMoveConfirmationMeta(undefined)} />
      )} */}

      <div className={`${classes.report_container} ${classes.flex_column}`}>
        <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
          <div className={`${classes.flex_row} ${classes.options_left}`}>
            {isDesktop && !isTabletLandscape && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  dropdownStyle={{
                    color: totalRecords === 0? '#666' : undefined,
                    pointerEvents: totalRecords === 0? 'none' : undefined,
                    backgroundColor: totalRecords === 0? '#F2F2F2' : undefined
                  }}
                  handleDropDownSelect={handleSort}
                  selectValue={"Modified: Newest to Oldest"}
                  selectedOption={sortSelectedOption}
                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container} ${tableData.length > 0? '' : 'text-[#666] pointer-events-none bg-[#F2F2F2]'}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={classes.search_keyword}
                value={searchText}
                disabled={tableData.length === 0}
                placeholder="Search documents"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {!isMobile && (
              <div>
                {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <ButtonWithTooltip
              className="whiteBtn"
              disabled={tableData.length === 0}
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" style={{filter: tableData.length === 0? 'var(--svgFilterDarkGray)' : undefined}} />}
              textClassName="filter-text"
              forceClose={closeFilters}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} />}
            />
          </div>
        </div>
        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.showDeleted && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, showDeleted: undefined })}
                type="default"
              >
                Show Deleted
              </Pill>
            )}
            {filters.createdDateFilter && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, createdDateFilter: undefined, createdStartDate: undefined, createdEndDate: undefined })}
                type="default"
              >
                {DatePublishedFilters.find((d) => d.value === filters.createdDateFilter)?.label}
              </Pill>
            )}
            {filters.modifiedDateFilter && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, modifiedDateFilter: undefined, modifiedStartDate: undefined, modifiedEndDate: undefined })}
                type="default"
              >
                {DatePublishedFilters.find((d) => d.value === filters.modifiedDateFilter)?.label}
              </Pill>
            )}
          </div>
        )}
        <div className={`${classes.results_container} ${classes.flex_column}`}>
          {isDesktop ? (
            <>
              <table className={classes.report_table}>
                <thead>
                  <tr>
                    {tableColumns.map((col) => (
                      <th key={col.title} style={col.style}>
                        {col.title}
                      </th>
                    ))}
                    {tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions")).length > 0 && (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {pagedData.map((row) => (
                    <DiscussionRowTable
                      key={row.id}
                      unread={notifications.filter(notification => notification.itemId === row.globalItemId && !notification.read).length > 0}
                      data={row}
                      columnDefinitions={tableColumns}
                      tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                    />
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {pagedData.map((row) => (
                <DiscussionRowAccordion
                  unread={false}
                  key={row.id}
                  data={row}
                  columnDefinitions={tableColumns.filter((column) => column.key !== "title")}
                  tooltipPortalRef={tooltipPortalRef}
                  tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                />
              ))}
            </>
          )}
          {totalRecords > 0 ? (
            <Pagination
                currentPage={currentPageNumber}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageNumbers={pageButtonNumbers}
                previousPage={previousPage}
                resultCount={pagedData.length}
                resultsPerPage={resultsPerPage}
                setCurrentPage={setCurrentPageNumber}
                setResultsPerPage={setResultsPerPage}
                totalRecords={totalRecords} />
          ) : isFetching ? (
            <div>Loading...</div>
          ) : (
            <div>No reports to display.</div>
          )}
        </div>
      </div>
      <div ref={tooltipPortalRef} />
    </div>
  );
};

export default Discussions;

type DateFilterValues = "P1D"|"P7D"|"P30D"|"P60D"|"P90D"|"custom";

const DatePublishedFilters: {
  label: string;
  value: DateFilterValues
}[] = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "custom",
  },
];

interface Filters {
  createdStartDate?: Date;
  createdEndDate?: Date;
  createdDateFilter?: DateFilterValues;
  modifiedStartDate?: Date;
  modifiedEndDate?: Date;
  modifiedDateFilter?: DateFilterValues;
  showDeleted?: boolean;
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
}
const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters }) => {
  const [createdDateFilter, setCreatedDateFilter] = useState<DateFilterValues>();
  const [createdStartDate, setCreatedStartDate] = useState<Dayjs>(dayjs(null));
  const [createdEndDate, setCreatedEndDate] = useState<Dayjs>(dayjs(null));
  const [modifiedDateFilter, setModifiedDateFilter] = useState<DateFilterValues>();
  const [modifiedStartDate, setModifiedStartDate] = useState<Dayjs>(dayjs(null));
  const [modifiedEndDate, setModifiedEndDate] = useState<Dayjs>(dayjs(null));

  const [showCreatedStartCalendar, setShowCreatedStartCalendar] = useState<boolean>(false);
  const [showCreatedEndCalendar, setShowCreatedEndCalendar] = useState<boolean>(false);
  const [showModifiedStartCalendar, setShowModifiedStartCalendar] = useState<boolean>(false);
  const [showModifiedEndCalendar, setShowModifiedEndCalendar] = useState<boolean>(false);

  const [showDeleted, setShowDeleted] = useState<boolean>();

  const {header: {data: {user}}} = useContext(Store);
  
  useEffect(() => {
    if (filters.createdDateFilter) {
      setCreatedDateFilter(filters.createdDateFilter);
      if (filters.createdDateFilter === "custom") {
        if (filters.createdStartDate && dayjs(filters.createdStartDate).isValid()) {
          setCreatedStartDate(dayjs(filters.createdStartDate));
        }
        if (filters.createdEndDate && dayjs(filters.createdEndDate).isValid()) {
          setCreatedEndDate(dayjs(filters.createdEndDate));
        }
      }
    }
    if (filters.modifiedDateFilter) {
      setModifiedDateFilter(filters.modifiedDateFilter);
      if (filters.modifiedDateFilter === "custom") {
        if (filters.modifiedStartDate && dayjs(filters.modifiedStartDate).isValid()) {
          setModifiedStartDate(dayjs(filters.modifiedStartDate));
        }
        if (filters.modifiedEndDate && dayjs(filters.modifiedEndDate).isValid()) {
          setModifiedEndDate(dayjs(filters.modifiedEndDate));
        }
      }
    }
    setShowDeleted(filters.showDeleted);
  }, [filters]);

  const applyFilters = () => {
    const filters: Filters = {
      showDeleted
    };

    if (createdDateFilter) {
      if (createdDateFilter === "custom" && createdStartDate && createdEndDate) {
        filters.createdStartDate = createdStartDate.startOf("day").toDate();
        filters.createdEndDate = createdEndDate.endOf("day").toDate();
      } else {
        const createdStartDate = moment().startOf("day");
        createdStartDate.subtract(moment.duration(createdDateFilter));
        filters.createdStartDate = createdStartDate.toDate();
      }
      filters.createdDateFilter = createdDateFilter;
    }

    if (modifiedDateFilter) {
      if (modifiedDateFilter === "custom" && modifiedStartDate && modifiedEndDate) {
        filters.modifiedStartDate = modifiedStartDate.startOf("day").toDate();
        filters.modifiedEndDate = modifiedEndDate.endOf("day").toDate();
      } else {
        const modifiedStartDate = moment().startOf("day");
        modifiedStartDate.subtract(moment.duration(modifiedDateFilter));
        filters.modifiedStartDate = modifiedStartDate.toDate();
      }
      filters.modifiedDateFilter = modifiedDateFilter;
    }

    onFiltersApplied(filters);
  };

  const canApplyFilters = useMemo(() => {
    const obj1 = Object.entries({
      createdDateFilter,
      createdStartDate: createdStartDate.isValid()? createdStartDate : undefined,
      createdEndDate: createdEndDate.isValid()? createdEndDate : undefined,
      modifiedDateFilter,
      modifiedStartDate: modifiedStartDate.isValid()? modifiedStartDate : undefined,
      modifiedEndDate: modifiedEndDate.isValid()? modifiedEndDate: undefined,
      showDeleted
    });
    const obj2 = Object.entries({
      createdDateFilter: filters.createdDateFilter,
      createdStartDate: filters.createdStartDate,
      createdEndDate: filters.createdEndDate,
      modifiedDateFilter: filters.modifiedDateFilter,
      modifiedStartDate: filters.modifiedStartDate,
      modifiedEndDate: filters.modifiedEndDate,
      showDeleted: filters.showDeleted
    });
    const hasCreatedDateValues = !(createdDateFilter === "custom" && (!createdStartDate.isValid() || !createdEndDate.isValid()));
    const hasModifiedDateValues = !(modifiedDateFilter === "custom" && (!modifiedStartDate.isValid() || !modifiedEndDate.isValid()));
    return obj1.toString() !== obj2.toString() && hasCreatedDateValues && hasModifiedDateValues;
  }, [filters, createdDateFilter, createdStartDate, createdEndDate, modifiedDateFilter, modifiedStartDate, modifiedEndDate, showDeleted]);

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      {user?.portalAdmin && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Show Deleted:</label>
          <div className={classes.checkboxWrapper}>
            <div className={classes.checkboxRow}>
              <ToggleSwitch isChecked={showDeleted || false} label="" onChange={() => setShowDeleted(del => !del)} />
            </div>
          </div>
        </div>
      )}
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Date Created:</label>
        <RadioButtonGroup onChange={setCreatedDateFilter} name="date_published" options={DatePublishedFilters} value={createdDateFilter} />
      </div>
      {createdDateFilter === "custom" && (
        <DateRangePicker
          startDate={createdStartDate}
          endDate={createdEndDate}
          showEndCalendar={showCreatedEndCalendar}
          showStartCalendar={showCreatedStartCalendar}
          startName="createdStartDate"
          endName="createdEndDate"
          startDateChange={setCreatedStartDate}
          endDateChange={setCreatedEndDate}
          setToggleEndCalendarDisplay={setShowCreatedEndCalendar}
          setToggleStartCalendarDisplay={setShowCreatedStartCalendar}
        />
      )}
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Last Modified:</label>
        <RadioButtonGroup onChange={setModifiedDateFilter} name="last_modified" options={DatePublishedFilters} value={modifiedDateFilter} />
      </div>
      {modifiedDateFilter === "custom" && (
        <DateRangePicker
          startDate={modifiedStartDate}
          endDate={modifiedEndDate}
          showEndCalendar={showModifiedEndCalendar}
          showStartCalendar={showModifiedStartCalendar}
          startName="modifiedStartDate"
          endName="modifiedEndDate"
          startDateChange={setModifiedStartDate}
          endDateChange={setModifiedEndDate}
          setToggleEndCalendarDisplay={setShowModifiedEndCalendar}
          setToggleStartCalendarDisplay={setShowModifiedStartCalendar}
        />
      )}
      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
