import React, { useState, useEffect } from "react";
import { GlobalSearch, SearchResultItemField } from "../../../utils/interface";
import Pill from "../../Pill/Pill";
import classes from "./SearchItem.module.css";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
interface SearchItemProps {
  searchItem: GlobalSearch;
}
const SearchItem = ({ searchItem }: SearchItemProps) => {
  const [fileType, setFileType] = useState<SearchResultItemField>();
  const [fileSize, setFileSize] = useState<SearchResultItemField>();
  const [fileName, setFileName] = useState<SearchResultItemField>();
  const [publishedDate, setPublishedDate] = useState<SearchResultItemField>();
  const [publishedBy, setPublishedBy] = useState<SearchResultItemField>();
  const [modifiedDate, setModifiedDate] = useState<SearchResultItemField>();
  const [modifiedBy, setModifiedBy] = useState<SearchResultItemField>();

  const { searchText } = useParams();

  useEffect(() => {
    setPublishedDate(searchItem.fields.find(d => d.name.toLowerCase() === 'published date'));
    setPublishedBy(searchItem.fields.find(d => d.name.toLowerCase() === 'published by'));
    setFileType(searchItem.fields.find(d => d.name.toLowerCase() === "file format"));
    setFileSize(searchItem.fields.find(d => d.name.toLowerCase() === "file size"));
    setFileName(searchItem.fields.find(d => d.name.toLowerCase() === "file original name"));
    setModifiedDate(searchItem.fields.find(d => d.name.toLowerCase() === "modified date"));
    setModifiedBy(searchItem.fields.find(d => d.name.toLowerCase() === "modified by"));
  }, []);

  return (
 
      <div className={classes.searchTbl}>
      <div className={classes.searchCell}>
        <Pill style={{ fontSize: "16px", fontWeight: "bold", borderRadius: '8px' }} type={"dkBlue"}>
          {searchItem.type}
        </Pill>
      </div>
      <div className={classes.searchCell}>
        <div className={classes.searchKeyWord}>
          <Highlighter
            highlightClassName={classes.Highlight}
            searchWords={searchText ? [`${searchText}`] : ["a"]}
            autoEscape={true}
            textToHighlight={searchItem.title}
          />
        </div>
        <div className={classes.fileSize}>
          {fileType?.value}, {fileSize?.value}
        </div>
      </div>

      <div className={classes.searchCell}>
        <div className={classes.dateTextTbl}>
          <span className={classes.dateTextLabel}>Published Date</span>{" "}
          <span className={classes.dateText}>
            <Moment date={publishedDate?.value} format={'MM/DD/YYYY'} /> ({publishedBy?.value})
          </span>
        </div>
        {modifiedDate && (
          <div className={classes.dateTextTbl}>
            <span className={classes.dateTextLabel}>Last Modified</span>{" "}
            <span className={classes.dateText}>
              <Moment date={modifiedDate?.value} format={'MM/DD/YYYY'} /> ({modifiedBy?.value})
            </span>
          </div>
        )}
      </div>
    </div>

    
  );
};

export default SearchItem;
