import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Button from "../Button/Button";
import ImageImports from "../../utils/ImageImports";
import { RequiresAdmin } from "../Auth/RequiresAdmin";
import EmptyModal from "../Modals/EmptyModal";
import { LinkDisplay } from "../Links/LinkDisplay";
import { Link } from "../../utils/interface";
import { AddEditLink, AddEditLinkConfirmation } from "../Links/AddEditLink";
import { Store } from "../../Store";
import { useCreatePageContent, useGetPageContent } from "../../customHooks/PageContentHttpServices";
import Moment from "react-moment";
import { useCreateLink, useGetAllLinks } from "../../customHooks/LinkHttpServices";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";

const { pencil, tooltip } = ImageImports;

// TODO: This should go in interfaces.tsx when we know the structure of it
interface PageContent {
  title: string;
  modified: string;
  content: string;
}

export const TermsOfUse = () => {
  const { selectedUserGroup } = useContext(Store);
  const [editContent, setEditContent] = useState<PageContent>();
  const [showEditContentConfirmation, setShowEditContentConfirmation] = useState<boolean>(false);
  const [addLink, setAddLink] = useState<Link>();
  const [addLinkConfirmation, setAddLinkConfirmation] = useState<Link>();
  const [links, setLinks] = useState<Link[]>([]);
  const [content, setContent] = useState({
    title: "Terms of Use, General Disclaimer and Privacy Policy",
    modified: "",
    content: "No announcements to display.",
  });

  const { mutate: createPageContent } = useCreatePageContent({onSuccess: () => {},});
  const { mutate: createLinkContent } = useCreateLink({onSuccess: () => {},});
  const { data: pageContent, refetch: getPageContent } = useGetPageContent();
  const { data: linkContent, refetch: getLinkContent } = useGetAllLinks();

  useEffect(() => {
    pageContent &&
      setContent({
        title: pageContent.contentTitle,
        modified: pageContent.created,
        content: pageContent.contentMessage,
      });
  }, [pageContent]);

  useEffect(() => {
    linkContent && setLinks(linkContent);
  }, [linkContent]);

  const defaultLink: Link = useMemo(() => {
    return {
      created: "",
      createdBy: "",
      displayOrder: Math.max(...links.map((link) => link.displayOrder)) + 1,
      id: 0,
      modified: "",
      modifiedBy: "",
      title: "",
      url: "",
      userGroupId: selectedUserGroup.id,
    };
  }, [links]);

  const saveContent = useCallback((content: PageContent) => {
    createPageContent(
      {
        contentMessage: content.content,
        contentTitle: `${content.title}`,
        created: "",
        createdBy: "",
        globalItemId: 0,
        id: 0,
        userGroupId: selectedUserGroup.id,
      },
      {
        onSuccess(data) {
          getPageContent();
        },
      }
    );
    // do the saving
    setEditContent(undefined);  

    setShowEditContentConfirmation(true);
  }, []);

  const linkSortFn = useCallback((a: Link, b: Link): number => {
    if (a.displayOrder > b.displayOrder) return 1;
    if (b.displayOrder > a.displayOrder) return -1;
    return 0;
  }, []);

  const saveLink = useCallback((link: Link) => {
    link && createLinkContent(link, {
        onSuccess(data) {
            setLinks(links => [...links, data]);
            getLinkContent();
            setAddLink(undefined);
            setAddLinkConfirmation(link);
        },
    });
  }, []);

  const addAnotherLink = useCallback(() => {
    setAddLinkConfirmation(undefined);
    setAddLink(defaultLink);
  }, [defaultLink]);

  return (
    <div className="flex flex-row !pb-0 self-stretch">
      {editContent && <EditContent content={editContent} onSave={saveContent} onClose={() => setEditContent(undefined)} />}
      {showEditContentConfirmation && <EditContentConfirmation onClose={() => setShowEditContentConfirmation(false)} />}
      {addLink && (
        <AddEditLink
          link={addLink}
          onClose={() => setAddLink(undefined)}
          onDelete={() => {}}
          onSave={saveLink}
        />
      )}
      {addLinkConfirmation && (
        <AddEditLinkConfirmation link={addLinkConfirmation} canAddAnother={links.length < 5} onAddAnother={addAnotherLink} onClose={() => setAddLinkConfirmation(undefined)} />
      )}
      <div className="flex flex-col lg:basis-2/3 bg-white rounded-lg gap-6 p-6 shadow-[0_25px_50px_-12px_rgba(0,0,0,0.1)]">
        <div className="flex flex-col items-start gap-2 self-stretch">
          <div className="flex flex-row !pb-0 justify-between self-stretch">
            <span className="font-bold text-2xl">{content.title}</span>
            <RequiresAdmin>
              <img src={pencil} alt="Edit" className="cursor-pointer" onClick={() => setEditContent(content)} />
            </RequiresAdmin>
          </div>
          <span className="text-sm text-[#808080]">
            {" "}
            {content.modified && <Moment date={content.modified} format="MM/DD/YYYY" />}
          </span>
          <span className="text-base text-black" dangerouslySetInnerHTML={{__html: content.content.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></span>
        </div>
        {links.sort(linkSortFn).map((link) => (
          <LinkDisplay key={link.id} link={link} />
        ))}
        <RequiresAdmin>
          {links.length < 5? (
            <div>
              <Button className="whiteBtn" text="Add Link" onClick={() => setAddLink(defaultLink)} />
            </div>
          ) : <></>}
        </RequiresAdmin>
      </div>
    </div>
  );
};

const EditContent = ({ content, onClose, onSave }: { content: PageContent; onClose: () => void; onSave: (content: PageContent) => void }) => {
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setTitle(content.title);
    setMessage(content.content);
  }, [content]);

  const canSave = useMemo(() => {
    const checks: (() => boolean)[] = [
      () => title.length > 0,
      () => message.length > 0,
      () =>
        Object.entries({
          title,
          content: message,
        }).toString() !==
        Object.entries({
          title: content.title,
          content: content.content,
        }).toString(),
    ];
    let canSave = true;
    for (let i = 0; i < checks.length && canSave === true; i++) {
      canSave = checks[i]();
    }

    return canSave;
  }, [title, message, content]);

  const update = useCallback(() => {
    onSave({
      ...content,
      title,
      content: message,
    });
  }, [content, title, message]);

  return (
    <EmptyModal
      body={
        <div className="flex flex-col items-start gap-8 self-stretch">
          <div className="flex flex-row !pb-0 gap-6 self-stretch">
            <div className="flex flex-row !pb-0 gap-1 basis-36 items-center">
              <span>Content Title*</span>
              <MiniTooltip text="Please enter the Content Title.">
                  <img src={tooltip} alt="Content Title" className="h-4 w-4" />
              </MiniTooltip>
            </div>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
              style={{ boxShadow: "0 1px 0 #B3B3B3" }}
            />
          </div>
          <div className="flex flex-row !pb-0 gap-6 self-stretch">
            <div className="flex flex-row !pb-0 gap-1 basis-36 items-center">
              <span>Message</span>
              <MiniTooltip text="Please enter the Message.">
                  <img src={tooltip} alt="Message" className="h-4 w-4" />
              </MiniTooltip>
            </div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={12}
              placeholder="Message"
              className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
              style={{ boxShadow: "0 1px 0 #B3B3B3" }}
            ></textarea>
          </div>
        </div>
      }
      footer={
        <>
          <div className="flex flex-row !pb-0 gap-4 justify-center">
            <Button className="darkBlue" text="Save Changes" disabled={!canSave} onClick={update} />
            <Button className="whiteBtn" text="Cancel" onClick={onClose} />
          </div>
          <span className="font-light text-sm">*Required fields</span>
        </>
      }
      heading="Edit Content"
      onClose={onClose}
      shown={true}
    />
  );
};

const EditContentConfirmation = ({ onClose }: { onClose: () => void }) => (
  <EmptyModal
    body={<span className="self-center">Content has been successfully updated.</span>}
    footer={
      <div className="flex flex-row !pb-0 justify-center grow gap-4">
        <Button className="darkBlue" text="Done" onClick={onClose} />
      </div>
    }
    heading="Changes Saved"
    headingClass="pl-8 text-center"
    onClose={onClose}
    shown={true}
    width={564}
  />
);
