import { useContext, useState } from "react";
import { useUpdate_FAQ } from "../../../containers/Support/SupportHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { Store } from "../../../Store";
import { CPApiResponse, FaqInterfaceDTO } from "../../../utils/interface";
import Editor from "../../UI/RichTextEditor/Editor";

const FormFaq = (props: FormFaqProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const { setFaq, setIsModal, isModal, faq } = useContext(Store);

  const { mutate: UpdateFaq, isLoading: isUpdatingFaq } = useUpdate_FAQ();

  const setErrorData = useOnError();

  const [faqText, setFaqText] = useState<string>("");

  const handleUpdateFaq = () => {
    UpdateFaq({ faqText: faqText }, {
      onSuccess: (data: CPApiResponse<FaqInterfaceDTO>) => {
        queryClient.invalidateQueries(queryKeys.getFaqApi);
        if (props.modalClose)
          props.modalClose();
      },
      onError: (error: unknown) => {
        setErrorData(error);
      }
    });
  }

  const handleContentChange = (val: string) => {
    if (val !== faq.faqText) {
      setFaqText(val)
      setTouched(true)
    } else
      setTouched(false)
  }

  return (
    <div>
      <Editor handleChange={handleContentChange} content={faq?.faqText} />

      <div className="flex align-center">

        <div className="flex-grow-1 flex">
          <button
            type="button"
            className="primaryBtn darkBlue"
            onClick={handleUpdateFaq}
            disabled={!touched}
          >
            Save Changes
          </button>
          <button
            type="button"
            className="primaryBtn whiteBtn marginLeft16"
            onClick={props.modalClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

interface FormFaqProps {
  modalClose?: () => void;
}

export default FormFaq;
