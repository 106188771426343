import {  useEffect, useState } from "react"

const useScroll = () =>{
    const [onScroll100Active, setOnScroll100Active] = useState(false);  
    useEffect(() => {
        const moveSelectOnscroll = () => {
            if(window.scrollY >= 100){
                setOnScroll100Active(true);
            }else{
                setOnScroll100Active(false);
            }
          }
          window.addEventListener("scroll",moveSelectOnscroll)

        return () => {
            window.removeEventListener('scroll',moveSelectOnscroll);
        }
    },[]);
    

      return {onScroll100Active};
   
}

export default useScroll;