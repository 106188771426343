import { FunctionComponent, useContext } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { DocumentModuleInterface } from "../Documents/DocumentsContainer";
import classes from './Reports.module.css';
import {CloseAllModal} from "../../utils/CloseAllModal";

interface Props {
    documentModule: DocumentModuleInterface;
    deletionCount: number;
    onClose: () => void;
}

const DeleteDocumentConfirmation: FunctionComponent<Props> = ({documentModule, deletionCount, onClose}) => {
    const {selectedPlant, setIsModal} = useContext(Store);
    const close = () => {
        
        setIsModal && setIsModal!({ ...CloseAllModal });
        onClose();
    };
    return (
        <ModalGlobal modaltitle={`(${deletionCount}) Document(s) Successfully Deleted`} isModalHeader={true} modalClose={close} modalSize="sm" titleClassName="centeredText">
            <div className={`${classes.delete_confirm_body} ${classes.flex_column}`}>
                <p>You successfully deleted document(s) on the {documentModule.name} page. This document(s) will no longer be accessible to {selectedPlant.name} customers.</p>
                <div className={classes.flex_row}>
                    <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={close} />
                </div>
            </div>
        </ModalGlobal>
    );
}

export default DeleteDocumentConfirmation;