import React, { useContext, useEffect, useRef, useState } from "react";

import QuoteAttachmentOptions from "./QuoteAttachmentOptions";
import classes from "./AttachmentCard.module.css";
import ImageImports from "../../utils/ImageImports";
import moment from "moment";
import { IdObj, RequestQuoteFormInterface } from "../../utils/interface";

import { PrintPDF, toBase64 } from "../../utils/FileUtils";
import { AxiosError } from "axios";
import { useOnError } from "../../customHooks/useOnError";
import { useIsFetching } from "react-query";
import Skeleton from "react-loading-skeleton";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { useFormikContext } from "formik";

interface AttachmentCardProps {
  id: number;
  fileName: string;
  fileType: string;
  lastUpdated: string;
  ext: string;
  image: string;
}

function AttachmentCard(props: AttachmentCardProps) {
  // Image imports;
  const { ellipsis, trash, file_edit, download, print, spinner } = ImageImports;
  const { values,  setFieldValue, resetForm, errors, touched } = useFormikContext<RequestQuoteFormInterface>();
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState<string>("");
  const isFetching = useIsFetching();

  const popoutToolTipListItems = [
    {
      img: file_edit,
      text: "Edit",
      onclick: (id:number) => {
        if(id === 1){
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isPartsListDocument: true },
            })
        }else if(id === 2){
          setIsModal &&
          setIsModal({
            ...CloseAllModal,
            isAdd: { ...isModal.isAdd, isAttachNameplateAsset: true },
          })
        }else{
          setIsModal &&
          setIsModal({
            ...CloseAllModal,          
          })
        }
      },
    },
    {
      img: download,
      text: "Download",
      onclick: (id:number) => {
        alert("download");
      },
    },
    {
      img: print,
      text: "Print",
      onclick: (id:number) => {
        alert("Print");
      },
    },
    {
      img: trash,
      text: "Delete",
      onclick: (id:number) => {
        if(id === 1){
          setFieldValue("partsDocumentBase64","");
          setFieldValue("partsDocument.file","");
          setFieldValue("partsDocument.name","");
        }else if(id === 2){
          setFieldValue("nameplateBase64","");
          setFieldValue("nameplate.file","");
          setFieldValue("nameplate.name","");
        }else{
          setIsModal &&
          setIsModal({
            ...CloseAllModal,          
          })
        }
        
      },
    },
  ];

  // const stateContext = useContext(Store);
  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  useEffect(() => {
    setImgBase64(props.image);
  }, [props.image]);

  let previewContainer;
  if (imgBase64) {
    if (imgBase64.includes("data:image")) {
      previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} style={{ backgroundImage: `url(${imgBase64})` }}></div>;
    }
    if (imgBase64.includes("data:application")) {
      previewContainer = (
        <div className={`${classes.col_item} ${classes.imgPrevWrapper}`}>
          <iframe src={imgBase64} width="100%" height="100%"></iframe>
        </div>
      );
    }
  }

  return (
    <>
      <div className={classes.nameplate_title}>
        {
          <div className={classes.nameplate_content}>
            <div className={classes.nameplate_ellipse}>
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                onClick={() => {
                  setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              <QuoteAttachmentOptions listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={props.id} />
            </div>
            {isFetching > 0 ? <Skeleton height={200} /> : previewContainer }

            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1">{props.fileName}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                Last Modified: {`${moment(props.lastUpdated).format("MMM DD YYYY")}`}
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default AttachmentCard;
